/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalEscalationPath,
    ExternalEscalationPathFromJSON,
    ExternalEscalationPathFromJSONTyped,
    ExternalEscalationPathToJSON,
} from './ExternalEscalationPath';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';

/**
 * 
 * @export
 * @interface EscalationPathsListExternalResponseBody
 */
export interface EscalationPathsListExternalResponseBody {
    /**
     * 
     * @type {Array<ExternalEscalationPath>}
     * @memberof EscalationPathsListExternalResponseBody
     */
    external_escalation_paths: Array<ExternalEscalationPath>;
    /**
     * 
     * @type {PaginationMeta}
     * @memberof EscalationPathsListExternalResponseBody
     */
    pagination_meta?: PaginationMeta;
}

export function EscalationPathsListExternalResponseBodyFromJSON(json: any): EscalationPathsListExternalResponseBody {
    return EscalationPathsListExternalResponseBodyFromJSONTyped(json, false);
}

export function EscalationPathsListExternalResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathsListExternalResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_escalation_paths': ((json['external_escalation_paths'] as Array<any>).map(ExternalEscalationPathFromJSON)),
        'pagination_meta': !exists(json, 'pagination_meta') ? undefined : PaginationMetaFromJSON(json['pagination_meta']),
    };
}

export function EscalationPathsListExternalResponseBodyToJSON(value?: EscalationPathsListExternalResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_escalation_paths': ((value.external_escalation_paths as Array<any>).map(ExternalEscalationPathToJSON)),
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
    };
}

