import { usePylon } from "@bolasim/react-use-pylon";
import {
  Button,
  ButtonTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

import graphic from "./first-access.svg";

export const CATALOG_FIRST_ACCESS_MODAL = "catalog-first-access";
export const FIRST_ACCESS_MODAL_RELEASE_DATE = new Date(2024, 8, 23); // the 8th month _is_ September

export const CatalogFirstAccessModal = ({
  onClose,
  loading,
}: {
  onClose: () => void;
  loading: boolean;
}): React.ReactElement => {
  const { showKnowledgeBaseArticle } = usePylon();
  return (
    <Modal
      isOpen
      title={""} // hidden
      hideHeader
      onClose={onClose}
      analyticsTrackingId={null}
      className="!w-[600px]"
      isExtraLarge
    >
      <ModalContent className="!p-0 !m-0">
        <img className="w-full" src={graphic} />
        <div className="flex flex-col items-center p-10 pb-6 gap-3 text-center">
          <div className="text-2xl-bold">
            Automation tailored to your organization
          </div>
          <div className="text-sm-normal flex flex-col gap-2">
            <div>
              Unlock powerful automations for your organization by integrating
              your existing tools with Catalog or quickly building with AI
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter hideBorder>
        <div className="flex gap-2 items-center mx-auto pb-2">
          <Button
            analyticsTrackingId={null}
            onClick={() => showKnowledgeBaseArticle("2606187127")}
            theme={ButtonTheme.Secondary}
            loading={loading}
            disabled={loading}
          >
            Learn more
          </Button>
          <Button
            analyticsTrackingId={null}
            onClick={onClose}
            theme={ButtonTheme.Primary}
            loading={loading}
            disabled={loading}
          >
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
