import { usePylon } from "@bolasim/react-use-pylon";
import { IntegrationsGitLabInstallRequestBody } from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { IntegrationsListObject } from "@incident-shared/integrations";
import { Button, ButtonTheme, ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

type FormType = IntegrationsGitLabInstallRequestBody & {
  hosting_type: "saas" | "self-hosted";
};
export const ConnectToGitLabModal = ({
  onClose,
  isReconnect,
}: {
  onClose: () => void;
  integration: IntegrationsListObject;
  isReconnect?: boolean;
}): React.ReactElement | null => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  const formMethods = useForm<FormType>({
    defaultValues: {
      hosting_type: "saas",
    },
  });

  const { trigger, isMutating } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, data: FormType) => {
      await apiClient.integrationsGitLabInstall({
        installRequestBody: {
          token: data.token,
          base_url:
            data.hosting_type === "saas"
              ? "https://gitlab.com/"
              : data.base_url,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal
      title={"Connect to GitLab"}
      analyticsTrackingId="connect-integration"
      analyticsTrackingMetadata={{ integration: "git_lab" }}
      onClose={onClose}
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      footer={
        <ModalFooter
          saving={isMutating}
          onClose={onClose}
          confirmButtonText={isReconnect ? "Reconnect" : "Connect"}
          confirmButtonType="submit"
        />
      }
    >
      <Form.Helptext>
        Connect to GitLab to export your actions to GitLab issues and connect
        merge requests to incidents. You can find a detailed guide to our GitLab
        integration{" "}
        <Button
          analyticsTrackingId={"connect-gitlab-integration-help"}
          theme={ButtonTheme.Link}
          onClick={() => showArticle("7096340768")}
        >
          in our help center.
        </Button>
      </Form.Helptext>

      <RadioButtonGroupV2
        name="hosting_type"
        formMethods={formMethods}
        boxed
        options={[
          {
            label: "GitLab.com",
            value: "saas",
          },
          {
            label: "Self-managed or GitLab Dedicated",
            value: "self-hosted",
            renderWhenSelectedNode: () => (
              <InputV2
                formMethods={formMethods}
                name="base_url"
                helptext={"Where is your instance hosted?"}
                placeholder="https://gitlab.example.com/"
                required
              />
            ),
          },
        ]}
        srLabel=""
      />

      <InputV2
        formMethods={formMethods}
        name="token"
        label="Access token"
        type={InputType.Password}
        helptext={
          <>
            This must be a non-expiring personal access token for a service
            account. For more information, see our{" "}
            <Button
              analyticsTrackingId={"connect-gitlab-integration-help"}
              theme={ButtonTheme.Link}
              onClick={() => showArticle("7096340768")}
            >
              setup guide
            </Button>
            .
          </>
        }
      />
    </Form.Modal>
  );
};
