import { InvestigationPlanTypeEnum } from "@incident-io/api";
import {
  CreateEvaluationBacktestRequestBody,
  useAiServiceAiListEvaluationDatasets,
  useAiStaffServiceAiStaffCreateEvaluationBacktest,
  UseAiStaffServiceAiStaffListEvaluationBacktestsKeyFn,
} from "@incident-io/query-api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter, ToastTheme } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";

type FormData = {
  investigation_plan_type: InvestigationPlanTypeEnum;
  limit_runs_using_credit: boolean;
  credit: number;
  notes: string;
  // We either have a whole dataset or a list of incidents and segments
  dataset_id?: string;
};

export const BacktestCreateModal = ({
  incidentIDs,
  segments,
  onClose,
}: {
  incidentIDs?: string[];
  segments?: { [segmentName: string]: string[] };
  onClose: () => void;
}) => {
  const investigationPlans = Object.values(InvestigationPlanTypeEnum);
  const defaultPlanID = InvestigationPlanTypeEnum.Default;

  const { data: datsetData, isLoading: isDataLoading } =
    useAiServiceAiListEvaluationDatasets();

  const allowChooseDataset = !incidentIDs || incidentIDs.length === 0;

  const formMethods = useForm<FormData>({
    defaultValues: {
      investigation_plan_type: defaultPlanID,
      credit: 10,
    },
  });

  const shouldUseCredit = formMethods.watch("limit_runs_using_credit");
  const apiClient = useClient();
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();
  const queryClient = useQueryClient();

  const { mutate: createBacktest, isPending: isCreating } =
    useAiStaffServiceAiStaffCreateEvaluationBacktest({
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffListEvaluationBacktestsKeyFn(),
        });

        showToast({
          title: "Backtest created",
          theme: ToastTheme.Success,
        });

        navigate(`/workbench/backtests/${data.backtest.id}`);
      },
      onError: (error) => {
        showToast({
          title: "Failed to create backtest",
          theme: ToastTheme.Error,
          description: JSON.stringify(error),
        });
      },
    });

  const onSubmit = async (data: FormData) => {
    const requestBody: CreateEvaluationBacktestRequestBody = {
      dataset_id: data.dataset_id,
      investigation_plan_type: data.investigation_plan_type,
      credit: data.limit_runs_using_credit ? data.credit : undefined,
      notes: data.notes,
      entries: [],
    };

    const getTags = (
      incidentID: string,
      segments: { [name: string]: string[] },
    ) => {
      return Object.entries(segments).reduce(
        (acc, [segmentName, segmentIDs]) => {
          if (segmentIDs.includes(incidentID)) {
            acc.push(segmentName);
          }
          return acc;
        },
        [] as string[],
      );
    };

    if (data.dataset_id) {
      // We need to grab the dataset and the grouped entries, so we know what incidentIDs
      // to pass down.
      const dataset = datsetData?.datasets?.find(
        (d) => d.id === data.dataset_id,
      );
      if (!dataset) {
        throw new Error("Unreachable: selected dataset not found");
      }
      const groupedEntries = await apiClient.aIGroupDatasetEntriesBySegment({
        id: data.dataset_id,
      });

      requestBody.entries = dataset?.entry_ids.map((entryID) => ({
        incident_id: entryID,
        tags: getTags(entryID, groupedEntries.entries_by_segment),
      }));
    } else if (incidentIDs) {
      requestBody.entries = incidentIDs.map((id) => ({
        incident_id: id,
        tags: segments ? getTags(id, segments) : [],
      }));
    }
    return createBacktest({ requestBody });
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Create backtest"
      onClose={onClose}
      analyticsTrackingId="create-backtest"
      disableQuickClose
      onSubmit={(data: FormData) => {
        onSubmit(data);
      }}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText={"Create backtest"}
          analyticsTrackingId="create-backtest-submit"
          saving={isCreating}
        />
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="investigation_plan_type"
        label="Investigation plan"
        helptext={"Choose what parts of an investigation you want to run."}
        options={investigationPlans.map((p) => ({
          label: p,
          value: p,
        }))}
      />

      {/* If we haven't been passed incident IDs, we need to choose from our list of datasets */}
      {allowChooseDataset && (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="dataset_id"
          label="Dataset"
          isLoading={isDataLoading}
          helptext={"Which dataset should this backtest run against."}
          required
          options={
            datsetData?.datasets?.map((p) => ({
              label: p.name,
              value: p.id,
            })) || []
          }
        />
      )}

      <ToggleV2
        formMethods={formMethods}
        name="limit_runs_using_credit"
        label="Test against a subset of entries first"
      />

      {shouldUseCredit && (
        <InputV2
          type={InputType.Number}
          formMethods={formMethods}
          name="credit"
          label="Credit"
          helptext={
            "How many entries should be processed before pausing and asking a human to review."
          }
        />
      )}

      <TextareaV2
        formMethods={formMethods}
        name="notes"
        label="Notes"
        helptext="Any notes you want to attach to this backtest."
      />
    </Form.Modal>
  );
};
