import {
  BadgeSize,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  StackedList,
} from "@incident-ui";
import React, { useState } from "react";

interface DatasetCollapsibleSectionProps {
  /** Title of the section */
  title: string;
  /** Icon to show next to the title */
  icon: IconEnum;
  /** Content to render in the expanded section */
  content: React.ReactNode;
  /** Optional component to render in the header next to the expand/collapse button */
  accessory?: React.ReactNode;
  /** Text to show when content is empty */
  isEmpty: boolean;
  emptyStateText?: string;
  /** Additional CSS classes */
  className?: string;
}

export const DatasetCollapsibleSection = ({
  title,
  icon,
  content,
  accessory,
  emptyStateText = "No data available",
  isEmpty,
  className,
}: DatasetCollapsibleSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <StackedList className={className}>
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center gap-2">
          <Icon id={icon} size={IconSize.Small} />
          <span className="text-content-primary font-medium">{title}</span>
        </div>
        <div className="flex gap-2">
          {accessory}
          <Button
            title=""
            analyticsTrackingId={null}
            theme={ButtonTheme.Naked}
            size={BadgeSize.Small}
            icon={isExpanded ? IconEnum.Collapse : IconEnum.Expand}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
      </div>
      {isExpanded && (
        <>
          {!isEmpty ? (
            <div>{content}</div>
          ) : (
            <div className="text-content-tertiary p-4">{emptyStateText}</div>
          )}
        </>
      )}
    </StackedList>
  );
};
