import { IncidentVisibilityEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import React, { useState } from "react";
import { IncidentCallsCreateButton } from "src/components/incident-calls/IncidentCallsCreateButton";
import { useIdentity } from "src/contexts/IdentityContext";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { BodyTabs } from "../body/IncidentBody";
import { isIncidentClosedOrResolved } from "../body/Overview";
import { useActions, useIncident } from "../hooks";
import { ProductAccessConditionalComponent } from "../sidebar/IncidentSidebar";
import { ActionCreateModal } from "./actions/ActionCreateModal";
import { ActionsStackedList } from "./ActionsStackedList";
import { CallsStackedList } from "./CallsStackedList";
import { canCreateStream, StreamsStackedList } from "./StreamsStackedList";

type Props = {
  incidentId: string | null;
  onTabChange: (tab: BodyTabs) => void;
};

export const IncidentStackedLists = ({
  incidentId,
  onTabChange,
}: Props): React.ReactElement => {
  const { identity } = useIdentity();
  const { incident } = useIncident(incidentId);
  const { actions, isLoading: actionsLoading } = useActions(incidentId);
  const [creatingAction, setCreatingAction] = useState(false);
  const navigateToModal = useNavigateToModal();

  const {
    data: { streams },
    isLoading: streamsLoading,
  } = useAPI(
    incident ? "streamsList" : null,
    { parentId: incident ? incident.id : "" },
    { fallbackData: { streams: [] } },
  );

  const { data: callData, isLoading: callLoading } = useAPI(
    "incidentCallsGetForLatestForIncident",
    {
      incidentId: incident ? incident.id : "",
    },
    {
      refreshInterval: 15000,
      revalidateOnFocus: true,
    },
  );

  const { data: pastCallNotes, isLoading: pastCallNotesLoading } = useAPI(
    "incidentCallsListCallSessionsWithSummariesForIncident",
    {
      incidentId: incident ? incident.id : "",
    },
    {
      refreshInterval: 15000,
      revalidateOnFocus: true,
    },
  );

  if (!incident) {
    // We don't need to include anything in the loading skeleton here
    return <></>;
  }

  const isClosed = isIncidentClosedOrResolved(incident);

  const showCreateStream =
    streams.length === 0 &&
    canCreateStream({
      streams,
      parentIncident: incident,
      identity,
    });
  const showCreateAction = actions.length === 0;
  const showCreateCall = callData?.incident_call == null;
  const showDoThings =
    (showCreateStream || showCreateAction || showCreateCall) && !isClosed;

  const hasActions = actions.length > 0;
  const hasCall = callData?.incident_call != null;
  const hasStreams = streams.length > 0;
  const showLists = hasActions || hasCall || hasStreams;

  if (!showLists && !showDoThings) {
    return <></>;
  }

  return (
    <ProductAccessConditionalComponent requiredProduct={Product.Response}>
      <div className="flex flex-col gap-6">
        <ActionsStackedList
          actions={actions}
          incident={incident}
          onCreateAction={() => setCreatingAction(true)}
          onTabChange={onTabChange}
          isLoading={actionsLoading}
        />
        <CallsStackedList
          isLoading={callLoading || pastCallNotesLoading}
          callData={callData}
          pastCallNotes={pastCallNotes}
          incident={incident}
        />
        <StreamsStackedList
          parentIncident={incident}
          streams={streams}
          isLoading={streamsLoading}
        />
        {!isClosed && showDoThings && (
          <DoThingsButtons
            incidentId={incident.id}
            onCreateAction={() => setCreatingAction(true)}
            onCreateStream={() => navigateToModal("streams-create")}
            showCreateStream={showCreateStream}
            showCreateAction={showCreateAction}
            showCreateCall={showCreateCall}
          />
        )}
      </div>
      {incident && creatingAction && (
        <ActionCreateModal
          incidentId={incident.id}
          onClose={() => setCreatingAction(false)}
          isPrivateIncident={
            incident.visibility === IncidentVisibilityEnum.Private
          }
        />
      )}
    </ProductAccessConditionalComponent>
  );
};

const DoThingsButtons = ({
  incidentId,
  onCreateAction,
  onCreateStream,
  showCreateAction,
  showCreateStream,
  showCreateCall,
}: {
  incidentId: string;
  showCreateAction: boolean;
  showCreateCall: boolean;
  showCreateStream: boolean;
  onCreateAction: () => void;
  onCreateStream: () => void;
}) => {
  return (
    <div className="flex items-start gap-2 flex-wrap">
      {showCreateAction && (
        <Button
          theme={ButtonTheme.Secondary}
          size={BadgeSize.Medium}
          onClick={onCreateAction}
          icon={IconEnum.Action}
          analyticsTrackingId="create-action"
        >
          Add an action
        </Button>
      )}
      {showCreateCall && (
        <IncidentCallsCreateButton
          incidentId={incidentId}
          theme={ButtonTheme.Secondary}
          size={BadgeSize.Medium}
          icon={IconEnum.Call}
        >
          Start a call
        </IncidentCallsCreateButton>
      )}
      {showCreateStream && (
        <Button
          theme={ButtonTheme.Secondary}
          size={BadgeSize.Medium}
          onClick={onCreateStream}
          icon={IconEnum.GitBranchNew}
          analyticsTrackingId="create-action"
        >
          Create a stream
        </Button>
      )}
    </div>
  );
};
