import { usePylon } from "@bolasim/react-use-pylon";
import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  LoadingBar,
  OrgAwareLink,
  StackedList,
  StackedListItem,
  Toggle,
  Txt,
} from "@incident-ui";
import React, { useState } from "react";
import {
  IncidentLifecycle,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentType,
  ScopeNameEnum,
  Settings,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { UpsellNotice } from "../UpsellNotice";
import { LifecycleCreateModal } from "./LifecycleCreateModal";
import { useLifecyclePageDeps } from "./LifecyclePageWrapper";
import { LifecycleOverview } from "./overview/LifecycleOverview";
import { LIFECYCLE_GRID_CLASSNAME } from "./overview/sections/LifecycleOverviewSection";

export const LifecycleList = (): React.ReactElement => {
  const {
    statuses,
    timestamps,
    isLoading: statusesAndTimestampsLoading,
  } = useLifecyclePageDeps();

  const {
    data: { incident_lifecycles: lifecycles },
    isLoading: lifecyclesLoading,
    error: lifecyclesError,
  } = useAPI("incidentLifecyclesList", undefined, {
    fallbackData: { incident_lifecycles: [] },
  });

  const {
    data: { incident_types: incidentTypes },
    isLoading: typesLoading,
    error: typesError,
  } = useAPI("incidentTypesList", undefined, {
    fallbackData: { incident_types: [] },
  });

  const [showAddLifecycleModal, setShowAddLifecycleModal] = useState(false);
  const { identity } = useIdentity();
  const { settings } = useSettings();

  const maxLifecycles = identity?.feature_gates?.incident_lifeycle_count;
  const orgHasFeatureGateToCreateLifecycle =
    maxLifecycles === undefined || lifecycles.length < maxLifecycles;
  const orgHasMultipleClosedStatuses =
    statuses.filter((x) => x.category === IncidentStatusCategoryEnum.Closed)
      .length > 1;

  const orgCanCreateLifecycle =
    orgHasFeatureGateToCreateLifecycle && !orgHasMultipleClosedStatuses;

  const error = lifecyclesError || typesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (statusesAndTimestampsLoading || lifecyclesLoading || typesLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-6">
      {lifecycles.length === 1 ? (
        <>
          <LifecycleOverview
            isOnlyLifecycle={true}
            lifecycle={lifecycles[0]}
            coreStatuses={statuses}
            timestamps={timestamps}
          />
          <div className={LIFECYCLE_GRID_CLASSNAME}>
            <div />
            <ConfigureAnotherLifecycleOrUpsell
              lifecycles={lifecycles}
              statuses={statuses}
              onClickAdd={() => setShowAddLifecycleModal(true)}
            />
          </div>
        </>
      ) : (
        <>
          <ContentBox className="p-4">
            {settings ? (
              <TriageIncidentsForm settings={settings} />
            ) : (
              <LoadingBar className="h-24" />
            )}
          </ContentBox>
          <StackedList>
            {lifecycles.map((lifecycle) => (
              <li
                key={lifecycle.id}
                className="bg-white hover:bg-surface-secondary group"
              >
                <OrgAwareLink to={`/settings/lifecycle/${lifecycle.id}`}>
                  <StackedListItem
                    title={lifecycle.name}
                    description={
                      <IncidentTypesForLifecycle
                        incidentTypes={incidentTypes}
                        lifecycle={lifecycle}
                      />
                    }
                    descriptionPosition="inline"
                    accessory={
                      <Icon
                        id={IconEnum.ChevronRight}
                        className="text-content-tertiary"
                        size={IconSize.Medium}
                      />
                    }
                  />
                </OrgAwareLink>
              </li>
            ))}
          </StackedList>
          <GatedButton
            theme={ButtonTheme.Secondary}
            onClick={() => setShowAddLifecycleModal(true)}
            analyticsTrackingId="configure-another-lifecycle"
            icon={IconEnum.Add}
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            requiredProduct={Product.Response}
            upgradeRequired={!orgCanCreateLifecycle}
            upgradeRequiredProps={{
              gate: {
                type: "numeric",
                value: maxLifecycles,
                featureNameSingular: "lifecycle",
              },
              featureName: "lifecycles",
            }}
            className="w-fit"
          >
            Add lifecycle
          </GatedButton>
        </>
      )}
      {showAddLifecycleModal && (
        <LifecycleCreateModal
          lifecycles={lifecycles}
          onClose={() => setShowAddLifecycleModal(false)}
        />
      )}
    </div>
  );
};

const IncidentTypesForLifecycle = ({
  lifecycle,
  incidentTypes,
}: {
  lifecycle: IncidentLifecycle;
  incidentTypes: IncidentType[];
}): React.ReactElement => {
  const typesForThisLifecycle = incidentTypes.filter(
    (x) =>
      x.override_incident_lifecycle_id === lifecycle.id ||
      (x.override_incident_lifecycle_id == null && lifecycle.is_default),
  );

  if (typesForThisLifecycle.length === 0) {
    return <Txt lightGrey>Not in use</Txt>;
  }

  if (lifecycle.is_default && typesForThisLifecycle.length > 3) {
    return <Txt lightGrey>All other incidents</Txt>;
  }

  return (
    <div className="flex items-center flex-wrap gap-2">
      {typesForThisLifecycle.map((type) => (
        <Badge
          key={type.id}
          theme={BadgeTheme.Tertiary}
          icon={IconEnum.IncidentType}
          className="border border-white group-hover:border-stroke"
        >
          {type.name}
        </Badge>
      ))}
    </div>
  );
};

const TriageIncidentsForm = ({ settings }: { settings: Settings }) => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { trigger: saveState } = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data) =>
      await apiClient.settingsUpdateManualTriageIncidentsEnabled({
        updateManualTriageIncidentsEnabledRequestBody: {
          manual_triage_incidents_enabled:
            data.misc.manual_triage_incidents_enabled,
        },
      }),
  );

  const { state, setState, hasSaved, saving } = useOptimisticAutoSave({
    saveState: async (data) => {
      await saveState(data);
    },
    initialState: settings,
  });

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <Toggle
          id="manual_triage_incidents_enabled"
          disabled={!canEditSettings}
          align="left"
          label="Allow users to manually create triage incidents"
          on={state.misc.manual_triage_incidents_enabled}
          onToggle={() =>
            setState({
              ...state,
              misc: {
                ...state.misc,
                manual_triage_incidents_enabled:
                  !state.misc.manual_triage_incidents_enabled,
              },
            })
          }
        />
        <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
      </div>
      <Txt className="mt-2 mr-20">
        Triage incidents give you a space to investigate potential issues before
        either accepting them as active incidents, or declining them as false
        positives.
      </Txt>
    </>
  );
};

const ConfigureAnotherLifecycleOrUpsell = ({
  lifecycles,
  statuses,
  onClickAdd,
}: {
  statuses: IncidentStatus[];
  lifecycles: IncidentLifecycle[];
  onClickAdd: () => void;
}): React.ReactElement | null => {
  const { identity } = useIdentity();
  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  const maxLifecycles = identity?.feature_gates?.incident_lifeycle_count;
  const orgHasFeatureGateToCreateLifecycle =
    maxLifecycles === undefined || lifecycles.length < maxLifecycles;

  const orgHasMultipleClosedStatuses =
    statuses.filter((x) => x.category === IncidentStatusCategoryEnum.Closed)
      .length > 1;

  const title = "Multiple lifecycles";
  const description =
    "Use multiple lifecycles to define a set of statuses for different incident types.";
  const articleId = "2830149060";

  // If the org doesn't have the feature gate, show an upsell
  if (!orgHasFeatureGateToCreateLifecycle) {
    return (
      <UpsellNotice
        analyticsId={"multiple-lifecycles-upsell-banner"}
        title={title}
        planName={"Enterprise"}
        description={description}
        articleId={articleId}
      />
    );
  }

  // If the org has multiple closed statuses, don't include anything
  if (orgHasMultipleClosedStatuses) {
    return null;
  }

  // Otherwise, show the button to add another lifecycle
  return (
    <ContentBox className="p-4 flex flex-col gap-4">
      <div>
        <div className="font-semibold text-base mb-1">{title}</div>
        <div className="grow max-w-2xl text-sm !text-slate-700">
          {description}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <GatedButton
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          requiredProduct={Product.Response}
          theme={ButtonTheme.Secondary}
          analyticsTrackingId="configure-another-lifecycle"
          onClick={onClickAdd}
        >
          Configure another lifecycle
        </GatedButton>
        <Button
          onClick={() => showArticle(articleId)}
          theme={ButtonTheme.Link}
          analyticsTrackingId={null}
        >
          Learn more
        </Button>
      </div>
    </ContentBox>
  );
};
