import { AlertRoute } from "@incident-io/api";
import { Banner, BannerTheme, IconEnum } from "@incident-ui";

export const AlertRouteDisabledBanner = ({
  alertRoute,
}: {
  alertRoute: AlertRoute;
}) => {
  const showBanner = !alertRoute.enabled;

  if (!showBanner) return null;

  return (
    <Banner
      theme={BannerTheme.Error}
      className="max-w-[560px] rounded-2 border border-alarmalade-300"
      icon={IconEnum.Exclamation}
      innerClassName="w-full text-left"
    >
      This alert route is inactive and will not run.
    </Banner>
  );
};
