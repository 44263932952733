/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DebriefsSetPlaceholderReuseConferenceRequestBody
 */
export interface DebriefsSetPlaceholderReuseConferenceRequestBody {
    /**
     * ID of the placeholder we're updating
     * @type {string}
     * @memberof DebriefsSetPlaceholderReuseConferenceRequestBody
     */
    placeholder_id: string;
    /**
     * Should we reuse the conference when creating a debrief
     * @type {boolean}
     * @memberof DebriefsSetPlaceholderReuseConferenceRequestBody
     */
    reuse_conference: boolean;
}

export function DebriefsSetPlaceholderReuseConferenceRequestBodyFromJSON(json: any): DebriefsSetPlaceholderReuseConferenceRequestBody {
    return DebriefsSetPlaceholderReuseConferenceRequestBodyFromJSONTyped(json, false);
}

export function DebriefsSetPlaceholderReuseConferenceRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefsSetPlaceholderReuseConferenceRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'placeholder_id': json['placeholder_id'],
        'reuse_conference': json['reuse_conference'],
    };
}

export function DebriefsSetPlaceholderReuseConferenceRequestBodyToJSON(value?: DebriefsSetPlaceholderReuseConferenceRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'placeholder_id': value.placeholder_id,
        'reuse_conference': value.reuse_conference,
    };
}

