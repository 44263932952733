/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Vector
 */
export interface Vector {
    /**
     * Content of the vector
     * @type {string}
     * @memberof Vector
     */
    content: string;
    /**
     * When the vector was created.
     * @type {Date}
     * @memberof Vector
     */
    created_at: Date;
    /**
     * ID of the vector
     * @type {string}
     * @memberof Vector
     */
    id: string;
    /**
     * Name of the vector
     * @type {string}
     * @memberof Vector
     */
    name: string;
    /**
     * Type of the vector
     * @type {string}
     * @memberof Vector
     */
    type: string;
    /**
     * The vector itself
     * @type {Array<number>}
     * @memberof Vector
     */
    vector: Array<number>;
}

export function VectorFromJSON(json: any): Vector {
    return VectorFromJSONTyped(json, false);
}

export function VectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vector {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'vector': json['vector'],
    };
}

export function VectorToJSON(value?: Vector | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'vector': value.vector,
    };
}

