import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { Form } from "@incident-shared/forms";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { Button, Loader } from "@incident-ui";
import { useForm } from "react-hook-form";

import { Expression, useClient } from "../../../../../contexts/ClientContext";
import { useAPI, useAPIMutation } from "../../../../../utils/swr";
import { stripInvalidBindings } from "../../stripInvalidBindings";
import { SetupInfoNumberedList } from "./helpers";
import { AlertSourceSetupProps } from "./types";
import { WaitForCatalogType } from "./WaitForCatalogType";

type JiraFormValues = {
  jiraProjectIDs: string[];
};

export const AlertSourceJiraSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  const { data: catalogTypes, isLoading } = useAPI("catalogListTypes", {});
  const jiraProjectType = catalogTypes?.catalog_types?.find(
    (type) => type.type_name === "JiraCloudProject",
  );

  const formMethods = useForm<JiraFormValues>({
    defaultValues: {
      jiraProjectIDs: alertSourceConfig.routing.jira_project_ids,
    },
  });
  const apiClient = useClient();

  const { trigger } = useAPIMutation(
    "alertsShowSourceConfig",
    {
      id: alertSourceConfig.id,
    },
    async (client, data: JiraFormValues) => {
      return client.alertsUpdateSourceConfig({
        id: alertSourceConfig.id,
        updateSourceConfigRequestBody: {
          ...alertSourceConfig,
          template: stripInvalidBindings({
            expressions: alertSourceConfig.template.expressions.map((e) =>
              expressionToPayload(e as unknown as Expression),
            ),
            title: alertSourceConfig.template.title,
            description: alertSourceConfig.template.description,
            bindings: alertSourceConfig.template.bindings,
          }),
          routing: {
            jira_project_ids: data.jiraProjectIDs,
          },
        },
      });
    },
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Form.Root formMethods={formMethods} onSubmit={trigger}>
      <SetupInfoNumberedList>
        <p>
          Once this source has been saved, we&rsquo;ll automatically start
          receiving Jira tickets as alerts.
        </p>
        <p>
          <div className={"flex flex-col w-full items-start"}>
            Select the Jira projects you&rsquo;d like to connect to this alert
            source.
            {jiraProjectType ? (
              <DynamicMultiSelectV2
                className={"w-full"}
                loadOptions={getCatalogTypeaheadOptions({
                  apiClient,
                  catalogTypeID: jiraProjectType.id,
                })}
                hydrateOptions={hydrateInitialCatalogOptions({
                  apiClient,
                  catalogTypeID: jiraProjectType.id,
                })}
                name="jiraProjectIDs"
                formMethods={formMethods}
              />
            ) : (
              <WaitForCatalogType typeLabel="projects from Jira" />
            )}
          </div>
        </p>
        <p>
          Go to your Jira dashboard and create a ticket in your chosen project
          to confirm the connection. The ticket should appear as an alert to the
          right.
        </p>
      </SetupInfoNumberedList>
      <Button
        type="submit"
        analyticsTrackingId={"alert-source-jira-setup-info-save"}
      >
        Save
      </Button>
    </Form.Root>
  );
};
