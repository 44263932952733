import { ScopeNameEnum, SuggestionConfig } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { FormHelpText } from "@incident-shared/forms/v2/helpers";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import { ContentBox, IconEnum, SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useOptimisticAutoSave } from "src/hooks/useOptimisticAutoSave";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
} from "../../ai/AIFeatureConfigurationDrawer";
import {
  AIPrivacyButton,
  AISuggestionFeatureDrawerProps,
  ToggleSuggestionButton,
} from "./SuggestionAICards";

export const SuggestedNamesDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: suggestConfigResp } = useAPI(
    "incidentSuggestionsGetSuggestionConfig",
    undefined,
  );

  const showToast = useToast();

  const { trigger: toggleSuggestionFeature } = useAPIMutation(
    "incidentSuggestionsGetSuggestionConfig",
    undefined,
    async (apiClient, { enabled }) =>
      await apiClient.incidentSuggestionsUpdateSuggestionConfig({
        updateSuggestionConfigRequestBody: {
          suggest_names_enabled: enabled,
        },
      }),
    {
      onSuccess: () => {
        showToast(SharedToasts.SETTINGS_SAVED);
      },
      showErrorToast: "Toggling suggested names",
    },
  );

  const enabled = suggestConfigResp?.config.suggest_names_enabled;

  return (
    <AIFeatureConfigurationDrawer
      loading={!suggestConfigResp}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleSuggestionButton
                enabled={!!enabled}
                disableModalTitle="Disable suggestion"
                disableModalContent="This means we'll stop suggesting incident names."
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                onEnable={() => toggleSuggestionFeature({ enabled: true })}
                onDisable={() => toggleSuggestionFeature({ enabled: false })}
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Smart incident naming"
              description="When an incident's nature changes significantly, we'll suggest new names that better describe what's actually happening."
              icon={IconEnum.Bulb}
            />
            <AIProductMarketingCard
              title="Prevent naming drift"
              description="Get alerts when an incident's scope has evolved beyond its original name, helping teams find and track the right issues."
              icon={IconEnum.Clock}
            />
          </AIProductMarketingCards>
          {suggestConfigResp?.config && (
            <AutoRenameIncidentsToggle config={suggestConfigResp.config} />
          )}
        </>
      }
      width="medium"
    />
  );
};

const AutoRenameIncidentsToggle = ({
  config,
}: {
  config: SuggestionConfig;
}) => {
  const showToast = useToast();
  const { trigger: toggleAutoNameIncidents } = useAPIMutation(
    "incidentSuggestionsGetSuggestionConfig",
    undefined,
    async (apiClient, { enabled }) =>
      await apiClient.incidentSuggestionsUpdateSuggestionConfig({
        updateSuggestionConfigRequestBody: {
          auto_rename_incidents: enabled,
        },
      }),
    {
      onSuccess: () => {
        showToast(SharedToasts.SETTINGS_SAVED);
      },
      showErrorToast: "Toggling auto-renaming incidents",
    },
  );

  const {
    setState,
    saving,
    state: renameEnabled,
  } = useOptimisticAutoSave<boolean>({
    initialState: config.suggest_names_enabled && config.auto_rename_incidents,
    saveState: async (enabled: boolean) => {
      await toggleAutoNameIncidents({ enabled });
    },
  });

  const nameSuggestionsDisabled = !config.suggest_names_enabled;

  return (
    <ContentBox className={tcx("p-3 flex-col gap-2")}>
      <GatedToggle
        id={`auto-rename-incidents`}
        on={config.auto_rename_incidents}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
        requiredProduct={Product.Response}
        disabled={nameSuggestionsDisabled}
        isDisabledTooltipContent="Enable incident naming suggestions to use this feature."
        onToggle={() => {
          setState(!renameEnabled);
        }}
        isLoading={saving}
        label={
          <span
            className={tcx(nameSuggestionsDisabled && "text-content-tertiary")}
          >
            Automatically rename incidents using suggestions
          </span>
        }
      />
      <FormHelpText
        className={tcx(nameSuggestionsDisabled && "text-content-tertiary")}
      >
        {`When enabled, we'll automatically rename incidents using suggestions.`}
      </FormHelpText>
    </ContentBox>
  );
};
