import { Modal, ModalContent } from "@incident-ui";
import React from "react";

import AppInstallQr from "../../alerts/images/app-install-qr-code.svg";
import AppIcon from "../../alerts/images/orange-app-icon.png";

export const InstallAppModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  return (
    <Modal
      isOpen
      title="Download the on-call app"
      analyticsTrackingId="on-call-app-install-modal"
      onClose={onClose}
    >
      <ModalContent>
        <div className="flex flex-col items-center">
          <img
            className="h-[72px] w-[72px] mb-6"
            src={AppIcon}
            alt="The icon of incident.io's On-call app"
          />
          <h2 className="text-xl font-semibold text-content-primary mb-2 normal-nums">
            On-call, on-the-go
          </h2>
          <p className="text-sm text-content-secondary text-center mb-8 text-balance">
            Download the on call mobile app to receive and acknowledge alerts
            natively on your device
          </p>
          <div className="bg-surface-secondary rounded-lg p-4">
            <img
              className="h-[210px] w-[210px]"
              src={AppInstallQr}
              alt="The QR code to install the incident.io On-Call app"
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
