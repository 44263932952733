import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Callout,
  CalloutTheme,
  IconEnum,
  Loader,
  OrgAwareLink,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import React from "react";
import { useAPI } from "src/utils/swr";

import { AlertAttributesForm } from "./AlertAttributesForm";

export const AlertAttributesCreateEditDrawer = ({
  title = "Alert attributes",
  onClose,
}: {
  title?: string;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data,
    isLoading: schemaLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);
  if (schemaError) {
    throw schemaError;
  }

  if (!data || schemaLoading) {
    return <Loader />;
  }

  return (
    <Drawer onClose={onClose} warnWhenDirty width="medium">
      <DrawerContents className="font-normal">
        <DrawerTitle
          icon={IconEnum.Tag}
          title={title}
          color={ColorPaletteEnum.Slate}
          onClose={onClose}
          closeIcon={IconEnum.Close}
          sticky
        />
        <DrawerBody>
          <div className={"w-full flex justify-between items-start"}>
            <div className="text-sm">
              Attributes capture context like Team, Service, or Environment
              across all your alerts. Configure them in alert sources to drive
              escalation paths and custom fields in your routes.
            </div>
          </div>
          <Callout
            theme={CalloutTheme.Info}
            iconOverride={IconEnum.Announcement}
          >
            If you want to track the priority of an alert, you can{" "}
            <OrgAwareLink
              className="underline"
              to="/alerts/configuration/priorities"
            >
              configure your alert priorities
            </OrgAwareLink>
            . Alert priority can be used in both alert routes and escalation
            paths to customize how users are notified.
          </Callout>
          <AlertAttributesForm
            alertSchema={data.alert_schema}
            onClose={onClose}
          />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};
