import {
  SlackEnterpriseSettings,
  SlackInfoUserSlackRoleEnum as SlackRoleEnum,
} from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";

import { SettingsSubHeading } from "../SettingsSubHeading";

export const SlackEnterpriseGridWorkspaceViewSection = ({
  settings,
}: {
  settings: SlackEnterpriseSettings;
}) => {
  const { identity } = useIdentity();
  const slackInfo = identity.slack_info;
  if (!slackInfo) {
    // This is impossible!
    return null;
  }

  const addWorkspaceBlockedReason =
    slackInfo.enterprise_install_url === undefined
      ? "You cannot add more workspaces to incident.io. Please contact your Customer Success Manager if you need to add additional workspaces."
      : ![SlackRoleEnum.Admin, SlackRoleEnum.Owner].includes(
          slackInfo.user_slack_role,
        )
      ? "Only Slack Admin and Owners can add more Slack workspaces to incident.io"
      : null;
  const canAddWorkspace = addWorkspaceBlockedReason == null;

  return (
    <>
      <SettingsSubHeading
        title="Workspace configuration"
        titleHeadingLevel={2}
        className="!mb-2"
        explanation={`The Slack workspaces we have access to within your Slack Enterprise Grid organization.`}
        accessory={
          <Tooltip
            content={
              canAddWorkspace
                ? "Navigate to Slack Grid settings to add more Slack workspaces to incident.io"
                : addWorkspaceBlockedReason
            }
          >
            <Button
              theme={ButtonTheme.Secondary}
              href={
                canAddWorkspace
                  ? `https://app.slack.com/manage/${settings.slack_enterprise_id}/integrations/profile/${settings.slack_app_id}/workspaces/add`
                  : undefined
              }
              openInNewTab
              icon={IconEnum.Add}
              iconProps={{ size: IconSize.Medium }}
              analyticsTrackingId="settings-slack-enterprise-grid-add-workspace"
              disabled={!canAddWorkspace}
            >
              Add workspace
            </Button>
          </Tooltip>
        }
      />
      <StackedList className="mb-6">
        {settings.slack_team_configs.map((slackTeamConfig) => (
          <div
            className="w-full py-2 px-4 overflow-hidden"
            key={slackTeamConfig.slack_team_id}
          >
            <div className="md:flex md:items-center">
              <div className="grow my-1 space-y-1 overflow-hidden">
                <div className="flex shrink-0 font-semibold text-sm items-center gap-1">
                  {slackTeamConfig.icon_url ? (
                    <img
                      src={slackTeamConfig.icon_url}
                      className="w-6 h-6 rounded-0.5 mr-1"
                    />
                  ) : (
                    <Icon
                      id={IconEnum.SlackTeam}
                      size={IconSize.Large}
                      className="mr-1 text-content-tertiary bg-surface-secondary rounded-0.5 border border-stroke"
                    />
                  )}
                  <span>{slackTeamConfig.name}</span>
                </div>
              </div>
              <div className="flex flex-row items-center">
                {slackTeamConfig.enabled ? (
                  <></>
                ) : (
                  <>
                    <Icon
                      className="text-content-tertiary mr-0.5"
                      size={IconSize.Medium}
                      id={IconEnum.Warning}
                    />
                    <p className="text-sm text-content-tertiary">
                      App is not enabled, please{" "}
                      <Button
                        href={`mailto:help@incident.io?subject=${encodeURIComponent(
                          "Adding incident.io to " + slackTeamConfig.name,
                        )}`}
                        analyticsTrackingId="settings-slack-enterprise-grid-request"
                        theme={ButtonTheme.Link}
                      >
                        contact us
                      </Button>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </StackedList>
    </>
  );
};
