import {
  CustomDashboard,
  InsightsDateRange,
  InsightsDateRangeModeEnum,
  InsightsUpdateDashboardRequestBodyColorEnum,
  InsightsUpdateDashboardRequestBodyIconEnum,
  PanelPayload,
} from "@incident-io/api";
import {
  ExtendedFormFieldValue,
  FiltersContextProvider,
} from "@incident-shared/filters";
import { Form } from "@incident-shared/forms";
import {
  DateRangePickerMode,
  QUICK_SELECT_INTERVAL_CONFIG,
} from "@incident-shared/forms/v1/DateRangePicker";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import _ from "lodash";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";
import { v4 as uuid } from "uuid";

import {
  dateRangeToFormState,
  formFieldValueToInsightsFilter,
  insightsFilterToFormFieldValue,
  panelToFormData,
} from "../common/marshall";
import { useGetPanelFilterFields } from "../common/useGetPanelFilterFields";
import {
  DashboardViewMode,
  EditDashboardFormData,
  InsightsContext,
  InsightsDateRangeState,
  PanelFormData,
} from "../common/useInsightsContext";
import { PanelsFormStateContextProvider } from "./PanelsFormStateContext";

// InsightsEditDashboardProvider gives the contents of a workflow form access to the various mutations
// and view state that it needs to avoid prop-drilling hell.
export const InsightsEditDashboardProvider = ({
  dashboard,
  children,
}: {
  dashboard: CustomDashboard;
  children: React.ReactNode;
}): JSX.Element => {
  const navigate = useOrgAwareNavigate();
  const formMethods = useForm<EditDashboardFormData>({
    defaultValues: {
      name: dashboard.name,
      icon: dashboard.icon,
      color: dashboard.color,
      dateRange: dateRangeToFormState(dashboard.date_range),
      filters: _.map(dashboard.filters, insightsFilterToFormFieldValue),
      panels: dashboard.panels.map(panelToFormData),
    },
  });
  const { watch, setValue } = formMethods;

  const [filters, dateRange, panels] = watch([
    "filters",
    "dateRange",
    "panels",
  ]);

  const setDateRange = useCallback(
    (partial: Partial<InsightsDateRangeState>) => {
      const newRange = { ...dateRange, ...partial };
      setValue<"dateRange">("dateRange", newRange);
    },
    [dateRange, setValue],
  );

  const setSelectedFilters = (newFilters: ExtendedFormFieldValue[]) => {
    setValue<"filters">("filters", newFilters);
  };

  const refetchDashboard = useRevalidate([
    "insightsListCustomDashboards",
    "insightsShowCustomDashboard",
  ]);

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "insightsListCustomDashboards",
    undefined,
    async (apiClient, data: EditDashboardFormData) => {
      await apiClient.insightsUpdateDashboard({
        id: dashboard.id,
        updateDashboardRequestBody: {
          name: data.name,
          icon: data.icon as unknown as InsightsUpdateDashboardRequestBodyIconEnum,
          color:
            data.color as unknown as InsightsUpdateDashboardRequestBodyColorEnum,
          date_range: dateRangeToPayload(data.dateRange),
          filters: _.map(data.filters, formFieldValueToInsightsFilter),
          panels: data.panels.map(panelFormDataToPayload),
        },
      });
    },
    {
      onSuccess: () => {
        navigate(`/insights/dashboards/custom/${dashboard.id}`);
        refetchDashboard();
      },
      showErrorToast: "Couldn't save dashboard",
    },
  );

  const { filterFields, filtersLoading, filtersError } =
    useGetPanelFilterFields({
      panels,
    });

  return (
    <InsightsContext.Provider
      value={{
        dateRange,
        setDateRange,
        saving,
        filtersLoading,
        filtersError: filtersError,
        viewMode: DashboardViewMode.EditDashboard,
      }}
    >
      <FiltersContextProvider
        filters={filters}
        setFilters={setSelectedFilters}
        availableFilterFields={filterFields}
        filtersLoading={filtersLoading}
        kind="insights"
      >
        <Form.Root fullHeight formMethods={formMethods} onSubmit={onSubmit}>
          <PanelsFormStateContextProvider>
            {children}
          </PanelsFormStateContextProvider>
        </Form.Root>
      </FiltersContextProvider>
    </InsightsContext.Provider>
  );
};

export const dateRangeToPayload = (
  state: InsightsDateRangeState,
): InsightsDateRange => {
  const dateRange: Partial<InsightsDateRange> = {
    compare_previous_period: state.is_comparison,
    aggregation: state.aggregation,
  };

  switch (state.range.mode) {
    case DateRangePickerMode.Absolute:
      dateRange.mode = InsightsDateRangeModeEnum.Absolute;
      dateRange.absolute = {
        from: new Date(state.range.absolute.from),
        to: new Date(state.range.absolute.to),
      };
      break;
    case DateRangePickerMode.Relative:
      dateRange.mode = InsightsDateRangeModeEnum.Relative;
      dateRange.relative = {
        interval: state.range.relative.interval,
        number_of_intervals: state.range.relative.numberOfIntervals,
        include_this_interval: state.range.relative.includeThisInterval,
      };
      break;
    case DateRangePickerMode.QuickSelect: {
      dateRange.mode = InsightsDateRangeModeEnum.Relative;
      const quickSelectState =
        QUICK_SELECT_INTERVAL_CONFIG[state.range.quick_select].state;
      dateRange.relative = {
        interval: quickSelectState.interval,
        number_of_intervals: quickSelectState.numberOfIntervals,
        include_this_interval: quickSelectState.includeThisInterval,
      };
      break;
    }
  }
  return dateRange as InsightsDateRange;
};

const panelFormDataToPayload = (panel: PanelFormData): PanelPayload => {
  const variables = {};
  Object.entries(panel.variables_data).forEach(([key, value]) => {
    if (!Array.isArray(value)) {
      variables[key] = value?.value;
    } else {
      variables[key] = value.map((v) => v.value);
    }
  });

  return {
    key: panel.key || uuid(),
    name: panel.name,
    text: panel.text,
    explo_dashboard: panel.explo_dashboard,
    variables: variables,
    filters: panel.filters.map(formFieldValueToInsightsFilter),
  };
};
