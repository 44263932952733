import { ExploDashboardInfo, PanelVariable } from "@incident-io/api";
import {
  InsightsDateRangeAggregationEnum,
  PanelFilterContextsEnum,
} from "@incident-io/api/models";
import { ExploDashboard } from "@incident-shared/explo/ExploDashboard";
import { useGetContextForFilter } from "@incident-shared/filters";
import { dateRangePickerStateToTimestamps } from "@incident-shared/forms/v1/DateRangePicker";
import { calculatePreviousPeriod } from "@incident-shared/insights/calculatePreviousPeriod";
import { GenericErrorMessage } from "@incident-ui";
import { Loader } from "@incident-ui/Loader/Loader";
import { useEffect, useRef } from "react";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import {
  useQueryParams,
  useSafeUpdateQueryString,
} from "src/utils/query-params";

import { TrendsTiles } from "../trends/TrendsTiles";
import { ComparisonModeHeading, PanelWrapper } from "./PanelWrapper";
import { useDashboardAndPanelFilters } from "./useDashboardAndPanelFilters";
import {
  PanelVariableFormData,
  useInsightsContext,
} from "./useInsightsContext";

export const ExploDashboardPanel = ({
  exploDashboardInfo,
  panelName,
  panelIdx,
  variableData,
  variables,
}: {
  exploDashboardInfo: ExploDashboardInfo;
  panelName: string;
  panelIdx: number;
  panelFilterContexts: PanelFilterContextsEnum[];
  variableData: PanelVariableFormData;
  variables: PanelVariable[];
}) => {
  const analytics = useAnalytics();
  const queryParams = useQueryParams();
  const setURLParams = useSafeUpdateQueryString();
  const self = useRef<HTMLDivElement | null>(null);

  const {
    title,
    description,
    initial_height: initialHeight,
    explo_dashboard_id: exploDashboardID,
  } = exploDashboardInfo;

  useEffect(() => {
    const scrollTo = queryParams.get("scroll_to");
    // We want to be able to link to particular panels, but we can't depend
    // on #fragment links as the page doesn't render immediately.
    if (!!self.current && !!scrollTo) {
      if (scrollTo === exploDashboardID) {
        self.current.scrollIntoView();
      }
    }
  }, [self, queryParams, exploDashboardID]);

  const { dateRange } = useInsightsContext();

  const isComparisonMode = dateRange.is_comparison;

  const { from: startDate, to: endDate } = dateRangePickerStateToTimestamps(
    dateRange.range,
  );
  const { prevEndDate, prevStartDate } = calculatePreviousPeriod(
    startDate,
    endDate,
  );

  // For now going to use an offset of '+00:00' to make sure we don't do any
  // timezone calculations within Explo in order to show everything in UTC
  const timeZoneOffset = "+00:00";

  const filters = useDashboardAndPanelFilters(panelIdx);

  const {
    isLoading: contextLoading,
    error: contextError,
    getContextForFilter,
  } = useGetContextForFilter();

  if (contextLoading || !getContextForFilter) {
    return <Loader />;
  }
  if (contextError) {
    return <GenericErrorMessage error={contextError} />;
  }

  const filterJSON = JSON.stringify(filters);

  const dashboardProps = {
    dashboardEmbedID: exploDashboardID,
    initialHeight,
    dashboardVariables: {
      filter_json: filterJSON,
      timezone_offset: timeZoneOffset, // used to truncate dates properly
      // Strip the timezone information from the datetime as we're only dealing with UTC
      start_date: stripTimezone(startDate),
      end_date: stripTimezone(endDate),
      date_aggregation: DateAggregationMap[dateRange.aggregation],
      ...exploVariablesFrom(variables, variableData),
    },
  };

  const redirectToComparisonView = (trendID: string, sentiment: string) => {
    const newParams = new URLSearchParams(queryParams);
    newParams.set("compare_previous_period", "true");
    setURLParams(newParams.toString());
    analytics?.track(`trend-tile.clicked`, {
      location: "panel",
      trendID: trendID,
      sentiment,
    });
  };

  return (
    <PanelWrapper
      title={title}
      description={description}
      panelIdx={panelIdx}
      variables={variables}
      trends={
        <TrendsTiles
          dateRange={dateRange.range}
          filters={filters.length > 0 ? filterJSON : undefined}
          panelID={panelName}
          panelVariables={dashboardProps.dashboardVariables}
          onTileClick={isComparisonMode ? undefined : redirectToComparisonView}
          smaller={true}
          reverse={true}
        />
      }
    >
      <div className="flex-1 flex-col gap-3 w-auto">
        {/* Date range badges */}
        {isComparisonMode && (
          <ComparisonModeHeading startDate={startDate} endDate={endDate} />
        )}
        {isComparisonMode ? (
          <div className="flex flex-row gap-4 w-full">
            {/* The two side by side dashboards */}
            <div className="w-1/2">
              <ExploDashboard
                {...dashboardProps}
                dashboardVariables={{
                  ...dashboardProps.dashboardVariables,
                  // Strip the timezone information from the datetime as we're only dealing with UTC
                  start_date: stripTimezone(prevStartDate),
                  end_date: stripTimezone(prevEndDate),
                }}
              />
            </div>
            <div className="w-1/2">
              <ExploDashboard {...dashboardProps} />
            </div>
          </div>
        ) : (
          <ExploDashboard {...dashboardProps} />
        )}
      </div>
    </PanelWrapper>
  );
};

// Our explo panels are backed by SQL which is templated with variables.
// These variables have particular names which we need to match.
// This function builds a map of these variables under the correct names.
//
// This logic is replicated in the backend (transformPanelVarsForPanel).
// Any changes here _must_ be reflected there in order to not break reporting.
export const exploVariablesFrom = (
  variables: PanelVariable[],
  variableData: PanelVariableFormData,
): Record<string, string> => {
  const out = {};

  variables.forEach((variable) => {
    // The form data holds the actual values of our panel variables
    const formData = variableData[variable.form_field.key];
    if (!formData) {
      return;
    }
    // The explo mapping hold the keys to be used in the map sent to Explo
    const mapping = variable.form_field.explo_mapping;
    if (!mapping) {
      return;
    }

    // HACK: We hard code this edge case as currently we do not properly support multi-select
    // within custom dashboards in the API. They do work in core dashboards as we never
    // save those values to the DB.
    if (
      variable.form_field.explo_mapping?.label_key ===
        "selected_escalation_targets" &&
      !Array.isArray(formData)
    ) {
      out[mapping.value_key] = [formData.value];
      return;
    }

    if (Array.isArray(formData)) {
      const values = formData?.map((selected) => selected.value);
      out[mapping.value_key] = JSON.stringify(values);
      return;
    }
    out[mapping.value_key] = formData.value;
    // If there's a second value key, the value contains two values,
    // separated by a pipe character. Separate them and add them to the map
    if (mapping.pipe_separated_value_key != null) {
      const splitValue = formData.value.split("|");
      if (splitValue.length > 1) {
        out[mapping.value_key] = splitValue[0];
        out[mapping.pipe_separated_value_key] = splitValue[1];
      }
    }
    // If we need to pass a label to explo, do so
    if (mapping.label_key != null) {
      out[mapping.label_key] = formData.label;
    }
  });

  return out;
};

export const stripTimezone = (dateTime: string): string =>
  dateTime.replace(/(\+|-)[0-9][0-9]:[0-9][0-9]/, "Z");

const DateAggregationMap: {
  [key in InsightsDateRangeAggregationEnum]: string;
} = {
  [InsightsDateRangeAggregationEnum.Days]: "DATE_DAY",
  [InsightsDateRangeAggregationEnum.Weeks]: "DATE_WEEK",
  [InsightsDateRangeAggregationEnum.Months]: "DATE_MONTH",
  [InsightsDateRangeAggregationEnum.Quarters]: "DATE_QUARTER",
};
