import { useIsMainSidebarExpanded } from "@incident-shared/layout/MainSidebar/MainSidebar";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { BadgeSize } from "@incident-ui/Badge/Badge";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { IconBadge } from "@incident-ui/IconBadge/IconBadge";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { motion } from "framer-motion";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";
import overlayStyles from "src/components/insights/assistant/AssistantOverlay.module.scss";
import { PortalProvider } from "src/contexts/PortalContext";
import { tcx } from "src/utils/tailwind-classes";
import { useResize } from "src/utils/use-resize";

import {
  DrawerFormStateProvider,
  useDrawerFormState,
} from "./DrawerFormStateContext";
import { useDrawer } from "./DrawerProvider";

export type DrawerWidth = "extra-small" | "small" | "medium" | "large" | "full";

export type DrawerProps = {
  children: React.ReactNode;
  onClose: () => void;
  // isInBackground tells a drawer to scale and shift itself over to appear
  // nicely underneath a second one
  isInBackground?: boolean;
  className?: string;
  // You'll need useWarnOnDrawerClose if you want this to do anything!
  // see DrawerFormStateContext.tsx for an example.
  warnWhenDirty?: boolean;
  width: DrawerWidth;
  side?: "left" | "right";
};

export enum Mode {
  Create = "create",
  Edit = "edit",
  Duplicate = "duplicate",
  Closed = "closed",
}

/**
 * Drawer renders a drawer that slides in from either side of the screen.
 *
 * Some things to note here:
 * ### Exit animations
 * If you're using `useState` to track whether this should render or not,
 * you'll want to wrap it in `AnimatePresence`, to get a nice exit animation:
 *
 * ```tsx
 * <AnimatePresence>{isOpen && (<MyDrawer />)}</AnimatePresence>
 * ```
 *
 * ### Routing
 * If a drawer has its own route, you should use this pattern to make your drawer routable:
 *
 * ```tsx
 * <Routes>
 *   <Route path="top-thing" element={<TopThingWithDrawers />} />
 *     <Route path="create" element={<CreateThing />} />
 *     <Route path=":id" element={<EditThing />} />
 *   </Route>
 * </Routes>
 * ```
 * This means that react-router will always render `TopThingWithDrawers`, even
 * while your create/edit drawer is open on top.
 *
 * What's that outlet thing? Let's take a look:
 *
 * ```tsx
 * const TopThingWithDrawers = () => {
 *   const drawer = useOutlet();
 *
 *   return (
 *     <>
 *      <AnimatePresence>{drawer}</AnimatePresence>
 *      <TopThing />
 *     </>
 *   )
 * }
 * ```
 *
 * `useOutlet` is a react-router hook which returns whatever route is rendered
 * _inside_ the current route - i.e. it will be `<CreateThing/>` or
 * `<EditThing />` (or null) in this case.
 *
 * Wrapping that in `<AnimatePresence>` means we get a nice exit animation
 * when navigating away!
 */
export const Drawer = (props: DrawerProps) => {
  const { pageDrawerRef } = useDrawer();

  // HACK HACK HACK: If a drawer is rendered by react-router, without waiting
  // for any data to be loaded, it will get mounted on the initial render, when
  // `pageDrawerRef` has not yet been set. This causes the drawer to not render
  // at all.
  //
  // This hook forces the drawer to re-render when `pageDrawerRef` is set.
  const [_, setReady] = React.useState(false);
  useLayoutEffect(() => {
    if (pageDrawerRef.current != null) setReady(true);
  }, [pageDrawerRef]);

  return (
    pageDrawerRef.current &&
    createPortal(
      <DrawerFormStateProvider warnWhenDirty={!!props.warnWhenDirty}>
        <OverlayDrawer {...props} portalRef={pageDrawerRef} />
      </DrawerFormStateProvider>,
      pageDrawerRef.current,
    )
  );
};

export const DrawerContents = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={tcx("flex flex-col h-full overflow-y-auto", className)}>
      {children}
    </div>
  );
};

export type DrawerTitleProps = {
  title: React.ReactNode;
  titleAccessory?: React.ReactNode;
  subtitle?: string;
  secondaryAccessory?: React.ReactNode;
  footer?: React.ReactNode;
  onClose: () => void;
  closeIcon?: IconEnum;
  sticky?: boolean;
  compact?: boolean;
  icon?: IconEnum;
  color?: ColorPaletteEnum;
  iconClassName?: string;
  className?: string;
  hexColor?: string;
  theme?: DrawerTitleTheme;
  invertIcon?: boolean;
};

export enum DrawerTitleTheme {
  Default = "default",
  Bordered = "bordered",
}

export const DrawerTitle = ({
  title,
  titleAccessory,
  secondaryAccessory,
  subtitle,
  footer,
  theme = DrawerTitleTheme.Default,
  onClose,
  closeIcon = IconEnum.Close,
  sticky,
  compact = false,
  icon,
  color,
  className,
  iconClassName,
  invertIcon = true,
  hexColor,
}: DrawerTitleProps) => {
  const { isDirty, warnWhenDirty } = useDrawerFormState();
  const onCloseWithWarn = getOnCloseWithWarning(onClose);

  const bgPalette = getColorPalette(color || ColorPaletteEnum.Slate);

  const LHAccessory: () => React.ReactElement | null = () =>
    icon || titleAccessory ? (
      <div className="flex items-center gap-1">
        {icon && (
          <IconBadge
            icon={icon}
            size={IconSize.Small}
            color={color || ColorPaletteEnum.Red}
            className={iconClassName}
            invert={invertIcon}
          />
        )}
        {titleAccessory}
      </div>
    ) : null;

  const RHAccessory = () => (
    <div className="flex gap-3 items-center">
      {secondaryAccessory}
      {onClose && (
        <Button
          theme={ButtonTheme.Secondary}
          icon={closeIcon}
          onClick={warnWhenDirty ? () => onCloseWithWarn(isDirty) : onClose}
          title="close"
          analyticsTrackingId={"close-drawer"}
          size={BadgeSize.Medium}
        />
      )}
    </div>
  );

  const withBackgroundColor = !hexColor && theme === DrawerTitleTheme.Default;
  const borderClasses =
    theme === DrawerTitleTheme.Bordered ? "border-b border-stroke" : "";

  if (compact) {
    return (
      <div
        className={tcx(
          "p-1 w-full text-content-primary",
          borderClasses,
          className,
        )}
      >
        <div
          className={tcx(
            "p-5  w-full flex items-start gap-3",
            withBackgroundColor && bgPalette.background,
            theme === DrawerTitleTheme.Default && "rounded-1",
            {
              "z-20 sticky top-0": sticky,
            },
          )}
          style={hexColor ? { backgroundColor: hexColor } : undefined}
        >
          <LHAccessory />
          <div className="flex flex-col gap-2 grow">
            <div className="flex items-center justify-between gap-3">
              <div className="font-semibold text-base-bold  grow">{title}</div>
              <RHAccessory />
            </div>
            {subtitle && <div className="text-xs-normal">{subtitle}</div>}
            {footer}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={tcx(
        "p-1 w-full text-content-primary",
        borderClasses,
        className,
      )}
    >
      <div
        className={tcx(
          theme === DrawerTitleTheme.Default && "rounded-1",
          withBackgroundColor && bgPalette.background,
          "flex flex-col gap-6 p-5 w-full",
          { "z-20 sticky top-0": sticky },
        )}
        style={{ backgroundColor: hexColor }}
      >
        <div className="flex items-center gap-1 justify-between">
          <div>
            <LHAccessory />
          </div>
          <RHAccessory />
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-2xl-bold">{title}</div>
          {subtitle && <div className="text-sm-normal">{subtitle}</div>}
        </div>
        {footer}
      </div>
    </div>
  );
};

export const DrawerBody = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={tcx("flex flex-col gap-6 p-6 grow", className)}>
      {children}
    </div>
  );
};

export const DrawerFooter = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={tcx("px-6 py-5 border-t border-stroke", className)}>
      {children}
    </div>
  );
};

export const getOnCloseWithWarning = (onClose: () => void) => {
  return (isDirty: boolean) => {
    if (isDirty) {
      if (
        window.confirm(
          "You have unsaved changes, are you sure you want to navigate away?",
        )
      ) {
        onClose();
      }
    } else {
      onClose();
    }
  };
};

const OverlayDrawer = ({
  onClose,
  children,
  isInBackground,
  className,
  warnWhenDirty,
  width = "large",
  side = "right",
  portalRef,
}: DrawerProps & { portalRef: React.RefObject<HTMLDivElement> }) => {
  const { isDirty } = useDrawerFormState();
  const sidebarIsExpanded = useIsMainSidebarExpanded();

  // We measure the rendered size of the drawer to animate it in and out.
  const drawerRef = useRef<HTMLDivElement>(null);
  const { width: drawerWidth } = useResize(drawerRef, {
    width: width === "full" ? 1800 : 950,
    height: window.innerHeight,
    left: 0,
  });

  const onCloseWithWarn = warnWhenDirty
    ? getOnCloseWithWarning(onClose)
    : onClose;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onCloseWithWarn(isDirty);
      }
    };
    window.addEventListener("keydown", handleKeyDown, true);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [isDirty, onCloseWithWarn]);

  const widthClasses = classesForWidth(width, side, sidebarIsExpanded);

  return (
    <>
      {/* Darkening overlay over whole screen that is click-to-close */}
      <motion.div
        onClick={() => onCloseWithWarn(isDirty)}
        className={tcx(
          "fixed bg-[#4f5c6c] right-0 top-0 bottom-0 left-0 bg-opacity-40 !m-0",
        )}
        variants={{
          open: { zIndex: 33, opacity: 1 },
          openInBackground: { zIndex: 31, opacity: 1 },
        }}
        initial={{ opacity: 0 }}
        animate={isInBackground ? "openInBackground" : "open"}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      />

      {/* Main drawer */}
      {/*
          This is styled as a full height motion.div fixed to the right of the screen
          with a smaller height aside as a child, to create 16px of margin on the
          top/bottom/right sides.
          */}
      <PortalProvider portalRef={portalRef}>
        <motion.div
          ref={drawerRef}
          initial={{
            x: side === "right" ? `${drawerWidth}px` : `-${drawerWidth}px`,
            zIndex: 32,
          }}
          variants={{
            open: { x: 0, zIndex: 34, z: 2 },
            openInBackground: { x: -32, zIndex: 32, z: 1, scale: 0.96 },
          }}
          animate={isInBackground ? "openInBackground" : "open"}
          // Exit at the highest z-Index, so we can always see the drawer sliding to reveal the background drawer from underneath.
          exit={{
            x: side === "right" ? `${drawerWidth}px` : `-${drawerWidth}px`,
            zIndex: 35,
          }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className={tcx(
            // Overall styling.
            "fixed !mt-0 top-0 h-full rounded-2",
            overlayStyles.hideScrollbar,
            widthClasses.container,
            // "Background" styling.
            {
              "scale-75 ": isInBackground,
              "origin-right":
                (isInBackground && width === "large") || width === "full",
            },
          )}
        >
          <aside
            className={tcx(
              // Overall styling.
              "fixed bg-white translate-x-2 top-2 rounded-2 h-[calc(100%-16px)] shadow-xl",
              "drawer-container", // this is required for drag and drop items within the drawer to be positioned correctly
              widthClasses.aside,
              className,
              overlayStyles.hideScrollbar,
            )}
          >
            {children}
          </aside>
        </motion.div>
      </PortalProvider>
    </>
  );
};

// This is really awkward, but doing it without tailwind is just as sad as you want to encode
// media queries here too.
const classesForWidth = (
  width: DrawerWidth,
  side: "left" | "right",
  sidebarExpanded: boolean,
): { container: string; aside: string } => {
  const isRight = side === "right";
  // First we handle the small drawers. This is super simple as it's always the same width,
  // irrespective of any sidebar shenanigans
  if (width === "extra-small") {
    return {
      container: tcx("w-[428px]", isRight ? "right-[16px]" : "left-0"),
      aside: tcx("w-[428px]", isRight ? "right-0" : "left-0"),
    };
  }

  if (width === "small") {
    return {
      container: tcx("w-[512px]", isRight ? "right-[16px]" : "left-0"),
      aside: tcx("w-[512px]", isRight ? "right-0" : "left-0"),
    };
  }

  // Our base classes handle small screen sizes. On anything below md, we always want a full width
  // drawer.
  const container = ["w-full", isRight ? "right-[16px]" : "left-0"];
  const aside = ["w-[calc(100%-8px)]", isRight ? "right-0" : "left-0"];

  // From md and up, let's now default to an almost full-width drawer (minus-ing the sidebar, and
  // some space to make the overlay clear).

  if (sidebarExpanded) {
    // An expanded sidebar is 256px wide.
    container.push("md:w-[calc(100%-256px)]");
  } else {
    // A collapsed one is 72px wide.
    container.push("md:w-[calc(100%-72px)]");
  }

  // Our full-width drawer is now working, so we can return early.
  if (width === "full") {
    return {
      container: tcx(...container),
      aside: tcx(...aside),
    };
  }

  // Now we'll handle the large drawer. This is simple, as it's just a 'max-width' that will only
  // grow if there's space in the main content area.
  if (width === "large") {
    container.push("xl:w-[1024px]");
  }

  // Now we'll handle the medium drawer. What we really want here is a 'max-width' that will only
  // grow if there's space in the main content area. That's challenging because of portal
  // shenanigans, so instead we use our knowledge of whether the sidebar to make a decision.
  if (width === "medium") {
    if (sidebarExpanded) {
      // An expanded sidebar is 256px wide, so we are 'safe' to use 950px once we get to our xl breakpoint.
      container.push("xl:w-[768px]");
    } else {
      // The collapsed sidebar is only 72px wide, so we're 'safe' from lg.
      container.push("lg:w-[768px]");
    }
  }

  return {
    container: tcx(...container),
    aside: tcx(...aside),
  };
};

export const DrawerContentsLoading = () => {
  const widths = ["w-32", "w-64", "w-40"];
  return (
    <DrawerContents>
      <div className={tcx("p-1 w-full")}>
        <LoadingBar className="h-16 w-full" />
      </div>

      <DrawerBody className="flex flex-col gap-5">
        {widths.map((width) => (
          <div key={width} className="flex flex-col gap-1">
            <LoadingBar className={tcx("h-8", width)} />
          </div>
        ))}
      </DrawerBody>
    </DrawerContents>
  );
};
