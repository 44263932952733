import { UploadImageValidationError } from "@incident-shared/images/useImageUpload";
import {
  Button,
  ButtonTheme,
  Heading,
  IconEnum,
  IconSize,
  Spinner,
} from "@incident-ui";
import { useState } from "react";
import { DropzoneOptions, DropzoneState, useDropzone } from "react-dropzone";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StatusPageGenerateLogoUploadURLRequestBodyContentTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { GENERIC_ERROR_MESSAGE } from "src/utils/fetchData";
import { tcx } from "src/utils/tailwind-classes";

import { cropPNG } from "../common/utils/crop-logo";

export type LogoData =
  | {
      key: string;
      preview_url: string;
      uploading?: never;
    }
  | { key?: never; preview_url?: never; uploading: true }
  | null;

function useLogoUploader<
  TFormType extends FieldValues,
  TPath1 extends FieldPath<TFormType>,
  TPath extends FieldPathValue<TFormType, TPath> extends LogoData
    ? TPath1
    : never,
>({
  formMethods,
  name,
  subpath,
  dropzoneOpts,
}: {
  subpath: string;
  formMethods: UseFormReturn<TFormType>;
  name: TPath;
  dropzoneOpts: Pick<
    DropzoneOptions,
    "accept" | "maxSize" | "minSize" | "validator"
  >;
}): [
  opts: {
    originalFilename: string | null;
    clear: () => void;
  },
  dropzone: DropzoneState,
] {
  const apiClient = useClient();
  const { watch } = formMethods;

  const previewURL = watch(name)?.preview_url;
  const [originalFilename, setOriginalFilename] = useState<string | null>(null);
  const doUpload = async (file: File) => {
    setOriginalFilename(file.name);
    let body = await file.arrayBuffer();

    let fileType: StatusPageGenerateLogoUploadURLRequestBodyContentTypeEnum;
    switch (file.type) {
      case "image/png":
        // try to crop any whitespace out of the image
        try {
          body = await cropPNG(await file.arrayBuffer());
        } catch (error) {
          console.error("Error cropping logo", { error });
          formMethods.setError(name, {
            message:
              "There was an error uploading your file. Please check the file format and try again.",
          });
          formMethods.setValue(name, null as PathValue<TFormType, TPath>, {
            shouldDirty: true,
          });
          return;
        }

        fileType =
          StatusPageGenerateLogoUploadURLRequestBodyContentTypeEnum.Png;
        break;

      case "image/jpg":
      case "image/jpeg":
        fileType =
          StatusPageGenerateLogoUploadURLRequestBodyContentTypeEnum.Jpeg;
        break;

      case "image/svg+xml":
        fileType =
          StatusPageGenerateLogoUploadURLRequestBodyContentTypeEnum.Svgxml;
        break;

      default:
        formMethods.setError(name, { message: UploadImageValidationError });
        return;
    }
    formMethods.setValue(name, { uploading: true } as PathValue<
      TFormType,
      TPath
    >);
    formMethods.clearErrors(name);
    try {
      const { key, upload_url, preview_url } =
        await apiClient.statusPageGenerateLogoUploadURL({
          generateLogoUploadURLRequestBody: {
            subpath,
            content_type: fileType,
          },
        });

      await fetch(upload_url, {
        method: "PUT",
        body,
        headers: { "content-type": fileType },
      });

      formMethods.setValue(
        name,
        { preview_url, key } as PathValue<TFormType, TPath>,
        { shouldDirty: true },
      );
    } catch (error) {
      console.error("Error uploading logo", { error });
      formMethods.setError(name, { message: GENERIC_ERROR_MESSAGE });
      formMethods.setValue(name, null as PathValue<TFormType, TPath>, {
        shouldDirty: true,
      });
    }
  };

  const clear = () => {
    formMethods.setValue(name, null as PathValue<TFormType, TPath>, {
      shouldDirty: true,
    });
    formMethods.clearErrors(name);
  };

  const dropzone = useDropzone({
    disabled: previewURL != null,
    maxFiles: 1,
    multiple: false,
    onDropAccepted: (files) => {
      if (files.length > 0) {
        doUpload(files[0]);
      }
    },
    onDropRejected: () => {
      formMethods.setError(name, { message: UploadImageValidationError });
    },
    ...dropzoneOpts,
  });

  return [{ originalFilename, clear }, dropzone];
}

export const StatusPagesLogoUploader = <
  TFormType extends FieldValues,
  TPath1 extends FieldPath<TFormType>,
  TPath extends FieldPathValue<TFormType, TPath> extends LogoData
    ? TPath1
    : never,
>({
  subpath,
  label,
  name,
  displayFilename,
  dropText,
  extraHelp,
  dropzoneOpts = {},
}: {
  subpath: string;
  label: string;
  name: TPath;
  dropText: string;
  extraHelp?: string;
  displayFilename: (previewURL: string) => string;
  dropzoneOpts?: DropzoneOptions;
}) => {
  const formMethods = useFormContext<TFormType>();

  const [
    { originalFilename, clear },
    { getRootProps, getInputProps, open, isDragActive },
  ] = useLogoUploader({
    formMethods,
    name,
    subpath,
    dropzoneOpts: Object.assign(
      {
        accept: {
          "image/png": [".png"],
          "image/jpeg": [".jpg", ".jpeg"],
        },
        maxSize: 10 * 1024 * 1024, // 10MB
      },
      dropzoneOpts,
    ),
  });

  const state = formMethods.watch(name);
  const previewURL = state?.preview_url;
  const isUploading = state?.uploading ?? false;

  return (
    <div>
      <Heading level={3} size="small">
        {label}
      </Heading>
      <label {...getRootProps()} className="block mt-1" htmlFor={name}>
        <div
          className={tcx(
            "border border-stroke rounded-2 text-slate-700 text-sm transition",
            previewURL
              ? "text-left pl-3 pr-2 py-2 inline-flex items-center gap-1 bg-white border-stroke shadow-sm"
              : "text-center p-4 block w-full",
            isDragActive && "bg-white",
          )}
        >
          {isUploading ? (
            <Spinner />
          ) : previewURL ? (
            <>
              {originalFilename || displayFilename(previewURL)}
              <Button
                theme={ButtonTheme.Naked}
                analyticsTrackingId={null}
                onClick={(ev) => {
                  ev.stopPropagation();
                  clear();
                }}
                icon={IconEnum.Close}
                iconProps={{ size: IconSize.Medium }}
                title="Remove favicon"
              />
            </>
          ) : (
            <span>
              {dropText}, or{" "}
              <Button
                onClick={(ev) => {
                  ev.stopPropagation();
                  open();
                }}
                analyticsTrackingId="status-page-choose-file"
                theme={ButtonTheme.Link}
              >
                choose a file.
              </Button>
              {extraHelp}
            </span>
          )}
        </div>
        <Form.ErrorMessage
          errors={formMethods.formState.errors}
          name={name}
          className="my-1"
        />
        <input
          type="file"
          name={name}
          className="hidden"
          {...getInputProps()}
        />
      </label>
    </div>
  );
};
