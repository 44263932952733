import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  SearchBar,
} from "@incident-ui";
import { useRef } from "react";

import { useAlertConfigContext } from "./AlertsConfigurationPage";

export const AlertSourcesHeader = () => {
  const navigate = useOrgAwareNavigate();
  const { searchSources, setSearchSources } = useAlertConfigContext();

  // This prevents re-renders, lol
  const iconProps = useRef({ size: IconSize.Small }).current;
  const triggerButton = useRef(
    <Button
      theme={ButtonTheme.Secondary}
      analyticsTrackingId={"alert-source-config"}
      size={BadgeSize.Medium}
      icon={IconEnum.Cog}
      iconProps={iconProps}
      title=""
    />,
  ).current;

  return (
    <div className="w-1/3 flex flex-row items-center justify-between pb-4 border-b border-stroke-primary">
      <div className="flex flex-row items-center">
        <Icon id={IconEnum.MergedArrow} size={IconSize.Small} />
        <div className="pl-2 text-base-bold">Sources</div>
      </div>
      <div className="flex flex-row space-x-2">
        <SearchBar
          id="search-alert-sources"
          value={searchSources}
          onChange={setSearchSources}
          className="w-full"
          inputClassName={"h-7 rounded-md bg-surface-primary shadow-sm"}
          placeholder="Search"
          iconProps={iconProps}
        />
        <DropdownMenu
          menuClassName={"w-[140px]"}
          side={"bottom"}
          align={"end"}
          scroll={false}
          triggerButton={triggerButton}
        >
          <DropdownMenuItem
            tooltipContent={""}
            onSelect={() => {
              navigate("/alerts/configuration/attributes");
            }}
            analyticsTrackingId={"alert-source-config-attributes"}
            icon={IconEnum.Tag}
            label={"Attributes"}
          >
            Attributes
          </DropdownMenuItem>
          <DropdownMenuItem
            tooltipContent={""}
            analyticsTrackingId={"alert-source-config-priorities"}
            onSelect={() => {
              navigate("/alerts/configuration/priorities");
            }}
            label="Priorities"
            icon={IconEnum.Priority}
          >
            Priorities
          </DropdownMenuItem>
        </DropdownMenu>
        <GatedButton
          theme={ButtonTheme.Secondary}
          href={"/alerts/sources/create"}
          analyticsTrackingId={"add-alert-source"}
          requiredScope={ScopeNameEnum.AlertSourceCreate}
          size={BadgeSize.Medium}
          icon={IconEnum.Add}
          iconProps={iconProps}
          title=""
        />
      </div>
    </div>
  );
};
