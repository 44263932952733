/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoCallConfig
 */
export interface AutoCallConfig {
    /**
     * Which integration to use for creating the call
     * @type {string}
     * @memberof AutoCallConfig
     */
    provider?: AutoCallConfigProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum AutoCallConfigProviderEnum {
    Zoom = 'zoom',
    GoogleMeet = 'google_meet',
    MicrosoftTeamsOnlineMeetings = 'microsoft_teams_online_meetings'
}

export function AutoCallConfigFromJSON(json: any): AutoCallConfig {
    return AutoCallConfigFromJSONTyped(json, false);
}

export function AutoCallConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoCallConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
    };
}

export function AutoCallConfigToJSON(value?: AutoCallConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
    };
}

