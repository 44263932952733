export const zoneName = (
  tz: string,
  format: "short" | "shortGeneric" | "shortOffset" = "shortOffset",
) => {
  return new Intl.DateTimeFormat("en", {
    timeZone: tz,
    timeZoneName: format,
  })
    .formatToParts()
    .find((e) => e.type === "timeZoneName")?.value;
};
