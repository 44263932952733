import {
  Alert,
  InsightsFilter,
  InsightsFilterOperatorEnum,
} from "@incident-io/api";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Checkbox,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Popover,
  PopoverBody,
  PopoverTitleBar,
} from "@incident-ui/Popover/Popover";
import {
  PopoverItem,
  PopoverItemGroup,
} from "@incident-ui/Popover/PopoverItem";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { AlertDetailsHeadlineMetrics } from "./insights/AlertDetailsHeadlineMetrics";
import { WorkloadChart } from "./insights/WorkloadChart";
import {
  ALERT_INSIGHTS_TIME_PERIOD_LABEL,
  useAlertInsightsDates,
} from "./useAlertInsightsDates";

export type AlertMatchByOption = {
  label: string;
  value: "title" | "deduplicationKey" | "themes";
};

type AlertInsightsFormData = {
  matchBy: AlertMatchByOption;
  themes: string[];
};

export const AlertDetailsInsights = ({ alert }: { alert: Alert }) => {
  const formMethods = useForm<AlertInsightsFormData>({
    defaultValues: {
      matchBy: { label: "Title", value: "title" },
    },
  });

  const matchBy: AlertMatchByOption = formMethods.watch("matchBy");

  const dates = useAlertInsightsDates({
    fromStartOfToday: false,
  });
  const selectedThemes = formMethods.watch("themes");

  let filters: Array<InsightsFilter>;
  if (matchBy.value === "themes") {
    const themeFilter: InsightsFilter = {
      operator: InsightsFilterOperatorEnum.AllOf,
      values: selectedThemes,
      field_key: "themes",
      field_id: "themes",
      is_disabled: false,
    };
    filters = [themeFilter];
  } else if (matchBy.value === "title") {
    const titleFilter: InsightsFilter = {
      operator: InsightsFilterOperatorEnum.Is,
      values: [alert.title],
      field_key: "title",
      field_id: "title",
      is_disabled: false,
    };
    filters = [titleFilter];
  } else if (matchBy.value === "deduplicationKey") {
    const deduplicationKeyFilter: InsightsFilter = {
      operator: InsightsFilterOperatorEnum.Is,
      values: [alert.deduplication_key],
      field_key: "deduplication_key",
      field_id: "deduplication_key",
      is_disabled: false,
    };
    filters = [deduplicationKeyFilter];
  } else {
    filters = [];
  }

  const { data: insightsData, error: insightsError } = useAPI(
    "alertsGetInsights",
    {
      getInsightsRequestBody: {
        filters,
        start_date: dates.startDate,
        end_date: dates.endDate,
        timezone: dates.timezone,
        alert_id: alert.id,
      },
    },
  );

  if (insightsError) {
    // If we fail to render the insights, we should still render the rest of the page
    // as it is quite informational.
    captureException(insightsError);
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <div className={"flex flex-col gap-2"}>
        <div className={"justify-between items-center inline-flex"}>
          <div className={"justify-start items-baseline gap-2 inline-flex"}>
            <span className={"font-semibold"}>Alert insights</span>
            <span className={"text-xs-med text-content-secondary"}>
              {ALERT_INSIGHTS_TIME_PERIOD_LABEL}
            </span>
          </div>
          <div className={"inline-flex items-baseline gap-2"}>
            <span className={"text-sm-med text-content-secondary"}>
              Group by
            </span>
            <GroupByPopover formMethods={formMethods} alert={alert} />
          </div>
        </div>
        <div className={"flex flex-col gap-3"}>
          <AlertDetailsHeadlineMetrics
            data={insightsData}
            filteredBy={matchBy.value}
          />
          <WorkloadChart data={insightsData} />
        </div>
      </div>
    </FormProvider>
  );
};

interface GroupByPopoverProps {
  formMethods: ReturnType<typeof useForm<AlertInsightsFormData>>;
  alert: { themes: string[] };
}

export const GroupByPopover = ({ formMethods, alert }: GroupByPopoverProps) => {
  const { setValue, watch } = formMethods;
  const matchBy = watch("matchBy");
  const selectedThemes = watch("themes") || [];
  const [showThemes, setShowThemes] = useState(matchBy?.value === "themes");
  const [isOpen, setIsOpen] = useState(false);

  const options: AlertMatchByOption[] = [
    { label: "Title", value: "title" },
    { label: "Deduplication key", value: "deduplicationKey" },
  ];

  const { featureAlertThemes } = useFlags();

  if (featureAlertThemes) {
    options.push({ label: "Themes", value: "themes" });
  }

  const handleOptionSelect = (option: AlertMatchByOption) => {
    if (option.value === "themes") {
      setShowThemes(true);
    } else {
      setValue("matchBy", option);
      setValue("themes", undefined as unknown as string[]);
      setIsOpen(false);
    }
  };

  const handleThemeToggle = (theme: string) => {
    const newThemes = selectedThemes.includes(theme)
      ? selectedThemes.filter((t) => t !== theme)
      : [...selectedThemes, theme];

    setValue("themes", newThemes);
    setValue("matchBy", { label: "Themes", value: "themes" });
  };

  const handleBack = () => {
    setShowThemes(false);
    if (selectedThemes.length === 0) {
      setValue("matchBy", { label: "Title", value: "title" });
      setValue("themes", undefined as unknown as string[]);
    }
  };

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      setShowThemes(false);
    } else if (matchBy?.value === "themes") {
      setShowThemes(true);
    }
  };

  return (
    <Popover
      trigger={
        <Button
          size={BadgeSize.Medium}
          analyticsTrackingId="group-by-select"
          theme={ButtonTheme.Tertiary}
        >
          {matchBy?.label}
          {matchBy?.value === "themes" &&
            selectedThemes.length > 0 &&
            ` (${selectedThemes.length})`}
          <Icon size={IconSize.Small} id={IconEnum.ChevronDown} />
        </Button>
      }
      align="end"
      open={isOpen}
      onOpenChange={handleOpenChange}
    >
      {showThemes ? (
        <>
          <PopoverTitleBar
            title="Select Themes"
            handleBack={handleBack}
            submitButton={
              <Button
                type="button"
                onClick={() => setIsOpen(false)}
                analyticsTrackingId="themes-done"
                theme={ButtonTheme.Primary}
                size={BadgeSize.Medium}
              >
                Done
              </Button>
            }
          />
          <PopoverBody className="w-[350px]">
            <PopoverItemGroup>
              {alert.themes.map((theme) => (
                <PopoverItem
                  key={theme}
                  // don't close the menu immediately. let user choose multiple items
                  onClick={(e) => {
                    e.preventDefault();
                    handleThemeToggle(theme);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id={theme}
                      onChange={() => handleThemeToggle(theme)}
                      checked={selectedThemes.includes(theme)}
                      className="!cursor-pointer"
                    />
                    <p className="text-slate-700">{theme}</p>
                  </div>
                </PopoverItem>
              ))}
            </PopoverItemGroup>
          </PopoverBody>
        </>
      ) : (
        <PopoverBody className="w-[350px]">
          <PopoverItemGroup>
            {options.map((option) => (
              <PopoverItem
                key={option.value}
                onClick={() => handleOptionSelect(option)}
                suffix={
                  option.value === "themes" ? (
                    <Icon
                      id={IconEnum.ChevronRight}
                      size={IconSize.Small}
                      className="text-content-tertiary"
                    />
                  ) : undefined
                }
              >
                {option.label}
              </PopoverItem>
            ))}
          </PopoverItemGroup>
        </PopoverBody>
      )}
    </Popover>
  );
};
