/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathNodeIfElse,
    EscalationPathNodeIfElseFromJSON,
    EscalationPathNodeIfElseFromJSONTyped,
    EscalationPathNodeIfElseToJSON,
} from './EscalationPathNodeIfElse';
import {
    EscalationPathNodeLevel,
    EscalationPathNodeLevelFromJSON,
    EscalationPathNodeLevelFromJSONTyped,
    EscalationPathNodeLevelToJSON,
} from './EscalationPathNodeLevel';
import {
    EscalationPathNodeNotifyChannel,
    EscalationPathNodeNotifyChannelFromJSON,
    EscalationPathNodeNotifyChannelFromJSONTyped,
    EscalationPathNodeNotifyChannelToJSON,
} from './EscalationPathNodeNotifyChannel';
import {
    EscalationPathNodeRepeat,
    EscalationPathNodeRepeatFromJSON,
    EscalationPathNodeRepeatFromJSONTyped,
    EscalationPathNodeRepeatToJSON,
} from './EscalationPathNodeRepeat';

/**
 * 
 * @export
 * @interface EscalationPathNode
 */
export interface EscalationPathNode {
    /**
     * An ID for this node, unique within the escalation path.
     * 
     * This allows you to reference the node in other nodes, such as when configuring a 'repeat' node.
     * @type {string}
     * @memberof EscalationPathNode
     */
    id: string;
    /**
     * 
     * @type {EscalationPathNodeIfElse}
     * @memberof EscalationPathNode
     */
    if_else?: EscalationPathNodeIfElse;
    /**
     * 
     * @type {EscalationPathNodeLevel}
     * @memberof EscalationPathNode
     */
    level?: EscalationPathNodeLevel;
    /**
     * 
     * @type {EscalationPathNodeNotifyChannel}
     * @memberof EscalationPathNode
     */
    notify_channel?: EscalationPathNodeNotifyChannel;
    /**
     * 
     * @type {EscalationPathNodeRepeat}
     * @memberof EscalationPathNode
     */
    repeat?: EscalationPathNodeRepeat;
    /**
     * The type of this node. Available types are:
     * * level: A set of targets (users or schedules) that should be paged, either all at once, or with a round-robin configuration.
     * * notify_channel: Send the escalation to a Slack channel, where it can be acked by anyone in the channel.
     * * if_else: Branch the escalation based on a set of conditions.
     * * repeat: Go back to a previous node and repeat the logic from there.
     * @type {string}
     * @memberof EscalationPathNode
     */
    type: EscalationPathNodeTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathNodeTypeEnum {
    IfElse = 'if_else',
    Repeat = 'repeat',
    Level = 'level',
    NotifyChannel = 'notify_channel'
}

export function EscalationPathNodeFromJSON(json: any): EscalationPathNode {
    return EscalationPathNodeFromJSONTyped(json, false);
}

export function EscalationPathNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathNode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'if_else': !exists(json, 'if_else') ? undefined : EscalationPathNodeIfElseFromJSON(json['if_else']),
        'level': !exists(json, 'level') ? undefined : EscalationPathNodeLevelFromJSON(json['level']),
        'notify_channel': !exists(json, 'notify_channel') ? undefined : EscalationPathNodeNotifyChannelFromJSON(json['notify_channel']),
        'repeat': !exists(json, 'repeat') ? undefined : EscalationPathNodeRepeatFromJSON(json['repeat']),
        'type': json['type'],
    };
}

export function EscalationPathNodeToJSON(value?: EscalationPathNode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'if_else': EscalationPathNodeIfElseToJSON(value.if_else),
        'level': EscalationPathNodeLevelToJSON(value.level),
        'notify_channel': EscalationPathNodeNotifyChannelToJSON(value.notify_channel),
        'repeat': EscalationPathNodeRepeatToJSON(value.repeat),
        'type': value.type,
    };
}

