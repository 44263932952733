import { IconEnum } from "@incident-ui";
import { useDrawer } from "@incident-ui/Drawer/DrawerProvider";
import React from "react";

import { SecondaryNavDesktop, SecondaryNavGroup } from "./SecondaryNav";

export const SecondaryNavPageWrapper = ({
  navItemGroups,
  title,
  pathPrefix,
  children,
  prefixNode,
}: {
  navItemGroups: SecondaryNavGroup[];
  title: string;
  icon: IconEnum;
  pathPrefix: string;
  headerNode?: React.ReactNode;
  prefixNode?: React.ReactNode;
  children: React.ReactNode;
}): React.ReactElement => {
  const { pageDrawerRef } = useDrawer();

  return (
    <>
      <div ref={pageDrawerRef}></div>
      <div className="relative h-full w-full">
        <div className="flex items-start h-full">
          <SecondaryNavDesktop
            className="h-full bg-surface-secondary border-r-[1px] border-surface-tertiary overflow-auto"
            itemGroups={navItemGroups}
            pathPrefix={pathPrefix}
            prefixNode={prefixNode}
            title={title}
          />
          <div className="grow h-full overflow-auto">{children}</div>
        </div>
      </div>
    </>
  );
};
