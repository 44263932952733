import { useAiServiceAiShowInvestigation } from "@incident-io/query-api";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useParams } from "react-router";

import { InvestigationsShowPage } from "./show/InvestigationsShowPage";

export const InvestigationsRoute = (): React.ReactElement => {
  const { id } = useParams();

  const { data, isLoading } = useAiServiceAiShowInvestigation({
    id: id || "",
  });

  if (isLoading || !data) {
    return <FullPageLoader />;
  }

  return (
    <InvestigationsShowPage
      investigation={data?.investigation}
      checks={data?.checks}
    />
  );
};
