import { EngineScope, Resource, Trigger } from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { Button, ButtonTheme } from "@incident-ui";
import { StaticSingleSelectWithObj } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import _ from "lodash";
import { useState } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { filterScope } from "src/utils/scope";
import { sendToSentry } from "src/utils/utils";

import { WorkflowOnceFor } from "../common/types";
import { WorkflowFormData } from "../common/types";
import { WorkflowsFrequencySettingsExplanation } from "./WorkflowsFrequencySettingsExplanation";

export const WorkflowsFrequencySettingsForm = ({
  onClose,
  formMethods,
  setIsEditing,
  scope,
  resources,
  trigger,
}: {
  onClose: () => void;
  setIsEditing: (isEditing: boolean) => void;
  formMethods: UseFormReturn<WorkflowFormData>;
  scope: EngineScope;
  resources: Resource[];
  trigger: Trigger;
}) => {
  const [currentlyAdding, setCurrentlyAdding] = useState<WorkflowOnceFor>();
  const { control, formState, reset, getValues } = formMethods;

  const {
    fields: onceForFields,
    append: appendOnceFor,
    remove: deleteOnceFor,
  } = useFieldArray({
    control: control,
    name: "once_for",
  });

  const onConfirm = () => {
    reset(getValues());
    setIsEditing(false);
  };

  const onceForFieldsRef = onceForFields.map(
    (onceFor: WorkflowOnceFor) => onceFor.key,
  );
  const isOnceForDirty = (formState.dirtyFields.once_for || []).length > 0;
  // we want to compare the current onceFor fields with the default values
  // however because it is a field array, we need to omit the id field
  const isDeepEqualToDefaultOnceFor = _.isEqual(
    formState.defaultValues?.once_for,
    onceForFields.map((onceFor: WorkflowOnceFor) => _.omit(onceFor, "id")),
  );

  // Our options come from the scope, however we must remove any items we have
  // already selected i.e. things within onceForFields.
  const addMoreSelectOptions: SelectOption[] = filterScope(
    scope,
    (scopeRef) => {
      return !onceForFieldsRef.includes(scopeRef.key);
    },
  ).references.map(
    (scopeRef) =>
      ({
        ...scopeRef,
        sort_key: scopeRef.label,
        value: scopeRef.key,
      }) as SelectOption,
  );

  const onAdd = () => {
    if (!currentlyAdding) {
      return;
    }
    appendOnceFor(currentlyAdding);
    setCurrentlyAdding(undefined);
  };

  const onDelete = (key: number) => {
    deleteOnceFor(key);
  };

  const isConfirmDisabled =
    !isOnceForDirty || isDeepEqualToDefaultOnceFor || !!currentlyAdding;

  return (
    <div className="space-y-1">
      <WorkflowsFrequencySettingsExplanation
        onceFor={onceForFields}
        trigger={trigger}
        isEditing={true}
        setIsEditing={() => {
          return;
        }}
      />
      <div className="flex flex-col gap-2">
        <ul className="space-y-2">
          {onceForFields.map((opt, index) => {
            const resourceConfig = resources.find((x) => x.type === opt.type);
            if (!resourceConfig) {
              sendToSentry(
                "unreachable: could not find resource config for selected Once For.",
                {
                  selectedOneForItem: opt,
                  triggerResources: resources,
                },
              );
              return null;
            }
            return (
              <li className="w-fit" key={opt.key}>
                <EngineLiteralBadge
                  icon={resourceConfig.field_config.icon}
                  color={resourceConfig.field_config.color}
                  label={opt.label}
                  onDelete={() => onDelete(index)}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="flex flex-row gap-2">
        <StaticSingleSelectWithObj
          value={currentlyAdding ? currentlyAdding : null}
          // @ts-expect-error it's a react dispatch, but the function signature is actually the same
          onChange={setCurrentlyAdding}
          options={addMoreSelectOptions}
          placeholder={"Choose new item"}
          isClearable={true}
          className="grow"
        />
        <Button
          analyticsTrackingId="workflows-v2-once-for-add-this-item"
          theme={ButtonTheme.Secondary}
          onClick={onAdd}
          disabled={!currentlyAdding}
        >
          Add
        </Button>
      </div>

      <div className="flex flex-row gap-2 pt-2">
        <Button
          analyticsTrackingId="workflows-v2-once-for-cancel"
          theme={ButtonTheme.Secondary}
          onClick={() => {
            // set back to default values
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          analyticsTrackingId="workflows-v2-once-for-confirm"
          theme={ButtonTheme.Primary}
          onClick={onConfirm}
          disabled={isConfirmDisabled}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
