/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface IncidentSlim
 */
export interface IncidentSlim {
    /**
     * External identifier for the incident - often displayed with an INC- prefix
     * @type {number}
     * @memberof IncidentSlim
     */
    external_id: number;
    /**
     * Unique identifier for the incident
     * @type {string}
     * @memberof IncidentSlim
     */
    id: string;
    /**
     * Explanation of the incident
     * @type {string}
     * @memberof IncidentSlim
     */
    name: string;
    /**
     * Reference to this incident, as displayed across the product
     * @type {string}
     * @memberof IncidentSlim
     */
    reference: string;
    /**
     * The category of the incidents status
     * @type {string}
     * @memberof IncidentSlim
     */
    status_category?: IncidentSlimStatusCategoryEnum;
    /**
     * 
     * @type {TextDocument}
     * @memberof IncidentSlim
     */
    summary?: TextDocument;
    /**
     * Whether the incident should be open to anyone in your Slack workspace (public), or invite-only (private). For more information on Private Incidents see our [help centre](https://help.incident.io/articles/5905558102-can-we-mark-incidents-as-sensitive-and-restrict-access).
     * @type {string}
     * @memberof IncidentSlim
     */
    visibility: IncidentSlimVisibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentSlimStatusCategoryEnum {
    Triage = 'triage',
    Declined = 'declined',
    Merged = 'merged',
    Canceled = 'canceled',
    Active = 'active',
    PostIncident = 'post-incident',
    Closed = 'closed',
    Paused = 'paused'
}/**
* @export
* @enum {string}
*/
export enum IncidentSlimVisibilityEnum {
    Public = 'public',
    Private = 'private'
}

export function IncidentSlimFromJSON(json: any): IncidentSlim {
    return IncidentSlimFromJSONTyped(json, false);
}

export function IncidentSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': json['external_id'],
        'id': json['id'],
        'name': json['name'],
        'reference': json['reference'],
        'status_category': !exists(json, 'status_category') ? undefined : json['status_category'],
        'summary': !exists(json, 'summary') ? undefined : TextDocumentFromJSON(json['summary']),
        'visibility': json['visibility'],
    };
}

export function IncidentSlimToJSON(value?: IncidentSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'id': value.id,
        'name': value.name,
        'reference': value.reference,
        'status_category': value.status_category,
        'summary': TextDocumentToJSON(value.summary),
        'visibility': value.visibility,
    };
}

