import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { UseFormReturn } from "react-hook-form";

import { EscalationPathFormData, PathNode } from "../../common/types";

// deleteNode is a helper function to delete a node from the tree, along with any downstream nodes
// that are no longer reachable (e.g. when deleting an if/else node, the else branch is also
// deleted).
export const deleteNode = (
  formMethods: UseFormReturn<EscalationPathFormData>,
  nodeId: string,
) => {
  const { nodes, firstNodeId } = formMethods.getValues();

  const updatedNodes = { ...nodes };
  const deletedNode = updatedNodes[nodeId];

  // Remove the deleted node from the updated nodes object.
  delete updatedNodes[deletedNode.id];

  // If the deleted node is the current first node in the tree, then lets find the new first node
  // by looking at what the deleted node was pointing to. This is simple for level nodes, but for
  // if/else nodes we need to look at the thenNodeId.
  let newFirstNodeId = firstNodeId;
  if (deletedNode.id === firstNodeId) {
    switch (deletedNode.data.type) {
      case NodeTypes.Level:
        if (!deletedNode.data.nextNodeId) {
          throw new Error(
            "level node deleted, but level data or next node not found",
          );
        }
        newFirstNodeId = deletedNode.data.nextNodeId;
        break;

      case NodeTypes.NotifyChannel:
        if (!deletedNode.data.nextNodeId) {
          throw new Error(
            "notify_channel node deleted, but notify_channel data or next node not found",
          );
        }
        newFirstNodeId = deletedNode.data.nextNodeId;
        break;

      case NodeTypes.IfElse:
        const ifElseNode = deletedNode.data.if_else;
        const eitherBranchId = ifElseNode.thenNodeId ?? ifElseNode.elseNodeId;
        if (!eitherBranchId) {
          throw new Error("Top-level if-else deleted, but no branches found");
        }
        newFirstNodeId = eitherBranchId;
        break;
    }
  }

  // We want to grab the child node that the deleted node is pointing to, so we can update any
  // references to the deleted node to point to the child node.
  let childNodeId: string | undefined;
  switch (deletedNode.data.type) {
    case NodeTypes.Level:
      childNodeId = deletedNode.data.nextNodeId;
      break;

    case NodeTypes.NotifyChannel:
      childNodeId = deletedNode.data.nextNodeId;
      break;

    case NodeTypes.IfElse:
      childNodeId = deletedNode.data.if_else.thenNodeId;
      break;
    case NodeTypes.Repeat:
      break;
  }

  // If the deleted node is an if/else node, then delete all nodes in the else branch (if there are any).
  if (
    deletedNode.data.type === NodeTypes.IfElse &&
    deletedNode.data.if_else.elseNodeId
  ) {
    deleteBranch(deletedNode.data.if_else.elseNodeId, updatedNodes);
  }

  // Update any nodes that reference the deleted node.
  Object.values(updatedNodes).forEach((node) => {
    // Level nodes → If a level node still present in the tree references the deleted node as it's
    // next node.
    if (
      node.data.type === NodeTypes.Level &&
      node.data.nextNodeId === deletedNode.id
    ) {
      // childNodeId can be undefined if the deleted node was the last node in the tree.
      node.data.nextNodeId = childNodeId;
    }

    // Channel nodes → If a channel node still present in the tree references the deleted node as it's
    // next node.
    if (
      node.data.type === NodeTypes.NotifyChannel &&
      node.data.nextNodeId === deletedNode.id
    ) {
      node.data.nextNodeId = childNodeId;
    }

    // Repeat nodes → If a repeat node still present in the tree referenced the deleted node, as the
    // deleted node was the first in tree, then update the repeat node to reference the new first
    // node.
    if (
      node.data.type === NodeTypes.Repeat &&
      node.data.repeat.to_node === deletedNode.id &&
      newFirstNodeId
    ) {
      node.data.repeat.to_node = newFirstNodeId;
    }

    // If/Else nodes → If an if/else node still present in the tree referenced the deleted node,
    // then update the if/else node to reference the new first node within the relevant branch.
    if (node.data.type === NodeTypes.IfElse) {
      if (node.data.if_else.thenNodeId === deletedNode.id) {
        node.data.if_else.thenNodeId = childNodeId;
      }

      if (node.data.if_else.elseNodeId === deletedNode.id) {
        node.data.if_else.elseNodeId = childNodeId;
      }
    }
  });

  // Update the firstNodeId in the form state, if it has changed.
  if (newFirstNodeId !== firstNodeId) {
    formMethods.setValue<"firstNodeId">("firstNodeId", newFirstNodeId);
  }

  // Update the form state of the nodes in the tree via the provided function.
  formMethods.setValue<"nodes">("nodes", updatedNodes);
};

// deleteBranch is a helper function to recursively delete all nodes in a branch.
const deleteBranch = (
  nodeId: string | undefined,
  nodes: Record<string, PathNode>,
) => {
  if (!nodeId) {
    // No branch to delete!
    return;
  }
  const node = nodes[nodeId];

  // If the node doesn't exist, return early.
  if (!node) {
    return;
  }

  // Delete the node from the nodes object.
  delete nodes[nodeId];

  // If the node is an if/else node, then delete all nodes in both the then and else branches.
  if (node.data.type === NodeTypes.IfElse) {
    deleteBranch(node.data.if_else.thenNodeId, nodes);
    deleteBranch(node.data.if_else.elseNodeId, nodes);
  }

  // If the node is a level node, then delete the next node in the branch.
  if (node.data.type === NodeTypes.Level && node.data.nextNodeId) {
    deleteBranch(node.data.nextNodeId, nodes);
  }

  // If the node is a channel node, then delete the next node in the branch.
  if (node.data.type === NodeTypes.NotifyChannel && node.data.nextNodeId) {
    deleteBranch(node.data.nextNodeId, nodes);
  }
};
