import { ScopeNameEnum } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { UseFormReturn } from "react-hook-form";

import { EscalationPathFormData } from "../common/types";
import { useEscalationPathDrawers } from "./EscalationPathCreateEditDrawer";

export const EscalationPathCreateEditSplitButton = ({
  mode,
  formMethods,
}: {
  mode: Mode;
  formMethods: UseFormReturn<EscalationPathFormData>;
}) => {
  const createMode = mode === Mode.Create;
  const { watch } = formMethods;
  const { openTerraformDrawer } = useEscalationPathDrawers();

  const name = watch("name");

  return (
    <div className={"flex flex-center mr-6 !max-h-8 gap-[1px]"}>
      <GatedButton
        type="submit"
        form="escalation-path-create-edit-form"
        analyticsTrackingId="create-edit-escalation-path-v2"
        theme={ButtonTheme.Primary}
        className="ml-auto mr-auto !rounded-r-none"
        requiredScope={scope[mode]}
        disabledTooltipContent={"You do not have permission to do this"}
      >
        {button[mode]}
      </GatedButton>
      <DropdownMenu
        triggerButton={
          <Button
            theme={ButtonTheme.Primary}
            type="button"
            className="!px-2 !rounded-l-none"
            analyticsTrackingId="manage-ep-options-click"
            icon={IconEnum.ChevronUp}
            iconProps={{
              size: IconSize.Medium,
              className: "text-content-white",
            }}
            title="Manage escalation path options"
          />
        }
        align={"end"}
      >
        <DropdownMenuItem
          analyticsTrackingId={null}
          onSelect={() =>
            formMethods
              .trigger()
              .then((valid) => valid && openTerraformDrawer())
          }
          disabled={name === ""}
          tooltipContent={
            name === ""
              ? createMode
                ? "Please enter a name before creating in Terraform"
                : "Please enter a name before exporting to Terraform"
              : undefined
          }
          icon={IconEnum.Terraform}
          label={createMode ? "Create in Terraform" : "Export to Terraform"}
          iconProps={{
            size: IconSize.Small,
          }}
        >
          {createMode ? "Create in Terraform" : "Export to Terraform"}
        </DropdownMenuItem>
      </DropdownMenu>
    </div>
  );
};

const button = {
  create: "Create",
  edit: "Save",
  duplicate: "Duplicate",
};
const scope = {
  create: ScopeNameEnum.EscalationPathsCreate,
  edit: ScopeNameEnum.EscalationPathsUpdate,
  duplicate: ScopeNameEnum.EscalationPathsCreate,
};
