/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InsightsCreateDashboardRequestBody,
    InsightsCreateDashboardRequestBodyFromJSON,
    InsightsCreateDashboardRequestBodyToJSON,
    InsightsCreateDashboardResponseBody,
    InsightsCreateDashboardResponseBodyFromJSON,
    InsightsCreateDashboardResponseBodyToJSON,
    InsightsCreateReportScheduleRequestBody,
    InsightsCreateReportScheduleRequestBodyFromJSON,
    InsightsCreateReportScheduleRequestBodyToJSON,
    InsightsCreateReportScheduleResponseBody,
    InsightsCreateReportScheduleResponseBodyFromJSON,
    InsightsCreateReportScheduleResponseBodyToJSON,
    InsightsDuplicateDashboardRequestBody,
    InsightsDuplicateDashboardRequestBodyFromJSON,
    InsightsDuplicateDashboardRequestBodyToJSON,
    InsightsDuplicateDashboardResponseBody,
    InsightsDuplicateDashboardResponseBodyFromJSON,
    InsightsDuplicateDashboardResponseBodyToJSON,
    InsightsGenerateExploJWTRequestBody,
    InsightsGenerateExploJWTRequestBodyFromJSON,
    InsightsGenerateExploJWTRequestBodyToJSON,
    InsightsGenerateExploJWTResponseBody,
    InsightsGenerateExploJWTResponseBodyFromJSON,
    InsightsGenerateExploJWTResponseBodyToJSON,
    InsightsGetCustomDashboardIDOfSavedViewResponseBody,
    InsightsGetCustomDashboardIDOfSavedViewResponseBodyFromJSON,
    InsightsGetCustomDashboardIDOfSavedViewResponseBodyToJSON,
    InsightsGetLastSyncResponseBody,
    InsightsGetLastSyncResponseBodyFromJSON,
    InsightsGetLastSyncResponseBodyToJSON,
    InsightsGetTrendsResponseBody,
    InsightsGetTrendsResponseBodyFromJSON,
    InsightsGetTrendsResponseBodyToJSON,
    InsightsListAvailablePanelsResponseBody,
    InsightsListAvailablePanelsResponseBodyFromJSON,
    InsightsListAvailablePanelsResponseBodyToJSON,
    InsightsListCatalogEntryOptionsResponseBody,
    InsightsListCatalogEntryOptionsResponseBodyFromJSON,
    InsightsListCatalogEntryOptionsResponseBodyToJSON,
    InsightsListCatalogTypesResponseBody,
    InsightsListCatalogTypesResponseBodyFromJSON,
    InsightsListCatalogTypesResponseBodyToJSON,
    InsightsListCoreDashboardsResponseBody,
    InsightsListCoreDashboardsResponseBodyFromJSON,
    InsightsListCoreDashboardsResponseBodyToJSON,
    InsightsListCustomDashboardsResponseBody,
    InsightsListCustomDashboardsResponseBodyFromJSON,
    InsightsListCustomDashboardsResponseBodyToJSON,
    InsightsListCustomFieldOptionsResponseBody,
    InsightsListCustomFieldOptionsResponseBodyFromJSON,
    InsightsListCustomFieldOptionsResponseBodyToJSON,
    InsightsListReportSchedulesResponseBody,
    InsightsListReportSchedulesResponseBodyFromJSON,
    InsightsListReportSchedulesResponseBodyToJSON,
    InsightsShowCoreDashboardResponseBody,
    InsightsShowCoreDashboardResponseBodyFromJSON,
    InsightsShowCoreDashboardResponseBodyToJSON,
    InsightsShowCustomDashboardResponseBody,
    InsightsShowCustomDashboardResponseBodyFromJSON,
    InsightsShowCustomDashboardResponseBodyToJSON,
    InsightsShowReportScheduleResponseBody,
    InsightsShowReportScheduleResponseBodyFromJSON,
    InsightsShowReportScheduleResponseBodyToJSON,
    InsightsTestInsightsReportRequestBody,
    InsightsTestInsightsReportRequestBodyFromJSON,
    InsightsTestInsightsReportRequestBodyToJSON,
    InsightsUpdateDashboardRequestBody,
    InsightsUpdateDashboardRequestBodyFromJSON,
    InsightsUpdateDashboardRequestBodyToJSON,
    InsightsUpdateDashboardResponseBody,
    InsightsUpdateDashboardResponseBodyFromJSON,
    InsightsUpdateDashboardResponseBodyToJSON,
    InsightsUpdateReportScheduleRequestBody,
    InsightsUpdateReportScheduleRequestBodyFromJSON,
    InsightsUpdateReportScheduleRequestBodyToJSON,
    InsightsUpdateReportScheduleResponseBody,
    InsightsUpdateReportScheduleResponseBodyFromJSON,
    InsightsUpdateReportScheduleResponseBodyToJSON,
} from '../models';

export interface InsightsCreateDashboardRequest {
    createDashboardRequestBody: InsightsCreateDashboardRequestBody;
}

export interface InsightsCreateReportScheduleRequest {
    createReportScheduleRequestBody: InsightsCreateReportScheduleRequestBody;
}

export interface InsightsDestroyDashboardRequest {
    id: string;
}

export interface InsightsDestroyReportScheduleRequest {
    id: string;
}

export interface InsightsDuplicateDashboardRequest {
    duplicateDashboardRequestBody: InsightsDuplicateDashboardRequestBody;
}

export interface InsightsGenerateExploJWTRequest {
    generateExploJWTRequestBody: InsightsGenerateExploJWTRequestBody;
}

export interface InsightsGetCustomDashboardIdOfSavedViewRequest {
    savedViewId: string;
}

export interface InsightsGetTrendsRequest {
    dashboardId: string;
    startDate: Date;
    endDate: Date;
    numTrends?: number;
    filters?: string;
    panelVariables?: any;
    allTrends?: boolean;
}

export interface InsightsListCatalogEntryOptionsRequest {
    catalogTypeId: string;
    query?: string;
    entryIds?: Array<string>;
}

export interface InsightsListCustomFieldOptionsRequest {
    customFieldId: string;
    query?: string;
    optionIds?: Array<string>;
}

export interface InsightsShowCoreDashboardRequest {
    id: string;
}

export interface InsightsShowCustomDashboardRequest {
    id: string;
}

export interface InsightsShowReportScheduleRequest {
    id: string;
}

export interface InsightsStarInsightsDashboardRequest {
    id: string;
}

export interface InsightsTestInsightsReportRequest {
    testInsightsReportRequestBody: InsightsTestInsightsReportRequestBody;
}

export interface InsightsUnstarInsightsDashboardRequest {
    id: string;
}

export interface InsightsUpdateDashboardRequest {
    id: string;
    updateDashboardRequestBody: InsightsUpdateDashboardRequestBody;
}

export interface InsightsUpdateReportScheduleRequest {
    id: string;
    updateReportScheduleRequestBody: InsightsUpdateReportScheduleRequestBody;
}

/**
 * 
 */
export class InsightsApi extends runtime.BaseAPI {

    /**
     * Create a custom dashboard.
     * CreateDashboard Insights
     */
    async insightsCreateDashboardRaw(requestParameters: InsightsCreateDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsCreateDashboardResponseBody>> {
        if (requestParameters.createDashboardRequestBody === null || requestParameters.createDashboardRequestBody === undefined) {
            throw new runtime.RequiredError('createDashboardRequestBody','Required parameter requestParameters.createDashboardRequestBody was null or undefined when calling insightsCreateDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/dashboards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsCreateDashboardRequestBodyToJSON(requestParameters.createDashboardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsCreateDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a custom dashboard.
     * CreateDashboard Insights
     */
    async insightsCreateDashboard(requestParameters: InsightsCreateDashboardRequest, initOverrides?: RequestInit): Promise<InsightsCreateDashboardResponseBody> {
        const response = await this.insightsCreateDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new insights report schedule
     * CreateReportSchedule Insights
     */
    async insightsCreateReportScheduleRaw(requestParameters: InsightsCreateReportScheduleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsCreateReportScheduleResponseBody>> {
        if (requestParameters.createReportScheduleRequestBody === null || requestParameters.createReportScheduleRequestBody === undefined) {
            throw new runtime.RequiredError('createReportScheduleRequestBody','Required parameter requestParameters.createReportScheduleRequestBody was null or undefined when calling insightsCreateReportSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/report_schedules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsCreateReportScheduleRequestBodyToJSON(requestParameters.createReportScheduleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsCreateReportScheduleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new insights report schedule
     * CreateReportSchedule Insights
     */
    async insightsCreateReportSchedule(requestParameters: InsightsCreateReportScheduleRequest, initOverrides?: RequestInit): Promise<InsightsCreateReportScheduleResponseBody> {
        const response = await this.insightsCreateReportScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives a custom dashboard.
     * DestroyDashboard Insights
     */
    async insightsDestroyDashboardRaw(requestParameters: InsightsDestroyDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsDestroyDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a custom dashboard.
     * DestroyDashboard Insights
     */
    async insightsDestroyDashboard(requestParameters: InsightsDestroyDashboardRequest, initOverrides?: RequestInit): Promise<void> {
        await this.insightsDestroyDashboardRaw(requestParameters, initOverrides);
    }

    /**
     * Archives an insights report schedule
     * DestroyReportSchedule Insights
     */
    async insightsDestroyReportScheduleRaw(requestParameters: InsightsDestroyReportScheduleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsDestroyReportSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/report_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an insights report schedule
     * DestroyReportSchedule Insights
     */
    async insightsDestroyReportSchedule(requestParameters: InsightsDestroyReportScheduleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.insightsDestroyReportScheduleRaw(requestParameters, initOverrides);
    }

    /**
     * Duplicate a custom dashboard.
     * DuplicateDashboard Insights
     */
    async insightsDuplicateDashboardRaw(requestParameters: InsightsDuplicateDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsDuplicateDashboardResponseBody>> {
        if (requestParameters.duplicateDashboardRequestBody === null || requestParameters.duplicateDashboardRequestBody === undefined) {
            throw new runtime.RequiredError('duplicateDashboardRequestBody','Required parameter requestParameters.duplicateDashboardRequestBody was null or undefined when calling insightsDuplicateDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/dashboards/duplicate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsDuplicateDashboardRequestBodyToJSON(requestParameters.duplicateDashboardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsDuplicateDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Duplicate a custom dashboard.
     * DuplicateDashboard Insights
     */
    async insightsDuplicateDashboard(requestParameters: InsightsDuplicateDashboardRequest, initOverrides?: RequestInit): Promise<InsightsDuplicateDashboardResponseBody> {
        const response = await this.insightsDuplicateDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a token for communicating with Explo, using a secret only accessible via the backend.
     * GenerateExploJWT Insights
     */
    async insightsGenerateExploJWTRaw(requestParameters: InsightsGenerateExploJWTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsGenerateExploJWTResponseBody>> {
        if (requestParameters.generateExploJWTRequestBody === null || requestParameters.generateExploJWTRequestBody === undefined) {
            throw new runtime.RequiredError('generateExploJWTRequestBody','Required parameter requestParameters.generateExploJWTRequestBody was null or undefined when calling insightsGenerateExploJWT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsGenerateExploJWTRequestBodyToJSON(requestParameters.generateExploJWTRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsGenerateExploJWTResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generates a token for communicating with Explo, using a secret only accessible via the backend.
     * GenerateExploJWT Insights
     */
    async insightsGenerateExploJWT(requestParameters: InsightsGenerateExploJWTRequest, initOverrides?: RequestInit): Promise<InsightsGenerateExploJWTResponseBody> {
        const response = await this.insightsGenerateExploJWTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the ID of the custom dashboard that a saved view has been converted into.
     * GetCustomDashboardIdOfSavedView Insights
     */
    async insightsGetCustomDashboardIdOfSavedViewRaw(requestParameters: InsightsGetCustomDashboardIdOfSavedViewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsGetCustomDashboardIDOfSavedViewResponseBody>> {
        if (requestParameters.savedViewId === null || requestParameters.savedViewId === undefined) {
            throw new runtime.RequiredError('savedViewId','Required parameter requestParameters.savedViewId was null or undefined when calling insightsGetCustomDashboardIdOfSavedView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/saved_view/{saved_view_id}`.replace(`{${"saved_view_id"}}`, encodeURIComponent(String(requestParameters.savedViewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsGetCustomDashboardIDOfSavedViewResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the ID of the custom dashboard that a saved view has been converted into.
     * GetCustomDashboardIdOfSavedView Insights
     */
    async insightsGetCustomDashboardIdOfSavedView(requestParameters: InsightsGetCustomDashboardIdOfSavedViewRequest, initOverrides?: RequestInit): Promise<InsightsGetCustomDashboardIDOfSavedViewResponseBody> {
        const response = await this.insightsGetCustomDashboardIdOfSavedViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the last sync time for our insights tables
     * GetLastSync Insights
     */
    async insightsGetLastSyncRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsGetLastSyncResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/last_sync_at`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsGetLastSyncResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the last sync time for our insights tables
     * GetLastSync Insights
     */
    async insightsGetLastSync(initOverrides?: RequestInit): Promise<InsightsGetLastSyncResponseBody> {
        const response = await this.insightsGetLastSyncRaw(initOverrides);
        return await response.value();
    }

    /**
     * Search for notable patterns in data for a given dashboard.
     * GetTrends Insights
     */
    async insightsGetTrendsRaw(requestParameters: InsightsGetTrendsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsGetTrendsResponseBody>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling insightsGetTrends.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling insightsGetTrends.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling insightsGetTrends.');
        }

        const queryParameters: any = {};

        if (requestParameters.dashboardId !== undefined) {
            queryParameters['dashboard_id'] = requestParameters.dashboardId;
        }

        if (requestParameters.numTrends !== undefined) {
            queryParameters['num_trends'] = requestParameters.numTrends;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.panelVariables !== undefined) {
            queryParameters['panel_variables'] = requestParameters.panelVariables;
        }

        if (requestParameters.allTrends !== undefined) {
            queryParameters['all_trends'] = requestParameters.allTrends;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/trends`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsGetTrendsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Search for notable patterns in data for a given dashboard.
     * GetTrends Insights
     */
    async insightsGetTrends(requestParameters: InsightsGetTrendsRequest, initOverrides?: RequestInit): Promise<InsightsGetTrendsResponseBody> {
        const response = await this.insightsGetTrendsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all available panels that a user can choose from to create a custom dashboard.
     * ListAvailablePanels Insights
     */
    async insightsListAvailablePanelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListAvailablePanelsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/available_panels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListAvailablePanelsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all available panels that a user can choose from to create a custom dashboard.
     * ListAvailablePanels Insights
     */
    async insightsListAvailablePanels(initOverrides?: RequestInit): Promise<InsightsListAvailablePanelsResponseBody> {
        const response = await this.insightsListAvailablePanelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List entries for a catalog type, for use with typeaheads. This will return the right fields for demo / sample data as needed.
     * ListCatalogEntryOptions Insights
     */
    async insightsListCatalogEntryOptionsRaw(requestParameters: InsightsListCatalogEntryOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListCatalogEntryOptionsResponseBody>> {
        if (requestParameters.catalogTypeId === null || requestParameters.catalogTypeId === undefined) {
            throw new runtime.RequiredError('catalogTypeId','Required parameter requestParameters.catalogTypeId was null or undefined when calling insightsListCatalogEntryOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.catalogTypeId !== undefined) {
            queryParameters['catalog_type_id'] = requestParameters.catalogTypeId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.entryIds) {
            queryParameters['entry_ids'] = requestParameters.entryIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/catalog_entry_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListCatalogEntryOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List entries for a catalog type, for use with typeaheads. This will return the right fields for demo / sample data as needed.
     * ListCatalogEntryOptions Insights
     */
    async insightsListCatalogEntryOptions(requestParameters: InsightsListCatalogEntryOptionsRequest, initOverrides?: RequestInit): Promise<InsightsListCatalogEntryOptionsResponseBody> {
        const response = await this.insightsListCatalogEntryOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List catalog types in an org. This will return the right types for demo / sample data as needed.
     * ListCatalogTypes Insights
     */
    async insightsListCatalogTypesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListCatalogTypesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/catalog_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListCatalogTypesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List catalog types in an org. This will return the right types for demo / sample data as needed.
     * ListCatalogTypes Insights
     */
    async insightsListCatalogTypes(initOverrides?: RequestInit): Promise<InsightsListCatalogTypesResponseBody> {
        const response = await this.insightsListCatalogTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all our core dashboards.
     * ListCoreDashboards Insights
     */
    async insightsListCoreDashboardsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListCoreDashboardsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/core_dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListCoreDashboardsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all our core dashboards.
     * ListCoreDashboards Insights
     */
    async insightsListCoreDashboards(initOverrides?: RequestInit): Promise<InsightsListCoreDashboardsResponseBody> {
        const response = await this.insightsListCoreDashboardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all our custom dashboards.
     * ListCustomDashboards Insights
     */
    async insightsListCustomDashboardsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListCustomDashboardsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/custom_dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListCustomDashboardsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all our custom dashboards.
     * ListCustomDashboards Insights
     */
    async insightsListCustomDashboards(initOverrides?: RequestInit): Promise<InsightsListCustomDashboardsResponseBody> {
        const response = await this.insightsListCustomDashboardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List options for a custom field, for use with typeaheads. This will return the right fields for demo / sample data as needed.
     * ListCustomFieldOptions Insights
     */
    async insightsListCustomFieldOptionsRaw(requestParameters: InsightsListCustomFieldOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListCustomFieldOptionsResponseBody>> {
        if (requestParameters.customFieldId === null || requestParameters.customFieldId === undefined) {
            throw new runtime.RequiredError('customFieldId','Required parameter requestParameters.customFieldId was null or undefined when calling insightsListCustomFieldOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.customFieldId !== undefined) {
            queryParameters['custom_field_id'] = requestParameters.customFieldId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.optionIds) {
            queryParameters['option_ids'] = requestParameters.optionIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/custom_field_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListCustomFieldOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List options for a custom field, for use with typeaheads. This will return the right fields for demo / sample data as needed.
     * ListCustomFieldOptions Insights
     */
    async insightsListCustomFieldOptions(requestParameters: InsightsListCustomFieldOptionsRequest, initOverrides?: RequestInit): Promise<InsightsListCustomFieldOptionsResponseBody> {
        const response = await this.insightsListCustomFieldOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all insights report schedules
     * ListReportSchedules Insights
     */
    async insightsListReportSchedulesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsListReportSchedulesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/report_schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsListReportSchedulesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all insights report schedules
     * ListReportSchedules Insights
     */
    async insightsListReportSchedules(initOverrides?: RequestInit): Promise<InsightsListReportSchedulesResponseBody> {
        const response = await this.insightsListReportSchedulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the details of a specific core dashboard
     * ShowCoreDashboard Insights
     */
    async insightsShowCoreDashboardRaw(requestParameters: InsightsShowCoreDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsShowCoreDashboardResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsShowCoreDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/core_dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsShowCoreDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the details of a specific core dashboard
     * ShowCoreDashboard Insights
     */
    async insightsShowCoreDashboard(requestParameters: InsightsShowCoreDashboardRequest, initOverrides?: RequestInit): Promise<InsightsShowCoreDashboardResponseBody> {
        const response = await this.insightsShowCoreDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the details of a specific custom dashboard
     * ShowCustomDashboard Insights
     */
    async insightsShowCustomDashboardRaw(requestParameters: InsightsShowCustomDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsShowCustomDashboardResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsShowCustomDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/custom_dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsShowCustomDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the details of a specific custom dashboard
     * ShowCustomDashboard Insights
     */
    async insightsShowCustomDashboard(requestParameters: InsightsShowCustomDashboardRequest, initOverrides?: RequestInit): Promise<InsightsShowCustomDashboardResponseBody> {
        const response = await this.insightsShowCustomDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single insights report schedule
     * ShowReportSchedule Insights
     */
    async insightsShowReportScheduleRaw(requestParameters: InsightsShowReportScheduleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsShowReportScheduleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsShowReportSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/report_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsShowReportScheduleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single insights report schedule
     * ShowReportSchedule Insights
     */
    async insightsShowReportSchedule(requestParameters: InsightsShowReportScheduleRequest, initOverrides?: RequestInit): Promise<InsightsShowReportScheduleResponseBody> {
        const response = await this.insightsShowReportScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a custom dashboard to the user\'s favourite dashboards.
     * StarInsightsDashboard Insights
     */
    async insightsStarInsightsDashboardRaw(requestParameters: InsightsStarInsightsDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsStarInsightsDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/custom_dashboards/{id}/actions/star`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a custom dashboard to the user\'s favourite dashboards.
     * StarInsightsDashboard Insights
     */
    async insightsStarInsightsDashboard(requestParameters: InsightsStarInsightsDashboardRequest, initOverrides?: RequestInit): Promise<void> {
        await this.insightsStarInsightsDashboardRaw(requestParameters, initOverrides);
    }

    /**
     * Sends a test insights report to the current user
     * TestInsightsReport Insights
     */
    async insightsTestInsightsReportRaw(requestParameters: InsightsTestInsightsReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.testInsightsReportRequestBody === null || requestParameters.testInsightsReportRequestBody === undefined) {
            throw new runtime.RequiredError('testInsightsReportRequestBody','Required parameter requestParameters.testInsightsReportRequestBody was null or undefined when calling insightsTestInsightsReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/report_schedules/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsTestInsightsReportRequestBodyToJSON(requestParameters.testInsightsReportRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends a test insights report to the current user
     * TestInsightsReport Insights
     */
    async insightsTestInsightsReport(requestParameters: InsightsTestInsightsReportRequest, initOverrides?: RequestInit): Promise<void> {
        await this.insightsTestInsightsReportRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a custom dashboard from the user\'s favourite dashboards.
     * UnstarInsightsDashboard Insights
     */
    async insightsUnstarInsightsDashboardRaw(requestParameters: InsightsUnstarInsightsDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsUnstarInsightsDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/insights/custom_dashboards/{id}/actions/unstar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a custom dashboard from the user\'s favourite dashboards.
     * UnstarInsightsDashboard Insights
     */
    async insightsUnstarInsightsDashboard(requestParameters: InsightsUnstarInsightsDashboardRequest, initOverrides?: RequestInit): Promise<void> {
        await this.insightsUnstarInsightsDashboardRaw(requestParameters, initOverrides);
    }

    /**
     * Update a custom dashboard.
     * UpdateDashboard Insights
     */
    async insightsUpdateDashboardRaw(requestParameters: InsightsUpdateDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsUpdateDashboardResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsUpdateDashboard.');
        }

        if (requestParameters.updateDashboardRequestBody === null || requestParameters.updateDashboardRequestBody === undefined) {
            throw new runtime.RequiredError('updateDashboardRequestBody','Required parameter requestParameters.updateDashboardRequestBody was null or undefined when calling insightsUpdateDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsUpdateDashboardRequestBodyToJSON(requestParameters.updateDashboardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsUpdateDashboardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a custom dashboard.
     * UpdateDashboard Insights
     */
    async insightsUpdateDashboard(requestParameters: InsightsUpdateDashboardRequest, initOverrides?: RequestInit): Promise<InsightsUpdateDashboardResponseBody> {
        const response = await this.insightsUpdateDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an insights report schedule
     * UpdateReportSchedule Insights
     */
    async insightsUpdateReportScheduleRaw(requestParameters: InsightsUpdateReportScheduleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InsightsUpdateReportScheduleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insightsUpdateReportSchedule.');
        }

        if (requestParameters.updateReportScheduleRequestBody === null || requestParameters.updateReportScheduleRequestBody === undefined) {
            throw new runtime.RequiredError('updateReportScheduleRequestBody','Required parameter requestParameters.updateReportScheduleRequestBody was null or undefined when calling insightsUpdateReportSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/insights/report_schedules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InsightsUpdateReportScheduleRequestBodyToJSON(requestParameters.updateReportScheduleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsightsUpdateReportScheduleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an insights report schedule
     * UpdateReportSchedule Insights
     */
    async insightsUpdateReportSchedule(requestParameters: InsightsUpdateReportScheduleRequest, initOverrides?: RequestInit): Promise<InsightsUpdateReportScheduleResponseBody> {
        const response = await this.insightsUpdateReportScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
