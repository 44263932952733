import { Mode } from "@incident-shared/forms/v2/formsv2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Drawer, DrawerContentsLoading } from "@incident-ui/Drawer/Drawer";
import { useParams } from "react-router";

import { useAPI } from "../../../../utils/swr";
import { NudgesCreateEditForm } from "../common/NudgesCreateEditForm";

export const NudgesEditDrawer = ({
  renameToChannelSuggestion,
  onClose,
}: {
  renameToChannelSuggestion: boolean;
  onClose: () => void;
}): React.ReactElement => {
  const { id } = useParams();
  if (!id) {
    throw new Error("Missing nudge id");
  }

  const navigate = useOrgAwareNavigate();
  const { data, isLoading } = useAPI("nudgesShow", { id: id });

  return (
    <>
      {isLoading || !data ? (
        <Drawer width="medium" onClose={() => navigate("settings/nudges")}>
          <DrawerContentsLoading />
        </Drawer>
      ) : (
        <NudgesCreateEditForm
          renameToChannelSuggestion={renameToChannelSuggestion}
          onClose={onClose}
          initialData={data.nudge}
          mode={Mode.Edit}
          nudgeType={data.nudge.nudge_type}
        />
      )}
    </>
  );
};
