import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Card, OrgAwareLink } from "@incident-ui";
import React from "react";
import { useProductAccess } from "src/hooks/useProductAccess";

import {
  SettingsPage,
  SettingsSection,
  SettingsSubPageWrapper,
} from "../SettingsRoute";

export const SettingsOverviewPage = ({
  sections,
}: {
  sections: SettingsSection[];
}): React.ReactElement => {
  return (
    <SettingsSubPageWrapper>
      <div className="grid gap-8 grid-cols-1 ">
        {sections
          .filter((x) => x.showOnOverview)
          .map((section) => (
            <div key={section.label}>
              <p className="text-sm text-slate-700 mb-4">
                {section.overviewSummary}
              </p>
              <SettingsOverviewSection
                items={section.items}
                color={section.color as ColorPaletteEnum}
              />
            </div>
          ))}
      </div>
    </SettingsSubPageWrapper>
  );
};

export const SettingsOverviewSection = ({
  items,
  color,
}: {
  items: SettingsPage[];
  color: ColorPaletteEnum;
}): React.ReactElement => {
  const { hasProduct } = useProductAccess();

  return (
    <div className="grid gap-4 xl:grid-cols-3 md:grid-cols-2 grid-cols-1">
      {items
        .filter((item) => !item.hide)
        .sort((a, b) => {
          const aHasRequired =
            a.requiredProduct && hasProduct(a.requiredProduct);
          const bHasRequired =
            b.requiredProduct && hasProduct(b.requiredProduct);

          if (aHasRequired && !bHasRequired) {
            return -1; // a should come before b
          }
          if (!aHasRequired && bHasRequired) {
            return 1; // a should come after b
          }
          if (!a.requiredProduct && b.requiredProduct) {
            return -1; // a should come before b
          }
          if (a.requiredProduct && !b.requiredProduct) {
            return 1; // a should come after b
          }
          return 0; // keep their relative positions
        })
        .map((item) => (
          <OrgAwareLink to={`/settings/${item.slug}`} key={item.slug}>
            <Card
              key={item.slug}
              icon={item.icon}
              title={item.label}
              description={item.overviewDescription}
              color={color}
              requiredProduct={item.requiredProduct}
            />
          </OrgAwareLink>
        ))}
    </div>
  );
};
