import { usePylon } from "@bolasim/react-use-pylon";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import {
  FormCustomFieldEntries,
  marshallCustomFieldEntriesToRequestPayload,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import {
  DeclareFormData,
  FormElements,
  marshallFormElementDataToIncidentForm,
  marshallIncidentRolesToFormData,
  marshallTextDocumentPayload,
  useElementBindings,
} from "@incident-shared/incident-forms";
import {
  VisibilityData,
  VisibilityElement,
} from "@incident-shared/incident-forms/VisibilityElement";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Toggle,
} from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  FieldNamesMarkedBoolean,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  AvailableIncidentFormLifecycleElementElementTypeEnum as FormElementType,
  CustomFieldEntryPayload,
  IncidentFormLifecycleElementBinding,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum as IncidentFormType,
  IncidentsCreateRequestBody,
  IncidentsCreateRequestBodyModeEnum,
  IncidentsCreateRequestBodyVisibilityEnum as VisibilityEnum,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  RetrospectiveIncidentOptions,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { getLocalTimeZone } from "src/utils/datetime";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";
import { v4 as uuid } from "uuid";

import {
  getCustomFieldErrorPath,
  isCustomFieldError,
} from "./ActiveIncidentCreateModal";
import { buildIncidentTypeOptions } from "./buildIncidentTypeOptions";
import { buildSlackTeamOptions } from "./buildSlackTeamOptions";
import { IncidentCrudResourceTypes } from "./useIncidentCrudResources";

export type RetrospectiveIncidentFormData = Omit<
  DeclareFormData,
  "active_or_triage"
> & {
  retrospective_incident_options: RetrospectiveIncidentOptions;
};

function formDataToRequestBody(
  ourElementBindings: IncidentFormLifecycleElementBinding[],
  formData: RetrospectiveIncidentFormData,
  touchedFields: Partial<
    Readonly<FieldNamesMarkedBoolean<RetrospectiveIncidentFormData>>
  >,
): IncidentsCreateRequestBody {
  const incidentFormRequestBody = marshallFormElementDataToIncidentForm(
    ourElementBindings,
    formData,
    touchedFields,
  );

  return {
    ...formData,
    ...incidentFormRequestBody,
    mode: IncidentsCreateRequestBodyModeEnum.Retrospective,
    summary: marshallTextDocumentPayload(formData.summary),
  };
}

export const RetrospectiveIncidentForm = ({
  incidentTypes,
  settings,
  slackTeamConfigs,
  formMethods,
  elementBindings,
  customFieldEntryPayloads,
}: {
  formMethods: UseFormReturn<RetrospectiveIncidentFormData>;
  elementBindings: IncidentFormLifecycleElementBinding[];
  customFieldEntryPayloads: CustomFieldEntryPayload[];
} & IncidentCrudResourceTypes): React.ReactElement => {
  const apiClient = useClient();

  const { identity } = useIdentity();
  const { showKnowledgeBaseArticle } = usePylon();

  const {
    data: { incident_lifecycles: incidentLifecycles },
  } = useAPI("incidentLifecyclesList", undefined, {
    fallbackData: { incident_lifecycles: [] },
  });

  const defaultIncidentLifecycle = incidentLifecycles.find((l) => l.is_default);
  const defaultIncidentType = incidentTypes.find((it) => it.is_default);

  if (!defaultIncidentType) {
    throw new Error("could not find default incident type");
  }

  const slackTeamIds = useIdentity().identity.slack_info?.team_ids;
  const accessibleSlackTeamConfigs = slackTeamConfigs.filter(
    (team) =>
      slackTeamIds === undefined ||
      slackTeamIds.length === 0 ||
      slackTeamIds.includes(team.slack_team_id),
  );

  const { watch, resetField } = formMethods;
  const [selectedIncidentTypeId, selectedSlackTeamId] = watch([
    "incident_type_id",
    "slack_team_id",
  ]);

  const { incidentTypeOptions, selectedIncidentType } =
    buildIncidentTypeOptions({
      selectedIncidentTypeID: selectedIncidentTypeId,
      incidentTypes,
      includeNotYetKnownOption: false,
    });

  const { slackTeamOptions } = buildSlackTeamOptions({
    selectedSlackTeamID: selectedSlackTeamId,
    slackTeamConfigs: accessibleSlackTeamConfigs,
  });

  const [hasSlackChannel, setHasSlackChannel] = useState(false);

  // We do this while there is no restrospective form type. In the future we can
  // just render all form elements together
  const nameFormElement = elementBindings.filter(
    (e) => e.element.available_element.element_type === FormElementType.Name,
  );

  // The visibility element is currently rendered separately but can be changed
  // with the above
  const otherElementBindings = elementBindings.filter(
    (e) =>
      e.element.available_element.element_type !== FormElementType.Name &&
      e.element.available_element.element_type !== FormElementType.Visibility,
  );

  const hasTimestampElement = elementBindings.some(
    (e) =>
      e.element.available_element.element_type === FormElementType.Timestamp,
  );

  const lifecycle = selectedIncidentType?.override_incident_lifecycle_id
    ? incidentLifecycles.find(
        (l) => l.id === selectedIncidentType.override_incident_lifecycle_id,
      )
    : defaultIncidentLifecycle;

  return (
    <>
      <Callout theme={CalloutTheme.Info}>
        Retrospective incidents are historical, closed incidents. We&rsquo;ll
        only announce the incident if you opt-in. Some of your workflows may not
        be configured to run on retrospective incidents.
      </Callout>
      {hasTimestampElement && (
        <Form.Helptext>
          <>
            {`All times are in your local time zone: `}
            <strong>{getLocalTimeZone(new Date())}.</strong>
          </>
        </Form.Helptext>
      )}
      {/* When we have a retrospective form type this can be changed */}
      <FormElements
        elementBindings={nameFormElement}
        formMethods={formMethods}
        selectedIncidentType={selectedIncidentType}
        incidentFormType={IncidentFormType.Resolve}
        customFieldEntryPayloads={customFieldEntryPayloads}
      />
      {/* Currently the restropective form is a "resolve" form, so we need to render the incident type separately */}
      {/* Incident type */}
      {identity?.incident_types_enabled && incidentTypeOptions.length > 0 ? (
        <>
          <StaticSingleSelectV2
            formMethods={formMethods}
            name="incident_type_id"
            options={incidentTypeOptions}
            placeholder="Choose an incident type"
            label={"Incident Type"}
            isClearable={false}
            required={true}
            onValueChange={() => {
              resetField("severity_id");
            }}
          />
          <p className="mt-1 text-xs text-slate-700">
            {selectedIncidentType?.description}
          </p>
        </>
      ) : null}
      <FormElements
        elementBindings={otherElementBindings}
        formMethods={formMethods}
        selectedIncidentType={selectedIncidentType}
        incidentFormType={IncidentFormType.Resolve}
        customFieldEntryPayloads={customFieldEntryPayloads}
      />
      {/* Slack workspace */}
      {slackTeamOptions.length > 1 && (
        <StaticSingleSelectV2<RetrospectiveIncidentFormData>
          formMethods={formMethods}
          name="slack_team_id"
          options={slackTeamOptions}
          placeholder="Choose a Slack workspace"
          isClearable={false}
          required
          helptext="Which Slack workspace should the incident channel be created for?"
          label="Slack workspace"
        />
      )}
      {/* Slack channel */}
      <div>
        <Toggle
          id="has_slack_channel"
          onToggle={() => {
            // Clear the Slack channel when toggling off
            if (hasSlackChannel) {
              resetField("retrospective_incident_options.slack_channel_id");
            }
            setHasSlackChannel(!hasSlackChannel);
          }}
          on={hasSlackChannel}
          label="Does this incident have a Slack channel?"
        />
        <Form.Helptext>
          If this incident has an existing Slack channel, you can link it here.
        </Form.Helptext>
        {hasSlackChannel && (
          <DynamicSingleSelectV2
            formMethods={formMethods}
            name="retrospective_incident_options.slack_channel_id"
            placeholder="Select a Slack channel"
            loadOptions={getTypeaheadOptions(
              apiClient,
              TypeaheadTypeEnum.SlackChannel,
              {},
            )}
            hydrateOptions={hydrateInitialSelectOptions(
              apiClient,
              TypeaheadTypeEnum.SlackChannel,
            )}
            isClearable={true}
          />
        )}
      </div>
      {/* Visibility */}
      <VisibilityElement
        formMethods={formMethods as unknown as UseFormReturn<VisibilityData>}
        selectedIncidentType={selectedIncidentType}
        hasSelectedIncidentTypeDontKnow={false} // This isn't possible in a retrospective incident
        settings={settings}
      />
      {/* Announcements Enabled */}
      <ToggleV2
        formMethods={formMethods}
        name="retrospective_incident_options.announcements_enabled"
        label="Should we announce this incident?"
      />
      <Form.Helptext className="!mt-1">
        Read more about announcements{" "}
        <Button
          theme={ButtonTheme.Naked}
          className="underline"
          analyticsTrackingId="retrospective-incidents-announcements-help"
          onClick={() => showKnowledgeBaseArticle("3129915986")}
        >
          here
        </Button>
        .
      </Form.Helptext>
      {/* Start in post-incident flow */}
      {lifecycle?.post_incident_flow_enabled && (
        <>
          <ToggleV2
            formMethods={formMethods}
            name="retrospective_incident_options.enter_post_incident_flow"
            label="Should this incident enter the post-incident flow?"
          />
          <Form.Helptext className="!mt-1">
            When toggled, the incident will be created in the first
            post-incident status rather than closed.
          </Form.Helptext>
        </>
      )}
    </>
  );
};

export const useRetrospectiveIncidentForm = ({
  customFields,
  incidentRoles,
  severities,
  incidentTypes,
  allStatuses,
  slackTeamConfigs,
  onSuccess,
}: IncidentCrudResourceTypes & {
  onSuccess: () => void;
  allStatuses: IncidentStatus[];
}): {
  formMethods: UseFormReturn<RetrospectiveIncidentFormData>;
  genericError: string | undefined;
  saving: boolean;
  onSubmit: SubmitHandler<RetrospectiveIncidentFormData>;
  confirmButtonText: string;
  elementBindings: IncidentFormLifecycleElementBinding[];
  customFieldEntryPayloads: CustomFieldEntryPayload[];
  disabled: boolean;
} => {
  const { enableRetrospectiveIncidentAnnouncementsByDefault } = useFlags();
  const navigate = useOrgAwareNavigate();

  const minSeverityId = _.minBy(severities, "rank")?.id;
  const defaultIncidentType = incidentTypes.find((it) => it.is_default);

  if (!defaultIncidentType) {
    throw new Error("could not find default incident type");
  }

  const slackTeamIds = useIdentity().identity.slack_info?.team_ids;
  const accessibleSlackTeamConfigs = slackTeamConfigs.filter(
    (team) =>
      slackTeamIds === undefined ||
      slackTeamIds.length === 0 ||
      slackTeamIds.includes(team.slack_team_id),
  );

  const formMethods = useForm<RetrospectiveIncidentFormData>({
    defaultValues: {
      retrospective_incident_options: {
        // Set announcement_enabled to true by default for organisations that request for it
        announcements_enabled:
          enableRetrospectiveIncidentAnnouncementsByDefault,
      },
      severity_id: minSeverityId,
      idempotency_key: uuid(),
      visibility: defaultIncidentType.private_incidents_only
        ? VisibilityEnum.Private
        : VisibilityEnum.Public,
      custom_field_entries: customFields.reduce(
        (all, field) => ({
          ...all,
          [field.id]: { values: [], hasBeenManuallyEdited: false },
        }),
        {} as FormCustomFieldEntries,
      ),
      incident_role_assignments: incidentRoles.reduce(
        (all, role) => ({ ...all, [role.id]: [] }),
        {},
      ),
      incident_type_id: defaultIncidentType.id,
      slack_team_id:
        accessibleSlackTeamConfigs.length === 1
          ? accessibleSlackTeamConfigs[0].slack_team_id
          : undefined,
    },
  });
  const { watch, setError, setValue, getValues } = formMethods;
  const [
    selectedSeverityId,
    selectedCustomFieldEntries,
    selectedIncidentRoleAssignments,
    selectedIncidentTypeId,
  ] = watch([
    "severity_id",
    "custom_field_entries",
    "incident_role_assignments",
    "incident_type_id",
  ]);

  const { selectedIncidentType } = buildIncidentTypeOptions({
    selectedIncidentTypeID: selectedIncidentTypeId,
    incidentTypes,
    includeNotYetKnownOption: false,
  });

  // Update the visibility field everytime we change to/from an incident
  // type that requires private incidents.
  useEffect(() => {
    if (selectedIncidentType?.private_incidents_only) {
      setValue<"visibility">("visibility", VisibilityEnum.Private);
    } else {
      setValue<"visibility">("visibility", VisibilityEnum.Public);
    }
  }, [selectedIncidentType, setValue]);

  const customFieldEntries = marshallCustomFieldEntriesToRequestPayload(
    customFields,
    formMethods.formState.touchedFields,
    selectedCustomFieldEntries,
  );
  const incidentRoleAssignments = marshallIncidentRolesToFormData({
    incidentRoles,
    formAssignments: selectedIncidentRoleAssignments,
  });

  const firstClosedStatus = allStatuses.filter(
    (x) => x.category === IncidentStatusCategoryEnum.Closed,
  )[0];

  const elementBindingsPayload = {
    incident_form_type: IncidentFormType.Resolve,
    incident_type_id: selectedIncidentTypeId,
    incident_status_id: firstClosedStatus.id,
    severity_id: selectedSeverityId,
    custom_field_entries: customFieldEntries,
    incident_role_assignments: incidentRoleAssignments,
    show_all_elements_override: false,
  };
  const { elementBindings, isLoading: isLoadingElementBindings } =
    useElementBindings<RetrospectiveIncidentFormData>({
      payload: elementBindingsPayload,
      setValue,
      initialValues: formMethods.formState.defaultValues,
      touchedFields: formMethods.formState.touchedFields,
      manualEdits: [],
    });
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, formData: RetrospectiveIncidentFormData) => {
      const createRequestBody = formDataToRequestBody(
        elementBindings,
        formData,
        formMethods.formState.touchedFields,
      );

      const { incident } = await apiClient.incidentsCreate({
        createRequestBody,
      });
      navigate(`/incidents/${incident.id}/edit-timestamps`);
    },
    {
      onSuccess: () => {
        refreshIncidentList();
        onSuccess();
      },
      setError: (path, error) => {
        if (!isCustomFieldError(path)) {
          setError(path, error);
          return;
        }

        const requestData = formDataToRequestBody(
          elementBindings,
          getValues(),
          formMethods.formState.touchedFields,
        );

        setError(
          getCustomFieldErrorPath<RetrospectiveIncidentFormData>(
            path,
            requestData.custom_field_entries ?? [],
          ),
          error,
        );
      },
    },
  );

  return {
    elementBindings,
    formMethods,
    onSubmit,
    genericError,
    saving,
    confirmButtonText: "Continue to set timestamps",
    customFieldEntryPayloads: customFieldEntries,
    disabled: isLoadingElementBindings,
  };
};
