import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { InterpolatedRef } from "@incident-shared/forms/v1/TemplatedText/TemplatedTextEditor";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { FlowCards } from "@incident-ui/FlowCards/FlowCards";
import React from "react";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { EnrichedScope } from "src/utils/scope";

import { ParamWithBinding, WorkflowStepListItem } from "../common/types";

type WorkflowStepCardProps = {
  variableScope: EnrichedScope<InterpolatedRef>;
  step: WorkflowStepListItem;
  stepNumber: number;
  // Required to make the card draggable.
  innerRef?: React.Ref<HTMLDivElement>;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  draggableProps: DraggableProvidedDraggableProps | null | undefined;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  onEditStep: () => void;
  onRemoveStep: () => void;
  className?: string;
};

// WorkflowStepCard renders a card that represents a step
// in a workflow, it can be used within or outside of a
// looping step.
export const WorkflowStepCard = ({
  step,
  variableScope,
  stepNumber,
  onEditStep,
  onRemoveStep,
  innerRef,
  dragHandleProps,
  draggableProps,
  className,
}: WorkflowStepCardProps): React.ReactElement => {
  const paramBindingsToShow: ParamWithBinding[] = step.step.param_bindings
    .map((binding, idx) => {
      const param = step.step.params[idx];
      return { param, binding };
    })
    .filter(({ param }) => {
      return !param.infer_reference;
    });

  return (
    <FlowCards.Card
      ref={innerRef}
      {...draggableProps}
      iconNode={
        <div {...dragHandleProps}>
          <Icon id={IconEnum.Draggable} size={IconSize.Small} />
        </div>
      }
      title={step.step.label}
      className={className}
      accessory={
        <div className="flex items-center gap-1">
          <Button
            analyticsTrackingId="workflows-step-edit"
            onClick={onEditStep}
            theme={ButtonTheme.Naked}
            icon={IconEnum.Edit}
            title="Edit"
          />
          <Button
            analyticsTrackingId="workflows-step-delete"
            onClick={onRemoveStep}
            theme={ButtonTheme.Naked}
            icon={IconEnum.Delete}
            title="Delete"
          />
        </div>
      }
    >
      {/* Step content section */}
      {paramBindingsToShow.length > 0 && (
        <div className="text-sm flex flex-col gap-5 w-full">
          {paramBindingsToShow.map(({ binding, param }, idx) => {
            return (
              <div
                key={`pb-${stepNumber}-${idx}`}
                className="text-sm flex flex-col gap-1"
              >
                <div className="font-normal text-slate-600">{param?.label}</div>
                <div className="text-content-primary flex min-w-0 overflow-hidden">
                  <EngineBinding
                    binding={binding}
                    variableScope={variableScope}
                    resourceType={param.type}
                    displayExpressionAs="pill"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </FlowCards.Card>
  );
};
