import type { SVGProps } from "react";
import * as React from "react";
const SvgFollowUpsAiGradient = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#follow-ups-ai-gradient_svg__a)"
      d="m7.494 4.492-.946-.316-.316-.947c-.102-.305-.609-.305-.711 0l-.316.947-.946.316a.375.375 0 0 0 0 .712l.946.315.316.946a.374.374 0 0 0 .71 0l.316-.946.946-.316a.375.375 0 0 0 .001-.712Z"
    />
    <path
      fill="url(#follow-ups-ai-gradient_svg__b)"
      d="M2.25 7a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
    />
    <path
      fill="url(#follow-ups-ai-gradient_svg__c)"
      d="m17.586 10.004-6.58-4.991a1.245 1.245 0 0 0-1.312-.125A1.241 1.241 0 0 0 9 6.008V8H3.75C2.785 8 2 8.786 2 9.75v2.431a2 2 0 0 1 1.5-.681c.868 0 1.631.534 1.896 1.33l.186.553.552.184c.272.09.498.25.701.434h2.166v1.992a1.254 1.254 0 0 0 1.251 1.251c.267 0 .531-.085.754-.255l6.581-4.993a1.24 1.24 0 0 0 .494-.996c0-.396-.18-.758-.494-.996l-.001-.002Z"
    />
    <path
      fill="url(#follow-ups-ai-gradient_svg__d)"
      d="m5.659 14.99-1.263-.42-.421-1.264c-.137-.407-.812-.407-.949 0l-.421 1.264-1.263.42a.5.5 0 0 0 0 .948l1.263.421.421 1.263a.501.501 0 0 0 .95 0l.421-1.262 1.263-.421a.5.5 0 0 0-.001-.948Z"
    />
    <defs>
      <linearGradient
        id="follow-ups-ai-gradient_svg__a"
        x1={1}
        x2={20.622}
        y1={17.965}
        y2={8.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#F25533" />
        <stop offset={0.9} stopColor="#F1CD98" />
        <stop offset={1} stopColor="#F8F5EF" />
      </linearGradient>
      <linearGradient
        id="follow-ups-ai-gradient_svg__b"
        x1={1}
        x2={20.622}
        y1={17.965}
        y2={8.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#F25533" />
        <stop offset={0.9} stopColor="#F1CD98" />
        <stop offset={1} stopColor="#F8F5EF" />
      </linearGradient>
      <linearGradient
        id="follow-ups-ai-gradient_svg__c"
        x1={1}
        x2={20.622}
        y1={17.965}
        y2={8.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#F25533" />
        <stop offset={0.9} stopColor="#F1CD98" />
        <stop offset={1} stopColor="#F8F5EF" />
      </linearGradient>
      <linearGradient
        id="follow-ups-ai-gradient_svg__d"
        x1={1}
        x2={20.622}
        y1={17.965}
        y2={8.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#F25533" />
        <stop offset={0.9} stopColor="#F1CD98" />
        <stop offset={1} stopColor="#F8F5EF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgFollowUpsAiGradient;
