/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestigationArtifact
 */
export interface InvestigationArtifact {
    /**
     * Content of the artifact
     * @type {string}
     * @memberof InvestigationArtifact
     */
    content: string;
    /**
     * Unique identifier of the artifact
     * @type {string}
     * @memberof InvestigationArtifact
     */
    id: string;
    /**
     * Identifier of associated image
     * @type {string}
     * @memberof InvestigationArtifact
     */
    image_id?: string;
    /**
     * URL to access the image
     * @type {string}
     * @memberof InvestigationArtifact
     */
    image_url?: string;
    /**
     * Check that generated this artifact
     * @type {string}
     * @memberof InvestigationArtifact
     */
    investigation_check_id: string;
    /**
     * Investigation this artifact belongs to
     * @type {string}
     * @memberof InvestigationArtifact
     */
    investigation_id: string;
    /**
     * Name of the artifact
     * @type {string}
     * @memberof InvestigationArtifact
     */
    name: string;
}

export function InvestigationArtifactFromJSON(json: any): InvestigationArtifact {
    return InvestigationArtifactFromJSONTyped(json, false);
}

export function InvestigationArtifactFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationArtifact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'id': json['id'],
        'image_id': !exists(json, 'image_id') ? undefined : json['image_id'],
        'image_url': !exists(json, 'image_url') ? undefined : json['image_url'],
        'investigation_check_id': json['investigation_check_id'],
        'investigation_id': json['investigation_id'],
        'name': json['name'],
    };
}

export function InvestigationArtifactToJSON(value?: InvestigationArtifact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'id': value.id,
        'image_id': value.image_id,
        'image_url': value.image_url,
        'investigation_check_id': value.investigation_check_id,
        'investigation_id': value.investigation_id,
        'name': value.name,
    };
}

