import { usePylon } from "@bolasim/react-use-pylon";
import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, ContentBox, Txt } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { default as React } from "react";
import { useIdentity } from "src/contexts/IdentityContext";

import { SettingsSubHeading } from "../SettingsSubHeading";
import { UpsellNotice } from "../UpsellNotice";

const heading = "Audit logs";

export const AuditLogsCard = (): React.ReactElement => {
  const { identity } = useIdentity();
  const { showKnowledgeBaseArticle: showArticle } = usePylon();
  const { permanentAuditLogsAccess } = useFlags();

  if (!identity?.feature_gates.audit_logs && !permanentAuditLogsAccess) {
    return <AuditLogsUpsellBanner />;
  }

  return (
    <ContentBox className="p-6">
      <div className="flex">
        <div className="grow">
          <SettingsSubHeading
            title={heading}
            explanation={
              <>
                Gain visibility over the changes that are made within your
                incident.io account, powered by WorkOS. You can learn more in
                our{" "}
                <Button
                  theme={ButtonTheme.Link}
                  analyticsTrackingId={"audit-logs-learn-more"}
                  onClick={() => showArticle("9416833228")}
                >
                  help center
                </Button>
                .
              </>
            }
          />
          <div className="mt-2 space-y-4">
            <AuditLogsCardInner />
          </div>
        </div>
      </div>
    </ContentBox>
  );
};

const AuditLogsCardInner = (): React.ReactElement => {
  const { hasScope, identity } = useIdentity();
  const canViewAuditLogs = hasScope(ScopeNameEnum.AuditLogsView);
  const tooltipContent = canViewAuditLogs
    ? undefined
    : "You don't have permission to view audit logs.";

  const urlForIntent = (intent: string) =>
    `/auth/workos_portal?intent=${intent}&organisation_id=${identity?.organisation_id}`;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mb-4">
        <GatedButton
          analyticsTrackingId={"view-audit-logs-button"}
          disabledTooltipContent={tooltipContent}
          disabled={!canViewAuditLogs}
          href={urlForIntent("audit_logs")}
          openInNewTab={true}
          className="mr-2"
        >
          View audit logs
        </GatedButton>

        <GatedButton
          analyticsTrackingId={"configure-log-stream"}
          disabledTooltipContent={tooltipContent}
          disabled={!canViewAuditLogs}
          href={urlForIntent("log_streams")}
          openInNewTab={true}
          className="mr-2"
        >
          Configure log streaming
        </GatedButton>
      </div>
      <div className="flex flex-col">
        <Txt className="text-xs text-content-tertiary">
          Audit log entries are available in WorkOS for 365 days.
        </Txt>
      </div>
    </div>
  );
};

const AuditLogsUpsellBanner = (): React.ReactElement => {
  return (
    <UpsellNotice
      analyticsId={"audit-logs-upsell-banner"}
      title={heading}
      planName={"Enterprise"}
      description={
        "Gain visibility over the changes that are made within your incident.io account, powered by WorkOS."
      }
      articleId={"9416833228"}
    />
  );
};
