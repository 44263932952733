import {
  AlertRoute,
  AlertSourceConfig,
  ConditionGroup,
  ConditionSubject,
} from "@incident-io/api";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { Tooltip } from "@incident-ui";
import { useCallback, useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  Position,
  useReactFlow,
} from "reactflow";
import { tcx } from "src/utils/tailwind-classes";

import { getColor } from "../../../utils/twConfig";
import { useReduceOpacity } from "./AlertsConfigurationPage";

export interface AlertsConfigurationEdgeData {
  alertSource?: AlertSourceConfig;
  alertRoute?: AlertRoute;
  colorBucket?: number;
}

export const AlertsConfigurationEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}: {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  data?: AlertsConfigurationEdgeData;
}) => {
  const reactFlowInstance = useReactFlow();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const reduceOpacity = useReduceOpacity({ edgeId: id });

  const handleMouseMove = useCallback(
    (event: React.MouseEvent) => {
      const canvasPosition = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      setMousePosition((prev) => {
        // Avoid re-rendering for no reason
        if (prev.x === canvasPosition.x && prev.y === canvasPosition.y) {
          return prev;
        }

        return {
          x: canvasPosition.x,
          y: canvasPosition.y,
        };
      });
    },
    [reactFlowInstance],
  );

  const [edgePath] = getBezierPath({
    sourceX,
    // We add a small offset to the sourceY to create an invisible curvature to
    // the line that prevents it from disappearing when the source and target
    // nodes are aligned vertically
    //
    // This stackoverflow post explains the issue, but essentially linear
    // gradient uses an Object Bounding Box which does not support zero height
    // elements (like a line with the same source and target Y).
    //
    // https://stackoverflow.com/questions/73043945/why-does-a-svg-line-disappear-when-i-apply-a-svg-lineargradient
    //
    sourceY: sourceY === targetY ? sourceY + 0.001 : sourceY,
    targetX,
    targetY,
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    curvature: 0.1,
  });

  const sourceConditionGroups =
    data?.alertRoute?.alert_sources.find((source) => {
      return source.alert_source_id === data?.alertSource?.id;
    })?.condition_groups || [];

  const routeConditionGroups = data?.alertRoute?.condition_groups || [];

  const {
    conditionGroups: sourceConditions,
    conditionCount: sourceConditionCount,
  } = getNConditionsWithCount(sourceConditionGroups, 3);

  const {
    conditionGroups: routeConditions,
    conditionCount: routeConditionCount,
  } = getNConditionsWithCount(routeConditionGroups, 3);

  const edgeColor = {
    healthy: [
      getColor("green", "600"),
      getColor("green", "500"),
      getColor("green", "400"),
      getColor("green", "300"),
      getColor("green", "200"),
    ],
    never_fired: getColor("slate", "100"),
    fade: getColor("slate", "50"),
  };

  const alertSourceHasFired = data?.alertSource?.alert_last_fired_at;

  // Determine the stroke color based on your condition
  const strokeColor = !alertSourceHasFired
    ? edgeColor.never_fired
    : edgeColor.healthy[data?.colorBucket || 0];

  // Generate a unique gradient ID for each edge
  const gradientId = `gradient-${id}`;

  return (
    <>
      <g
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseMove={handleMouseMove}
      >
        <svg>
          <defs>
            {/* Define the gradient for unconnected sources */}
            <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor={strokeColor} />
              <stop offset="100%" stopColor={edgeColor.fade} />
            </linearGradient>
          </defs>

          <BaseEdge
            id={id}
            path={edgePath}
            style={{
              stroke: data?.alertRoute ? strokeColor : `url(#${gradientId})`,
              strokeWidth: "4px",
              strokeOpacity: reduceOpacity ? 0.2 : 1,
            }}
          />
        </svg>
        <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              transform: `translate(-5%, -50%) translate(${
                mousePosition.x
              }px, ${mousePosition.y + 15}px)`,
              pointerEvents: "all",
              zIndex: 100,
            }}
            className={tcx("rounded py-0.5 px-1", "nodrag nopan")}
          >
            <Tooltip
              delayDuration={0}
              content={
                sourceConditionCount + routeConditionCount > 0 ? (
                  <div className="space-y-2">
                    <ConditionGroupBox
                      title={`${data?.alertSource?.name} filters`}
                      groups={sourceConditions}
                      count={sourceConditionCount}
                    />
                    <ConditionGroupBox
                      title={"Global filters"}
                      groups={routeConditions}
                      count={routeConditionCount}
                    />
                  </div>
                ) : (
                  <div className={"text-white text-sm max-w-[350px]"}>
                    All alerts from {data?.alertSource?.name || "this source"}{" "}
                    will be routed to {data?.alertRoute?.name || "this route"}.
                  </div>
                )
              }
              side="bottom"
              openStateOverride={isHovered}
              bubbleProps={{ className: "!bg-content-primary" }}
              noMaxWidth
            >
              {/* This div means that we don't render the tooltip icon,
              and is needed to correctly position the popover. */}
              <div></div>
            </Tooltip>
          </div>
        </EdgeLabelRenderer>
      </g>
    </>
  );
};

const ConditionGroupBox = ({
  title,
  groups,
  count,
}: {
  title: string;
  groups: ConditionGroup[];
  count: number;
}) => {
  if (count === 0) {
    return null;
  }

  return (
    <div className={"max-w-[400px]"}>
      <div className="text-xs-med text-surface-primary pb-2">{title}:</div>
      <ConditionGroupsList
        groups={groups}
        className="p-0 bg-content-primary shadow-none"
        theme="dark"
        boxless
        noTooltip
      />
      {count > 3 ? (
        <div className="text-xs-med text-surface-primary pt-2">
          + {count - 3} more
        </div>
      ) : null}
    </div>
  );
};

const getNConditionsWithCount = (groups: ConditionGroup[], n: number) => {
  const conditionGroups: ConditionGroup[] = [];
  let conditionCount = 0;

  groups.forEach((group) => {
    // Create a new group to hold the filtered conditions
    const newGroup: ConditionGroup = { conditions: [] };

    group.conditions.forEach((condition) => {
      if (conditionCount < n) {
        // Add the condition to the new group, but modify the label as needed
        const modifiedCondition = {
          ...condition,
          subject: {
            ...condition.subject,
            label: condition.subject.label
              .split("→")
              .findLast(() => true)
              ?.trimStart(),
          } as ConditionSubject,
        };

        // Add the modified condition to the group
        newGroup.conditions.push(modifiedCondition);
      }

      // Increment the condition count
      conditionCount++;
    });

    // Only add the group if it contains conditions and if the condition count
    // is less than 3
    if (newGroup.conditions.length > 0) {
      conditionGroups.push(newGroup);
    }
  });

  return {
    conditionGroups,
    conditionCount,
  };
};
