import { IconEnum } from "@incident-ui";

import { NodeCard } from "./NodeCard";

// NodeDoNothing is used for empty branches
export const NodeDoNothing = (_: { id: string }) => {
  return (
    <NodeCard
      title="End"
      subtitle="Do nothing"
      icon={IconEnum.Stop}
      iconColor={{
        background: "bg-slate-surface",
        icon: "text-slate-content",
      }}
      // TODO: allow you to replace this with (e.g.) a level/channel node
    />
  );
};
