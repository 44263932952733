import { ScopeNameEnum } from "@incident-io/api";
import { NoPermissionMessage } from "@incident-shared/gates/gates";
import { BadgeSize } from "@incident-ui/Badge/Badge";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import {
  Popover,
  PopoverBody,
  PopoverContext,
  PopoverTitleBar,
} from "@incident-ui/Popover/Popover";
import {
  PopoverItem,
  PopoverItemGroup,
} from "@incident-ui/Popover/PopoverItem";
import { PopoverSearch } from "@incident-ui/Popover/PopoverSearch";
import { PopoverClose as RadixPopoverClose } from "@radix-ui/react-popover";
import React, { useContext, useEffect } from "react";
import { IconEnum, IconProps, IconSize } from "src/components/@ui/Icon/Icon";
import { Tooltip } from "src/components/@ui/Tooltip/Tooltip";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { usePortal } from "../../../contexts/PortalContext";

type DropdownSide = "top" | "right" | "bottom" | "left";

export type Props = {
  children: React.ReactNode;
  menuClassName?: string;
  side?: DropdownSide;
  sideOffset?: number;
  scroll?: boolean;
  disabled?: boolean;
  tooltipContent?: React.ReactNode;
  tooltipSide?: "top" | "right" | "bottom" | "left";
  align?: "end" | "start" | "center" | undefined;
  alignOffset?: number;
  menuWidthMatchesTrigger?: boolean; // If true, the width of the popover will match the width of the trigger button
  onOpenChange?: (isOpen: boolean) => void;
  search?: string;
  setSearch?: (search: string) => void;
} & (
  | {
      triggerButton?: never;
      triggerIcon: IconEnum;
      triggerIconSize?: IconSize;
      triggerButtonTheme?: ButtonTheme;
      triggerBadgeSize?: BadgeSize;
      analyticsTrackingId: string | null;
      screenReaderText: string;
    }
  | {
      triggerButton: React.ReactNode;
      triggerIcon?: never;
      triggerIconSize?: never;
      triggerButtonTheme?: never;
      triggerBadgeSize?: never;
      analyticsTrackingId?: never;
      screenReaderText?: never;
    }
);

export function DropdownMenuLink({
  label,
  to,
  analyticsTrackingId,
  className,
  openInNewTab,
  icon,
}: {
  label: string;
  to: string;
  analyticsTrackingId: string | null;
  className?: string;
  openInNewTab?: boolean;
  icon?: IconEnum;
}): React.ReactElement {
  return (
    <PopoverItem
      className={className}
      to={to}
      analyticsTrackingId={analyticsTrackingId}
      openInNewTab={openInNewTab}
      icon={icon}
    >
      {label}
    </PopoverItem>
  );
}

type DropdownMenuGroupProps = {
  label?: string;
  icon?: IconEnum;
  children: React.ReactNode;
  className?: string;
};

export function DropdownMenuGroup({
  label,
  icon,
  children,
  className,
}: DropdownMenuGroupProps): React.ReactElement {
  return (
    <PopoverItemGroup label={label} icon={icon} className={className}>
      {children}
    </PopoverItemGroup>
  );
}

export type DropdownMenuItemProps = {
  analyticsTrackingId: string | null;
  analyticsTrackingMetadata?: { [key: string]: string | boolean | number };
  className?: string;
  label: string;
  icon?: IconEnum;
  iconProps?: Omit<IconProps, "id">;
  children?: React.ReactNode;
  prefix?: React.ReactNode;
  disabled?: boolean;
  tooltipContent?: React.ReactNode;
  tooltipSide?: "top" | "right" | "bottom" | "left";
  destructive?: boolean;
} & (
  | {
      onSelect: (e: Event) => void;
      to?: never;
      openInNewTab?: never;
      type?: never;
    }
  | { to: string; openInNewTab: boolean; onSelect?: never; type?: never }
  | { type: "submit"; onSelect?: never; to?: never; openInNewTab?: never }
);

export function DropdownMenuItem({
  label,
  onSelect,
  to,
  openInNewTab,
  type,
  analyticsTrackingId,
  analyticsTrackingMetadata,
  icon,
  children,
  prefix,
  disabled,
  tooltipContent,
  tooltipSide,
  className,
  destructive = false,
}: DropdownMenuItemProps): React.ReactElement {
  return (
    <RadixPopoverClose onClick={(e) => e.stopPropagation()} asChild>
      <PopoverItem
        analyticsTrackingId={analyticsTrackingId}
        analyticsTrackingMetadata={analyticsTrackingMetadata}
        icon={icon}
        prefix={prefix}
        onClick={onSelect}
        to={to}
        type={type}
        openInNewTab={openInNewTab}
        disabled={disabled}
        className={tcx(destructive && "text-content-destroy", className)}
        tooltipContent={tooltipContent}
        tooltipSide={tooltipSide}
      >
        {children || label}
      </PopoverItem>
    </RadixPopoverClose>
  );
}

export function DropdownMenuSubItem({
  trigger,
  disabled,
  children,
  icon,
}: {
  disabled?: boolean;
  trigger: string | React.ReactNode;
  children: React.ReactNode;
  icon?: IconEnum;
  iconProps?: Omit<IconProps, "id">;
}): React.ReactElement {
  const { setContent } = useContext(PopoverContext);

  const onOpen = () => {
    if (setContent) {
      const DropdownMenuSubItemContent = () => (
        <>
          <PopoverBody>
            <PopoverTitleBar
              title={trigger}
              handleBack={() => setContent(undefined)}
            />

            {children}
          </PopoverBody>
        </>
      );

      // If we just set the function as the state directly, React will interpret
      // that as a "state mutator function", and put the resulting fragment in
      // the state, rather than putting the _function_ in the state as we would
      // like.
      setContent(() => DropdownMenuSubItemContent);
    }
  };

  return (
    <PopoverItem
      showContinueChevron
      icon={icon}
      onClick={onOpen}
      disabled={disabled}
    >
      {trigger}
    </PopoverItem>
  );
}

export function GatedDropdownMenuItem({
  explanationText,
  requiredScope,
  disabled,
  children,
  ...props
}: {
  explanationText?: string;
  requiredScope?: ScopeNameEnum;
} & DropdownMenuItemProps): React.ReactElement {
  const { hasScope } = useIdentity();
  const hasRequiredScope = requiredScope ? hasScope(requiredScope) : true;

  if (!hasRequiredScope) {
    explanationText = NoPermissionMessage;
    disabled = true;
  }

  if (explanationText && disabled) {
    return (
      <Tooltip
        analyticsTrackingId={null}
        content={explanationText}
        delayDuration={200}
      >
        <span>
          <DropdownMenuItem {...props} disabled={disabled}>
            {children}
          </DropdownMenuItem>
        </span>
      </Tooltip>
    );
  }

  return (
    <DropdownMenuItem {...props} disabled={disabled}>
      {children}
    </DropdownMenuItem>
  );
}

/**
 *  A nice dropdown menu which is triggered with a button click.
 * Pass the `DropdownMenuItem` components in as children to populate the menu.
 * If you want to supply your own button, pass it in as the `triggerButton` prop.
 * Built on top of the `radix-ui` dropdown menu - see the [docs](https://www.radix-ui.com/docs/primitives/components/dropdown-menu) for more info.
 *
 */
export function DropdownMenu({
  triggerIcon,
  triggerIconSize = IconSize.XL,
  triggerButtonTheme,
  triggerBadgeSize,
  triggerButton,
  analyticsTrackingId,
  children,
  screenReaderText,
  side,
  sideOffset,
  scroll = true,
  menuWidthMatchesTrigger,
  disabled,
  menuClassName: className,
  tooltipContent,
  tooltipSide,
  align,
  alignOffset,
  search,
  setSearch,
  onOpenChange,
}: Props): React.ReactElement {
  // Force re-render if the portal ref changes. As otherwise, if you're in a portal'd view (e.g. a modal),
  // then you won't be able to click the dropdown button to open the menu.
  const portalRef = usePortal();
  const [isPortalRefAvailable, setPortalRefAvailable] = React.useState(false);
  useEffect(() => {
    if (portalRef && !isPortalRefAvailable) {
      setPortalRefAvailable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalRef, setPortalRefAvailable]);

  return (
    <Popover
      onOpenChange={onOpenChange}
      disabled={disabled}
      // Default to overflow visible in non-scrolling views so that tooltips show!
      className={tcx({ "overflow-visible": !scroll }, className)}
      side={side}
      sideOffset={sideOffset}
      alignOffset={alignOffset}
      align={align}
      scroll={scroll}
      menuWidthMatchesTrigger={menuWidthMatchesTrigger}
      tooltipContent={tooltipContent}
      tooltipSide={tooltipSide}
      trigger={
        triggerButton ? (
          (triggerButton as React.ReactElement)
        ) : (
          <Button
            theme={triggerButtonTheme || ButtonTheme.Secondary}
            size={triggerBadgeSize}
            className={tcx("!p-0 hover:bg-surface-secondary")}
            icon={triggerIcon}
            iconProps={{
              size: triggerIconSize,
            }}
            title={screenReaderText as string}
            analyticsTrackingId={`${analyticsTrackingId}.open-menu`}
          />
        )
      }
    >
      {/* This takes the focus from the trigger button, to avoid the first item in the menu becoming focussed */}
      <button className="sr-only"></button>
      {setSearch && <PopoverSearch value={search ?? ""} onChange={setSearch} />}
      <PopoverBody>{children}</PopoverBody>
    </Popover>
  );
}
