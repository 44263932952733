import { CustomDashboard } from "@incident-io/api";
import { FiltersContextProvider } from "@incident-shared/filters";
import { Form } from "@incident-shared/forms";
import _ from "lodash";
import React from "react";
import { useForm } from "react-hook-form";

import {
  dateRangeToFormState,
  insightsFilterToFormFieldValue,
  panelToFormData,
} from "../common/marshall";
import { useGetPanelFilterFields } from "../common/useGetPanelFilterFields";
import {
  DashboardViewMode,
  EditDashboardFormData,
  InsightsContext,
} from "../common/useInsightsContext";
import { PanelsFormStateContextProvider } from "../create-edit/PanelsFormStateContext";

// InsightsShowCustomDashboardProvider gives the contents of a workflow form access to the various mutations
// and view state that it needs to avoid prop-drilling hell.
export const InsightsShowCustomDashboardProvider = ({
  dashboard,
  children,
}: {
  dashboard: CustomDashboard;
  children: React.ReactNode;
}): JSX.Element => {
  const formMethods = useForm<
    Pick<EditDashboardFormData, "panels" | "dateRange" | "filters">
  >({
    defaultValues: {
      panels: dashboard.panels.map(panelToFormData),
      dateRange: dateRangeToFormState(dashboard.date_range),
      filters: _.map(dashboard.filters, insightsFilterToFormFieldValue),
    },
  });

  const [dateRange, filters] = formMethods.watch(["dateRange", "filters"]);

  const { filterFields, filtersLoading } = useGetPanelFilterFields({
    panels: dashboard.panels.map(panelToFormData),
  });

  return (
    <InsightsContext.Provider
      value={{
        dateRange,
        viewMode: DashboardViewMode.View,
      }}
    >
      <FiltersContextProvider
        filters={filters}
        setFilters={() => null} // We don't need to set filters in Custom dashboard view mode
        availableFilterFields={filterFields}
        kind="insights"
        filtersLoading={filtersLoading}
      >
        <Form.Root fullHeight formMethods={formMethods} onSubmit={() => null}>
          <PanelsFormStateContextProvider>
            {children}
          </PanelsFormStateContextProvider>
        </Form.Root>
      </FiltersContextProvider>
    </InsightsContext.Provider>
  );
};
