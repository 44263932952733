import { ExternalUser } from "@incident-io/api";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  Button,
  Icon,
  IconEnum,
  LoadingWrapper,
  ModalFooter,
  Txt,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { SearchBar } from "@incident-ui/SearchBar/SearchBar";
import { useRef, useState } from "react";
import { Path, useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { useDebounce } from "use-debounce";

import { ExternalUserNameAndEmail } from "./ExternalUserNameAndEmail";

export const ConnectAccountButton = ({
  userIDToLink,
}: {
  userIDToLink?: string;
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const { identity } = useIdentity();

  return (
    <>
      {identity && isOpen && (
        <ConnectAccountModal
          defaultSearch={userIDToLink ? "" : identity.user_name}
          onClose={onClose}
          userIDToLink={userIDToLink}
        />
      )}
      <Button
        onClick={() => setIsOpen(true)}
        icon={IconEnum.Add}
        analyticsTrackingId={"userlinks-connect-self"}
      >
        Connect account
      </Button>
    </>
  );
};

type FormData = { external_user_ids: Record<string, boolean> };
const ConnectAccountModal = ({
  defaultSearch,
  onClose,
  userIDToLink,
}: {
  defaultSearch: string;
  onClose: () => void;
  userIDToLink?: string;
}) => {
  const [rawQuery, setQuery] = useState(defaultSearch);
  const [query] = useDebounce(rawQuery, 300);
  const { identity } = useIdentity();

  const refetchUserLinks = useAPIRefetch("userLinksListUserLinks", {
    userId: userIDToLink || identity?.user_id || "",
  });

  const {
    data: { connectable_users: connectableUsers },
    isLoading,
    error,
  } = useAPI(
    "userLinksListConnectableUsers",
    { query, userIdToLink: userIDToLink },
    { fallbackData: { connectable_users: [] }, keepPreviousData: true },
  );

  const formMethods = useForm<FormData>({
    defaultValues: { external_user_ids: {} },
  });

  const selectedIds = new Set(
    Object.entries(formMethods.watch("external_user_ids"))
      .filter(([_, enabled]) => enabled)
      .map(([id, _]) => id),
  );
  const visibleIds = new Set(connectableUsers.map((user) => user.id));

  // If everything in the form state is no longer visible, that's disabled too
  const disabled =
    selectedIds.size === 0 ||
    [...selectedIds].every((id) => !visibleIds.has(id));

  const submittedIds = useRef<string[]>([]);
  const { trigger, isMutating, genericError } = useAPIMutation(
    userIDToLink ? "userLinksListUserLinks" : "userLinksListSelf",
    userIDToLink ? { userId: userIDToLink as string } : undefined,
    async (apiClient, { external_user_ids: selectedIds }) => {
      const visibleSelectedIds = Object.entries(selectedIds)
        .filter(([_, enabled]) => enabled)
        .map(([id, _]) => id)
        .filter((id) => visibleIds.has(id));
      if (visibleSelectedIds.length === 0) {
        return;
      }

      // Stash this in a ref, so we can map errors back
      submittedIds.current = visibleSelectedIds;

      if (userIDToLink) {
        await apiClient.userLinksCreateUserLinks({
          userId: userIDToLink as string,
          createUserLinksRequestBody: {
            external_user_ids: visibleSelectedIds,
          },
        });
      } else {
        await apiClient.userLinksCreateSelf({
          createSelfRequestBody: {
            external_user_ids: visibleSelectedIds,
          },
        });
      }
    },
    {
      onSuccess: async () => {
        await refetchUserLinks();
        onClose();
      },
      setError: (key, err) => {
        // Our form state is a map, keyed by user ID. But the API takes a list
        // of IDs. Therefore we need to map things back to the right key in the
        // form state.
        if (key.startsWith("external_user_ids.")) {
          const idx = parseInt(key.split(".")[1]);
          const id = submittedIds.current[idx];
          key = `external_user_ids.${id}`;
        }

        formMethods.setError(key as Path<FormData>, err);
      },
    },
  );

  if (error) {
    return (
      <ErrorModal title="Connect account" error={error} onClose={onClose} />
    );
  }

  return (
    <Form.Modal<FormData>
      formMethods={formMethods}
      onSubmit={trigger}
      onClose={onClose}
      genericError={genericError}
      saving={isMutating}
      title="Connect accounts"
      analyticsTrackingId={"userlinks-connect-self"}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          disabled={disabled || isMutating}
          confirmButtonText="Connect"
          saving={isMutating}
        />
      }
    >
      <SearchBar
        value={rawQuery}
        onChange={(value: string) => {
          setQuery(value);
        }}
        placeholder="Search"
        autoFocus={true}
      />
      <LoadingWrapper loading={isLoading}>
        <div className="space-y-3">
          {connectableUsers.length > 0 ? (
            connectableUsers.map((user) => (
              <ConnectableUser
                key={user.id}
                user={user}
                formMethods={formMethods}
              />
            ))
          ) : (
            <Txt grey>We found no available accounts</Txt>
          )}
        </div>
      </LoadingWrapper>
    </Form.Modal>
  );
};

const ConnectableUser = ({
  user,
  formMethods,
}: {
  user: ExternalUser;
  formMethods: UseFormReturn<FormData>;
}) => {
  const integration = IntegrationConfigFor(user.provider);

  return (
    <label
      className="flex flex-row items-center text-sm"
      htmlFor={`external_user_ids.${user.id}`}
    >
      <CheckboxV2
        formMethods={formMethods}
        name={`external_user_ids.${user.id}`}
        className="mr-2"
        inputWrapperClassName="flex items-center"
      />
      <Icon id={integration.icon} className="h-5 w-5 inline mr-2" />
      <ExternalUserNameAndEmail user={user} alwaysIncludeExternalId />
    </label>
  );
};
