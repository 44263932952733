import { IntegrationSettingsProviderEnum } from "@incident-io/api";
import { Callout, CalloutTheme, Loader } from "@incident-ui";

import { useInstalledIntegrationsThatUseDelegatedPermissions } from "./integrations";

const integrationSpecificWarnings = {
  [IntegrationSettingsProviderEnum.MicrosoftTeamsOnlineMeetings]: (
    <>
      We&apos;ll no longer be able to start call links for your incident
      channels.
    </>
  ),
  [IntegrationSettingsProviderEnum.OutlookCalendar]: (
    <>
      We&apos;ll no longer be able to link Outlook Calendar events to incidents.
    </>
  ),
};

export const DisconnectMicrosoftDelegatedPermissionsWarning = () => {
  const installedIntegrations =
    useInstalledIntegrationsThatUseDelegatedPermissions();

  if (typeof installedIntegrations === "undefined") {
    return <Loader />;
  }

  if (installedIntegrations.length === 1) {
    return (
      <p className="text-sm">
        {integrationSpecificWarnings[installedIntegrations[0].provider]}
      </p>
    );
  }

  return (
    <div className="space-y-4 text-sm">
      <Callout theme={CalloutTheme.Warning}>
        Disconnecting will disconnect{" "}
        <span className="font-semibold">
          the following Microsoft integrations
        </span>
        .
      </Callout>
      <ul className="list-disc ml-4 space-y-2">
        {installedIntegrations.map(
          (x) =>
            integrationSpecificWarnings[x.provider] && (
              <li key={x.provider}>
                <p>
                  <span className="font-semibold">{x.provider_name}</span> will
                  be <span className="font-semibold">uninstalled</span>
                </p>
                <p>{integrationSpecificWarnings[x.provider]}</p>
              </li>
            ),
        )}
      </ul>
    </div>
  );
};
