import {
  Button,
  ButtonTheme,
  FullPageEmptyState,
  IconEnum,
} from "@incident-ui";

import graphic from "../common/empty-state-dashboard-graphic.svg";

export const InsightsCustomDashboardEmptyState = ({
  openAddPanelDrawer,
  addTextPanel,
}: {
  openAddPanelDrawer: () => void;
  addTextPanel: () => void;
}) => {
  return (
    <div className="flex flex-col grow p-6 bg-white">
      <FullPageEmptyState
        graphic={<img src={graphic} className="my-6" />}
        title={"Add charts to your new dashboard"}
        subtitle={
          "Create a variety of panels for tracking incidents, follow-up completion, MTTX metrics, on-call readiness, and more."
        }
        gradientClasses={"from-[#F7F7F8] to-[#FFFFFF]"}
        cards={<></>}
        cta={
          <div className="flex gap-4">
            <Button
              onClick={() => openAddPanelDrawer()}
              icon={IconEnum.BarChart}
              theme={ButtonTheme.Primary}
              analyticsTrackingId={null}
            >
              Add charts
            </Button>
            <Button
              onClick={addTextPanel}
              icon={IconEnum.Text}
              theme={ButtonTheme.Secondary}
              analyticsTrackingId={null}
            >
              Add text
            </Button>
          </div>
        }
      />
    </div>
  );
};
