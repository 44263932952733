import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

export const JsonViewer = ({
  title = "JSON",
  jsonStr,
  className,
  collapsable = false,
}: {
  title?: React.ReactNode;
  jsonStr: string;
  className?: string;
  collapsable?: boolean;
}) => {
  const [open, setOpen] = useState(!collapsable);
  const processedJson = JSON.stringify(JSON.parse(jsonStr), null, 2);

  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <div
      className={tcx(
        "w-full bg-white rounded-lg shadow-sm border font-mono",
        className,
      )}
    >
      <div className="flex justify-between items-center p-2 border-b">
        <div
          className={tcx(
            "text-sm font-medium text-gray-700 flex-grow",
            collapsable && "cursor-pointer",
          )}
          onClick={() => {
            if (collapsable) {
              setOpen(!open);
            }
          }}
        >
          {title}
        </div>
        {collapsable && (
          <Button
            analyticsTrackingId={null}
            className="hidden group-hover:block"
            icon={open ? IconEnum.Collapse : IconEnum.Expand}
            theme={ButtonTheme.Secondary}
            onClick={() => setOpen(!open)}
            title={open ? "Collapse" : "Expand"}
          />
        )}
      </div>
      {open && (
        <div className="p-4 bg-surface-secondary font-mono text-sm overflow-y-hidden">
          <pre className="whitespace-pre-wrap relative">
            <Button
              analyticsTrackingId={null}
              className="absolute top-0 right-0"
              onClick={() => copyTextToClipboard(processedJson)}
              icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
              theme={ButtonTheme.Secondary}
              title="Copy contents"
            />
            {processedJson.split("\n").map((line, index) => (
              <div
                key={index}
                style={{
                  paddingLeft: `${
                    (line.match(/^\s*/)?.[0]?.length ?? 0) * 3
                  }px`,
                }}
                className="flex"
              >
                {line}
              </div>
            ))}
          </pre>
        </div>
      )}
    </div>
  );
};
