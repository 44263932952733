import {
  AIConfig,
  AIToggleFeatureRequestBodyFeatureEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Product, UpgradeRequiredMessage } from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import {
  NoPermissionMessage,
  UpgradeRequiredProps,
} from "@incident-shared/gates/gates";
import { ContentBox, Loader, SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useIdentity } from "src/contexts/IdentityContext";
import { AIFeatureEnum } from "src/hooks/useAI";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { usePrimaryCommsPlatform } from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPIMutation } from "src/utils/swr";

export const AIFeatureToggle = ({
  feature,
  requiredProduct,
  config,
  title,
  description,
  children,
  upgradeRequired,
  upgradeRequiredProps,
  disabledExplanation,
}: {
  feature: AIFeatureEnum;
  requiredProduct?: Product;
  config: AIConfig;
  title: string;
  description: React.ReactNode;
  children?: React.ReactNode;
  disabledExplanation?: React.ReactNode;
  upgradeRequired?: boolean;
  upgradeRequiredProps?: UpgradeRequiredProps;
}) => {
  const { hasScope, identity } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const commsPlatform = usePrimaryCommsPlatform();
  const { hasProduct } = useProductAccess();
  const hasRequiredProduct = requiredProduct
    ? hasProduct(requiredProduct)
    : true;

  const showToast = useToast();
  const { trigger: saveState } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { enabled }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: {
          feature: feature as unknown as AIToggleFeatureRequestBodyFeatureEnum,
          enabled,
        },
      }),
    {
      onSuccess: () => {
        showToast(SharedToasts.SETTINGS_SAVED);
      },
      onError: () => {
        showToast(SharedToasts.SETTINGS_SAVE_ERROR);
      },
    },
  );

  const {
    setState,
    hasSaved,
    saving,
    state: enabled,
  } = useOptimisticAutoSave<boolean>({
    initialState:
      config.openai_subprocessor_enabled &&
      config.enabled_features.includes(feature),
    saveState: async (enabled: boolean) => {
      await saveState({ enabled });
    },
  });

  if (!identity) {
    return <Loader />;
  }

  return (
    <>
      <ContentBox className={"p-6 space-y-2"}>
        <div className="flex flex-row justify-between">
          <span className="flex items-center max-w-3xl">
            <GatedToggle
              id={`ai-${feature}`}
              labelledById=""
              on={enabled}
              disabled={
                !hasRequiredProduct ||
                !canEditSettings ||
                !config.openai_subprocessor_enabled ||
                upgradeRequired ||
                (!!disabledExplanation && !enabled) ||
                saving
              }
              isDisabledTooltipContent={
                !hasRequiredProduct && requiredProduct ? (
                  <ProductRequiredMessage
                    requiredProduct={requiredProduct}
                    commsPlatform={commsPlatform}
                  />
                ) : upgradeRequired && upgradeRequiredProps ? (
                  <UpgradeRequiredMessage
                    gate={upgradeRequiredProps?.gate}
                    featureName={upgradeRequiredProps?.featureName}
                  />
                ) : !canEditSettings ? (
                  NoPermissionMessage
                ) : disabledExplanation ? (
                  disabledExplanation
                ) : undefined
              }
              onToggle={() => {
                setState(!enabled);
              }}
            />
            <span className={"text-sm flex flex-col ml-2.5"}>
              <span className="text-content-primary font-medium ">{title}</span>
              <span className="text-slate-700 !font-normal mt-1">
                {description}
              </span>
            </span>
          </span>
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </div>
        {children}
      </ContentBox>
    </>
  );
};
