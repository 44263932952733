/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationBacktest
 */
export interface EvaluationBacktest {
    /**
     * When the backtest was completed
     * @type {Date}
     * @memberof EvaluationBacktest
     */
    completed_at?: Date;
    /**
     * When the backtest was created
     * @type {Date}
     * @memberof EvaluationBacktest
     */
    created_at: Date;
    /**
     * Number of investigations that can be run before requiring human intervention
     * @type {number}
     * @memberof EvaluationBacktest
     */
    credit?: number;
    /**
     * Optional ID of the evaluation dataset this backtest was created from
     * @type {string}
     * @memberof EvaluationBacktest
     */
    dataset_id?: string;
    /**
     * Unique identifier of the backtest
     * @type {string}
     * @memberof EvaluationBacktest
     */
    id: string;
    /**
     * Type of investigation plan this backtest is running
     * @type {string}
     * @memberof EvaluationBacktest
     */
    investigation_plan_type: string;
    /**
     * Optional notes about the rationale for this backtest
     * @type {string}
     * @memberof EvaluationBacktest
     */
    notes?: string;
    /**
     * When the backtest was last updated
     * @type {Date}
     * @memberof EvaluationBacktest
     */
    updated_at: Date;
}

export function EvaluationBacktestFromJSON(json: any): EvaluationBacktest {
    return EvaluationBacktestFromJSONTyped(json, false);
}

export function EvaluationBacktestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationBacktest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'created_at': (new Date(json['created_at'])),
        'credit': !exists(json, 'credit') ? undefined : json['credit'],
        'dataset_id': !exists(json, 'dataset_id') ? undefined : json['dataset_id'],
        'id': json['id'],
        'investigation_plan_type': json['investigation_plan_type'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EvaluationBacktestToJSON(value?: EvaluationBacktest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'credit': value.credit,
        'dataset_id': value.dataset_id,
        'id': value.id,
        'investigation_plan_type': value.investigation_plan_type,
        'notes': value.notes,
        'updated_at': (value.updated_at.toISOString()),
    };
}

