import { Button, ButtonTheme, IconEnum, IconSize } from "@incident-ui";
import { useFormContext } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { EscalationPathFormData, PathNode } from "../common/types";
import { insertAboveNode, insertBelowNode } from "./helpers/insertNode";

export const AddNodeButton = ({
  nodeId,
  mode,
  className,
  style,
}: {
  nodeId: string;
  mode: "before" | "after" | "after-then" | "after-else";
  className?: string;
  style?: React.CSSProperties;
}) => {
  const formMethods = useFormContext<EscalationPathFormData>();
  const onClick =
    mode === "before"
      ? insertAboveNode
      : mode === "after" || mode === "after-then" || mode === "after-else"
      ? insertBelowNode
      : assertUnreachable(mode);

  const nodes = formMethods.watch("nodes");
  const firstNodeId = formMethods.watch("firstNodeId");

  return (
    <div
      className={tcx(
        "flex w-6 h-6 justify-center",
        "pointer-events-auto nodrag nopan",
        className,
      )}
      style={style}
    >
      <Button
        theme={ButtonTheme.Unstyled}
        icon={IconEnum.Add}
        iconProps={{
          size: IconSize.Small,
          className: "mx-auto",
        }}
        title={"Add level"}
        className="bg-surface-secondary text-content-tertiary hover:text-content-primary transition"
        analyticsTrackingId={"add-level-to-escalation-path"}
        onClick={() => {
          onClick({
            node: nodes[nodeId],
            nodes: nodes,
            originalFirstNodeId: firstNodeId,
            addToThenBranch:
              mode === "after-then"
                ? true
                : mode === "after-else"
                ? false
                : undefined,
            updateNodes: (nodes: Record<string, PathNode>, newNodeData) => {
              formMethods.setValue<"nodes">("nodes", nodes);

              if (newNodeData?.isFirst) {
                formMethods.setValue<"firstNodeId">(
                  "firstNodeId",
                  newNodeData.node.id,
                );
              }
            },
          });
        }}
      />
    </div>
  );
};
