import { IntegrationsListObject } from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Link,
  Modal,
  ModalContent,
} from "@incident-ui";
import React from "react";
import { useQueryParams } from "src/utils/query-params";

import ButtonImage from "./google_signin_button.png";

export const ConnectGoogleWorkspaceModal = ({
  integration,
  onClose,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
}): React.ReactElement | null => {
  const error = useQueryParams().get("error");
  return (
    <Modal
      title={"Connect to " + integration.provider_name}
      isOpen
      analyticsTrackingId="connect-google-workspace-modal"
      onClose={onClose}
    >
      <ModalContent>
        <div className="flex flex-col space-y-3">
          <p className="text-sm text-slate-700">
            We&apos;re about to send you to Google Authentication where (if you
            have permission) you can set up the incident.io integration.
          </p>
          {error ? (
            error === "google_user_already_in_use" ? (
              <Callout theme={CalloutTheme.Danger}>
                This Google account is already linked to another incident.io
                organization. It&rsquo;s not possible to use the same service
                account for two organizations.
              </Callout>
            ) : null
          ) : (
            <Callout theme={CalloutTheme.Warning}>
              We&apos;ll invite the connected user to every incident debrief, so
              we strongly advise the use of a service account here.{" "}
              <Link
                to={`https://help.incident.io/articles/5012716703-using-a-service-account-to-integrate-with-google`}
                className={"inline-block"}
                analyticsTrackingId={"connect-google-calendar-help-link"}
                openInNewTab
              >
                <p className="text-slate-800 font-normal mr-1 hover:underline">
                  {`Learn more.`}
                </p>
              </Link>
            </Callout>
          )}
        </div>
        <div className="flex flex-row w-full justify-center pt-2">
          <Button
            analyticsTrackingId="sign-in-with-google-button"
            href={integration.add_integration_url}
            theme={ButtonTheme.Unstyled}
          >
            <img
              src={ButtonImage}
              alt="Sign in with Google"
              style={{ height: "40px" }}
            />
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
