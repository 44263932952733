import {
  AlertInsightsDatapointPeriodEnum,
  AlertsGetInsightsOverviewKeyInsightsResponseBody,
  DateRangeRelativeStateIntervalEnum,
  ErrorResponse,
  OverviewKeyInsight,
  TrendSentimentEnum,
} from "@incident-io/api";
import { DateRangePickerMode } from "@incident-shared/forms/v1/DateRangePicker";
import {
  Tile,
  TrendTheme,
  TrendThemeLookup,
  TrendTooltipContent,
} from "@incident-shared/insights/TrendTile";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Icon, IconSize } from "@incident-ui";
import { BarChart } from "@incident-ui/Chart/BarChart";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";
import { getColor } from "src/utils/twConfig";

import { ALERT_INSIGHTS_TIME_PERIOD_LABEL } from "../alert-details/useAlertInsightsDates";
import { HEADLINE_METRIC_CONFIG } from "../common/AlertHeadlineMetrics";
import { formatValueWithUnit } from "../common/formatValueWithUnit";
import { GetNavigateHref } from "./AlertsOverview";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

// Animation variants for individual insights/shimmer placeholders
const insightVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.2,
    },
  },
};

const ShimmerPlaceholder = () => (
  <motion.div
    variants={insightVariants}
    initial="hidden"
    animate="visible"
    exit="exit"
    className={tcx(
      "w-[314px] h-[314px] rounded-lg bg-gradient-to-r",
      "bg-surface-secondary from-slate-50 relative overflow-hidden",
    )}
  >
    <motion.div
      className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent"
      animate={{
        x: ["-100%", "100%"],
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        ease: "linear",
      }}
    />
  </motion.div>
);

export const KeyInsights = ({
  keyInsightsError,
  keyInsightsData,
  isLoadingKeyInsights,
  getNavigateHref,
}: {
  keyInsightsData: AlertsGetInsightsOverviewKeyInsightsResponseBody | undefined;
  isLoadingKeyInsights: boolean;
  keyInsightsError: ErrorResponse | undefined;
  getNavigateHref: GetNavigateHref;
}) => {
  if (keyInsightsError) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex">
          <div className="text-base-bold">
            Highlights
            <span className="ml-2 text-xs-med text-content-secondary">
              {ALERT_INSIGHTS_TIME_PERIOD_LABEL}
            </span>
          </div>
        </div>
        <div className="text-content-secondary max-w-[560px]">
          Alerts and themes that are outliers in terms of time spend, decline
          rate, or have exhibited the most change in either of those categories.
        </div>
      </div>
      <AnimatePresence mode="wait">
        {isLoadingKeyInsights || !keyInsightsData ? (
          <motion.div
            key="loading"
            className="flex flex-nowrap overflow-x-auto gap-4"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {Array.from({ length: 4 }, (_, i) => (
              <ShimmerPlaceholder key={i} />
            ))}
          </motion.div>
        ) : (
          <motion.div
            key="loaded"
            className="flex flex-nowrap overflow-x-auto gap-4"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {keyInsightsData.key_insights.map((insight, idx) => (
              <motion.div key={idx} variants={insightVariants}>
                <KeyInsight
                  insight={insight}
                  getNavigateHref={getNavigateHref}
                />
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

type KeyInsightDatapoint = {
  id: string;
  label: string;
  previous: string;
  previousAverage?: string;
  current: string;
  currentAverage?: string;
};

const keyInsightsChartConfig = {
  previous: {
    label: "Previous period",
    color: getColor("slate", "100"),
    background: "bg-slate-100",
  },
  current: {
    label: ALERT_INSIGHTS_TIME_PERIOD_LABEL,
    color: getColor("slate", "200"),
    background: "bg-slate-200",
  },
  previousAverage: {
    label: "Previous period",
    color: getColor("slate", "400"),
    background: "bg-slate-400",
  },
  currentAverage: {
    label: ALERT_INSIGHTS_TIME_PERIOD_LABEL,
    color: getColor("red", "500"),
    background: "bg-slate-200",
  },
};

export const KeyInsight = ({
  insight,
  getNavigateHref,
}: {
  insight: OverviewKeyInsight;
  getNavigateHref: GetNavigateHref;
}) => {
  const navigate = useOrgAwareNavigate();
  const onClick = () => {
    navigate(
      getNavigateHref({
        theme: insight.subject.theme?.name,
        title: insight.subject.title,
      }),
    );
  };

  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const theme = TrendThemeLookup[insight.trend.sentiment];

  const previousAverage = _.meanBy(
    insight.datapoints.filter(
      (data) => data.period === AlertInsightsDatapointPeriodEnum.Previous,
    ),
    (data) => data.value,
  );
  const currentAverage = _.meanBy(
    insight.datapoints.filter(
      (data) => data.period === AlertInsightsDatapointPeriodEnum.Current,
    ),
    (data) => data.value,
  );
  const datapoints =
    insight.datapoints.map((data): KeyInsightDatapoint => {
      const value = data.value.toString();
      const isPrevious =
        data.period === AlertInsightsDatapointPeriodEnum.Previous;
      return {
        id: data.date.toISOString(),
        label: new Date(data.date).toLocaleDateString(locale, {
          day: "numeric",
          month: "short",
          timeZone: timezone,
        }),
        previous: isPrevious ? value : "0",
        previousAverage: isPrevious ? previousAverage.toString() : undefined,
        current: isPrevious ? "0" : value,
        currentAverage: isPrevious ? undefined : currentAverage.toString(),
      };
    }) || [];

  const getColorForAverageLine = (key: string) => {
    if (key === "previousAverage") {
      return getColor("slate", "400");
    }

    return insight.trend.sentiment === TrendSentimentEnum.Good
      ? getColor("green", "500")
      : getColor("red", "600");
  };

  return (
    <Tile
      title={getTitleForInsight(insight)}
      theme={TrendTheme.White}
      onClick={onClick}
      tooltipContent={
        <TrendTooltipContent
          dateRange={{
            mode: DateRangePickerMode.Relative,
            relative: {
              interval: DateRangeRelativeStateIntervalEnum.Weeks,
              numberOfIntervals: 4,
              includeThisInterval: true,
            },
          }}
          previousValue={insight.trend.previous_value}
          currentValue={insight.trend.current_value}
          unit={insight.trend.unit}
          colour={theme.tooltipBar}
        />
      }
      className={tcx(
        "w-[314px] h-[314px] transition-colors",
        insight.trend.sentiment === TrendSentimentEnum.Good
          ? `hover:border-green-600 `
          : "hover:border-red-600",
      )}
      content={
        <div className="flex flex-col gap-2">
          <div className="text-sm-bold truncate overflow-ellipsis text-nowrap">
            {insight.subject.title || insight.subject.theme?.name}
          </div>
          {getDescriptionForInsight(insight)}

          <div>
            <BarChart<KeyInsightDatapoint>
              cursor={"pointer"}
              data={datapoints}
              dataKeys={["previous", "current"]}
              chartConfig={keyInsightsChartConfig}
              referenceLineDataKeys={["previousAverage", "currentAverage"]}
              referenceLineConfig={{
                getLabelConfig: (key) => {
                  const value =
                    key === "previousAverage"
                      ? previousAverage
                      : currentAverage;

                  return {
                    content: formatValueWithUnit(value, insight.trend.unit),
                    textColor: getColor("white"),
                    backgroundColor: getColorForAverageLine(key),
                  };
                },
                getLineColor: getColorForAverageLine,
              }}
              cartesianGridProps={{
                vertical: false,
                horizontal: true,
                stroke: getColor("slate", "50"),
              }}
              barProps={{
                minPointSize: 1,
                defaultFill: getColor("slate", "100"),
              }}
            />
            <div className="w-full flex items-center text-xs-med">
              <div className="w-1/2 flex items-center justify-center text-content-tertiary">
                Previous period
              </div>
              <div className="w-1/2 flex items-center justify-center text-content-primary">
                {ALERT_INSIGHTS_TIME_PERIOD_LABEL}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

const getTitleForInsight = (insight: OverviewKeyInsight): React.ReactNode => {
  const metricConfig = HEADLINE_METRIC_CONFIG[insight.headline_metric_type];

  const styling = TrendThemeLookup[insight.trend.sentiment];
  const textColor = styling.variables;

  return (
    <div className="flex items-center gap-1">
      <Icon
        id={metricConfig.icon}
        className={`${textColor}`}
        size={IconSize.Small}
      />
      <div className={`!${textColor} text-xs-med`}>{metricConfig.title}</div>{" "}
    </div>
  );
};

const getDescriptionForInsight = (
  insight: OverviewKeyInsight,
): React.ReactNode => {
  const metricConfig = HEADLINE_METRIC_CONFIG[insight.headline_metric_type];
  const changeDirection =
    insight.trend.absolute_change > 0 ? "increased" : "decreased";

  let percentageChange = formatValueWithUnit(
    insight.trend.percentage_change,
    "%",
  );
  if (insight.trend.percentage_change > 0) {
    percentageChange = `+${percentageChange}`;
  }

  return (
    <div className="text-content-primary">
      {metricConfig.title} {changeDirection} by{" "}
      <span className="text-sm-bold">{percentageChange}</span> in the last 4
      weeks
    </div>
  );
};
