/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopilotThreadScoringMetrics
 */
export interface CopilotThreadScoringMetrics {
    /**
     * The alignment rating of the incident
     * @type {number}
     * @memberof CopilotThreadScoringMetrics
     */
    alignment: number;
    /**
     * The efficiency rating of the incident
     * @type {number}
     * @memberof CopilotThreadScoringMetrics
     */
    efficiency: number;
    /**
     * The tone rating of the incident
     * @type {number}
     * @memberof CopilotThreadScoringMetrics
     */
    tone: number;
}

export function CopilotThreadScoringMetricsFromJSON(json: any): CopilotThreadScoringMetrics {
    return CopilotThreadScoringMetricsFromJSONTyped(json, false);
}

export function CopilotThreadScoringMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotThreadScoringMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alignment': json['alignment'],
        'efficiency': json['efficiency'],
        'tone': json['tone'],
    };
}

export function CopilotThreadScoringMetricsToJSON(value?: CopilotThreadScoringMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alignment': value.alignment,
        'efficiency': value.efficiency,
        'tone': value.tone,
    };
}

