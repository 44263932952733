import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Card, IconBadge, IconSize } from "@incident-ui";
import { CardProps } from "@incident-ui/Card/Card";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerProps,
  DrawerTitle,
  DrawerTitleProps,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type AIFeatureConfigurationDrawerProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  preview?: React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
} & Omit<DrawerProps, "children">;

export const AIFeatureConfigurationDrawer = ({
  content,
  preview,
  title,
  footer,
  loading,
  onClose,
  width,
}: AIFeatureConfigurationDrawerProps): React.ReactElement => {
  return (
    <Drawer width={width} onClose={onClose}>
      {loading ? (
        <DrawerContentsLoading />
      ) : (
        <div className="flex flex-col h-full !overflow-hidden">
          {title}
          <DrawerContents>
            <DrawerBody className="flex flex-row min-h-0 overflow-y-hidden h-full !p-0 !gap-0">
              {/* Left hand, input side of the form */}
              <div className="h-full p-6 overflow-y-auto flex flex-col gap-6 flex-[1] border-r border-stroke">
                {content}
              </div>

              {/* Right hand, preview side of the form */}
              {preview && (
                <div
                  className={
                    "flex flex-col items-stretch flex-[1] p-6 bg-surface-secondary overflow-auto gap-3"
                  }
                >
                  {preview}
                </div>
              )}
            </DrawerBody>
            {footer}
          </DrawerContents>
        </div>
      )}
    </Drawer>
  );
};

export const AIFeatureDrawerTitle = (props: DrawerTitleProps) => {
  return (
    <DrawerTitle
      color={ColorPaletteEnum.SlateOnWhite}
      iconClassName="text-alarmalade-500 bg-alarmalade-50"
      invertIcon={false}
      theme={DrawerTitleTheme.Bordered}
      {...props}
    />
  );
};

export const AIProductMarketingCards = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className="grid grid-cols-2 gap-4">{children}</div>;
};

export const AIProductMarketingCard = (props: CardProps) => {
  return (
    <Card
      {...props}
      iconOverride={
        <IconBadge
          color={ColorPaletteEnum.SlateOnWhite}
          icon={props.icon}
          size={IconSize.Small}
          className="text-alarmalade-500 border-none"
        />
      }
      className={tcx("bg-surface-secondary border-none", props.className)}
    />
  );
};
