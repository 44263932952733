import { Route, Routes } from "react-router";

import { CopilotThreadPage } from "./CopilotThreadPage";
import { CopilotThreadsListPage } from "./CopilotThreadsListPage";

export const CopilotThreadsRoute = () => {
  return (
    <Routes>
      <Route path="/:threadId" element={<CopilotThreadPage />} />
      <Route path="*" element={<CopilotThreadsListPage />} />
    </Routes>
  );
};
