import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";

import {
  PrioritiesCreateEditForm,
  PrioritiesDescription,
} from "./PrioritiesCreateEditForm";

export const PrioritiesCreateEditDrawer = ({
  title = "Alert priorities",
  onClose,
}: {
  title?: string;
  onClose: () => void;
}): React.ReactElement => {
  return (
    <Drawer onClose={onClose} warnWhenDirty width="medium">
      <DrawerContents className="font-normal">
        <DrawerTitle
          icon={IconEnum.Priority}
          title={title}
          color={ColorPaletteEnum.Slate}
          onClose={onClose}
          closeIcon={IconEnum.Close}
          sticky
        />
        <DrawerBody>
          <PrioritiesDescription />
          <PrioritiesCreateEditForm />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};
