import { Product } from "@incident-shared/billing";
import {
  SecondaryNavPageWrapper,
  SecondaryNavSubPageWrapper,
  SecondaryNavSubPageWrapperProps,
} from "@incident-shared/layout/SecondaryNav";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";
import { SettingsBillingSuccessRoute } from "src/routes/settings/legacy/SettingsBillingSuccessRoute";
import { useAPI } from "src/utils/swr";

import { SettingsDecisionFlowsRoute } from "../../routes/settings/legacy/SettingsDecisionFlowsRoute";
import { APIKeysRoute } from "../settings/api-keys/APIKeysRoute";
import { AIRoute } from "./ai/AIRoute";
import { SettingsAnnouncementsRoute } from "./announcements/AnnouncementRoute";
import { AutomationRoute } from "./automation/AutomationRoute";
import { BillingRoute } from "./billing/BillingRoute";
import { SettingsCustomFieldsRoute } from "./custom-fields/SettingsCustomFieldsRoute";
import { SettingsDebriefsRoute } from "./debriefs/DebriefsRoute";
import { FollowUpRoute } from "./follow-ups/FollowUpRoute";
import { IncidentRolesRoute } from "./incident-roles/IncidentRolesRoute";
import { IncidentTicketsRoute } from "./incident-tickets/IncidentTicketsRoute";
import { IncidentTypesRoute } from "./incident-types/IncidentTypesRoute";
import { IncidentFormsRoute } from "./incidentforms/IncidentFormsRoute";
import { IntegrationsRoute } from "./integrations/IntegrationsRoute";
import { SettingsLifecycleRoute } from "./lifecycle/SettingsLifecycleRoute";
import { NudgesRoute } from "./nudges/NudgesRoute";
import { SuggestionsRoute } from "./nudges/SuggestionsRoute";
import { SettingsOverviewPage } from "./overview/SettingsOverviewPage";
import { PolicyRoute } from "./policy/PolicyRoute";
import { PostIncidentFlowRoute } from "./post-incident-flow/PostIncidentFlowRoute";
import { PostmortemRoute } from "./post-mortem/PostmortemRoute";
import { SecurityRoute } from "./security/SecurityRoute";
import { SeveritiesRoute } from "./severities/SeveritiesRoute";
import { SlackChannelRoute } from "./slack-channel/SlackChannelRoute";
import { SlackEnterpriseGridRoute } from "./slack-enterprise-grid/SlackEnterpriseGridRoute";
import { TeamsChannelRoute } from "./teams-channel/TeamsChannelRoute";
import { SettingsUsersRoute } from "./users/SettingsUsersRoute";
import { WebhooksRoute } from "./webhooks/WebhooksRoute";

export type SettingsPage = {
  label: string;
  slug: string;
  Component: () => React.ReactElement;
  exact?: boolean;
  hide?: boolean;
  icon: IconEnum;
  isTeam?: boolean;
  isPro?: boolean;
  newUntil?: Date;
  requiredProduct?: Product;
  overviewDescription?: string;
};

export type SettingsSection = {
  label: string;
  overviewSummary?: string;
  items: SettingsPage[];
} & (
  | {
      showOnOverview: true;
      color: ColorPaletteEnum;
    }
  | {
      showOnOverview?: never;
      color?: never;
    }
);

export const useSettingsPages = (): SettingsSection[] => {
  const flags = useFlags();
  const commsPlatform = usePrimaryCommsPlatform() ?? CommsPlatform.Slack;
  const { hasProduct } = useProductAccess();
  const { identity } = useIdentity();

  // even if we don't use flags we should keep them here
  // so that future us can easily gate settings pages based on flags.
  const _dont_remove_the_flags_param_you_bastards = flags;

  const { data: enterpriseSettingsData } = useAPI(
    "settingsGetSlackEnterpriseSettings",
    undefined,
  );
  const slackEnterpriseSettings =
    enterpriseSettingsData?.slack_enterprise_settings;

  const settingsPages: SettingsSection[] = [
    {
      label: "Respond",
      showOnOverview: true,
      color: ColorPaletteEnum.Red,
      overviewSummary:
        "Level up your incident response with some of these powerful features.",
      items: [
        {
          label: "Custom fields",
          slug: "custom-fields",
          Component: SettingsCustomFieldsRoute,
          icon: IconEnum.CustomField,
          requiredProduct: Product.Response,
          overviewDescription:
            "Use Custom Fields to tag incidents with structured data, for example the Impacted Product or Affected Team.",
        },
        {
          label: "Roles",
          slug: "incident-roles",
          Component: IncidentRolesRoute,
          icon: IconEnum.User,
          requiredProduct: Product.Response,
          overviewDescription:
            "Use incident roles to define key responsibilities during an incident.",
        },
        {
          label: "Types",
          slug: "incident-types",
          requiredProduct: Product.Response,
          Component: IncidentTypesRoute,
          icon: IconEnum.IncidentType,
          isPro: true,
          overviewDescription:
            "Tailor your incident response with different custom fields and roles for each incident type.",
        },
        {
          label: "Lifecycle",
          slug: "lifecycle",
          Component: SettingsLifecycleRoute,
          icon: IconEnum.Status,
          requiredProduct: Product.Response,
          overviewDescription:
            "Control the statuses that an incident moves through, and the timestamps that you record along the way.",
        },
        {
          label: "Severities",
          slug: "severities",
          Component: SeveritiesRoute,
          icon: IconEnum.Severity,
          requiredProduct: Product.Response,
          overviewDescription:
            "Create custom severities that you can assign to incidents.",
        },
        {
          label: "Announcements",
          slug: "announcements",
          icon: IconEnum.Announcement,
          Component: SettingsAnnouncementsRoute,
          hide: commsPlatform !== CommsPlatform.Slack,
          overviewDescription:
            "Announce incidents in a Slack channel to keep everyone up-to-date.",
        },
        {
          label: "Slack channel",
          slug: "slack-channel",
          icon: IconEnum.SlackGreyscale,
          Component: SlackChannelRoute,
          hide: commsPlatform !== CommsPlatform.Slack,
          exact: true,
          overviewDescription:
            "Configure channel naming, bookmarks and the quick actions that appear in an incident channel.",
        },
        {
          label: "Teams channel",
          slug: "teams-channel",
          icon: IconEnum.MicrosoftTeamsGreyscale,
          Component: TeamsChannelRoute,
          hide: commsPlatform !== CommsPlatform.MSTeams,
          exact: true,
          overviewDescription: "Configure the way incident channels are named.",
        },
        {
          label: "Nudges",
          slug: "nudges",
          icon: IconEnum.Nudge,
          Component: NudgesRoute,
          hide:
            commsPlatform !== CommsPlatform.Slack ||
            identity.feature_gates.suggestions,
          requiredProduct: Product.Response,
          overviewDescription:
            "Create custom nudges that you can send to incident channels.",
        },
        {
          label: "Suggestions",
          slug: "suggestions",
          icon: IconEnum.Nudge,
          Component: SuggestionsRoute,
          hide:
            commsPlatform !== CommsPlatform.Slack ||
            !identity.feature_gates.suggestions,
          requiredProduct: Product.Response,
          overviewDescription:
            "Help your responders progress the incident using well-timed suggestions.",
        },
        {
          label: "Forms",
          slug: "forms",
          icon: IconEnum.InputField,
          Component: IncidentFormsRoute,
          overviewDescription:
            "Configure the forms that users see when moving an incident through the lifecycle.",
          newUntil: new Date("2023-12-01"),
        },
      ],
    },
    {
      label: "Improve",
      showOnOverview: true,
      color: ColorPaletteEnum.Blue,
      overviewSummary:
        "Make running a great post-incident process as easy as possible.",
      items: [
        {
          label: "Post-incident flow",
          slug: "post-incident-flow",
          Component: PostIncidentFlowRoute,
          icon: IconEnum.Clipboard,
          requiredProduct: Product.Response,
          overviewDescription:
            "Define your post-incident process with tasks to complete and statuses to progress through.",
        },
        {
          label: "Follow-ups",
          slug: "follow-ups",
          Component: FollowUpRoute,
          icon: IconEnum.Checklist,
          requiredProduct: Product.Response,
          overviewDescription:
            "Use Follow-up priorities to assign the importance of tasks relative to others",
        },
        {
          label: "Debriefs",
          slug: "debriefs",
          Component: SettingsDebriefsRoute,
          icon: IconEnum.Calendar,
          newUntil: new Date("2023-10-01"),
          requiredProduct: Product.Response,
          overviewDescription:
            "Use debriefs to discuss and capture the learnings from an incident.",
        },
        {
          label: "Post-mortems",
          slug: "post-mortem",
          icon: IconEnum.Doc,
          Component: PostmortemRoute,
          requiredProduct: Product.Response,
          overviewDescription:
            "Make it easy to create post-mortems so your team can learn from incidents.",
        },
        {
          label: "Policies",
          slug: "policies",
          Component: PolicyRoute,
          icon: IconEnum.Shield,
          isPro: true,
          requiredProduct: Product.Response,
          overviewDescription:
            "Encode your commitments around incident closure rates, follow-up completions, and more.",
        },
      ],
    },
    {
      label: "Extend",
      showOnOverview: true,
      color: ColorPaletteEnum.Purple,
      overviewSummary:
        "Connect incident.io to your existing processes and tools.",
      items: [
        {
          label: "Integrations",
          slug: "integrations",
          icon: IconEnum.Integrations,
          Component: IntegrationsRoute,
          overviewDescription:
            "Amplify the value of the tools you already know and love.",
        },
        {
          label: "API keys",
          slug: "api-keys",
          Component: APIKeysRoute,
          icon: IconEnum.Key,
          isTeam: true,
          overviewDescription:
            "Access our public APIs and build your own powerful automations.",
        },
        {
          label: "Webhooks",
          slug: "webhooks",
          Component: WebhooksRoute,
          icon: IconEnum.Globe,
          isTeam: true,
          overviewDescription:
            "Notify external systems when something happens in incident.io.",
        },
        {
          label: "Incident tickets",
          slug: "incident-tickets",
          Component: IncidentTicketsRoute,
          icon: IconEnum.IncidentTicket,
          isPro: true,
          overviewDescription:
            "Create tickets in your issue tracker to track incidents.",
        },
      ],
    },
    {
      label: "Account",
      items: [
        {
          label: "Billing",
          slug: "billing",
          icon: IconEnum.Billing,
          Component: BillingRoute,
          exact: true,
        },
        {
          label: "Security",
          slug: "security",
          Component: SecurityRoute,
          icon: IconEnum.LockClosed,
          exact: true,
        },
        {
          label: "Users",
          slug: "users",
          Component: SettingsUsersRoute,
          icon: IconEnum.Users,
        },
        {
          label: "Slack Enterprise Grid",
          slug: "slack-enterprise-grid",
          icon: IconEnum.SlackTeam,
          Component: SlackEnterpriseGridRoute,
          hide: !slackEnterpriseSettings?.slack_enterprise_id,
        },
      ],
    },
    {
      label: "General",
      items: [
        {
          label: "AI",
          slug: "ai",
          icon: IconEnum.Robot,
          Component: AIRoute,
          isTeam: true,
        },
        {
          label: "Automation",
          slug: "automation",
          icon: IconEnum.Automation,
          Component: AutomationRoute,
        },
        {
          label: "Decision flows",
          slug: "decision-flows",
          icon: IconEnum.DecisionFlows,
          Component: SettingsDecisionFlowsRoute,
          requiredProduct: Product.Response,
          hide: commsPlatform !== CommsPlatform.Slack,
          exact: true,
        },
      ],
    },
  ];

  // The overview page needs to know about all the other pages, so we have to add it after
  // declaring the array to avoid recursion
  const SettingsOverview = () => (
    <SettingsOverviewPage sections={_.cloneDeep(settingsPages)} />
  );
  settingsPages.unshift({
    label: "",
    items: [
      {
        label: "Overview",
        icon: IconEnum.View,
        slug: "overview",
        Component: SettingsOverview,
      },
    ],
  });

  const sortedSettingsPages = settingsPages.map((page) => {
    const sortedItems = page.items.slice().sort((a, b) => {
      // First, check if the item should be prioritized based on hasProduct
      const hasProductA = a.requiredProduct
        ? hasProduct(a.requiredProduct)
        : true;
      const hasProductB = b.requiredProduct
        ? hasProduct(b.requiredProduct)
        : true;

      if (hasProductA && !hasProductB) {
        return -1; // a should come before b
      } else if (!hasProductA && hasProductB) {
        return 1; // b should come before a
      } else {
        // If both have the same hasProduct result, preserve original order
        return 0;
      }
    });

    // Return the page with sorted items
    return {
      ...page,
      items: sortedItems,
    };
  });

  return sortedSettingsPages;
};

export const SettingsRoute = (): React.ReactElement => {
  const settingsPageGroups = useSettingsPages();

  return (
    <SecondaryNavPageWrapper
      title="Settings"
      navItemGroups={settingsPageGroups}
      pathPrefix="settings"
      icon={IconEnum.Cog}
    >
      <Routes>
        {settingsPageGroups.map((grp) =>
          grp.items.map((page) => {
            return (
              <Route
                path={`${page.slug}${page.exact ? "" : "/*"}`}
                element={
                  <>
                    <Helmet title={`Settings: ${page.label} - incident.io`} />
                    <page.Component />
                  </>
                }
                key={page.slug}
              />
            );
          }),
        )}
        <Route
          path="billing/success"
          element={<SettingsBillingSuccessRoute />}
        />
        {/* Fallback with a redirect to a specific subsection */}
        <Route path="*" element={<OrgAwareNavigate to="overview" replace />} />
      </Routes>
    </SecondaryNavPageWrapper>
  );
};

export const SettingsSubPageWrapper = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & Omit<SecondaryNavSubPageWrapperProps, "icon" | "title">) => {
  const options = useSettingsPages();

  const location = useLocation();
  // /org-slug/settings/:slug
  const slug = location.pathname.split("/")[3];

  // to find the page, we'll take the path, split it by slashes
  // and go through the slashes until we find a match for our 'parent' page
  // there can't be collisions here because the slugs are unique!
  const page = options.flatMap((p) => p.items).find((i) => slug === i.slug);

  return (
    <SecondaryNavSubPageWrapper
      icon={page?.icon || IconEnum.Cog}
      title={page?.label || "Settings"}
      {...props}
    >
      {children}
    </SecondaryNavSubPageWrapper>
  );
};
