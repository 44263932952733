import { CustomDashboard, ScopeNameEnum } from "@incident-io/api";
import { AppliedFiltersBannerReadOnly } from "@incident-shared/filters";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useParams } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { InsightsCustomDashboardEmptyState } from "../common/InsightsCustomDashboardEmptyState";
import { InsightsDateBadge } from "../common/InsightsDatePicker";
import { InsightsPanel } from "../common/InsightsPanel";
import { dateRangeToFormState, panelToFormData } from "../common/marshall";
import { InsightsScheduledReportDrawer } from "../schedule/InsightsScheduledReportDrawer";
import { InsightsShowCustomDashboardProvider } from "./InsightsShowCustomDashboardContext";

export const InsightsCustomDashboardRoute = () => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;

  const { id } = useParams<{ id: string }>() as { id: string };

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useAPI("insightsShowCustomDashboard", {
    id,
  });

  if (!insightsFeatureGate) {
    return <OrgAwareNavigate to="/insights" />;
  }

  const error = dashboardError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (dashboardLoading || !dashboardData) {
    return <FullPageLoader />;
  }

  return <InsightsCustomDashboardPage dashboard={dashboardData.dashboard} />;
};

const InsightsCustomDashboardPage = ({
  dashboard,
}: {
  dashboard: CustomDashboard;
}) => {
  const [showScheduleDrawer, setShowScheduleDrawer] = useState(false);
  const { scheduledInsightsReports } = useFlags();

  const noPanels = dashboard.panels.length === 0;

  const navigate = useOrgAwareNavigate();

  const navigateToEditAndOpenDrawer = () => {
    navigate(`/insights/dashboards/custom/${dashboard.id}/edit`, {
      state: {
        openAddDrawer: true,
      },
    });
  };
  const navigateToEditAndAddTextPanel = () => {
    navigate(`/insights/dashboards/custom/${dashboard.id}/edit`, {
      state: {
        addTextPanel: true,
      },
    });
  };

  const {
    data: reportSchedules,
    isLoading: schedulesLoading,
    error,
  } = useAPI("insightsListReportSchedules", undefined);

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const existingReportSchedule = reportSchedules?.report_schedules.find(
    (schedule) => schedule.dashboard_id === dashboard.id,
  );

  return (
    <InsightsShowCustomDashboardProvider dashboard={dashboard}>
      <PageWrapper
        width={PageWidth.Wide}
        icon={dashboard.icon}
        color={dashboard.color as unknown as ColorPaletteEnum}
        title={dashboard.name}
        className={noPanels ? "" : "bg-surface-secondary"}
        crumbs={[
          {
            title: "Insights",
            to: "/insights",
          },
        ]}
        backHref="/insights"
        noPadding
        accessory={
          // TODO: filters
          <div className="flex items-center gap-2">
            <InsightsDateBadge
              dateRange={dateRangeToFormState(dashboard.date_range)}
              size={BadgeSize.Large}
            />
            {scheduledInsightsReports ? (
              <>
                <EditDeleteDashboardDropdown dashboardID={dashboard.id} />
                <GatedButton
                  requiredScope={ScopeNameEnum.InsightsCustomDashboardsUpdate}
                  theme={
                    existingReportSchedule
                      ? ButtonTheme.Secondary
                      : ButtonTheme.Primary
                  }
                  icon={
                    existingReportSchedule
                      ? IconEnum.EnvelopeCheck
                      : IconEnum.EnvelopeClock
                  }
                  analyticsTrackingId="insights-schedule-dashboard"
                  onClick={() => setShowScheduleDrawer(true)}
                >
                  {existingReportSchedule ? "Scheduled" : "Schedule"}
                </GatedButton>
              </>
            ) : (
              <GatedButton
                requiredScope={ScopeNameEnum.InsightsCustomDashboardsUpdate}
                theme={ButtonTheme.Primary}
                icon={IconEnum.Edit}
                analyticsTrackingId="insights-edit-dashboard-report"
                href={`/insights/dashboards/custom/${dashboard.id}/edit`}
              >
                Edit dashboard
              </GatedButton>
            )}
          </div>
        }
        banner={
          <AppliedFiltersBannerReadOnly
            totalNumberOfItems={null}
            itemsLabel={null}
            style="wholeOfPage"
            badgeTheme="slate"
            className="bg-white border-b"
          />
        }
      >
        {noPanels ? (
          <InsightsCustomDashboardEmptyState
            openAddPanelDrawer={() => navigateToEditAndOpenDrawer()}
            addTextPanel={() => navigateToEditAndAddTextPanel()}
          />
        ) : (
          <div className="flex flex-col grow gap-6 p-6">
            {dashboard.panels.map((panel, idx) => (
              <InsightsPanel
                key={idx}
                panel={panelToFormData(panel)}
                panelIdx={idx}
              />
            ))}
          </div>
        )}
        <AnimatePresence>
          {showScheduleDrawer && (
            <InsightsScheduledReportDrawer
              dashboard={dashboard}
              existingSchedule={existingReportSchedule}
              onClose={() => setShowScheduleDrawer(false)}
              loading={schedulesLoading}
            />
          )}
        </AnimatePresence>
      </PageWrapper>
    </InsightsShowCustomDashboardProvider>
  );
};

const EditDeleteDashboardDropdown = ({
  dashboardID,
}: {
  dashboardID: string;
}) => {
  const navigate = useOrgAwareNavigate();

  return (
    <DropdownMenu
      analyticsTrackingId={"custom-dashboard.dropdown-click"}
      menuClassName={"w-[120px] mr-5"}
      triggerButtonTheme={ButtonTheme.Secondary}
      triggerIcon={IconEnum.DotsVertical}
      screenReaderText="Custom dashboard management options"
      side={"bottom"}
      align={"end"}
      alignOffset={-20}
    >
      <DropdownMenuItem
        analyticsTrackingId="insights-edit-dashboard"
        onSelect={() =>
          navigate(`/insights/dashboards/custom/${dashboardID}/edit`)
        }
        label="Edit dashboard"
        icon={IconEnum.Edit}
      />
      {/* TODO(FND-1379) Delete scheduled report  */}
    </DropdownMenu>
  );
};
