import { Callout, CalloutTheme } from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceDatadogSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  const DATADOG_PAYLOAD = `{
    "alert_transition": "$ALERT_TRANSITION",
    "deduplication_key": "$AGGREG_KEY-$ALERT_CYCLE_KEY",
    "title": "$EVENT_TITLE",
    "description": "$EVENT_MSG",
    "source_url": "$LINK",
    "metadata": {
        "id": "$ID",
        "alert_metric": "$ALERT_METRIC",
        "alert_query": "$ALERT_QUERY",
        "alert_scope": "$ALERT_SCOPE",
        "alert_status": "$ALERT_STATUS",
        "alert_title": "$ALERT_TITLE",
        "alert_type": "$ALERT_TYPE",
        "alert_url": "$LINK",
        "alert_priority": "$ALERT_PRIORITY",
        "date": "$DATE",
        "event_type": "$EVENT_TYPE",
        "hostname": "$HOSTNAME",
        "last_updated": "$LAST_UPDATED",
        "logs_sample": $LOGS_SAMPLE,
        "org": {
            "id": "$ORG_ID",
            "name": "$ORG_NAME"
        },
        "snapshot_url": "$SNAPSHOT",
        "tags": "$TAGS"
    }
}`;
  return (
    <SetupInfoNumberedList>
      <p>
        Go to the <strong>Integrations</strong> tab in Datadog
      </p>
      <p>
        Search for <strong>webhooks</strong> and choose{" "}
        <strong>Webhooks Integration</strong>
      </p>
      <div>
        <p>
          Create a <strong>new webhook</strong>
        </p>
        <Callout theme={CalloutTheme.Info} className={"my-4"}>
          If you&apos;re using conditional variables in your notification
          messages, make sure you are sending a message when an alert fires and
          when it recovers.
        </Callout>
      </div>
      <div className="overflow-hidden flex flex-col gap-4 pb-4">
        <p>
          Then copy and paste in the <strong>URL</strong>,{" "}
          <strong>payload</strong> and <strong>headers</strong> below. {"We'll"}
          parse all the fields in the <code>metadata</code> object and make them
          available as alert attributes.
        </p>
        <CodeBlock
          title={"URL"}
          code={`${publicApiUrl()}/v2/alert_events/datadog/${
            alertSourceConfig.id
          }`}
        />
        <CodeBlock title={"Payload"} code={DATADOG_PAYLOAD} />
        <CodeBlock
          title={"Custom headers"}
          code={`{
    "Authorization": "Bearer ${alertSourceConfig.secret_token}"
}`}
        />
      </div>
      <p>
        Once you&apos;ve saved your webhook, you can use it using{" "}
        <strong>@webhook-NAME</strong> when configuring an alert monitor
      </p>
    </SetupInfoNumberedList>
  );
};
