import { Toggle, ToggleProps } from "@incident-ui/Toggle/Toggle";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { tcx } from "src/utils/tailwind-classes";

import { GatedInputProps, useGateInput } from "../gates";

export type GatedToggleProps = GatedInputProps & ToggleProps;

/**
 * A special wrapped toggle that displays a tooltip when disabled.
 * Pass in `tooltipContent` to tell it what to show in the tooltip.
 * If there's no tooltip content, it's just a normal toggle.
 */
export function GatedToggle({
  spanClassName,
  ...props
}: GatedToggleProps): React.ReactElement {
  const { tooltip, disabled } = useGateInput(props);
  if (tooltip) {
    return (
      <Tooltip analyticsTrackingId={null} content={tooltip} delayDuration={0}>
        <span
          className={tcx("cursor-not-allowed flex items-center", spanClassName)}
        >
          <Toggle
            {...props}
            toggleClassName={tcx("pointer-events-none", props.toggleClassName)}
            disabled={true}
          />
        </span>
      </Tooltip>
    );
  }

  return <Toggle {...props} disabled={disabled} />;
}
