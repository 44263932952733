import { IntegrationSettingsProviderEnum } from "@incident-io/api";
import { IntegrationsListObject } from "@incident-shared/integrations";
import {
  Button,
  Callout,
  CalloutTheme,
  IconEnum,
  Link,
  Loader,
  Modal,
  ModalContent,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import React from "react";
import { useQueryParams } from "src/utils/query-params";

import { useInstalledIntegrationsThatUseDelegatedPermissions } from "./integrations";

export const ConnectMicrosoftDelegatedPermissionsModal = ({
  integration,
  onClose,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
}): React.ReactElement | null => {
  const params = useQueryParams();
  const error = params.get("microsoft_error");

  const otherIntegrationsThatUseDelegatedPermission =
    useInstalledIntegrationsThatUseDelegatedPermissions();

  return (
    <Modal
      title={`Connect to ${integration.provider_name}`}
      isOpen
      analyticsTrackingId={`connect-${integration.provider}-modal`}
      onClose={onClose}
    >
      <ModalContent>
        <div className="flex flex-col space-y-3">
          <p className="text-sm text-slate-700">
            We&apos;re about to send you to Microsoft authentication where (if
            you have permission) you can set up the incident.io integration.
          </p>
          {error ? (
            error === "microsoft_user_already_used" ? (
              <Callout theme={CalloutTheme.Danger}>
                This Microsoft account{" "}
                {<b>{params.get("microsoft_user_email")}</b>} is already linked
                to another incident.io organization. It&rsquo;s not possible to
                use the same service account for two organizations.
              </Callout>
            ) : (
              <Callout theme={CalloutTheme.Danger}>
                An error occurred when connecting to Microsoft. Please try again
                later.
                <CodeBlock
                  className={"py-2"}
                  title={"Error code"}
                  code={error}
                />
              </Callout>
            )
          ) : (
            <Callout theme={CalloutTheme.Warning}>
              {typeof otherIntegrationsThatUseDelegatedPermission ===
              "undefined" ? (
                <Loader />
              ) : (
                otherIntegrationsThatUseDelegatedPermission.length > 0 && (
                  <div className="pb-2">
                    <p>
                      We will request all required permissions to power the
                      following integrations, as they all share the same
                      connection to Microsoft:
                    </p>
                    {otherIntegrationsThatUseDelegatedPermission
                      .sort((a, b) =>
                        a.provider_name.localeCompare(b.provider_name),
                      )
                      .map(({ provider_name, provider }) => (
                        <li key={provider}>{provider_name}</li>
                      ))}
                  </div>
                )
              )}
              <div>
                You&apos;ll need to use a service account for this, as
                we&apos;ll be{" "}
                {integration.provider ===
                  IntegrationSettingsProviderEnum.OutlookCalendar && (
                  <>
                    subscribing to all calendar events that this user is invited
                    to.{" "}
                  </>
                )}
                {integration.provider ===
                  IntegrationSettingsProviderEnum.MicrosoftTeamsOnlineMeetings && (
                  <>creating online meetings on behalf of this user. </>
                )}
              </div>
              <Link
                to={`https://help.incident.io/articles/5470687337-using-a-service-account-to-integrate-with-microsoft-outlook-and-teams`}
                className={"inline-block"}
                analyticsTrackingId={"connect-microsoft-help-link"}
                openInNewTab
              >
                <p className="text-slate-800 font-normal mr-1 hover:underline">
                  {`Learn more.`}
                </p>
              </Link>
            </Callout>
          )}
        </div>
        <div className="flex flex-row w-full justify-center pt-2">
          <Button
            analyticsTrackingId="login-with-micr"
            href={integration.add_integration_url}
            className="inline-flex items-center cursor-pointer"
            icon={IconEnum.Microsoft}
          >
            Sign in with Microsoft
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
