import {
  Avatar,
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconSize,
  Modal,
  ModalContent,
  Tooltip,
} from "@incident-ui";
import _, { kebabCase } from "lodash";
import { useState } from "react";
import {
  DebriefEventAttendee,
  DebriefEventAttendeeResponseEnum,
  User,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

// MaybeUser represents a user that may or may not be in our system.
export type MaybeUser = {
  // User metadata which we want to show in the modal opened on click.
  isNonMember?: boolean;
  attendee?: Omit<DebriefEventAttendee, "user">;
  greyedOut?: boolean;
} & (
  | {
      user: Pick<User, "id" | "name" | "avatar_url">;
      nonUserLabel?: never;
    }
  // When we don't have a user, we should pass a label to identify them e.g. email address.
  | {
      user?: never;
      nonUserLabel: string;
    }
);

export enum AvatarListClickableType {
  // Allow the whole avatar list to be clicked to open a modal
  Always,
  // Only open the modal if the user clicks "see more" when there are more than maxToShow users
  OnlyOnSeeMore,
}

export const AvatarList = ({
  users,
  modalTitle,
  maxToShow,
  clickableType,
  avatarClassName,
  avatarSize,
}: {
  users: MaybeUser[];
  modalTitle: string;
  maxToShow: number;
  clickableType: AvatarListClickableType;
  avatarClassName?: string;
  avatarSize?: IconSize;
}): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);

  // Sort users so that non-members are at the bottom of the list, and members are sorted by name.
  users = _.sortBy(users, (p) => p.nonUserLabel);
  users = _.sortBy(users, (p) => p.user?.name);

  return (
    <>
      <div className="flex shrink-0">
        {/* Wrap the list in a button if the whole thing should be clickable */}
        {clickableType === AvatarListClickableType.Always ? (
          <>
            <Button
              theme={ButtonTheme.Unstyled}
              analyticsTrackingId={null}
              onClick={() => setShowModal(true)}
            >
              <AvatarListInner
                users={users}
                modalTitle={modalTitle}
                maxToShow={maxToShow}
                setShowModal={setShowModal}
                avatarClassName={avatarClassName}
              />
            </Button>
          </>
        ) : (
          <AvatarListInner
            users={users}
            modalTitle={modalTitle}
            maxToShow={maxToShow}
            setShowModal={setShowModal}
            avatarClassName={avatarClassName}
            avatarSize={avatarSize}
          />
        )}
      </div>
      {showModal && (
        <AvatarsModal
          users={users}
          closeModal={() => setShowModal(false)}
          title={modalTitle}
        />
      )}
    </>
  );
};

const AvatarListInner = ({
  users,
  modalTitle,
  maxToShow,
  setShowModal,
  avatarClassName,
  avatarSize,
}: {
  users: MaybeUser[];
  modalTitle: string;
  avatarClassName?: string;
  avatarSize?: IconSize;
  maxToShow: number;
  setShowModal: (show: boolean) => void;
}): React.ReactElement => {
  return (
    <>
      {users.slice(0, maxToShow).map((p, idx) => (
        <Tooltip
          key={p.user?.id || p.nonUserLabel}
          content={p.user?.name || p.nonUserLabel}
          delayDuration={0}
        >
          <Button
            className={tcx(
              "border border-white border-[2px] rounded-full",
              idx > 0 && "-ml-1.5",
              avatarClassName,
            )}
            theme={ButtonTheme.Unstyled}
            analyticsTrackingId={null}
          >
            <Avatar
              size={avatarSize ?? IconSize.Medium}
              url={p.user?.avatar_url}
              name={p.user?.name || p.nonUserLabel}
              className={p.greyedOut ? "grayscale" : ""}
            />
          </Button>
        </Tooltip>
      ))}
      {users.length > maxToShow && (
        <Button
          analyticsTrackingId={`show-modal-${kebabCase(modalTitle)}`}
          className={`relative rounded-full flex items-center justify-center ml-[-0.4rem]`}
          theme={ButtonTheme.Unstyled}
          onClick={() => setShowModal(true)}
          title="See more"
        >
          <ExtraAvatars n={users.length - maxToShow} />
        </Button>
      )}
    </>
  );
};

const AvatarsModal = ({
  title,
  users,
  closeModal,
}: {
  title: string;
  users: MaybeUser[];
  closeModal: () => void;
}): React.ReactElement => {
  const groupedUsers = groupAndSortUsers(users);
  // If we grouped users into multiple groups, we should show the group names
  const shouldShowGroups = groupedUsers.length > 1;

  return (
    <Modal
      isOpen={true}
      analyticsTrackingId={kebabCase(title)}
      title={title}
      onClose={closeModal}
    >
      <ModalContent className="space-y-4">
        {Object.entries(groupedUsers).map(([_, value]) => {
          return (
            <>
              {shouldShowGroups && value.usersInGroup.length > 0 && (
                <div className="pt-2 text-slate-600 tracking-widest text-xs font-medium">
                  {value.name?.toUpperCase()}
                </div>
              )}
              {value.usersInGroup.map((p: MaybeUser) => (
                <li key={p.user?.id || p.nonUserLabel} className="list-none">
                  <div className="flex-center-y justify-between">
                    <div className="flex-center-y gap-2">
                      <Avatar
                        size={IconSize.Large}
                        key={p.user?.id || p.nonUserLabel}
                        url={p.user?.avatar_url}
                      />
                      <span className="text-slate-800 max-w-[340px] truncate">
                        {p.user?.name || p.nonUserLabel}
                      </span>
                    </div>
                    {p.attendee ? (
                      <AttendeeResponseBadge response={p.attendee.response} />
                    ) : (
                      p.isNonMember && (
                        <Badge theme={BadgeTheme.Tertiary}>Revoked</Badge>
                      )
                    )}
                  </div>
                </li>
              ))}
            </>
          );
        })}
      </ModalContent>
    </Modal>
  );
};

const ExtraAvatars = ({ n }: { n: number }) => {
  return (
    <div
      className={tcx(
        "bg-blue-200 text-blue-500 rounded-full h-[24px] border border-stroke flex-center text-xs px-1",
        {
          "w-6": n <= 9, // this is 24px
          "w-8": n > 9,
          "w-10": n > 99,
        },
      )}
    >
      {`+${n > 999 ? "999" : n}`}
    </div>
  );
};

const AttendeeResponseBadge = ({
  response,
}: {
  response: DebriefEventAttendeeResponseEnum;
}): React.ReactElement => {
  const classNames = "!font-normal border px-3 !py-1 my-0";
  switch (response) {
    case DebriefEventAttendeeResponseEnum.Yes:
      return (
        <Badge
          theme={BadgeTheme.Success}
          className={tcx(classNames, "border-green-300")}
        >
          Attending
        </Badge>
      );
    case DebriefEventAttendeeResponseEnum.No:
      return (
        <Badge
          theme={BadgeTheme.Warning}
          className={tcx(classNames, "border-amber-300")}
        >
          Declined
        </Badge>
      );
    case DebriefEventAttendeeResponseEnum.Maybe:
      return (
        <Badge
          theme={BadgeTheme.Unstyled}
          className={tcx(
            classNames,
            "text-blue-500 bg-blue-surface border-blue-200",
          )}
        >
          Maybe
        </Badge>
      );
    case DebriefEventAttendeeResponseEnum.None:
      return (
        <Badge
          theme={BadgeTheme.Unstyled}
          className={tcx(
            classNames,
            "text-slate-700 bg-surface-tertiary border-stroke",
          )}
        >
          No response
        </Badge>
      );
    default:
      return <></>;
  }
};

function responseToRank(response: DebriefEventAttendeeResponseEnum): number {
  switch (response) {
    case DebriefEventAttendeeResponseEnum.Yes:
      return 1;
    case DebriefEventAttendeeResponseEnum.Maybe:
      return 2;
    case DebriefEventAttendeeResponseEnum.None:
      return 3;
    case DebriefEventAttendeeResponseEnum.No:
      return 4;
    default:
      return 5;
  }
}

type GroupedUsers = {
  name?: string;
  usersInGroup: MaybeUser[];
};

function groupAndSortUsers(users: MaybeUser[]): GroupedUsers[] {
  // For now, we only want to create groups if there are attendees involved.
  // Note that this assumes we're not mixing different types of users in the same list.
  if (!users[0].attendee) {
    return [
      {
        usersInGroup: users, // Users are already sorted by name
      },
    ];
  }

  let optionalUsers: MaybeUser[] = [];
  let requiredUsers: MaybeUser[] = [];
  users.forEach((user) => {
    if (!user.attendee) {
      throw new Error("User should have attendee");
    }
    if (user.attendee?.is_optional) {
      optionalUsers.push(user);
    } else {
      requiredUsers.push(user);
    }
  });

  optionalUsers = _.sortBy(optionalUsers, (u) => {
    // @ts-expect-error there will always be an attendee here
    return responseToRank(u.attendee.response);
  });

  requiredUsers = _.sortBy(requiredUsers, (u) => {
    // @ts-expect-error there will always be an attendee here
    return responseToRank(u.attendee.response);
  });

  return [
    {
      name: "Required",
      usersInGroup: requiredUsers,
    },
    {
      name: "Optional",
      usersInGroup: optionalUsers,
    },
  ];
}
