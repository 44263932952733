import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import {
  drawerUrlFor,
  IntegrationsListObject,
  reconnectModalUrlFor,
} from "@incident-shared/integrations";
import { Button, ButtonTheme, IconEnum, Loader } from "@incident-ui";
import { useParams } from "react-router";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";

export const InstallIntegrationButton = ({
  integration,
  className = "",
  onOAuthModal,
  isReconnect,
  reinstallNeeded = false,
}: {
  integration: IntegrationsListObject;
  className?: string;
  onOAuthModal?: boolean;
  isReconnect?: boolean;
  reinstallNeeded?: boolean;
}): React.ReactElement | null => {
  const { installedIntegrationsCount } = useIntegrations();

  const { identity, hasScope } = useIdentity();
  if (!identity) {
    return <Loader />;
  }
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const orgCanInstallIntegration = !numericGateLimitReached(
    identity.feature_gates.integrations_count,
    installedIntegrationsCount ?? 0,
  );

  if (integration.installed && !isReconnect && !reinstallNeeded) {
    return null;
  }

  if (isReconnect || reinstallNeeded) {
    return (
      <ConnectButton
        integration={integration}
        className={className}
        onOAuthModal={onOAuthModal}
        text="Reinstall"
      />
    );
  }

  if (integration.requires_upgrade) {
    return (
      <Button
        href={`mailto:help@incident.io?subject=${encodeURIComponent(
          "Using " + integration.provider_name,
        )}`}
        analyticsTrackingId="settings-integrations-request"
        icon={IconEnum.LockClosed}
        className={className}
      >
        Talk to us
      </Button>
    );
  }

  if (!canEditSettings || !orgCanInstallIntegration) {
    return (
      <GatedButton
        analyticsTrackingId="settings-integrations-request"
        disabledTooltipContent={
          canEditSettings
            ? undefined
            : "You do not have permissions to configure integrations."
        }
        upgradeRequiredProps={{
          gate: {
            type: "numeric",
            value: identity.feature_gates.integrations_count,
            featureNameSingular: "integration",
          },
          featureName: "integrations",
        }}
        theme={ButtonTheme.Primary}
        disabled={!canEditSettings}
        className={className}
      >
        Install
      </GatedButton>
    );
  }

  return (
    <ConnectButton
      theme={ButtonTheme.Primary}
      integration={integration}
      className={className}
      onOAuthModal={onOAuthModal}
      icon={IconEnum.ExternalLink}
    />
  );
};

const ConnectButton = ({
  integration,
  text = "Install",
  className = "",
  theme,
  onOAuthModal,
  icon = undefined,
  isReconnect = false,
}: {
  integration: IntegrationsListObject;
  text?: string;
  className?: string;
  theme?: ButtonTheme;
  onOAuthModal?: boolean;
  icon?: IconEnum;
  isReconnect?: boolean;
}): React.ReactElement => {
  const { slug } = useParams();

  // If the button is on the ConnectIntegrationWithOAuthModal modal, which is simple modal we can
  // deep link to, that says "We're about to redirect you", the button should redirect through the
  // OAuth flow.
  if (onOAuthModal) {
    return (
      <Button
        theme={theme}
        href={integration.add_integration_url}
        analyticsTrackingId={`settings-integrations-connect-${integration.provider}`}
        className={className}
        icon={icon}
      >
        {text}
      </Button>
    );
  } else {
    // Otherwise, open a modal for the provider. This will be the connect modal if it exists for the
    // provider, or the ConnectIntegrationWithOAuthModal.
    const url = `/${slug}/settings/integrations/${
      isReconnect
        ? reconnectModalUrlFor(integration.provider)
        : drawerUrlFor(integration.provider)
    }`;
    return (
      <Button
        theme={theme}
        href={url}
        analyticsTrackingId={`settings-integrations-connect-${integration.provider}`}
        className={className}
        icon={icon}
      >
        {text}
      </Button>
    );
  }
};
