/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffListPromptNamesResponseBody
 */
export interface AIStaffListPromptNamesResponseBody {
    /**
     * List of registered prompt names
     * @type {Array<string>}
     * @memberof AIStaffListPromptNamesResponseBody
     */
    prompts: Array<string>;
}

export function AIStaffListPromptNamesResponseBodyFromJSON(json: any): AIStaffListPromptNamesResponseBody {
    return AIStaffListPromptNamesResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListPromptNamesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListPromptNamesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prompts': json['prompts'],
    };
}

export function AIStaffListPromptNamesResponseBodyToJSON(value?: AIStaffListPromptNamesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prompts': value.prompts,
    };
}

