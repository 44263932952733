import { Icon, IconEnum } from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";

export const SensitiveInfoBanner = () => {
  const { isImpersonating } = useIdentity();
  if (!isImpersonating) {
    return null;
  }
  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex flex-col gap-2"
      role="alert"
    >
      <div className="flex flex-row items-center gap-2">
        <Icon id={IconEnum.Warning} className="text-red-400 w-5 h-5" />
        <h2 className="font-semibold">
          Sensitive Information - Restricted Access
        </h2>
      </div>
      <div className="whitespace-pre-wrap pl-7">
        This analysis contains sensitive information that must not be shared
        outside the organization. Do not copy or reproduce this content in
        production systems or our codebase without proper review and
        modification.
      </div>
    </div>
  );
};
