import {
  Condition,
  ConditionSubjectColorEnum,
  ConditionSubjectIconEnum,
} from "@incident-io/api";

import { MenuEntry } from "../ReferenceSelectorPopover/ReferenceSelectorPopover";

// This allows us to define builders for references that have a one-click operation
// definition. For example, an AI triggered condition doesn't need to be "is_set", "is_not_set"
// , "true" or "false", but rather the existence of which is indicative of the behaviour we want.
export const FIXED_OPERATIONS: Record<
  string,
  (entry: MenuEntry) => Condition | undefined
> = {
  has_suggestion: (entry) => ({
    operation: {
      label: "",
      value: "true",
    },
    param_bindings: [],
    params: [],
    subject: {
      reference: entry.key,
      icon:
        (entry.icon as unknown as ConditionSubjectIconEnum) ??
        ConditionSubjectIconEnum.Sparkles,
      color: entry.color as unknown as ConditionSubjectColorEnum,
      label: entry.label,
    },
  }),
};
