import {
  AppliedFiltersBanner,
  ExtendedFormFieldValue,
  FilterPopover,
  FiltersContextProvider,
  filterToQueryKeyValues,
} from "@incident-shared/filters";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import _ from "lodash";
import React, { useCallback } from "react";

import { useAPI } from "../../../utils/swr";
import { formFieldValueToInsightsFilter } from "../../insights/v3/dashboards/common/marshall";
import { useAlertFilters } from "../alert-details/useAlertFilters";
import { useAlertInsightsDates } from "../alert-details/useAlertInsightsDates";
import { AlertActivityChart } from "./AlertActivityChart";
import { AlertInsightTables } from "./AlertInsightTables";
import { KeyInsights } from "./KeyInsights";

export type GetNavigateHref = ({
  theme,
  title,
}: {
  theme?: string;
  title?: string;
}) => string;

export const AlertsOverviewPage: React.FC = () => {
  const { timezone, startDate, endDate } = useAlertInsightsDates({
    fromStartOfToday: true,
  });

  const { filters, setFilters, filterFields, filtersLoading, filtersError } =
    useAlertFilters();

  const isLoading = filtersLoading;
  const error = filtersError;

  const {
    data: keyInsightsData,
    isLoading: isLoadingKeyInsights,
    error: keyInsightsError,
  } = useAPI("alertsGetInsightsOverviewKeyInsights", {
    getInsightsOverviewKeyInsightsRequestBody: {
      timezone: timezone,
      start_date: startDate,
      end_date: endDate,
      filters: filters.map(formFieldValueToInsightsFilter),
    },
  });

  const getNavigateHref: GetNavigateHref = useCallback(
    ({ theme, title }) => {
      const query: ExtendedFormFieldValue | undefined = title
        ? {
            key: "title",
            filter_id: "title",
            field_key: "title",
            field_id: "title",
            operator: "is",
            string_value: title,
          }
        : theme
        ? {
            key: "themes",
            filter_id: "themes",
            field_key: "themes",
            field_id: "themes",
            operator: "one_of",
            string_value: theme,
          }
        : undefined;

      const allQueryParams = _.flatMap(
        query ? [...filters, query] : filters,
        filterToQueryKeyValues,
      );
      const searchParams = new URLSearchParams();
      for (const { key, value } of allQueryParams) {
        searchParams.append(key, value);
      }
      return `/alerts/recent?${searchParams.toString()}`;
    },
    [filters],
  );

  if (error) {
    return <GenericErrorMessage />;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <FiltersContextProvider
      filters={filters}
      setFilters={setFilters}
      availableFilterFields={filterFields}
      filtersLoading={isLoading}
      kind={"alert"}
    >
      <div className={"flex flex-col justify-center w-full min-w-0 py-10 px-8"}>
        <div className={"mb-2 flex flex-row justify-between align-center"}>
          <div className="text-2xl-bold text-content-primary inline-flex items-center">
            Alerts overview
            <Badge theme={BadgeTheme.Info} className={"ml-2"}>
              Beta
            </Badge>
          </div>
          <FilterPopover
            renderTriggerButton={({ onClick }) => (
              <Button
                theme={ButtonTheme.Naked}
                onClick={() => onClick()}
                analyticsTrackingId={"alert-list-filter"}
                icon={IconEnum.Filter}
                iconProps={{
                  size: IconSize.Medium,
                }}
              >
                Add filter
              </Button>
            )}
          />
        </div>
        <AppliedFiltersBanner
          totalNumberOfItems={null} // Not applicable for this page
          itemsLabel={"alert"}
          style={"partOfPage"}
          className={"mb-4"}
        />

        <div className="flex justify-center w-full min-w-0">
          <div className="grow w-full">
            <div className="py-6 flex flex-col gap-10">
              <KeyInsights
                isLoadingKeyInsights={isLoadingKeyInsights}
                keyInsightsData={keyInsightsData}
                keyInsightsError={keyInsightsError}
                getNavigateHref={getNavigateHref}
              />
              <AlertActivityChart
                timezone={timezone}
                startDate={startDate}
                endDate={endDate}
                filters={filters}
              />
              {/* Insights tables */}
              <AlertInsightTables
                timezone={timezone}
                startDate={startDate}
                endDate={endDate}
                filters={filters}
                getNavigateHref={getNavigateHref}
              />
            </div>
          </div>
        </div>
      </div>
    </FiltersContextProvider>
  );
};
