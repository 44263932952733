import { ScopeNameEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Heading,
  IconEnum,
  StackedList,
  StackedListButton,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { StackedListGatedButton } from "@incident-ui/StackedList/StackedListGatedButton";
import { useState } from "react";
import { PlanBadge } from "src/components/settings/PlanBadge";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { ImportModal } from "../atlassian-import/ImportModal";
import { CreateStatusPageProps } from "../view/StatusPagesHomepage";

export const PAGE_TYPE_TO_ICON = {
  public: IconEnum.Globe,
  customer: IconEnum.Users,
  internal: IconEnum.LockClosed,
  standalone: IconEnum.File,
  subpage: IconEnum.Files,
  atlassian: IconEnum.AtlassianStatuspage,
};

export const ChoosePageTypeDrawer = (props: CreateStatusPageProps) => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/status-pages");
  const { identity } = useIdentity();
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);

  // We need to check if the user has Atlassian pages they could potentially import
  const {
    data: { available_migrations: availablePages },
  } = useAPI("statusPageListAvailableMigrations", undefined, {
    fallbackData: { available_migrations: [] },
  });

  let importPagesAvailable = false;
  if (availablePages.length !== 0) {
    importPagesAvailable = true;
  }

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          title="Create status page"
          onClose={onClose}
          icon={IconEnum.StatusPage}
          iconClassName="bg-alarmalade-500"
        />
        <DrawerBody>
          <div className="flex flex-col self-stretch items-start space-y-6">
            <div>
              <Heading level={1} className="medium pb-3">
                Public
              </Heading>
              <div className="text-xs-med text-content-secondary pb-2">
                Create from scratch
              </div>
              <StackedList>
                <StackedListButton
                  disabled={!props.canCreatePublicPages}
                  key="standalone"
                  analyticsTrackingId={"status-page-select-public-single"}
                  icon={PAGE_TYPE_TO_ICON["standalone"]}
                  badgeNode={
                    !props.canCreatePublicPages ? (
                      <PlanBadge
                        planName="enterprise"
                        label="Plan upgrade required"
                      />
                    ) : undefined
                  }
                  title="Create single page"
                  description="Create a unified public status page to serve all your users."
                  href={"/status-pages/public/create"}
                />
                <StackedListButton
                  disabled={!identity?.feature_gates.status_pages_sub_pages}
                  key="parent"
                  analyticsTrackingId={"status-page-select-public-parent"}
                  icon={PAGE_TYPE_TO_ICON["subpage"]}
                  title="Create with sub-pages"
                  badgeNode={
                    identity?.feature_gates
                      .status_pages_sub_pages ? undefined : (
                      <PlanBadge
                        planName="enterprise"
                        label="Enterprise only"
                      />
                    )
                  }
                  description="Create multiple connected public status pages, e.g. a global page, and individual pages for each region in which you operate."
                  href={"/status-pages/public/sub-pages/create"}
                />
              </StackedList>
              <>
                <div className="text-xs-med text-content-secondary pt-6 pb-2">
                  Import existing status page
                </div>
                <StackedList>
                  <StackedListGatedButton
                    key="import"
                    analyticsTrackingId={"status-page-select-import"}
                    icon={PAGE_TYPE_TO_ICON["atlassian"]}
                    disabled={!importPagesAvailable}
                    requiredScope={ScopeNameEnum.StatusPagesConfigure}
                    title="Import from Atlassian"
                    description="Using an Atlassian page already? Easily import it here"
                    onSelect={() => setImportModalIsOpen(true)}
                    disabledTooltipContent={
                      "To import your pages, go to Settings > Integrations and configure your Atlassian Statuspage integration."
                    }
                  />
                </StackedList>
                {importModalIsOpen && (
                  <ImportModal onClose={() => setImportModalIsOpen(false)} />
                )}
              </>
            </div>
            <div>
              <Heading level={1} className="medium pb-3">
                Private
              </Heading>
              <StackedList>
                <StackedListButton
                  title="Customer status page"
                  analyticsTrackingId={"status-page-select-customer"}
                  icon={PAGE_TYPE_TO_ICON["customer"]}
                  description="Securely provide private status updates to specific customers."
                  href={"/status-pages/customer/create"}
                  disabled={!props.canCreateCustomerPages}
                />
                <StackedListButton
                  key="internal"
                  analyticsTrackingId={"status-page-select-internal"}
                  icon={PAGE_TYPE_TO_ICON["internal"]}
                  title="Internal status page"
                  description="Share active status updates with your internal employees, only accessible to your incident.io users."
                  href={"/status-pages/internal/create"}
                  disabled={!props.canCreateInternalPages}
                />
              </StackedList>
            </div>
          </div>
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};
