import { Button, ButtonTheme, LoadingWrapper, StackedList } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

const IncidentStackedListSection = ({
  title,
  children,
  titleAccessory,
  loading,
}: {
  title?: React.ReactNode;
  titleAccessory?: React.ReactNode;
  children: React.ReactNode;
  loading: boolean;
}): React.ReactElement => {
  return (
    <div className="flex flex-col gap-2">
      {title && (
        <div className="flex items-center justify-between gap-2">
          <h3 className="text-xs-bold">{title}</h3>
          {titleAccessory}
        </div>
      )}
      <LoadingWrapper loading={loading}>
        <StackedList>{children}</StackedList>
      </LoadingWrapper>
    </div>
  );
};

const IncidentStackedListItem = ({
  className,
  iconNode,
  title,
  onClick,
  href,
  accessoryOne,
  accessoryTwo,
  footerAccessory,
}: {
  className?: string;
  iconNode: React.ReactNode;
  title: React.ReactNode;
  accessoryOne: React.ReactNode;
  onClick?: () => void;
  href?: string;
  accessoryTwo: React.ReactNode;
  footerAccessory?: React.ReactNode;
}): React.ReactElement => {
  const isInteractive = onClick || href;

  return (
    <>
      <div
        className={tcx(
          "flex gap-2 items-center h-11 truncate",
          isInteractive && "hover:bg-surface-secondary",
          className,
        )}
      >
        {isInteractive ? (
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Unstyled}
            className="flex items-center grow gap-2 cursor-pointer pl-4 h-11 shrink min-w-0"
            onClick={onClick}
            href={href}
            openInNewTab
          >
            <StackedItemContent iconNode={iconNode} title={title} />
          </Button>
        ) : (
          <div className="flex items-center grow gap-2 pl-4 h-11 shrink min-w-0">
            <StackedItemContent iconNode={iconNode} title={title} />
          </div>
        )}
        <div className="shrink-0 flex items-center">{accessoryOne}</div>
        <div className="shrink-0 flex items-center pr-4">{accessoryTwo}</div>
      </div>
      {footerAccessory && (
        <div className="text-sm-normal p-4 rounded-b-lg border-t-0 shadow-none">
          {footerAccessory}
        </div>
      )}
    </>
  );
};

const StackedItemContent = ({
  iconNode,
  title,
}: {
  iconNode: React.ReactNode;
  title: React.ReactNode;
}) => {
  return (
    <>
      <div className="shrink-0 flex items-center justify-center">
        {iconNode}
      </div>
      <div className="truncate grow text-xs-med text-left">{title}</div>
    </>
  );
};

export const IncidentStackedList = {
  Section: IncidentStackedListSection,
  Item: IncidentStackedListItem,
};
