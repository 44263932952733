import { IntegrationSettingsProviderEnum } from "@incident-io/api";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Loader,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { AboutThisIntegration } from "src/components/settings/integrations/common/AboutThisIntegration";
import { IntegrationCatalogTypeList } from "src/components/settings/integrations/common/IntegrationCatalogTypeList";
import { useIntegrationsList } from "src/components/settings/integrations/IntegrationsRoute";
import { IntegrationDrawerTitle } from "src/components/settings/integrations/list/IntegrationDrawer";
import { useClient } from "src/contexts/ClientContext";
import { useIsCatalogTypeReady } from "src/hooks/useIsCatalogTypeReady";
import { useMutation } from "src/utils/fetchData";

export const ServiceWizardIntegrationDrawer = ({
  provider,
  resource,
}: {
  provider: IntegrationSettingsProviderEnum;
  resource: string;
}) => {
  const { integrations, loading, error } = useIntegrationsList();
  const integration = integrations.find((i) => i.provider === provider);

  const navigate = useOrgAwareNavigate();
  const [showConnectModal, setShowConnectModal] = useState(false);

  const config = IntegrationConfigFor(provider);

  const catalogType = useIsCatalogTypeReady(resource);

  const onClose = () =>
    navigate("/catalog/service-wizard/choose-source-of-truth");

  const apiClient = useClient();
  const showToast = useToast();

  const [bootstrapExisting, { saving }] = useMutation(
    async () => {
      await apiClient.catalogBootstrapServiceType({
        bootstrapServiceTypeRequestBody: {
          catalog_type_id: catalogType?.id,
        },
      });
      navigate(`/catalog/service-wizard/${catalogType?.id}/add-attributes`);
    },
    {
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error bootstrapping service type",
        });
      },
    },
  );

  if (error) {
    return (
      <Drawer width="medium" onClose={onClose}>
        <DrawerContents>
          <GenericErrorMessage error={error} />
        </DrawerContents>
      </Drawer>
    );
  }

  if (loading || !integration) {
    return (
      <Drawer width="medium" onClose={onClose}>
        <DrawerContents>
          <Loader />
        </DrawerContents>
      </Drawer>
    );
  }

  return (
    <>
      <Drawer width="medium" onClose={onClose}>
        <DrawerContents>
          <IntegrationDrawerTitle
            config={config}
            subtitle={config.description}
            setShowConnectModal={setShowConnectModal}
            integration={integration}
            onClose={onClose}
          />
          <DrawerBody>
            {integration.installed ? (
              <Callout
                title={`Integration installed`}
                subtitle={`You're now ready to set up your teams with ${config.label}.`}
                theme={CalloutTheme.Success}
              />
            ) : (
              <>
                <AboutThisIntegration
                  hexColor={config.hexColor || "#000000"}
                  providerLabel={config.label}
                  features={config.features}
                  provider={provider}
                />
                <IntegrationCatalogTypeList
                  types={integration.available_catalog_types}
                  integrationInstalled={integration.installed}
                />
              </>
            )}
          </DrawerBody>
          <DrawerFooter>
            <div className="flex justify-end">
              <Button
                analyticsTrackingId={null}
                theme={ButtonTheme.Primary}
                onClick={() => bootstrapExisting({})}
                loading={!catalogType || saving}
              >
                Continue
              </Button>
            </div>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
      {config.CustomConnectModal && integration && showConnectModal && (
        <config.CustomConnectModal
          integration={integration}
          allIntegrations={integrations}
          onClose={() => setShowConnectModal(false)}
        />
      )}
    </>
  );
};
