import {
  IncidentPostmortemStatusEnum,
  PostmortemTemplateSection,
  PostmortemTemplateSectionTypeEnum,
} from "@incident-io/api";
import { assertUnreachable } from "@incident-io/status-page-ui";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";

export const getSectionTypeName = (
  type: PostmortemTemplateSectionTypeEnum,
): string => {
  let sectionTypeName = "";
  switch (type) {
    case PostmortemTemplateSectionTypeEnum.Custom:
      // We shouldn't hit here, but lets not explode if we do
      sectionTypeName = "Custom";
      break;
    case PostmortemTemplateSectionTypeEnum.FollowUps:
      sectionTypeName = "Follow-ups";
      break;
    case PostmortemTemplateSectionTypeEnum.Timeline:
      sectionTypeName = "Timeline";
      break;
    case PostmortemTemplateSectionTypeEnum.Summary:
      sectionTypeName = "Summary";
      break;
    case PostmortemTemplateSectionTypeEnum.KeyInformation:
      sectionTypeName = "Key Information";
      break;
    // This should only be on the backend for export
    case PostmortemTemplateSectionTypeEnum.Header:
      sectionTypeName = "Header";
      break;
    default:
      assertUnreachable(type);
  }

  return sectionTypeName;
};

export const getSectionName = (section: PostmortemTemplateSection): string => {
  if (section.type === PostmortemTemplateSectionTypeEnum.Custom) {
    if (section.config) {
      return section.config.name;
    }
  }
  return getSectionTypeName(section.type);
};

type StatusProp = {
  label: string;
  colors: ColorPaletteEnum;
};

const InProgressProps: StatusProp = {
  label: "In progress",
  colors: ColorPaletteEnum.Red,
};

export const StatusProps: Record<IncidentPostmortemStatusEnum, StatusProp> = {
  // Hack: We never need to show a badge for "Not started" because it's not possible
  // to move a postmortem to that status manually. Instead, let's render it as "In progress".
  // This also means we don't have to worry about the true postmortem status not getting reflected
  // in the UI immediately when the postmortem has been created async.
  [IncidentPostmortemStatusEnum.NotStarted]: InProgressProps,
  [IncidentPostmortemStatusEnum.Created]: InProgressProps,
  [IncidentPostmortemStatusEnum.Review]: {
    label: "In review",
    colors: ColorPaletteEnum.Blue,
  },
  [IncidentPostmortemStatusEnum.Complete]: {
    label: "Completed",
    colors: ColorPaletteEnum.Green,
  },
};
