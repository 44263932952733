import { usePylon } from "@bolasim/react-use-pylon";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { IntegrationsListObject } from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Input,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { InputType } from "@incident-ui/Input/Input";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  AtlassianStatuspageConfig,
  AtlassianStatuspagePage,
  Identity,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { PageSelectionForm } from "./AtlassianStatuspageConfigureDrawer";

enum Steps {
  SetToken = "set_token",
  PageSelection = "page_selection",
}

export const ConnectToAtlassianStatuspageModal = ({
  onClose: externalOnClose,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
}): React.ReactElement | null => {
  const [step, setStep] = useState(Steps.SetToken);

  const onClose = () => {
    setStep(Steps.SetToken);
    externalOnClose();
  };

  const onTokenSet = () => {
    setStep(Steps.PageSelection);
  };
  const onPagesSet = () => {
    externalOnClose();
  };

  if (step === Steps.SetToken) {
    return <SetToken onClose={onClose} onSuccess={onTokenSet} />;
  } else if (step === Steps.PageSelection) {
    return <PageSelection onClose={onClose} onSuccess={onPagesSet} />;
  }

  return null;
};

type TokenFormData = {
  api_token: string;
};
const SetToken = ({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}): React.ReactElement | null => {
  const { showKnowledgeBaseArticle } = usePylon();

  const formMethods = useForm<TokenFormData>();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, { api_token }: TokenFormData) =>
      await apiClient.integrationsAtlassianStatuspageSetAPIToken({
        setAPITokenRequestBody: {
          api_token,
        },
      }),
    {
      setError: formMethods.setError,
      onSuccess,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-statuspage-integration"
      title="Connect to Statuspage"
      onClose={onClose}
      genericError={genericError}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Connect"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700">
        Add your Statuspage API token here to manage your external Statuspage
        from within an incident channel. If you need help finding your API
        token, you can follow our instructions{" "}
        <Button
          theme={ButtonTheme.Link}
          analyticsTrackingId="connect-statuspage-integration-help"
          onClick={() => showKnowledgeBaseArticle("4643543889")}
        >
          here
        </Button>
        .
      </p>
      <hr className="my-3" />
      <InputV2
        formMethods={formMethods}
        name="api_token"
        label="API Token"
        type={InputType.Password}
        required="API token is required"
        autoComplete="none"
        placeholder={"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
      />
    </Form.Modal>
  );
};

const PageSelection = ({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}): React.ReactElement | null => {
  const { identity } = useIdentity();

  const {
    data: { pages },
    isLoading: loadingPages,
    error: errorPages,
  } = useAPI("integrationsAtlassianStatuspageListPages", undefined, {
    fallbackData: { pages: [] },
  });

  const {
    data: configData,
    isLoading: loadingConfig,
    error: errorConfig,
  } = useAPI("integrationsAtlassianStatuspageGetConfig", undefined);
  const config = configData?.config;

  if (loadingPages || loadingConfig || !identity) {
    return <LoadingModal onClose={onClose} />;
  }

  if (errorPages) {
    return (
      <ErrorModal
        description="We couldn't load your Statuspage pages."
        onClose={onClose}
      />
    );
  }

  if (errorConfig || !config) {
    return (
      <ErrorModal
        description="We couldn't load your Statuspage config."
        onClose={onClose}
      />
    );
  }

  return (
    <PageSelectionInner
      pages={pages}
      config={config}
      identity={identity}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
};

const PageSelectionInner = ({
  pages,
  config,
  identity,
  onClose,
  onSuccess,
}: {
  pages: AtlassianStatuspagePage[];
  config: AtlassianStatuspageConfig;
  identity: Identity;
  onClose: () => void;
  onSuccess: () => void;
}): React.ReactElement => {
  const { showKnowledgeBaseArticle } = usePylon();

  const allowedMultiplePages =
    identity.feature_gates.atlassian_statuspage_pages_count === undefined ||
    identity.feature_gates.atlassian_statuspage_pages_count > 1;

  const formMethods = useForm<AtlassianStatuspageConfig>({
    defaultValues: config?.included_pages ? config : { included_pages: [] },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsAtlassianStatuspageGetConfig",
    undefined,
    async (apiClient, data: AtlassianStatuspageConfig) =>
      await apiClient.integrationsAtlassianStatuspageUpdateConfig({
        updateConfigRequestBody: {
          config: data,
        },
      }),
    {
      setError: formMethods.setError,
      onSuccess,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-statuspage-integration"
      title="Connect to Statuspage"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Connect"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700">
        Add your Statuspage API token here to manage your external Statuspage
        from within an incident channel. If you need help finding your API
        token, you can follow our instructions{" "}
        <Button
          theme={ButtonTheme.Link}
          analyticsTrackingId={"connect-statuspage-integration-help"}
          onClick={() => showKnowledgeBaseArticle("4643543889")}
        >
          here
        </Button>
        .
      </p>
      <hr className="my-3" />
      <Form.Label htmlFor="pagerduty_key">API token</Form.Label>
      <Input
        id="api_token"
        disabled
        type={InputType.Password}
        autoComplete="none"
        placeholder={"xxxxxxxxxxxx"}
      />
      <PageSelectionForm
        pages={pages}
        allowedMultiplePages={allowedMultiplePages}
      />
    </Form.Modal>
  );
};
