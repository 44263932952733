import { tcx } from "src/utils/tailwind-classes";

export const LoadingBar = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => (
  <div
    className={tcx(
      "animate-pulse bg-surface-secondary rounded-[6px] h-[32px] w-full text-center p-2",
      className,
    )}
  >
    {children || <>&nbsp;</>}
  </div>
);
