import { usePylon } from "@bolasim/react-use-pylon";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import { ConfigureDrawerProps } from "@incident-shared/integrations";
import { OrgAwareNavLink } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  DynamicSingleSelect,
  IconEnum,
  Loader,
} from "@incident-ui";
import { DrawerBody } from "@incident-ui/Drawer/Drawer";
import React, { useState } from "react";
import { Form } from "src/components/@shared/forms";
import {
  PagerDutyConfig,
  ScopeNameEnum,
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { GenericConfigureDrawerContents } from "../IntegrationDrawer";

export const PagerDutyConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const { data: pagerDutyConfigData, isLoading: pagerDutyConfigLoading } =
    useAPI("integrationsPagerDutyGetConfig", undefined);
  const pagerDutyConfig = pagerDutyConfigData?.pager_duty_config;

  // When we first setup pager-duty we might not have any users at all - in which case
  // we want the user to wait before trying to do anything else.
  // We will keep trying to poll until we have some users.
  const [hasUsers, setHasUsers] = useState(false);
  useAPI(
    hasUsers ? null : "typeaheadsList",
    { typeaheadType: TypeaheadsListTypeaheadTypeEnum.PagerDutyUser },
    {
      fallbackData: { options: [], option_groups: [] },
      refreshInterval: 1000,
      onSuccess: ({ options }) => {
        if (options?.length > 0) {
          setHasUsers(true);
        }
      },
    },
  );

  return (
    <>
      {pagerDutyConfigLoading || !pagerDutyConfig ? (
        <Loader />
      ) : (
        <ConfigurePagerDutyForm
          pagerDutyConfig={pagerDutyConfig}
          hasUsers={!hasUsers}
          {...props}
        />
      )}
    </>
  );
};

const ConfigurePagerDutyForm = ({
  pagerDutyConfig,
  hasUsers,
  ...props
}: {
  pagerDutyConfig: PagerDutyConfig;
  hasUsers: boolean;
} & ConfigureDrawerProps): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  const { trigger: updateBotUserID } = useAPIMutation(
    "integrationsPagerDutyGetConfig",
    undefined,
    async (apiClient, { bot_user_id }) => {
      return await apiClient.integrationsPagerDutyUpdateConfig({
        updateConfigRequestBody: { bot_user_id },
      });
    },
  );

  const {
    setState,
    hasSaved,
    saving,
    state: botUserId,
  } = useOptimisticAutoSave<string | undefined>({
    initialState: pagerDutyConfig.bot_user_id,
    saveState: async (bot_user_id: string | undefined) => {
      await updateBotUserID({ bot_user_id });
    },
  });

  const apiClient = useClient();

  // If we do not actually have any users yet, don't even show the form - let the user know that we are polling them.
  if (hasUsers) {
    return (
      <DrawerBody className="overflow-y-auto">
        <ContentBox className="flex items-center">
          <Loader className="flex-none w-14 h-14" />
          <div className="text-sm flex-1">
            We&apos;re still syncing your users from PagerDuty. This should only
            take a few minutes. Please wait so we can finish setting up your
            integration.
          </div>
        </ContentBox>
      </DrawerBody>
    );
  }

  return (
    <GenericConfigureDrawerContents {...props}>
      {pagerDutyConfig.need_bot_user_id ? (
        <>
          <ContentBox className="p-4">
            <Form.Label
              htmlFor="bot_user_id"
              className="flex items-center gap-2"
            >
              PagerDuty bot user
              <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
            </Form.Label>
            <Form.Helptext>
              <span className="mb-1">
                PagerDuty requires a user account to create incidents directly.
                This should be a user that you won&apos;t escalate to because
                PagerDuty will auto-acknowledge any incidents it thinks came
                from that user. For more information, see our{" "}
                <Button
                  onClick={() => showArticle("2470059178")}
                  analyticsTrackingId="configure-pagerduty-help"
                  theme={ButtonTheme.Link}
                  className="underline"
                >
                  help center
                </Button>
                .
              </span>
            </Form.Helptext>
            <DynamicSingleSelect
              value={botUserId || ""}
              onChange={(value) => setState(value || undefined)}
              id="bot_user_id"
              isClearable={false}
              placeholder="Select user..."
              isDisabled={!canEditSettings}
              loadOptions={getTypeaheadOptions(
                apiClient,
                TypeaheadsListTypeaheadTypeEnum.PagerDutyUser,
              )}
              hydrateOptions={hydrateInitialSelectOptions(
                apiClient,
                TypeaheadsListTypeaheadTypeEnum.PagerDutyUser,
              )}
            />
          </ContentBox>
        </>
      ) : null}
      <Callout
        theme={CalloutTheme.Info}
        iconOverride={IconEnum.New}
        title="Auto-create incidents in Alerts"
        subtitle={
          <>
            If you want to automatically create incidents from PagerDuty, you
            can do so from the{" "}
            <OrgAwareNavLink
              className="underline"
              to="/alerts/configuration"
              target="_blank"
            >
              alert sources
            </OrgAwareNavLink>{" "}
            tab.
          </>
        }
      />
    </GenericConfigureDrawerContents>
  );
};
