import {
  Incident,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  PostIncidentFlow,
  PostIncidentTask,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  Icon,
  IconEnum,
  IconSize,
  LoadingBar,
  ProgressBar,
  StackedList,
  Tooltip,
} from "@incident-ui";
import React, { useEffect, useState } from "react";
import { PostIncidentAssigneeSection } from "src/components/post-incident/post-incident-flow/PostIncidentAssigneeSection";
import {
  getTaskStatus,
  TASK_STATUS_CONFIG,
} from "src/components/post-incident/post-incident-flow/PostIncidentList";
import { TaskDueDateBadge } from "src/components/post-incident/post-incident-flow/TaskDueDateBadge";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useAPI, useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";

import { PostIncidentDivider } from "../postincident/PostIncidentTab";
import { Category, isCategory } from "../statuses/status-utils";
import { useAllStatuses } from "../useIncidentCrudResources";
import { EmptyPostIncidentStatusDetails } from "./EmptyPostIncidentStatusDetails";
import {
  PostIncidentActionModals,
  PostIncidentTaskActionItem,
} from "./PostIncidentTaskActionItem";
import {
  manualCompletionIsDisabledExplanation,
  taskCanBeManuallyCompleted,
} from "./taskCanBeManuallyCompleted";
import { usePostIncidentFlow } from "./usePostIncidentFlow";
import { usePostIncidentTaskMutations } from "./usePostIncidentTaskMutations";
import { findCurrentTask } from "./utils";

export const PostIncidentSection = ({ incident }: { incident: Incident }) => {
  const { numCompleted, numTotal } = usePostIncidentFlow(incident.id);

  const [isExpanded, setIsExpanded] = useState(false);

  const {
    data: { incident_tasks: incidentTasks },
    isLoading: incidentTasksLoading,
  } = useAPI(
    incident ? "postIncidentFlowListTasks" : null,
    { incidentId: incident?.id ?? "" },
    { fallbackData: { incident_tasks: [] } },
  );

  const { allStatuses, allStatusesLoading } = useAllStatuses();

  const { data: flowData } = useAPI(
    incident?.post_incident_flow_id ? "postIncidentFlowShow" : null,
    {
      id: incident?.post_incident_flow_id ?? "",
    },
  );

  const loading =
    incident == null || incidentTasksLoading || !flowData || allStatusesLoading;

  const flowStatuses = flowData?.post_incident_flow.incident_statuses ?? [];
  const statuses = [
    ...flowStatuses,
    ...allStatuses.filter(isCategory(Category.Closed)),
  ];

  if (
    ![
      IncidentStatusCategoryEnum.PostIncident,
      IncidentStatusCategoryEnum.Closed,
    ].includes(incident.incident_status.category)
  ) {
    return null;
  }

  if (numTotal === 0) {
    return null;
  }

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        <div className="text-base-bold">Post-incident flow</div>
        <ContentBox
          className={tcx(
            "overflow-hidden",
            isExpanded ? "" : "border-none shadow-none",
          )}
        >
          <Button
            theme={
              isExpanded ? ButtonTheme.UnstyledPill : ButtonTheme.Secondary
            }
            analyticsTrackingId="incident-overview-pinc-flow"
            className={tcx(
              "px-4 py-3 flex items-center gap-2 w-full",
              isExpanded ? "bg-surface-secondary rounded-b-none" : "",
            )}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Icon id={IconEnum.Clipboard} size={IconSize.Small} />
            <ProgressBar
              numCompleted={numCompleted}
              numTotal={numTotal}
              containerClassName="h-1"
            />
            <div className="text-xs-med">
              {numCompleted} of {numTotal} tasks done
            </div>
            <Icon
              id={isExpanded ? IconEnum.ChevronDown : IconEnum.ChevronRight}
            />
          </Button>
          {flowData && isExpanded ? (
            <div className="rounded-b-2 p-4">
              <PostIncidentFlowLists
                incident={incident}
                incidentTasks={incidentTasks}
                statuses={statuses}
                flow={flowData.post_incident_flow}
              />
            </div>
          ) : undefined}
        </ContentBox>
      </div>
      <PostIncidentActionModals />
      <PostIncidentDivider />
    </>
  );
};

const PostIncidentFlowLists = ({
  incident,
  incidentTasks,
  flow,
  statuses,
}: {
  incident: Incident;
  incidentTasks: PostIncidentTask[];
  flow: PostIncidentFlow;
  statuses: IncidentStatus[];
}): React.ReactElement | null => {
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const refreshIncidentActivity = useRevalidate([
    "incidentTimelineListActivityLog",
  ]);
  const refetchIncident = useAPIRefetch("incidentsShow", {
    id: incident.id,
  });
  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });

  const refetch = async () => {
    refreshIncidentList();
    refreshIncidentActivity();
    refetchIncident();
    refetchPostIncidentTasks();
  };

  const currentTask = findCurrentTask({
    tasks: incidentTasks,
    status: incident.incident_status,
  });

  const [currentlyExpandedTask, setCurrentlyExpandedTask] =
    useState(currentTask);

  useEffect(() => {
    if (currentTask) {
      setCurrentlyExpandedTask(currentTask);
    }
  }, [currentTask, setCurrentlyExpandedTask]);

  return (
    <div className="flex flex-col gap-6">
      {flow.incident_statuses.map((status) => {
        const tasks = incidentTasks.filter(
          (t) => t.config.incident_status_id === status.id,
        );
        return (
          <div className="flex flex-col gap-3" key={status.id}>
            <div className="flex gap-2 text-sm-bold">{status.name}</div>
            <StackedList>
              {tasks.map((task, i) => (
                <PostIncidentItemTask
                  key={`${task.id}${currentTask?.id}`} // Trigger a re-mount when something becomes the current task, so it expands/collapses
                  task={task}
                  refetchIncidents={refetch}
                  statuses={statuses}
                  isExpanded={task.id === currentlyExpandedTask?.id}
                  toggleIsExpanded={() =>
                    setCurrentlyExpandedTask(
                      task.id === currentlyExpandedTask?.id ? undefined : task,
                    )
                  }
                  incident={incident}
                  idx={i}
                />
              ))}
            </StackedList>
            {tasks.length === 0 && (
              <EmptyPostIncidentStatusDetails
                incident={incident}
                status={status}
                statuses={statuses}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const PostIncidentItemTask = ({
  task,
  refetchIncidents,
  statuses,
  isExpanded,
  toggleIsExpanded,
  incident,
  idx,
}: {
  task: PostIncidentTask;
  refetchIncidents: () => Promise<void>;
  statuses: IncidentStatus[];
  isExpanded: boolean;
  toggleIsExpanded: () => void;
  incident: Incident;
  idx: number;
}) => {
  const taskStatus = getTaskStatus(task);
  const taskConfig = TASK_STATUS_CONFIG[taskStatus];

  return (
    <div>
      <div
        className={tcx(
          "flex items-center justify-between w-full px-4 truncate h-[44px]",
          isExpanded ? "bg-surface-secondary" : "",
          idx === 0 && "rounded-t-2",
        )}
      >
        {/* Key details: task status, name and due date */}
        <Button
          theme={ButtonTheme.Unstyled}
          onClick={() => toggleIsExpanded()}
          title=""
          className="grow truncate h-full"
          analyticsTrackingId={null}
        >
          <div className="flex items-center gap-3 w-full truncate text-xs-med">
            <Tooltip content={taskConfig.label}>
              <Icon
                id={taskConfig.compactIcon}
                size={IconSize.Small}
                className={taskConfig.compactIconClassName}
              />
            </Tooltip>
            {task.config.title}
            <TaskDueDateBadge task={task} statuses={statuses} />
          </div>
        </Button>
        <div className="flex items-center gap-2">
          <PostIncidentAssigneeSection
            task={task}
            refetchIncidents={refetchIncidents}
            BadgeSize={BadgeSize.Small}
          />
          <Button
            analyticsTrackingId={null}
            icon={isExpanded ? IconEnum.Collapse : IconEnum.Expand}
            theme={ButtonTheme.Naked}
            onClick={() => toggleIsExpanded()}
            title={isExpanded ? "Collapse" : "Expand"}
            size={BadgeSize.Small}
          />
        </div>
      </div>
      {isExpanded && (
        <div className="p-4 pl-11 flex flex-col gap-4 items-start">
          <div className="text-sm-normal">
            <TemplatedTextDisplay
              value={task.config.description}
              style={TemplatedTextDisplayStyle.Naked}
            />
          </div>
          <div className="flex gap-2">
            <PostIncidentTaskButtons
              task={task}
              refetchIncidents={refetchIncidents}
              incident={incident}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const PostIncidentTaskButtons = ({
  task,
  refetchIncidents,
  incident,
}: {
  task: PostIncidentTask;
  refetchIncidents: () => Promise<void>;
  incident: Incident;
}) => {
  const {
    rejectTask,
    completeTask,
    unresolveTask,
    unresolvingTask,
    completingTask,
    rejectingTask,
  } = usePostIncidentTaskMutations({
    task,
    refetchIncidents,
  });

  const { postmortemNameFormatted } = usePostmortemName(incident);

  switch (getTaskStatus(task)) {
    case "completed":
      return (
        <>
          {task.completer && (
            <Badge
              size={BadgeSize.Small}
              theme={BadgeTheme.Success}
              icon={IconEnum.Tick}
              label={
                task.completer.user
                  ? `Completed by ${task.completer.user?.name}`
                  : "Completed"
              }
            />
          )}
          <Button
            theme={ButtonTheme.Secondary}
            size={BadgeSize.Small}
            icon={IconEnum.Undo}
            analyticsTrackingId="post-incident-mark-as-incomplete"
            onClick={() => unresolveTask({})}
            loading={unresolvingTask}
          >
            Mark as incomplete
          </Button>
        </>
      );
    case "outstanding":
      return (
        <>
          <PostIncidentTaskActionItem
            mode="button"
            incidentTask={task}
            onTaskComplete={() => {
              completeTask({});
              refetchIncidents();
            }}
          />
          {taskCanBeManuallyCompleted(task.config) && (
            <GatedButton
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
              icon={IconEnum.TickCircle}
              analyticsTrackingId="post-incident-manually-complete"
              onClick={() => completeTask({})}
              loading={completingTask}
              disabled={
                manualCompletionIsDisabledExplanation(
                  task,
                  incident,
                  postmortemNameFormatted,
                ) != null
              }
              disabledTooltipContent={manualCompletionIsDisabledExplanation(
                task,
                incident,
                postmortemNameFormatted,
              )}
            >
              Complete
            </GatedButton>
          )}
          <GatedButton
            theme={ButtonTheme.Secondary}
            size={BadgeSize.Small}
            icon={IconEnum.CloseCircle}
            disabled={task.config.unskippable}
            disabledTooltipContent="This task is required"
            analyticsTrackingId="post-incident-skip"
            onClick={() => rejectTask({})}
            loading={rejectingTask}
          >
            Skip
          </GatedButton>
        </>
      );
    case "skipped":
      return (
        <>
          {task.rejecter && (
            <Badge
              size={BadgeSize.Small}
              theme={BadgeTheme.Error}
              icon={IconEnum.CloseCircle}
              label={
                task.rejecter.user
                  ? `Skipped by ${task.rejecter.user?.name}`
                  : "Skipped"
              }
            />
          )}
          <Button
            theme={ButtonTheme.Secondary}
            size={BadgeSize.Small}
            icon={IconEnum.Undo}
            analyticsTrackingId="post-incident-mark-as-incomplete"
            onClick={() => unresolveTask({})}
            loading={unresolvingTask}
          >
            Undo skip
          </Button>
        </>
      );
    default:
      return <></>;
  }
};
