import type { SVGProps } from "react";
import * as React from "react";
const SvgIncidentTypeAi = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#161618"
      fillRule="evenodd"
      d="M7.25 2.5h-3A1.75 1.75 0 0 0 2.5 4.25v3C2.5 8.216 3.284 9 4.25 9h3A1.75 1.75 0 0 0 9 7.25v-3A1.75 1.75 0 0 0 7.25 2.5Zm0 8.5h-3a1.75 1.75 0 0 0-1.75 1.75v3c0 .966.784 1.75 1.75 1.75h3A1.75 1.75 0 0 0 9 15.75v-3A1.75 1.75 0 0 0 7.25 11Zm5.5-8.5h3c.966 0 1.75.784 1.75 1.75v3A1.75 1.75 0 0 1 15.75 9h-3A1.75 1.75 0 0 1 11 7.25v-3c0-.966.784-1.75 1.75-1.75Zm2.851 10.378 1.54.773a.652.652 0 0 1 0 1.168l-1.54.773-.77 1.547a.65.65 0 0 1-1.162 0l-.77-1.546-1.54-.774a.652.652 0 0 1 0-1.168l1.54-.773.77-1.546c.22-.443.942-.443 1.162 0l.77 1.546Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIncidentTypeAi;
