import { Schedule, ScopeNameEnum } from "@incident-io/api";
import { getEngineTypeaheadOptions } from "@incident-shared/engine";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import {
  Callout,
  CalloutTheme,
  Link,
  ModalFooter,
  TabModalPane,
  Txt,
} from "@incident-ui";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

type ScheduleSyncToSlackData = {
  id: string; // schedule id

  mirrored_slack_user_group_id?: string;
  add_bot_to_mirrored_user_group?: string;

  name?: string;
  handle?: string;
  description?: string;
};

type SlackPermissionError = "require_owners" | "require_admins_or_owners";

export const SyncToSlackModal = ({
  onClose,
  schedule,
}: {
  onClose: () => void;
  schedule: Schedule;
}) => {
  const apiClient = useClient();
  const formMethods = useForm<ScheduleSyncToSlackData>({
    defaultValues: {
      id: schedule.id,
      mirrored_slack_user_group_id: schedule.mirrored_slack_user_group_id,
      name: schedule.name,
      handle: _.kebabCase(schedule.name),
      description: `A user group for on-call users of ${schedule.name}. Created by incident.io`,
      add_bot_to_mirrored_user_group: schedule.add_bot_to_mirrored_user_group
        ? "true"
        : "false",
    },
  });

  const { hasScope } = useIdentity();

  const [slackPermissionError, setSlackPermissionError] = useState<
    SlackPermissionError | undefined
  >(undefined);
  const [currentTab, setCurrentTab] = useState<"existing" | "new">("existing");

  // Update the handle when the name changes
  // E.g. name='Project Team A' -> handle='project-team-a'
  const [name, setName] = useState("");
  const formName = formMethods.watch("name");
  if (formName && formName !== name) {
    setName(formName);
    formMethods.setValue("handle", _.kebabCase(formName));
  }

  const addBotOptions = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "schedulesShow",
    { id: schedule.id },
    async (apiClient, data) => {
      formMethods.clearErrors();

      if (currentTab === "existing") {
        await apiClient.schedulesSetMirroredSlackUserGroup({
          id: data.id,
          setMirroredSlackUserGroupRequestBody: {
            mirrored_slack_user_group_id: data.mirrored_slack_user_group_id,
            // We have to do this because react hook form doesn't handle
            // boolean values correctly
            add_bot_to_mirrored_user_group:
              data.add_bot_to_mirrored_user_group === "true" ? true : false,
          },
        });
      }

      if (currentTab === "new") {
        await apiClient.schedulesSetMirroredSlackUserGroup({
          id: data.id,
          setMirroredSlackUserGroupRequestBody: {
            new_slack_user_group: {
              name: data.name,
              handle: data.handle,
              description: data.description,
            },
            // We have to do this because react hook form doesn't handle
            // boolean values correctly
            add_bot_to_mirrored_user_group:
              data.add_bot_to_mirrored_user_group === "true" ? true : false,
          },
        });
      }

      return;
    },
    {
      onSuccess: onClose,
      setError: (_, error) => {
        if (
          error.message?.includes(
            "Your Slack Workspace settings only allow Workspace Admins or Workspace Owners",
          )
        ) {
          setSlackPermissionError("require_admins_or_owners");
        } else if (
          error.message?.includes(
            "Your Slack Workspace settings only allow Workspace Owners",
          )
        ) {
          setSlackPermissionError("require_owners");
        } else {
          if (currentTab === "existing") {
            formMethods.setError("mirrored_slack_user_group_id", {
              message: error.message,
            });
          } else {
            formMethods.setError("root", {
              message: error.message,
            });
          }
        }
      },
    },
  );

  return (
    <Form.TabModal
      formMethods={formMethods}
      onSubmit={onSubmit}
      title="Sync schedule to Slack user group"
      analyticsTrackingId="schedule-sync-schedule-to-slack-user-group-modal"
      onClose={onClose}
      tabs={[
        {
          id: "existing",
          label: "Link to existing",
        },
        {
          id: "new",
          label: "Create new",
        },
      ]}
      footer={
        <div>
          <ModalFooter
            requiredScope={ScopeNameEnum.SchedulesUpdate}
            disabled={!hasScope(ScopeNameEnum.SchedulesUpdate)}
            disabledTooltipContent="You do not have permission to update schedules"
            onClose={onClose}
            confirmButtonText={currentTab === "existing" ? "Save" : "Create"}
            saving={saving}
            confirmButtonType="submit"
          />
        </div>
      }
      onTabChange={(tabId) => {
        setCurrentTab(tabId as "existing" | "new");
      }}
    >
      {/* This component doesn't do anything, it just fires off a sync */}
      <SyncSlackUserGroupCatalogType />

      {slackPermissionError === "require_admins_or_owners" ? (
        <Callout theme={CalloutTheme.Danger}>
          <div className="flex flex-col">
            <Txt bold>Insufficient Slack permissions</Txt>
            <Txt>
              Your Slack Workspace settings only allow Workspace Admins and
              Owners to manage user groups.
            </Txt>
            <br />
            <Txt>
              To allow Slack user groups to sync with schedules, an Owner or
              Admin will need to configure{" "}
              <strong>Privileged Slack access</strong>{" "}
              <Link
                href="/settings/security"
                analyticsTrackingId={null}
                openInNewTab
              >
                here
              </Link>
              .
            </Txt>
            <br />
            <Txt>
              Alternatively, please update your Slack workspace settings to
              allow everyone to manage groups, as outlined{" "}
              <Link
                href="https://help.incident.io/"
                analyticsTrackingId={null}
                openInNewTab
              >
                here
              </Link>
              .
            </Txt>
          </div>
        </Callout>
      ) : slackPermissionError === "require_owners" ? (
        <Callout theme={CalloutTheme.Danger}>
          <div className="flex flex-col">
            <Txt bold>Insufficient Slack permissions</Txt>
            <Txt>
              Your Slack Workspace settings only allow Workspace Owners to
              manage user groups.
            </Txt>
            <br />
            <Txt>
              To allow Slack user groups to sync with schedules, an Owner will
              need to configure <strong>Privileged Slack access</strong>{" "}
              <Link
                href="/settings/security"
                analyticsTrackingId={null}
                openInNewTab
              >
                here
              </Link>
              .
            </Txt>
            <br />
            <Txt>
              Alternatively, please update your Slack workspace settings to
              allow everyone to manage groups, as outlined{" "}
              <Link
                href="https://help.incident.io/"
                analyticsTrackingId={null}
                openInNewTab
              >
                here
              </Link>
              .
            </Txt>
          </div>
        </Callout>
      ) : null}

      <TabModalPane tabId="existing" className="space-y-2">
        <Txt>
          We&apos;ll keep the users in this Slack group synchronised with
          whoever is currently on call in this schedule.
        </Txt>

        <DynamicSingleSelectV2
          formMethods={formMethods}
          name="mirrored_slack_user_group_id"
          label="Select a Slack user group"
          required={false}
          loadOptions={getEngineTypeaheadOptions(
            apiClient,
            'CatalogEntry["SlackUserGroup"]',
          )}
          hydrateOptions={getEngineTypeaheadOptions(
            apiClient,
            'CatalogEntry["SlackUserGroup"]',
          )}
        />
        <StaticSingleSelectV2
          formMethods={formMethods}
          name={"add_bot_to_mirrored_user_group"}
          label={"Add incident.io to the user group?"}
          helptext={
            "This will prevent the group from being disabled if no one is on call"
          }
          options={addBotOptions}
        />
      </TabModalPane>

      <TabModalPane tabId="new" className="space-y-2">
        <InputV2
          formMethods={formMethods}
          name="name"
          label="Name"
          helptext={"The name of the user group, e.g. On-call engineers"}
          required
          placeholder={`On call ${schedule.name}`}
        />
        <InputV2
          formMethods={formMethods}
          name="handle"
          label="Handle"
          helptext={
            "The handle is used to mention the user group in Slack, e.g. @on-call-engineers"
          }
          required
          placeholder={`on-call-${_.kebabCase(schedule.name)}`}
        />
        <StaticSingleSelectV2
          formMethods={formMethods}
          name={"add_bot_to_mirrored_user_group"}
          label={"Add incident.io to the user group?"}
          helptext={
            "This will prevent the group from being disabled if no one is on call"
          }
          options={addBotOptions}
        />
        <TextareaV2
          formMethods={formMethods}
          name={"description"}
          label={"Description"}
          placeholder={`A user group for on-call users of ${schedule.name}. Created by incident.io`}
        />
      </TabModalPane>
    </Form.TabModal>
  );
};

// This is a dummy component, it never renders anything. But it's used
// to fire off a sync of the SlackUserGroup catalog type
const SyncSlackUserGroupCatalogType = () => {
  const apiClient = useClient();

  const {
    data: { catalog_types: catalogTypes },
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const slackUserGroupCatalogType = catalogTypes.find(
    (type) => type.type_name === "SlackUserGroup",
  );

  useEffect(() => {
    if (!slackUserGroupCatalogType) {
      return;
    }

    const resyncType = async () => {
      await apiClient.catalogSyncType({ id: slackUserGroupCatalogType.id });
    };

    resyncType();
  }, [apiClient, slackUserGroupCatalogType]);

  return <></>;
};
