import { Route, Routes } from "react-router";

import { BacktestsListPage } from "./BacktestsListPage";
import { BacktestsShowPage } from "./BacktestsShowPage";

export const BacktestsRoute = () => {
  return (
    <Routes>
      <Route path="/:backtestId" element={<BacktestsShowPage />} />
      <Route path="*" element={<BacktestsListPage />} />
    </Routes>
  );
};
