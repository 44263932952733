/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CallProviders
 */
export interface CallProviders {
    /**
     * The available call providers
     * @type {Array<string>}
     * @memberof CallProviders
     */
    providers?: Array<CallProvidersProvidersEnum>;
}

/**
* @export
* @enum {string}
*/
export enum CallProvidersProvidersEnum {
    Zoom = 'zoom',
    MicrosoftTeamsOnlineMeetings = 'microsoft_teams_online_meetings',
    GoogleMeet = 'google_meet'
}

export function CallProvidersFromJSON(json: any): CallProviders {
    return CallProvidersFromJSONTyped(json, false);
}

export function CallProvidersFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallProviders {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providers': !exists(json, 'providers') ? undefined : json['providers'],
    };
}

export function CallProvidersToJSON(value?: CallProviders | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providers': value.providers,
    };
}

