/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathNodePayload,
    EscalationPathNodePayloadFromJSON,
    EscalationPathNodePayloadFromJSONTyped,
    EscalationPathNodePayloadToJSON,
} from './EscalationPathNodePayload';
import {
    WeekdayIntervalConfig,
    WeekdayIntervalConfigFromJSON,
    WeekdayIntervalConfigFromJSONTyped,
    WeekdayIntervalConfigToJSON,
} from './WeekdayIntervalConfig';

/**
 * 
 * @export
 * @interface EscalationPathConfigPayload
 */
export interface EscalationPathConfigPayload {
    /**
     * The nodes that form the levels and branches of this escalation path.
     * @type {Array<EscalationPathNodePayload>}
     * @memberof EscalationPathConfigPayload
     */
    path: Array<EscalationPathNodePayload>;
    /**
     * The working hours for this escalation path.
     * @type {Array<WeekdayIntervalConfig>}
     * @memberof EscalationPathConfigPayload
     */
    working_hours: Array<WeekdayIntervalConfig>;
}

export function EscalationPathConfigPayloadFromJSON(json: any): EscalationPathConfigPayload {
    return EscalationPathConfigPayloadFromJSONTyped(json, false);
}

export function EscalationPathConfigPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathConfigPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path': ((json['path'] as Array<any>).map(EscalationPathNodePayloadFromJSON)),
        'working_hours': ((json['working_hours'] as Array<any>).map(WeekdayIntervalConfigFromJSON)),
    };
}

export function EscalationPathConfigPayloadToJSON(value?: EscalationPathConfigPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path': ((value.path as Array<any>).map(EscalationPathNodePayloadToJSON)),
        'working_hours': ((value.working_hours as Array<any>).map(WeekdayIntervalConfigToJSON)),
    };
}

