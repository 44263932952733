import { AISuggestionsNotificationsBadge } from "@incident-shared/aisuggestions/AISuggestionNotification";
import { PolicyViolationNotificationBadge } from "@incident-shared/policy/PolicyViolationNotification";
import * as Tabs from "@radix-ui/react-tabs";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./TabSection.module.scss";

export type Tab = {
  label: string | React.ReactFragment;
  id: string;
  className?: string;
  hidden?: boolean;
  violations?: number;
  aiSuggestions?: number;
  accessory?: React.ReactNode;
};

export type TabSectionProps = {
  /**
   *  The TabPane components, passed in as children
   */
  children?: React.ReactNode;
  /**
   * An array of tabs with a `label` (human readable) and an `id` (unique)
   */
  tabs: Tab[];
  /**
   * The ID of the tab you want to open by default. If not provided, defaults to the first tab.
   */
  defaultTab?: string;
  /**
   * Whether to show a blue underline on the active tab.
   */
  withIndicator?: boolean;
  /**
   * Optional class name(s) for the individual tabs
   */
  tabClassName?: string;
  /**
   * Optional class name(s) for the tab bar
   */
  tabBarClassName?: string;
  /**
   * Optional ref for the tab bar, if you need to portal it to render somewhere else
   */
  tabBarRef?: React.RefObject<HTMLDivElement>;
  /**
   * Optional callback to execute when changing tab
   */
  onTabChange?: (tabId: string) => void;
  /**
   * An accessory to render at the end of the tab list
   */
  tabBarAccessory?: React.ReactNode;
  /**
   * Class name for the root element
   */
  rootClassName?: string;
  /**
   * The currently selected tab
   */
  value?: string;
  /**
   * If there's one tab, what to show instead
   */
  replaceForSingleTab?: React.ReactNode;
  indicatorClassName?: string;
};

/**
 * A set of layered sections of content that are displayed one at a time.
 * It takes an array of tabs defined as objects:
 *
 * ```js
 *   const tabs = [
 *     {
 *       id: "tab1",
 *       label: "First tab",
 *     },
 *     {
 *       id: "tab2",
 *       label: "Second tab",
 *     },
 *   ]
 * ```
 *
 * Pass in `TabPane` components as children. Each tab must have a corresponding `TabPane`. Pass in the id of the tab as the `tabId` prop in `TabPane` (see the code for this story).
 *
 * You can style this content however you want - it's completely unstyled except for a `padding-top`.
 *
 * You can choose which tab displays first - e.g. to show a different one by default depending on the state of an incident.
 *
 * If you want to totally control the tabs, pass the current tab in as the `value` prop.
 *
 */
export function TabSection({
  children,
  tabs,
  defaultTab,
  value,
  withIndicator = false,
  tabClassName,
  tabBarClassName,
  tabBarAccessory,
  rootClassName,
  onTabChange,
  tabBarRef,
  replaceForSingleTab,
}: TabSectionProps): React.ReactElement {
  // If we ever receive a tab value that is invalid, we should default to the
  // first of the tabs.
  let defaultValue = defaultTab != null ? defaultTab : tabs[0].id;
  if (tabs.map(({ id }) => id).indexOf(defaultValue) < 0) {
    defaultValue = tabs[0].id;
    if (onTabChange) {
      onTabChange(defaultValue);
    }
  }

  const valueProp = value ? { value } : {};

  return (
    <Tabs.Root
      className={rootClassName}
      onValueChange={onTabChange}
      defaultValue={defaultValue}
      {...valueProp}
    >
      <TabBarWithRef
        tabs={tabs}
        tabBarRef={tabBarRef}
        withIndicator={withIndicator}
        tabClassName={tabClassName}
        tabBarClassName={tabBarClassName}
        tabBarAccessory={tabBarAccessory}
        replaceForSingleTab={replaceForSingleTab}
      />
      {children}
    </Tabs.Root>
  );
}

const TabBarWithRef = ({
  tabBarRef,
  replaceForSingleTab,
  ...props
}: TabSectionProps & { tabBarRef?: React.RefObject<HTMLDivElement> }) => {
  const [refIsReady, setIsReady] = useState(false);

  // We need to force this to re-render when the tabBarRef gets set
  useEffect(() => {
    if (!tabBarRef) return;

    if (tabBarRef.current) {
      setIsReady(true);
    }
  }, [tabBarRef, setIsReady]);

  if (tabBarRef && (!refIsReady || !tabBarRef.current)) {
    // We're waiting for the ref to be ready
    return <></>;
  }

  if (
    replaceForSingleTab &&
    props.tabs.filter((tab) => !tab.hidden).length < 2
  ) {
    if (tabBarRef) {
      return createPortal(replaceForSingleTab, tabBarRef.current as Element);
    }
    return <>{replaceForSingleTab}</>;
  }

  if (tabBarRef) {
    return createPortal(<TabBar {...props} />, tabBarRef.current as Element);
  }

  return <TabBar {...props} />;
};

export const TabBar = ({
  tabs,
  withIndicator = false,
  indicatorClassName,
  tabClassName,
  tabBarClassName,
  tabBarAccessory,
}: TabSectionProps): React.ReactElement => {
  return (
    <Tabs.List
      className={tcx("flex-center-y flex-wrap gap-2 mb-2", tabBarClassName)}
    >
      {tabs.map((t) => {
        if (t.hidden) {
          return null;
        }

        const showPolicyViolations = t.violations && t.violations > 0;
        const showAccessory = !showPolicyViolations && t.accessory;
        const showAISuggestions =
          !showPolicyViolations &&
          !showAccessory &&
          t.aiSuggestions &&
          t.aiSuggestions > 0;

        return (
          <div
            className={tcx(
              "flex !mr-0 relative",
              showPolicyViolations
                ? styles.violationsTabWrapper
                : styles.tabWrapper,
            )}
            key={t.id}
          >
            <Tabs.Trigger
              className={tcx(
                styles.tab,
                "text-content-tertiary font-medium text-sm !px-2 transition flex gap-2",
                {
                  [styles.withIndicator]: withIndicator,
                },
                withIndicator && indicatorClassName ? indicatorClassName : "",
                showAISuggestions
                  ? styles.rainbowGradient
                  : "hover:text-content-primary",
                t.className,
                tabClassName,
              )}
              value={t.id}
            >
              <p> {t.label} </p>
              {showAccessory ? t.accessory : null}
            </Tabs.Trigger>
            {showPolicyViolations ? <PolicyViolationNotificationBadge /> : null}
            {showAISuggestions ? <AISuggestionsNotificationsBadge /> : null}
          </div>
        );
      })}
      {tabBarAccessory}
    </Tabs.List>
  );
};

export function TabPane({
  children,
  tabId,
  className,
}: {
  children: React.ReactNode;
  tabId: string;
  className?: string;
}): React.ReactElement {
  return (
    <Tabs.Content value={tabId} className={tcx(className)}>
      {children}
    </Tabs.Content>
  );
}
