/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageImportResult,
    StatusPageImportResultFromJSON,
    StatusPageImportResultFromJSONTyped,
    StatusPageImportResultToJSON,
} from './StatusPageImportResult';

/**
 * 
 * @export
 * @interface StaffImportStatusPageSubscribersResponseBody
 */
export interface StaffImportStatusPageSubscribersResponseBody {
    /**
     * Results of the import
     * @type {Array<StatusPageImportResult>}
     * @memberof StaffImportStatusPageSubscribersResponseBody
     */
    results: Array<StatusPageImportResult>;
}

export function StaffImportStatusPageSubscribersResponseBodyFromJSON(json: any): StaffImportStatusPageSubscribersResponseBody {
    return StaffImportStatusPageSubscribersResponseBodyFromJSONTyped(json, false);
}

export function StaffImportStatusPageSubscribersResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffImportStatusPageSubscribersResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(StatusPageImportResultFromJSON)),
    };
}

export function StaffImportStatusPageSubscribersResponseBodyToJSON(value?: StaffImportStatusPageSubscribersResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(StatusPageImportResultToJSON)),
    };
}

