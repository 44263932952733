/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EligibilityInformation,
    EligibilityInformationFromJSON,
    EligibilityInformationFromJSONTyped,
    EligibilityInformationToJSON,
} from './EligibilityInformation';
import {
    EngineScope,
    EngineScopeFromJSON,
    EngineScopeFromJSONTyped,
    EngineScopeToJSON,
} from './EngineScope';

/**
 * 
 * @export
 * @interface AlertSource
 */
export interface AlertSource {
    /**
     * Whether this alert source is can be auto-acknowledged in the source system
     * @type {boolean}
     * @memberof AlertSource
     */
    can_acknowledge_in_source?: boolean;
    /**
     * Whether this alert source is a configurable, native or integration based source
     * @type {string}
     * @memberof AlertSource
     */
    classification: AlertSourceClassificationEnum;
    /**
     * Explanation of what this source is
     * @type {string}
     * @memberof AlertSource
     */
    docstring: string;
    /**
     * 
     * @type {EligibilityInformation}
     * @memberof AlertSource
     */
    eligibility?: EligibilityInformation;
    /**
     * JSON encoded exemplar payload
     * @type {string}
     * @memberof AlertSource
     */
    example_payload: string;
    /**
     * Whether this alert source is exclusively resolved externally
     * @type {boolean}
     * @memberof AlertSource
     */
    externally_resolved?: boolean;
    /**
     * Maximum number of instances of this alert source that can be created
     * @type {number}
     * @memberof AlertSource
     */
    max_instances: number;
    /**
     * Human readable name of this source
     * @type {string}
     * @memberof AlertSource
     */
    name: string;
    /**
     * If populated, the integrations required for this type
     * @type {Array<string>}
     * @memberof AlertSource
     */
    required_integrations: Array<string>;
    /**
     * 
     * @type {EngineScope}
     * @memberof AlertSource
     */
    scope: EngineScope;
    /**
     * 
     * @type {string}
     * @memberof AlertSource
     */
    source_type: AlertSourceSourceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AlertSourceClassificationEnum {
    Configurable = 'configurable',
    Integration = 'integration',
    Native = 'native'
}/**
* @export
* @enum {string}
*/
export enum AlertSourceSourceTypeEnum {
    Alertmanager = 'alertmanager',
    AppOptics = 'app_optics',
    AzureMonitor = 'azure_monitor',
    Bugsnag = 'bugsnag',
    Checkly = 'checkly',
    Chronosphere = 'chronosphere',
    Cloudwatch = 'cloudwatch',
    Cloudflare = 'cloudflare',
    Cronitor = 'cronitor',
    CrowdstrikeFalcon = 'crowdstrike_falcon',
    Datadog = 'datadog',
    Dynatrace = 'dynatrace',
    Elasticsearch = 'elasticsearch',
    Email = 'email',
    Expel = 'expel',
    GithubIssue = 'github_issue',
    GoogleCloud = 'google_cloud',
    Grafana = 'grafana',
    Http = 'http',
    Honeycomb = 'honeycomb',
    IncomingCalls = 'incoming_calls',
    Jira = 'jira',
    MonteCarlo = 'monte_carlo',
    Nagios = 'nagios',
    NewRelic = 'new_relic',
    Opsgenie = 'opsgenie',
    Prtg = 'prtg',
    PagerDuty = 'pager_duty',
    Panther = 'panther',
    Pingdom = 'pingdom',
    Runscope = 'runscope',
    Sns = 'sns',
    Sentry = 'sentry',
    Splunk = 'splunk',
    StatusCake = 'status_cake',
    StatusPageViews = 'status_page_views',
    SumoLogic = 'sumo_logic',
    Uptime = 'uptime',
    Zendesk = 'zendesk'
}

export function AlertSourceFromJSON(json: any): AlertSource {
    return AlertSourceFromJSONTyped(json, false);
}

export function AlertSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_acknowledge_in_source': !exists(json, 'can_acknowledge_in_source') ? undefined : json['can_acknowledge_in_source'],
        'classification': json['classification'],
        'docstring': json['docstring'],
        'eligibility': !exists(json, 'eligibility') ? undefined : EligibilityInformationFromJSON(json['eligibility']),
        'example_payload': json['example_payload'],
        'externally_resolved': !exists(json, 'externally_resolved') ? undefined : json['externally_resolved'],
        'max_instances': json['max_instances'],
        'name': json['name'],
        'required_integrations': json['required_integrations'],
        'scope': EngineScopeFromJSON(json['scope']),
        'source_type': json['source_type'],
    };
}

export function AlertSourceToJSON(value?: AlertSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_acknowledge_in_source': value.can_acknowledge_in_source,
        'classification': value.classification,
        'docstring': value.docstring,
        'eligibility': EligibilityInformationToJSON(value.eligibility),
        'example_payload': value.example_payload,
        'externally_resolved': value.externally_resolved,
        'max_instances': value.max_instances,
        'name': value.name,
        'required_integrations': value.required_integrations,
        'scope': EngineScopeToJSON(value.scope),
        'source_type': value.source_type,
    };
}

