/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSourceRouting,
    AlertSourceRoutingFromJSON,
    AlertSourceRoutingFromJSONTyped,
    AlertSourceRoutingToJSON,
} from './AlertSourceRouting';
import {
    AlertTemplatePayload,
    AlertTemplatePayloadFromJSON,
    AlertTemplatePayloadFromJSONTyped,
    AlertTemplatePayloadToJSON,
} from './AlertTemplatePayload';

/**
 * 
 * @export
 * @interface AlertsUpdateSourceConfigRequestBody
 */
export interface AlertsUpdateSourceConfigRequestBody {
    /**
     * Whether to automatically acknowledge incidents in the source system when they are acknowledged in incident.io. This is not supported for all source types.
     * @type {boolean}
     * @memberof AlertsUpdateSourceConfigRequestBody
     */
    auto_ack_in_source: boolean;
    /**
     * Unique name of the alert source
     * @type {string}
     * @memberof AlertsUpdateSourceConfigRequestBody
     */
    name: string;
    /**
     * 
     * @type {AlertSourceRouting}
     * @memberof AlertsUpdateSourceConfigRequestBody
     */
    routing: AlertSourceRouting;
    /**
     * 
     * @type {AlertTemplatePayload}
     * @memberof AlertsUpdateSourceConfigRequestBody
     */
    template: AlertTemplatePayload;
}

export function AlertsUpdateSourceConfigRequestBodyFromJSON(json: any): AlertsUpdateSourceConfigRequestBody {
    return AlertsUpdateSourceConfigRequestBodyFromJSONTyped(json, false);
}

export function AlertsUpdateSourceConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsUpdateSourceConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_ack_in_source': json['auto_ack_in_source'],
        'name': json['name'],
        'routing': AlertSourceRoutingFromJSON(json['routing']),
        'template': AlertTemplatePayloadFromJSON(json['template']),
    };
}

export function AlertsUpdateSourceConfigRequestBodyToJSON(value?: AlertsUpdateSourceConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_ack_in_source': value.auto_ack_in_source,
        'name': value.name,
        'routing': AlertSourceRoutingToJSON(value.routing),
        'template': AlertTemplatePayloadToJSON(value.template),
    };
}

