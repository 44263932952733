import { Checkbox } from "@incident-ui/Checkbox/Checkbox";
import React, { useMemo } from "react";

import { Table, TableCell, TableHeaderCell, TableProps } from "./Table";

export interface SelectableTableProps<T extends { id: string }>
  extends Omit<TableProps<T>, "renderRow"> {
  renderRow: (
    row: T,
    index: number,
    checkbox: React.ReactElement,
  ) => React.ReactNode;
  selected: T["id"][];
  onSelectChanged?: (id: T["id"], newValue: boolean) => void;
  // This is done as a separate prop to allow for custom select all behavior (e.g. only selecting visible rows)
  selectAll?: boolean;
  onSelectAllChanged?: (selected: boolean) => void;
  isRowDisabled?: (row: T) => boolean;
}

export const SelectableTable = <T extends { id: string }>(
  props: SelectableTableProps<T>,
) => {
  const shouldHaveSelectAllCheckbox = !!props.onSelectAllChanged;
  const shouldHaveSelectCheckbox = !!props.onSelectChanged;
  const shouldHaveCheckboxColumn = !!(
    props.onSelectChanged || props.onSelectAllChanged
  );

  const { data, isRowDisabled } = props;

  const allRowsDisabled = useMemo(() => {
    if (!isRowDisabled || !data) return false;
    return data.every((row) => isRowDisabled?.(row));
  }, [data, isRowDisabled]);

  return (
    <Table<T>
      {...props}
      gridTemplateColumns={`${shouldHaveCheckboxColumn ? "min-content" : ""} ${
        props.gridTemplateColumns
      }`}
      header={
        props.header && (
          <>
            {shouldHaveCheckboxColumn ? (
              <TableHeaderCell className="pr-4">
                {shouldHaveSelectAllCheckbox && (
                  <Checkbox
                    id={"selectable-table-checkbox-header"}
                    onChange={(e) =>
                      props.onSelectAllChanged &&
                      props.onSelectAllChanged(e.target.checked)
                    }
                    checked={props.selectAll}
                    disabled={allRowsDisabled}
                  />
                )}
              </TableHeaderCell>
            ) : null}
            {props.header}
          </>
        )
      }
      renderRow={(row, index) =>
        props.renderRow(
          row,
          index,
          // the checkbox must be placed into the TableRow (by the caller)
          // for proper alignment and hover behaviour
          shouldHaveCheckboxColumn ? (
            <TableCell className="pr-4">
              {shouldHaveSelectCheckbox && (
                <Checkbox
                  id={"selectable-table-checkbox-" + row.id}
                  onChange={(e) =>
                    props.onSelectChanged &&
                    props.onSelectChanged(row.id, e.target.checked)
                  }
                  checked={props.selected.includes(row.id)}
                  disabled={props.isRowDisabled?.(row)}
                />
              )}
            </TableCell>
          ) : (
            <></>
          ),
        )
      }
    />
  );
};
