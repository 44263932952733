import {
  AlertsGetInsightsResponseBody,
  IncidentSlim,
  IncidentSlimStatusCategoryEnum,
} from "@incident-io/api";
import { GradientBar } from "@incident-shared/insights/LoadingTrendsTiles";
import { TrendTheme } from "@incident-shared/insights/TrendTile";
import { BadgeSize, Button, ButtonTheme } from "@incident-ui";
import { BarChart } from "@incident-ui/Chart/BarChart";
import _ from "lodash";
import { tcx } from "src/utils/tailwind-classes";
import { getColor } from "src/utils/twConfig";

import { formatValueWithUnit } from "../../common/formatValueWithUnit";

type WorkloadData = {
  id: string;
  label: string;
  real: string;
  declined: string;
  relevantIncidents: IncidentSlim[];
};

const workloadChartConfig = {
  real: {
    label: "Accepted incidents",
    color: getColor("alarmalade", "200"),
    background: "bg-alarmalade-200",
  },
  declined: {
    label: "Declined incidents",
    color: getColor("alarmalade", "500"),
    background: "bg-alarmalade-500",
  },
};

export const WorkloadChart = ({
  data,
}: {
  data?: AlertsGetInsightsResponseBody;
}) => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const datapoints =
    data?.workload.datapoints.map((data): WorkloadData => {
      return {
        id: data.date.toISOString(),
        // format label as Tue 3rd Dec
        label: data.date.toLocaleDateString(locale, {
          timeZone: timezone,
          weekday: "short",
          day: "numeric",
          month: "short",
        }),
        real: Number.isInteger(data.real_incident_workload)
          ? data.real_incident_workload.toString()
          : data.real_incident_workload.toFixed(2),
        declined: Number.isInteger(data.declined_incident_workload)
          ? data.declined_incident_workload.toString()
          : data.declined_incident_workload.toFixed(2),
        relevantIncidents: data.relevant_incidents,
      };
    }) || [];

  const totalWorkload = data?.workload_total_incidents;
  const totalIncidents = data?.number_incidents;

  if (!data) {
    return <LoadingWorkloadChart />;
  }

  return (
    <div
      className={
        "flex flex-col p-4 gap-4 rounded-2 border border-stroke-primary"
      }
    >
      <div className={"flex flex-col gap-1"}>
        <span className={"text-xs-med"}>Workload over time</span>
        <span className={"leading-normal text-base-bold"}>
          {formatValueWithUnit(
            totalWorkload?.current_value || 0,
            totalWorkload?.unit || "hour",
          )}
        </span>
      </div>
      <BarChart<WorkloadData>
        dataKeys={["real", "declined"]}
        data={datapoints}
        chartConfig={workloadChartConfig}
        className={"w-full max-h-[108px]"}
        cartesianGridProps={{
          vertical: false,
          horizontal: true,
          stroke: getColor("slate", "50"),
        }}
        barProps={{
          minPointSize: 1,
          defaultFill:
            totalIncidents?.current_value === 0
              ? getColor("slate", "200")
              : workloadChartConfig.real.color,
        }}
        renderTooltipContent={(data) => <WorkloadTooltip data={data} />}
        emptyStateMessage={data?.unavailable_reason}
      />
    </div>
  );
};

const WorkloadTooltip = ({ data }: { data: WorkloadData }) => {
  const incidents = _.sortBy(
    data.relevantIncidents || [],
    (incident) =>
      incident.status_category === IncidentSlimStatusCategoryEnum.Declined,
  );
  return (
    <div className="w-[360px] flex flex-col">
      <div className="p-3 flex flex-col gap-2">
        <div className="text-xs-bold">{data.label}</div>
        <div className="flex w-full gap-2 text-xs-med text-content-secondary">
          <div className="flex items-center gap-2 grow">
            <div
              className={tcx(
                "w-2 h-2 shrink-0 rounded-[2px]",
                workloadChartConfig.real.background,
              )}
            />
            <div>{`Accepted (${formatValueWithUnit(
              parseFloat(data.real),
              "hour",
            )})`}</div>
          </div>
          <div className="flex items-center gap-2 grow">
            <div
              className={tcx(
                "w-2 h-2 shrink-0 rounded-[2px]",
                workloadChartConfig.declined.background,
              )}
            />
            <div>{`Declined (${formatValueWithUnit(
              parseFloat(data.declined),
              "hour",
            )})`}</div>
          </div>
        </div>
      </div>
      {data.relevantIncidents.length ? (
        <div className="p-1 border-t border-stroke">
          {incidents.map((inc) => {
            return (
              <Button
                key={inc.id}
                href={`/incidents/${inc.id}`}
                analyticsTrackingId="alert-insights-view-incident"
                size={BadgeSize.Small}
                className={tcx(
                  "!justify-start w-full gap-2 px-2 py-[6px]",
                  "hover:bg-surface-secondary transition rounded",
                  "text-xs-med text-content-primary",
                )}
                theme={ButtonTheme.Naked}
              >
                <div
                  className={tcx(
                    "w-2 h-2 shrink-0 rounded-[2px]",
                    inc.status_category ===
                      IncidentSlimStatusCategoryEnum.Declined
                      ? workloadChartConfig.declined.background
                      : workloadChartConfig.real.background,
                  )}
                />
                <div className="truncate">
                  INC-{inc.external_id} {inc.name}
                </div>
              </Button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const LoadingWorkloadChart = () => {
  return (
    <div
      className={
        "flex flex-col p-4 gap-4 rounded-xl border border-stroke-primary"
      }
    >
      <div className={"flex flex-col gap-1 animate-pulse"}>
        <div className={"w-[181px]"}>
          <GradientBar theme={TrendTheme.Grey} />
        </div>
        <div className={"w-[156px] h-6"}>
          <GradientBar theme={TrendTheme.Grey} taller stopPoint="to-80%" />
        </div>
      </div>
      <BarChart
        dataKeys={["real", "declined"]}
        data={[
          {
            id: "loading",
            label: "loading",
            real: 0,
            declined: 0,
          },
        ]}
        chartConfig={workloadChartConfig}
        className={"w-full max-h-[108px]"}
        cartesianGridProps={{
          vertical: false,
          horizontal: true,
          stroke: getColor("slate", "50"),
        }}
      />
    </div>
  );
};
