import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Icon, IconEnum, IconSize, ModalFooter } from "@incident-ui";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  InvestigationPayloadPlanTypeEnum,
  InvestigationPlanTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { tcx } from "src/utils/tailwind-classes";

export const RunInvestigationModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const planTypes = Object.values(InvestigationPlanTypeEnum);

  return (
    <IncidentRunInvestigationForm
      onClose={onClose}
      incident={incident}
      investigationPlans={planTypes}
      defaultPlanID={InvestigationPlanTypeEnum.Default}
    />
  );
};

type FormData = {
  plan_type: InvestigationPlanTypeEnum;
  muted: boolean;
};

const IncidentRunInvestigationForm = ({
  onClose,
  incident,
  investigationPlans,
  defaultPlanID,
}: {
  onClose: (inc?: Incident) => void;
  incident: Incident;
  investigationPlans: InvestigationPlanTypeEnum[];
  defaultPlanID?: InvestigationPlanTypeEnum;
}): React.ReactElement => {
  const formMethods = useForm<FormData>({
    defaultValues: { plan_type: defaultPlanID },
  });
  const apiClient = useClient();
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();

  const [cutoffDate, setCutoffDate] = useState<Date | undefined>();

  const [createInvestigation, { saving: isCreating, genericError }] =
    useMutation(
      async ({
        plan_type,
        muted,
      }: {
        plan_type: InvestigationPlanTypeEnum;
        muted: boolean;
      }) => {
        const investigation = await apiClient.aICreateInvestigation({
          createInvestigationRequestBody: {
            incident_id: incident.id,
            plan_type: plan_type as unknown as InvestigationPayloadPlanTypeEnum,
            cutoff_at: cutoffDate,
            muted,
          },
        });
        return investigation;
      },
      {
        onSuccess: async ({ investigation }) => {
          showToast({
            theme: ToastTheme.Success,
            title: "Starting investigation",
            toastSide: ToastSideEnum.TopRight,
          });
          navigate(`/workbench/investigations/${investigation.id}`);
        },
        onError: async (err) => {
          showToast({
            theme: ToastTheme.Error,
            title: "Failed to start investigation",
            description: JSON.stringify(err),
            toastSide: ToastSideEnum.TopRight,
          });
        },
      },
    );

  return (
    <Form.Modal
      formMethods={formMethods}
      title={"Run an investigation"}
      analyticsTrackingId="run-investigation"
      disableQuickClose
      onClose={onClose}
      onSubmit={createInvestigation}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText={"Run investigation"}
          analyticsTrackingId="run-investigation-submit"
          saving={isCreating}
        />
      }
    >
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="plan_type"
        label="Investigation plan"
        helptext={"Choose what parts of an investigation you want to run."}
        options={investigationPlans.map((p) => ({
          label: p,
          value: p,
        }))}
      />
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Controller
            name="muted"
            control={formMethods.control}
            defaultValue={true}
            render={({ field }) => (
              <input
                type="checkbox"
                id="muted"
                checked={field.value}
                onChange={field.onChange}
                className="h-4 w-4 rounded border-stroke text-blue-600 focus:ring-blue-500"
              />
            )}
          />
          <label htmlFor="muted" className="flex items-center space-x-2">
            <span className="text-sm font-medium text-content-primary">
              Muted
            </span>
            <Icon
              id={IconEnum.Mute}
              size={IconSize.Small}
              className="text-content-secondary"
            />
          </label>
        </div>
        <p className="text-sm text-content-secondary">
          When enabled, no message will be created for this investigation. This
          is useful for testing, especially against archived or closed
          incidents.
        </p>
      </div>
      <InvestigationCutoffSelect
        incidentReportedAt={incident.reported_at}
        onChange={setCutoffDate}
      />
    </Form.Modal>
  );
};

type CutoffType = "none" | "incident_reported" | "custom";

type Props = {
  incidentReportedAt: Date;
  onChange: (cutoffDate: Date | undefined) => void;
  className?: string;
};

export const InvestigationCutoffSelect = ({
  incidentReportedAt,
  onChange,
  className,
}: Props) => {
  const [cutoffType, setCutoffType] = useState<CutoffType>("incident_reported");
  const [customDate, setCustomDate] = useState<string>(
    new Date().toISOString().slice(0, 16),
  );

  useEffect(() => {
    switch (cutoffType) {
      case "none":
        onChange(undefined);
        break;
      case "incident_reported":
        onChange(new Date(incidentReportedAt));
        break;
      case "custom":
        onChange(new Date(customDate));
        break;
    }
  }, [cutoffType, customDate, incidentReportedAt, onChange]);

  return (
    <div className={tcx("space-y-4", className)}>
      <div className="space-y-2">
        <label className="text-sm font-medium text-content-primary">
          Investigation cutoff time
        </label>
        <p className="text-sm text-content-secondary">
          Choose when to simulate running this investigation from.
        </p>
      </div>

      <div className="space-y-3">
        <div className="space-y-2">
          {[
            {
              value: "none" as const,
              label: "No cutoff",
              description: "Run with access to all current data",
              icon: IconEnum.Clock,
            },
            {
              value: "incident_reported" as const,
              label: "From incident reported time",
              description:
                "Only use data available when the incident was reported",
              icon: IconEnum.IncidentFlame,
            },
            {
              value: "custom" as const,
              label: "Custom time",
              description: "Pick a specific cutoff time",
              icon: IconEnum.Calendar,
            },
          ].map((option) => (
            <label
              key={option.value}
              className={tcx(
                "flex items-start space-x-3 rounded-md border p-3 cursor-pointer",
                cutoffType === option.value
                  ? "border-blue-500 bg-blue-50"
                  : "border-stroke hover:border-blue-200 hover:bg-blue-50/50",
              )}
            >
              <input
                type="radio"
                className="mt-1 h-4 w-4 border-stroke text-blue-600"
                checked={cutoffType === option.value}
                onChange={() => setCutoffType(option.value)}
              />
              <div className="flex-1 space-y-1">
                <div className="flex items-center space-x-2">
                  <Icon
                    id={option.icon}
                    size={IconSize.Small}
                    className="text-content-secondary"
                  />
                  <span className="font-medium text-content-primary">
                    {option.label}
                  </span>
                </div>
                <p className="text-sm text-content-secondary">
                  {option.description}
                </p>
              </div>
            </label>
          ))}
        </div>

        {cutoffType === "custom" && (
          <input
            type="datetime-local"
            className="block w-full rounded-md border-stroke bg-white px-3 py-2 text-sm"
            value={customDate}
            onChange={(e) => setCustomDate(e.target.value)}
          />
        )}
      </div>
    </div>
  );
};
