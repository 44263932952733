import { Button, ButtonProps } from "@incident-ui/Button/Button";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import React, { ForwardedRef } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { GatedInputProps, useGateInput } from "../gates";

export type GatedButtonProps = GatedInputProps & ButtonProps;

// GatedButton is a wrapper for Button which handle the various reasons a button might be disabled.
// * If the feature requires a particular user scope, pass the `scopeRequiredToAdd`
// * If the feature requires the organisation to upgrade, pass the `upgradeRequired` bool and `upgradeRequiredProps`
// * If the feature is disabled for some other reason (e.g. isDirty), pass the `disabled` bool and optionally `disabledTooltipContent`
export const GatedButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  GatedButtonProps
>(
  (
    { spanClassName, children, ...props }: GatedButtonProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    const { disabled: buttonIsDisabled, tooltip } = useGateInput(props);

    if (tooltip) {
      return (
        <GatedButtonInner
          tooltipContent={tooltip}
          spanClassName={spanClassName}
          ref={ref}
          {...props}
          disabled={buttonIsDisabled}
        >
          {children}
        </GatedButtonInner>
      );
    }

    return (
      <Button ref={ref} {...props} disabled={buttonIsDisabled}>
        {children}
      </Button>
    );
  },
);

GatedButton.displayName = "GatedButton";

type GatedButtonInnerProps = ButtonProps & {
  tooltipContent: React.ReactNode;
  spanClassName?: string;
};
const GatedButtonInner = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  GatedButtonInnerProps
>(
  (
    {
      tooltipContent,
      spanClassName,
      disabled,
      className,
      onClick,
      href,
      title,
      children,
      ...buttonProps
    }: GatedButtonInnerProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    if (disabled) {
      className = tcx(className, "pointer-events-none");
      onClick = undefined;
      href = undefined;
      title = undefined;
    }

    return (
      <Tooltip
        analyticsTrackingId={null}
        content={tooltipContent}
        delayDuration={0}
      >
        <span
          className={tcx(
            "w-fit cursor-not-allowed flex items-center",
            spanClassName,
          )}
        >
          <Button
            {...buttonProps}
            ref={ref}
            className={className}
            disabled={disabled}
            // clear out any other behaviour (as disabled:true won't actually do anything)
            onClick={onClick}
            href={href}
            title={title}
          >
            {children}
          </Button>
        </span>
      </Tooltip>
    );
  },
);
GatedButtonInner.displayName = "GatedButtonInner";
