/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertWorkload,
    AlertWorkloadFromJSON,
    AlertWorkloadFromJSONTyped,
    AlertWorkloadToJSON,
} from './AlertWorkload';
import {
    Trend,
    TrendFromJSON,
    TrendFromJSONTyped,
    TrendToJSON,
} from './Trend';

/**
 * 
 * @export
 * @interface AlertsGetInsightsResponseBody
 */
export interface AlertsGetInsightsResponseBody {
    /**
     * 
     * @type {Trend}
     * @memberof AlertsGetInsightsResponseBody
     */
    decline_rate: Trend;
    /**
     * The timestamp that this data is valid from - note that we cache insights queries so this may be earlier than GetLastSync reports
     * @type {Date}
     * @memberof AlertsGetInsightsResponseBody
     */
    last_sync_at: Date;
    /**
     * 
     * @type {Trend}
     * @memberof AlertsGetInsightsResponseBody
     */
    number_alerts: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertsGetInsightsResponseBody
     */
    number_incidents: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertsGetInsightsResponseBody
     */
    number_incidents_declined: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertsGetInsightsResponseBody
     */
    number_incidents_real: Trend;
    /**
     * 
     * @type {string}
     * @memberof AlertsGetInsightsResponseBody
     */
    unavailable_reason?: string;
    /**
     * 
     * @type {AlertWorkload}
     * @memberof AlertsGetInsightsResponseBody
     */
    workload: AlertWorkload;
    /**
     * 
     * @type {Trend}
     * @memberof AlertsGetInsightsResponseBody
     */
    workload_declined_incidents: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertsGetInsightsResponseBody
     */
    workload_real_incidents: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertsGetInsightsResponseBody
     */
    workload_total_incidents: Trend;
}

export function AlertsGetInsightsResponseBodyFromJSON(json: any): AlertsGetInsightsResponseBody {
    return AlertsGetInsightsResponseBodyFromJSONTyped(json, false);
}

export function AlertsGetInsightsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsGetInsightsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decline_rate': TrendFromJSON(json['decline_rate']),
        'last_sync_at': (new Date(json['last_sync_at'])),
        'number_alerts': TrendFromJSON(json['number_alerts']),
        'number_incidents': TrendFromJSON(json['number_incidents']),
        'number_incidents_declined': TrendFromJSON(json['number_incidents_declined']),
        'number_incidents_real': TrendFromJSON(json['number_incidents_real']),
        'unavailable_reason': !exists(json, 'unavailable_reason') ? undefined : json['unavailable_reason'],
        'workload': AlertWorkloadFromJSON(json['workload']),
        'workload_declined_incidents': TrendFromJSON(json['workload_declined_incidents']),
        'workload_real_incidents': TrendFromJSON(json['workload_real_incidents']),
        'workload_total_incidents': TrendFromJSON(json['workload_total_incidents']),
    };
}

export function AlertsGetInsightsResponseBodyToJSON(value?: AlertsGetInsightsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decline_rate': TrendToJSON(value.decline_rate),
        'last_sync_at': (value.last_sync_at.toISOString()),
        'number_alerts': TrendToJSON(value.number_alerts),
        'number_incidents': TrendToJSON(value.number_incidents),
        'number_incidents_declined': TrendToJSON(value.number_incidents_declined),
        'number_incidents_real': TrendToJSON(value.number_incidents_real),
        'unavailable_reason': value.unavailable_reason,
        'workload': AlertWorkloadToJSON(value.workload),
        'workload_declined_incidents': TrendToJSON(value.workload_declined_incidents),
        'workload_real_incidents': TrendToJSON(value.workload_real_incidents),
        'workload_total_incidents': TrendToJSON(value.workload_total_incidents),
    };
}

