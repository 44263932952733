import { ExpressionElseBranchInput } from "@incident-shared/engine/expressions/ExpressionElseBranchInput";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  IconEnum,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import { CheckboxHelptextDisplayEnum } from "@incident-ui/Checkbox/Checkbox";
import { StaticSingleSelectWithObj } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import React, { ChangeEvent } from "react";
import {
  Controller,
  FieldErrors,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  UseFormGetValues,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { rehydrateBinding } from "src/components/legacy/workflows/common/utils";
import {
  EngineScope,
  Resource,
  ResourceFieldConfigArrayTypeEnum,
  ResourceFieldConfigTypeEnum,
} from "src/contexts/ClientContext";
import { filterScope } from "src/utils/scope";

import { isEmptyBinding, isExpression, MaybeRef } from "../..";
import {
  CreateEditExpressionFormData,
  ExpressionFormProps,
} from "../AddEditExpressionModal";
import {
  ExpressionDeletionUsages,
  expressionIsInUse,
  getUsagesNode,
} from "../ExpressionsEditor";
import { useExpressionsMethods } from "../ExpressionsMethodsProvider";
import { createDefaultIfElseValues } from "./createDefaultExpressionFormValues";
import { Branch, ExpressionBranchesList } from "./ExpressionBranchesList";

export const IfElseExpressionModal = ({
  initialExpression,
  defaultExpression,
  onAddExpression,
  onEditExpression,
  onClose,
  analyticsTrackingContext,
  scope: fullScope,
  resources,
  existingExpressions,
  fixedResult,
  labelOverrides,
  onDeleteExpression,
  currentlyInUse,
  returnMultipleItemsCheckboxHelptext,
  validateReturnType,
  elseBranchRequired,
}: ExpressionFormProps): React.ReactElement | null => {
  const { showExpressionNames } = useExpressionsMethods();

  const isEditingExistingExpression = !!initialExpression;
  const formMethods = useForm<CreateEditExpressionFormData>({
    defaultValues: createDefaultIfElseValues(
      initialExpression,
      defaultExpression,
      fixedResult,
    ),
    shouldUnregister: false,
  });

  const {
    control,
    watch,
    getValues,
    setError,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = formMethods;
  const {
    move: onMoveBranch,
    append: onAddBranchRaw,
    remove: onRemoveBranch,
    update: onEditBranchRaw,
    fields: branches,
  } = useFieldArray({
    control,
    name: "operations.0.branches.branches",
  });

  // Filter available references once again, to ensure we can't have an expression
  // pointing at another expression (as we can't guarantee what order they'll
  // evaluate, and we could get a circular reference)
  const scope = filterScope(fullScope, (ref) => !isExpression(ref.key));

  const [label, selectedResultType, resultArray_] = watch([
    "label",
    "operations.0.branches.returns.type",
    "operations.0.branches.returns.array",
  ]);

  const resultArray = fixedResult ? fixedResult.array : resultArray_;

  const selectedExpressionTypeResource = resources.find(
    (x) => x.type === selectedResultType,
  );

  const rehydrateBranchBindings = (branch: Branch): Branch => {
    if (!selectedExpressionTypeResource) {
      return branch;
    }
    return {
      ...branch,
      result: rehydrateBinding({
        array: false,
        resource: selectedExpressionTypeResource,
        binding: branch.result,
      }),
    };
  };

  const onAddBranch = (branch: Branch): void => {
    return onAddBranchRaw(rehydrateBranchBindings(branch));
  };
  const onEditBranch = (idx: number, branch: Branch): void => {
    return onEditBranchRaw(idx, rehydrateBranchBindings(branch));
  };

  const isValidType = (resourceInfo: MaybeRef): boolean => {
    if (!validateReturnType) {
      return true;
    }

    const res = validateReturnType(resourceInfo);
    // Undefined means "valid"
    if (res === undefined) return true;

    // String means "invalid, this is why"
    if (typeof res === "string") return false;

    return res;
  };

  let availableReturnTypeResources = resources.filter(
    (r) =>
      isValidType({ resource: r, array: true }) ||
      isValidType({ resource: r, array: false }),
  );

  availableReturnTypeResources =
    availableReturnTypeResources
      .filter((r) =>
        resultArray
          ? r.field_config.array_type !== ResourceFieldConfigArrayTypeEnum.None
          : r.field_config.type !== ResourceFieldConfigTypeEnum.None,
      )
      .filter((r) => {
        return (
          r.type !== "NotYetInvitedSlackUser" &&
          r.type !== "Text" &&
          r.type !== "Wrapper" &&
          r.type !== "Incident"
        );
      }) ?? [];

  const resultTypeOptions = availableReturnTypeResources.map(
    (res): SelectOption => ({
      label: res.type_label,
      value: res.type,
      // Bump incident type to the top
      sort_key: res.type === "IncidentType" ? "AAAIncidentType" : res.type,
      icon:
        (res.field_config.icon as unknown as IconEnum) ?? IconEnum.CustomField,
    }),
  );

  const onOk = (expression: CreateEditExpressionFormData) => {
    clearErrors();
    if (!selectedExpressionTypeResource) {
      setError("operations.0.branches.returns.type", {
        type: "manual",
        message: "Choose a return type",
      });
      return;
    }
    if (
      expression.else_branch &&
      isEmptyBinding(expression.else_branch.result)
    ) {
      // if the else branch is required, set an error
      if (elseBranchRequired) {
        if (expression.returns.array) {
          setError("else_branch.result.array_value", {
            type: "required",
            message: "This field is required",
          });
        } else {
          setError("else_branch.result.value", {
            type: "required",
            message: "This field is required",
          });
        }
        return;
      }
    }

    if (!showExpressionNames) {
      expression.label =
        fixedResult?.label ?? "new-derived-custom-field-expression";
    }

    // If we don't show the label of the expression, don't validate it since we
    // can't show this error anywhere.
    if (!fixedResult?.label) {
      if (
        !isEditingExistingExpression &&
        existingExpressions.some((e) => e.label === expression.label)
      ) {
        setError("label", {
          type: "manual",
          message: "An expression with this name already exists",
        });
        return;
      }

      if (!expression.label || expression.label?.trim().length === 0) {
        setError("label", {
          type: "required",
          message: "Choose a name for your expression",
        });
        return;
      }
    }

    const branchesOpts = expression.operations?.[0].branches;
    const branches = branchesOpts?.branches || [];
    if (!branchesOpts || !branches || branches.length === 0) {
      setError("operations.0.branches.branches", {
        type: "required",
        message: "At least one rule is required",
      });
      return;
    }

    expression.else_branch.result = rehydrateBinding({
      array: expression.returns?.array,
      resource: selectedExpressionTypeResource,
      binding: expression.else_branch.result,
    });

    // Lift the result information up to the expression level
    expression.returns = {
      type: branchesOpts.returns?.type,
      array: branchesOpts.returns?.array,
    };

    // Also set the result type on the operation, as the query
    // expression editor and serializer would include it here
    // too.
    expression.operations[0].returns = {
      type: branchesOpts.returns?.type,
      array: branchesOpts.returns?.array,
    };

    if (isEditingExistingExpression) {
      reset();
      onEditExpression(expression);
    } else {
      reset();
      onAddExpression(expression);
    }
  };

  const onMigrateBranches = (targetIsArray: boolean) => {
    const [branches] = getValues(["operations.0.branches.branches"]);

    // Figure out what the 'new' branches look like, by pulling the data
    // over from array -> scalar (or vice versa)

    const newBranches = branches.map((original) => {
      const branch = { ...original };

      const scalarValue = branch.result.value;
      const scalarIsSet =
        scalarValue && (scalarValue.literal || scalarValue.reference);
      const arrayValue = branch.result?.array_value ?? [];
      const arrayIsSet = arrayValue.length > 0;

      if (targetIsArray) {
        branch.result.array_value = scalarIsSet ? [scalarValue] : [];
      } else {
        branch.result.value = arrayIsSet ? arrayValue[0] : undefined;
      }
      return branch;
    });

    setValue<"operations.0.branches.branches">(
      "operations.0.branches.branches",
      newBranches,
    );
  };

  const canReturnMultipleItems = validateReturnType
    ? selectedExpressionTypeResource &&
      isValidType({ resource: selectedExpressionTypeResource, array: true })
    : selectedExpressionTypeResource?.field_config.array_type !==
      ResourceFieldConfigArrayTypeEnum.None;

  return (
    <FormProvider<CreateEditExpressionFormData> {...formMethods}>
      <Modal
        isOpen={true}
        as="form"
        analyticsTrackingId={`${analyticsTrackingContext}-add-expression`}
        title={
          labelOverrides?.modalTitle ??
          `${initialExpression ? `Edit expression` : `Add an expression`}${
            fixedResult?.label && showExpressionNames
              ? ` (${fixedResult?.label})`
              : ""
          }`
        }
        disableQuickClose
        onClose={onClose}
        onSubmit={(e) => {
          handleSubmit(onOk)(e);
          // Prevent this portal'd modal from submitting the parent one
          // https://github.com/facebook/react/issues/19637#issuecomment-811096805
          e.stopPropagation();
        }}
        isExtraLarge
      >
        <ModalContent>
          {/* Label */}
          {showExpressionNames && (
            <>
              <Form.InputWrapper
                name="label"
                className="mb-4"
                label={
                  labelOverrides?.nameLabel ??
                  "What should this expression be called?"
                }
                helptext={
                  labelOverrides?.helpText ||
                  "This is how you'll refer to your expression later on in the workflow."
                }
              >
                <Input
                  autoFocus
                  id="label"
                  disabled={isEditingExistingExpression}
                  placeholder={"e.g. Team Slack Channel"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValue("label", e.target.value)
                  }
                  value={label}
                  autoComplete="none"
                />
              </Form.InputWrapper>
            </>
          )}
          {fixedResult ? null : (
            <>
              {/* Expression return type */}
              <Form.InputWrapper
                name="operations.0.branches.returns.type"
                label="Return type"
                helptext={
                  "Your expression can return a value in a specific format, such as plain text, an email address, or a Slack channel."
                }
                className={"mb-4"}
              >
                <Controller
                  control={control}
                  name="operations.0.branches.returns.type"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <StaticSingleSelectWithObj
                      icon={
                        selectedExpressionTypeResource?.field_config
                          .icon as unknown as IconEnum
                      }
                      id="operations.0.branches.returns.type"
                      value={resultTypeOptions.find((o) => o.value === value)}
                      onChange={(selected) => {
                        if (
                          selected?.value &&
                          selected.value !== selectedResultType
                        ) {
                          // Reset branches when the result type changes
                          setValue<"operations.0.branches.branches">(
                            "operations.0.branches.branches",
                            [],
                          );
                        }
                        onChange(selected?.value);
                      }}
                      options={resultTypeOptions}
                      onBlur={onBlur}
                      placeholder="Choose a return type"
                      isClearable={false}
                      disabled={isEditingExistingExpression}
                    />
                  )}
                />
              </Form.InputWrapper>
              <CheckboxV2
                formMethods={formMethods}
                name="operations.0.branches.returns.array"
                onValueChange={(val) => {
                  onMigrateBranches(val);
                  setValue<"returns.array">("returns.array", val);
                }}
                className="font-medium text-content-primary text-sm mb-4"
                disabled={!canReturnMultipleItems || !!currentlyInUse}
                helptextDisplay={CheckboxHelptextDisplayEnum.Newline}
                label="Should this expression return multiple items?"
                helptext={returnMultipleItemsCheckboxHelptext}
              />
            </>
          )}
          {/* Expression branches */}
          <ShowExpressionBranchesFormSection
            selectedExpressionTypeResource={selectedExpressionTypeResource}
            errors={errors}
            scope={scope}
            availableReturnTypeResources={availableReturnTypeResources}
            branches={branches ?? []}
            onMoveBranch={onMoveBranch}
            onAddBranch={onAddBranch}
            onRemoveBranch={onRemoveBranch}
            onEditBranch={onEditBranch}
            elseBranchRequired={elseBranchRequired ?? false}
          />
        </ModalContent>
        <Footer
          isEditing={isEditingExistingExpression}
          analyticsTrackingContext={analyticsTrackingContext}
          onOk={onOk}
          onCancel={onClose}
          currentlyInUse={currentlyInUse}
          getValues={getValues}
          onDelete={
            initialExpression && onDeleteExpression
              ? () => {
                  onDeleteExpression(initialExpression);
                  reset();
                }
              : undefined
          }
          isValid={!!selectedExpressionTypeResource}
        />
      </Modal>
    </FormProvider>
  );
};

type ShowExpressionBranchesFormProps = {
  scope: EngineScope;
  branches: (Branch & { id: string })[];
  errors: FieldErrors<CreateEditExpressionFormData>;
  selectedExpressionTypeResource: Resource | undefined;
  onMoveBranch: (indexA: number, indexB: number) => void;
  onAddBranch: (branch: Branch) => void;
  onRemoveBranch: (index: number) => void;
  onEditBranch: (index: number, branch: Branch) => void;
  // The resources currently available, given the users' single/multi return type option.
  // This is determined by whether a resource type can display a single or array form field or not.
  availableReturnTypeResources: Resource[];
  elseBranchRequired?: boolean;
};

export const ShowExpressionBranchesFormSection = ({
  selectedExpressionTypeResource,
  errors,
  scope,
  branches,
  onMoveBranch,
  onAddBranch,
  onRemoveBranch,
  onEditBranch,
  availableReturnTypeResources,
  elseBranchRequired,
}: ShowExpressionBranchesFormProps): React.ReactElement => {
  const formMethods = useFormContext<CreateEditExpressionFormData>();
  const resultArray = formMethods.watch("operations.0.branches.returns.array");

  // We're opting to manually handle errors ourselves, in order
  // to be able to put the error in the middle of the component
  const id = "operations.0.branches.branches";
  const hasError = errors[id] != null;

  return (
    <Form.InputWrapper
      name={id}
      hideErrors
      className="mb-4"
      label="Rules"
      helptext={
        "Set the conditions for this expression and the values that should be returned for each."
      }
    >
      <Form.ErrorMessage
        className={hasError ? "my-1" : ""}
        errors={errors}
        name={id}
      />
      <ExpressionBranchesList
        branches={branches}
        selectedExpressionTypeResource={selectedExpressionTypeResource}
        scope={scope}
        availableReturnTypeResources={availableReturnTypeResources}
        onMoveBranch={onMoveBranch}
        onAddBranch={onAddBranch}
        onRemoveBranch={onRemoveBranch}
        onEditBranch={onEditBranch}
      />
      <ExpressionElseBranchInput
        label={
          "If no rule conditions are met, what should the expression return?"
        }
        className={"mt-4"}
        scope={scope}
        isArray={resultArray}
        availableReturnTypeResources={availableReturnTypeResources}
        resourceType={selectedExpressionTypeResource?.type}
        required={elseBranchRequired}
      />
    </Form.InputWrapper>
  );
};

const Footer = ({
  isEditing,
  onOk,
  onCancel,
  getValues,
  analyticsTrackingContext,
  isValid,
  onDelete,
  currentlyInUse,
}: {
  isEditing: boolean;
  onOk: (cond: CreateEditExpressionFormData) => void;
  onDelete?: () => void;
  onCancel: () => void;
  getValues: UseFormGetValues<CreateEditExpressionFormData>;
  analyticsTrackingContext: string;
  isValid: boolean;
  currentlyInUse?: ExpressionDeletionUsages;
}): React.ReactElement => {
  let currentlyInUseTooltip: React.ReactNode | undefined;
  if (currentlyInUse) {
    currentlyInUseTooltip = (
      <>
        This expression is currently in use by {getUsagesNode(currentlyInUse)}{" "}
        and cannot be deleted.
      </>
    );
  }

  return (
    <ModalFooter
      gadget={
        onDelete ? (
          <div className="grow">
            <GatedButton
              analyticsTrackingId={null}
              theme={ButtonTheme.DestroySecondary}
              onClick={() => {
                onDelete();
              }}
              disabled={expressionIsInUse(currentlyInUse)}
              disabledTooltipContent={currentlyInUseTooltip}
            >
              Delete
            </GatedButton>
          </div>
        ) : undefined
      }
      onClose={onCancel}
      analyticsTrackingId={analyticsTrackingContext}
      onConfirm={() => onOk(getValues())}
      confirmButtonType="button"
      disabled={!isValid}
      confirmButtonText={isEditing ? "Continue" : "Add"}
    />
  );
};
