/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverviewDeclineRateDatapoint
 */
export interface OverviewDeclineRateDatapoint {
    /**
     * 
     * @type {number}
     * @memberof OverviewDeclineRateDatapoint
     */
    percentage: number;
    /**
     * 
     * @type {string}
     * @memberof OverviewDeclineRateDatapoint
     */
    theme?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewDeclineRateDatapoint
     */
    title?: string;
}

export function OverviewDeclineRateDatapointFromJSON(json: any): OverviewDeclineRateDatapoint {
    return OverviewDeclineRateDatapointFromJSONTyped(json, false);
}

export function OverviewDeclineRateDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewDeclineRateDatapoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'percentage': json['percentage'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function OverviewDeclineRateDatapointToJSON(value?: OverviewDeclineRateDatapoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'percentage': value.percentage,
        'theme': value.theme,
        'title': value.title,
    };
}

