import {
  AvailableIncidentFormLifecycleElementElementTypeEnum as FormElementType,
  CustomField,
  CustomFieldEntryPayload,
  IncidentFormLifecycleElementBinding,
  IncidentManualEdit,
  IncidentRole,
  IncidentRoleAssignmentPayload,
  IncidentsCreateRequestBodyModeEnum,
  IncidentTimestamp,
  IncidentTimestampValuePayload,
  IncidentType,
  TextDocumentPayload,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import {
  CustomFieldFormElement,
  FormCustomFieldEntries,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { SeverityElementSelect } from "@incident-shared/incident-forms/SeverityElementSelect";
import { Markdown } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import _ from "lodash";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { buildIncidentTypeOptions } from "src/components/legacy/incident/buildIncidentTypeOptions";
import {
  IncidentStatusFormData,
  StatusFormElement,
} from "src/components/legacy/incident/statuses/StatusFormElement";
import { useIncidentCrudResources } from "src/components/legacy/incident/useIncidentCrudResources";
import {
  Incident,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum as IncidentFormType,
} from "src/contexts/ClientContext";

import {
  IncidentRoleFormData,
  IncidentRoleFormElement,
} from "../../legacy/incident/IncidentRoleFormElement";
import { TimestampElement, TimestampFormData } from "./TimestampElement";
import {
  isIncidentInTriage,
  TriageElementSelect,
  TriageFormData,
} from "./TriageElement";
import { VisibilityData, VisibilityElement } from "./VisibilityElement";

export type SharedIncidentFormData = {
  name?: string;
  summary?: TextDocumentPayload;
  incident_type_id?: string;
  severity_id?: string;
  incident_status_id?: string;
  custom_field_entries: FormCustomFieldEntries;
} & TimestampFormData &
  IncidentRoleFormData;

export type UpdateOnlyData = {
  message?: TextDocumentPayload;
  next_update_in_minutes?: number;
  unpause_in_minutes?: number;
  unpause_as_date?: Date;
} & IncidentStatusFormData;

type DeclareOnlyData = {
  slack_team_id?: string;
  idempotency_key: string;
  mode: IncidentsCreateRequestBodyModeEnum;
  escalation_id?: string;
} & VisibilityData &
  TriageFormData;

export type DeclareFormData = SharedIncidentFormData & DeclareOnlyData;
export type UpdateFormData = SharedIncidentFormData & UpdateOnlyData;

// This is used in the request body of all incident forms
export type IncidentForm = {
  custom_field_entries: Array<CustomFieldEntryPayload>;
  incident_role_assignments: Array<IncidentRoleAssignmentPayload>;
  incident_timestamp_values: Array<IncidentTimestampValuePayload>;
};

export type AllIncidentFormData = SharedIncidentFormData &
  UpdateOnlyData &
  DeclareOnlyData;

export const FormElements = <
  TFormType extends FieldValues & SharedIncidentFormData,
>({
  elementBindings,
  incident,
  formMethods,
  selectedIncidentType,
  activeOrTriage,
  incidentFormType,
  customFieldEntryPayloads,
  manualEdits,
}: {
  elementBindings: IncidentFormLifecycleElementBinding[];
  incident?: Incident;
  formMethods: UseFormReturn<TFormType>;
  selectedIncidentType?: IncidentType;
  activeOrTriage?: "active" | "triage";
  incidentFormType: IncidentFormType;
  customFieldEntryPayloads: CustomFieldEntryPayload[];
  manualEdits?: IncidentManualEdit[];
}) => {
  const sortedElementList = _.sortBy(
    elementBindings,
    (element) => element.element.rank,
  );

  return (
    <>
      {sortedElementList.map((element) => (
        <FormElement
          key={element.element.id}
          elementBinding={element}
          incident={incident}
          formMethods={formMethods}
          selectedIncidentType={selectedIncidentType}
          activeOrTriage={activeOrTriage}
          incidentFormType={incidentFormType}
          customFieldEntryPayloads={customFieldEntryPayloads}
          manualEdits={manualEdits}
        />
      ))}
    </>
  );
};

export const FormElement = <
  TFormType extends FieldValues & SharedIncidentFormData,
>({
  elementBinding,
  incident,
  formMethods,
  incidentFormType,
  selectedIncidentType,
  activeOrTriage,
  dontAutoFocusOverride = false,
  customFieldEntryPayloads,
  manualEdits,
}: {
  elementBinding: IncidentFormLifecycleElementBinding;
  incident?: Incident;
  formMethods: UseFormReturn<TFormType>;
  incidentFormType: IncidentFormType;
  selectedIncidentType?: IncidentType;
  activeOrTriage?: "active" | "triage";
  dontAutoFocusOverride?: boolean;
  customFieldEntryPayloads: CustomFieldEntryPayload[];
  manualEdits?: IncidentManualEdit[];
}) => {
  const { incidentTypes, settings } = useIncidentCrudResources();
  const availableElement = elementBinding.element.available_element;

  // If this is a declare form, figure out if we are declaring a triage incident
  const incidentIsTriage =
    incidentFormType === IncidentFormType.Declare
      ? isIncidentInTriage(activeOrTriage, selectedIncidentType)
      : false;

  const { incidentTypeOptions, hasSelectedIncidentTypeDontKnow } =
    buildIncidentTypeOptions({
      selectedIncidentTypeID: selectedIncidentType?.id,
      incidentTypes,
      includeNotYetKnownOption: false,
    });

  const description = () => {
    return elementBinding.element.description ? (
      <TemplatedTextDisplay
        style={TemplatedTextDisplayStyle.Compact}
        value={elementBinding.element.description}
        className="text-slate-700"
      />
    ) : undefined;
  };

  let defaultDescription: string;

  // Figure out what to render
  switch (availableElement.element_type) {
    case FormElementType.Name:
      defaultDescription =
        "Give a short, punchy description of what is happening. You'll be able to change it later!";
      return (
        <InputV2<SharedIncidentFormData>
          formMethods={
            formMethods as unknown as UseFormReturn<SharedIncidentFormData>
          }
          autoFocus={!dontAutoFocusOverride}
          type={InputType.Text}
          required={elementBinding.required}
          name="name"
          rules={{
            required: elementBinding.required ? "Please enter a name" : false,
            minLength: elementBinding.required
              ? { value: 3, message: "Name must be at least 3 characters" }
              : undefined,
          }}
          label={"Name"}
          helptext={description() || defaultDescription}
        />
      );
    case FormElementType.Severity:
      return (
        <SeverityElementSelect<SharedIncidentFormData>
          formMethods={
            formMethods as unknown as UseFormReturn<SharedIncidentFormData>
          }
          name="severity_id"
          incidentType={selectedIncidentType}
          required={incidentIsTriage ? false : elementBinding.required}
          placeholder={elementBinding.element.placeholder}
        />
      );
    case FormElementType.Triage:
      return (
        <TriageElementSelect
          // We trust that this will only be rendered on the declare form,
          // meaning we can assume that formMethods is of type UseFormReturn<DeclareFormData>
          formMethods={formMethods as unknown as UseFormReturn<TriageFormData>}
          selectedIncidentType={selectedIncidentType}
        />
      );
    case FormElementType.IncidentType:
      return (
        <div className="flex flex-col gap-1.5">
          <StaticSingleSelectV2
            formMethods={
              formMethods as unknown as UseFormReturn<SharedIncidentFormData>
            }
            name="incident_type_id"
            options={incidentTypeOptions}
            label={"Incident Type"}
            isClearable={!elementBinding.required}
            required={elementBinding.required}
            placeholder={
              elementBinding.element.placeholder || "Choose an incident type"
            }
          />
          <Markdown className="text-xs text-slate-700">
            {selectedIncidentType?.description || ""}
          </Markdown>
        </div>
      );
    case FormElementType.IncidentRole:
      return (
        <div key={elementBinding.element.id} className="mb-4">
          <IncidentRoleFormElement
            formMethods={formMethods}
            key={elementBinding.element.id}
            formKey={
              `incident_role_assignments.${availableElement.incident_role?.id}.assignee_id` as Path<TFormType>
            }
            incidentId={incident?.id}
            placeholder={elementBinding.element.placeholder}
            role={availableElement.incident_role as IncidentRole}
            required={elementBinding.required}
          />
        </div>
      );
    case FormElementType.CustomField:
      return (
        <CustomFieldFormElement
          fieldKeyPrefix={
            `custom_field_entries.${availableElement.custom_field?.id}.values` as Path<TFormType>
          }
          placeholder={elementBinding.element.placeholder}
          key={availableElement.custom_field?.id}
          formMethods={formMethods}
          incidentId={incident?.id}
          customField={availableElement.custom_field as CustomField}
          required={elementBinding.required}
          includeNoValue={elementBinding.element.can_select_no_value}
          entryPayloads={customFieldEntryPayloads}
          manualEdits={manualEdits}
        />
      );
    case FormElementType.Timestamp:
      return (
        <TimestampElement
          formMethods={formMethods}
          timestamp={availableElement.incident_timestamp as IncidentTimestamp}
          incident={incident}
          required={elementBinding.required}
        />
      );
    case FormElementType.Summary:
      defaultDescription =
        "For anyone joining the incident, what are the most important things they need to know? This can be updated later.";
      return (
        <TemplatedTextInputV2
          formMethods={
            formMethods as unknown as UseFormReturn<SharedIncidentFormData>
          }
          name="summary.text_node"
          label={"Summary"}
          helptext={description() || defaultDescription}
          required={elementBinding.required}
          placeholder={elementBinding.element.placeholder}
          includeVariables={false}
          includeExpressions={false}
          format="basic"
        />
      );
    case FormElementType.UpdateMessage:
      defaultDescription =
        "Updates provide a view of what's going on right now, and are used to keep everyone on the same page.";
      return (
        <TemplatedTextInputV2
          formMethods={formMethods as unknown as UseFormReturn<UpdateFormData>}
          name="message.text_node"
          label={"Message"}
          required={elementBinding.required}
          placeholder={elementBinding.element.placeholder}
          helptext={description() || defaultDescription}
          includeVariables={false}
          includeExpressions={false}
          format="slack_rich_text"
        />
      );
    case FormElementType.NextUpdateIn:
      return (
        <StaticSingleSelectV2
          formMethods={formMethods as unknown as UseFormReturn<UpdateFormData>}
          label={"When will you provide the next update?"}
          name="next_update_in_minutes"
          options={[
            { value: "5", label: "5 minutes" },
            { value: "15", label: "15 minutes" },
            { value: "30", label: "30 minutes" },
            { value: "60", label: "60 minutes" },
            { value: "180", label: "3 hours" },
            { value: "360", label: "6 hours" },
            { value: "720", label: "12 hours" },
            { value: "1440", label: "1 day" },
            { value: "10080", label: "7 days" },
          ]}
          placeholder="Choose a time"
          isClearable={!elementBinding.required}
          required={elementBinding.required}
        />
      );
    case FormElementType.Visibility:
      return (
        <VisibilityElement
          // We trust that this will only be rendered on the declare form,
          // meaning we can assume that formMethods is of type UseFormReturn<DeclareFormData>
          formMethods={formMethods as unknown as UseFormReturn<DeclareOnlyData>}
          selectedIncidentType={selectedIncidentType}
          hasSelectedIncidentTypeDontKnow={hasSelectedIncidentTypeDontKnow}
          settings={settings}
        />
      );
    case FormElementType.Divider:
      return <FormDivider />;
    case FormElementType.Text:
      return <Form.Helptext>{description()}</Form.Helptext>;
    case FormElementType.Status:
      return (
        <StatusFormElement
          // We trust that this will only be rendered on the update form,
          // meaning we can assume that formMethods is of type UseFormReturn<DeclareFormData>
          formMethods={formMethods as unknown as UseFormReturn<UpdateFormData>}
          incident={incident || null}
        />
      );
    default:
      return null;
  }
};
