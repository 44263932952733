import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { Loader, SlackButtonPreview, SlackMessagePreview } from "@incident-ui";
import { useEffect, useState } from "react";
import {
  EngineParamBinding,
  EngineParamBindingPayload,
  NudgeNudgeTypeEnum,
  NudgePreviewButton,
  NudgesRenderPreviewRequestBody,
  NudgesRenderPreviewRequestBodyNudgeTypeEnum,
  NudgesRenderPreviewResponseBody,
  NudgeWorkflowButton,
  TextNode,
  useClient,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";
import { useDebouncedCallback } from "use-debounce";
import { useDeepCompareMemo } from "use-deep-compare";

type Props = {
  messageContent: EngineParamBinding;
  nudgeType: NudgeNudgeTypeEnum;
  paramBindings: EngineParamBindingPayload[];
  workflowButtons: NudgeWorkflowButton[];
};

export const NudgePreview = ({
  messageContent,
  nudgeType,
  paramBindings,
  workflowButtons,
}: Props) => {
  const [preview, setPreview] = useState<
    NudgesRenderPreviewResponseBody | undefined
  >(undefined);

  const apiClient = useClient();

  // Memoise this to avoid regenerating a preview if the message content object changes (but not a semantic change)
  const memoisedMessageContent = useDeepCompareMemo(
    () => messageContent,
    [messageContent],
  );

  // We want to make sure we generate a preview as the user edits the form,
  // but we don't want to be too aggressive, hence the debounce.
  const makePreview = useDebouncedCallback(
    async (req: NudgesRenderPreviewRequestBody) => {
      const render = await apiClient.nudgesRenderPreview({
        renderPreviewRequestBody: req,
      });
      setPreview(render);
    },
    500,
  );

  // Whenever one of the below values changes, we want to make a new preview.
  useEffect(() => {
    makePreview({
      nudge_type:
        nudgeType as unknown as NudgesRenderPreviewRequestBodyNudgeTypeEnum,
      message_content: memoisedMessageContent,
      param_bindings: paramBindings,
      workflow_buttons: workflowButtons,
    });
  }, [
    makePreview,
    memoisedMessageContent,
    nudgeType,
    paramBindings,
    workflowButtons,
  ]);

  // We set a min-height to prevent the height of the component
  // thrashing around before and after the preview has been loaded.

  // We don't show a loading spinner when the API request is in flight because
  // it's visually disruptive — instead, we just show it on the initial state,
  // where no preview has yet been loaded.
  if (!preview) {
    return (
      <SlackMessagePreview className="min-h-[116px]">
        <div className="space-y-2">
          <Loader className="w-auto my-0" />
        </div>
      </SlackMessagePreview>
    );
  }

  return (
    <NudgePreviewInner
      messageContent={preview.message_content}
      buttons={preview.buttons}
    />
  );
};

export type NudgePreviewInnerProps = {
  messageContent: TextNode | undefined;
  buttons: NudgePreviewButton[] | undefined;
  borderless?: boolean;
};
export const NudgePreviewInner = ({
  messageContent,
  buttons,
  borderless,
}: NudgePreviewInnerProps) => {
  return (
    <SlackMessagePreview
      className={tcx("min-h-[116px]", borderless && "border-none")}
    >
      <div className="space-y-2">
        <div className="flex justify-between items-center gap-1">
          {messageContent && (
            <TemplatedTextDisplay
              value={messageContent}
              style={TemplatedTextDisplayStyle.Compact}
              className="!border-0 !p-0"
            />
          )}
        </div>
        {buttons && (
          <div className="flex gap-2 flex-wrap">
            {buttons.map(({ emoji, text }, idx) => {
              return <SlackButtonPreview key={idx} emoji={emoji} text={text} />;
            })}
          </div>
        )}
      </div>
    </SlackMessagePreview>
  );
};
