/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffImportStatusPageSubscribersRequestBody
 */
export interface StaffImportStatusPageSubscribersRequestBody {
    /**
     * Emails of the users to subscribe: can be separated by commas or newlines
     * @type {string}
     * @memberof StaffImportStatusPageSubscribersRequestBody
     */
    email_addresses: string;
    /**
     * The status page to import subscribers for
     * @type {string}
     * @memberof StaffImportStatusPageSubscribersRequestBody
     */
    status_page_id?: string;
    /**
     * The sub-page to import subscribers for
     * @type {string}
     * @memberof StaffImportStatusPageSubscribersRequestBody
     */
    status_page_subpage_id?: string;
}

export function StaffImportStatusPageSubscribersRequestBodyFromJSON(json: any): StaffImportStatusPageSubscribersRequestBody {
    return StaffImportStatusPageSubscribersRequestBodyFromJSONTyped(json, false);
}

export function StaffImportStatusPageSubscribersRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffImportStatusPageSubscribersRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_addresses': json['email_addresses'],
        'status_page_id': !exists(json, 'status_page_id') ? undefined : json['status_page_id'],
        'status_page_subpage_id': !exists(json, 'status_page_subpage_id') ? undefined : json['status_page_subpage_id'],
    };
}

export function StaffImportStatusPageSubscribersRequestBodyToJSON(value?: StaffImportStatusPageSubscribersRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_addresses': value.email_addresses,
        'status_page_id': value.status_page_id,
        'status_page_subpage_id': value.status_page_subpage_id,
    };
}

