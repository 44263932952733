import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "reactflow";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { PathEdge } from "../common/types";
import { AddNodeButton } from "./AddNodeButton";

// CustomEdge is a custom edge component used in ReactFlow to render edges between
// nodes.
export const CustomEdge = ({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}: {
  id: string;
  source: string;
  target: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  data?: PathEdge;
}) => {
  const [edgePath, _, originalLabelY] = generatePath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    data,
  });

  // We want branch labels to always appear after the edge has curved towards its target.
  const labelX = targetX;
  const labelY = sourceX === targetX ? originalLabelY : originalLabelY + 25;

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          stroke: "#CACACE", // slate-200 - we should make this a CSS variable!
          strokeWidth: "2px",
        }}
      />
      {data && (
        <EdgeLabelRenderer>
          <EdgeLabel
            label={data.label}
            addButton={data.addButton}
            labelX={labelX}
            labelY={labelY}
            source={source}
            target={target}
            sourceY={sourceY}
            targetY={targetY}
          />
        </EdgeLabelRenderer>
      )}
    </>
  );
};

const generatePath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}: {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  data?: PathEdge;
}) => {
  if (data?.label === "End") {
    // We do something special here to generate a fixed-height edge with a label at the end
    return getSmoothStepPath({
      sourceX,
      sourceY,
      targetX: sourceX,
      targetY: sourceY + 125,
      centerY: sourceY + 125,
      borderRadius: 12,
    });
  }

  return getSmoothStepPath({
    sourceX,
    sourceY,
    // Place the horizontal part of the edge near the target: we don't know know
    // how tall the source node is compared to other nodes at the same rank, so we
    // put the center of the edge just above the top of the target, which we do
    // know will be the same as other nodes at that rank.
    //
    // The exception is if this is a simple vertical edge, in which case we want the center to be the center, since:
    // (a) nothing will get in between and screw it up; and
    // (b) it looks weird to have the label off-center.
    centerY: targetX === sourceX ? undefined : sourceY + 60,
    targetX,
    targetY,
    borderRadius: 12,
  });
};

const EdgeLabel = ({
  label,
  source,
  target,
  addButton,
  labelX,
  labelY,
  sourceY,
  targetY,
}: {
  label: string | null;
  labelX: number;
  labelY: number;
  source: string;
  target: string;
  sourceY: number;
  targetY: number;
  addButton:
    | "none"
    | "after-source"
    | "before-target"
    | "then-after-source"
    | "else-after-source";
}) => {
  // This is a mixture of maths and magic numbers to make the buttons look
  // (mostly) in the right place on the path.
  const addAboveY =
    label == null ? labelY : labelY - (labelY - sourceY) / 2 + 10;
  const addBelowY =
    label == null ? labelY : labelY + (targetY - labelY) / 2 - 15;

  // Whie the then/else-after-source buttons use the source node ID, they are
  // positioned like before-target, since they are placed after an if/else
  // branch.
  const buttonY = addButton === "after-source" ? addAboveY : addBelowY;

  return (
    <>
      {label && (
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
          }}
          className={tcx(
            "absolute",
            "w-fit rounded py-0.5 px-1",
            "nodrag nopan",
            "text-xs font-semibold",
            "bg-slate-100 text-content-primary",
          )}
        >
          {label}
        </div>
      )}
      {addButton === "none" ? null : (
        <AddNodeButton
          nodeId={
            // All the others are after source of some kind!
            addButton === "before-target" ? target : source
          }
          mode={
            addButton === "before-target"
              ? "before"
              : addButton === "after-source"
              ? "after"
              : addButton === "then-after-source"
              ? "after-then"
              : addButton === "else-after-source"
              ? "after-else"
              : assertUnreachable(addButton)
          }
          className="absolute"
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px, ${buttonY}px)`,
          }}
        />
      )}
    </>
  );
};
