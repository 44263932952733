import {
  IncidentStatusCategoryEnum,
  InternalStatusPage,
  InternalStatusPageIncident,
  InternalStatusPageStructure,
  ScopeNameEnum,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  ConfirmationDialog,
  ContentBox,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  Loader,
  SeverityBadge,
} from "@incident-ui";
import { partition } from "lodash";
import { Fragment, useState } from "react";
import { AllGoodBanner } from "src/components/status-pages/incidents/list/StatusPageIncidentListPage";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";

import { formatDurationInSecondsShort } from "../../../../utils/datetime";
import { getDurationInSeconds } from "../../../../utils/presenters";
import { TooltipButton } from "../shared/TooltipButton";
import { OverviewTabIds } from "./tabs";

export const IncidentsList = ({
  page,
  structure,
  overviewTab,
}: {
  page: InternalStatusPage;
  structure: InternalStatusPageStructure;
  overviewTab: OverviewTabIds;
}) => {
  const {
    data: { internal_status_page_incidents: incidents },
    error,
    isLoading,
  } = useAPI(
    "internalStatusPageListIncidents",
    { internalStatusPageId: page.id },
    { fallbackData: { internal_status_page_incidents: [] } },
  );

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const [active, resolved] = partition(
    incidents,
    (incident) =>
      incident.incident_status.category === IncidentStatusCategoryEnum.Active,
  );

  return (
    <>
      <div
        className={
          "flex flex-col gap-4 text-sm w-full 2xl:w-[calc(100%-300px)]"
        }
      >
        {active.length === 0 && overviewTab === OverviewTabIds.Now ? (
          <AllGoodBanner />
        ) : overviewTab === OverviewTabIds.Past && resolved.length === 0 ? (
          <>
            <Heading
              level={3}
              size="small"
              className="mr-2 !-mb-2 !font-medium"
            >
              Incidents
            </Heading>
            <div className="text-slate-600">
              Incidents pushed to this internal status page that have been
              resolved will appear here.
            </div>
          </>
        ) : (
          <>
            <Heading
              level={3}
              size="small"
              className="mr-2 !-mb-2 !font-medium"
            >
              Incidents
            </Heading>
            <div className="space-y-4 pb-16">
              {(overviewTab === OverviewTabIds.Now ? active : resolved).map(
                (incident) => (
                  <IncidentsListItem
                    key={incident.id}
                    page={page}
                    structure={structure}
                    incident={incident}
                  />
                ),
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const IncidentsListItem = ({
  page,
  structure,
  incident,
}: {
  page: InternalStatusPage;
  structure: InternalStatusPageStructure;
  incident: InternalStatusPageIncident;
}) => {
  const navigate = useOrgAwareNavigate();

  const [showDestroy, setShowDestroy] = useState(false);
  const { trigger: triggerDelete, isMutating } = useAPIMutation(
    "internalStatusPageListIncidents",
    { internalStatusPageId: page.id },
    async (apiClient) => {
      await apiClient.internalStatusPageDestroyIncident({
        id: incident.id,
      });
    },
  );

  const duration = getDurationInSeconds(incident);

  return (
    <>
      {showDestroy && (
        <ConfirmationDialog
          onConfirm={() => triggerDelete({})}
          onCancel={() => setShowDestroy(false)}
          title="Remove incident from internal status page"
          analyticsTrackingId={"internal-sp-confirm-hide-incident"}
          isOpen
          saving={isMutating}
          className="text-sm space-y-2"
          // At present this reuses the same scope as for external status pages. In
          // future, we might:
          // - have a separate scope and privilege for internal vs public pages, so the
          //   permissions can be configured separately
          // - implement per-status-page permissions
          //
          // For now we're not sure, so keeping things simple
          requiredScope={ScopeNameEnum.StatusPagesPublishUpdates}
          confirmButtonText="Remove"
        >
          <p>
            Are you sure you want to remove{" "}
            <span className="font-medium">
              {incident.reference}: {incident.name}
            </span>{" "}
            from the {page.name} internal status page?
          </p>
          <p>
            It will not be added to the page again, even if it matches your
            automation rules. If you change your mind, add it manually with{" "}
            <code>/incident statuspage</code>.
          </p>
        </ConfirmationDialog>
      )}

      <ContentBox className="w-full">
        <div className="p-3 truncate rounded-t-lg bg-surface-secondary flex items-center min-h-[48px] justify-between gap-2">
          <div className="font-medium truncate">
            {incident.reference}: {incident.name}
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-slate-600">
              Added by{" "}
              {incident.creator.user
                ? incident.creator.user.name
                : "automation rule"}
            </div>
            <TooltipButton
              iconName={IconEnum.Delete2}
              iconProps={{ size: IconSize.Medium }}
              title="Remove from page"
              onClick={() => setShowDestroy(true)}
            />
          </div>
        </div>
        <div className="divide-y divide-slate-200">
          <div className="text-content-tertiary flex items-center p-3 space-x-2">
            <SeverityBadge
              severity={incident.severity}
              size={BadgeSize.Small}
              naked
            />
            <IncidentStatusBadge
              status={incident.incident_status}
              size={BadgeSize.Small}
              naked
            />
            {duration && (
              <div className="flex items-center">
                <Icon id={IconEnum.Clock} className="mr-0.5" />
                <span>
                  {formatDurationInSecondsShort(duration, {
                    significantFigures: 1,
                  })}
                </span>
              </div>
            )}
            <AffectsBlock structure={structure} incident={incident} />
          </div>
          <div className="flex-none flex gap-2 p-3">
            <Button
              title="View on page"
              analyticsTrackingId={"internal-status-page-view-page-incident"}
              theme={ButtonTheme.Naked}
              icon={IconEnum.ExternalLink}
              openInNewTab
              href={`${page.page_url}/incidents/${incident.external_id}`}
            >
              View on page
            </Button>
            <Button
              title="View in dashboard"
              analyticsTrackingId={
                "internal-status-page-view-dashboard-incident"
              }
              theme={ButtonTheme.Naked}
              icon={IconEnum.Incident}
              onClick={() => navigate(`/incidents/${incident.external_id}`)}
            >
              View in dashboard
            </Button>
          </div>
        </div>
      </ContentBox>
    </>
  );
};

const AffectsBlock = ({
  structure,
  incident,
}: {
  structure: InternalStatusPageStructure;
  incident: InternalStatusPageIncident;
}) => {
  const affectedComponentIDs = new Set(
    incident.affected_components.map(({ id }) => id),
  );

  const impactedTopLevelComponents = structure.components.flatMap(
    ({ component, group }) => {
      if (component) {
        if (component.hidden) return [];
        if (!affectedComponentIDs.has(component.id)) return [];

        return [component.label];
      }

      if (group) {
        if (
          group.components.some(
            ({ id, hidden }) => !hidden && affectedComponentIDs.has(id),
          )
        ) {
          return [group.label];
        }

        return [];
      }

      return [];
    },
  );

  if (impactedTopLevelComponents.length === 0) return null;

  return (
    <div>
      Affects{" "}
      {joinSpansWithCommasAndConnectorWord(
        impactedTopLevelComponents.map((name, idx) => (
          <Fragment key={idx}>{name}</Fragment>
        )),
      )}
    </div>
  );
};
