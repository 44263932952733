/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldValuePayload,
    CustomFieldValuePayloadFromJSON,
    CustomFieldValuePayloadFromJSONTyped,
    CustomFieldValuePayloadToJSON,
} from './CustomFieldValuePayload';

/**
 * 
 * @export
 * @interface CustomFieldEntryPayload
 */
export interface CustomFieldEntryPayload {
    /**
     * ID of the custom field this entry is linked against
     * @type {string}
     * @memberof CustomFieldEntryPayload
     */
    custom_field_id: string;
    /**
     * If true, we won't create a manual_edit row for this entry. This should be used if a field hasn't been touched by a user (or a customer's API key)
     * @type {boolean}
     * @memberof CustomFieldEntryPayload
     */
    not_manually_edited?: boolean;
    /**
     * How to change the values of this custom field for this incident. 'append' is only supported for multi-value option or catalog-backed custom fields.
     * @type {string}
     * @memberof CustomFieldEntryPayload
     */
    update_mode?: CustomFieldEntryPayloadUpdateModeEnum;
    /**
     * List of values to associate with this entry. Use an empty array to unset the value of the custom field.
     * @type {Array<CustomFieldValuePayload>}
     * @memberof CustomFieldEntryPayload
     */
    values: Array<CustomFieldValuePayload>;
}

/**
* @export
* @enum {string}
*/
export enum CustomFieldEntryPayloadUpdateModeEnum {
    Merge = 'merge',
    Set = 'set',
    Remove = 'remove'
}

export function CustomFieldEntryPayloadFromJSON(json: any): CustomFieldEntryPayload {
    return CustomFieldEntryPayloadFromJSONTyped(json, false);
}

export function CustomFieldEntryPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldEntryPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_id': json['custom_field_id'],
        'not_manually_edited': !exists(json, 'not_manually_edited') ? undefined : json['not_manually_edited'],
        'update_mode': !exists(json, 'update_mode') ? undefined : json['update_mode'],
        'values': ((json['values'] as Array<any>).map(CustomFieldValuePayloadFromJSON)),
    };
}

export function CustomFieldEntryPayloadToJSON(value?: CustomFieldEntryPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_id': value.custom_field_id,
        'not_manually_edited': value.not_manually_edited,
        'update_mode': value.update_mode,
        'values': ((value.values as Array<any>).map(CustomFieldValuePayloadToJSON)),
    };
}

