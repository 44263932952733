import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import {
  Popover,
  PopoverBody,
  PopoverProps,
} from "@incident-ui/Popover/Popover";
import { PopoverSearch } from "@incident-ui/Popover/PopoverSearch";
import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import {
  SearchableDropdownEntries,
  SearchableDropdownEntry,
} from "./SearchableDropdown";

export const SearchableDynamicDropdown = <TItemType,>({
  loadEntries,
  onSelectItem,
  renderTriggerButton,
  emptyText,
  align = "start",
  extraItems,
}: {
  loadEntries: (
    inputValue: string,
  ) => Promise<SearchableDropdownEntry<TItemType>[]>;
  onSelectItem: (item: TItemType) => void;
  renderTriggerButton: (props: { onClick: () => void }) => React.ReactElement;
  emptyText: string;
  align?: PopoverProps["align"];
  extraItems?: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);

  const [entries, setEntries] = useState<SearchableDropdownEntry<TItemType>[]>(
    [],
  );
  const [loadingEntries, setLoadingEntries] = useState(false);

  const refetchEntries = useCallback(async () => {
    setLoadingEntries(true);
    const newEntries = await loadEntries(debouncedSearch);
    setEntries(newEntries);
    setLoadingEntries(false);
  }, [debouncedSearch, loadEntries, setEntries, setLoadingEntries]);

  useEffect(() => {
    refetchEntries();
  }, [refetchEntries]);

  const handleClose = () => {
    setSearch("");
    setIsOpen(false);
  };

  const handleSelect = (item: TItemType) => {
    setSearch("");
    onSelectItem(item);
    setIsOpen(false);
  };

  return (
    <>
      <Popover
        onInteractOutside={() => {
          setIsOpen(false);
        }}
        trigger={renderTriggerButton({ onClick: () => setIsOpen(true) })}
        className="max-h-[400px] min-h-[100px] !p-0 w-[350px]"
        sideOffset={-38}
        align={align}
        onOpenChange={(open) => {
          if (!open) {
            handleClose();
          }
        }}
        open={isOpen}
      >
        <>
          <PopoverSearch value={search} onChange={setSearch} />
          {/* List of groups */}
          <PopoverBody scroll>
            {loadingEntries ? (
              <div className="flex flex-col gap-2 px-2">
                <LoadingBar className="!h-[16px] w-[236px]" />
                <LoadingBar className="!h-[16px] w-[118px]" />
              </div>
            ) : (
              <SearchableDropdownEntries
                entries={entries}
                isSearching={!!search}
                handleSelect={handleSelect}
                emptyState={emptyText}
              />
            )}
            {extraItems}
          </PopoverBody>
        </>
      </Popover>
    </>
  );
};
