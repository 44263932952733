/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AIStaffCreateEvaluationBacktestRequestBody,
    AIStaffCreateEvaluationBacktestRequestBodyFromJSON,
    AIStaffCreateEvaluationBacktestRequestBodyToJSON,
    AIStaffCreateEvaluationBacktestResponseBody,
    AIStaffCreateEvaluationBacktestResponseBodyFromJSON,
    AIStaffCreateEvaluationBacktestResponseBodyToJSON,
    AIStaffListAIRequestsResponseBody,
    AIStaffListAIRequestsResponseBodyFromJSON,
    AIStaffListAIRequestsResponseBodyToJSON,
    AIStaffListAISpansResponseBody,
    AIStaffListAISpansResponseBodyFromJSON,
    AIStaffListAISpansResponseBodyToJSON,
    AIStaffListCopilotThreadsResponseBody,
    AIStaffListCopilotThreadsResponseBodyFromJSON,
    AIStaffListCopilotThreadsResponseBodyToJSON,
    AIStaffListEvaluationBacktestsResponseBody,
    AIStaffListEvaluationBacktestsResponseBodyFromJSON,
    AIStaffListEvaluationBacktestsResponseBodyToJSON,
    AIStaffListProcessorsResponseBody,
    AIStaffListProcessorsResponseBodyFromJSON,
    AIStaffListProcessorsResponseBodyToJSON,
    AIStaffListPromptHealthReportsResponseBody,
    AIStaffListPromptHealthReportsResponseBodyFromJSON,
    AIStaffListPromptHealthReportsResponseBodyToJSON,
    AIStaffListPromptNamesResponseBody,
    AIStaffListPromptNamesResponseBodyFromJSON,
    AIStaffListPromptNamesResponseBodyToJSON,
    AIStaffListToolsResponseBody,
    AIStaffListToolsResponseBodyFromJSON,
    AIStaffListToolsResponseBodyToJSON,
    AIStaffListVectorsResponseBody,
    AIStaffListVectorsResponseBodyFromJSON,
    AIStaffListVectorsResponseBodyToJSON,
    AIStaffRunPromptHealthReportRequestBody,
    AIStaffRunPromptHealthReportRequestBodyFromJSON,
    AIStaffRunPromptHealthReportRequestBodyToJSON,
    AIStaffShowCopilotInteractionThreadContextResponseBody,
    AIStaffShowCopilotInteractionThreadContextResponseBodyFromJSON,
    AIStaffShowCopilotInteractionThreadContextResponseBodyToJSON,
    AIStaffShowCopilotThreadResponseBody,
    AIStaffShowCopilotThreadResponseBodyFromJSON,
    AIStaffShowCopilotThreadResponseBodyToJSON,
    AIStaffShowEvaluationBacktestResponseBody,
    AIStaffShowEvaluationBacktestResponseBodyFromJSON,
    AIStaffShowEvaluationBacktestResponseBodyToJSON,
    AIStaffShowPromptHealthReportResponseBody,
    AIStaffShowPromptHealthReportResponseBodyFromJSON,
    AIStaffShowPromptHealthReportResponseBodyToJSON,
} from '../models';

export interface AIStaffCreateEvaluationBacktestRequest {
    createEvaluationBacktestRequestBody: AIStaffCreateEvaluationBacktestRequestBody;
}

export interface AIStaffListAIRequestsRequest {
    traceId?: string;
    prompt?: string;
    copilotInteractionId?: string;
    threadId?: string;
    investigationCheckId?: string;
    calledTool?: string;
}

export interface AIStaffListAISpansRequest {
    traceId?: string;
    type?: AIStaffListAISpansTypeEnum;
    name?: string;
    resourceId?: string;
}

export interface AIStaffListCopilotThreadsRequest {
    prompt?: string;
    tag?: string;
    score?: string;
    classification?: string;
    traceId?: string;
}

export interface AIStaffListVectorsRequest {
    type: string;
}

export interface AIStaffRunPromptHealthReportRequest {
    runPromptHealthReportRequestBody: AIStaffRunPromptHealthReportRequestBody;
}

export interface AIStaffShowCopilotInteractionThreadContextRequest {
    slackChannelId: string;
    threadTs: string;
}

export interface AIStaffShowCopilotThreadRequest {
    slackChannelId: string;
    threadTs: string;
}

export interface AIStaffShowEvaluationBacktestRequest {
    id: string;
}

export interface AIStaffShowPromptHealthReportRequest {
    id: string;
}

/**
 * 
 */
export class AIStaffApi extends runtime.BaseAPI {

    /**
     * Create a new backtest to evaluate investigation performance.
     * CreateEvaluationBacktest AI Staff
     */
    async aIStaffCreateEvaluationBacktestRaw(requestParameters: AIStaffCreateEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCreateEvaluationBacktestResponseBody>> {
        if (requestParameters.createEvaluationBacktestRequestBody === null || requestParameters.createEvaluationBacktestRequestBody === undefined) {
            throw new runtime.RequiredError('createEvaluationBacktestRequestBody','Required parameter requestParameters.createEvaluationBacktestRequestBody was null or undefined when calling aIStaffCreateEvaluationBacktest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_backtests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffCreateEvaluationBacktestRequestBodyToJSON(requestParameters.createEvaluationBacktestRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCreateEvaluationBacktestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new backtest to evaluate investigation performance.
     * CreateEvaluationBacktest AI Staff
     */
    async aIStaffCreateEvaluationBacktest(requestParameters: AIStaffCreateEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<AIStaffCreateEvaluationBacktestResponseBody> {
        const response = await this.aIStaffCreateEvaluationBacktestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show YAML evals for given AI requests
     * ListAIRequests AI Staff
     */
    async aIStaffListAIRequestsRaw(requestParameters: AIStaffListAIRequestsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListAIRequestsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.traceId !== undefined) {
            queryParameters['trace_id'] = requestParameters.traceId;
        }

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        if (requestParameters.copilotInteractionId !== undefined) {
            queryParameters['copilot_interaction_id'] = requestParameters.copilotInteractionId;
        }

        if (requestParameters.threadId !== undefined) {
            queryParameters['thread_id'] = requestParameters.threadId;
        }

        if (requestParameters.investigationCheckId !== undefined) {
            queryParameters['investigation_check_id'] = requestParameters.investigationCheckId;
        }

        if (requestParameters.calledTool !== undefined) {
            queryParameters['called_tool'] = requestParameters.calledTool;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/ai_requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListAIRequestsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show YAML evals for given AI requests
     * ListAIRequests AI Staff
     */
    async aIStaffListAIRequests(requestParameters: AIStaffListAIRequestsRequest, initOverrides?: RequestInit): Promise<AIStaffListAIRequestsResponseBody> {
        const response = await this.aIStaffListAIRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show spans for a given trace
     * ListAISpans AI Staff
     */
    async aIStaffListAISpansRaw(requestParameters: AIStaffListAISpansRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListAISpansResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.traceId !== undefined) {
            queryParameters['trace_id'] = requestParameters.traceId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource_id'] = requestParameters.resourceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/ai_spans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListAISpansResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show spans for a given trace
     * ListAISpans AI Staff
     */
    async aIStaffListAISpans(requestParameters: AIStaffListAISpansRequest, initOverrides?: RequestInit): Promise<AIStaffListAISpansResponseBody> {
        const response = await this.aIStaffListAISpansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists recent threads between Copilot and users
     * ListCopilotThreads AI Staff
     */
    async aIStaffListCopilotThreadsRaw(requestParameters: AIStaffListCopilotThreadsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListCopilotThreadsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        if (requestParameters.score !== undefined) {
            queryParameters['score'] = requestParameters.score;
        }

        if (requestParameters.classification !== undefined) {
            queryParameters['classification'] = requestParameters.classification;
        }

        if (requestParameters.traceId !== undefined) {
            queryParameters['trace_id'] = requestParameters.traceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/copilot_threads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListCopilotThreadsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists recent threads between Copilot and users
     * ListCopilotThreads AI Staff
     */
    async aIStaffListCopilotThreads(requestParameters: AIStaffListCopilotThreadsRequest, initOverrides?: RequestInit): Promise<AIStaffListCopilotThreadsResponseBody> {
        const response = await this.aIStaffListCopilotThreadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all evaluation backtests.
     * ListEvaluationBacktests AI Staff
     */
    async aIStaffListEvaluationBacktestsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListEvaluationBacktestsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_backtests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListEvaluationBacktestsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all evaluation backtests.
     * ListEvaluationBacktests AI Staff
     */
    async aIStaffListEvaluationBacktests(initOverrides?: RequestInit): Promise<AIStaffListEvaluationBacktestsResponseBody> {
        const response = await this.aIStaffListEvaluationBacktestsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all registered processors
     * ListProcessors AI Staff
     */
    async aIStaffListProcessorsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListProcessorsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/processors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListProcessorsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all registered processors
     * ListProcessors AI Staff
     */
    async aIStaffListProcessors(initOverrides?: RequestInit): Promise<AIStaffListProcessorsResponseBody> {
        const response = await this.aIStaffListProcessorsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show recent prompt health reports
     * ListPromptHealthReports AI Staff
     */
    async aIStaffListPromptHealthReportsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListPromptHealthReportsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/health_reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListPromptHealthReportsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show recent prompt health reports
     * ListPromptHealthReports AI Staff
     */
    async aIStaffListPromptHealthReports(initOverrides?: RequestInit): Promise<AIStaffListPromptHealthReportsResponseBody> {
        const response = await this.aIStaffListPromptHealthReportsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Lists all registered prompts in the system.
     * ListPromptNames AI Staff
     */
    async aIStaffListPromptNamesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListPromptNamesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/prompts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListPromptNamesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists all registered prompts in the system.
     * ListPromptNames AI Staff
     */
    async aIStaffListPromptNames(initOverrides?: RequestInit): Promise<AIStaffListPromptNamesResponseBody> {
        const response = await this.aIStaffListPromptNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all registered tools
     * ListTools AI Staff
     */
    async aIStaffListToolsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListToolsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/registered_tools`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListToolsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all registered tools
     * ListTools AI Staff
     */
    async aIStaffListTools(initOverrides?: RequestInit): Promise<AIStaffListToolsResponseBody> {
        const response = await this.aIStaffListToolsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show all vectors of a given type for an Organisation
     * ListVectors AI Staff
     */
    async aIStaffListVectorsRaw(requestParameters: AIStaffListVectorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListVectorsResponseBody>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling aIStaffListVectors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/vectors/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListVectorsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show all vectors of a given type for an Organisation
     * ListVectors AI Staff
     */
    async aIStaffListVectors(requestParameters: AIStaffListVectorsRequest, initOverrides?: RequestInit): Promise<AIStaffListVectorsResponseBody> {
        const response = await this.aIStaffListVectorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run a prompt health report
     * RunPromptHealthReport AI Staff
     */
    async aIStaffRunPromptHealthReportRaw(requestParameters: AIStaffRunPromptHealthReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.runPromptHealthReportRequestBody === null || requestParameters.runPromptHealthReportRequestBody === undefined) {
            throw new runtime.RequiredError('runPromptHealthReportRequestBody','Required parameter requestParameters.runPromptHealthReportRequestBody was null or undefined when calling aIStaffRunPromptHealthReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/health_reports/actions/run`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffRunPromptHealthReportRequestBodyToJSON(requestParameters.runPromptHealthReportRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Run a prompt health report
     * RunPromptHealthReport AI Staff
     */
    async aIStaffRunPromptHealthReport(requestParameters: AIStaffRunPromptHealthReportRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aIStaffRunPromptHealthReportRaw(requestParameters, initOverrides);
    }

    /**
     * Show the context for a given Copilot interaction
     * ShowCopilotInteractionThreadContext AI Staff
     */
    async aIStaffShowCopilotInteractionThreadContextRaw(requestParameters: AIStaffShowCopilotInteractionThreadContextRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowCopilotInteractionThreadContextResponseBody>> {
        if (requestParameters.slackChannelId === null || requestParameters.slackChannelId === undefined) {
            throw new runtime.RequiredError('slackChannelId','Required parameter requestParameters.slackChannelId was null or undefined when calling aIStaffShowCopilotInteractionThreadContext.');
        }

        if (requestParameters.threadTs === null || requestParameters.threadTs === undefined) {
            throw new runtime.RequiredError('threadTs','Required parameter requestParameters.threadTs was null or undefined when calling aIStaffShowCopilotInteractionThreadContext.');
        }

        const queryParameters: any = {};

        if (requestParameters.slackChannelId !== undefined) {
            queryParameters['slack_channel_id'] = requestParameters.slackChannelId;
        }

        if (requestParameters.threadTs !== undefined) {
            queryParameters['thread_ts'] = requestParameters.threadTs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/copilot_interaction_thread_context`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowCopilotInteractionThreadContextResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the context for a given Copilot interaction
     * ShowCopilotInteractionThreadContext AI Staff
     */
    async aIStaffShowCopilotInteractionThreadContext(requestParameters: AIStaffShowCopilotInteractionThreadContextRequest, initOverrides?: RequestInit): Promise<AIStaffShowCopilotInteractionThreadContextResponseBody> {
        const response = await this.aIStaffShowCopilotInteractionThreadContextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a Copilot thread
     * ShowCopilotThread AI Staff
     */
    async aIStaffShowCopilotThreadRaw(requestParameters: AIStaffShowCopilotThreadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowCopilotThreadResponseBody>> {
        if (requestParameters.slackChannelId === null || requestParameters.slackChannelId === undefined) {
            throw new runtime.RequiredError('slackChannelId','Required parameter requestParameters.slackChannelId was null or undefined when calling aIStaffShowCopilotThread.');
        }

        if (requestParameters.threadTs === null || requestParameters.threadTs === undefined) {
            throw new runtime.RequiredError('threadTs','Required parameter requestParameters.threadTs was null or undefined when calling aIStaffShowCopilotThread.');
        }

        const queryParameters: any = {};

        if (requestParameters.slackChannelId !== undefined) {
            queryParameters['slack_channel_id'] = requestParameters.slackChannelId;
        }

        if (requestParameters.threadTs !== undefined) {
            queryParameters['thread_ts'] = requestParameters.threadTs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/copilot_thread`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowCopilotThreadResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a Copilot thread
     * ShowCopilotThread AI Staff
     */
    async aIStaffShowCopilotThread(requestParameters: AIStaffShowCopilotThreadRequest, initOverrides?: RequestInit): Promise<AIStaffShowCopilotThreadResponseBody> {
        const response = await this.aIStaffShowCopilotThreadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single backtest including all its entries.
     * ShowEvaluationBacktest AI Staff
     */
    async aIStaffShowEvaluationBacktestRaw(requestParameters: AIStaffShowEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowEvaluationBacktestResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffShowEvaluationBacktest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_backtests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowEvaluationBacktestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single backtest including all its entries.
     * ShowEvaluationBacktest AI Staff
     */
    async aIStaffShowEvaluationBacktest(requestParameters: AIStaffShowEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<AIStaffShowEvaluationBacktestResponseBody> {
        const response = await this.aIStaffShowEvaluationBacktestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a prompt health report
     * ShowPromptHealthReport AI Staff
     */
    async aIStaffShowPromptHealthReportRaw(requestParameters: AIStaffShowPromptHealthReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowPromptHealthReportResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffShowPromptHealthReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/health_reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowPromptHealthReportResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a prompt health report
     * ShowPromptHealthReport AI Staff
     */
    async aIStaffShowPromptHealthReport(requestParameters: AIStaffShowPromptHealthReportRequest, initOverrides?: RequestInit): Promise<AIStaffShowPromptHealthReportResponseBody> {
        const response = await this.aIStaffShowPromptHealthReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum AIStaffListAISpansTypeEnum {
    Prompt = 'prompt',
    Processor = 'processor',
    CompletionRequest = 'completion_request',
    ToolCall = 'tool_call'
}
