import { usePylon } from "@bolasim/react-use-pylon";
import { ErrorMessage } from "@incident-ui";
import {
  Avatar,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Tooltip,
  Txt,
} from "@incident-ui";
import _ from "lodash";
import React, { useState } from "react";
import {
  Identity,
  IdentitySelfResponseBody,
  SlackInfoUserReconnectionReasonEnum as ReconnectionReasonEnum,
  SlackInfoUserTokenBehavioursEnum as BehavioursEnum,
  UserSlackRoleEnum as SlackRoleEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { useQueryParams } from "../../../../utils/query-params";
import { SettingsSubHeading } from "../../SettingsSubHeading";
import { ConnectModal } from "./ConnectModal";
import { DisconnectModal } from "./DisconnectModal";

interface BehaviourInfo {
  // If no description is set, we'll hide this from the list
  description: string | null;
  tooltip?: React.ReactNode;
  isPrivate: boolean;
  enterpriseGridBehaviour?: boolean;
}

const BEHAVIOUR_INFO: { [key in BehavioursEnum]: BehaviourInfo } = {
  [BehavioursEnum.CreatePublicChannel]: {
    description: "Create public incident channels.",
    tooltip:
      "The connected user will be the 'channel owner', and therefore added to all new incidents. We recommend creating a separate user account just for incident.io, to avoid confusion.",
    isPrivate: false,
  },
  [BehavioursEnum.CreatePrivateChannel]: {
    description: "Create private incident channels.",
    tooltip:
      "The connected user will be the 'channel owner', and therefore added to all private incidents. We recommend creating a separate user account just for incident.io, to avoid confusion.",
    isPrivate: true,
  },
  [BehavioursEnum.ArchivePublicChannel]: {
    description: "Archive incident channels.",
    tooltip:
      "To archive private incident channels, the connected user must be a Slack Workspace Owner, and will be invited to private incidents to archive them.",
    isPrivate: false,
  },
  [BehavioursEnum.ArchivePrivateChannel]: {
    description: null, // it's easier to just explain this once.
    isPrivate: true,
  },
  [BehavioursEnum.UnarchivePublicChannel]: {
    description: "Unarchive incident channels, to load the timeline.",
    tooltip:
      "We'll do this to store messages from old incidents, and to import the timeline of retrospective incidents. We'll archive the channel again as soon as we're done.",
    isPrivate: false,
  },
  [BehavioursEnum.UnarchivePrivateChannel]: {
    description: null, // we don't actually do this for the moment
    isPrivate: true,
  },
  [BehavioursEnum.RemovePrivateChannelMember]: {
    description:
      "Remove users from private incident channels when their access is revoked.",
    tooltip:
      "If the connected user is a Slack Owner, they'll be invited to the incident channel so they can remove other channel members. We recommend creating a separate user account just for incident.io.",
    isPrivate: true,
  },
  [BehavioursEnum.AddChannelToMultipleWorkspaces]: {
    description:
      "Share an incident channel to multiple workspaces in Enterprise Grid.",
    isPrivate: false,
    enterpriseGridBehaviour: true,
  },
  [BehavioursEnum.ManageUserGroup]: {
    description: "Manage Slack user groups.",
    tooltip:
      "To create or update Slack user groups that can be synced with whoever is on call in a schedule, without giving access to user groups management to everyone in your workspace. The connected user needs to be a Slack Owner/Admin according to your workspace settings.",
    isPrivate: false,
  },
};

export const UserTokenForm = (): React.ReactElement | null => {
  const { identity, fetchIdentity } = useIdentity();

  if (
    !identityHasSlackInfo(identity) ||
    !identity.slack_info.user_token_behaviours
  ) {
    return null;
  }

  return (
    <UserTokenFormInner identity={identity} refetchIdentity={fetchIdentity} />
  );
};

type IdentityWithSlackInfo = Identity & Required<Pick<Identity, "slack_info">>;

// This is a lil helper that tells TypeScript that we're runtime-checking the type.
//
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
const identityHasSlackInfo = (
  identity: Identity,
): identity is IdentityWithSlackInfo => identity.slack_info !== undefined;

enum ModalState {
  Closed = "closed",
  Connect = "connect",
  Disconnect = "disconnect",
}

// The redirect flow puts some error messages in the URL. Most of these we don't
// want to expose to the user: they're things like 'the state param got screwed
// up' or 'we didn't get the extra scopes we want'.
const QUERY_ERRORS = {
  "user-token-bad-role":
    "You must connect a Slack Workspace Admin or Owner. Please try again.",
};

const UserTokenFormInner = ({
  identity,
  refetchIdentity,
}: {
  identity: IdentityWithSlackInfo;
  refetchIdentity: () => Promise<IdentitySelfResponseBody | undefined>;
}): React.ReactElement | null => {
  const slackInfo = identity.slack_info;

  const { data: enterpriseSettingsData } = useAPI(
    "settingsGetSlackEnterpriseSettings",
    undefined,
  );
  const queryParams = useQueryParams();
  const queryError = queryParams.get("message");
  const renderedError =
    queryError == null ? null : QUERY_ERRORS[queryError] ? (
      <ErrorMessage message={QUERY_ERRORS[queryError]} />
    ) : (
      <GenericErrorMessage />
    );

  const {
    user_token_behaviours: behaviours,
    user_token_owner: currentTokenOwner,
  } = slackInfo;

  const [modalState, setModalState] = useState(ModalState.Closed);

  const isAdmin = currentTokenOwner?.slack_role === SlackRoleEnum.Admin;
  const userReconnectionReason = slackInfo.user_reconnection_reason;
  const needOwner =
    behaviours.some((behaviour) => BEHAVIOUR_INFO[behaviour].isPrivate) ||
    userReconnectionReason === ReconnectionReasonEnum.InsufficientRole;

  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  const reconnectButton = (
    <Button
      onClick={() => setModalState(ModalState.Connect)}
      analyticsTrackingId="reconnect-slack-user"
    >
      Reconnect
    </Button>
  );

  const connectedUser = currentTokenOwner ? (
    <>
      <Avatar
        name={currentTokenOwner.name}
        url={currentTokenOwner.avatar_url}
        size={IconSize.Medium}
        className="inline"
      />{" "}
      <span className="font-semibold inline">{currentTokenOwner.name}</span>
    </>
  ) : null;

  return (
    <>
      <DisconnectModal
        isOpen={modalState === ModalState.Disconnect}
        onClose={() => setModalState(ModalState.Closed)}
        refetchIdentity={refetchIdentity}
      />
      <ConnectModal
        isOpen={modalState === ModalState.Connect}
        onClose={() => setModalState(ModalState.Closed)}
        identity={identity}
      />
      <ContentBox className="p-6 flex items-start gap-2">
        <div className="grow">
          <SettingsSubHeading title="Privileged Slack access" />
          <div className="space-y-2 text-sm text-slate-700">
            <Txt>
              {currentTokenOwner ? (
                <>
                  If the incident.io Slack integration is unable to carry out
                  one of the following actions as the bot due to not having the
                  required permissions, we will instead use {connectedUser}
                  &apos;s Slack account.
                </>
              ) : (
                <>
                  <Txt>
                    The incident.io Slack integration uses an app bot token to
                    take actions in your Slack workspace, but some Slack APIs
                    are only useable with a Slack user token (an authorised
                    connection on the behalf of a user, not an app).
                  </Txt>
                  <Txt>
                    If you&apos;d like to enable flows like unarchiving incident
                    channels or removing users from private incident channels
                    when their access is revoked, then connect an admin or owner
                    account. When connecting, we&apos;ll ask for the following
                    scopes:
                  </Txt>
                </>
              )}
            </Txt>

            <ul className="list-disc ml-4 mt-2 space-y-1">
              {_.compact(
                Object.keys(BEHAVIOUR_INFO).map((behaviour) => {
                  const { description, tooltip, enterpriseGridBehaviour } =
                    BEHAVIOUR_INFO[behaviour];
                  if (!description) return undefined;
                  if (
                    enterpriseGridBehaviour &&
                    !enterpriseSettingsData?.slack_enterprise_settings
                      .is_slack_enterprise_installed
                  ) {
                    return undefined;
                  }

                  return (
                    <li key={behaviour}>
                      <div className="flex items-center">
                        {description}
                        {tooltip && <Tooltip content={tooltip} side="right" />}
                      </div>
                    </li>
                  );
                }),
              )}
            </ul>

            <div className="!mt-4">
              <Button
                theme={ButtonTheme.Secondary}
                analyticsTrackingId="privileged-slack-access-help"
                onClick={() => showArticle("7428307766")}
                icon={IconEnum.Help}
              >
                Learn more
              </Button>
            </div>
          </div>
          {renderedError}
          {needOwner && isAdmin ? (
            <Callout theme={CalloutTheme.Info} className="mt-4">
              <div className="space-y-2 pb-1">
                <Txt>
                  The current token is for a Slack admin, but some actions
                  require a workspace owner. Please change{" "}
                  {currentTokenOwner?.name} to be a workspace owner, or connect
                  a different user.{" "}
                  <Button
                    analyticsTrackingId={
                      "privileged-slack-access-need-owner-help"
                    }
                    onClick={() => showArticle("7953827636")}
                    theme={ButtonTheme.Link}
                  >
                    Learn more
                  </Button>
                  .
                </Txt>
                {reconnectButton}
              </div>
            </Callout>
          ) : userReconnectionReason !== ReconnectionReasonEnum.Empty ? (
            <Callout theme={CalloutTheme.Warning} className="mt-4">
              <div className="space-y-2 pb-1">
                <Txt>
                  The connected Slack admin or owner account has been
                  deactivated, or revoked our access.
                </Txt>
                {reconnectButton}
              </div>
            </Callout>
          ) : null}
        </div>
        <Button
          theme={ButtonTheme.Primary}
          onClick={() =>
            setModalState(
              currentTokenOwner ? ModalState.Disconnect : ModalState.Connect,
            )
          }
          analyticsTrackingId={
            currentTokenOwner ? "disconnect-slack-user" : "connect-slack-user"
          }
        >
          {currentTokenOwner ? "Disconnect" : "Connect"}
        </Button>
      </ContentBox>
    </>
  );
};
