import {
  EscalationPathsGenerateExportRequestBody,
  EscalationPathsGenerateExportRequestBodyModeEnum,
  ManagedResourceResourceTypeEnum as ResourceTypeEnum,
  ManagementMeta,
} from "@incident-io/api";
import { CopyToTerraformDrawer } from "@incident-shared/management-meta/CopyToTerraformDrawer";
import { defaultManagementMeta } from "@incident-shared/management-meta/utils";
import { Loader } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import {
  escalationPathFormDataNodesToPath,
  escalationPathWorkingHoursToPayload,
} from "../common/marshall";
import { EscalationPathFormData } from "../common/types";

const buildConfigPayload = (
  escalationPathFormData: EscalationPathFormData,
): EscalationPathsGenerateExportRequestBody["escalation_path"] => {
  return {
    name: escalationPathFormData.name,
    path: escalationPathFormDataNodesToPath(
      escalationPathFormData,
      escalationPathFormData.firstNodeId,
    ),
    working_hours: escalationPathWorkingHoursToPayload(
      escalationPathFormData.working_hours,
    ),
  };
};

export const EscalationPathCopyToTerraformDrawer = ({
  formMethods,
  isOpen,
  onClose,
  resourceID,
  managementMeta,
}: {
  formMethods: UseFormReturn<EscalationPathFormData>;
  isOpen: boolean;
  onClose: () => void;
  resourceID?: string;
  managementMeta?: ManagementMeta;
}): React.ReactElement => {
  const formState = formMethods.watch();

  let path: EscalationPathsGenerateExportRequestBody["escalation_path"] | null =
    null;
  let err: Error | null = null;
  try {
    path = buildConfigPayload(formState);
  } catch (e) {
    err = e as Error;
  }

  const {
    data: terraformData,
    isLoading: terraformLoading,
    error: terraformError,
  } = useAPI(isOpen && path ? "escalationPathsGenerateExport" : null, {
    generateExportRequestBody: {
      id: resourceID,
      escalation_path: path ?? { name: "", path: [] },
      mode: EscalationPathsGenerateExportRequestBodyModeEnum.Terraform,
    },
  });

  const terraform = terraformData?._export;

  if (terraformLoading) {
    return <Loader />;
  }

  if (err && isOpen) {
    return (
      <ErrorModal
        title="Your escalation path isn't valid"
        description="Please fix the errors in your escalation path before exporting it to Terraform."
        error={err}
        onClose={onClose}
      />
    );
  }

  return (
    <CopyToTerraformDrawer
      isOpen={isOpen}
      onClose={onClose}
      terraform={terraform}
      terraformIsLoading={terraformLoading}
      terraformError={terraformError}
      resourceType={ResourceTypeEnum.EscalationPath}
      managementMeta={managementMeta || defaultManagementMeta()}
      resourceID={resourceID}
      resourceName={formState.name}
    />
  );
};
