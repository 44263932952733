import { Route, Routes } from "react-router";

import { VectorsListPage } from "./VectorsListPage";

export const VectorsRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<VectorsListPage />} />
    </Routes>
  );
};
