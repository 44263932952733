import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Tooltip, TooltipAlign } from "@incident-ui/Tooltip/Tooltip";
import { tcx } from "src/utils/tailwind-classes";

type BasePopoverItemProps = {
  className?: string;
  onClick?: (e: Event) => void;
  to?: string;
  openInNewTab?: boolean;
  type?: "submit" | never;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  showContinueChevron?: boolean;
  icon?: IconEnum;
  iconProps?: Omit<React.ComponentProps<typeof Icon>, "id">;
  disabled?: boolean;
  noHover?: boolean;
  analyticsTrackingId?: string | null;
  analyticsTrackingMetadata?: { [key: string]: string | boolean | number };
  children: React.ReactNode;
  tooltipContent?: React.ReactNode;
  tooltipSide?: "top" | "right" | "bottom" | "left";
  tooltipDelayDuration?: number;
  tooltipAlign?: TooltipAlign;
};

type PopoverItemProps = BasePopoverItemProps;

export const PopoverItem = ({
  analyticsTrackingId = null,
  analyticsTrackingMetadata,
  className,
  children,
  to,
  type,
  openInNewTab,
  onClick,
  prefix,
  suffix,
  showContinueChevron,
  icon,
  iconProps,
  disabled,
  noHover,
  tooltipContent,
  tooltipSide,
  tooltipDelayDuration,
  tooltipAlign,
}: PopoverItemProps) => {
  return (
    <Tooltip
      content={tooltipContent}
      side={tooltipSide}
      delayDuration={tooltipDelayDuration}
      align={tooltipAlign}
    >
      <Button
        theme={ButtonTheme.Unstyled}
        className={tcx(
          "flex w-full items-center text-sm text-left font-normal group px-3 py-2 gap-2 cursor-default outline-none rounded",
          !disabled &&
            !noHover &&
            "hover:bg-surface-secondary cursor-pointer focus:bg-surface-secondary",
          disabled ? "text-content-tertiary" : "text-content-primary",
          className,
        )}
        type={type}
        onClick={
          onClick ? (e: unknown) => onClick(e as unknown as Event) : undefined
        }
        tabIndex={0}
        analyticsTrackingId={analyticsTrackingId}
        analyticsTrackingMetadata={analyticsTrackingMetadata}
        href={to}
        openInNewTab={openInNewTab}
        disabled={disabled}
      >
        {(!!icon || !!prefix) && (
          <>
            {icon ? (
              <Icon id={icon} size={IconSize.Small} {...iconProps} />
            ) : (
              prefix
            )}
          </>
        )}
        {children}
        {suffix && <div className="flex-center-y ml-auto">{suffix}</div>}
        {showContinueChevron && (
          <Icon
            id={IconEnum.ChevronRight}
            className="ml-auto text-content-tertiary"
          />
        )}
      </Button>
    </Tooltip>
  );
};

type PopoverItemGroupProps = {
  label?: React.ReactNode;
  icon?: IconEnum;
  children: React.ReactNode;
  className?: string;
};

export const PopoverItemGroup = ({
  label,
  icon,
  children,
  className,
}: PopoverItemGroupProps): React.ReactElement => {
  return (
    <div
      className={tcx(
        "border-b border-stroke pb-1 last:pb-0 mb-1 last:mb-0 last:border-b-0 -mx-1 px-1 flex flex-col",
        className,
      )}
    >
      {!!label && (
        <div className="text-xs font-medium text-content-tertiary pt-2 pb-0.5 px-3 flex-center-y justify-between">
          <span>{label}</span>
          {icon && <Icon id={icon} size={IconSize.Small} />}
        </div>
      )}
      {children}
    </div>
  );
};
