/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertInsightsDatapoint
 */
export interface AlertInsightsDatapoint {
    /**
     * The date this datapoint represents
     * @type {Date}
     * @memberof AlertInsightsDatapoint
     */
    date: Date;
    /**
     * Whether this datapoint is for the current or previous period
     * @type {string}
     * @memberof AlertInsightsDatapoint
     */
    period: AlertInsightsDatapointPeriodEnum;
    /**
     * The unit of the metric
     * @type {string}
     * @memberof AlertInsightsDatapoint
     */
    unit?: AlertInsightsDatapointUnitEnum;
    /**
     * The value of the metric
     * @type {number}
     * @memberof AlertInsightsDatapoint
     */
    value: number;
}

/**
* @export
* @enum {string}
*/
export enum AlertInsightsDatapointPeriodEnum {
    Current = 'current',
    Previous = 'previous'
}/**
* @export
* @enum {string}
*/
export enum AlertInsightsDatapointUnitEnum {
    Alert = 'alert',
    Incident = 'incident',
    Hour = 'hour',
    Percent = '%'
}

export function AlertInsightsDatapointFromJSON(json: any): AlertInsightsDatapoint {
    return AlertInsightsDatapointFromJSONTyped(json, false);
}

export function AlertInsightsDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertInsightsDatapoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'period': json['period'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'value': json['value'],
    };
}

export function AlertInsightsDatapointToJSON(value?: AlertInsightsDatapoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        'period': value.period,
        'unit': value.unit,
        'value': value.value,
    };
}

