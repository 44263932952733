import {
  ScheduleConfigPayload,
  ScheduleEntry,
  ScheduleLayer,
  ScopeNameEnum,
} from "@incident-io/api";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { endTimeForTimelinePeriod } from "@incident-shared/schedules/ScheduleOverview/common/types";
import { ScheduleOverviewHeader } from "@incident-shared/schedules/ScheduleOverview/ScheduleOverviewHeader";
import {
  makeQueryParams,
  useScheduleTimeWindowReducer,
} from "@incident-shared/schedules/ScheduleOverview/scheduleTimeWindowReducer";
import { AutoSizer } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/CollapsableContainer";
import { TimelineSection } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineSectionV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ErrorMessage,
  GenericErrorMessage,
  Heading,
  IconEnum,
  Loader,
  LoadingBar,
  Spinner,
  Txt,
} from "@incident-ui";
import {
  Drawer,
  DrawerProps,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { SpinnerTheme } from "@incident-ui/Spinner/Spinner";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { roundToNearestMinutes } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Sherlock from "sherlockjs";
import { Form } from "src/components/@shared/forms";
import {
  getDefaultOverrideValues,
  parseFormData,
  parseOverride,
} from "src/components/legacy/on-call/schedules/overrides/marshall";
import { OverrideUserInput } from "src/components/legacy/on-call/schedules/overrides/UserInput";
import {
  Schedule,
  ScheduleOverride,
  useClient,
  UserWithRoles,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { DurationEnum, formatDurationInSeconds } from "src/utils/datetime";
import { useMutation } from "src/utils/fetchData";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useNow } from "src/utils/use-now";
import { useRevalidate } from "src/utils/use-revalidate";
import useSWRImmutable from "swr/immutable";
import { useDebounce } from "use-debounce";
import { useLocalStorage } from "use-hooks";

import {
  getCurrentlyActiveRotas,
  getUpcomingRotaChanges,
} from "../common/util";

export type OverrideFormData = {
  start_at: Date;
  end_at: Date;
  user_id: string;
  rotation_id: string;
  layer_id: string;
  natural_language: string;
};

export type OverrideData = CreateEditFormProps<ScheduleOverride> & {
  rotationId?: string;
  layerId?: string;
  startAt?: Date;
  endAt?: Date;
  userId?: string;
};

export const OverrideCreateEditDrawer = (
  props: CreateEditFormProps<string> & {
    rotationId?: string;
    layerId?: string;
    startAt?: Date;
    endAt?: Date;
    userId?: string;
    scheduleId: string;
    onClose: () => void;
  },
) => {
  const { scheduleId, onClose } = props;

  const sharedDrawerProps: Pick<DrawerProps, "onClose" | "width"> = {
    onClose,
    width: "large",
  };

  const {
    data: scheduleResp,
    isLoading: scheduleIsLoading,
    error: scheduleError,
  } = useAPI("schedulesShow", {
    id: scheduleId,
  });

  const overrideId = props.mode === Mode.Edit ? props.initialData : undefined;
  const {
    data: overrideResponse,
    isLoading: overrideIsLoading,
    error: overrideError,
  } = useAPI(overrideId ? "schedulesShowOverride" : null, {
    id: overrideId ?? "",
  });

  const error = overrideError || scheduleError;
  if (error) {
    return (
      <Drawer {...sharedDrawerProps}>
        <GenericErrorMessage error={error} />
      </Drawer>
    );
  }

  if (scheduleIsLoading || !scheduleResp || overrideIsLoading) {
    return (
      <Drawer {...sharedDrawerProps}>
        <Loader />
      </Drawer>
    );
  }

  return (
    <OverrideCreateEditDrawerInner
      schedule={scheduleResp?.schedule}
      {...sharedDrawerProps}
      {...props}
      {...(overrideResponse
        ? {
            mode: Mode.Edit,
            initialData: overrideResponse.schedule_override,
          }
        : {
            mode: Mode.Create,
            initialData: undefined,
          })}
    />
  );
};

const OverrideCreateEditDrawerInner = ({
  schedule,
  onClose,
  isInBackground,
  ...editCreateProps
}: {
  schedule: Schedule;
  onClose: () => void;
  isInBackground?: boolean;
} & OverrideData) => {
  return (
    <Drawer
      onClose={onClose}
      isInBackground={isInBackground}
      width="full"
      className="flex flex-col"
    >
      <DrawerTitle
        title={
          (editCreateProps.mode === Mode.Create ? "Create" : "Edit") +
          " override"
        }
        onClose={onClose}
        closeIcon={IconEnum.Close}
        sticky
        compact
        theme={DrawerTitleTheme.Bordered}
      />
      <OverrideCreateEditDrawerForm
        schedule={schedule}
        onClose={onClose}
        {...editCreateProps}
      />
    </Drawer>
  );
};

const OverrideCreateEditDrawerForm = ({
  mode,
  initialData,
  schedule,
  rotationId,
  layerId,
  startAt,
  endAt,
  onClose,
  userId,
}: OverrideData & {
  schedule: Schedule;
  onClose: () => void;
}) => {
  const apiClient = useClient();
  const showToast = useToast();
  const resizerRef = useRef<HTMLDivElement | null>(null);
  const { identity, isImpersonating } = useIdentity();

  // Round down to the nearest minutes when calculating a sensible now.
  const now = useNow(schedule.timezone).startOf("minute");

  const [state, dispatch] = useScheduleTimeWindowReducer(now);

  const currentEndTime = endTimeForTimelinePeriod({
    from: state.startTime,
    timePeriod: state.timePeriodOption,
  });

  const defaultValues =
    mode === Mode.Edit && initialData
      ? parseOverride(initialData, now)
      : getDefaultOverrideValues({
          overrideData: {
            rotationId,
            layerId,
            startAt,
            endAt,
            initialData,
            mode,
            userId,
          },
          identity,
          schedule,
          now,
        });
  if (isImpersonating) {
    defaultValues.user_id = undefined;
  }

  const formMethods = useForm<OverrideFormData>({ defaultValues });

  const refetchSchedules = useRevalidate([
    "schedulesList",
    "schedulesListEntries",
    "schedulesListForUser",
    "schedulesShow",
  ]);

  const mutationOptsProps = {
    onSuccess: async () => {
      showToast({
        theme: ToastTheme.Success,
        title: `Override successfully ${
          mode === Mode.Create ? "created" : "updated"
        }`,
        toastSide: ToastSideEnum.Bottom,
      });
      onClose();
    },
    setError: formMethods.setError,
  };

  const [
    createOverride,
    { genericError: createOverrideGenericError, saving: createSaving },
  ] = useMutation(async (formData: OverrideFormData) => {
    await apiClient.schedulesCreateOverride({
      createOverrideRequestBody: parseFormData(schedule.id, formData),
    });
    await refetchSchedules();
  }, mutationOptsProps);

  const [
    updateOverride,
    { genericError: updateOverrideGenericError, saving: updateSaving },
  ] = useMutation(async (formData: OverrideFormData) => {
    await apiClient.schedulesUpdateOverride({
      // We only ever call this when the initial data is defined.
      id: initialData?.id as string,
      updateOverrideRequestBody: parseFormData(schedule.id, formData),
    });
    await refetchSchedules();
  }, mutationOptsProps);

  const [deleteOverride, { saving: deleteSaving }] = useMutation(
    async () => {
      await apiClient.schedulesArchiveOverride({
        id: initialData?.id as string,
      });
      await refetchSchedules();
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: `Override deleted`,
          toastSide: ToastSideEnum.Bottom,
        });
        onClose();
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Something went wrong! Please try again.",
        });
      },
    },
  );

  // We reuse these in multiple places so declare them here.
  const [
    overrideAssignee,
    overrideStartAt,
    overrideEndAt,
    selectedRotationID,
    selectedLayerID,
    naturalLanguage,
  ] = formMethods.watch([
    "user_id",
    "start_at",
    "end_at",
    "rotation_id",
    "layer_id",
    "natural_language",
  ]);
  // Extract the currently selected start at so we can use it to warn if it's in
  // the past.
  const currentStartAt = DateTime.fromJSDate(overrideStartAt);
  const [from, until] = makeQueryParams(state, schedule.timezone);

  // Convert to Luxon
  const overrideStartAtAsDatetime = DateTime.fromJSDate(
    overrideStartAt,
  ).setZone(schedule.timezone);
  const overrideEndsAtAsDateTime = DateTime.fromJSDate(overrideEndAt).setZone(
    schedule.timezone,
  );
  const overrideDuration = overrideEndsAtAsDateTime.diff(
    overrideStartAtAsDatetime,
  );

  // We always want to preview _at least_ the full override duration, since
  // we're using the previewed entries to calculate the overall override length.
  const previewWindowStart = DateTime.min(
    DateTime.fromISO(from),
    overrideStartAtAsDatetime,
  );
  const previewWindowEnd = DateTime.max(
    DateTime.fromISO(until),
    overrideEndsAtAsDateTime,
  );

  // Use 3m ago as the marker for 'now' so that users
  // can still edit "just passed" overrides
  const endsInPast = overrideEndsAtAsDateTime < now.minus(3 * 1000 * 60);

  // We want to fetch the schedulePreviewEntries before the override is applied
  const [originalPreviewInput] = useDebounce(
    {
      config: schedule.config as unknown as ScheduleConfigPayload,
      from: previewWindowStart.toISO(),
      until: previewWindowEnd.toISO(),
      schedule_id: schedule.id,
      overrides: [],
    },
    500,
    { equalityFn: _.isEqual },
  );
  const { data: originalSchedulesResp, isLoading: originalSchedulesIsLoading } =
    useAPI("schedulesPreviewEntries", {
      previewEntriesRequestBody: originalPreviewInput,
    });

  const {
    data: previewSchedulesResp,
    isLoading: previewSchedulesIsLoading,
    mutate: previewScheduleMutate,
    // isValidating: isValidatingPreview, -> we're not using this right now but will imminently.
    // We don't assert on errors just so that we show an empty preview in those cases.
  } = useAPI(
    "schedulesPreviewEntries",
    {
      previewEntriesRequestBody: {
        config: schedule.config as unknown as ScheduleConfigPayload,
        from: previewWindowStart.toISO(),
        until: previewWindowEnd.toISO(),
        schedule_id: schedule.id,
        overrides: [
          {
            start_at: overrideStartAt,
            end_at: overrideEndAt,
            user_id: overrideAssignee,
            rotation_id: selectedRotationID,
            layer_id: selectedLayerID,
          },
        ],
      },
    },
    {
      // We rely on the call to previewScheduleMutate to trigger the preview
      // recalculation, so we don't want to revalidate automatically on stale
      // data. We do this because the preview body changes rapidly as the user
      // interacts with the form, so we want to control revalidation with our
      // debounced call which relies on a predictable checksum.
      //
      // Note that we debounce the call to previewScheduleMutate, so we don't
      // hammer the preview API
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  );
  const [previewIsLoading] = useDebounce(previewSchedulesIsLoading, 1000);

  const [selectedTimezones, setSelectedTimezones] = useLocalStorage<string[]>(
    `${schedule.id}-selected-timezones`,
    [schedule.timezone],
  );

  // We can't marshall directly from formMethods.formState because that is super sad and doesn't always update.
  const [formChecksum] = useDebounce(
    overrideAssignee +
      overrideStartAt +
      overrideEndAt +
      selectedRotationID +
      selectedLayerID,
    1000,
  );
  useEffect(() => {
    previewScheduleMutate();
  }, [formChecksum, previewScheduleMutate]);

  const onSubmit = async (data: OverrideFormData) => {
    if (!data.user_id) {
      formMethods.setError("user_id", {
        type: "required",
        message: "Please select a user",
      });
      return;
    }

    // If you've changed the user, we actually just create a new override, as
    // we don't let you edit the user of an existing override.
    const hasChangedUser = data.user_id !== initialData?.user_id;
    if (mode === Mode.Edit && !hasChangedUser) {
      await updateOverride(data);
    } else {
      await createOverride(data);
    }
    formMethods.reset();
  };

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  if (!schedule.config) {
    throw new Error("expected schedule config on native schedule");
  }

  const allRotas = useMemo(() => {
    return schedule.config?.rotations || [];
  }, [schedule]);
  const activeRotas = getCurrentlyActiveRotas({
    rotas: allRotas,
    now: now,
  });

  const hasMultipleActiveRotas = activeRotas.length > 1;
  const selectedRota = activeRotas.find((x) => x.id === selectedRotationID);

  // Includes all possible layers for all upcoming changes.
  const allLayersPerRotaId: { [rotaId: string]: ScheduleLayer[] } = _.chain(
    allRotas,
  )
    .groupBy((r): string => r.id)
    .mapValues((rotas): ScheduleLayer[] =>
      _.uniqBy(
        rotas.flatMap((r): ScheduleLayer[] => r.layers),
        (l) => l.id,
      ),
    )
    .value();

  const selectedRotaLayers: ScheduleLayer[] = selectedRota
    ? allLayersPerRotaId[selectedRota.id]
    : [];

  // When we switch rota, we want to re-default the rotation id to the first layer.
  useEffect(() => {
    // TODO: Find layers from the rotation that'd be active from the start
    const newRotation = getCurrentlyActiveRotas({
      rotas: allRotas,
      now: DateTime.fromJSDate(overrideStartAt),
    }).find((x) => x.id === selectedRotationID);
    if (newRotation?.layers.some((l) => l.id === selectedLayerID)) {
      // It's still a valid layer, no need to change
      return;
    }
    formMethods.setValue("layer_id", newRotation?.layers[0].id || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formMethods,
    allRotas,
    selectedRotationID,
    defaultValues?.rotation_id,
    overrideStartAt,
  ]);

  const [naturalLanguageError, setNaturalLanguageError] = useState<
    string | null
  >(null);
  const [throttledNaturalLanguageError] = useDebounce(
    naturalLanguageError,
    2500,
  );
  const [throttledNaturalLanguage] = useDebounce(naturalLanguage, 200);
  const [isLoadingNaturalLanguage, setIsLoadingNaturalLanguage] =
    useState<boolean>(false);
  useEffect(() => {
    if ((throttledNaturalLanguage ?? "").trim().length > 0) {
      setIsLoadingNaturalLanguage(true);
      const parsed = Sherlock.parse(throttledNaturalLanguage);
      if (parsed.startDate && parsed.endDate) {
        formMethods.clearErrors("natural_language");
        setNaturalLanguageError(null);
        formMethods.setValue(
          "start_at",
          roundToNearestMinutes(parsed.startDate, { nearestTo: 15 }),
        );
        formMethods.setValue(
          "end_at",
          roundToNearestMinutes(parsed.endDate, { nearestTo: 15 }),
        );

        if (parsed.eventTitle) {
          apiClient
            .usersTypeahead({
              query: parsed.eventTitle,
            })
            .then((users) => {
              if (users.options.length > 0) {
                formMethods.setValue("user_id", users.options[0].value);
              }
            })
            .finally(() => {
              setIsLoadingNaturalLanguage(false);
            });
        } else {
          setIsLoadingNaturalLanguage(false);
        }
      } else {
        setNaturalLanguageError(
          "Sorry, we couldn't interpret an override, please enter it manually below.",
        );
        setIsLoadingNaturalLanguage(false);
      }
    } else {
      setNaturalLanguageError(null);
    }
  }, [throttledNaturalLanguage, formMethods, apiClient]);
  useEffect(() => {
    if (throttledNaturalLanguageError) {
      formMethods.setError("natural_language", {
        type: "invalid",
        message: throttledNaturalLanguageError,
      });
    } else {
      formMethods.clearErrors("natural_language");
    }
  }, [throttledNaturalLanguageError, formMethods]);

  // When the user changes the start/end from date, we want to auto-select the most
  // appropriate timeline view
  useEffect(() => {
    dispatch({
      type: "recalculatePreview",
      payload: {
        overrideStartAt: DateTime.fromJSDate(overrideStartAt).setZone(
          schedule.timezone,
        ),
        overrideEndAt: DateTime.fromJSDate(overrideEndAt).setZone(
          schedule.timezone,
        ),
      },
    });
  }, [overrideEndAt, overrideStartAt, dispatch, schedule.timezone]);

  const activeUsersOnSchedules = useMemo(() => {
    return _.uniq([
      ...(getCurrentlyActiveRotas({
        rotas: schedule.config?.rotations || [],
        now: now,
      })
        .map((r) => r.user_ids)
        .flat() ?? []),
      ...(previewSchedulesResp?._final.map((e) => e.external_user_id) ?? []),
    ]);
  }, [schedule.config, now, previewSchedulesResp]);

  const { data: holidaysResponse } = useAPI(
    "schedulesListHolidayEntries",
    {
      from,
      until,
      userIds: activeUsersOnSchedules,
      countryCodes: schedule.holidays_public_config?.country_codes ?? [],
    },
    {
      fallbackData: {
        holiday_public_entries: [],
        holiday_user_entries: [],
      },
    },
  );

  const { data: userData, isLoading: userIsLoading } = useAPI("usersShow", {
    id: overrideAssignee,
  });
  const assignedUser = userData?.user;

  const setOverrideDuration = (hours: number) => {
    const newUntil = DateTime.fromJSDate(overrideStartAt).plus({ hours });
    formMethods.setValue(
      "end_at",
      roundToNearestMinutes(newUntil.toJSDate(), { nearestTo: 15 }),
    );
  };

  // Find the current override in the preview schedules response
  const currentOverride = _.find(
    previewSchedulesResp?.overrides,
    (override) => {
      return (
        override.start_at.getTime() === overrideStartAt.getTime() &&
        override.end_at.getTime() === overrideEndAt.getTime() &&
        override.rotation_id === selectedRotationID &&
        override.layer_id === selectedLayerID
      );
    },
  );

  // overriddenEntries is the set of entries that are going to be overriden by the
  // current override
  const overriddenEntries =
    previewSchedulesResp?._final.filter(
      (entry) => entry.override_id === currentOverride?.id && entry,
    ) || [];

  // Calculate the total number of seconds of shift time actually covered by the override.
  // This excludes any time outside of working hours.
  //
  // Bit nasty but essentially we're calculating the total time that this
  // override covers by iterating through all of the overridden entries,
  // calculating the amount of overlap in seconds, then summing that up.
  //
  // The function overlap([A, B], [X, Y]) = (Y - X) - (Y - B) - (A - X)
  // where ABXY are instants.
  const coveredTimeInSeconds = _.sum(
    _.map(overriddenEntries, (e): number => {
      return overrideEndsAtAsDateTime
        .diff(overrideStartAtAsDatetime)
        .minus(overrideEndsAtAsDateTime.diff(DateTime.fromJSDate(e.end_at)))
        .minus(DateTime.fromJSDate(e.start_at).diff(overrideStartAtAsDatetime))
        .as("seconds");
    }),
  );

  const earliestEntry = _.minBy(overriddenEntries, "start_at");
  const latestEntry = _.maxBy(overriddenEntries, "end_at");

  // Extract the Date objects
  const finalStartAt = earliestEntry ? earliestEntry.start_at : overrideStartAt;
  const finalEndAt = latestEntry ? latestEntry.end_at : overrideEndAt;

  const dateRangeAsString = formatDateRange(
    schedule.timezone,
    finalStartAt,
    finalEndAt,
  );

  const finalOverrideLengthAsString = formatDurationInSeconds(
    coveredTimeInSeconds,
    2,
    DurationEnum.minutes,
  );

  // If our override is for a rotation that applies working intervals at any point, let's add a callout.
  const overriddenRotations = schedule.config.rotations.filter(
    (x) => x.id === selectedRotationID,
  );

  const overriddenRotationHasWorkingHours =
    overriddenRotations.filter(
      (rotation) => rotation.working_intervals.length > 0,
    ).length > 0;

  const { data: nattyLangSuggestions, isLoading: nattyLangSuggestionsLoading } =
    useSWRImmutable(
      `override-preview-${schedule.id}`,
      async (): Promise<string[]> => {
        // Choose three random users from the schedule config
        const userIDs = _.chain(selectedRota?.user_ids ?? [])
          .uniq()
          .sampleSize(3)
          .value();

        // TODO swap these user look ups for a single call
        // https://linear.app/incident-io/issue/ONC-3143/add-a-users-fetch-by-id-endpoint
        const userResponses = await Promise.all(
          userIDs.map((u) => apiClient.usersShow({ id: u })),
        );

        const suggestionSuffixes = [
          "today from 9am to 5pm",
          "on Monday 9am to Friday 5pm",
          "tomorrow 9am to 5pm",
          "today from 11pm to tomorrow 7am",
        ];

        const users = userResponses.filter(
          (u) => (u.user.state as string) !== "deactivated",
        );
        const randomSuffixes = _.sampleSize(suggestionSuffixes, users.length);

        return users.map((u, i) => `${u.user?.name} ${randomSuffixes[i]}`);
      },
    );

  if (!identity) {
    return <LoadingBar />;
  }

  // Exclude zero-length and entries that start before they end
  const entriesToDisplay: (ScheduleEntry & { user_id: string })[] = (
    previewSchedulesResp?._final ?? []
  )
    .filter((e) => e.end_at.getTime() - e.start_at.getTime() > 0)
    .map((e) => ({ ...e, user_id: e.external_user_id }));
  const originalEntries = (originalSchedulesResp?._final ?? []).map((e) => ({
    ...e,
    user_id: e.external_user_id,
  }));

  const analyticsTrackingId =
    (mode === Mode.Create ? "create" : "edit") + "-override";

  return (
    <Form.Root
      onSubmit={onSubmit}
      formMethods={formMethods}
      id="schedule-override-form"
      warnWhenDirty
      outerClassName="flex-1 min-h-0 overflow-y-hidden"
      innerClassName="h-full !space-y-0 flex flex-col min-h-0 overflow-y-hidden"
      loadingWrapperClassName="h-full"
    >
      <div
        className={"outerbox flex flex-row min-h-0 overflow-y-hidden h-full"}
      >
        {/* Left hand, inputs side of the form */}
        <div
          className={
            "flex flex-col items-stretch flex-[2] p-6 border-r border-slate-200 overflow-auto"
          }
        >
          {/* Natural language overrides */}
          <>
            <div className="mb-3">
              <InputV2
                name={"natural_language"}
                formMethods={formMethods}
                label={"Who is providing cover and when?"}
                labelClassName={"h-[17px]"}
                labelAccessory={
                  <Spinner
                    theme={SpinnerTheme.Slate}
                    className={tcx("ml-[2px] mt-[1px]", {
                      "opacity-0":
                        !isLoadingNaturalLanguage &&
                        !nattyLangSuggestionsLoading,
                    })}
                  />
                }
                placeholder={
                  (nattyLangSuggestions ?? []).length > 0
                    ? `e.g. ${nattyLangSuggestions?.[0]}`
                    : "e.g. Leo from Saturday 9am to Sunday 9pm"
                }
              />
              {nattyLangSuggestionsLoading ||
              (nattyLangSuggestions ?? []).length === 0 ? null : (
                <div className={"flex flex-col mt-4 gap-3 items-start"}>
                  <div className="flex items-center">
                    <span className={"font-semibold text-sm"}>Examples</span>
                  </div>
                  {nattyLangSuggestions?.map((s) => (
                    <Button
                      key={s}
                      theme={ButtonTheme.Naked}
                      analyticsTrackingId={"natural-language-suggestion"}
                      onClick={() => {
                        formMethods.setValue("natural_language", s);
                      }}
                      className={
                        "text-content-primary hover:bg-surface-tertiary font-medium bg-surface-secondary rounded px-2 py-1"
                      }
                    >
                      {s}
                    </Button>
                  ))}
                </div>
              )}
            </div>
            <hr className={"my-3"} />
            {/* Enter override details toggle */}
            <div className="flex items-center my-3">
              <Txt bold>Enter override details manually </Txt>
            </div>
          </>

          {/*Override assignee*/}
          <div className={"flex items-center w-full mb-3"}>
            <OverrideUserInput formMethods={formMethods} />
          </div>
          <div className={"flex flex-row flex-wrap gap-4"}>
            {/* Rota and layer */}
            {(hasMultipleActiveRotas || selectedRotaLayers.length > 1) && (
              <>
                <AnimatePresence>
                  {activeRotas.length > 1 && (
                    <div className="flex-1 my-3">
                      <Form.Label
                        htmlFor={"search"}
                        className="block w-fit mb-2"
                        required
                      >
                        Rota
                      </Form.Label>
                      <StaticSingleSelectV2
                        name="rotation_id"
                        formMethods={formMethods}
                        options={activeRotas.map((rota) => {
                          return {
                            value: rota.id,
                            label: rota.name,
                          };
                        })}
                      />
                    </div>
                  )}
                  {selectedRotaLayers.length > 1 && (
                    <motion.div
                      key="layer-input"
                      className="flex-1 my-3"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Form.Label
                        htmlFor={"search"}
                        className="block w-fit mb-2"
                      >
                        Layer
                      </Form.Label>
                      <StaticSingleSelectV2
                        name="layer_id"
                        formMethods={formMethods}
                        options={selectedRotaLayers.map((layer, i) => {
                          const layerName = layer.name
                            ? layer.name
                            : "Layer " + (i + 1).toString();
                          return {
                            value: layer.id,
                            label: layerName,
                          };
                        })}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            )}
          </div>

          {/*Override duration*/}
          <div className="flex flex-col">
            <div className={tcx("flex my-3 flex-row flex-wrap gap-4")}>
              <div className="flex flex-col flex-[1]">
                <div className="flex flex-row justify-between">
                  <Txt bold>Cover from </Txt>
                </div>
                <DateTimeInputV2
                  name="start_at"
                  formMethods={formMethods}
                  className="mt-2"
                  timezone={schedule.timezone}
                  displayNowButton
                />
                {/* Use 3m ago as the marker for 'now' so that we don't slide into
             /* this warning if we spend a bit of time on the form. */}
                {currentStartAt < now.minus(3 * 1000 * 60) && !endsInPast && (
                  <Form.Helptext className="text-xs mb-0">
                    This override starts in the past but will only take effect
                    going forward.
                  </Form.Helptext>
                )}
              </div>
              <div className="flex flex-col flex-[1]">
                <div className="flex-center-y">
                  <Txt bold>Until</Txt>
                  <Button
                    theme={ButtonTheme.Naked}
                    analyticsTrackingId={null}
                    onClick={() => setOverrideDuration(1)}
                    className="ml-auto text-xs font-normal"
                  >
                    1h
                  </Button>
                  <Button
                    theme={ButtonTheme.Naked}
                    analyticsTrackingId={null}
                    onClick={() => setOverrideDuration(3)}
                    className="ml-1 text-xs font-normal"
                  >
                    3h
                  </Button>
                  <Button
                    theme={ButtonTheme.Naked}
                    analyticsTrackingId={null}
                    onClick={() => setOverrideDuration(6)}
                    className="ml-1 mr-1 text-xs font-normal"
                  >
                    6h
                  </Button>
                </div>
                <DateTimeInputV2
                  name="end_at"
                  formMethods={formMethods}
                  className="mt-2"
                  timezone={schedule.timezone}
                  displayNowButton
                />
                {overrideDuration.as("years") > 1 && (
                  <ErrorMessage message="Overrides longer than a year are not supported." />
                )}
              </div>
            </div>
            {overriddenRotationHasWorkingHours && !endsInPast && (
              <Txt grey xs>
                This override applies to a rotation that has working hours.
                You&apos;ll only be on call within the rota&apos;s working
                hours.
              </Txt>
            )}
          </div>
        </div>

        {/* Right hand, preview side of the form */}
        <div
          className={
            "flex flex-col items-stretch flex-[3] p-6 bg-slate-50 overflow-auto"
          }
        >
          <Heading level={2} size={"small"} className={"mb-2"}>
            Preview
          </Heading>
          {/*Override preview*/}
          <div className={"flex-1 mb-4"}>
            <AutoSizer ref={resizerRef}>
              {({ width }) => (
                <div className="flex flex-col space-y-2 mb-4 h-full">
                  <ScheduleOverviewHeader
                    className={"mb-3"}
                    timeWindowState={state}
                    focusDate={overrideStartAtAsDatetime}
                    timeWindowDispatch={dispatch}
                    inModal
                    scheduleDefaultTimezone={schedule.timezone}
                    selectedTimezones={selectedTimezones}
                    setSelectedTimezones={setSelectedTimezones}
                    showCalendarOption={false}
                  />
                  <TimelineSection
                    rotations={schedule.config?.rotations ?? []}
                    title={"Schedule before override"}
                    now={now}
                    isLoadingEntries={
                      userIsLoading || originalSchedulesIsLoading
                    }
                    timelineStartPoint={state.startTime}
                    holidaysResponse={holidaysResponse}
                    timelineEndpoint={currentEndTime}
                    timePeriod={state.timePeriodOption}
                    width={width}
                    entries={originalEntries}
                    disableOverride
                    scheduleTimezone={schedule.timezone}
                    selectedTimezones={selectedTimezones}
                    scheduleId={schedule.id}
                    upcomingRotaChanges={getUpcomingRotaChanges(allRotas, now)}
                    collapseByDefault
                    collapsable={true}
                  />
                  <TimelineSection
                    rotations={schedule.config?.rotations ?? []}
                    title={"Schedule after override"}
                    now={now}
                    isLoadingEntries={userIsLoading || previewIsLoading}
                    timelineStartPoint={state.startTime}
                    timelineEndpoint={currentEndTime}
                    timePeriod={state.timePeriodOption}
                    holidaysResponse={holidaysResponse}
                    width={width}
                    scheduleId={schedule.id}
                    entries={entriesToDisplay}
                    overriddenEntry={{
                      startAt: overrideStartAt,
                      endAt: overrideEndAt,
                      layerId: selectedLayerID,
                    }}
                    disableOverride
                    // Disable flattening of entries to avoid concatenating our potential
                    // override with any shifts for the same user adjacent to it.
                    // flattenEntries={false} // TODO
                    scheduleTimezone={schedule.timezone}
                    selectedTimezones={selectedTimezones}
                    upcomingRotaChanges={getUpcomingRotaChanges(allRotas, now)}
                  />
                </div>
              )}
            </AutoSizer>
          </div>

          {endsInPast ? (
            <Callout theme={CalloutTheme.Warning} className="w-full">
              This override ends in the past and won&apos;t have any effect
              going forward.
            </Callout>
          ) : overrideEndsAtAsDateTime < overrideStartAtAsDatetime ? (
            <Callout theme={CalloutTheme.Warning}>
              <Txt>This override ends before it starts</Txt>
            </Callout>
          ) : coveredTimeInSeconds > 0 && overrideDuration.as("months") < 3 ? (
            <Callout theme={CalloutTheme.Plain} className={"!bg-white"}>
              <Txt inline bold>
                {getUserLabel(assignedUser, identity?.user_id)}
              </Txt>{" "}
              <Txt inline>will be covering this rota for </Txt>
              <Txt inline bold>
                {finalOverrideLengthAsString}
              </Txt>{" "}
              {dateRangeAsString}
              {overriddenEntries.length > 1 ? (
                <Txt inline>
                  {", over "}
                  <Txt inline bold>
                    {overriddenEntries.length} shifts
                  </Txt>
                </Txt>
              ) : (
                "."
              )}
            </Callout>
          ) : (
            <></>
          )}
        </div>
      </div>

      {createOverrideGenericError || updateOverrideGenericError ? (
        <ErrorMessage
          message={createOverrideGenericError || updateOverrideGenericError}
        />
      ) : null}

      {/* Drawer Footer */}
      <div className="flex gap-2 w-full sticky bottom-0 z-[50] py-4 bg-white border-t border-stroke items-center	">
        {initialData?.id && (
          <GatedButton
            onClick={initialData?.id ? () => deleteOverride({}) : undefined}
            analyticsTrackingId={analyticsTrackingId + "-delete"}
            theme={ButtonTheme.DestroySecondary}
            loading={createSaving || updateSaving || deleteSaving}
            requiredScope={ScopeNameEnum.ScheduleOverridesDestroy}
            disabledTooltipContent={"You do not have permission to do this."}
            className="ml-6"
          >
            Delete override
          </GatedButton>
        )}
        <div className="grow" />
        <Button
          onClick={() => onCloseWithWarn(isDirty)}
          analyticsTrackingId={analyticsTrackingId + "-cancel"}
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        <GatedButton
          type="submit"
          analyticsTrackingId={analyticsTrackingId + "-submit"}
          theme={ButtonTheme.Primary}
          loading={createSaving || updateSaving || deleteSaving}
          className="mr-6"
          requiredScope={
            mode === Mode.Create
              ? ScopeNameEnum.ScheduleOverridesCreate
              : ScopeNameEnum.ScheduleOverridesUpdate
          }
          disabledTooltipContent={
            endsInPast
              ? "You cannot create an override that ends in the past."
              : "You do not have permission to do this."
          }
        >
          Save override
        </GatedButton>
      </div>
    </Form.Root>
  );
};

const getUserLabel = (
  user?: UserWithRoles,
  identityUserId?: string,
): string => {
  if (!user) {
    return "No one";
  }
  if (user.id === identityUserId) {
    return "You";
  }
  return user.name;
};

const formatDateRange = (timezone: string, startDate: Date, endDate: Date) => {
  const options: DateTimeFormatOptions = {
    timeZone: timezone,
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const startDayStr = startDate.toLocaleString("en-US", options);
  const endDayStr = endDate.toLocaleString("en-US", options);

  if (startDayStr === endDayStr) {
    return (
      <>
        <Txt inline>on</Txt>{" "}
        <Txt inline bold>
          {startDayStr}
        </Txt>
      </>
    );
  }

  return (
    <>
      <Txt inline>from</Txt>{" "}
      <Txt inline bold>
        {startDayStr}
      </Txt>{" "}
      <Txt inline>through</Txt>{" "}
      <Txt inline bold>
        {endDayStr}
      </Txt>
    </>
  );
};
