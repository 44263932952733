import type { SVGProps } from "react";
import * as React from "react";
const SvgLinkAi = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#161618"
      fillRule="evenodd"
      d="m16.548 3.176.946.315a.375.375 0 0 1-.001.712l-.946.316-.316.946a.375.375 0 0 1-.71 0l-.316-.946-.946-.316a.375.375 0 0 1 0-.712l.946-.315.316-.947c.102-.306.609-.306.711 0l.316.947Zm-5.563 10.231a.75.75 0 0 1-.323-1.427c.283-.135.541-.318.766-.543a2.793 2.793 0 0 0 .01-3.95L9.263 5.312a2.79 2.79 0 0 0-3.939 0 2.791 2.791 0 0 0-.01 3.95l.931.93a.75.75 0 1 1-1.06 1.061l-.932-.93a4.29 4.29 0 0 1 0-6.06 4.29 4.29 0 0 1 6.07-.01l2.175 2.174a4.29 4.29 0 0 1 0 6.061 4.293 4.293 0 0 1-1.19.846.742.742 0 0 1-.323.073Zm2.688 4.561a4.261 4.261 0 0 1-3.027-1.252l-2.174-2.175a4.29 4.29 0 0 1 0-6.06 4.293 4.293 0 0 1 1.19-.847.75.75 0 1 1 .645 1.354 2.767 2.767 0 0 0-.765.543 2.793 2.793 0 0 0-.01 3.95l2.175 2.175a2.79 2.79 0 0 0 3.938 0 2.791 2.791 0 0 0 .01-3.949l-.93-.93a.75.75 0 1 1 1.06-1.062l.931.931a4.292 4.292 0 0 1-3.044 7.322Zm-7.014-2.977-1.263-.421-.421-1.264c-.137-.407-.812-.407-.949 0l-.421 1.264-1.263.42a.5.5 0 0 0 0 .948l1.263.421.421 1.263a.501.501 0 0 0 .95 0l.421-1.262 1.263-.421a.5.5 0 0 0-.001-.948Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLinkAi;
