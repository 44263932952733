import { ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, Callout, CalloutTheme, IconEnum } from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useOutlet } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";

import { Plurality, ProductUpsellNotice } from "../../ProductUpsellNotice";
import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { SettingsSubHeading } from "../../SettingsSubHeading";
import { SuggestionAICards } from "../ai/SuggestionAICards";
import { NudgesList } from "./NudgesList";

export const SuggestionsListPage = (): React.ReactElement => {
  const drawer = useOutlet();
  const { hasProduct } = useProductAccess();
  const { identity } = useIdentity();

  const [showChooseTypeModal, setShowChooseTypeModal] = useState(false);

  if (!identity.feature_gates.suggestions) {
    return <NotFoundPage />;
  }

  // Currently, you can only get here if you have access to suggestions.
  // TODO: [AI-356] - handle it if you don't have access

  return (
    <>
      <AnimatePresence>{drawer}</AnimatePresence>
      <SettingsSubPageWrapper>
        <Callout
          theme={CalloutTheme.Plain}
          className="text-white bg-pink-600"
          title="We're building out suggestions (only incident.io staff can see this right now)"
        />
        <SuggestionAICards />
        {hasProduct(Product.Response) ? (
          <div className="flex flex-col gap-4">
            <SettingsSubHeading
              title="Channel suggestions"
              className="mb-0"
              accessory={
                <GatedButton
                  theme={ButtonTheme.Primary}
                  onClick={() => setShowChooseTypeModal(true)}
                  analyticsTrackingId="create-nudge"
                  icon={IconEnum.Add}
                  requiredScope={ScopeNameEnum.NudgesCreate}
                  requiredProduct={Product.Response}
                >
                  Add suggestion
                </GatedButton>
              }
            />
            <NudgesList
              showChooseTypeModal={showChooseTypeModal}
              setShowChooseTypeModal={setShowChooseTypeModal}
              hasFeatureGate
              renameToChannelSuggestion
            />
          </div>
        ) : (
          <ProductUpsellNotice
            featureName="Channel suggestions"
            plurality={Plurality.Plural}
            requiredProduct={Product.Response}
          />
        )}
      </SettingsSubPageWrapper>
    </>
  );
};
