/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CopilotChatMessage
 */
export interface CopilotChatMessage {
    /**
     * The content of the message
     * @type {string}
     * @memberof CopilotChatMessage
     */
    content: string;
    /**
     * ID of the message
     * @type {string}
     * @memberof CopilotChatMessage
     */
    id: string;
    /**
     * Reactions to the message
     * @type {Array<string>}
     * @memberof CopilotChatMessage
     */
    reactions: Array<string>;
    /**
     * When the message was sent.
     * @type {Date}
     * @memberof CopilotChatMessage
     */
    sent_at: Date;
    /**
     * ID of the thread this message is in
     * @type {string}
     * @memberof CopilotChatMessage
     */
    thread_id: string;
    /**
     * 
     * @type {User}
     * @memberof CopilotChatMessage
     */
    user: User;
}

export function CopilotChatMessageFromJSON(json: any): CopilotChatMessage {
    return CopilotChatMessageFromJSONTyped(json, false);
}

export function CopilotChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotChatMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'id': json['id'],
        'reactions': json['reactions'],
        'sent_at': (new Date(json['sent_at'])),
        'thread_id': json['thread_id'],
        'user': UserFromJSON(json['user']),
    };
}

export function CopilotChatMessageToJSON(value?: CopilotChatMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'id': value.id,
        'reactions': value.reactions,
        'sent_at': (value.sent_at.toISOString()),
        'thread_id': value.thread_id,
        'user': UserToJSON(value.user),
    };
}

