/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotThreadScoringMetrics,
    CopilotThreadScoringMetricsFromJSON,
    CopilotThreadScoringMetricsFromJSONTyped,
    CopilotThreadScoringMetricsToJSON,
} from './CopilotThreadScoringMetrics';

/**
 * 
 * @export
 * @interface CopilotThreadScoring
 */
export interface CopilotThreadScoring {
    /**
     * When the scoring was created
     * @type {Date}
     * @memberof CopilotThreadScoring
     */
    created_at: Date;
    /**
     * 
     * @type {CopilotThreadScoringMetrics}
     * @memberof CopilotThreadScoring
     */
    metrics?: CopilotThreadScoringMetrics;
    /**
     * Detailed explanation for the given score
     * @type {string}
     * @memberof CopilotThreadScoring
     */
    reasoning?: string;
    /**
     * Numerical score as a string representing quality
     * @type {string}
     * @memberof CopilotThreadScoring
     */
    score?: CopilotThreadScoringScoreEnum;
    /**
     * Key elements that influenced the score
     * @type {Array<string>}
     * @memberof CopilotThreadScoring
     */
    tags?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum CopilotThreadScoringScoreEnum {
    Low = 'low',
    Medium = 'medium',
    High = 'high'
}

export function CopilotThreadScoringFromJSON(json: any): CopilotThreadScoring {
    return CopilotThreadScoringFromJSONTyped(json, false);
}

export function CopilotThreadScoringFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotThreadScoring {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'metrics': !exists(json, 'metrics') ? undefined : CopilotThreadScoringMetricsFromJSON(json['metrics']),
        'reasoning': !exists(json, 'reasoning') ? undefined : json['reasoning'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function CopilotThreadScoringToJSON(value?: CopilotThreadScoring | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'metrics': CopilotThreadScoringMetricsToJSON(value.metrics),
        'reasoning': value.reasoning,
        'score': value.score,
        'tags': value.tags,
    };
}

