import { useIncidentSuggestionsServiceIncidentSuggestionsShowSuggestion } from "@incident-io/query-api";
import { Button, IconEnum } from "@incident-ui";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { Suggestion } from "../../legacy/incident/body/DebugViewSuggestions";
import { useIncident } from "../../legacy/incident/hooks";
import { IncidentCard } from "../../legacy/incident-grid/card/IncidentCard";

export const WorkbenchSuggestionShowPage = () => {
  const { suggestionId } = useParams<{
    suggestionId: string;
  }>();

  const { data, isLoading } =
    useIncidentSuggestionsServiceIncidentSuggestionsShowSuggestion(
      {
        id: suggestionId ?? "",
      },
      undefined,
      {
        enabled: !!suggestionId,
      },
    );
  const suggestion = data?.incident_suggestion;

  const { incident } = useIncident(suggestion?.incident_id ?? null);

  return (
    <WorkbenchSubPageWrapper
      loading={isLoading}
      accessory={
        <>
          {data?.trace_id && (
            <Button
              analyticsTrackingId={null}
              icon={IconEnum.Cog}
              href={`/workbench/processors/${data.trace_id}/${data.span_id}`}
            >
              View processor
            </Button>
          )}
        </>
      }
    >
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          {suggestion && <Suggestion suggestion={suggestion} />}
        </div>
        {incident && (
          <IncidentCard
            incident={incident}
            isGridFirstUpdate={false}
            slackTeamConfig={undefined}
          />
        )}
      </div>
    </WorkbenchSubPageWrapper>
  );
};
