/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MicrosoftTeamsOnlineMeetingsConfig,
    MicrosoftTeamsOnlineMeetingsConfigFromJSON,
    MicrosoftTeamsOnlineMeetingsConfigFromJSONTyped,
    MicrosoftTeamsOnlineMeetingsConfigToJSON,
} from './MicrosoftTeamsOnlineMeetingsConfig';

/**
 * 
 * @export
 * @interface IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody
 */
export interface IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody {
    /**
     * 
     * @type {MicrosoftTeamsOnlineMeetingsConfig}
     * @memberof IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody
     */
    config: MicrosoftTeamsOnlineMeetingsConfig;
}

export function IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBodyFromJSON(json: any): IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody {
    return IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': MicrosoftTeamsOnlineMeetingsConfigFromJSON(json['config']),
    };
}

export function IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBodyToJSON(value?: IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': MicrosoftTeamsOnlineMeetingsConfigToJSON(value.config),
    };
}

