import {
  OnCallNotificationMethod,
  OnCallNotificationMethodMethodTypeEnum,
  OnCallNotificationMethodPhoneVerificationTypeEnum,
  OnCallNotificationMethodStatusEnum,
  OnCallNotificationsCreateMethodRequestBody,
  OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum,
  OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum,
} from "@incident-io/api";
import { FormErrorMessage } from "@incident-shared/forms/ErrorMessage";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { Badge, BadgeTheme, Link, ModalFooter } from "@incident-ui";
import { AsYouType, CountryCode } from "libphonenumber-js";
import { useForm, useFormState } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { useRestrictedCountries } from "./useRestrictedCountries";

type AddContactMethodFormData = OnCallNotificationsCreateMethodRequestBody;
export const AddContactMethodModal = ({
  onClose,
  showVerificationModal,
  allowEmail = true,
}: {
  onClose: () => void;
  showVerificationModal: (method: OnCallNotificationMethod) => void;
  allowEmail?: boolean;
}) => {
  const formMethods = useForm<AddContactMethodFormData>({
    defaultValues: {
      method_type:
        OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum.Phone,
      phone_verification_type:
        OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum.Sms,
    },
  });
  const restrictedCountries = useRestrictedCountries();

  const identity = useIdentity();

  const phoneNumberFormat = identity?.identity?.user_locale
    ? identity?.identity?.user_locale.split("-")[1]
    : "US";

  const [methodType] = formMethods.watch(["method_type"]);

  const [phoneVerificationType] = formMethods.watch([
    "phone_verification_type",
  ]);
  const phoneNumber = formMethods.watch("phone_number");
  const formState = useFormState(formMethods);

  const asYouType = new AsYouType();
  asYouType.input(phoneNumber || "");

  const countryCode = asYouType.getCountry();
  const reachableCountryCode = (
    countryCode?: CountryCode,
    phoneVerificationType?: OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum,
  ) => {
    if (!countryCode || !phoneVerificationType || !restrictedCountries) {
      return true;
    }
    const unreachableMethods = restrictedCountries[countryCode];
    // If we don't have an entry for this country, it's reachable.
    if (!unreachableMethods) {
      return true;
    }
    // If we don't have an entry for this method type for this country, it's reachable.
    return !unreachableMethods.includes(phoneVerificationType);
  };

  const isUnreachableCountryCode = !reachableCountryCode(
    countryCode,
    phoneVerificationType,
  );

  const unsupportedMessages = {
    sms: "We currently do not support SMS notifications in this country.",
    call: "We currently do not support voice call notifications in this country.",
  };

  const {
    trigger: onCreate,
    isMutating,
    genericError,
  } = useAPIMutation(
    "onCallNotificationsListMethodsV2",
    undefined,
    async (
      apiClient,
      createMethodRequestBody: OnCallNotificationsCreateMethodRequestBody,
    ) => {
      const { method } = await apiClient.onCallNotificationsCreateMethod({
        createMethodRequestBody,
      });
      if (
        method.status === OnCallNotificationMethodStatusEnum.PendingVerification
      ) {
        showVerificationModal(method);
      } else {
        onClose();
      }
    },
    {
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal<AddContactMethodFormData>
      formMethods={formMethods}
      onSubmit={onCreate}
      title={"Add contact method"}
      analyticsTrackingId={"add-on-call-notification-method-modal"}
      onClose={onClose}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonType={"submit"}
          disabled={
            !formMethods.formState.isValid ||
            isMutating ||
            isUnreachableCountryCode ||
            !asYouType.isValid()
          }
          disabledTooltipContent={
            isUnreachableCountryCode && phoneVerificationType
              ? unsupportedMessages[phoneVerificationType]
              : ""
          }
          saving={isMutating}
          confirmButtonText={
            methodType ===
            OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum.Phone
              ? "Send verification code"
              : "Add method"
          }
          onClose={onClose}
          analyticsTrackingId={"add-on-call-notification-method-modal"}
        />
      }
    >
      {/* PhoneInput doesn't set errors properly so we render the error separately here for it to work */}
      <FormErrorMessage errors={formState.errors} name={"phone_number"} />
      {allowEmail ? (
        <RadioButtonGroupV2
          name="method_type"
          formMethods={formMethods}
          label={"What would you like to add?"}
          srLabel="method_type"
          boxed
          horizontal
          options={[
            {
              label: "Phone number",
              value: OnCallNotificationMethodMethodTypeEnum.Phone,
            },
            {
              label: "Email",
              value: OnCallNotificationMethodMethodTypeEnum.Email,
            },
          ]}
        />
      ) : null}
      {methodType ===
        OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum.Phone && (
        <>
          <Form.Label htmlFor="phone_number">Phone number</Form.Label>
          <PhoneInput
            name={"phone_number"}
            control={formMethods.control}
            rules={{ required: true }}
            defaultCountry={phoneNumberFormat as CountryCode}
            international
            countrySelectProps={{ unicodeFlags: true }}
            className="!mt-1"
            numberInputProps={{
              className: "border border-stroke rounded-[6px] p-2 tabular-nums",
            }}
          />
          <RadioButtonGroupV2
            name="phone_verification_type"
            formMethods={formMethods}
            label={
              "How would you like to receive the code to verify your number?"
            }
            srLabel="phone_verification_type"
            boxed
            horizontal
            radioClassName={"min-h-12"}
            options={[
              {
                label: "SMS",
                value: OnCallNotificationMethodPhoneVerificationTypeEnum.Sms,
                suffixNode: !reachableCountryCode(
                  countryCode,
                  OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum.Sms,
                ) ? (
                  <Badge theme={BadgeTheme.Warning}>Unsupported</Badge>
                ) : undefined,
              },
              {
                label: "Phone call",
                value: OnCallNotificationMethodPhoneVerificationTypeEnum.Call,
                suffixNode: !reachableCountryCode(
                  countryCode,
                  OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum.Call,
                ) ? (
                  <Badge theme={BadgeTheme.Warning}>Unsupported</Badge>
                ) : undefined,
              },
            ]}
          />
          {isUnreachableCountryCode ? (
            <div className={"text-sm text-content-secondary"}>
              {phoneVerificationType
                ? unsupportedMessages[phoneVerificationType]
                : ""}
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className={"text-sm text-content-secondary"}>
                By opting in, message and data rates may apply. The frequency of
                messages will vary depending on your On-call configuration. You
                will also receive 1 message if verifying via SMS.
              </div>
              <div className={"text-sm text-content-secondary"}>
                You may opt out at any time by unselecting in your preferences,
                or by texting STOP. You can also text HELP for help.
              </div>
              <div className={"text-sm text-content-secondary"}>
                See our{" "}
                <Link
                  href={"https://incident.io/terms"}
                  openInNewTab
                  analyticsTrackingId={"terms-of-service"}
                >
                  terms of service
                </Link>{" "}
                and our{" "}
                <Link
                  href={"https://incident.io/privacy"}
                  openInNewTab
                  analyticsTrackingId={"privacy"}
                >
                  privacy policy
                </Link>{" "}
                for more information.
              </div>
            </div>
          )}
        </>
      )}
    </Form.Modal>
  );
};
