import { ROTA_NAME_COLUMN_WIDTH } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/constants";
import * as d3 from "d3";
import { DateTime } from "luxon";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { tcx } from "src/utils/tailwind-classes";

import { TimePeriodOption } from "./common/types";
import stripeBackground from "./hatched.png";

export interface TimelineDragState {
  dragStartTime: DateTime;
  dragEndTime: DateTime;
  draggingOn: "start" | "end";
  dragStartLayer: {
    layerId: string;
    rotationId: string;
  };
}

interface LayerContext {
  layerId: string;
  rotationId: string;
}

export const CLICK_NOT_DRAG = "override-drag";

// GlobalDragIndicator shows the full-height line whenever hovering
export const GlobalDragIndicator = ({
  xScale,
  timelineStartPoint,
}: {
  xScale: d3.ScaleTime<number, number>;
  timelineStartPoint: DateTime;
}) => {
  const { dragState, currentTime } = useContext(DragContext);

  const times = (
    dragState
      ? // While dragging, show start and end
        [dragState.dragStartTime, dragState.dragEndTime]
      : currentTime
      ? // Otherwise just show the current time
        [currentTime]
      : []
  ).filter((ts) => ts >= timelineStartPoint);

  if (times.length === 0) {
    return null;
  }

  return (
    <div className="absolute inset-0" style={{ zIndex: 0 }}>
      {times.map((ts) => (
        <div
          key={ts.toISO()}
          className="absolute bottom-0 top-2 transition-opacity duration-150 bg-surface-tertiary w-[1px]"
          style={{
            left: xScale(ts.toJSDate()) + ROTA_NAME_COLUMN_WIDTH,
          }}
        />
      ))}
    </div>
  );
};

// Create a context to store the currently hovered layer and active drag globally
export const DragContext = createContext<{
  dragState: TimelineDragState | null;
  currentTime: DateTime | null;
  hoveredLayer: LayerContext | null;
  setHoveredLayer: React.Dispatch<React.SetStateAction<LayerContext | null>>;
}>({
  dragState: null,
  currentTime: null,
  hoveredLayer: null,
  setHoveredLayer: () => {
    //
  },
});

const getIncrementMillis = (timePeriod: TimePeriodOption): number => {
  switch (timePeriod) {
    case TimePeriodOption.ThreeHours:
      return 5 * 60 * 1000; // 5 minutes
    case TimePeriodOption.OneDay:
      return 15 * 60 * 1000; // 15 minutes
    case TimePeriodOption.OneWeek:
      return 60 * 60 * 1000; // 1 hour
    case TimePeriodOption.TwoWeeks:
      return 60 * 60 * 1000; // 1 hour
    case TimePeriodOption.OneMonth:
      return 6 * 60 * 60 * 1000; // 6 hours
    default:
      return 15 * 60 * 1000;
  }
};

const snapToIncrement = (
  time: DateTime,
  timePeriod: TimePeriodOption,
): DateTime => {
  const incrementMs = getIncrementMillis(timePeriod);
  const timeMs = time.toMillis();
  const snappedMs = Math.round(timeMs / incrementMs) * incrementMs;
  return DateTime.fromMillis(snappedMs).setZone(time.zone);
};

export const TimelineMousePositionProvider = ({
  xScale,
  timePeriod,
  timezone,
  onDragEnd,
  children,
  disabled,
}: {
  disabled?: boolean;
  xScale: d3.ScaleTime<number, number>;
  timePeriod: TimePeriodOption;
  timezone: string;
  onDragEnd: (
    startTime: DateTime,
    endTime: DateTime,
    layer: LayerContext,
  ) => void;
  children: React.ReactNode;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dragState, setDragState] = useState<TimelineDragState | null>(null);
  const [hoveredLayer, setHoveredLayer] = useState<LayerContext | null>(null);
  const [currentTime, setCurrentTime] = useState<DateTime | null>(null);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!containerRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left - ROTA_NAME_COLUMN_WIDTH;

      const rawTime = DateTime.fromJSDate(xScale.invert(x)).setZone(timezone);
      const snappedTime = snapToIncrement(rawTime, timePeriod);

      // For both of these we avoid returning a new value that is semantically
      // the same as the previous one to minimise re-renders
      setCurrentTime((prev) =>
        prev?.equals(snappedTime) ? prev : snappedTime,
      );

      setDragState((prev) => {
        if (!prev) return prev;

        const { dragStartTime, dragEndTime } = prev;

        if (prev.draggingOn === "start") {
          // First check if we should swap the end we're dragging
          if (snappedTime > dragEndTime) {
            return {
              ...prev,
              dragStartTime: dragEndTime,
              dragEndTime: snappedTime,
              draggingOn: "end",
            };
          }

          // Ok, check if it's _actually_ changed
          if (snappedTime.equals(dragStartTime)) {
            return prev;
          }

          return { ...prev, dragStartTime: snappedTime };
        } else {
          // First check if we should swap the end we're dragging
          if (snappedTime < dragStartTime) {
            return {
              ...prev,
              dragStartTime: snappedTime,
              dragEndTime: dragStartTime,
              draggingOn: "start",
            };
          }

          // Ok, check if it's _actually_ changed
          if (snappedTime.equals(dragEndTime)) {
            return prev;
          }

          return { ...prev, dragEndTime: snappedTime };
        }
      });
    },
    [xScale, timezone, timePeriod],
  );

  // If you're here trying to change behaviour for "clicking" rather than dragging, either add CLICK_NOT_DRAG
  // to your class or change onDragEnd, depending on your use case!
  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (disabled) return;

      if (e.button !== 0 || !currentTime || !hoveredLayer) return;

      const target = e.target as HTMLElement;
      if (target.className.includes(CLICK_NOT_DRAG)) return;

      setDragState({
        // Assume you're dragging left-to-right
        draggingOn: "end",
        dragStartTime: currentTime,
        dragEndTime: currentTime,
        dragStartLayer: hoveredLayer,
      });
    },
    [disabled, currentTime, hoveredLayer],
  );

  const handleMouseUp = useCallback(
    (ev: React.MouseEvent | MouseEvent) => {
      if (disabled) return;

      setDragState((dragState) => {
        if (dragState) {
          const { dragStartTime, dragEndTime } = dragState;

          onDragEnd(dragStartTime, dragEndTime, dragState.dragStartLayer);
          // Prevent the 'click' happening from whatever's underneath
          ev.stopPropagation();
          ev.preventDefault();
        }

        return null;
      });
    },
    [disabled, onDragEnd],
  );

  const handleMouseLeave = useCallback(() => {
    setCurrentTime(null);
  }, []);

  // If you press the escape key while dragging, cancel the drag
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      setDragState((dragState) => {
        if (e.key === "Escape" && dragState) {
          return null;
        }

        return dragState;
      });
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Handle global mouse up
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (dragState) {
      const globalHandleMouseUp = (ev: MouseEvent) => handleMouseUp(ev);

      window.addEventListener("mouseup", globalHandleMouseUp);
      return () => window.removeEventListener("mouseup", globalHandleMouseUp);
    }
  }, [dragState, handleMouseUp]);

  return (
    <DragContext.Provider
      value={{
        dragState,
        currentTime,
        hoveredLayer,
        setHoveredLayer,
      }}
    >
      <div
        ref={containerRef}
        className="relative w-full h-full"
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
    </DragContext.Provider>
  );
};

// Layer-specific indicator shows the create override UI only on the active layer
export const DragToOverrideIndicator = ({
  xScale,
  layerId,
  rotationId,
}: {
  xScale: d3.ScaleTime<number, number>;
  layerId: string;
  rotationId: string;
}) => {
  const { dragState, hoveredLayer, currentTime } = useContext(DragContext);

  const isActiveLayer =
    dragState &&
    dragState.dragStartLayer.layerId === layerId &&
    dragState.dragStartLayer.rotationId === rotationId;

  const isHovered =
    hoveredLayer?.layerId === layerId &&
    hoveredLayer?.rotationId === rotationId;

  // Only show when this is the active layer where drag started
  if (!isActiveLayer && !isHovered) {
    return null;
  }

  return (
    <div
      className="absolute inset-0 pointer-events-none"
      style={{
        zIndex: 20,
      }}
    >
      <div
        className="absolute inset-0 pointer-events-none"
        style={{ zIndex: 20 }}
      >
        {/* Hover state indicator */}
        {isHovered && !dragState && currentTime && (
          <div
            className="absolute h-full border-l-alarmalade-700  transition-opacity duration-150"
            style={{
              left: xScale(currentTime.toJSDate()),
              opacity: 0.3,
            }}
          />
        )}

        {/* Layer-specific drag selection with "create override" UI */}
        {isActiveLayer && (
          <div
            className="absolute h-full"
            style={{
              left: xScale(dragState.dragStartTime.toJSDate()),
              width:
                xScale(dragState.dragEndTime.toJSDate()) -
                xScale(dragState.dragStartTime.toJSDate()),
            }}
          >
            {/* Stripey background */}
            <div
              style={{
                opacity: 0.6,
                backgroundImage: `url(${stripeBackground})`,
                backgroundSize: "32px 32px",
                mixBlendMode: "color-burn",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
            <div className="absolute inset-0 bg-black/5 border border-dashed border-black/20 rounded-1 mix-blend-multiply">
              <div className="absolute inset-0 flex items-center justify-center px-2" />
            </div>

            {/* Duration tooltip */}
            <div
              className={tcx(
                // Positioning
                "absolute -bottom-1 translate-x-full translate-y-full z-10 right-0",
                // Sizing
                "px-2 py-1 rounded bg-surface-primary",
                // Border
                "border border-stroke shadow-sm",
                // Styling
                "text-xs-med text-content-primary whitespace-nowrap",
              )}
            >
              {dragState.dragEndTime
                .diff(dragState.dragStartTime)
                .normalize()
                .rescale()
                .toHuman()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
