/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentGroundTruthCodeChange,
    IncidentGroundTruthCodeChangeFromJSON,
    IncidentGroundTruthCodeChangeFromJSONTyped,
    IncidentGroundTruthCodeChangeToJSON,
} from './IncidentGroundTruthCodeChange';

/**
 * 
 * @export
 * @interface AIUpdateIncidentGroundTruthRequestBody
 */
export interface AIUpdateIncidentGroundTruthRequestBody {
    /**
     * List of code changes that caused this incident
     * @type {Array<IncidentGroundTruthCodeChange>}
     * @memberof AIUpdateIncidentGroundTruthRequestBody
     */
    causing_code_changes?: Array<IncidentGroundTruthCodeChange>;
    /**
     * When code changes were human-verified (blank string to unset)
     * @type {string}
     * @memberof AIUpdateIncidentGroundTruthRequestBody
     */
    causing_code_changes_verified_at?: string;
}

export function AIUpdateIncidentGroundTruthRequestBodyFromJSON(json: any): AIUpdateIncidentGroundTruthRequestBody {
    return AIUpdateIncidentGroundTruthRequestBodyFromJSONTyped(json, false);
}

export function AIUpdateIncidentGroundTruthRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIUpdateIncidentGroundTruthRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'causing_code_changes': !exists(json, 'causing_code_changes') ? undefined : ((json['causing_code_changes'] as Array<any>).map(IncidentGroundTruthCodeChangeFromJSON)),
        'causing_code_changes_verified_at': !exists(json, 'causing_code_changes_verified_at') ? undefined : json['causing_code_changes_verified_at'],
    };
}

export function AIUpdateIncidentGroundTruthRequestBodyToJSON(value?: AIUpdateIncidentGroundTruthRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'causing_code_changes': value.causing_code_changes === undefined ? undefined : ((value.causing_code_changes as Array<any>).map(IncidentGroundTruthCodeChangeToJSON)),
        'causing_code_changes_verified_at': value.causing_code_changes_verified_at,
    };
}

