import { StaffPermissionEnum } from "src/hooks/useCheckStaffPermissions";

export const permissionName: {
  [key in StaffPermissionEnum]: { label: string; tooltip?: React.ReactNode };
} = {
  [StaffPermissionEnum.AdjustPlan]: { label: "Adjust plan settings" },
  [StaffPermissionEnum.AttachSubscription]: { label: "Attach subscription" },
  [StaffPermissionEnum.BootstrapCustomerChannel]: {
    label: "Run CS Bot on customer channel",
  },
  [StaffPermissionEnum.GrantStaffPermission]: {
    label: "Grant staff permissions",
  },
  [StaffPermissionEnum.Impersonate]: {
    label: "Impersonate",
  },
  [StaffPermissionEnum.ImpersonateTrialAndDemo]: {
    label: "Impersonate trial/demo only",
  },
  [StaffPermissionEnum.ManageFeatureAccess]: { label: "Change feature access" },
  [StaffPermissionEnum.ManageTrial]: { label: "Start & extend trials" },
  [StaffPermissionEnum.RenameOrganisation]: { label: "Rename organisations" },
  [StaffPermissionEnum.TeardownOrganisation]: {
    label: "Disable & archive organisations",
  },
  [StaffPermissionEnum.TeardownOnCallForOrganisation]: {
    label: "Disable on-call for organisations",
  },
  [StaffPermissionEnum.EnableOnCallForOrganisation]: {
    label: "Enable on-call for organisations",
  },
  [StaffPermissionEnum.EnableResponseForOrganisation]: {
    label: "Enable response for organisations",
  },
  [StaffPermissionEnum.TeardownResponseForOrganisation]: {
    label:
      "Disable response for organisations and tear down their configuration",
  },
  [StaffPermissionEnum.MarkSandboxAndDemo]: {
    label: "Mark organisations as sandbox/demo",
  },
  [StaffPermissionEnum.GrantOwnerRole]: { label: "Make a user an owner" },
  [StaffPermissionEnum.ViewChatContext]: {
    label: "View Slack chat context while impersonating",
  },
  [StaffPermissionEnum.ImportStatusPageSubscribers]: {
    label: "Import status page subscribers",
  },
};

export const permissionOptions = Object.values(StaffPermissionEnum).map(
  (permission) => ({
    label: permissionName[permission].label,
    value: permission,
  }),
);
