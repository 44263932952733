import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { CheckboxGroupV2 } from "@incident-shared/forms/v2/inputs/CheckboxGroupV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ExpandableContentBox,
  Icon,
  IconEnum,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import {
  Checkbox,
  CheckboxHelptextDisplayEnum,
} from "@incident-ui/Checkbox/Checkbox";
import { groupBy, sortBy } from "lodash";
import React from "react";
import { Path, useForm, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import {
  RBACPrivilege,
  RBACRole,
  ScopeNameEnum,
  UsersCreateRequestBodyBaseRoleSlugEnum as BaseRoleSlugEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

type FormState = {
  name: string;
  description?: string;
  slug?: string;
  privileges: { [key: string]: string[] };
};

export const RoleCreateEditModal = () => {
  const { id: roleId } = useParams();
  const navigate = useOrgAwareNavigate();

  // We have to fetch all roles here, because we can't fetch by ID
  const {
    data: { rbac_roles: roles },
    isLoading: rolesLoading,
  } = useAPI("rBACRolesList", undefined, {
    fallbackData: { rbac_roles: [], rbac_base_role_counts: {} },
  });

  const {
    data: { rbac_privileges: privileges },
    isLoading: privilegesLoading,
  } = useAPI("rBACRolesListPrivileges", undefined, {
    fallbackData: { rbac_privileges: [] },
  });

  const refetchUsers = useRevalidate(["usersList"]);
  const refetchRoles = useAPIRefetch("rBACRolesList", undefined);

  if (rolesLoading || privilegesLoading) {
    // Returning nothing prevents a brief flash of a modal or a spinner
    return null;
  }

  const onClose = () => {
    refetchRoles();
    refetchUsers();
    navigate(`/settings/users/roles`);
  };

  const role = roles.find(({ id }) => id === roleId);
  if (!role) {
    return (
      <Modal
        isOpen
        analyticsTrackingId={null}
        title="Edit role"
        onClose={onClose}
      >
        <ModalContent>That role could not be found.</ModalContent>
      </Modal>
    );
  }

  const props: CreateEditFormProps<RBACRole> = roleId
    ? { mode: Mode.Edit, initialData: role }
    : { mode: Mode.Create };

  return (
    <RoleCreateEditModalInner
      {...props}
      privileges={privileges}
      onClose={onClose}
    />
  );
};

type Props = {
  privileges: RBACPrivilege[];
  onClose: () => void;
} & CreateEditFormProps<RBACRole>;

export const RoleCreateEditModalInner = ({
  initialData: role,
  mode,
  privileges,
  onClose,
}: Props): React.ReactElement | null => {
  const isEditing = mode === Mode.Edit;

  const defaultPrivileges = {};
  if (role) {
    role.privileges.forEach((p) => {
      const rbacPrivilege = privileges.find(
        (privilege) => privilege.name === p,
      );
      if (!rbacPrivilege) {
        return;
      }
      if (!defaultPrivileges[rbacPrivilege.group.name]) {
        defaultPrivileges[rbacPrivilege.group.name] = [];
      }
      defaultPrivileges[rbacPrivilege.group.name].push(rbacPrivilege.name);
    });
  }

  const formMethods = useForm<FormState>({
    defaultValues: role && {
      name: role.name,
      description: role.description,
      slug: role.slug,
      privileges: defaultPrivileges,
    },
    mode: "onBlur",
  });
  const {
    setError,
    formState: { isDirty },
  } = formMethods;

  const { hasScope } = useIdentity();

  // We always mutate the 'list roles' response, since we don't have a 'show
  // role' API.
  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "rBACRolesList",
    undefined,
    async (apiClient, data: FormState) => {
      // Flatten out the groupings
      const privileges = Object.values(data.privileges || {})
        .flat()
        // and remove anything null, undefined, or empty (those shouldn't get here but...)
        .filter((p) => !!p);

      if (role) {
        await apiClient.rBACRolesUpdate({
          id: role.id,
          updateRequestBody: {
            name: data.name,
            description: data.description,
            privileges,
          },
        });
      } else {
        await apiClient.rBACRolesCreate({
          createRequestBody: {
            name: data.name,
            description: data.description,
            privileges,
            slug: data.slug,
          },
        });
      }
    },
    {
      onSuccess: onClose,
      showErrorToast: role ? "Could not save role" : "Could not create role",
      setError,
    },
  );

  // We want everyone to be able to view the modal, along with all the descriptions for each
  // privlege, so we'll just disable the submit button if someone doesn't have permission to
  // make changes.
  let scope: ScopeNameEnum;
  let helpText: string;

  if (role?.is_base_role && isEditing) {
    // Editing a base role.
    scope = ScopeNameEnum.BaseRbacRolesEdit;
    helpText = "You don't have permission to edit the base user roles.";
  } else if (!role?.is_base_role && isEditing) {
    // Editing a custom role.
    scope = ScopeNameEnum.CustomRbacRolesUpdate;
    helpText = "You don't have permission to edit custom user roles.";
  } else if (!role?.is_base_role && !isEditing) {
    // Creating a custom role. Note that we already gate the "create role" button, so this is here
    // for completeness.
    scope = ScopeNameEnum.CustomRbacRolesCreate;
    helpText = "You don't have permission to create custom user roles.";
  } else {
    throw new Error("Invalid role state");
  }

  const canEditSettings = hasScope(scope);
  const disabledHelpText = canEditSettings ? undefined : helpText;

  const privilegeGroups = groupBy(privileges, (p) => p.group.name);

  let title = isEditing ? "Edit role" : "Create custom role";
  if (role?.is_base_role) {
    title = `Edit ${role.name} role`;
    if (role.slug === BaseRoleSlugEnum.User) {
      title = "Edit Standard role";
    }
  }

  return (
    <Form.Modal
      onSubmit={onSubmit}
      formMethods={formMethods}
      onClose={onClose}
      disableQuickClose
      title={title}
      analyticsTrackingId="edit-role"
      footer={
        <ModalFooter
          onClose={onClose}
          saving={saving}
          disabled={!isDirty || !canEditSettings}
          disabledTooltipContent={disabledHelpText}
          confirmButtonType="submit"
        />
      }
    >
      {role?.slug === BaseRoleSlugEnum.User ? (
        <Callout theme={CalloutTheme.Plain}>
          Standard permissions are granted to everyone within your organisation.
        </Callout>
      ) : undefined}

      {role?.is_base_role ? undefined : (
        <>
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Name"
            required="Please provide a name"
          />
          <InputV2
            formMethods={formMethods}
            name="slug"
            label="Slug"
            required={false}
            helptext={
              "A machine friendly way of identifying roles within API requests."
            }
            disabled={isEditing}
            rules={{
              pattern: {
                value: /^[a-z0-9_-]+$/,
                message:
                  "Slugs can only contain lowercase letters, numbers, hyphens, and underscores.",
              },
            }}
          />
          <InputV2
            formMethods={formMethods}
            name="description"
            label="Description"
            required={false}
          />
        </>
      )}
      {Object.keys(privilegeGroups)
        .sort()
        .map((group) => {
          const privileges = privilegeGroups[group];

          return (
            <PrivilegeGroup
              key={group}
              privileges={privileges}
              formMethods={formMethods}
              group={group}
              canEditSettings={canEditSettings}
              role={role}
            />
          );
        })}
    </Form.Modal>
  );
};

const PrivilegeGroup = ({
  privileges,
  group,
  formMethods,
  canEditSettings,
  role,
}: {
  privileges: RBACPrivilege[];
  group: string;
  formMethods: UseFormReturn<FormState>;
  canEditSettings: boolean;
  role?: RBACRole;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const groupLabel = privileges[0].group.label;
  const formPath = `privileges.${group}` as Path<FormState>;

  // This helps us disable the 'manage private incidents' permission for users: doing this
  // would make all private incidents public, which is almost certainly not what someone
  // wants to do.
  const isUserRoleAndManagePrivateIncidentsPrivilege = (
    privilege: RBACPrivilege,
  ): boolean => {
    if (role?.slug !== BaseRoleSlugEnum.User) {
      return false;
    }
    // this should match PrivilegeIDPrivateIncidentsManage
    if (privilege.name !== "private_incidents.manage") {
      return false;
    }
    return true;
  };

  const currentPrivileges = formMethods.watch(formPath) || [];

  const options = sortBy(privileges, "label").map((p) => ({
    value: p.name,
    label: p.label,
    helptext: p.description,
    disabled:
      !canEditSettings || isUserRoleAndManagePrivateIncidentsPrivilege(p),
  }));

  const allAreSelected = options.length === currentPrivileges.length;

  return (
    <ExpandableContentBox
      titleNode={
        <Button
          theme={ButtonTheme.Unstyled}
          title=""
          analyticsTrackingId={null}
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center justify-between w-full cursor-pointer"
        >
          <div className="flex items-center gap-2 cursor-pointer">
            {groupLabel} permissions
            <Badge
              theme={BadgeTheme.Tertiary}
              size={BadgeSize.ExtraSmall}
              className="cursor-pointer"
            >
              {currentPrivileges.length} selected
            </Badge>
          </div>
          <Icon
            id={isExpanded ? IconEnum.Collapse : IconEnum.Expand}
            className="text-content-secondary"
          />
        </Button>
      }
      isExpanded={isExpanded}
    >
      <div className="flex flex-col gap-2">
        <Checkbox
          id={group}
          label="Select all"
          onChange={() =>
            formMethods.setValue(
              formPath,
              allAreSelected ? [] : options.map((o) => o.value),
              { shouldDirty: true },
            )
          }
          checked={allAreSelected}
        />
        <hr />
        <CheckboxGroupV2
          formMethods={formMethods}
          name={formPath}
          helptextDisplay={CheckboxHelptextDisplayEnum.Tooltip}
          options={options}
          inputClassName="space-y-1 mt-1"
        />
      </div>
    </ExpandableContentBox>
  );
};
