import { useCallback, useState } from "react";
import { Edge, Node } from "reactflow";

export const useHoveringState = ({ edges }: { edges: Edge[] }) => {
  const [hoveringNodesAndEdges, setHoveringNodesAndEdges] = useState<{
    nodeIds: Set<string>;
    edgeIds: Set<string>;
  } | null>(null);

  const onHovering = useCallback(
    ({
      hoveredEdge,
      hoveredNode,
    }:
      | { hoveredEdge: Edge; hoveredNode?: never }
      | { hoveredEdge?: never; hoveredNode: Node }) => {
      setHoveringNodesAndEdges((prev) => {
        const updated = buildHighlightedNodesAndEdges(
          edges,
          hoveredNode ?? null,
          hoveredEdge ?? null,
        );
        // If there's no actual change, return the previous value and skip a render
        if (updated == null) {
          return null;
        }
        if (prev == null) {
          return updated;
        }
        if (
          setsEq(prev.nodeIds, updated.nodeIds) &&
          setsEq(prev.edgeIds, updated.edgeIds)
        ) {
          return prev;
        }

        // Fine! Something new
        return updated;
      });
    },
    [edges],
  );

  const onHoverEnd = useCallback(() => {
    setHoveringNodesAndEdges(null);
  }, []);
  const onHoveringNode = useCallback(
    (_, node: Node) => onHovering({ hoveredNode: node }),
    [onHovering],
  );
  const onHoveringEdge = useCallback(
    (_, edge: Edge) => onHovering({ hoveredEdge: edge }),
    [onHovering],
  );

  return { hoveringNodesAndEdges, onHoveringNode, onHoveringEdge, onHoverEnd };
};

// buildHighlightedNodesAndEdges is a helper function that takes a list of edges
// and two optional hovered elements, a node and an edge.
//
// It returns either null (meaning nothing should be highlighted) or an object
// with two sets of strings, nodeIds and edgeIds, that represent the nodes and
// edges that should be highlighted.
const buildHighlightedNodesAndEdges = (
  edges: Edge[],
  hoveredNode: Node | null,
  hoveredEdge: Edge | null,
): { nodeIds: Set<string>; edgeIds: Set<string> } | null => {
  // If we're not hovering over anything, don't do anything
  if (!hoveredEdge && !hoveredNode) {
    return null;
  }

  const result: { nodeIds: Set<string>; edgeIds: Set<string> } = {
    nodeIds: new Set(),
    edgeIds: new Set(),
  };

  // If we're hovering over an edge, and this node is this edge's source or target, i.e. the source or route connected,
  // don't reduce the opacity.
  if (hoveredEdge) {
    result.edgeIds.add(hoveredEdge.id);
    result.nodeIds.add(hoveredEdge.source);
    result.nodeIds.add(hoveredEdge.target);
  }

  // If we're hovering over a node:
  if (hoveredNode) {
    // highlight that node
    result.nodeIds.add(hoveredNode.id);

    // and all edges from it
    edges.forEach((edge) => {
      if (edge.source === hoveredNode.id || edge.target === hoveredNode.id) {
        result.edgeIds.add(edge.id);
        // and all their connected nodes
        result.nodeIds.add(edge.source);
        result.nodeIds.add(edge.target);
      }
    });
  }

  return result;
};

const setsEq = (a: Set<string>, b: Set<string>) => {
  if (a.size !== b.size) {
    return false;
  }

  return Array.from(a).every((item) => b.has(item));
};
