/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotThreadScoring,
    CopilotThreadScoringFromJSON,
    CopilotThreadScoringFromJSONTyped,
    CopilotThreadScoringToJSON,
} from './CopilotThreadScoring';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CopilotThread
 */
export interface CopilotThread {
    /**
     * The classification of the thread
     * @type {string}
     * @memberof CopilotThread
     */
    classification?: CopilotThreadClassificationEnum;
    /**
     * When the thread was created.
     * @type {Date}
     * @memberof CopilotThread
     */
    created_at: Date;
    /**
     * External ID of the thread, which includes the channel ID and thread timestamp
     * @type {string}
     * @memberof CopilotThread
     */
    external_id: string;
    /**
     * 
     * @type {User}
     * @memberof CopilotThread
     */
    from_user?: User;
    /**
     * The incident this thread is related to
     * @type {number}
     * @memberof CopilotThread
     */
    incident_external_id?: number;
    /**
     * Total cost of all interactions in the thread
     * @type {number}
     * @memberof CopilotThread
     */
    overall_cost_cents: number;
    /**
     * The request types for this thread
     * @type {Array<string>}
     * @memberof CopilotThread
     */
    request_types?: Array<string>;
    /**
     * 
     * @type {CopilotThreadScoring}
     * @memberof CopilotThread
     */
    scores?: CopilotThreadScoring;
    /**
     * The initial message that triggered the copilot interaction
     * @type {string}
     * @memberof CopilotThread
     */
    source_message: string;
}

/**
* @export
* @enum {string}
*/
export enum CopilotThreadClassificationEnum {
    Question = 'question',
    Action = 'action',
    Both = 'both',
    Unsure = 'unsure'
}

export function CopilotThreadFromJSON(json: any): CopilotThread {
    return CopilotThreadFromJSONTyped(json, false);
}

export function CopilotThreadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotThread {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'created_at': (new Date(json['created_at'])),
        'external_id': json['external_id'],
        'from_user': !exists(json, 'from_user') ? undefined : UserFromJSON(json['from_user']),
        'incident_external_id': !exists(json, 'incident_external_id') ? undefined : json['incident_external_id'],
        'overall_cost_cents': json['overall_cost_cents'],
        'request_types': !exists(json, 'request_types') ? undefined : json['request_types'],
        'scores': !exists(json, 'scores') ? undefined : CopilotThreadScoringFromJSON(json['scores']),
        'source_message': json['source_message'],
    };
}

export function CopilotThreadToJSON(value?: CopilotThread | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classification': value.classification,
        'created_at': (value.created_at.toISOString()),
        'external_id': value.external_id,
        'from_user': UserToJSON(value.from_user),
        'incident_external_id': value.incident_external_id,
        'overall_cost_cents': value.overall_cost_cents,
        'request_types': value.request_types,
        'scores': CopilotThreadScoringToJSON(value.scores),
        'source_message': value.source_message,
    };
}

