import { Product } from "@incident-shared/billing";
import { FollowUpCreateModal } from "@incident-shared/follow-ups";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import type { Tab } from "@incident-ui";
import {
  AddNewButton,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  TabPane,
  TabSection,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { IncidentTimeline } from "src/components/incident-timeline/IncidentTimeline";
import {
  GroundTruthSectionController,
  useGroundTruth,
} from "src/components/workbench/ground-truths/GroundTruthSection";
import {
  IncidentModeEnum,
  IncidentStatusCategoryEnum as IncidentStatusCategory,
  IncidentVisibilityEnum,
  PolicyViolationLevelEnum,
  PolicyViolationPolicyTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useCanBeginImpersonating } from "src/utils/sessions";
import { assertUnreachable } from "src/utils/utils";

import { useNavigateToModal } from "../../../../utils/query-params";
import { IncidentFollowUps } from "../followups/IncidentFollowUps";
import { incidentInEditableStatus } from "../helpers";
import {
  useIncident,
  usePoliciesAndViolations,
  useSuggestedFollowUps,
} from "../hooks";
import { PostIncidentTab } from "../postincident/PostIncidentTab";
import { usePostIncidentFlow } from "../postincidentflow/usePostIncidentFlow";
import { ProductAccessConditionalComponent } from "../sidebar/IncidentSidebar";
import { ActionCreateModal } from "../stacked-lists/actions/ActionCreateModal";
import { DebugViewSuggestions } from "./DebugViewSuggestions";
import { Overview } from "./Overview";

export enum BodyTabs {
  Overview = "overview",
  PostIncident = "post-incident",

  // This is for people who have permission to impersonate only, as it is to help us build
  // AI evaluation datasets.
  Investigations = "investigations",

  // Legacy ones we keep around for funky
  // redirecting
  Updates = "updates",
  Timeline = "timeline",
  Actions = "actions",
  FollowUps = "follow-ups",
  Pulse = "pulse",
}

const redirects: Partial<Record<BodyTabs, { to: BodyTabs; flagKey?: string }>> =
  {
    [BodyTabs.Updates]: { to: BodyTabs.Overview },
    [BodyTabs.Actions]: { to: BodyTabs.Overview },
    [BodyTabs.FollowUps]: {
      to: BodyTabs.PostIncident,
      flagKey: "postmortemsInHouse",
    },
    [BodyTabs.Timeline]: {
      to: BodyTabs.PostIncident,
      flagKey: "postmortemsInHouse",
    },
  };

export const IncidentBody = ({
  incidentId,
  tabBarRef,
}: {
  incidentId: string | null;
  tabBarRef?: React.RefObject<HTMLDivElement>;
}): React.ReactElement => {
  const [showCreateActionModal, setShowCreateActionModal] = useState(false);
  const [showCreateFollowUpModal, setShowCreateFollowUpModal] = useState(false);
  const [timelineMinimized, setTimelineMinimized] = useState<string[]>([]);
  const [timelineCommentsOpen, setTimelineCommentsOpen] = useState<string[]>(
    [],
  );
  const topOfPage = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    topOfPage.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const { incident } = useIncident(incidentId);
  const { policyViolations } = usePoliciesAndViolations(incidentId);
  const { identity } = useIdentity();
  const canImpersonate = useCanBeginImpersonating();
  const { suggestedFollowUps } = useSuggestedFollowUps(incidentId);
  const { numCompleted, numTotal, incidentTasks } =
    usePostIncidentFlow(incidentId);
  const { requiresVerification: groundTruthRequiresVerification } =
    useGroundTruth(incidentId);

  const { hasResponse } = useProductAccess();
  const flags = useFlags();
  const { postmortemsInHouse: featurePostmortemsInHouse } = flags;

  const [currentTab, setCurrentTab] = useState<BodyTabs>(BodyTabs.Overview);
  const navigate = useOrgAwareNavigate();
  const navigateToModal = useNavigateToModal();

  const isClosed =
    incident?.incident_status?.category === IncidentStatusCategory.Closed;

  const onTabChange = (newTab: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("tab", newTab);
    navigate(
      {
        pathname,
        search: newSearch.toString(),
      },
      { replace: true },
    );
    setCurrentTab(newTab as BodyTabs);
  };

  const { search, pathname } = useLocation();

  // Note that we'll show the post-incident tab if the incident is in a post-incident OR closed state.
  const didOptOutOfPostIncidentFlow =
    incident?.did_opt_out_of_post_incident_flow ?? false;
  const shouldShowPostIncidentTab =
    incident?.post_incident_flow_id &&
    !didOptOutOfPostIncidentFlow &&
    incident?.mode !== IncidentModeEnum.Tutorial &&
    incident?.incident_status?.category !== IncidentStatusCategory.Active &&
    hasResponse;

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get("tab") as BodyTabs;

    const defaultTab =
      shouldShowPostIncidentTab &&
      incident?.incident_status.category === IncidentStatusCategory.PostIncident
        ? BodyTabs.PostIncident
        : BodyTabs.Overview;

    setCurrentTab(tab || defaultTab);
  }, [
    search,
    currentTab,
    shouldShowPostIncidentTab,
    incident?.incident_status.category,
  ]);

  const getAccessory = (): React.ReactElement | null => {
    if (!incident) return null;
    if (!incidentInEditableStatus(incident)) return null;

    switch (currentTab) {
      case BodyTabs.Updates:
        return (
          <AddNewButton
            title="Share update"
            analyticsTrackingId="share-update"
            onClick={() => navigateToModal(IncidentHeaderModal.UpdateIncident)}
          />
        );
      case BodyTabs.Actions:
        if (!isClosed) {
          return (
            <AddNewButton
              title="New action"
              analyticsTrackingId="new-action"
              onClick={() => setShowCreateActionModal(true)}
            />
          );
        }
        return null;
      case BodyTabs.FollowUps:
        return (
          <AddNewButton
            title="New follow-up"
            analyticsTrackingId="new-follow-up"
            onClick={() => setShowCreateFollowUpModal(true)}
          />
        );
      case BodyTabs.Timeline:
        return null;
      case BodyTabs.Pulse:
        return null;
      case BodyTabs.PostIncident:
        return null;
      case BodyTabs.Overview:
        return null;
      case BodyTabs.Investigations:
        return null;
      default:
        if (currentTab === "learning") {
          // This is a hack to make the 'learning' tab work, so old URLs lying around still work.
          return null;
        }
        assertUnreachable(currentTab);
    }
    return null;
  };

  const tabs: Tab[] = [];

  tabs.push(
    ...[
      { id: BodyTabs.Overview, label: "Overview" },
      {
        id: BodyTabs.Timeline,
        label: "Timeline",
        className: "intercom-timeline",
        hidden: featurePostmortemsInHouse,
      },
      {
        id: BodyTabs.PostIncident,
        label: "Post-incident",
        hidden: !hasResponse || !featurePostmortemsInHouse,
        violations: policyViolations.filter(
          (v) =>
            v.policy_type === PolicyViolationPolicyTypeEnum.FollowUp &&
            v.level === PolicyViolationLevelEnum.Error,
        )?.length,
        aiSuggestions: suggestedFollowUps.length,
        accessory:
          incidentTasks.length && numCompleted < numTotal ? (
            <Badge theme={BadgeTheme.Info} size={BadgeSize.ExtraSmall}>
              {numCompleted}/{numTotal}
            </Badge>
          ) : null,
      },
      {
        id: BodyTabs.FollowUps,
        label: "Follow-ups",
        className: "intercom-follow-ups",
        violations: policyViolations.filter(
          (v) =>
            v.policy_type === PolicyViolationPolicyTypeEnum.FollowUp &&
            v.level === PolicyViolationLevelEnum.Error,
        )?.length,
        aiSuggestions: suggestedFollowUps.length,
        hidden: !hasResponse || featurePostmortemsInHouse,
      },
      {
        id: BodyTabs.Investigations,
        label: "Investigations",
        // We only show investigations for impersonation staff, as it's about building our
        // AI datasets not for customer use (yet).
        violations: groundTruthRequiresVerification ? 1 : 0,
        aiSuggestions: 0,
        hidden: !canImpersonate,
      },
    ],
  );

  const defaultChatChannelType = identity.ms_teams_info
    ? "Microsoft Teams"
    : "Slack";

  const primaryCommsChannelMissing =
    incident != null &&
    incident?.slack_channel_url == null &&
    incident?.ms_teams_channel_url == null;

  // Here we perform redirects from legacy tabs to the new versions, while also
  // factoring in any appropriate feature flags.
  useEffect(() => {
    const { to, flagKey } = redirects[currentTab as BodyTabs] ?? {};
    if (!to) {
      return;
    }
    if (flagKey && !flags[flagKey]) {
      return;
    }

    onTabChange(to);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, featurePostmortemsInHouse]);

  return (
    <div className="grow pb-16 pt-3" id="incident-content" ref={topOfPage}>
      {primaryCommsChannelMissing && (
        <Callout
          theme={CalloutTheme.Warning}
          className="mb-4 !bg-orange-50 !border !border-orange-400"
        >
          <div className="text-content-primary font-medium text-sm mb-1">
            We couldn&apos;t create a {defaultChatChannelType} Channel
          </div>
          <div className="text-xs space-y-2 max-w-xl">
            <div>
              This usually means {defaultChatChannelType} is having some form of
              outage. We&apos;ll keep trying to create the channel.
            </div>
            <div>
              In the meantime, share updates here to keep the rest of your team
              updated. All non {defaultChatChannelType} automations, such as
              subscriptions and workflows, will run as normal.
            </div>
            <ProductAccessConditionalComponent
              requiredProduct={Product.Response}
            >
              <div>
                {incident.call_url ? (
                  <>
                    You can also{" "}
                    <a href={incident.call_url} className="underline">
                      join the incident call.
                    </a>
                  </>
                ) : (
                  <>
                    You can also{" "}
                    <Button
                      analyticsTrackingId={"set-call-url"}
                      theme={ButtonTheme.Naked}
                      className="text-xs"
                      onClick={() =>
                        navigateToModal(IncidentHeaderModal.UpdateCall)
                      }
                    >
                      add an incident call
                    </Button>
                    .
                  </>
                )}
              </div>
            </ProductAccessConditionalComponent>
          </div>
        </Callout>
      )}
      <TabSection
        withIndicator
        replaceForSingleTab={<div className="border-b border-stroke -mt-2" />}
        value={currentTab}
        onTabChange={(newTab) => {
          // Sort out the URL
          onTabChange(newTab);

          // Scroll to the top of the page
          scrollToTop();
        }}
        tabClassName="!text-sm text-content-tertiary !py-2 !px-1 !-mb-px"
        tabs={tabs}
        tabBarAccessory={getAccessory()}
        tabBarRef={tabBarRef}
        tabBarClassName="border-b border-stroke px-4 md:px-8 mb-0 -mt-2"
      >
        <TabPane tabId={BodyTabs.Overview}>
          {incidentId && <DebugViewSuggestions incidentId={incidentId} />}
          <Overview incident={incident} onTabChange={onTabChange} />
        </TabPane>
        <TabPane tabId={BodyTabs.Timeline}>
          <IncidentTimeline
            incidentId={incidentId}
            minimizedItems={timelineMinimized}
            setMinimizedItems={setTimelineMinimized}
            commentBoxOpen={timelineCommentsOpen}
            setCommentBoxOpen={setTimelineCommentsOpen}
            className="pt-2"
          />
        </TabPane>
        <TabPane tabId={BodyTabs.PostIncident}>
          <PostIncidentTab incidentId={incidentId} />
        </TabPane>
        <ProductAccessConditionalComponent requiredProduct={Product.Response}>
          <TabPane
            tabId={BodyTabs.FollowUps}
            className="intercom-follow-ups-body"
          >
            <div className="mt-2">
              <IncidentFollowUps incidentId={incidentId} />
            </div>
          </TabPane>
        </ProductAccessConditionalComponent>
        <TabPane tabId={BodyTabs.Investigations}>
          <div className={"flex flex-col space-y-2 pt-3"}>
            <h2 className={"text-2xl-bold"}>Ground truth</h2>
            <div className={"text-content-secondary"}>
              We use ground truth data for evaluating investigations. Please set
              these values after closing an incident to help us evaluate
              investigation accuracy!
            </div>
            {incidentId ? (
              <GroundTruthSectionController incidentId={incidentId} />
            ) : null}
          </div>
        </TabPane>
      </TabSection>
      <AnimatePresence>
        {incident && showCreateActionModal && (
          <ActionCreateModal
            incidentId={incident.id}
            onClose={() => setShowCreateActionModal(false)}
            isPrivateIncident={
              incident.visibility === IncidentVisibilityEnum.Private
            }
          />
        )}
        {incident && showCreateFollowUpModal && (
          <FollowUpCreateModal
            incidentId={incident.id}
            onClose={() => setShowCreateFollowUpModal(false)}
            isPrivateIncident={
              incident.visibility === IncidentVisibilityEnum.Private
            }
          />
        )}
      </AnimatePresence>
    </div>
  );
};
