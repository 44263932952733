import {
  AIConfigEnabledFeaturesEnum,
  AIToggleFeatureRequestBodyFeatureEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
} from "../../ai/AIFeatureConfigurationDrawer";
import {
  AIPrivacyButton,
  AISuggestionFeatureDrawerProps,
  ToggleSuggestionButton,
} from "./SuggestionAICards";

export const SuggestedFollowUpsDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: aiConfigResp } = useAPI("aIShowConfig", undefined);

  const { trigger: toggleAIFeature } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { enabled }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: {
          feature: AIToggleFeatureRequestBodyFeatureEnum.SuggestFollowups,
          enabled,
        },
      }),
    {
      onSuccess: () => {
        onClose();
      },
      showErrorToast: "Toggling suggested follow-ups",
    },
  );

  const enabled = aiConfigResp?.config.enabled_features.includes(
    AIConfigEnabledFeaturesEnum.SuggestFollowups,
  );

  return (
    <AIFeatureConfigurationDrawer
      loading={!aiConfigResp}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleSuggestionButton
                enabled={!!enabled}
                disableModalTitle="Disable suggested follow-ups"
                disableModalContent="This means you'll no longer see suggested follow-ups in your incident channels or the dashboard"
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                onEnable={() => toggleAIFeature({ enabled: true })}
                onDisable={() => toggleAIFeature({ enabled: false })}
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Suggest follow-ups"
              description="Suggests follow-ups that you might have missed during the incident"
              icon={IconEnum.FollowUps}
            />
            <AIProductMarketingCard
              title="Available in Slack"
              description="We'll send a thread to the incident channel after an incident is resolved"
              icon={IconEnum.SlackGreyscale}
            />
          </AIProductMarketingCards>
        </>
      }
      width="medium"
    />
  );
};
