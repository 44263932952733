import type { SVGProps } from "react";
import * as React from "react";
const SvgUserAi = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#161618"
      fillRule="evenodd"
      d="M9.586 9a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-.001 1a7.002 7.002 0 0 0-6.394 4.167 2.237 2.237 0 0 0 .008 1.825 2.294 2.294 0 0 0 1.399 1.239 16.571 16.571 0 0 0 8.58.374l-.105-.212-1.327-.663a2.249 2.249 0 0 1-.001-4.027h.001l1.327-.664.42-.84A6.982 6.982 0 0 0 9.585 10Zm5.085 2.198-.479.959-1.776.888a.749.749 0 0 0 0 1.342l1.776.888.464.928.424.848a.75.75 0 0 0 1.342 0l.888-1.776 1.776-.888a.749.749 0 0 0 0-1.342l-1.776-.888-.888-1.776c-.254-.508-1.088-.508-1.342 0l-.409.817Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserAi;
