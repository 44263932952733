import { DateTime, Duration, Settings } from "luxon";

// This removes nulls from Luxon's return types. We use type-checking to catch
// these issues at build time.
Settings.throwOnInvalid = true;
declare module "luxon" {
  interface TSSettings {
    throwOnInvalid: true;
  }
}

export function format(ts: DateTime, format?: FormatOptions): string {
  return formatTimestampLocale(ts, format);
}

export function formatDateYyyyMmDd(timestamp: DateTime): string {
  return timestamp.toFormat("yyyy-MM-dd HH:mm");
}

export type FormatOptions =
  | "timeOnly"
  | "dateOnly"
  | "full"
  | "compact"
  | "month"
  | "intervalFull"
  | "intervalShort";

const FORMAT_OPTIONS: { [key in FormatOptions]: Intl.DateTimeFormatOptions } = {
  full: {
    year: "numeric" as const,
    month: "short" as const,
    day: "numeric" as const,
    weekday: "short" as const,
    hour: "2-digit" as const,
    minute: "2-digit" as const,
  },
  compact: {
    year: "numeric" as const,
    month: "short" as const,
    day: "numeric" as const,
    hour: "2-digit" as const,
    minute: "2-digit" as const,
  },
  dateOnly: {
    year: "numeric" as const,
    month: "short" as const,
    day: "numeric" as const,
    weekday: "short" as const,
  },
  timeOnly: {
    hour: "2-digit",
    minute: "2-digit",
  },
  month: {
    month: "short",
    year: "numeric",
  },
  intervalFull: {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  intervalShort: {
    hour: "numeric",
    minute: "numeric",
  },
};

// Returns a string representation of a date and time, formatted
// according to the user's locale.
// if no timezone is provided, it will format in the local timezone.
// e.g. timestamp 2022-01-21T15:55:00Z
// en-GB 21/01/2022, 15:55:00
// en-US 01/21/2022, 3:55:00 PM
// de-DE 21.01.2022, 15:55:00
export function formatTimestampLocale(
  timestamp: DateTime,
  format: FormatOptions = "full",
): string {
  return timestamp.toLocaleString({
    ...FORMAT_OPTIONS[format],
  });
}

// toDateString ignores time zone and locale info, and outputs a "date ID",
// assuming that all DateTimes in our app are in the same zone (which they
// should be if we've used useTime correctly!).
//
// This should not be shown to users, but is useful for things like React keys
// or grouping things, where performance matters but presentation doesn't.
export function toDateString(dt: DateTime) {
  return `${dt.year}::${dt.month}::${dt.day}`;
}

const FIELD_NAMES = {
  years: "y",
  months: "M",
  weeks: "w",
  days: "d",
  hours: "h",
  minutes: "m",
  seconds: "s",
} as const;
type DurationPart = keyof typeof FIELD_NAMES;
const durationFields = Object.keys(FIELD_NAMES) as DurationPart[];

export enum DurationEnum {
  years = 0,
  months = 1,
  weeks = 2,
  days = 3,
  hours = 4,
  minutes = 5,
  seconds = 6,
}

// appropriateDurationFormat rounds a duration| defaulting to 2 significant figures. So
// if something is 2 months, 3 weeks, 5 days, 4 hours etc., we return ['months','weeks'].
// this is designed to be passed to luxon's Duration.toFormat as a format part.
// Hopefully we can find a library to do this for us, because I am not that confident
// that this code will behave in all cases.
export const appropriateDurationFormat = (
  duration: Duration,
  significantFigures?: number,
  minInterval: DurationEnum = DurationEnum.seconds,
): DurationPart[] => {
  duration = duration.normalize().rescale();
  const availableDurationFields = durationFields.slice(0, minInterval + 1);
  // work out the most significant populated field
  let biggestPopulatedFieldIndex = 0;
  for (const field of availableDurationFields) {
    if ((duration[field] as number) > 0) {
      break;
    }
    biggestPopulatedFieldIndex++;
  }
  // Duration is zero under our minInterval - we probably want to
  // show a different message here rather than "0 minutes/hours" etc, so return nothing
  if (biggestPopulatedFieldIndex > minInterval) {
    return [];
  }
  // if the only populated field is seconds lets just return ['seconds']
  if (!availableDurationFields[biggestPopulatedFieldIndex + 1]) {
    return biggestPopulatedFieldIndex === availableDurationFields.length
      ? [availableDurationFields[biggestPopulatedFieldIndex - 1]]
      : [availableDurationFields[biggestPopulatedFieldIndex]];
  }

  significantFigures = significantFigures || 1;

  const durationFormat: DurationPart[] = [];

  for (let i = 0; i < significantFigures && i <= minInterval; i++) {
    const fieldIndex = biggestPopulatedFieldIndex + i;
    if (fieldIndex > availableDurationFields.length - 1) {
      break;
    }

    durationFormat.push(
      availableDurationFields[biggestPopulatedFieldIndex + i],
    );
  }

  return durationFormat;
};

type DurationOptions = {
  max?: Duration;
  significantFigures?: number;
  minInterval?: DurationEnum;
};

export const translatedFormatDurationShort = (
  start: DateTime,
  end: DateTime,
  tranFunc: <TKey extends DurationPart | "just_now">(
    k: TKey,
    opts?: TKey extends DurationPart ? { count: number } : never,
  ) => string,
  options: DurationOptions = {},
): string => {
  const { max, significantFigures, minInterval } = options || {};

  if (end < start) {
    return "-";
  }

  const duration = end.diff(start).normalize().rescale();
  const durationFormat = appropriateDurationFormat(
    duration,
    significantFigures,
    minInterval,
  );

  // If the format is empty, that means we've failed to hit the minimum
  // interval, so should show 'just now' instead.
  if (durationFormat.length === 0) {
    return tranFunc("just_now");
  }

  if (max && duration.toMillis() > max.toMillis()) {
    return formatTimestampLocale(start);
  }

  const durationString = durationFormat
    .map((part) => {
      const count = duration.get(part);
      if (count === 0) return "";

      return tranFunc(part, { count });
    })
    .join(" ");

  if (!durationString) {
    return "-";
  }

  return durationString;
};

// (DateTime).hasSame is surprisingly slow because it uses startOf and endOf
// under the hood which are a weirdly slow. Comparing the same day is quite
// simple!
export function hasSameDay(dt1: DateTime, dt2: DateTime): boolean {
  // DRAGON: if the dates are in different time zones this will do something bad
  // and wrong. However, TimeContext at the top of our app means that all dates
  // should be in the same time zone throughout the app, and even _checking_ a
  // time zone is really quite slow (if it's the `SystemZone` we call
  // `Intl.DateTimeFormat.resolvedOptions()` which takes ~70ms).
  return (
    dt1.year === dt2.year && dt1.month === dt2.month && dt1.day === dt2.day
  );
}

export const daysBetween = (
  startDate: DateTime,
  endDate: DateTime,
): DateTime[] => {
  const res: DateTime[] = [startDate];
  // Ensure we're all in a single zone.
  if (startDate.zone.name !== endDate.zone.name) {
    endDate = endDate.setZone(startDate.zone, { keepLocalTime: true });
  }

  // You might want to try `day < endDate.endOf("day")`. However endOf can be
  // _really_ slow (~70ms), so we avoid it here.
  const isBeforeEnd = (d: DateTime) => {
    // First check for different year and month
    if (d.year > endDate.year) return false;
    if (d.year < endDate.year) return true;
    if (d.month > endDate.month) return false;
    if (d.month < endDate.month) return true;

    // Right, same year and month - is it on or before the end?
    return d.day <= endDate.day;
  };

  for (
    let day = startDate.plus({ day: 1 });
    isBeforeEnd(day);
    day = day.plus({ day: 1 })
  ) {
    res.push(day);
  }
  return res;
};
