import {
  EngineScope,
  PostmortemsCreateTemplateSectionRequestBodyTypeEnum,
  PostmortemTemplate,
  PostmortemTemplateSection,
  PostmortemTemplateSectionConfig,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { FieldSelector } from "@incident-shared/settings/FilterSelector/FilterSelector";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Heading,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { captureException } from "@sentry/react";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAPI, useAPIMutation } from "src/utils/swr";

type PostmortemTemplateSectionEditProps = {
  onClose: () => void;
  template: PostmortemTemplate;
  section?: PostmortemTemplateSection;
  scope: EngineScope;
};

type FormType = Omit<
  PostmortemTemplateSectionConfig,
  "custom_field_ids" // these are in selectedCustomFieldIDs! take care!
>;
export const PostmortemTemplateCustomSectionDrawer = ({
  onClose,
  template,
  section,
  scope,
}: PostmortemTemplateSectionEditProps) => {
  const navigate = useOrgAwareNavigate();

  const isEditing = !!section;

  const {
    data: { custom_fields: customFields },
    isLoading: loadingFields,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  const [selectedCustomFieldIDs, setSelectedCustomFieldIDs] = useState<
    string[]
  >(section?.config?.custom_field_ids || []);

  const formMethods = useForm<FormType>({
    defaultValues: {
      // We're only creating custom sections from this drawer. There probably won't be a drawer for adding timeline / follow-up sections!
      name: section?.config?.name,
      help_text: section?.config?.help_text,
      template: section?.config?.template,
    },
  });

  const { setError } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    template ? "postmortemsShowTemplate" : "postmortemsListTemplates",
    template ? { id: template.id } : {},
    async (apiClient, formData: FormType) => {
      const config: PostmortemTemplateSectionConfig = {
        custom_field_ids: selectedCustomFieldIDs,
        ...formData,
      };

      if (isEditing) {
        await apiClient.postmortemsUpdateTemplateSection({
          id: section?.id,
          updateTemplateSectionRequestBody: {
            config,
          },
        });
      } else {
        await apiClient.postmortemsCreateTemplateSection({
          createTemplateSectionRequestBody: {
            type: PostmortemsCreateTemplateSectionRequestBodyTypeEnum.Custom,
            postmortem_template_id: template.id,
            config,
          },
        });
      }
    },
    {
      onSuccess: (res) => {
        // If there was no template previously (i.e. we were in the "Create" version of the drawer),
        // redirect to the "Edit" version of the drawer for the default template we just created.
        if (!template && "postmortem_templates" in res) {
          const defaultTemplate = (
            res.postmortem_templates as PostmortemTemplate[]
          ).find((t) => t.name === "Default");
          if (defaultTemplate) {
            navigate(
              `/settings/post-mortem/templates-v2/${defaultTemplate.id}/edit`,
            );
          } else {
            captureException(
              new Error(
                "Default template not returned just after we created it",
              ),
            );
          }
        }
        onClose();
      },
      setError,
    },
  );

  if (loadingFields) {
    return (
      <Drawer width="medium" onClose={onClose}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  return (
    <AnimatePresence>
      <Drawer onClose={onClose} width="medium" side="right">
        <DrawerContents>
          <DrawerTitle
            title={`${isEditing ? "Edit" : "Create"} section`}
            onClose={onClose}
            titleAccessory={
              <IconBadge
                icon={IconEnum.Doc}
                size={IconSize.Small}
                color={ColorPaletteEnum.Red}
              />
            }
          />
          <DrawerBody className="h-full overflow-y-auto">
            <Form.Root
              id="create-edit-section"
              formMethods={formMethods}
              onSubmit={onSubmit}
              saving={saving}
              genericError={genericError}
            >
              <InputV2
                formMethods={formMethods}
                name="name"
                label="Name"
                placeholder="Impact"
              />
              <TemplatedTextInputV2
                inputClassName="min-h-40"
                formMethods={formMethods}
                name="help_text"
                label="Help text"
                format={"basic"}
                includeVariables={false}
                includeExpressions={false}
                helptext={
                  "Users will see this text when filling out the section. Use this to help them understand what they should be writing."
                }
                required={false}
                placeholder={
                  "What were customers unable to do? How many customers were affected and for how long?"
                }
              />
              <div>
                <Heading
                  level={3}
                  size="small"
                  className="font-medium text-sm-bold"
                >
                  Custom fields
                </Heading>
                <Form.Helptext>
                  Choose the custom fields that you want to appear in this
                  section. This allows you to include relevant fields alongside
                  your sections e.g. an &apos;Affected customers&apos; custom
                  field in an &apos;Impact&apos; section. This also encourages
                  responders to keep custom fields up to date when writing the
                  post-mortem.
                </Form.Helptext>
                <FieldSelector
                  options={customFields.map((field) => ({
                    label: field.name,
                    value: field.id,
                  }))}
                  buttonLabel="Add custom field"
                  resourceIcon={IconEnum.CustomField}
                  selectedIDs={selectedCustomFieldIDs}
                  setSelectedItems={setSelectedCustomFieldIDs}
                  noOptionsMessage={"All custom fields have been added"}
                />
              </div>
              <TemplatedTextInputV2
                multiLine
                formMethods={formMethods}
                name="template"
                label="Template"
                format={"rich"}
                includeVariables={true}
                scope={scope}
                includeExpressions={false}
                helptext={
                  "You can optionally choose the starting text for this section, if you'd like it to have a particular structure across all post-mortems."
                }
                required={false}
              />
            </Form.Root>
          </DrawerBody>
          <DrawerFooter className="flex justify-end">
            <Button
              analyticsTrackingId={null}
              onClick={onClose}
              theme={ButtonTheme.Secondary}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              analyticsTrackingId={null}
              loading={formMethods.formState.isSubmitting}
              type="submit"
              form="create-edit-section"
              theme={ButtonTheme.Primary}
            >
              {isEditing ? "Save" : "Create"}
            </Button>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
    </AnimatePresence>
  );
};
