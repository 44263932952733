import {
  EscalationPathTargetTypeEnum,
  ExternalSchedule,
  ExternalScheduleExternalProviderEnum,
  ManagementMeta,
  Schedule,
  ScheduleConfigPayload,
  ScheduleEntry,
  SchedulesUpdateResponseBody,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { SingleTimezoneSelectV2 } from "@incident-shared/forms/v2/inputs/SingleTimezoneSelectV2";
import { isTerraform } from "@incident-shared/management-meta/utils";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { endTimeForTimelinePeriod } from "@incident-shared/schedules/ScheduleOverview/common/types";
import { ScheduleOverviewHeader } from "@incident-shared/schedules/ScheduleOverview/ScheduleOverviewHeader";
import {
  makeQueryParams,
  useScheduleTimeWindowReducer,
} from "@incident-shared/schedules/ScheduleOverview/scheduleTimeWindowReducer";
import { AutoSizer } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/CollapsableContainer";
import { TimelineSection } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineSectionV2";
import {
  AddNewButton,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  DropdownMenu,
  DropdownMenuItem,
  Heading,
  IconEnum,
  IconSize,
  Loader,
  TabPane,
  TabSection,
  Tooltip,
  Txt,
} from "@incident-ui";
import { DrawerBody, DrawerFooter } from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _, { compact } from "lodash";
import { DateTime } from "luxon";
import { useMemo, useRef, useState } from "react";
import { ErrorOption, useFormContext, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { EscalationPathUserTargetFormData } from "src/components/escalation-paths/common/types";
import styles from "src/components/insights/assistant/AssistantOverlay.module.scss";
import { HolidaysPublicConfigurationInput } from "src/components/legacy/on-call/schedules/schedule-create-edit/HolidaysPublicConfigurationInput";
import { useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useNow } from "src/utils/use-now";
import { useRevalidate } from "src/utils/use-revalidate";
import { useDebounce } from "use-debounce";
import { useLocalStorage } from "use-hooks";

import { isOnCallSeatCount } from "../../../../../hooks/useCanPromoteToOnCall";
import { isOnCallUser } from "../../../../settings/users/users/utils";
import {
  OnCallPromotionConfirmationModal,
  PromotionState,
} from "../../common/OnCallPromotionConfirmationModal";
import {
  buildRotationsPayload,
  CURRENT_VERSION_ID,
  isCurrentVersion,
  scheduleFormDataToCreatePayload,
  scheduleFormDataToUpdatePayload,
} from "../common/marshall";
import { RotaFormData, RotaVersionId, ScheduleFormData } from "../common/types";
import { useHydratedUserCache } from "../common/useHydratedUserCache";
import {
  buildPreviewRotas,
  flattenRotationsRecord,
  getCurrentAndUpcomingRotaVersions,
  getCurrentlyActiveRotas,
  getUpcomingRotaChanges,
  setScheduleError,
} from "../common/util";
import { RotaCreateEditForm } from "./RotaCreateEditForm";
import { RotaUpcomingChangePicker } from "./RotaUpcomingChangePicker";

export const ScheduleCreateEditForm = ({
  initialData,
  managementMeta,
  initialDuplicateData,
  importExternalScheduleData,
  onScheduleSaved,
  initialTab,
  onClose,
  openTerraformDrawer,
  openCalendarFeedDrawer,
  formMethods,
  defaultValues,
  getNewRota,
}: {
  initialData?: Schedule;
  managementMeta: ManagementMeta;
  initialDuplicateData?: Schedule;
  importExternalScheduleData?: ExternalSchedule;
  onScheduleSaved?: (result: Schedule) => void;
  initialTab?: string;
  onClose: () => void;
  openTerraformDrawer: () => void;
  openCalendarFeedDrawer: () => void;
  formMethods: UseFormReturn<ScheduleFormData>;
  defaultValues: ScheduleFormData;
  getNewRota: (index: number) => RotaFormData;
}) => {
  const createMode = !initialData;
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();
  const { identity } = useIdentity();
  const [genericError, setGenericError] = useState<string | null>(null);
  const client = useClient();
  const originalFormData = structuredClone(defaultValues);

  // Because our form structure is different from our API structure, we store the payload so that we can
  // find the rotation ID for index Y once returned in our errors.
  const [configPayload, setConfigPayload] = useState<
    ScheduleConfigPayload | undefined
  >(undefined);
  const [selectedTimezones, setSelectedTimezones] = useLocalStorage<string[]>(
    initialData?.timezone
      ? `${initialData.id}-selected-timezones`
      : "selected-timezones",
    [initialData?.timezone ?? defaultValues.timezone],
  );
  const [currentTab, setCurrentTab] = useState<string>(initialTab ?? "");
  const [promotionState, setPromotionState] =
    useState<PromotionState<ScheduleFormData>>(null);

  const {
    data: { seat_counts: seatCounts },
  } = useAPI("billingListSeatCounts", undefined, {
    fallbackData: { seat_counts: [] },
  });

  const currentSeatCount = seatCounts?.find(isOnCallSeatCount)?.used ?? 0;
  const onCallSeatCountLimit = seatCounts?.find(isOnCallSeatCount)?.limit;

  const revalidate = useRevalidate([
    "schedulesShow",
    "schedulesList",
    "schedulesListForUser",
    "schedulesListVersions",
    "usersTypeahead", // we might promote users!
  ]);

  const formData = formMethods.watch();
  const [timezone, rotations, countryCodes] = formMethods.watch([
    "timezone",
    "rotations",
    "holidays_public_config.country_codes",
  ]);

  const now = useNow(timezone);

  const flattenedRotas = flattenRotationsRecord(rotations);
  const currentlyActiveRotas = flattenedRotas.filter((rota) =>
    isCurrentVersion(rota.version_id),
  );
  const currentAndUpcomingRotas = getCurrentAndUpcomingRotaVersions(
    flattenedRotas,
    now,
  );

  // We track this so if you delete rotas, we just keep counting upwards
  const [rotaCounter, setRotaCounter] = useState(
    (currentlyActiveRotas.length ??
      importExternalScheduleData?.native_config?.rotations.length ??
      1) + 1,
  );

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  const allUsers = _.uniq(
    Object.values(rotations)
      .flatMap((versions) => Object.values(versions))
      .flatMap((rotation) => rotation.users)
      .map((user) => user.id),
  );
  const allUsersInCurrentOrUpcomingRota = _.uniq(
    currentAndUpcomingRotas.flatMap((r) => r.users.map((u) => u.id)),
  );
  const userCache = useHydratedUserCache(allUsers);

  const { trigger: createSchedule, isMutating: createSaving } = useAPIMutation(
    "schedulesShow",
    // We don't actually want to send anything to the query cache,
    // we just want to pass the response to the onSuccess
    { id: "" },
    async (
      apiClient,
      data: ScheduleFormData & { userIdsToPromote: string[] },
    ) => {
      const payload = scheduleFormDataToCreatePayload(
        data,
        data.userIdsToPromote,
      );
      setConfigPayload(payload.createRequestBody.config);
      const res = await apiClient.schedulesCreate(payload);
      userCache.removeFromCache(data.userIdsToPromote);
      return res;
    },
    {
      onSuccess: (res) => {
        showToast({
          title: `Schedule created`,
          theme: ToastTheme.Success,
          toastSide: ToastSideEnum.Bottom,
        });
        revalidate();
        if (onScheduleSaved) {
          onScheduleSaved(res.schedule);
        } else {
          navigate(`/on-call/schedules/${res.schedule.id}`);
        }
        onClose();
      },
      onError: (error, fieldErrors) => {
        showToast({
          title: "Failed to create schedule",
          theme: ToastTheme.Error,
          toastSide: ToastSideEnum.Bottom,
        });
        if (!fieldErrors) {
          setGenericError(error.message);
        }
      },
      setError: (
        path: string,
        error: ErrorOption,
        options?: {
          shouldFocus: boolean;
        },
      ) => {
        setScheduleError(
          formMethods,
          rotations,
          configPayload,
          path,
          error,
          options,
        );
      },
    },
  );

  const { trigger: updateSchedule, isMutating: updateSaving } = useAPIMutation(
    "schedulesShow",
    { id: initialData?.id || "" }, // this will only ever get called when initialData is set
    async (
      apiClient,
      data: ScheduleFormData & { userIdsToPromote: string[] },
    ) => {
      if (!initialData) {
        throw new Error("initial data is null");
      }

      const payload = scheduleFormDataToUpdatePayload(
        initialData?.id || "",
        data,
        initialData,
        initialData?.config?.version || 0,
        data.userIdsToPromote,
        now,
      );
      setConfigPayload(payload.updateRequestBody.config);
      const res = await apiClient.schedulesUpdate(payload);
      userCache.removeFromCache(data.userIdsToPromote);
      return res;
    },
    {
      onSuccess: (res: SchedulesUpdateResponseBody) => {
        showToast({
          title: `Schedule updated`,
          theme: ToastTheme.Success,
          toastSide: ToastSideEnum.Bottom,
        });
        revalidate();
        if (onScheduleSaved) {
          onScheduleSaved(res.schedule);
        } else {
          navigate(`/on-call/schedules/${initialData?.id}`);
        }
        onClose();
      },
      onError: (error, fieldErrors) => {
        showToast({
          title: "Failed to update schedule",
          theme: ToastTheme.Error,
          toastSide: ToastSideEnum.Bottom,
        });
        if (!fieldErrors) {
          setGenericError(error.message);
        }
      },
      setError: (
        path: string,
        error: ErrorOption,
        options?: {
          shouldFocus: boolean;
        },
      ) => {
        setScheduleError(
          formMethods,
          rotations,
          configPayload,
          path,
          error,
          options,
        );
      },
    },
  );

  const realSubmit = async (
    formData: ScheduleFormData,
    userIdsToPromote: string[] = [],
  ) => {
    if (initialData) {
      await updateSchedule({ ...formData, userIdsToPromote });
    } else {
      await createSchedule({ ...formData, userIdsToPromote });
    }
  };

  const handleSubmit = async (formData: ScheduleFormData) => {
    const valid = await formMethods.trigger();
    if (!valid) return;

    const usersToPromote = compact(
      allUsersInCurrentOrUpcomingRota.map(
        (id): EscalationPathUserTargetFormData =>
          ({
            ...userCache.getHydratedUser(id),
            type: EscalationPathTargetTypeEnum.User,
            value: id,
          }) as EscalationPathUserTargetFormData,
      ),
    ).filter((user) => user.value !== "NOBODY" && !isOnCallUser(user.state));

    if (usersToPromote.length > 0) {
      const { requires_billing_scope } =
        await client.billingRequiresBillingScopeToPromoteOnCallResponders({
          requiresBillingScopeToPromoteOnCallRespondersRequestBody: {
            number_of_promotions: usersToPromote.length,
          },
        });
      if (requires_billing_scope) {
        setPromotionState({
          formData,
          usersToPromote,
        });
        return;
      }

      if (
        onCallSeatCountLimit &&
        currentSeatCount + usersToPromote.length > onCallSeatCountLimit
      ) {
        setPromotionState({
          formData,
          usersToPromote,
          requiresSeatCountIncrease: true,
        });
        return;
      }
    }

    realSubmit(
      formData,
      usersToPromote.map((x) => x.value),
    );
  };

  const [debouncedFormData] = useDebounce(formData, 500, {
    equalityFn: _.isEqual,
  });

  const [timeWindowState, timeWindowDispatch] =
    useScheduleTimeWindowReducer(now);
  const currentEndTime = endTimeForTimelinePeriod({
    from: timeWindowState.startTime,
    timePeriod: timeWindowState.timePeriodOption,
  });
  const [from, until] = makeQueryParams(timeWindowState, timezone);

  // Need this to power the previews of the original and edited schedules
  const { data: originalEntriesResp, isLoading: originalEntriesIsLoading } =
    useAPI(
      createMode ? null : "schedulesListEntries", // Only fire this if we're in edit mode
      {
        scheduleIds: [initialData?.id ?? ""],
        from,
        until,
      },
    );

  const resizerRef = useRef<HTMLDivElement | null>(null);

  const { data: editedPreviewResp, isLoading: editedPreviewIsLoading } = useAPI(
    "schedulesPreviewEntries",
    buildSchedulePreviewRequestBody(
      debouncedFormData,
      from,
      until,
      now,
      timezone,
      initialData,
    ),
    {},
  );

  // Power our schedule preview
  const originalPreviewEntries = useMemo(
    () =>
      (originalEntriesResp?._final ?? []).map((e) => ({
        ...e,
        user_id: e.external_user_id,
      })),
    [originalEntriesResp?._final],
  );
  const originalPreviewRotas = buildPreviewRotas(
    originalPreviewEntries,
    flattenRotationsRecord(originalFormData.rotations),
  );

  const editedPreviewEntries = useMemo(
    () =>
      (editedPreviewResp?._final ?? []).map((e) => ({
        ...e,
        user_id: e.external_user_id,
      })),
    [editedPreviewResp],
  );

  const editedPreviewRotas = buildPreviewRotas(
    editedPreviewEntries,
    currentlyActiveRotas,
  );

  // Find who's currently on call to power our handover bump if someone edits the rota
  const currentShiftsByRota = originalEntriesResp?.scheduled.reduce<
    Record<string, ScheduleEntry[]>
  >((acc, entry) => {
    if (
      entry.start_at < new Date() &&
      entry.end_at > new Date() &&
      entry.rotation_id &&
      !acc[entry.rotation_id] // we only want the first layer
    ) {
      if (acc[entry.rotation_id]) {
        acc[entry.rotation_id].push(entry);
      } else {
        acc[entry.rotation_id] = [entry];
      }
    }
    return acc;
  }, {});

  const onClickNewRota = () => {
    const newRota = getNewRota(rotaCounter);
    const versionID: RotaVersionId = newRota.version_id;
    formMethods.setValue(
      `rotations.${newRota.id}`,
      {
        [versionID]: newRota,
      },
      { shouldDirty: true },
    );
    formMethods.setValue(
      `selected_rotation_versions.${newRota.id}`,
      versionID,
      { shouldDirty: true },
    );
    setRotaCounter((prev) => prev + 1);
    setCurrentTab(newRota.id as string);
  };

  const isDisabled = !!initialData;
  const saving = updateSaving || createSaving;
  const isManagedByTerraform = isTerraform(managementMeta);

  const activeUsersOnSchedules = useMemo(() => {
    return _.uniq([
      ...(getCurrentlyActiveRotas({
        rotas: editedPreviewRotas || [],
        now: now,
      })
        .map((r) => r.user_ids)
        .flat() ?? []),
      ...(editedPreviewEntries?.map((e) => e.external_user_id) ?? []),
    ]);
  }, [now, editedPreviewEntries, editedPreviewRotas]);

  const { data: holidaysResponse } = useAPI(
    "schedulesListHolidayEntries",
    {
      from,
      until,
      userIds: activeUsersOnSchedules,
      countryCodes: countryCodes?.map((c) => c.id) ?? [],
    },
    {
      fallbackData: {
        holiday_public_entries: [],
        holiday_user_entries: [],
      },
    },
  );

  const { data: entriesResp } = useAPI(
    initialData ? "schedulesListEntries" : null,
    {
      scheduleIds: initialData ? [initialData.id] : [],
      from,
      until: timeWindowState.startTime.plus({ weeks: 4 }).toISO(),
    },
  );

  if (!identity || userCache.hydrating) {
    return <Loader />;
  }

  return (
    <>
      <DrawerBody className="p-0 overflow-y-hidden">
        <Form.Root
          onSubmit={handleSubmit}
          formMethods={formMethods}
          id="schedule-create-form"
          warnWhenDirty
          outerClassName="flex-1 min-h-0 overflow-y-hidden"
          innerClassName="h-full !space-y-0 flex flex-col min-h-0 overflow-y-hidden"
          loadingWrapperClassName="h-full"
          genericError={genericError}
        >
          <div className="outerbox flex flex-row min-h-0 overflow-y-hidden">
            {/* Left hand, inputs side of the form */}
            <div
              className={tcx(
                "leftsection overflow-auto flex flex-col items-stretch flex-[2] p-6 border-r border-stroke",
                styles.hideScrollbar,
              )}
            >
              <div className={"space-y-6"}>
                {/* Schedule Details */}
                <div className="flex flex-col space-y-2">
                  <Heading level={2} size="medium">
                    Details
                  </Heading>
                  <Txt lightGrey className={"max-w-2xl"}>
                    Choose a name, set the timezone, and import holidays.
                  </Txt>
                  <div className="flex flex-col space-y-6 pt-4">
                    {isManagedByTerraform ? (
                      <Tooltip
                        content={
                          "You cannot edit a schedule that is managed by Terraform"
                        }
                        analyticsTrackingId={null}
                        bubbleProps={{ className: "!w-80" }}
                        side="bottom"
                      >
                        <div className="w-full">
                          <InputV2
                            formMethods={formMethods}
                            name="name"
                            label={
                              <div className="flex items-center space-x-0.5 mb-2">
                                <span>Schedule name</span>
                              </div>
                            }
                            placeholder="Enter your schedule name"
                            className="w-full"
                            required
                            disabled
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <InputV2
                        formMethods={formMethods}
                        name="name"
                        label={"Schedule name"}
                        labelClassName={"!mb-0"}
                        labelAccessory={
                          <Tooltip
                            content={
                              "What is the name of the team or group that will be on this schedule?"
                            }
                            analyticsTrackingId={null}
                            bubbleProps={{
                              className: "max-w-[200px] font-normal",
                            }}
                            buttonClassName="flex-inline"
                            side="right"
                          />
                        }
                        placeholder="Enter your schedule name"
                        autoFocus={true}
                        className="w-full"
                        required
                        disabled={isManagedByTerraform}
                      />
                    )}
                    <div>
                      <SingleTimezoneSelectV2
                        label={"Timezone"}
                        labelClassName={"!mb-0"}
                        labelAccessory={
                          <Tooltip
                            content={
                              "This determines the timezone for viewing and configuring your schedule. It cannot be modified after creation."
                            }
                            analyticsTrackingId={null}
                            bubbleProps={{
                              className: "max-w-[200px] font-normal",
                            }}
                            buttonClassName="flex-inline"
                            side="right"
                          />
                        }
                        name={"timezone"}
                        fullWidth
                        required
                        formMethods={formMethods}
                        disabled={isManagedByTerraform || isDisabled}
                        disabledTooltipContent={
                          isManagedByTerraform
                            ? "This schedule is managed by Terraform"
                            : "A schedule's timezone cannot be changed after creation. You can view your schedule in different timezones from the 3-hour and 1-day views."
                        }
                      />
                      <HolidaysPublicConfigurationInput
                        formMethods={formMethods}
                        openCalendarFeedDrawer={openCalendarFeedDrawer}
                        disabled={isManagedByTerraform}
                        disabledTooltipContent={
                          isManagedByTerraform
                            ? "This schedule is managed by Terraform"
                            : undefined
                        }
                      />
                    </div>
                  </div>
                  {importExternalScheduleData && (
                    <div className={"pt-2"}>
                      {importExternalScheduleData.external_provider ===
                      ExternalScheduleExternalProviderEnum.Pagerduty ? (
                        <Callout theme={CalloutTheme.Info}>
                          This configuration was imported from your{" "}
                          <strong>PagerDuty</strong> schedule and any upcoming
                          overrides will be imported after creation.
                        </Callout>
                      ) : (
                        <Callout theme={CalloutTheme.Warning}>
                          This configuration was imported from your{" "}
                          <strong>Opsgenie</strong>schedule and does not include
                          any overrides.
                        </Callout>
                      )}
                    </div>
                  )}
                  {initialDuplicateData && (
                    <div className={"pt-2"}>
                      <Callout theme={CalloutTheme.Info}>
                        This is a copy of your{" "}
                        <strong>{initialDuplicateData.name}</strong> schedule,
                        overrides are not copied.
                      </Callout>
                    </div>
                  )}
                </div>

                <hr className="my-3" />

                {/* Rota Details */}
                <div className="flex flex-col gap-6">
                  <div className={"flex flex-col gap-2"}>
                    <div className={"text-base font-semibold leading-normal"}>
                      Settings
                    </div>
                    <div
                      className={"text-content-secondary text-sm leading-tight"}
                    >
                      Define how your schedule will work, and who will be on it.
                    </div>
                  </div>

                  <TabSection
                    withIndicator
                    tabs={currentlyActiveRotas.map((x, i) => {
                      return {
                        label: x.name || `Rota ${i + 1}`,
                        id: x.id as string,
                      };
                    })}
                    tabBarClassName={tcx("border-b border-stroke", {
                      hidden: currentlyActiveRotas.length === 1,
                    })}
                    tabBarAccessory={
                      <AddNewButton
                        title="Add rotation"
                        analyticsTrackingId="schedules-add-rota"
                        className="text-sm font-medium text-slate-400 !ml-2"
                        onClick={onClickNewRota}
                      />
                    }
                    value={
                      // Make sure the tab param is valid, otherwise default to the first tab
                      Object.keys(rotations).includes(currentTab)
                        ? currentTab
                        : Object.keys(rotations)[rotaCounter - 1]
                    }
                    onTabChange={setCurrentTab}
                  >
                    {currentlyActiveRotas.map((rota) => {
                      const rotaVersionId = formMethods.watch(
                        `selected_rotation_versions.${rota.id}`,
                      );
                      return (
                        <TabPane
                          tabId={rota.id as string}
                          key={rota.id}
                          className={tcx({
                            "mt-6": currentlyActiveRotas.length > 1,
                          })}
                        >
                          <RotaUpcomingChangePicker
                            formMethods={formMethods}
                            rotaID={rota.id as string}
                            now={now}
                          />
                          <RotaCreateEditForm
                            key={rotaVersionId}
                            initialData={initialData}
                            formMethods={formMethods}
                            timezone={timezone}
                            rotaId={rota.id as string}
                            rotaVersionId={rotaVersionId}
                            onAddNewRota={
                              currentlyActiveRotas.length === 1
                                ? onClickNewRota
                                : undefined
                            }
                            onDelete={
                              currentlyActiveRotas.length > 1
                                ? () => {
                                    // We use unregister rather than resetField as react-hook-form
                                    // doesn't seem to play well with resetField when it's a record.
                                    formMethods.unregister(
                                      `rotations.${rota.id}`,
                                    );
                                    formMethods.unregister(
                                      `selected_rotation_versions.${rota.id}`,
                                    );
                                    setRotaCounter((prev) => prev - 1);
                                    setCurrentTab(Object.keys(rotations)[0]);
                                  }
                                : undefined
                            }
                            onCancelUpcomingChange={
                              !isCurrentVersion(rotaVersionId)
                                ? () => {
                                    formMethods.setValue(
                                      `selected_rotation_versions.${rota.id}`,
                                      CURRENT_VERSION_ID,
                                    );
                                    // We use unregister rather than resetField as react-hook-form
                                    // doesn't seem to play well with resetField when it's a record.
                                    formMethods.unregister(
                                      `rotations.${rota.id}.${rotaVersionId}`,
                                    );
                                  }
                                : undefined
                            }
                            hideNameInput={currentlyActiveRotas.length === 1}
                            userCache={userCache}
                            isRotaCreateMode={
                              !initialData?.config?.rotations.find(
                                (r) => r.id === rota.id,
                              )
                            }
                            currentShifts={
                              rota.id
                                ? currentShiftsByRota?.[rota.id]
                                : undefined
                            }
                            scheduledEntries={entriesResp?.scheduled.filter(
                              (e) => e.rotation_id === rota.id,
                            )}
                            isManagedByTerraform={isManagedByTerraform}
                          />
                        </TabPane>
                      );
                    })}
                  </TabSection>
                </div>
              </div>
            </div>

            {/* Right hand, preview side of the form */}
            {/*Schedule preview*/}
            <div
              className={tcx(
                "rightsection overflow-auto flex flex-col items-stretch flex-[2] p-6 bg-slate-50",
                styles.hideScrollbar,
              )}
            >
              <div className="flex flex-col space-y-2 flex-1">
                <AutoSizer ref={resizerRef}>
                  {({ width }) => (
                    <div className="flex flex-col space-y-2 mb-4 h-full">
                      <ScheduleOverviewHeader
                        className={"mb-3"}
                        timeWindowState={timeWindowState}
                        timeWindowDispatch={timeWindowDispatch}
                        scheduleDefaultTimezone={timezone}
                        showCalendarOption={false}
                        selectedTimezones={selectedTimezones}
                        setSelectedTimezones={setSelectedTimezones}
                      />
                      {/* existing - Only display in edit mode */}
                      {!createMode && (
                        <TimelineSection
                          title="Previous schedule"
                          width={width}
                          now={timeWindowState.now}
                          scheduleId={initialData?.id}
                          rotations={originalPreviewRotas}
                          isLoadingEntries={originalEntriesIsLoading}
                          timelineStartPoint={timeWindowState.startTime}
                          holidaysResponse={holidaysResponse}
                          timelineEndpoint={currentEndTime}
                          timePeriod={timeWindowState.timePeriodOption}
                          entries={originalPreviewEntries}
                          scheduleTimezone={timezone}
                          selectedTimezones={selectedTimezones}
                          upcomingRotaChanges={getUpcomingRotaChanges(
                            initialData?.config?.rotations || [],
                            now,
                          )}
                          disableOverride
                          collapsable={true}
                          collapseByDefault={true}
                        />
                      )}
                      {/* new */}
                      <TimelineSection
                        title={createMode ? "Overview" : "Updated schedule"}
                        now={timeWindowState.now}
                        rotations={editedPreviewRotas}
                        isLoadingEntries={editedPreviewIsLoading}
                        timelineStartPoint={timeWindowState.startTime}
                        timelineEndpoint={currentEndTime}
                        timePeriod={timeWindowState.timePeriodOption}
                        scheduleId={initialData?.id}
                        holidaysResponse={holidaysResponse}
                        entries={editedPreviewEntries}
                        width={width}
                        scheduleTimezone={timezone}
                        selectedTimezones={selectedTimezones}
                        upcomingRotaChanges={getUpcomingRotaChanges(
                          flattenRotationsRecord(rotations),
                          now,
                        )}
                        disableOverride
                        collapsable={false}
                      />
                    </div>
                  )}
                </AutoSizer>
              </div>
            </div>
          </div>
        </Form.Root>
      </DrawerBody>

      <DrawerFooter className="flex justify-end gap-3">
        <Button
          onClick={() => onCloseWithWarn(isDirty)}
          analyticsTrackingId={
            initialData ? "schedules-edit-cancel" : "schedules-create-cancel"
          }
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        {!createMode && isManagedByTerraform ? (
          /* Export to Terraform button */
          <Button
            analyticsTrackingId="schedule-sync-with-terraform"
            icon={IconEnum.Terraform}
            iconProps={{
              className: "!text-terraform-purple-500",
            }}
            onClick={openTerraformDrawer}
          >
            Export
          </Button>
        ) : (
          <ScheduleCreateEditSplitButton
            createMode={createMode}
            saving={saving}
            openTerraformDrawer={openTerraformDrawer}
            handleSubmit={() => handleSubmit(formData)}
          />
        )}
      </DrawerFooter>
      <OnCallPromotionConfirmationModal
        noun="schedule"
        state={promotionState}
        onClose={() => setPromotionState(null)}
        onSubmit={realSubmit}
      />
    </>
  );
};

const ScheduleCreateEditSplitButton = ({
  createMode,
  saving,
  openTerraformDrawer,
  handleSubmit,
}: {
  createMode: boolean;
  saving: boolean;
  openTerraformDrawer: () => void;
  handleSubmit: () => void;
}) => {
  const { watch } = useFormContext<ScheduleFormData>();

  const scheduleName = watch("name");

  return (
    <div className={"inline-flex items-center gap-[1px]"}>
      <Button
        type="submit"
        form="schedule-create-form"
        analyticsTrackingId={
          createMode ? "schedules-create-schedule" : "schedules-update-schedule"
        }
        theme={ButtonTheme.Primary}
        className="ml-auto mr-auto !rounded-r-none"
        loading={saving}
      >
        {createMode ? "Create schedule" : "Save"}
      </Button>
      <DropdownMenu
        triggerButton={
          <Button
            theme={ButtonTheme.Primary}
            type="button"
            className="!px-2 !rounded-l-none"
            analyticsTrackingId="manage-schedule-options-click"
            icon={IconEnum.ChevronUp}
            iconProps={{
              size: IconSize.Medium,
              className: "text-content-white",
            }}
            title="Manage schedule options"
          />
        }
        align={"end"}
      >
        <DropdownMenuItem
          analyticsTrackingId={null}
          onSelect={handleSubmit}
          label={createMode ? "Create schedule" : "Save schedule"}
          icon={createMode ? IconEnum.Add : IconEnum.Checkmark}
          iconProps={{
            size: IconSize.Medium,
          }}
        />
        <DropdownMenuItem
          analyticsTrackingId={null}
          onSelect={openTerraformDrawer}
          disabled={scheduleName === ""}
          tooltipContent={
            scheduleName === ""
              ? createMode
                ? "Please enter a schedule name before creating in Terraform"
                : "Please enter a schedule name before exporting to Terraform"
              : undefined
          }
          icon={IconEnum.Terraform}
          label={createMode ? "Create in Terraform" : "Export to Terraform"}
          iconProps={{
            size: IconSize.Small,
          }}
        >
          {createMode ? "Create in Terraform" : "Export to Terraform"}
        </DropdownMenuItem>
      </DropdownMenu>
    </div>
  );
};

const buildSchedulePreviewRequestBody = (
  formData: ScheduleFormData,
  from: string,
  until: string,
  now: DateTime,
  timezone: string,
  initialData?: Schedule,
) => {
  const rotas = buildRotationsPayload(
    formData,
    now,
    initialData?.config?.rotations,
  );

  return {
    previewEntriesRequestBody: {
      schedule_id: initialData?.id,
      config: {
        rotations: rotas,
      } as ScheduleConfigPayload,
      from,
      until,
      overrides: [],
      timezone: timezone,
    },
  };
};
