import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { ErrorMessage } from "@incident-ui";
import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import { useFormContext } from "react-hook-form";
import { useNodeFormErrors } from "src/components/escalation-paths/nodes/useNodeFormErrors";
import {
  EscalationPathNodePayloadTypeEnum,
  EscalationPathNodeTypeEnum as NodeTypes,
  EscalationPathTargetTypeEnum,
  EscalationPathTargetUrgencyEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import {
  EscalationPathFormData,
  EscalationPathTimeToAckOption,
  NodeLevel as NodeLevelData,
  PathNode,
} from "../common/types";
import { shouldDisplayWorkingHoursTimeToAck } from "../node-editor/helpers/displayWorkingHoursTimeToAck";
import { getLevelCount } from "../node-editor/helpers/getNodeCount";
import { EscalationPathTargetSelect } from "./EscalationPathTargetSelect";
import {
  NodeCard,
  NodeCardDropdown,
  NodeConversionTargetsEnum,
} from "./NodeCard";
import { RoundRobinForm } from "./RoundRobinForm";
import { TimeToAckForm, timeToAckOptions } from "./TimeToAckForm";

// NodeLevel is used for all Level nodes in an escalation path, and is a wrapper around the box element.
export const NodeLevel = ({ id }: { id: string }) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const nodes = formMethods.watch("nodes");
  const firstNodeId = formMethods.watch("firstNodeId");

  const urgency = formMethods.watch(`nodes.${id}.data.level.urgency`);
  const targets = formMethods.watch(`nodes.${id}.data.level.targets`);

  const relatedErrors = useNodeFormErrors({
    formMethods,
    id,
    nodeType: NodeTypes.Level,
  });

  const levelCount = getLevelCount(nodes, firstNodeId, id);

  const shouldAllowWorkingHoursTimeToAck = shouldDisplayWorkingHoursTimeToAck(
    nodes,
    nodes[id],
    firstNodeId,
  );

  const urgencyOptionToDescription = {
    [EscalationPathTargetUrgencyEnum.Low]: "Send low urgency notifications",
    [EscalationPathTargetUrgencyEnum.High]: "Send high urgency notifications",
  };

  const urgencyOptionToLabel = {
    [EscalationPathTargetUrgencyEnum.Low]: "Low",
    [EscalationPathTargetUrgencyEnum.High]: "High",
  };

  const urgencyOptions = [
    EscalationPathTargetUrgencyEnum.Low,
    EscalationPathTargetUrgencyEnum.High,
  ].map((urgency) => ({
    value: urgency,
    label: urgencyOptionToLabel[urgency],
    render: ({ onClick }) => {
      return (
        <PopoverItem onClick={onClick}>
          <UrgencyIcon urgency={urgency} />
          <span>{urgencyOptionToDescription[urgency]}</span>
        </PopoverItem>
      );
    },
  }));

  // We should only show the round robin config if you either have more than one
  // target or if you have a single target and it's set to escalate all users on
  // a schedule.
  const showRoundRobinConfig =
    targets.length > 1 ||
    (targets.length === 1 &&
      targets[0].type === EscalationPathTargetTypeEnum.Schedule);

  const level = formMethods.watch(`nodes.${id}.data.level`);

  const timeToAckMinutes = getTimeToAckMinutes(level);

  return (
    <NodeCard
      title={`Level ${levelCount}`}
      subtitle="Notify"
      icon={IconEnum.Siren}
      iconColor={
        urgency === EscalationPathTargetUrgencyEnum.High
          ? { background: "bg-alarmalade-50", icon: "text-brand" }
          : { background: "bg-amber-50", icon: "text-amber-500" }
      }
      actionButton={
        <NodeCardDropdown
          nodeId={id}
          conversions={[
            NodeConversionTargetsEnum.NotifyChannel,
            NodeConversionTargetsEnum.Repeat,
            NodeConversionTargetsEnum.Branch,
          ]}
          canDelete={allowDeleteNode(nodes)}
        />
      }
    >
      <div className="flex flex-col gap-4 mt-4">
        <EscalationPathTargetSelect
          formMethods={formMethods}
          name={`nodes.${id}.data.level.targets`}
        />

        <div className="flex flex-wrap gap-2 items-center">
          <PopoverSingleSelectV2
            options={urgencyOptions}
            sideOffset={1}
            name={`nodes.${id}.data.level.urgency`}
            formMethods={formMethods}
            tooltipContent="Notification urgency"
            tooltipSide="bottom"
            popoverClassName="min-w-[270px] max-w-[270px]"
            renderTriggerNode={({ onClick, selectedOption }) => {
              return (
                <Button
                  onClick={onClick}
                  theme={ButtonTheme.Tertiary}
                  analyticsTrackingId={null}
                  title={selectedOption?.label || "Select urgency"}
                  size={BadgeSize.Medium}
                >
                  {urgency ? <UrgencyIcon urgency={urgency} /> : null}
                  {selectedOption?.label || "Select urgency"}
                </Button>
              );
            }}
          />
          <TimeToAckForm
            id={id}
            formMethods={formMethods}
            nodeType={EscalationPathNodePayloadTypeEnum.Level}
            shouldAllowWorkingHoursTimeToAck={shouldAllowWorkingHoursTimeToAck}
          />
          {showRoundRobinConfig ? (
            <RoundRobinForm
              key={timeToAckMinutes}
              timeToAckMinutes={timeToAckMinutes}
              id={id}
            />
          ) : null}
        </div>

        {relatedErrors.length > 0 ? (
          <ErrorMessage message={relatedErrors[0]} className={"text-xs"} />
        ) : null}
      </div>
    </NodeCard>
  );
};

function getTimeToAckMinutes(level: NodeLevelData | undefined) {
  let timeToAckMinutes: number | undefined;
  if (
    level &&
    level.time_to_ack_option !==
      EscalationPathTimeToAckOption.WorkingHoursInactive &&
    level.time_to_ack_option !==
      EscalationPathTimeToAckOption.WorkingHoursActive
  ) {
    if (
      level.time_to_ack_option === EscalationPathTimeToAckOption.MinutesCustom
    ) {
      if (level.time_to_ack_custom_minutes) {
        timeToAckMinutes = level.time_to_ack_custom_minutes;
      }
    } else if (level.time_to_ack_option) {
      timeToAckMinutes = timeToAckOptions[level.time_to_ack_option].numMinutes;
    }
  }

  return timeToAckMinutes;
}

const UrgencyIcon = ({
  urgency,
}: {
  urgency: EscalationPathTargetUrgencyEnum | EscalationPathTargetUrgencyEnum;
}) => {
  return (
    <div
      className={tcx(
        "rounded-full h-4 w-4 border-2 shrink-0",
        urgency === EscalationPathTargetUrgencyEnum.Low
          ? "bg-yellow-200 border-yellow-500"
          : "bg-alarmalade-200 border-alarmalade-600",
      )}
    />
  );
};

// showDeleteButton determines whether the delete button should be shown on a
// given notifier node (level or notify_channel).
//
// We only require that a path has at least 1 notifier node. This means you can
// build some quite odd paths with lots of branches that all do nothing.
// We allow this since it can be helpful to build out the _structure_ of a path,
// even if you aren't ready to fill in every branch just yet.
export const allowDeleteNode = (nodes: Record<string, PathNode>): boolean => {
  return (
    Object.values(nodes).filter(
      (node) =>
        node.data.type === NodeTypes.Level ||
        node.data.type === NodeTypes.NotifyChannel,
    ).length > 1
  );
};
