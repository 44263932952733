import {
  InvestigationScorecard,
  useAiServiceAiCreateInvestigationScorecard,
  UseAiServiceAiListIncidentGroundTruthsKeyFn,
  useAiServiceAiListInvestigationScorecards,
  UseAiServiceAiListInvestigationScorecardsKeyFn,
} from "@incident-io/query-api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  Icon,
  IconEnum,
  LocalRelativeDateTime,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { tcx } from "src/utils/tailwind-classes";

export const InvestigationScorecardSectionController = ({
  investigationId,
  incidentId,
  className,
}: {
  investigationId: string;
  incidentId: string;
  className?: string;
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data, isLoading } = useAiServiceAiListInvestigationScorecards({
    investigationId: investigationId || "",
  });
  const scorecard = data?.scorecards[0];

  const { mutate: generateScorecard, isPending: isCreating } =
    useAiServiceAiCreateInvestigationScorecard({
      onSettled() {
        // We must fetch the latest scorecards
        queryClient.invalidateQueries({
          queryKey: UseAiServiceAiListInvestigationScorecardsKeyFn({
            investigationId: investigationId || "",
          }),
        });
        // And we need the latest ground truths, as this may have generated them
        queryClient.invalidateQueries({
          queryKey: UseAiServiceAiListIncidentGroundTruthsKeyFn({
            incidentId: incidentId || "",
          }),
        });
      },
      onError: () => {
        toast({
          title: "Failed to generate scorecard",
          theme: ToastTheme.Error,
        });
      },
    });

  const handle = () => {
    generateScorecard({
      requestBody: {
        investigation_id: investigationId || "",
      },
    });
  };

  return (
    <InvestigationScorecardSection
      scorecard={scorecard}
      onGenerateScorecard={handle}
      isGenerating={isCreating || isLoading}
      className={className}
    />
  );
};

export type InvestigationScorecardSectionProps = {
  scorecard?: InvestigationScorecard;
  onGenerateScorecard: () => void;
  isGenerating?: boolean;
  className?: string;
};

export const InvestigationScorecardSection = ({
  scorecard,
  onGenerateScorecard,
  isGenerating,
  className,
}: InvestigationScorecardSectionProps) => {
  if (!scorecard || scorecard.grades.length === 0) {
    const attrs = scorecard
      ? {
          title: "Last scorecard included no grades",
          content:
            "Generate a new scorecard to evaluate the investigation's performance with the latest graders",
        }
      : {
          title: "No scorecard available",
          content:
            "Generate a scorecard to evaluate the investigation's performance",
        };

    return (
      <div className={className}>
        <ContentBox>
          <EmptyState
            {...attrs}
            icon={IconEnum.Chart}
            cta={
              <Button
                onClick={onGenerateScorecard}
                disabled={isGenerating}
                analyticsTrackingId={null}
                icon={IconEnum.Chart}
                loading={isGenerating}
              >
                {isGenerating
                  ? "Generating scorecard..."
                  : "Generate scorecard"}
              </Button>
            }
          />
        </ContentBox>
      </div>
    );
  }

  return (
    <div className={className}>
      <ContentBox>
        <div className="relative">
          <div className="flex justify-between items-center p-4 border-b border-stroke">
            <div className="text-sm text-content-secondary">
              Graded{" "}
              <LocalRelativeDateTime date={new Date(scorecard.graded_at)} />
            </div>

            <div className="flex items-center gap-3">
              <Button
                onClick={onGenerateScorecard}
                disabled={isGenerating}
                analyticsTrackingId={null}
                icon={IconEnum.Chart}
                loading={isGenerating}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
              >
                {isGenerating ? "Generating..." : "Refresh"}
              </Button>
              {scorecard.warnings.length > 0 && (
                <Tooltip
                  content={<WarningsContent warnings={scorecard.warnings} />}
                >
                  <div className="flex items-center gap-1 text-amber-500">
                    <Icon id={IconEnum.AlertPriority} />
                    <span className="text-sm font-medium">
                      {scorecard.warnings.length}
                    </span>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          {scorecard.grades.map((grade, i) => (
            <div key={i} className="p-4">
              <h3 className="text-sm font-medium mb-2">{grade.name}</h3>
              <div className="grid grid-cols-2 gap-4">
                {grade.metrics.map((metric, j) => (
                  <GradeMetric
                    key={j}
                    name={metric.name}
                    score={metric.score}
                    numerator={metric.numerator}
                    denominator={metric.denominator}
                    description={metric.description}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    metadata={metric.metadata as unknown as Record<string, any>}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </ContentBox>
    </div>
  );
};

interface WarningsContentProps {
  warnings: string[];
  className?: string;
}

export const WarningsContent = ({
  warnings,
  className,
}: WarningsContentProps) => {
  if (warnings.length === 0) return null;

  return (
    <div
      className={tcx(
        "p-4 max-w-md bg-slate-800 rounded-lg shadow-lg",
        className,
      )}
    >
      <div className="text-base text-white font-medium mb-3">Warnings</div>
      <ul className="space-y-2">
        {warnings.map((warning, index) => (
          <li key={index} className="text-sm text-slate-400">
            {warning}
          </li>
        ))}
      </ul>
    </div>
  );
};

type GradeMetricProps = {
  name: string;
  score?: number;
  numerator?: number;
  denominator?: number;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: Record<string, any>;
  className?: string;
};

const GradeMetric = ({
  name,
  score,
  numerator,
  denominator,
  description,
  metadata,
  className,
}: GradeMetricProps) => {
  const indicator = getGradeIndicator(score);
  const hasRatio = numerator !== undefined && denominator !== undefined;
  const scoreDisplay = _.isUndefined(score)
    ? "N/A"
    : `${(score * 100).toFixed(1)}%`;

  return (
    <div className={tcx("flex items-start gap-2 p-2", className)}>
      <Icon id={indicator.icon} className={tcx("mt-1", indicator.color)} />
      <div className="flex-1">
        <div className="flex items-center gap-2 flex-wrap">
          <span className="font-medium">{name}</span>

          {metadata ? (
            <Popover>
              <PopoverTrigger asChild>
                <button
                  className={tcx(
                    indicator.color,
                    indicator.bgColor,
                    "px-2 py-0.5 rounded transition-colors",
                    "hover:opacity-90",
                  )}
                >
                  {scoreDisplay}
                </button>
              </PopoverTrigger>
              <PopoverContent
                className={tcx(
                  "min-w-72 max-w-120 p-4",
                  "bg-white dark:bg-gray-950", // Ensure solid background
                  "border border-border", // Consistent border
                  "shadow-lg", // Stronger shadow
                  "rounded-md", // Consistent rounding
                  "z-50",
                )}
                sideOffset={5}
                collisionPadding={20}
              >
                <h4 className="font-medium text-sm mb-3">Metadata</h4>
                <MetadataContent metadata={metadata} />
              </PopoverContent>
            </Popover>
          ) : (
            <span className={indicator.color}>{scoreDisplay}</span>
          )}

          {hasRatio && (
            <Badge theme={BadgeTheme.Secondary} className="text-xs">
              {numerator} / {denominator}
            </Badge>
          )}
        </div>
        <div className="text-xs text-gray-500 mt-0.5">{description}</div>
      </div>
    </div>
  );
};

type GradeIndicator = {
  icon: IconEnum;
  color: string;
  bgColor: string;
};

const getGradeIndicator = (score: number | undefined): GradeIndicator => {
  if (score === undefined) {
    return {
      icon: IconEnum.Circle,
      color: "text-gray-400",
      bgColor: "bg-gray-100",
    };
  }

  if (score >= 0.7) {
    return {
      icon: IconEnum.TickCircle,
      color: "text-green-500",
      bgColor: "bg-green-50",
    };
  }

  if (score >= 0.4) {
    return {
      icon: IconEnum.Circle,
      color: "text-amber-500",
      bgColor: "bg-amber-50",
    };
  }

  return {
    icon: IconEnum.WarningCircle,
    color: "text-red-500",
    bgColor: "bg-red-50",
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MetadataContent = ({ metadata }: { metadata: Record<string, any> }) => {
  return (
    <div className="grid gap-2">
      {Object.entries(metadata).map(([key, value]) => (
        <div key={key} className="grid grid-cols-2 gap-2">
          <span className="text-xs font-medium text-muted-foreground capitalize">
            {key.replace(/_/g, " ")}:
          </span>
          <span className="text-xs">
            {typeof value === "object"
              ? JSON.stringify(value)
              : value.toString()}
          </span>
        </div>
      ))}
    </div>
  );
};
