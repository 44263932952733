import { WeekdayIntervalWeekdayEnum } from "@incident-io/api";

export type ScheduleFormData = {
  name: string;
  timezone: string;
  rotations: Record<RotaId, RotationVersions>;
  selected_rotation_versions: SelectedRotationVersionValues;
  external_schedule_id?: string;
  holidays_public_config: {
    // ID here really just means the country code, but react-hook-form's useFieldArray
    // wants an object with an ID key, when I really just want to use a string.
    country_codes: { id: string }[];
  };
};

export type RotaId = string;
export type RotaVersionId = string;

export type RotationVersions = Record<RotaVersionId, RotaFormData>;

// This is a map of RotaId: RotaVersionId.
export type SelectedRotationVersionValues = {
  [key: string]: string;
};

export type RotaFormData = {
  id?: string; // TODO(leo-later): This could probably be required
  // This version_id acts as a fingerprint when we marshall from the API to the form data. You should _not_
  // be modifying this. rotaToFormData will take care of initialising it for you. Use isCurrentVersion to
  // do checks like 'is this rotation version the current one?'
  version_id: string;
  name: string;
  users: { id: string }[];
  handover_start_at: Date;
  layer_count: number;
  has_working_intervals: "all_day" | "specific_times";
  working_intervals: IntervalData;
  custom_handovers: CustomHandoverRule[];
  rota_handover_type: RotaHandoverType;
  is_deferred: "true" | "false";
  effective_from?: Date;
};

export type WorkingInterval = {
  days: {
    [key in WeekdayIntervalWeekdayEnum]: boolean;
  };
  start_time: string;
  end_time: string;
};
export type IntervalData = WorkingInterval[];

export enum RotaHandoverType {
  Daily = "daily",
  Weekly = "weekly",
  Custom = "custom",
}

export enum CustomHandoverRuleType {
  Daily = "daily",
  Weekly = "weekly",
  Hourly = "hourly",
}

export type CustomHandoverRule = {
  // This is a number, but we have to store it as a string to work with the input component
  handover_interval: string;
  handover_interval_type: CustomHandoverRuleType;
};
