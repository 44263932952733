/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPath,
    EscalationPathFromJSON,
    EscalationPathFromJSONTyped,
    EscalationPathToJSON,
} from './EscalationPath';

/**
 * 
 * @export
 * @interface EscalationPathsCreateExternalResponseBody
 */
export interface EscalationPathsCreateExternalResponseBody {
    /**
     * 
     * @type {Array<EscalationPath>}
     * @memberof EscalationPathsCreateExternalResponseBody
     */
    escalation_paths: Array<EscalationPath>;
}

export function EscalationPathsCreateExternalResponseBodyFromJSON(json: any): EscalationPathsCreateExternalResponseBody {
    return EscalationPathsCreateExternalResponseBodyFromJSONTyped(json, false);
}

export function EscalationPathsCreateExternalResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathsCreateExternalResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_paths': ((json['escalation_paths'] as Array<any>).map(EscalationPathFromJSON)),
    };
}

export function EscalationPathsCreateExternalResponseBodyToJSON(value?: EscalationPathsCreateExternalResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_paths': ((value.escalation_paths as Array<any>).map(EscalationPathToJSON)),
    };
}

