/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OverviewActivityDatapoint,
    OverviewActivityDatapointFromJSON,
    OverviewActivityDatapointFromJSONTyped,
    OverviewActivityDatapointToJSON,
} from './OverviewActivityDatapoint';

/**
 * 
 * @export
 * @interface AlertsGetInsightsOverviewActivityResponseBody
 */
export interface AlertsGetInsightsOverviewActivityResponseBody {
    /**
     * Daily metrics for the time period
     * @type {Array<OverviewActivityDatapoint>}
     * @memberof AlertsGetInsightsOverviewActivityResponseBody
     */
    datapoints: Array<OverviewActivityDatapoint>;
    /**
     * The timestamp that this data is valid from - note that we cache insights queries so this may be earlier than GetLastSync reports
     * @type {Date}
     * @memberof AlertsGetInsightsOverviewActivityResponseBody
     */
    last_sync_at: Date;
}

export function AlertsGetInsightsOverviewActivityResponseBodyFromJSON(json: any): AlertsGetInsightsOverviewActivityResponseBody {
    return AlertsGetInsightsOverviewActivityResponseBodyFromJSONTyped(json, false);
}

export function AlertsGetInsightsOverviewActivityResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsGetInsightsOverviewActivityResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoints': ((json['datapoints'] as Array<any>).map(OverviewActivityDatapointFromJSON)),
        'last_sync_at': (new Date(json['last_sync_at'])),
    };
}

export function AlertsGetInsightsOverviewActivityResponseBodyToJSON(value?: AlertsGetInsightsOverviewActivityResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoints': ((value.datapoints as Array<any>).map(OverviewActivityDatapointToJSON)),
        'last_sync_at': (value.last_sync_at.toISOString()),
    };
}

