/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianConnectAppInstallationInfo,
    AtlassianConnectAppInstallationInfoFromJSON,
    AtlassianConnectAppInstallationInfoFromJSONTyped,
    AtlassianConnectAppInstallationInfoToJSON,
} from './AtlassianConnectAppInstallationInfo';
import {
    JiraUser,
    JiraUserFromJSON,
    JiraUserFromJSONTyped,
    JiraUserToJSON,
} from './JiraUser';

/**
 * 
 * @export
 * @interface JiraCloudConfig
 */
export interface JiraCloudConfig {
    /**
     * All installations of connect app in Jira sites for the current organisation
     * @type {Array<AtlassianConnectAppInstallationInfo>}
     * @memberof JiraCloudConfig
     */
    connect_app_installations: Array<AtlassianConnectAppInstallationInfo>;
    /**
     * 
     * @type {JiraUser}
     * @memberof JiraCloudConfig
     */
    connecting_user?: JiraUser;
    /**
     * Whether the current organisation has a connection to Jira Cloud
     * @type {boolean}
     * @memberof JiraCloudConfig
     */
    has_access_token: boolean;
    /**
     * If set, this indicates an issue with the connection
     * @type {string}
     * @memberof JiraCloudConfig
     */
    reconnection_reason: JiraCloudConfigReconnectionReasonEnum;
    /**
     * site id that Jira Cloud integration is using
     * @type {string}
     * @memberof JiraCloudConfig
     */
    site_id: string;
}

/**
* @export
* @enum {string}
*/
export enum JiraCloudConfigReconnectionReasonEnum {
    Empty = '',
    PagerdutyLimitedUser = 'pagerduty_limited_user',
    GithubMissingScopes = 'github_missing_scopes',
    TokenExpired = 'token_expired',
    InvalidConfig = 'invalid_config',
    InsufficientPermissions = 'insufficient_permissions',
    IncompleteConfig = 'incomplete_config',
    SalesforceTooManyAccounts = 'salesforce_too_many_accounts',
    MultipleAccounts = 'multiple_accounts',
    NotACalendarUser = 'not_a_calendar_user',
    MissingScheduleWriteScope = 'missing_schedule_write_scope',
    GmeetUnavailableOnPrimaryCalendar = 'gmeet_unavailable_on_primary_calendar'
}

export function JiraCloudConfigFromJSON(json: any): JiraCloudConfig {
    return JiraCloudConfigFromJSONTyped(json, false);
}

export function JiraCloudConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraCloudConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connect_app_installations': ((json['connect_app_installations'] as Array<any>).map(AtlassianConnectAppInstallationInfoFromJSON)),
        'connecting_user': !exists(json, 'connecting_user') ? undefined : JiraUserFromJSON(json['connecting_user']),
        'has_access_token': json['has_access_token'],
        'reconnection_reason': json['reconnection_reason'],
        'site_id': json['site_id'],
    };
}

export function JiraCloudConfigToJSON(value?: JiraCloudConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connect_app_installations': ((value.connect_app_installations as Array<any>).map(AtlassianConnectAppInstallationInfoToJSON)),
        'connecting_user': JiraUserToJSON(value.connecting_user),
        'has_access_token': value.has_access_token,
        'reconnection_reason': value.reconnection_reason,
        'site_id': value.site_id,
    };
}

