import { TimePeriodOption } from "@incident-shared/schedules/ScheduleOverview/common/types";
import { DragContext } from "@incident-shared/schedules/ScheduleOverview/DragToOverrideIndicator";
import { TimelineMarkerContainer } from "@incident-shared/schedules/ScheduleOverview/TimelineMarker/TimelineMarkerContainerV2";
import { ROTA_NAME_COLUMN_WIDTH } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/constants";
import { UpcomingRotaChange } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineSectionV2";
import * as d3 from "d3";
import { DateTime, IANAZone } from "luxon";
import { useContext } from "react";
import { tcx } from "src/utils/tailwind-classes";

export const TimezoneRow = ({
  isFirst,
  isLast,
  tz,
  segments,
  timePeriod,
  timelineStartPoint,
  timelineEndpoint,
  isInSmallScreen,
  upcomingRotaChanges,
  xScale,
  now,
  hasMultipleTimezones,
  hasWrapper,
  noBottomBorder,
}: {
  noBottomBorder?: boolean;
  hasMultipleTimezones: boolean;
  now: DateTime;
  isFirst: boolean;
  isLast: boolean;
  xScale: d3.ScaleTime<number, number>;
  tz: string;
  segments: DateTime[];
  timePeriod: TimePeriodOption;
  timelineStartPoint: DateTime;
  timelineEndpoint: DateTime;
  isInSmallScreen: boolean;
  upcomingRotaChanges?: UpcomingRotaChange[];
  // hasWrapper controls whether the parent timeline is wrapped in a rounded view, or is full width
  hasWrapper?: boolean;
}) => {
  const { dragState, currentTime: currentTimeBeingHovered } =
    useContext(DragContext);

  const tzCity = tz.includes("/") ? tz.split("/")[1].replaceAll("_", " ") : tz;

  const timezoneZoneName = IANAZone.create(tz).offsetName(
    timelineStartPoint.toMillis(),
    {
      format: "short",
    },
  );

  return (
    <div
      className={tcx("relative flex flex-row border-stroke items-center", {
        "border-t": hasWrapper,
      })}
    >
      {/* Bottom border */}
      {noBottomBorder ? null : isLast ? (
        <div
          className={"absolute left-0 right-0 bottom-0 border-stroke border-b"}
        />
      ) : null}

      {/* First we render the empty space for the rota name column */}
      <div
        className={tcx("text-center flex items-center h-full", {
          "px-4": hasWrapper,
          "px-8": !hasWrapper,
        })}
        style={{
          width: ROTA_NAME_COLUMN_WIDTH,
          maxWidth: ROTA_NAME_COLUMN_WIDTH,
          minWidth: ROTA_NAME_COLUMN_WIDTH,
        }}
      >
        {!isInSmallScreen && (
          <span className="text-xs text-slate-400 font-normal">
            {tzCity} ({timezoneZoneName})
          </span>
        )}
      </div>

      {/* Then we render each of the headings */}
      <div id="date-headings" className={tcx("flex-1 h-8 relative")}>
        {segments.map((segment) => {
          return (
            <DateHeading
              key={segment.toISO()}
              timePeriod={timePeriod}
              dateTime={segment}
              isInSmallScreen={isInSmallScreen ?? false}
              xScale={xScale}
              // We always have several segments, they're all equal widths
              // so just use the first two to calculate the width
              width={
                xScale(segments[1].toJSDate()) - xScale(segments[0].toJSDate())
              }
            />
          );
        })}

        {/*  Render upcoming change markers */}
        {isLast && upcomingRotaChanges && (
          <TimelineMarkerContainer
            xScale={xScale}
            timelineStartAt={timelineStartPoint}
            timelineEndAt={timelineEndpoint}
            upcomingChanges={upcomingRotaChanges}
          />
        )}

        {/* Render the current time */}
        {now > timelineStartPoint && now < timelineEndpoint && (
          <TimezoneTimestampIndicator
            timezone={tz}
            isFirst={isFirst}
            xScale={xScale}
            timestamp={now}
            hasMultipleTimezones={hasMultipleTimezones}
            colorScheme={"red"}
            showIndicatorLine
          />
        )}

        {/* If we're currently drag-to-override-ing, show the bounds */}
        {dragState != null ? (
          <>
            <TimezoneTimestampIndicator
              timezone={tz}
              isFirst={isFirst}
              xScale={xScale}
              timestamp={
                // Render the non-moving one first
                dragState.draggingOn === "start"
                  ? dragState.dragEndTime
                  : dragState.dragStartTime
              }
              hasMultipleTimezones={hasMultipleTimezones}
              colorScheme={"slate"}
            />
            <TimezoneTimestampIndicator
              timezone={tz}
              isFirst={isFirst}
              xScale={xScale}
              timestamp={
                // ... then the moving one
                dragState.draggingOn === "start"
                  ? dragState.dragStartTime
                  : dragState.dragEndTime
              }
              hasMultipleTimezones={hasMultipleTimezones}
              colorScheme={"slate"}
            />
          </>
        ) : (
          currentTimeBeingHovered &&
          currentTimeBeingHovered > timelineStartPoint && (
            <TimezoneTimestampIndicator
              timezone={tz}
              isFirst={isFirst}
              xScale={xScale}
              timestamp={currentTimeBeingHovered}
              hasMultipleTimezones={hasMultipleTimezones}
              colorScheme={"slate"}
            />
          )
        )}
      </div>
    </div>
  );
};

function DateHeading({
  dateTime,
  timePeriod,
  xScale,
  isInSmallScreen,
  width,
}: {
  xScale: d3.ScaleTime<number, number>;
  dateTime: DateTime;
  timePeriod: TimePeriodOption;
  isInSmallScreen: boolean;
  width: number;
}) {
  let formatted: string;
  if (
    [
      TimePeriodOption.OneWeek,
      TimePeriodOption.TwoWeeks,
      TimePeriodOption.OneMonth,
    ].includes(timePeriod)
  ) {
    formatted = dateTime.toLocaleString({
      day: width > 30 ? "numeric" : undefined,
      weekday: "narrow",
    });
  } else if (timePeriod === TimePeriodOption.ThreeHours) {
    formatted = width > 20 ? dateTime.toFormat("H:mm") : dateTime.toFormat("H");
  } else if (timePeriod === TimePeriodOption.OneDay) {
    formatted =
      width > 45
        ? dateTime.toLocaleString(DateTime.TIME_SIMPLE)
        : dateTime.toFormat("H");
  } else {
    formatted = dateTime.toFormat("H:mm");
  }

  return (
    <div
      style={{
        position: "absolute",
        left: xScale(dateTime.toJSDate()),
        top: 0,
        bottom: 0,
        width,
      }}
      className={tcx(
        "text-xs text-slate-400 text-center font-normal flex items-center justify-center h-full text-ellipsis max-w-full",
        {
          // If we're showing one day, there will be many
          // sections, which will mean they'll likely be at their minimum
          // width on the screen.
          // "tabular-nums" means that all timestamps will be the same width
          // e.g. "11:11" and "22:55". This doesn't look as pretty though,
          // so we only do it for the one day view.
          "tabular-nums": timePeriod === TimePeriodOption.OneDay,
          "whitespace-pre-line":
            isInSmallScreen && timePeriod === TimePeriodOption.OneMonth,
        },
      )}
    >
      {formatted}
    </div>
  );
}

const TimezoneTimestampIndicator = ({
  xScale,
  timestamp,
  isFirst,
  timezone,
  colorScheme,
  showIndicatorLine,
}: {
  xScale: d3.ScaleTime<number, number>;
  timestamp: DateTime;
  isFirst: boolean;
  timezone: string;
  hasMultipleTimezones: boolean;
  colorScheme: "red" | "slate";
  showIndicatorLine?: boolean;
}) => {
  let timezoneName = timestamp.setZone(timezone).toFormat("z");
  if (timezoneName.indexOf("/") > -1) {
    timezoneName = timezoneName.split("/")[1].replaceAll("_", " ");
  }

  return (
    <>
      {showIndicatorLine && (
        <div
          style={{
            position: "absolute",
            left: xScale(timestamp.toJSDate()),
            top: isFirst ? "50%" : 0,
            bottom: 0,
          }}
          className={tcx("border-r-[1px] h-full pointer-events-none", {
            "border-r-alarmalade-500": colorScheme === "red",
            "border-r-slate-100": colorScheme === "slate",
          })}
        />
      )}
      <div
        style={{
          position: "absolute",
          left: xScale(timestamp.toJSDate()),
          top: 0,
          bottom: 0,
          transform: "translateX(-50%)",
        }}
        className={"flex items-center whitespace-nowrap z-10"}
      >
        <span
          className={tcx("px-2 py-0.5 rounded font-medium text-xs", {
            "bg-alarmalade-500 text-white": colorScheme === "red",
            "bg-slate-100 text-content-secondary": colorScheme === "slate",
          })}
        >
          {timestamp.setZone(timezone).toFormat(`EEE dd, HH:mm`)}
        </span>
      </div>
    </>
  );
};
