import type { SVGProps } from "react";
import * as React from "react";
const SvgCalendarClock = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.833 4.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75ZM13.333 4.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75Z"
    />
    <path
      fill="currentColor"
      d="M14.833 3h-9.5a2.752 2.752 0 0 0-2.75 2.75v8.5A2.752 2.752 0 0 0 5.333 17h4.141a.75.75 0 0 0 0-1.5h-4.14c-.69 0-1.25-.561-1.25-1.25V8h12v1.524a.75.75 0 0 0 1.5 0V5.75A2.752 2.752 0 0 0 14.833 3Z"
    />
    <path
      fill="currentColor"
      d="M15.083 11c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4Zm2.312 4.95a.749.749 0 0 1-.978.409l-1.619-.665a.751.751 0 0 1-.465-.694v-1.75a.75.75 0 0 1 1.5 0v1.247l1.154.474a.75.75 0 0 1 .408.979Z"
    />
  </svg>
);
export default SvgCalendarClock;
