/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ShoutoutsCreateRequestBody,
    ShoutoutsCreateRequestBodyFromJSON,
    ShoutoutsCreateRequestBodyToJSON,
    ShoutoutsCreateResponseBody,
    ShoutoutsCreateResponseBodyFromJSON,
    ShoutoutsCreateResponseBodyToJSON,
} from '../models';

export interface ShoutoutsCreateRequest {
    createRequestBody: ShoutoutsCreateRequestBody;
}

/**
 * 
 */
export class ShoutoutsApi extends runtime.BaseAPI {

    /**
     * Create a new shoutout
     * Create Shoutouts
     */
    async shoutoutsCreateRaw(requestParameters: ShoutoutsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoutoutsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling shoutoutsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/shoutouts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShoutoutsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShoutoutsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new shoutout
     * Create Shoutouts
     */
    async shoutoutsCreate(requestParameters: ShoutoutsCreateRequest, initOverrides?: RequestInit): Promise<ShoutoutsCreateResponseBody> {
        const response = await this.shoutoutsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
