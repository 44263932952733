import { DateTime } from "luxon";
import { useCallback } from "react";

import { TimePeriodOption } from "./common/types";
import { useKeyboardEvents } from "./common/useKeyboardEvents";
import {
  ScheduleTimeWindowAction,
  ScheduleTimeWindowState,
} from "./scheduleTimeWindowReducer";

export const useKeyboardNavigation = ({
  focusDate,
  timePeriodOptions,
  timeWindowDispatch,
  timeWindowState,
}: {
  focusDate?: DateTime;
  timePeriodOptions: { value: TimePeriodOption; label: string }[];
  timeWindowDispatch: React.Dispatch<ScheduleTimeWindowAction>;
  timeWindowState: ScheduleTimeWindowState;
}) => {
  useKeyboardEvents(
    useCallback(
      (e) => {
        // Don't handle plus/minus if we're in an input
        const activeElement = document.activeElement;
        if (
          activeElement?.tagName === "INPUT" ||
          activeElement?.tagName === "TEXTAREA" ||
          (activeElement && "isContentEditable" in activeElement
            ? activeElement?.isContentEditable
            : false)
        ) {
          return;
        }
        // Existing navigation
        if (e.key === "ArrowLeft") {
          timeWindowDispatch({ type: "previousPageClicked" });
        } else if (e.key === "ArrowRight") {
          timeWindowDispatch({ type: "nextPageClicked" });
        }

        const currentIndex = timePeriodOptions.findIndex(
          (opt) => opt.value === timeWindowState.timePeriodOption,
        );

        // Check for both numpad and regular plus/minus
        const isPlus = e.key === "+" || e.key === "=";
        const isMinus = e.key === "-" || e.key === "_";

        if (isPlus && currentIndex > 0) {
          // Zoom in (shorter time period)
          timeWindowDispatch({
            type: "setTimePeriodOption",
            payload: {
              period: timePeriodOptions[currentIndex - 1].value,
              focusDate,
            },
          });
          e.preventDefault();
        } else if (isMinus && currentIndex < timePeriodOptions.length - 1) {
          // Zoom out (longer time period)
          timeWindowDispatch({
            type: "setTimePeriodOption",
            payload: {
              period: timePeriodOptions[currentIndex + 1].value,
              focusDate,
            },
          });
          e.preventDefault();
        }
      },
      [
        timeWindowDispatch,
        timeWindowState.timePeriodOption,
        timePeriodOptions,
        focusDate,
      ],
    ),
  );
};
