import { ErrorResponse } from "@incident-io/api";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Heading } from "@incident-ui/Heading/Heading";
import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import React, { useEffect } from "react";
import { useDisableNPS } from "src/contexts/SurvicateContext";

export type GenericErrorMessageProps = {
  hideHeading?: boolean;
} & BaseErrorMessageProps;

type BaseErrorMessageProps = {
  description?: string;
  error?: ErrorResponse | Error;
  suggestRefresh?: boolean;
};

export const GenericErrorMessage = ({
  description,
  hideHeading = false,
  suggestRefresh = true,
  error,
}: GenericErrorMessageProps): React.ReactElement => {
  // Log the error if it's set - only log unique errors
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useDisableNPS();

  return (
    <div className="flex flex-col h-full min-h-full justify-center">
      <div className="space-y-4 text-center">
        <span className="font-semibold text-brand">
          /inc something went wrong
        </span>
        {!hideHeading && (
          <Heading level={2} size="2xl" className="mb-4">
            There&apos;s been a problem!
          </Heading>
        )}
        <ErrorMessageInner
          description={description}
          error={error}
          suggestRefresh={suggestRefresh}
        />
      </div>
    </div>
  );
};

export const InlineErrorMessage = (
  props: BaseErrorMessageProps,
): React.ReactElement => {
  return <ErrorMessageInner {...props} />;
};

const ErrorMessageInner = ({
  description,
  error,
  suggestRefresh,
}: BaseErrorMessageProps): React.ReactElement => {
  const showAreFixing = error && error["status"] === 500;

  return (
    <div className="flex flex-col items-center gap-4">
      {description && (
        <span className="text-content secondary max-w-[50ch]">
          {description}
        </span>
      )}
      {showAreFixing ? (
        <span className="text-content secondary max-w-[50ch]">
          Never fear - we&apos;ve already notified our engineers and we&apos;ll
          get it fixed.
        </span>
      ) : (
        <div className="flex flex-col items-center gap-4">
          {suggestRefresh && (
            <span className="text-content secondary max-w-[50ch]">
              Please refresh the page and try again. If the problem persists,
              contact support and we&apos;ll get right on it.
            </span>
          )}
          <div className="flex flex-row justify-center items-center gap-4">
            {suggestRefresh && (
              <Button
                analyticsTrackingId="generic-error-refresh"
                theme={ButtonTheme.Secondary}
                onClick={() => window.location.reload()}
              >
                Refresh page
              </Button>
            )}
            <Button
              analyticsTrackingId="generic-error-help"
              theme={ButtonTheme.Unstyled}
              className="hover:underline"
              href="mailto:help@incident.io"
              openInNewTab
            >
              <span className="font-semibold">Contact support</span>
              <Icon id={IconEnum.ArrowRight} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
