import {
  CatalogType,
  CatalogTypeIconEnum,
  IntegrationSettings,
} from "@incident-io/api";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { getIntegrationStatus } from "@incident-shared/integrations";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  Card,
  EmptyState,
  Heading,
  IconEnum,
  IconSize,
  SlackTeamAvatar,
} from "@incident-ui";
import { IconBadge } from "@incident-ui/IconBadge/IconBadge";
import { sortBy } from "lodash";
import { PlanBadge } from "src/components/settings/PlanBadge";
import { UpsellNotice } from "src/components/settings/UpsellNotice";
import { useIdentity } from "src/contexts/IdentityContext";

import { useCanAddCustomCatalogType } from "../common/useCanAddCustomCatalogType";
import { CatalogHeaderButtons } from "./CatalogHeaderButtons";
import { CatalogTypeCard } from "./CatalogTypeCard";

export const CatalogTypeGroup = ({
  label,
  groupTypes,
  icon,
  group,
  brokenIntegrationsLookup,
  hexColor,
  compactCards = false,
}: {
  label?: string;
  groupTypes: CatalogType[];
  icon?: IconEnum | CatalogTypeIconEnum;
  hexColor?: string;
  group: string;
  compactCards?: boolean;
  brokenIntegrationsLookup: {
    [key: string]: IntegrationSettings;
  };
}) => {
  const { identity } = useIdentity();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-start w-full">
        <Heading level={1} size="medium" className="flex-center-y space-x-2">
          {group === "custom" && identity?.organisation_icon_url ? (
            <IconBadge
              iconNode={
                <SlackTeamAvatar
                  size={IconSize.Large}
                  url={identity.organisation_icon_url}
                />
              }
              size={IconSize.Medium}
              color={ColorPaletteEnum.SlateOnWhite}
            />
          ) : (
            icon && (
              <IconBadge
                icon={icon}
                size={IconSize.Medium}
                color={ColorPaletteEnum.SlateOnWhite}
                hexColor={hexColor}
              />
            )
          )}
          <span className="mr-2">{label}</span>
        </Heading>
        <CatalogHeaderButtons group={group} />
      </div>
      <CatalogTypeCardGrid
        emptyStateText="You have not created any custom types yet"
        types={groupTypes}
        brokenIntegrationsLookup={brokenIntegrationsLookup}
        compact={compactCards}
        isCustomTypes={group === "custom"}
      />
    </div>
  );
};

export const CatalogTypeCardGrid = ({
  emptyStateText,
  types,
  brokenIntegrationsLookup,
  compact = false,
  isCustomTypes,
}: {
  emptyStateText: string;
  types: CatalogType[];
  brokenIntegrationsLookup: {
    [key: string]: IntegrationSettings;
  };
  compact?: boolean;
  isCustomTypes: boolean;
}) => {
  const { isLimited } = useCanAddCustomCatalogType();

  const showUpsellCard = isCustomTypes && isLimited;

  const sortedTypes = sortBy(types, (type) => type.name.toLowerCase());
  return types.length === 0 ? (
    showUpsellCard ? (
      <UpsellNotice
        title="Create your own custom types"
        description="Upgrade your plan to create your own custom types like environments, features and more"
        planName="pro"
        analyticsId="custom-catalog-types-upsell"
        articleId={"2606187127"}
      />
    ) : (
      <EmptyState icon={IconEnum.Book} content={emptyStateText} />
    )
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 items-stretch">
      {sortedTypes.map((type) => {
        const integrationStatus = getIntegrationStatus(
          type,
          brokenIntegrationsLookup,
        );

        return (
          <CatalogTypeCard
            path={slugForCatalogType(type)}
            key={type.name}
            icon={type.icon}
            color={type.color}
            name={type.name}
            description={type.description}
            entries={type.estimated_count}
            integrationStatus={integrationStatus}
            sourceRepoURL={type.source_repo_url}
            compact={compact}
            hasBeenSynced={
              "incident.io/catalog-importer/last-sync-at" in type.annotations
            }
          />
        );
      })}
      {showUpsellCard && (
        <Card
          headerAccessory={<PlanBadge planName="pro" />}
          icon={IconEnum.LockClosed}
          color={ColorPaletteEnum.Slate}
          title="Create your own custom types"
          description="Upgrade your plan to create your own custom types like environments, features and more"
          clickable={false}
          className="border-stroke-secondary"
          footerNode={
            <div className="w-fit">
              <Button
                analyticsTrackingId="custom-catalog-types-upsell"
                href="/settings/billing"
              >
                Upgrade
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
};
