/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestigationScorecard,
    InvestigationScorecardFromJSON,
    InvestigationScorecardFromJSONTyped,
    InvestigationScorecardToJSON,
} from './InvestigationScorecard';

/**
 * 
 * @export
 * @interface AIListInvestigationScorecardsResponseBody
 */
export interface AIListInvestigationScorecardsResponseBody {
    /**
     * Collection of scorecards, ordered by graded_at descending
     * @type {Array<InvestigationScorecard>}
     * @memberof AIListInvestigationScorecardsResponseBody
     */
    scorecards: Array<InvestigationScorecard>;
}

export function AIListInvestigationScorecardsResponseBodyFromJSON(json: any): AIListInvestigationScorecardsResponseBody {
    return AIListInvestigationScorecardsResponseBodyFromJSONTyped(json, false);
}

export function AIListInvestigationScorecardsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIListInvestigationScorecardsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scorecards': ((json['scorecards'] as Array<any>).map(InvestigationScorecardFromJSON)),
    };
}

export function AIListInvestigationScorecardsResponseBodyToJSON(value?: AIListInvestigationScorecardsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scorecards': ((value.scorecards as Array<any>).map(InvestigationScorecardToJSON)),
    };
}

