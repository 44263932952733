/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentGroundTruth,
    IncidentGroundTruthFromJSON,
    IncidentGroundTruthFromJSONTyped,
    IncidentGroundTruthToJSON,
} from './IncidentGroundTruth';

/**
 * 
 * @export
 * @interface AIUpdateIncidentGroundTruthResponseBody
 */
export interface AIUpdateIncidentGroundTruthResponseBody {
    /**
     * 
     * @type {IncidentGroundTruth}
     * @memberof AIUpdateIncidentGroundTruthResponseBody
     */
    ground_truth: IncidentGroundTruth;
}

export function AIUpdateIncidentGroundTruthResponseBodyFromJSON(json: any): AIUpdateIncidentGroundTruthResponseBody {
    return AIUpdateIncidentGroundTruthResponseBodyFromJSONTyped(json, false);
}

export function AIUpdateIncidentGroundTruthResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIUpdateIncidentGroundTruthResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ground_truth': IncidentGroundTruthFromJSON(json['ground_truth']),
    };
}

export function AIUpdateIncidentGroundTruthResponseBodyToJSON(value?: AIUpdateIncidentGroundTruthResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ground_truth': IncidentGroundTruthToJSON(value.ground_truth),
    };
}

