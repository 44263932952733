/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsightsFilter,
    InsightsFilterFromJSON,
    InsightsFilterFromJSONTyped,
    InsightsFilterToJSON,
} from './InsightsFilter';

/**
 * 
 * @export
 * @interface AlertsGetInsightsOverviewActivityRequestBody
 */
export interface AlertsGetInsightsOverviewActivityRequestBody {
    /**
     * The end date of the period we're interested in
     * @type {Date}
     * @memberof AlertsGetInsightsOverviewActivityRequestBody
     */
    end_date: Date;
    /**
     * The filters to apply to the insights
     * @type {Array<InsightsFilter>}
     * @memberof AlertsGetInsightsOverviewActivityRequestBody
     */
    filters: Array<InsightsFilter>;
    /**
     * The start date of the period we're interested in
     * @type {Date}
     * @memberof AlertsGetInsightsOverviewActivityRequestBody
     */
    start_date: Date;
    /**
     * Timezone of the user to fetch data in the correct times
     * @type {string}
     * @memberof AlertsGetInsightsOverviewActivityRequestBody
     */
    timezone: string;
}

export function AlertsGetInsightsOverviewActivityRequestBodyFromJSON(json: any): AlertsGetInsightsOverviewActivityRequestBody {
    return AlertsGetInsightsOverviewActivityRequestBodyFromJSONTyped(json, false);
}

export function AlertsGetInsightsOverviewActivityRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsGetInsightsOverviewActivityRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'end_date': (new Date(json['end_date'])),
        'filters': ((json['filters'] as Array<any>).map(InsightsFilterFromJSON)),
        'start_date': (new Date(json['start_date'])),
        'timezone': json['timezone'],
    };
}

export function AlertsGetInsightsOverviewActivityRequestBodyToJSON(value?: AlertsGetInsightsOverviewActivityRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'end_date': (value.end_date.toISOString()),
        'filters': ((value.filters as Array<any>).map(InsightsFilterToJSON)),
        'start_date': (value.start_date.toISOString()),
        'timezone': value.timezone,
    };
}

