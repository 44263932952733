import { ScopeNameEnum } from "@incident-io/api";
import {
  Gate,
  Product,
  UpgradeRequiredMessage,
} from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import React from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { usePrimaryCommsPlatform } from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";

export const numericGateLimitReached = (
  limit: number | undefined | null,
  current: number,
) => {
  if (limit === undefined || limit == null) {
    // Explicitly do not allow `=== 0` into this branch.
    // An undefined limit is an infinite limit.
    return false;
  }

  return current >= limit;
};

// UpgradeRequiredProps are used to form a sensible message on the tooltip.
export type UpgradeRequiredProps = {
  /** The feature name should be lower-case, as it's used in the middle of a sentence */
  featureName: string;
  gate: Gate;
};

export type GatedInputProps = {
  disabled?: boolean;
  disabledTooltipContent?: React.ReactNode;
  alwaysShownTooltipContent?: React.ReactNode;
  spanClassName?: string;
  requiredScope?: ScopeNameEnum;
  requiredProduct?: Product;
  upgradeRequired?: boolean;
  upgradeRequiredProps?: UpgradeRequiredProps;
};

export const NoPermissionMessage = "You don't have permission to do this";

// GatedButton is a wrapper for Button which handle the various reasons a button might be disabled.
// * If the feature requires a particular user scope, pass the `scopeRequiredToAdd`
// * If the feature requires the organisation to upgrade, pass the `upgradeRequired` bool and `upgradeRequiredProps`
// * If the feature is disabled for some other reason (e.g. isDirty), pass the `disabled` bool and optionally `disabledTooltipContent`
export const useGateInput = ({
  requiredScope,
  requiredProduct,
  disabled,
  disabledTooltipContent,
  alwaysShownTooltipContent,
  upgradeRequired,
  upgradeRequiredProps,
}: GatedInputProps): {
  disabled: boolean;
  tooltip?: React.ReactNode;
} => {
  const commsPlatform = usePrimaryCommsPlatform();
  const { hasScope } = useIdentity();
  const { hasProduct } = useProductAccess();
  const hasRequiredScope = requiredScope ? hasScope(requiredScope) : true;
  const hasRequiredProduct = requiredProduct
    ? hasProduct(requiredProduct)
    : true;
  const inputIsDisabled = disabled || !hasRequiredScope;

  // 1. Is the feature only avaliable to certain products?
  if (requiredProduct && !hasRequiredProduct) {
    return {
      disabled: true,
      tooltip: (
        <ProductRequiredMessage
          requiredProduct={requiredProduct}
          commsPlatform={commsPlatform}
        />
      ),
    };
  }

  // 2. Is the feature upgrade required? Only show if the button would otherwise work.
  if (upgradeRequired && !inputIsDisabled) {
    return {
      disabled: true,
      tooltip: upgradeRequiredProps ? (
        <UpgradeRequiredMessage {...upgradeRequiredProps} />
      ) : (
        // Sensible fallback, if we haven't got the props we expect!
        "You do not have access to this feature on your current plan"
      ),
    };
  }

  // 3. Do you have the required scope?
  if (!hasRequiredScope) {
    return {
      disabled: true,
      tooltip: NoPermissionMessage,
    };
  }

  if (disabledTooltipContent && inputIsDisabled) {
    return {
      disabled: true,
      tooltip: disabledTooltipContent,
    };
  }

  if (alwaysShownTooltipContent) {
    return {
      disabled: inputIsDisabled,
      tooltip: alwaysShownTooltipContent,
    };
  }

  return {
    disabled: inputIsDisabled,
  };
};
