import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ButtonTheme, IconEnum } from "@incident-ui";
import {
  SearchBar,
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useOutlet } from "react-router";
import graphic from "src/components/settings/banners/banner-nudges.svg";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";

import { Plurality, ProductUpsellNotice } from "../../ProductUpsellNotice";
import { SettingsHeading } from "../../SettingsHeading";
import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { UpsellNotice } from "../../UpsellNotice";
import { NudgesList } from "./NudgesList";

export const NudgesListPage = (): React.ReactElement => {
  const drawer = useOutlet();
  const { identity } = useIdentity();
  const { hasProduct } = useProductAccess();

  const [showChooseTypeModal, setShowChooseTypeModal] = useState(false);

  const hasFeatureGate = identity?.feature_gates.custom_nudges;

  return (
    <>
      <AnimatePresence>{drawer}</AnimatePresence>
      <SettingsSubPageWrapper
        accessory={
          hasProduct(Product.Response) ? (
            <GatedButton
              theme={ButtonTheme.Primary}
              onClick={() => setShowChooseTypeModal(true)}
              analyticsTrackingId="create-nudge"
              icon={IconEnum.Add}
              requiredScope={ScopeNameEnum.NudgesCreate}
              requiredProduct={Product.Response}
              disabled={!hasFeatureGate}
              disabledTooltipContent={
                "You need to be on our Pro tier to add a nudge"
              }
            >
              Add nudge
            </GatedButton>
          ) : undefined
        }
      >
        {hasFeatureGate ? (
          <SettingsHeading
            articleId={"2920552262"}
            title="Send timely nudges"
            subtitle="Create and send nudges to incident channels to improve your incident response."
            graphic={<img src={graphic} className="h-40" />}
          />
        ) : (
          <UpsellNotice
            analyticsId={"nudges-upsell-banner"}
            title={"Nudges"}
            planName={"Pro"}
            articleId={"2920552262"}
            description={
              <>
                <p>
                  Nudges can prompt responders to take action, helping to make
                  sure that your organization&apos;s incident response best
                  practices are followed. This is done by sending messages to
                  incident channels.
                </p>
                <p>
                  In order to customise these nudges, and create new ones,
                  you&apos;ll need to be on our Pro tier.
                </p>
              </>
            }
          />
        )}
        {hasProduct(Product.Response) ? (
          <SearchProvider>
            <div className="flex flex-col gap-4">
              <NudgesHeader renameToChannelSuggestion={false} />
              <NudgesList
                showChooseTypeModal={showChooseTypeModal}
                setShowChooseTypeModal={setShowChooseTypeModal}
                hasFeatureGate={hasFeatureGate}
                renameToChannelSuggestion={false}
              />
            </div>
          </SearchProvider>
        ) : (
          <ProductUpsellNotice
            featureName="Nudges"
            plurality={Plurality.Plural}
            requiredProduct={Product.Response}
          />
        )}
      </SettingsSubPageWrapper>
    </>
  );
};

const NudgesHeader = ({
  renameToChannelSuggestion,
  headerAccessory,
}: {
  renameToChannelSuggestion: boolean;
  headerAccessory?: React.ReactNode;
}) => {
  const searchBarProps = useSearchContext();

  return (
    <div className="w-full flex-center-y justify-between">
      <SearchBar
        {...searchBarProps}
        placeholder={renameToChannelSuggestion ? "Search" : "Search nudges"}
        className="mr-auto"
        autoFocus
      />
      {headerAccessory}
    </div>
  );
};
