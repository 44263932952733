import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { SingleTimezoneSelectV2 } from "@incident-shared/forms/v2/inputs/SingleTimezoneSelectV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  Loader,
  OrgAwareLink,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerProps,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { InputType } from "@incident-ui/Input/Input";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { IntegrationConfigFor } from "src/components/@shared/integrations";
import {
  ClientType,
  Incident,
  IncidentModeEnum,
  IntegrationSettingsProviderEnum,
  PostmortemDestination,
  PostmortemDestinationDocumentProviderEnum,
  PostmortemsAttachDocumentRequestBody,
  PostmortemsAttachDocumentRequestBodyDocumentProviderEnum,
  PostmortemSettingsDefaultDocumentProviderCopyPasteEnum,
  PostmortemsExportDocumentRequestBody,
  PostmortemsSettingsShowResponseBody,
  PostmortemsUpdateStatusRequestBodyStatusEnum,
  PostmortemTemplate,
  PostmortemTemplateWritingModeEnum,
} from "src/contexts/ClientContext";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { CopyPostMortemButton } from "./CopyPostMortemButton";

// ExportPostmortemDrawer is the V2 version of CreatePostMortemModal. It is able to export V2 postmortem templates.
export const ExportPostmortemDrawer = ({
  incident,
  templateId,
  overrideDefaultDestinationId,
  onClose,
}: {
  incident: Incident;
  templateId?: string;
  overrideDefaultDestinationId?: string;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data: { destinations },
    isLoading: destinationsLoading,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });

  const {
    data: { postmortem_templates: templates },
    isLoading: templatesLoading,
  } = useAPI(
    "postmortemsListTemplates",
    {
      incidentId: incident.id,
    },
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  const { data: settings, isLoading: settingsLoading } = useAPI(
    "postmortemsSettingsShow",
    undefined,
  );

  const sharedDrawerProps: Omit<DrawerProps, "children"> = {
    onClose,
    width: "small",
  };

  if (destinationsLoading || settingsLoading || !settings || templatesLoading) {
    return (
      <Drawer {...sharedDrawerProps}>
        <Loader />
      </Drawer>
    );
  }

  if (!templateId) {
    if (!templates || templates.length === 0) {
      return <ErrorModal title="No templates found" onClose={onClose} />;
    }
  }

  return (
    <ExportPostmortemDrawerInner
      incident={incident}
      onClose={onClose}
      destinations={destinations}
      templates={templates}
      settings={settings}
      sharedDrawerProps={sharedDrawerProps}
      overrideDefaultDestinationId={overrideDefaultDestinationId}
      incidentTemplateId={incident.postmortem_template_id}
    />
  );
};

type FormData = PostmortemsAttachDocumentRequestBody &
  PostmortemsExportDocumentRequestBody["generation_options"];

const defaultValues = ({
  incident,
  destinations,
  templates,
  settings,
  incidentTemplateId,
  overrideDefaultDestinationId,
}: {
  incident: Incident;
  destinations: PostmortemDestination[];
  templates: PostmortemTemplate[];
  settings: PostmortemsSettingsShowResponseBody;
  incidentTemplateId?: string;
  overrideDefaultDestinationId?: string;
}): Partial<FormData> => {
  // Timezone
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultSettingsTimezone = settings.settings.default_timezone;
  const defaultTimezone = defaultSettingsTimezone || localTimezone;

  // Destinations
  const defaultDestination = destinations.find((d) => d.is_default);
  const defaultTestDestination = destinations.find(
    (d) => d.id === settings.settings.test_or_tutorial_destination_id,
  );
  const isTestOrTutorial =
    incident.mode === IncidentModeEnum.Test ||
    incident.mode === IncidentModeEnum.Tutorial;

  const defaultTemplate = templates.find((t) => t.is_default);

  const defaultDestinationForIncident =
    incident.incident_type?.default_postmortem_destination_id;

  const defaultDestinationId =
    overrideDefaultDestinationId ||
    (isTestOrTutorial ? defaultTestDestination?.id : undefined) ||
    defaultDestinationForIncident ||
    defaultDestination?.id ||
    "copy_and_paste";

  const defaults: Partial<FormData> = {
    timezone: defaultTimezone,
    destination_id: defaultDestinationId,
    postmortem_template_id: incidentTemplateId ?? defaultTemplate?.id,
  };

  return defaults;
};

export async function createInHousePostmortem(
  apiClient: ClientType,
  incident: Incident,
  template: PostmortemTemplate,
) {
  // Set the template for the incident
  await apiClient.postmortemsUpdateTemplateForIncident({
    incidentId: incident.id,
    postmortemTemplateId: template.id,
  });
  // Also update the status to "In progress"
  await apiClient.postmortemsUpdateStatus({
    updateStatusRequestBody: {
      incident_id: incident.id,
      status: PostmortemsUpdateStatusRequestBodyStatusEnum.Created,
    },
  });
  return;
}

const ExportPostmortemDrawerInner = ({
  incident,
  destinations,
  templates,
  settings,
  sharedDrawerProps,
  incidentTemplateId,
  overrideDefaultDestinationId,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
  overrideDefaultDestinationId?: string;
  destinations: PostmortemDestination[];
  templates: PostmortemTemplate[];
  settings: PostmortemsSettingsShowResponseBody;
  sharedDrawerProps: Omit<DrawerProps, "children">;
  incidentTemplateId?: string;
}): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(incident);
  const [hasCreated, setHasCreated] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues: defaultValues({
      incident,
      destinations,
      templates,
      settings,
      incidentTemplateId,
      overrideDefaultDestinationId,
    }),
  });
  const { setError, watch } = formMethods;

  const selectedDestinationId = watch("destination_id");
  const postmortemTemplateId = watch("postmortem_template_id");

  const formSelectedTemplate = templates.find(
    (t) => t.id === postmortemTemplateId,
  );

  const isFormTemplateInternal =
    formSelectedTemplate?.writing_mode ===
    PostmortemTemplateWritingModeEnum.InHouse;

  const incidentHasTemplate = !!incidentTemplateId;

  // if the current template is internal, we should only show the export
  // once it has already been chosen
  const showExportOrAttachView = !isFormTemplateInternal || incidentHasTemplate;
  const isPlatformExport =
    selectedDestinationId !== "copy_and_paste" &&
    selectedDestinationId !== "manually_attach";

  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });
  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });
  const {
    trigger: createDocument,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident.id },
    async (
      apiClient,
      { timezone, postmortem_template_id, destination_id, permalink }: FormData,
    ) => {
      const template = templates.find((t) => t.id === postmortem_template_id);
      if (!template) {
        throw new Error("Template not found");
      }

      // If the template type is in house, but not on the incident, that means
      // that we've entered the "Create" flow from a fresh perspective.
      //
      // Therefor, this form should perform a faux "Create", which in reality
      // is just setting the template on the incident.
      if (
        template.writing_mode === PostmortemTemplateWritingModeEnum.InHouse &&
        !incidentHasTemplate
      ) {
        createInHousePostmortem(apiClient, incident, template);
        return;
      }

      const initialBody:
        | PostmortemsAttachDocumentRequestBody
        | PostmortemsExportDocumentRequestBody = {
        incident_id: incident.id,
      };

      if (showExportOrAttachView && isPlatformExport) {
        if (!timezone || !postmortem_template_id || !destination_id) {
          throw new Error(
            "Expected timezone, template and destination to be set",
          );
        }

        const body: PostmortemsExportDocumentRequestBody = initialBody;
        body.generation_options = {
          timezone,
          postmortem_template_id,
          destination_id,
        };

        await apiClient.postmortemsExportDocument({
          exportDocumentRequestBody: body,
        });
      } else {
        const body: PostmortemsAttachDocumentRequestBody = initialBody;
        let documentProvider: PostmortemsAttachDocumentRequestBodyDocumentProviderEnum =
          PostmortemsAttachDocumentRequestBodyDocumentProviderEnum.Empty;
        if (destination_id === "copy_and_paste") {
          documentProvider = settings.settings
            .default_document_provider_copy_paste as unknown as PostmortemsAttachDocumentRequestBodyDocumentProviderEnum;
        }
        body.document_provider = documentProvider;
        // trim the link so that it doesn't break slack links
        body.permalink = permalink?.trim();

        await apiClient.postmortemsAttachDocument({
          attachDocumentRequestBody: body,
        });
      }
    },
    {
      setError,
      onSuccess: async () => {
        setHasCreated(true);
        await refetchPostIncidentTasks();

        // Refetch the incident so anything on the page that uses postmortem_document_url will know
        // about it
        await refetchIncident();

        onClose();
      },
    },
  );

  // If the incident already has a template, we want this form to be an export-only view
  //
  // Situations where this could occur
  // - Export-only export has failed (lol)
  // - In-house template that we want to export
  const verb = incidentHasTemplate ? "Export" : "Create";

  const showDestinationChooser = destinations.length >= 1;

  return (
    <FormProvider<FormData> {...formMethods}>
      <Drawer {...sharedDrawerProps} warnWhenDirty className="flex flex-col">
        <DrawerTitle
          title={`${verb} ${postmortemNameFormatted}`}
          onClose={onClose}
          compact
          sticky
        />
        <DrawerBody className="h-full overflow-y-auto">
          <Form.Root
            formMethods={formMethods}
            onSubmit={createDocument}
            id="export-post-mortem"
            saving={saving || hasCreated}
            genericError={genericError}
          >
            <SelectPostmortemTemplate
              incident={incident}
              formMethods={formMethods}
              templateLocked={incidentHasTemplate}
            />

            {showExportOrAttachView && (
              <>
                {showDestinationChooser && (
                  <>
                    <SelectPostmortemDestination
                      formMethods={formMethods}
                      destinations={destinations}
                    />

                    {isPlatformExport && (
                      <SingleTimezoneSelectV2
                        formMethods={formMethods}
                        name="timezone"
                        label="Timezone"
                        fullWidth
                      />
                    )}
                  </>
                )}

                {selectedDestinationId === "copy_and_paste" && (
                  <CopyAndAttachPostMortemSection
                    formMethods={formMethods}
                    incident={incident}
                    defaultDocumentProviderCopyPaste={
                      settings.settings.default_document_provider_copy_paste
                    }
                  />
                )}

                {selectedDestinationId === "manually_attach" && (
                  <ManuallyAttachPostMortemSection
                    formMethods={formMethods}
                    incident={incident}
                  />
                )}
              </>
            )}
          </Form.Root>
        </DrawerBody>
        <DrawerFooter className="flex gap-3 justify-end">
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Primary}
            type={"submit"}
            // we perform some actions while the form is still open
            // so have to have a manual flag going "hey, we've done the thing"
            disabled={saving || hasCreated}
            form="export-post-mortem"
          >
            {verb}
          </Button>
        </DrawerFooter>
      </Drawer>
    </FormProvider>
  );
};

const CopyAndAttachPostMortemSection = ({
  incident,
  formMethods,
  defaultDocumentProviderCopyPaste,
}: {
  incident: Incident;
  formMethods: UseFormReturn<FormData>;
  defaultDocumentProviderCopyPaste?: PostmortemSettingsDefaultDocumentProviderCopyPasteEnum;
}): React.ReactElement => {
  const showToast = useToast();
  const { postmortemName, postmortemNameFormatted } =
    usePostmortemName(incident);
  const onCopied = (didSucceed: boolean) => {
    showToast(
      didSucceed
        ? {
            theme: ToastTheme.Success,
            title: `${postmortemName} copied to clipboard`,
          }
        : {
            theme: ToastTheme.Error,
            title: `Sorry, we had an unexpected problem copying the ${postmortemNameFormatted} to your clipboard. Our engineers have been notified.`,
          },
    );
  };

  const [timezone, selectedTemplateId] = formMethods.watch([
    "timezone",
    "postmortem_template_id",
  ]);

  return (
    <>
      <SingleTimezoneSelectV2
        formMethods={formMethods}
        name="timezone"
        label="Timezone"
        fullWidth
      />
      <Callout theme={CalloutTheme.Plain} showIcon={false}>
        <div className="flex flex-col gap-4">
          <div className="text-sm-bold">Instructions</div>
          <Form.InputWrapper
            name="copy-document-contents"
            helptext={`1. Create a new document in the provider of your choice and copy and paste the ${postmortemNameFormatted} using the button below.`}
          >
            {timezone && (
              <CopyPostMortemButton
                incident={incident}
                onCopied={onCopied}
                timezone={timezone}
                postmortemTemplateID={selectedTemplateId}
                defaultDocumentProviderCopyPaste={
                  defaultDocumentProviderCopyPaste
                }
              />
            )}
          </Form.InputWrapper>
          <PostMortemURLForm
            postmortemNameFormatted={postmortemNameFormatted}
            formMethods={formMethods}
          />
        </div>
      </Callout>
    </>
  );
};

const ManuallyAttachPostMortemSection = ({
  formMethods,
  incident,
}: {
  formMethods: UseFormReturn<FormData>;
  incident: Incident;
}): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(incident);

  return (
    <div className="flex flex-col gap-4">
      <div className="border-l-[3px] border-stroke-primary pl-4">
        <PostMortemURLForm
          formMethods={formMethods}
          postmortemNameFormatted={postmortemNameFormatted}
          hideHelpText
        />
      </div>
    </div>
  );
};

const PostMortemURLForm = ({
  formMethods,
  postmortemNameFormatted,
  hideHelpText = false,
}: {
  formMethods: UseFormReturn<FormData>;
  postmortemNameFormatted: string;
  hideHelpText?: boolean;
}): React.ReactElement => {
  const url = formMethods.watch("permalink");
  const isNotion = (url || "").includes("notion.so");
  const isConfluence = (url || "").includes("atlassian.net/wiki");
  const isGoogle = (url || "").includes("docs.google.com");

  return (
    <>
      <InputV2
        formMethods={formMethods}
        name="permalink"
        type={InputType.Url}
        helptext={
          hideHelpText
            ? undefined
            : `2. Add the URL of your ${postmortemNameFormatted} here so that we can link it to this incident.`
        }
        placeholder={"https://docs.example"}
        required="Please enter a valid link"
        maxLength={1000}
      />

      {isNotion && (
        <Callout
          theme={CalloutTheme.Info}
          iconOverride={IconEnum.New}
          className={"mt-2"}
          title={
            <span>
              incident.io offers a native{" "}
              <a href="https://help.incident.io/articles/5031629144-notion">
                Notion Integration
              </a>
            </span>
          }
          subtitle={
            <span>
              Allowing a seamless export experience with richer formatting,
              Notion databases with custom properties, native timestamps,
              tagging users and more.
            </span>
          }
        />
      )}

      {isConfluence && (
        <Callout
          theme={CalloutTheme.Info}
          iconOverride={IconEnum.New}
          className={"mt-2"}
          title={
            <span>
              incident.io offers a native{" "}
              <a href="https://help.incident.io/articles/7079133307-confluence">
                Confluence Integration
              </a>
            </span>
          }
          subtitle={
            <span>
              Allowing a seamless & more reliable export experience with richer
              formatting.
            </span>
          }
        />
      )}

      {isGoogle && (
        <Callout
          theme={CalloutTheme.Info}
          iconOverride={IconEnum.New}
          className={"mt-2"}
          title="incident.io offers a native Google Docs integration"
          subtitle=" Allowing a seamless & more reliable export experience with richer
        formatting."
        />
      )}
    </>
  );
};

const SelectPostmortemDestination = ({
  formMethods,
  destinations,
}: {
  destinations: PostmortemDestination[];
  formMethods: UseFormReturn<FormData>;
}): React.ReactElement => {
  const destinationsIncludingDummies = destinations.concat([
    {
      id: "copy_and_paste",
      name: "Copy & Paste",
      is_default: false,
      document_provider:
        PostmortemDestinationDocumentProviderEnum.CopyPasteGoogleDocs,
      provider_location: "Clipboard",
    },
    {
      id: "manually_attach",
      name: "Manually attach",
      is_default: false,
      document_provider:
        PostmortemDestinationDocumentProviderEnum.CopyPasteGoogleDocs,
      provider_location: "Manually attach",
    },
  ]);

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      name="destination_id"
      label="Destination"
      options={destinationsIncludingDummies?.map((destination) => {
        if (destination.id === "copy_and_paste") {
          return {
            label: "Copy & paste contents",
            value: "copy_and_paste",
            icon: IconEnum.Copy,
          };
        }
        if (destination.id === "manually_attach") {
          return {
            label: "Manually attach URL",
            value: "manually_attach",
            icon: IconEnum.Link,
          };
        }

        const config = IntegrationConfigFor(
          destination.document_provider as unknown as IntegrationSettingsProviderEnum,
        );

        return {
          label: `${config.label} (${destination.name})`,
          icon: config.icon,
          value: destination.id,
        };
      })}
      className="caret-transparent" // hide the blinking cursor
    />
  );
};

const SelectPostmortemTemplate = ({
  incident,
  formMethods,
  templateLocked,
}: {
  incident: Incident;
  formMethods: UseFormReturn<FormData>;
  templateLocked?: boolean;
}) => {
  const {
    data: { postmortem_templates: templates },
    isLoading: templatesLoading,
  } = useAPI(
    "postmortemsListTemplates",
    {
      incidentId: incident.id,
    },
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  const { watch } = formMethods;

  const templateId = watch("postmortem_template_id");
  const template = templates?.find((t) => t.id === templateId);

  const isExportOnly =
    template?.writing_mode === PostmortemTemplateWritingModeEnum.External;
  const isInternal =
    template?.writing_mode === PostmortemTemplateWritingModeEnum.InHouse;

  const { postmortemName, postmortemNameFormatted } =
    usePostmortemName(incident);

  if (isInternal && templateLocked) {
    // If the template should be written within incident.io, just show a callout about what'll
    // happen when they export. We don't need the extra fields about which template to use.
    return (
      <Callout theme={CalloutTheme.Warning}>
        Once exported, your {postmortemNameFormatted} will be moved to the
        chosen destination, and no longer be editable within incident.io
      </Callout>
    );
  }

  return (
    <>
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="postmortem_template_id"
        label="Template"
        isLoading={templatesLoading}
        options={templates?.map((template) => {
          return {
            label: template.name,
            icon: IconEnum.Doc,
            value: template.id,
          };
        })}
        className="caret-transparent" // hide the blinking cursor
      />
      <Callout theme={CalloutTheme.Info} showIcon={false}>
        {isExportOnly ? (
          <div>
            {postmortemName}s created from this template will be written in{" "}
            <span className="font-semibold">an external tool</span>. To write
            your {postmortemNameFormatted} directly in incident.io and take
            advantage of our rich editor experience, you&apos;ll need to update
            this template&apos;s{" "}
            <OrgAwareLink className="underline" to="/settings/post-mortem">
              settings
            </OrgAwareLink>
            .
          </div>
        ) : (
          <div>
            {postmortemName}s using this template are set to be drafted{" "}
            <span className="font-semibold">in incident.io</span>, rather than
            an external tool like Notion. You can always export later. Update
            this preference in{" "}
            <OrgAwareLink className="underline" to="/settings/post-mortem">
              settings
            </OrgAwareLink>
            .
          </div>
        )}
      </Callout>
    </>
  );
};
