import { DateTime } from "luxon";
import { useMemo } from "react";

const numberOfDays = 28;
export const ALERT_INSIGHTS_TIME_PERIOD_LABEL = `Last ${
  numberOfDays / 7
} weeks`;

export const useAlertInsightsDates = ({
  fromStartOfToday,
}: {
  fromStartOfToday: boolean;
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return useMemo(() => {
    let now: DateTime;
    if (fromStartOfToday) {
      now = DateTime.now().setZone(timezone).startOf("day");
    } else {
      now = DateTime.now().setZone(timezone);
    }

    return {
      endDate: now.toJSDate(),
      startDate: now.plus({ days: -numberOfDays }).toJSDate(),
      timezone,
    };
  }, [fromStartOfToday, timezone]); // Empty dependency array means this will only calculate once
};
