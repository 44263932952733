import { Investigation, InvestigationCheck } from "@incident-io/query-api";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  Markdown,
} from "@incident-ui";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Artifacts } from "src/components/workbench/investigations/WorkbenchInvestigationPage";
import { tcx } from "src/utils/tailwind-classes";

export const InvestigationsShowPage = ({
  investigation,
  checks,
}: {
  investigation: Investigation;
  checks: InvestigationCheck[];
}): React.ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeCheckId = searchParams.get("check") || null;

  const activeCheck = useMemo(
    () => checks.find((c) => c.id === activeCheckId),
    [checks, activeCheckId],
  );

  const setActiveCheckId = (checkId: string | null) => {
    if (checkId == null) {
      setSearchParams({});
      return;
    }
    setSearchParams({ check: checkId });
  };

  return (
    <PageWrapper
      // WARNING: this is currently a lie - when we know where this is going, we should embed this
      // into the incident route properly! This is just to keep it out the way of our main
      // incident page.
      crumbs={[
        {
          title: "Incident",
          to: `/incident/${investigation.incident_external_id}`,
        },
        {
          title: "Investigation",
          to: `/investigations/${investigation.id}`,
        },
      ]}
      icon={IconEnum.Bulb}
      title={investigation.incident_name}
      width={PageWidth.Medium}
      overflowY={false}
      noPadding
    >
      <div className="flex h-full">
        <InvestigationSidebar
          checks={checks}
          activeCheckId={activeCheckId}
          onSetActiveCheck={(checkId) => setActiveCheckId(checkId)}
        />
        <div className="grow p-6 pr-4 md:pr-8">
          <InvestigationContent
            investigation={investigation}
            check={activeCheck}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

const InvestigationSidebarStep = ({
  check,
  isActive,
  onClick,
}: {
  check: InvestigationCheck;
  isActive?: boolean;
  onClick?: () => void;
}) => {
  return (
    <InvestigationSidebarItem isActive={isActive} onClick={onClick}>
      <div className="flex items-center gap-2">
        <div className="flex-shrink-0">
          <CheckIcon check={check} size={IconSize.Small} />
        </div>
        <div className="flex gap-1 items-center flex-1 min-w-0">
          <div className="text-sm font-medium text-content-primary truncate">
            {check.title}
          </div>
          {check.duration_seconds > 0 && (
            <div className="text-xs text-content-secondary">
              {check.duration_seconds.toFixed(1)}s
            </div>
          )}
          {check.finding_relevance === "relevant" && (
            <Badge theme={BadgeTheme.Info}>Relevant</Badge>
          )}
        </div>
      </div>
    </InvestigationSidebarItem>
  );
};

const InvestigationSidebarItem = ({
  isActive,
  onClick,
  children,
}: React.PropsWithChildren<{
  isActive?: boolean;
  onClick?: () => void;
}>) => {
  return (
    <Button
      analyticsTrackingId={null}
      theme={ButtonTheme.Unstyled}
      onClick={onClick}
      className={tcx(
        "w-full px-4 py-3 text-left border-l-2 hover:bg-white text-sm-med text-content-primary",
        isActive ? "border-l-blue-500 bg-white" : "border-l-transparent",
      )}
    >
      {children}
    </Button>
  );
};

const InvestigationSidebar = ({
  checks,
  activeCheckId,
  onSetActiveCheck,
}: {
  checks: InvestigationCheck[];
  activeCheckId: string | null;
  onSetActiveCheck: (checkId: string | null) => void;
}) => {
  // Sort the checks according to the specified rules
  const sortedChecks = useMemo(() => {
    return [...checks].sort((a, b) => {
      // First priority: completed_at
      if (a.completed_at && b.completed_at) {
        return a.completed_at.getTime() - b.completed_at.getTime();
      }
      if (a.completed_at) return -1;
      if (b.completed_at) return 1;

      // Second priority: started_at for incomplete checks
      if (a.started_at && b.started_at) {
        return a.started_at.getTime() - b.started_at.getTime();
      }
      if (a.started_at) return -1;
      if (b.started_at) return 1;

      // Last priority: created_at
      return a.created_at.getTime() - b.created_at.getTime();
    });
  }, [checks]);

  return (
    <div className="w-[400px] shrink-0 border-r bg-surface-secondary overflow-y-auto h-full pl-4 md:pl-8">
      <div className="py-2">
        <InvestigationSidebarItem
          onClick={() => onSetActiveCheck(null)}
          isActive={activeCheckId == null}
        >
          Overview
        </InvestigationSidebarItem>
        {sortedChecks.map((check) => (
          <InvestigationSidebarStep
            key={check.id}
            check={check}
            isActive={check.id === activeCheckId}
            onClick={() => onSetActiveCheck(check.id)}
          />
        ))}
      </div>
    </div>
  );
};

const InvestigationContent = ({
  investigation,
  check,
}: {
  investigation: Investigation;
  check?: InvestigationCheck;
}) => {
  if (!check) {
    return (
      <div className="flex flex-col gap-2">
        <h2 className="text-2xl-bold">Investigation overview</h2>
        <Markdown className="[&>h3]:mt-8">
          {investigation.summary ?? "–"}
        </Markdown>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <CheckIcon check={check} size={IconSize.Medium} />
          <h2 className="text-xl font-semibold">{check.title}</h2>
          {check.finding_relevance === "relevant" && (
            <Badge theme={BadgeTheme.Info}>Relevant</Badge>
          )}
        </div>
        {check.duration_seconds > 0 && (
          <div className="text-xs uppercase font-semibold text-content-secondary tracking-wide tabular-nums lining-nums">
            COMPLETED IN {check.duration_seconds.toFixed(1)}s
          </div>
        )}
      </div>
      {check.error_message && (
        <Callout theme={CalloutTheme.Danger}>{check.error_message}</Callout>
      )}
      <div>{check.summary}</div>
      {check.artifacts && (
        <div className="flex flex-col gap-2">
          <div className="text-base-bold">Artifacts</div>
          <div className="bg-surface-secondary rounded-2 p-4">
            <Artifacts artifacts={check.artifacts} />
          </div>
        </div>
      )}
    </div>
  );
};

const CheckIcon = ({
  check,
  size,
}: {
  check: InvestigationCheck;
  size: IconSize;
}) => {
  let className = "";
  let icon = IconEnum.QuestionMark;

  if (check.result) {
    className = "text-green-500";
    icon = IconEnum.TickCircle;
  } else if (check.error_message) {
    className = "text-amber-500";
    icon = IconEnum.WarningCircle;
  } else {
    className = "text-content-tertiary";
    icon = IconEnum.Loader;
  }

  return <Icon id={icon} className={className} size={size} />;
};
