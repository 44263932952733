/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertThemeSlim,
    AlertThemeSlimFromJSON,
    AlertThemeSlimFromJSONTyped,
    AlertThemeSlimToJSON,
} from './AlertThemeSlim';

/**
 * 
 * @export
 * @interface MetricSubject
 */
export interface MetricSubject {
    /**
     * The deduplication key this metric is based on
     * @type {string}
     * @memberof MetricSubject
     */
    deduplication_key?: string;
    /**
     * 
     * @type {AlertThemeSlim}
     * @memberof MetricSubject
     */
    theme?: AlertThemeSlim;
    /**
     * An alert title that this metric is based on
     * @type {string}
     * @memberof MetricSubject
     */
    title?: string;
}

export function MetricSubjectFromJSON(json: any): MetricSubject {
    return MetricSubjectFromJSONTyped(json, false);
}

export function MetricSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricSubject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deduplication_key': !exists(json, 'deduplication_key') ? undefined : json['deduplication_key'],
        'theme': !exists(json, 'theme') ? undefined : AlertThemeSlimFromJSON(json['theme']),
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function MetricSubjectToJSON(value?: MetricSubject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deduplication_key': value.deduplication_key,
        'theme': AlertThemeSlimToJSON(value.theme),
        'title': value.title,
    };
}

