import type { SVGProps } from "react";
import * as React from "react";
const SvgSparklesAiGradient = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#sparkles-ai-gradient_svg__a)"
      d="M4.025 6.622a.501.501 0 0 0 .95 0l.421-1.263 1.263-.421a.5.5 0 0 0 0-.948l-1.263-.421-.421-1.263c-.137-.408-.812-.408-.949 0l-.421 1.263-1.263.421a.5.5 0 0 0 0 .948l1.263.421.42 1.263Z"
    />
    <path
      fill="url(#sparkles-ai-gradient_svg__b)"
      d="M17.525 9.802 12.99 8.009l-1.793-4.535c-.227-.572-1.168-.572-1.395 0L8.009 8.009 3.474 9.802a.75.75 0 0 0 0 1.394l4.535 1.793 1.793 4.535a.75.75 0 0 0 1.394 0l1.793-4.535 4.535-1.793a.751.751 0 0 0 .001-1.394Z"
    />
    <defs>
      <linearGradient
        id="sparkles-ai-gradient_svg__a"
        x1={2}
        x2={21.297}
        y1={17.998}
        y2={9.421}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#F25533" />
        <stop offset={0.9} stopColor="#F1CD98" />
        <stop offset={1} stopColor="#F8F5EF" />
      </linearGradient>
      <linearGradient
        id="sparkles-ai-gradient_svg__b"
        x1={2}
        x2={21.297}
        y1={17.998}
        y2={9.421}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#F25533" />
        <stop offset={0.9} stopColor="#F1CD98" />
        <stop offset={1} stopColor="#F8F5EF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSparklesAiGradient;
