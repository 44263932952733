import { usePylon } from "@bolasim/react-use-pylon";
import { AlertRoute, StatusPage } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  EmptyState,
  IconEnum,
  Spinner,
  StackedList,
  Txt,
} from "@incident-ui";
import { SpinnerTheme } from "@incident-ui/Spinner/Spinner";
import React from "react";

import { useAPI } from "../../../../utils/swr";

export const PageViewSettingsEditPage = ({
  page,
}: {
  page: StatusPage;
}): React.ReactElement => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();
  const { data: sourceConfigs, isLoading: sourceConfigsLoading } = useAPI(
    "alertsListSourceConfigs",
    {},
    {
      fallbackData: { alert_source_configs: [] },
    },
  );

  // we have an exclusive alert source config for status page views
  const alertSourceConfig =
    sourceConfigs.alert_source_configs.length > 0 &&
    sourceConfigs.alert_source_configs.filter(
      (config) => config.source_type === "status_page_views",
    )[0];

  const { data: matchingAlertRoutes, isLoading: alertRoutesLoading } = useAPI(
    alertSourceConfig ? "alertRoutesFindMatchingAlertRoutes" : null,
    {
      findMatchingAlertRoutesRequestBody: {
        alert_source_config_id: alertSourceConfig ? alertSourceConfig.id : "",
        attribute_values: {},
      },
    },
    {
      fallbackData: { alert_routes: [] },
    },
  );

  const hasAlertRoutesSetup = matchingAlertRoutes.alert_routes.length > 0;

  if (alertRoutesLoading || sourceConfigsLoading) {
    return (
      <div className="flex justify-center mt-8">
        <Spinner theme={SpinnerTheme.Slate} />
      </div>
    );
  }

  return (
    <div className="bg-surface-secondary rounded-[6px] p-4 border border-stroke">
      <h3 className="font-medium mb-2">High status page view alerts</h3>
      <Txt className="mb-2">
        Get alerted when your status page receives a higher number of views than
        normal; you can find out more about when we&apos;ll send an alert in our{" "}
        <Button
          className="underline"
          analyticsTrackingId={"alert-route-help"}
          theme={ButtonTheme.Link}
          onClick={() => showArticle("6637601672")}
        >
          help centre.
        </Button>
      </Txt>
      {hasAlertRoutesSetup ? (
        <>
          <Txt className="font-medium mt-6 mb-2">Alert routes</Txt>
          <Txt className="mb-2 text-pretty">
            Alert routes create incidents from alerts so you can be notified
            about them. The following alert routes will apply to alerts created
            for this status page.
          </Txt>
        </>
      ) : null}
      {hasAlertRoutesSetup ? (
        <div className="mb-2">
          <ListExistingAlertRoutes
            alertRoutes={matchingAlertRoutes.alert_routes}
          />
        </div>
      ) : (
        <EmptyState
          icon={IconEnum.Alert}
          content="To receive alerts, you'll need to create an alert route so you can create incidents when alerts fire."
          cta={
            <Button
              analyticsTrackingId={"status-page-create-new-alert-route"}
              theme={ButtonTheme.Secondary}
              href={`/alerts/routes/create?source=status-pages&returnTo=${page.id}`}
            >
              Create alert route
            </Button>
          }
        />
      )}
    </div>
  );
};

const ListExistingAlertRoutes = ({
  alertRoutes,
}: {
  alertRoutes: AlertRoute[];
}) => {
  return (
    <StackedList>
      {alertRoutes.map((alertRoute) => (
        <ExistingAlertRouteBadge key={alertRoute.id} alertRoute={alertRoute} />
      ))}
    </StackedList>
  );
};

const ExistingAlertRouteBadge = ({
  alertRoute,
}: {
  alertRoute: AlertRoute;
}) => {
  return (
    <div className="w-full flex justify-between items-center bg-white p-4">
      <div className="text-sm">{alertRoute.name}</div>
      <Button
        analyticsTrackingId={"status-page-edit-alert-route"}
        theme={ButtonTheme.Secondary}
        href={`/alerts/routes/${alertRoute.id}/edit`}
        icon={IconEnum.Edit}
        openInNewTab
      >
        Edit alert route
      </Button>
    </div>
  );
};
