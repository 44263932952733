import { usePylon } from "@bolasim/react-use-pylon";
import {
  AIToggleFeatureRequestBodyFeatureEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import scribeStyles from "@incident-shared/aiscribe/ScribeGradient.module.scss";
import { Button, ButtonTheme, SharedToasts, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";

import { useIdentity } from "../../contexts/IdentityContext";
import { useAPIMutation } from "../../utils/swr";
import { tcx } from "../../utils/tailwind-classes";
import upsellBanner from "./scribe-upsell.svg";

export const UpsellForScribe = () => {
  const { showKnowledgeBaseArticle } = usePylon();
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const showToast = useToast();

  const { trigger: enableScribe } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: {
          feature: AIToggleFeatureRequestBodyFeatureEnum.CallTranscription,
          enabled: true,
        },
      }),
    {
      onSuccess: () => {
        showToast({
          title: "Scribe successfully activated",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast(SharedToasts.SETTINGS_SAVE_ERROR);
        showToast({
          title: "Could not activate Scribe",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  return (
    <div className="flex flex-col flex-grow justify-center align-middle self-stretch">
      <div
        className={tcx(
          "w-full grow",
          "rounded-2 bg-surface-primary",
          "flex flex-col items-center justify-center",
          scribeStyles.upsellBackground,
        )}
      >
        <div className="max-w-lg flex flex-col items-center justify-center gap-6">
          <div className="hidden xl:block shrink-0 self-stretch">
            <img src={upsellBanner} />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-center text-2xl-bold">
              Never miss a call detail
            </div>
            <div className="text-sm-normal text-center text-content-tertiary">
              Scribe auto-joins video calls to take notes and deliver concise
              summaries, allowing everyone to participate freely.
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Button
              analyticsTrackingId="learn-more-scribe-notes-page"
              theme={ButtonTheme.Secondary}
              onClick={() => showKnowledgeBaseArticle("3340727910")}
            >
              Learn more
            </Button>
            {canEditSettings && (
              <Button
                analyticsTrackingId="enable-scribe"
                theme={ButtonTheme.Primary}
                onClick={() => enableScribe({})}
              >
                Activate
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
