import {
  ScheduleExternalProviderEnum,
  SchedulesCreateFeedRequestBodyFeedTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Gate } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import {
  SearchBar,
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useOutlet } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";

import { EmptyState } from "../EmptyState";
import { useOnCallEntityCount } from "../utils";
import EmptyStateImg from "./images/schedules-empty-state.png";
import { ScheduleCalendarFeedModal } from "./ScheduleCalendarFeedModal";
import { SchedulesTable } from "./SchedulesTable";

export const SchedulesListPage = () => {
  const outlet = useOutlet();
  const {
    data: schedulesResp,
    isLoading: schedulesIsLoading,
    error: schedulesError,
  } = useAPI("schedulesList", undefined);

  const {
    data: entityCount,
    isLoading: entityCountLoading,
    error: entityCountError,
  } = useOnCallEntityCount();

  const nativeSchedules = (schedulesResp?.schedules ?? []).filter(
    (s) => s.external_provider === ScheduleExternalProviderEnum.Native,
  );

  const [calendarFeedModalOpen, setCalendarFeedModalOpen] = useState(false);

  const { identity } = useIdentity();

  const { hasOnCall } = useProductAccess();

  if (!hasOnCall) {
    return <OrgAwareNavigate to="/on-call" replace={true} />;
  }

  if (schedulesError || entityCountError) {
    return <GenericErrorMessage error={schedulesError || entityCountError} />;
  }

  if (schedulesIsLoading || entityCountLoading || !identity) {
    return <Loader />;
  }

  // If we've just deleted the last on-call entity, we want to send the user
  // back to the get started page (unless they're trying to create something!)
  if (entityCount === 0 && !outlet) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  const numNativeSchedules =
    schedulesResp?.schedules.filter(
      (schedule) =>
        schedule.external_provider === ScheduleExternalProviderEnum.Native,
    ).length ?? Number.MAX_SAFE_INTEGER;

  const scheduleCreateUpgradeRequired = numericGateLimitReached(
    identity.feature_gates.on_call_schedules_count,
    numNativeSchedules,
  );

  const createScheduleGate: Gate = {
    type: "numeric",
    value: identity.feature_gates.on_call_schedules_count,
    featureNameSingular: "on call",
  };
  const schedulesGateFeatureName = `On-call schedules`;

  if (nativeSchedules.length === 0) {
    return (
      <>
        <Helmet title={"Schedules - incident.io"} />
        <EmptyState
          copy="Ensure the right people are on call at all times with simple but powerful scheduling that supports even the most complex use cases."
          imageSrc={EmptyStateImg}
          title="No schedules created"
          buttons={[
            {
              children: "Create schedule",
              href: "/on-call/schedules/create",
              analyticsTrackingId: "on-call-create-schedule",
              gatingProps: {
                upgradeRequired: scheduleCreateUpgradeRequired,
                upgradeRequiredProps: {
                  featureName: schedulesGateFeatureName,
                  gate: createScheduleGate,
                },
                requiredScope: ScopeNameEnum.SchedulesCreate,
              },
            },
            {
              children: "Import schedule",
              href: "/on-call/schedules/import",
              analyticsTrackingId: "on-call-import-schedule",
              theme: ButtonTheme.Secondary,
              gatingProps: {
                upgradeRequired: scheduleCreateUpgradeRequired,
                upgradeRequiredProps: {
                  featureName: schedulesGateFeatureName,
                  gate: createScheduleGate,
                },
                requiredScope: ScopeNameEnum.SchedulesCreate,
              },
            },
          ]}
        />
        <AnimatePresence>{outlet}</AnimatePresence>
      </>
    );
  }

  return (
    <SearchProvider>
      <Helmet title={"Schedules - incident.io"} />
      <div className={"flex flex-col gap-10"}>
        <SchedulesListPageHeader
          toggleCalendarFeedModalOpen={() => {
            setCalendarFeedModalOpen(!calendarFeedModalOpen);
          }}
          scheduleCreateUpgradeRequired={scheduleCreateUpgradeRequired}
          createScheduleGate={createScheduleGate}
          schedulesGateFeatureName={schedulesGateFeatureName}
        />
        <div className={"pb-5"}>
          <SchedulesTable />
        </div>
      </div>
      {calendarFeedModalOpen && (
        <ScheduleCalendarFeedModal
          feedType={SchedulesCreateFeedRequestBodyFeedTypeEnum.All}
          onCancel={() => setCalendarFeedModalOpen(false)}
        />
      )}
      <AnimatePresence>{outlet}</AnimatePresence>
    </SearchProvider>
  );
};

const SchedulesListPageHeader = ({
  toggleCalendarFeedModalOpen,
  scheduleCreateUpgradeRequired,
  createScheduleGate,
  schedulesGateFeatureName,
}: {
  toggleCalendarFeedModalOpen: () => void;
  scheduleCreateUpgradeRequired: boolean;
  createScheduleGate: Gate;
  schedulesGateFeatureName: string;
}) => {
  const searchBarProps = useSearchContext();

  return (
    <div className="flex gap-2">
      <SearchBar
        {...searchBarProps}
        placeholder="Search schedules"
        className={"grow text-content-tertiary"}
        iconProps={{
          className: "text-content-tertiary",
        }}
        autoFocus
      />
      <Button
        analyticsTrackingId={null}
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Calendar}
        onClick={toggleCalendarFeedModalOpen}
      >
        <span>Sync calendar</span>
      </Button>
      <GatedButton
        upgradeRequired={scheduleCreateUpgradeRequired}
        upgradeRequiredProps={{
          featureName: schedulesGateFeatureName,
          gate: createScheduleGate,
        }}
        requiredScope={ScopeNameEnum.SchedulesCreate}
        analyticsTrackingId={null}
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Download}
        href="/on-call/schedules/import"
      >
        <span>Import schedule</span>
      </GatedButton>
      <GatedButton
        upgradeRequired={scheduleCreateUpgradeRequired}
        upgradeRequiredProps={{
          featureName: schedulesGateFeatureName,
          gate: createScheduleGate,
        }}
        analyticsTrackingId={null}
        theme={ButtonTheme.Secondary}
        icon={IconEnum.CalendarPlus}
        requiredScope={ScopeNameEnum.SchedulesCreate}
        href="/on-call/schedules/create"
      >
        <span>New schedule</span>
      </GatedButton>
    </div>
  );
};
