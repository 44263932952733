/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OnCallNotificationsConfirmMethodVerificationRequestBody,
    OnCallNotificationsConfirmMethodVerificationRequestBodyFromJSON,
    OnCallNotificationsConfirmMethodVerificationRequestBodyToJSON,
    OnCallNotificationsConfirmMethodVerificationResponseBody,
    OnCallNotificationsConfirmMethodVerificationResponseBodyFromJSON,
    OnCallNotificationsConfirmMethodVerificationResponseBodyToJSON,
    OnCallNotificationsCreateMethodRequestBody,
    OnCallNotificationsCreateMethodRequestBodyFromJSON,
    OnCallNotificationsCreateMethodRequestBodyToJSON,
    OnCallNotificationsCreateMethodResponseBody,
    OnCallNotificationsCreateMethodResponseBodyFromJSON,
    OnCallNotificationsCreateMethodResponseBodyToJSON,
    OnCallNotificationsCreateRuleRequestBody,
    OnCallNotificationsCreateRuleRequestBodyFromJSON,
    OnCallNotificationsCreateRuleRequestBodyToJSON,
    OnCallNotificationsCreateRuleResponseBody,
    OnCallNotificationsCreateRuleResponseBodyFromJSON,
    OnCallNotificationsCreateRuleResponseBodyToJSON,
    OnCallNotificationsListMethodsResponseBody,
    OnCallNotificationsListMethodsResponseBodyFromJSON,
    OnCallNotificationsListMethodsResponseBodyToJSON,
    OnCallNotificationsListMethodsV2ResponseBody,
    OnCallNotificationsListMethodsV2ResponseBodyFromJSON,
    OnCallNotificationsListMethodsV2ResponseBodyToJSON,
    OnCallNotificationsListRestrictedCountriesResponseBody,
    OnCallNotificationsListRestrictedCountriesResponseBodyFromJSON,
    OnCallNotificationsListRestrictedCountriesResponseBodyToJSON,
    OnCallNotificationsListRulesResponseBody,
    OnCallNotificationsListRulesResponseBodyFromJSON,
    OnCallNotificationsListRulesResponseBodyToJSON,
    OnCallNotificationsResetMethodVerificationRequestBody,
    OnCallNotificationsResetMethodVerificationRequestBodyFromJSON,
    OnCallNotificationsResetMethodVerificationRequestBodyToJSON,
    OnCallNotificationsResetMethodVerificationResponseBody,
    OnCallNotificationsResetMethodVerificationResponseBodyFromJSON,
    OnCallNotificationsResetMethodVerificationResponseBodyToJSON,
    OnCallNotificationsShowMethodResponseBody,
    OnCallNotificationsShowMethodResponseBodyFromJSON,
    OnCallNotificationsShowMethodResponseBodyToJSON,
    OnCallNotificationsShowRuleResponseBody,
    OnCallNotificationsShowRuleResponseBodyFromJSON,
    OnCallNotificationsShowRuleResponseBodyToJSON,
    OnCallNotificationsTestContactMethodRequestBody,
    OnCallNotificationsTestContactMethodRequestBodyFromJSON,
    OnCallNotificationsTestContactMethodRequestBodyToJSON,
    OnCallNotificationsUpdateRuleRequestBody,
    OnCallNotificationsUpdateRuleRequestBodyFromJSON,
    OnCallNotificationsUpdateRuleRequestBodyToJSON,
    OnCallNotificationsUpdateRuleResponseBody,
    OnCallNotificationsUpdateRuleResponseBodyFromJSON,
    OnCallNotificationsUpdateRuleResponseBodyToJSON,
} from '../models';

export interface OnCallNotificationsConfirmMethodVerificationRequest {
    id: string;
    confirmMethodVerificationRequestBody: OnCallNotificationsConfirmMethodVerificationRequestBody;
}

export interface OnCallNotificationsCreateMethodRequest {
    createMethodRequestBody: OnCallNotificationsCreateMethodRequestBody;
}

export interface OnCallNotificationsCreateRuleRequest {
    createRuleRequestBody: OnCallNotificationsCreateRuleRequestBody;
}

export interface OnCallNotificationsDestroyMethodRequest {
    id: string;
}

export interface OnCallNotificationsDestroyRuleRequest {
    id: string;
}

export interface OnCallNotificationsResetMethodVerificationRequest {
    id: string;
    resetMethodVerificationRequestBody: OnCallNotificationsResetMethodVerificationRequestBody;
}

export interface OnCallNotificationsShowMethodRequest {
    id: string;
}

export interface OnCallNotificationsShowRuleRequest {
    id: string;
}

export interface OnCallNotificationsTestContactMethodRequest {
    onCallNotificationType: string;
    testContactMethodRequestBody: OnCallNotificationsTestContactMethodRequestBody;
}

export interface OnCallNotificationsUpdateRuleRequest {
    id: string;
    updateRuleRequestBody: OnCallNotificationsUpdateRuleRequestBody;
}

/**
 * 
 */
export class OnCallNotificationsApi extends runtime.BaseAPI {

    /**
     * If this method is awaiting verification, this confirms it.
     * ConfirmMethodVerification On-call notifications
     */
    async onCallNotificationsConfirmMethodVerificationRaw(requestParameters: OnCallNotificationsConfirmMethodVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsConfirmMethodVerificationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling onCallNotificationsConfirmMethodVerification.');
        }

        if (requestParameters.confirmMethodVerificationRequestBody === null || requestParameters.confirmMethodVerificationRequestBody === undefined) {
            throw new runtime.RequiredError('confirmMethodVerificationRequestBody','Required parameter requestParameters.confirmMethodVerificationRequestBody was null or undefined when calling onCallNotificationsConfirmMethodVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/on_call_notification_methods/{id}/actions/confirm_verification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnCallNotificationsConfirmMethodVerificationRequestBodyToJSON(requestParameters.confirmMethodVerificationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsConfirmMethodVerificationResponseBodyFromJSON(jsonValue));
    }

    /**
     * If this method is awaiting verification, this confirms it.
     * ConfirmMethodVerification On-call notifications
     */
    async onCallNotificationsConfirmMethodVerification(requestParameters: OnCallNotificationsConfirmMethodVerificationRequest, initOverrides?: RequestInit): Promise<OnCallNotificationsConfirmMethodVerificationResponseBody> {
        const response = await this.onCallNotificationsConfirmMethodVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new on-call notification method.
     * CreateMethod On-call notifications
     */
    async onCallNotificationsCreateMethodRaw(requestParameters: OnCallNotificationsCreateMethodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsCreateMethodResponseBody>> {
        if (requestParameters.createMethodRequestBody === null || requestParameters.createMethodRequestBody === undefined) {
            throw new runtime.RequiredError('createMethodRequestBody','Required parameter requestParameters.createMethodRequestBody was null or undefined when calling onCallNotificationsCreateMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/on_call_notification_methods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnCallNotificationsCreateMethodRequestBodyToJSON(requestParameters.createMethodRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsCreateMethodResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new on-call notification method.
     * CreateMethod On-call notifications
     */
    async onCallNotificationsCreateMethod(requestParameters: OnCallNotificationsCreateMethodRequest, initOverrides?: RequestInit): Promise<OnCallNotificationsCreateMethodResponseBody> {
        const response = await this.onCallNotificationsCreateMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new on-call notification rule.
     * CreateRule On-call notifications
     */
    async onCallNotificationsCreateRuleRaw(requestParameters: OnCallNotificationsCreateRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsCreateRuleResponseBody>> {
        if (requestParameters.createRuleRequestBody === null || requestParameters.createRuleRequestBody === undefined) {
            throw new runtime.RequiredError('createRuleRequestBody','Required parameter requestParameters.createRuleRequestBody was null or undefined when calling onCallNotificationsCreateRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/on_call_notification_rules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnCallNotificationsCreateRuleRequestBodyToJSON(requestParameters.createRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsCreateRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new on-call notification rule.
     * CreateRule On-call notifications
     */
    async onCallNotificationsCreateRule(requestParameters: OnCallNotificationsCreateRuleRequest, initOverrides?: RequestInit): Promise<OnCallNotificationsCreateRuleResponseBody> {
        const response = await this.onCallNotificationsCreateRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Destroys the notification method, removing it from any notification rules.
     * DestroyMethod On-call notifications
     */
    async onCallNotificationsDestroyMethodRaw(requestParameters: OnCallNotificationsDestroyMethodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling onCallNotificationsDestroyMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_notification_methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroys the notification method, removing it from any notification rules.
     * DestroyMethod On-call notifications
     */
    async onCallNotificationsDestroyMethod(requestParameters: OnCallNotificationsDestroyMethodRequest, initOverrides?: RequestInit): Promise<void> {
        await this.onCallNotificationsDestroyMethodRaw(requestParameters, initOverrides);
    }

    /**
     * Destroys the notification rule.
     * DestroyRule On-call notifications
     */
    async onCallNotificationsDestroyRuleRaw(requestParameters: OnCallNotificationsDestroyRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling onCallNotificationsDestroyRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_notification_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Destroys the notification rule.
     * DestroyRule On-call notifications
     */
    async onCallNotificationsDestroyRule(requestParameters: OnCallNotificationsDestroyRuleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.onCallNotificationsDestroyRuleRaw(requestParameters, initOverrides);
    }

    /**
     * List configurable on-call notification methods. This is currently just phone methods.
     * ListMethods On-call notifications
     */
    async onCallNotificationsListMethodsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsListMethodsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_notification_methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsListMethodsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List configurable on-call notification methods. This is currently just phone methods.
     * ListMethods On-call notifications
     */
    async onCallNotificationsListMethods(initOverrides?: RequestInit): Promise<OnCallNotificationsListMethodsResponseBody> {
        const response = await this.onCallNotificationsListMethodsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all on-call notification methods.
     * ListMethodsV2 On-call notifications
     */
    async onCallNotificationsListMethodsV2Raw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsListMethodsV2ResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_notification_methods_v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsListMethodsV2ResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all on-call notification methods.
     * ListMethodsV2 On-call notifications
     */
    async onCallNotificationsListMethodsV2(initOverrides?: RequestInit): Promise<OnCallNotificationsListMethodsV2ResponseBody> {
        const response = await this.onCallNotificationsListMethodsV2Raw(initOverrides);
        return await response.value();
    }

    /**
     * List countries that are unreachable by certain methods.
     * ListRestrictedCountries On-call notifications
     */
    async onCallNotificationsListRestrictedCountriesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsListRestrictedCountriesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_notification_methods/actions/list_restricted_countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsListRestrictedCountriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List countries that are unreachable by certain methods.
     * ListRestrictedCountries On-call notifications
     */
    async onCallNotificationsListRestrictedCountries(initOverrides?: RequestInit): Promise<OnCallNotificationsListRestrictedCountriesResponseBody> {
        const response = await this.onCallNotificationsListRestrictedCountriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List on-call notification rules.
     * ListRules On-call notifications
     */
    async onCallNotificationsListRulesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsListRulesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_notification_rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsListRulesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List on-call notification rules.
     * ListRules On-call notifications
     */
    async onCallNotificationsListRules(initOverrides?: RequestInit): Promise<OnCallNotificationsListRulesResponseBody> {
        const response = await this.onCallNotificationsListRulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reset the verification flow for a pending verification method.
     * ResetMethodVerification On-call notifications
     */
    async onCallNotificationsResetMethodVerificationRaw(requestParameters: OnCallNotificationsResetMethodVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsResetMethodVerificationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling onCallNotificationsResetMethodVerification.');
        }

        if (requestParameters.resetMethodVerificationRequestBody === null || requestParameters.resetMethodVerificationRequestBody === undefined) {
            throw new runtime.RequiredError('resetMethodVerificationRequestBody','Required parameter requestParameters.resetMethodVerificationRequestBody was null or undefined when calling onCallNotificationsResetMethodVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/on_call_notification_methods/{id}/actions/reset_verification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnCallNotificationsResetMethodVerificationRequestBodyToJSON(requestParameters.resetMethodVerificationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsResetMethodVerificationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Reset the verification flow for a pending verification method.
     * ResetMethodVerification On-call notifications
     */
    async onCallNotificationsResetMethodVerification(requestParameters: OnCallNotificationsResetMethodVerificationRequest, initOverrides?: RequestInit): Promise<OnCallNotificationsResetMethodVerificationResponseBody> {
        const response = await this.onCallNotificationsResetMethodVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single on-call notification method.
     * ShowMethod On-call notifications
     */
    async onCallNotificationsShowMethodRaw(requestParameters: OnCallNotificationsShowMethodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsShowMethodResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling onCallNotificationsShowMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_notification_methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsShowMethodResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single on-call notification method.
     * ShowMethod On-call notifications
     */
    async onCallNotificationsShowMethod(requestParameters: OnCallNotificationsShowMethodRequest, initOverrides?: RequestInit): Promise<OnCallNotificationsShowMethodResponseBody> {
        const response = await this.onCallNotificationsShowMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single on-call notification rule.
     * ShowRule On-call notifications
     */
    async onCallNotificationsShowRuleRaw(requestParameters: OnCallNotificationsShowRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsShowRuleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling onCallNotificationsShowRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/on_call_notification_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsShowRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single on-call notification rule.
     * ShowRule On-call notifications
     */
    async onCallNotificationsShowRule(requestParameters: OnCallNotificationsShowRuleRequest, initOverrides?: RequestInit): Promise<OnCallNotificationsShowRuleResponseBody> {
        const response = await this.onCallNotificationsShowRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Test a contact method.
     * TestContactMethod On-call notifications
     */
    async onCallNotificationsTestContactMethodRaw(requestParameters: OnCallNotificationsTestContactMethodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.onCallNotificationType === null || requestParameters.onCallNotificationType === undefined) {
            throw new runtime.RequiredError('onCallNotificationType','Required parameter requestParameters.onCallNotificationType was null or undefined when calling onCallNotificationsTestContactMethod.');
        }

        if (requestParameters.testContactMethodRequestBody === null || requestParameters.testContactMethodRequestBody === undefined) {
            throw new runtime.RequiredError('testContactMethodRequestBody','Required parameter requestParameters.testContactMethodRequestBody was null or undefined when calling onCallNotificationsTestContactMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/on_call_notification_type/{on_call_notification_type}/actions/test`.replace(`{${"on_call_notification_type"}}`, encodeURIComponent(String(requestParameters.onCallNotificationType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnCallNotificationsTestContactMethodRequestBodyToJSON(requestParameters.testContactMethodRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test a contact method.
     * TestContactMethod On-call notifications
     */
    async onCallNotificationsTestContactMethod(requestParameters: OnCallNotificationsTestContactMethodRequest, initOverrides?: RequestInit): Promise<void> {
        await this.onCallNotificationsTestContactMethodRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing on-call notification rule.
     * UpdateRule On-call notifications
     */
    async onCallNotificationsUpdateRuleRaw(requestParameters: OnCallNotificationsUpdateRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnCallNotificationsUpdateRuleResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling onCallNotificationsUpdateRule.');
        }

        if (requestParameters.updateRuleRequestBody === null || requestParameters.updateRuleRequestBody === undefined) {
            throw new runtime.RequiredError('updateRuleRequestBody','Required parameter requestParameters.updateRuleRequestBody was null or undefined when calling onCallNotificationsUpdateRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/on_call_notification_rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OnCallNotificationsUpdateRuleRequestBodyToJSON(requestParameters.updateRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnCallNotificationsUpdateRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing on-call notification rule.
     * UpdateRule On-call notifications
     */
    async onCallNotificationsUpdateRule(requestParameters: OnCallNotificationsUpdateRuleRequest, initOverrides?: RequestInit): Promise<OnCallNotificationsUpdateRuleResponseBody> {
        const response = await this.onCallNotificationsUpdateRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
