import { enrichAvailableFilterFields } from "@incident-shared/filters";
import { useStatefulQueryParamFilters } from "@incident-shared/filters/useStatefulQueryParamFilters";

import { useAPI } from "../../../utils/swr";

export const useAlertFilters = () => {
  const {
    data: { fields: availableFilterFields },
    isLoading: filtersLoading,
    error: filtersError,
  } = useAPI("alertsListFilterFields", undefined, {
    fallbackData: { fields: [] },
  });

  const filterFields = enrichAvailableFilterFields(availableFilterFields);

  const { getSelectedFilters, setSelectedFilters } =
    useStatefulQueryParamFilters({
      availableFilterFields: filterFields,
      availableParams: [],
    });

  const filters = getSelectedFilters();

  return {
    filters,
    setFilters: setSelectedFilters,
    filtersLoading,
    filtersError,
    filterFields,
  };
};
