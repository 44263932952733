import { AvailablePanel, AvailablePanelGroupEnum } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  ContentBox,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import {
  SearchBar,
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { Searcher, sortKind } from "fast-fuzzy";
import { Dictionary, groupBy } from "lodash";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { availablePanelToFormData } from "../common/marshall";
import { PanelGraphic } from "./panel-graphics/PanelGraphic";
import { usePanelsFormState } from "./PanelsFormStateContext";

export const CustomDashboardAddPanelDrawer = ({
  insertAtIndex,
  onClose,
}: {
  insertAtIndex?: number;
  onClose: () => void;
}) => {
  return (
    <Drawer onClose={onClose} width={"medium"} className="overflow-y-hidden">
      <DrawerTitle
        icon={IconEnum.BarChart}
        title={"Dashboard panels"}
        onClose={onClose}
        closeIcon={IconEnum.Close}
        sticky
        compact
        color={ColorPaletteEnum.Orange}
      />
      <CustomDashboardAddPanelInner
        onClose={onClose}
        insertAtIndex={insertAtIndex}
      />
    </Drawer>
  );
};

const CustomDashboardAddPanelInner = ({
  onClose,
  insertAtIndex,
}: {
  onClose: () => void;
  insertAtIndex?: number;
}) => {
  const {
    data: panelsData,
    isLoading: panelsLoading,
    error: panelsError,
  } = useAPI("insightsListAvailablePanels", undefined);

  if (panelsLoading || !panelsData) {
    return <DrawerContentsLoading />;
  }

  if (panelsError) {
    <GenericErrorMessage description="Unable to fetch panels" />;
  }

  return (
    <DrawerContents>
      {/* Remove padding so we can keep the scrollbar on the right of the drawer */}
      <DrawerBody className="pr-0 pb-0">
        <SearchProvider>
          <PanelsSelectionGrid
            panels={panelsData.panels}
            onClose={onClose}
            insertAtIndex={insertAtIndex}
          />
        </SearchProvider>
      </DrawerBody>
    </DrawerContents>
  );
};

const PanelsSelectionGrid = ({
  panels,
  onClose,
  insertAtIndex,
}: {
  panels: AvailablePanel[];
  onClose: () => void;
  insertAtIndex?: number;
}) => {
  const searchBarProps = useSearchContext();

  const { value: search } = searchBarProps;

  const searcher = new Searcher(panels, {
    keySelector: (panel) => [panel.name],
    sortBy: sortKind.insertOrder,
    threshold: 0.8,
  });

  const filteredPanels: AvailablePanel[] = search
    ? searcher.search(search)
    : panels;
  const groupedPanels: Dictionary<AvailablePanel[]> = groupBy(
    filteredPanels,
    (p) => p.group,
  );

  return (
    <div className="flex flex-col gap-6 w-full pb-16">
      <SearchBar
        {...searchBarProps}
        placeholder="Search panels"
        autoFocus
        className="w-full pr-6"
      />
      {filteredPanels.length === 0 && (
        <EmptyState
          icon={IconEnum.Chart}
          title="No panels match your search"
          content="Try adjusting your search terms"
          className="w-full grow"
        />
      )}
      <div className="flex flex-col gap-10 overflow-y-auto pr-6 pb-6 grow">
        {Object.keys(groupedPanels)
          // Sort by Text panel first, then alphabetical
          .sort((a, b) => {
            if (a === AvailablePanelGroupEnum.Layout) {
              return -1;
            } else if (b === AvailablePanelGroupEnum.Layout) {
              return 1;
            } else if (a < b) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((group, idx) => {
            return (
              <div className="flex flex-col gap-2" key={idx}>
                <div className="text-sm-med text-content-tertiary">{group}</div>
                <div className="grid grid-cols-2 gap-3">
                  {groupedPanels[group].map((panel, panelIdx) => (
                    <PanelCard
                      key={panelIdx}
                      panel={panel}
                      onClose={onClose}
                      insertAtIndex={insertAtIndex}
                    />
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const PanelCard = ({
  panel,
  onClose,
  insertAtIndex,
}: {
  panel: AvailablePanel;
  onClose: () => void;
  insertAtIndex?: number;
}) => {
  const PanelContent = () => (
    <>
      <div className="flex flex-col text-left grow gap-2 cursor-pointer">
        <div className="text-sm text-content-primary font-semibold flex items-center justify-between">
          <div>{panel.add_panel_name}</div>
        </div>

        <div className="text-sm text-content-tertsiary">
          {panel.add_panel_description}
        </div>
        <PanelGraphic panel={panel} />
      </div>
    </>
  );

  const contentBoxClasses = tcx(
    "rounded-2 p-4 h-full w-full",
    "hover:shadow-md hover:border-stroke-hover transition duration-150 shadow-sm border border-stroke",
    "bg-white",
    "flex flex-col gap-6",
  );

  const { append, insert } = usePanelsFormState();

  const addPanelToFormData = () => {
    if (insertAtIndex === undefined) {
      append(availablePanelToFormData(panel));
    } else {
      insert(insertAtIndex, availablePanelToFormData(panel));
    }
  };

  return (
    <ContentBox
      className={tcx(contentBoxClasses)}
      onClick={() => {
        addPanelToFormData();
        onClose();
      }}
    >
      <PanelContent />
    </ContentBox>
  );
};
