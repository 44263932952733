import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DraggableAttributes,
  DraggableSyntheticListeners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  AlertAttribute,
  AlertSchema,
  AlertSchemaPayload,
  ScopeNameEnum,
} from "@incident-io/api";
import { CatalogTypeSelectorV2 } from "@incident-shared/catalog";
import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { DisableConfirmationModal } from "@incident-shared/forms/DisableConfirmationModal/DisableConfirmationModal";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { DragHandle } from "@incident-shared/settings";
import {
  Button,
  ButtonTheme,
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  IconEnum,
  IconSize,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useDrawerFormState } from "@incident-ui/Drawer/DrawerFormStateContext";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import React, { ForwardedRef } from "react";
import { Component, useCallback, useEffect, useState } from "react";
import {
  FieldArrayWithId,
  useFieldArray,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { joinSpansWithCommasAndConnectorWord } from "src/utils/utils";
import { v4 as uuidv4 } from "uuid";

export const AlertAttributesForm = ({
  alertSchema,
  onClose,
}: {
  alertSchema: AlertSchema;
  onClose: () => void;
}) => {
  const showToast = useToast();

  const formMethods = useForm<AlertSchemaPayload>({
    defaultValues: {
      attributes: alertSchema.attributes,
      version: alertSchema.version,
    },
  });

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const { setIsDirty } = useDrawerFormState();
  useEffect(() => {
    setIsDirty(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty, setIsDirty]);

  const mutation = useAPIMutation(
    "alertsShowSchema",
    undefined,
    async (client, payload: AlertSchemaPayload) => {
      setShowDeleteConfirmModal(false);
      const data = await client.alertsUpdateSchema({
        updateSchemaRequestBody: {
          alert_schema: payload,
        },
      });

      formMethods.setValue<"version">("version", data.alert_schema.version);
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Alert attributes updated",
          toastSide: ToastSideEnum.TopRight,
        });
        onClose();
      },
      onError: async () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Could not update your alert attributes.",
          toastSide: ToastSideEnum.TopRight,
        });
      },
    },
  );

  const { hasScope } = useIdentity();
  const canEditSchema = hasScope(ScopeNameEnum.AlertSchemaUpdate);

  // Fetch the attributes so we can use them while dragging.
  const attributes = formMethods.watch("attributes");

  const attributesBeingRemoved = alertSchema.attributes.filter(
    (existingAttribute) =>
      !attributes.find((attr) => attr.id === existingAttribute.id),
  );

  const { append, fields, remove } = useFieldArray({
    control: formMethods.control,
    name: "attributes",
    keyName: "key",
  });

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over.id) {
        const fromIndex = fields.findIndex((field) => field.key === active.id);
        const toIndex = fields.findIndex((field) => field.key === over.id);
        const attributesClone = _.clone(attributes) || [];

        // Snip out the element we moved
        const [removed] = attributesClone.splice(fromIndex, 1);

        // Insert it back into the list wherever we dragged it to
        attributesClone.splice(toIndex, 0, removed);

        formMethods.setValue("attributes", attributesClone, {
          shouldDirty: true,
        });
      }
    },
    [attributes, fields, formMethods],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <div>
      <div>
        <Form.Root
          onSubmit={(formData: AlertSchemaPayload) => {
            if (formData.version !== alertSchema.version) {
              showToast({
                theme: ToastTheme.Error,
                title:
                  "The alert schema has been updated by another user. Please refresh the page to see the latest changes.",
                toastSide: ToastSideEnum.TopRight,
              });
              return;
            }
            if (attributesBeingRemoved.length > 0) {
              setShowDeleteConfirmModal(true);
            } else {
              mutation.trigger(formData);
            }
          }}
          saving={mutation.isMutating}
          formMethods={formMethods}
          genericError={mutation.genericError}
          warnWhenDirty
        >
          <div>
            <fieldset>
              <DeprecatedTable className={"shadow-none"}>
                <DeprecatedTableHeaderRow
                  className={
                    "bg-white rounded-t-xl text-xs font-medium text-content-secondary"
                  }
                >
                  <DeprecatedTableHeaderCell>Name</DeprecatedTableHeaderCell>
                  <DeprecatedTableHeaderCell className="min-w-[270px]">
                    <div className="flex-center-y gap-1">
                      Resource type
                      <Tooltip
                        content={
                          <span>
                            The resource type defines what kind of value this
                            attribute represents. It can be a primitive value
                            such as a string or number, or can refer to a
                            Catalog type.
                          </span>
                        }
                      />
                    </div>
                  </DeprecatedTableHeaderCell>
                  <DeprecatedTableHeaderCell className={"min-w-[100px]"}>
                    Multi-value
                  </DeprecatedTableHeaderCell>
                  <DeprecatedTableHeaderCell textHidden>
                    delete
                  </DeprecatedTableHeaderCell>
                </DeprecatedTableHeaderRow>
                {attributes.length === 0 && (
                  <tr>
                    <td align="center" colSpan={4}>
                      <div className={"py-10"}>
                        <Txt className="font-medium">
                          You haven&rsquo;t configured any attributes
                        </Txt>
                        <Txt>
                          You can add attributes to use across your alert
                          sources here, or from one of your alert sources.
                        </Txt>
                      </div>
                    </td>
                  </tr>
                )}
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={onDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <tbody>
                    <SortableContext
                      items={fields.map((field) => field.key)}
                      strategy={verticalListSortingStrategy}
                    >
                      {fields.map((field, idx) => (
                        <SortableAlertAttributeRow
                          index={idx}
                          key={field.key}
                          field={field}
                          onDelete={() => remove(idx)}
                          formMethods={formMethods}
                          existingAttributes={alertSchema.attributes}
                        />
                      ))}
                    </SortableContext>
                  </tbody>
                </DndContext>
              </DeprecatedTable>
            </fieldset>
          </div>
          <div className="flex justify-end space-x-3 items-center">
            <Button
              analyticsTrackingId="alert-schema-add-attribute"
              onClick={() => {
                append({
                  id: uuidv4(),
                  name: "",
                  array: false,
                  type: "",
                });
              }}
              className="!ml-3 !my-2"
            >
              Add attribute
            </Button>
            <GatedButton
              type="submit"
              analyticsTrackingId="alert-settings-submit"
              theme={ButtonTheme.Primary}
              loading={mutation.isMutating}
              disabled={!formMethods.formState.isDirty || !canEditSchema}
              disabledTooltipContent={
                !canEditSchema
                  ? "You don't have permission to update alert attributes"
                  : undefined
              }
            >
              Save changes
            </GatedButton>
          </div>
        </Form.Root>
      </div>

      {showDeleteConfirmModal ? (
        <DisableConfirmationModal
          helpText={
            <>
              <p className="mb-2">
                If you remove{" "}
                {joinSpansWithCommasAndConnectorWord(
                  attributesBeingRemoved.map((a) => (
                    <span key={a.id} className={"font-medium"}>
                      {a.name}
                    </span>
                  )),
                )}
                , you&apos;ll no longer be able to see{" "}
                {attributesBeingRemoved.length > 1 ? "them " : "it "} on your
                alerts and you&apos;ll be unable to use them in alert routes.
              </p>
              <p className="mb-2">
                Please type &apos;delete&apos; below to confirm.
              </p>
            </>
          }
          title={"Delete attributes"}
          typeToConfirmPhrase={"delete"}
          submitText={"Confirm"}
          onSubmit={() => mutation.trigger(formMethods.getValues())}
          onClose={() => setShowDeleteConfirmModal(false)}
        />
      ) : null}
    </div>
  );
};

const SortableAlertAttributeRow = ({
  field,
  ...props
}: AlertAttributeRowProps & {
  field: FieldArrayWithId<AlertSchemaPayload, "attributes", "key">;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: field.key });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <AlertAttributeRow
      {...props}
      listeners={listeners}
      attributes={attributes}
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
    />
  );
};

type AlertAttributeRowProps = {
  index: number;
  formMethods: UseFormReturn<AlertSchemaPayload, unknown>;
  onDelete: () => void;
  existingAttributes: AlertAttribute[];
};
const AlertAttributeRow = React.forwardRef(
  (
    {
      index,
      formMethods,
      onDelete,
      isDragging,
      existingAttributes,
      style,
      attributes,
      listeners,
    }: AlertAttributeRowProps & {
      style: React.CSSProperties;
      isDragging: boolean;
      attributes: DraggableAttributes;
      listeners: DraggableSyntheticListeners;
    },
    ref: ForwardedRef<HTMLTableRowElement>,
  ) => {
    const attributeType = formMethods.watch(`attributes.${index}.type`);
    const attributeName = formMethods.watch(`attributes.${index}.name`);
    const attributeID = formMethods.getValues(`attributes.${index}.id`);
    const arrayNotSupported = ["Text", "Bool", "Number"].includes(
      attributeType,
    );

    const newAttribute = !existingAttributes.find(
      (attr) => attr.id === attributeID,
    );

    // If you choose a resource type that we don't support arrays for, we lock the
    // multi-value switch and set it to false
    useEffect(() => {
      if (arrayNotSupported) {
        formMethods.setValue<`attributes.${number}.array`>(
          `attributes.${index}.array`,
          false,
        );
      }
      // This should only rerun if the attribute type changes,
      // we don't care if it gets reordered
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeType]);

    const { data, isLoading: isLoadingDependentResources } = useAPI(
      newAttribute ? null : "engineFindDependentResourcesForMultiple",
      {
        findDependentResourcesForMultipleRequestBody: {
          resources: [
            {
              resource_type: "AlertAttribute",
              id: attributeID,
            },
          ],
        },
      },
    );

    const attributeDependents = data?.dependent_resources || [];
    const disableBecauseOfDependents =
      isLoadingDependentResources || attributeDependents.length > 0;

    const dependentResourceTooltipContent = (
      <div>
        {isLoadingDependentResources ? (
          <p className="mb-2">
            Loading resources depending on{" "}
            <span className="font-medium">{attributeName}</span>
          </p>
        ) : (
          <DependentResourceList
            title={attributeName}
            requiresDeletionResources={[attributeDependents]}
            whiteText={true}
          />
        )}
      </div>
    );

    const multiValueDependentResourceTooltipContent = (
      <div>
        {isLoadingDependentResources ? (
          <p className="mb-2">
            Loading resources depending on{" "}
            <span className="font-medium">{attributeName}</span>
          </p>
        ) : (
          <DependentResourceList
            title={attributeName}
            verb={"toggle multi-value for"}
            requiresDeletionResources={[attributeDependents]}
            whiteText={true}
          />
        )}
      </div>
    );

    return (
      <tr ref={ref} style={style} {...attributes}>
        <LockedCell isDragging={isDragging}>
          <div className={"flex flex-center-y"}>
            <DragHandle className="mr-2 flex-0" {...listeners} />
            <InputV2
              name={`attributes.${index}.name`}
              formMethods={formMethods}
              placeholder="Attribute name"
              required
            />
          </div>
        </LockedCell>
        <LockedCell isDragging={isDragging}>
          <CatalogTypeSelectorV2
            disabled={disableBecauseOfDependents}
            disabledTooltipContent={
              disableBecauseOfDependents
                ? dependentResourceTooltipContent
                : undefined
            }
            name={`attributes.${index}.type`}
            formMethods={formMethods}
            mode={"engine"}
            required
            className="w-full"
            triggerClassName="w-full"
            disabledCatalogTypes={
              // Only allow Alert Priority if this is an existing attribute that already has Alert Priority type
              existingAttributes.find((attr) => attr.id === attributeID)
                ?.type === `CatalogEntry["AlertPriority"]`
                ? []
                : ["AlertPriority"]
            }
          />
        </LockedCell>
        <LockedCell isDragging={isDragging}>
          <ToggleV2
            name={`attributes.${index}.array`}
            formMethods={formMethods}
            disabled={arrayNotSupported || disableBecauseOfDependents}
            isDisabledTooltipContent={
              disableBecauseOfDependents
                ? multiValueDependentResourceTooltipContent
                : `Multi-valued ${
                    attributeType ? attributeType.toLowerCase() : ""
                  } attributes are not supported`
            }
          />
        </LockedCell>
        <LockedCell isDragging={isDragging}>
          <GatedButton
            disabled={disableBecauseOfDependents}
            disabledTooltipContent={dependentResourceTooltipContent}
            analyticsTrackingId="alert-schema-delete-attribute"
            theme={ButtonTheme.Naked}
            icon={IconEnum.Delete2}
            iconProps={{
              size: IconSize.Large,
            }}
            title="delete"
            onClick={onDelete}
          />
        </LockedCell>
      </tr>
    );
  },
);
AlertAttributeRow.displayName = "AlertAttributeRow";

type LockedCellProps = {
  className?: string;
  isDragging: boolean;
  children: React.ReactNode;
};

type LockedCellState = {
  snapshot: {
    width: number;
    height: number;
  };
};

// The getSnapshotBeforeUpdate lifecycle is not possible when using function
// components, so we'll write this as a class instead.
//
// We need this special cell component to support drag-and-drop with tables that
// flexibly adjust column width for their content. That's because when you
// remove the <tr> from the table, you lose the grid sizing that you get from
// being inside the <table>, so we must:
//
// 1. Store our current size just before we begin dragging
// 2. Apply that size to the cells via inline styles so they retain their size
// while being moved
// 3. Remove the inline styles when we detect dragging is finished
class LockedCell extends Component<LockedCellProps, LockedCellState> {
  ref;

  getSnapshotBeforeUpdate(prevProps: LockedCellProps) {
    if (!this.ref) {
      return null;
    }

    const isDragStarting = this.props.isDragging && !prevProps.isDragging;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    const snapshot = {
      width,
      height,
    };

    return snapshot;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragging) {
      return;
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty("height");
    ref.style.removeProperty("width");
  }

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    return (
      <td className={this.props.className} ref={this.setRef}>
        {this.props.children}
      </td>
    );
  }
}
