import { LoadingBar } from "@incident-ui";
import { useAPI } from "src/utils/swr";

export const WaitForCatalogType = ({ typeLabel }: { typeLabel: string }) => {
  // Increase the default refresh rate
  useAPI(
    "catalogListTypes",
    {},
    {
      refreshInterval: 5000,
    },
  );

  return (
    <LoadingBar className="mb-2 h-auto">
      We&rsquo;re fetching your {typeLabel}. This may take a few moments.
    </LoadingBar>
  );
};
