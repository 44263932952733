import "reactflow/dist/style.css";

import { AlertRoute, AlertSourceConfig } from "@incident-io/api";
import { useResize } from "@incident-io/status-page-ui/use-resize";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { Searcher, sortKind } from "fast-fuzzy";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import ReactFlow, {
  NodeProps,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { useAPI } from "src/utils/swr";

import { tcx } from "../../../utils/tailwind-classes";
import { shouldShowAlertSourcesEmptyState } from "../common/shouldShowAlertSourcesEmptyState";
import { AlertRouteItem } from "./AlertRouteItem";
import { AlertRoutesEmptyState } from "./AlertRoutesEmptyState";
import { AlertRoutesHeader } from "./AlertRoutesHeader";
import { AlertsConfigurationEdge } from "./AlertsConfigurationEdge";
import { AlertsConfigurationNodeType } from "./AlertsConfigurationNode";
import { AlertSourceItem } from "./AlertSourceItem";
import { AlertSourcesHeader } from "./AlertSourcesHeader";
import { drawEdges } from "./helpers/drawEdges";
import { drawNodes } from "./helpers/drawNodes";
import { hasSourcesAndRoutesCrossover } from "./helpers/hasSourcesAndRoutesCrossover";
import { useHoveringState } from "./helpers/useHoveringState";

const nodeTypes: Record<AlertsConfigurationNodeType, React.FC<NodeProps>> = {
  [AlertsConfigurationNodeType.NoAlertRoutes]: AlertRoutesEmptyState,
  [AlertsConfigurationNodeType.AlertSource]: AlertSourceItem,
  [AlertsConfigurationNodeType.AlertRoute]: AlertRouteItem,
};
const edgeTypes = { alertsConfigurationEdge: AlertsConfigurationEdge };

export const useAlertSourceConfigs = () => {
  const {
    data: { alert_source_configs: alertSourceConfigs },
    isLoading: configsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  return { alertSourceConfigs, configsLoading, sourceConfigsError };
};

export const useAlertRoutes = () => {
  const {
    data: { alert_routes: alertRoutes },
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined, {
    fallbackData: { alert_routes: [] },
  });

  return { alertRoutes, alertRoutesLoading, alertRoutesError };
};

export const AlertsConfigurationPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { width: containerWidth } = useResize(ref);
  const location = useLocation();

  const { alertSourceConfigs, configsLoading, sourceConfigsError } =
    useAlertSourceConfigs();
  const { alertRoutes, alertRoutesLoading, alertRoutesError } =
    useAlertRoutes();

  if (configsLoading || alertRoutesLoading) {
    return <Loader />;
  }

  if (sourceConfigsError || alertRoutesError) {
    return (
      <GenericErrorMessage error={sourceConfigsError || alertRoutesError} />
    );
  }

  const shouldShowEmptyState = shouldShowAlertSourcesEmptyState(
    alertSourceConfigs,
    alertRoutes,
  );

  // Only redirect to get started if you're not linking directly to the attributes page,
  // as you might have deleted all your sources/routes, but still be referencing some
  // catalog type in your attributes, preventing you from deleting it.
  if (shouldShowEmptyState && !location.pathname.includes("/attributes")) {
    return <OrgAwareNavigate to="/alerts/get-started" />;
  }

  return (
    <div ref={ref} className="min-w-[1200px]">
      <AlertsConfigurationPageFlow
        alertSourceConfigs={alertSourceConfigs}
        alertRoutes={alertRoutes}
        containerWidth={containerWidth || window.innerWidth}
      />
    </div>
  );
};

export const AlertsConfigurationPageFlow = ({
  alertSourceConfigs,
  alertRoutes,
  containerWidth,
}: {
  alertSourceConfigs: AlertSourceConfig[];
  alertRoutes: AlertRoute[];
  containerWidth: number;
}) => {
  const { forceAlertsConfigurationGrouping } = useFlags();

  const [searchSources, setSearchSources] = useState("");
  const [searchRoutes, setSearchRoutes] = useState("");

  // Filter our the sources that don't match the search term in the source header
  const sourceSearcher = useMemo(
    () =>
      new Searcher(alertSourceConfigs, {
        keySelector: (src) => src.name,
        sortBy: sortKind.insertOrder,
        threshold: 0.8,
      }),
    [alertSourceConfigs],
  );

  const filteredAlertSources = useMemo(
    () =>
      searchSources ? sourceSearcher.search(searchSources) : alertSourceConfigs,
    [searchSources, sourceSearcher, alertSourceConfigs],
  );

  // Filter out the routes that don't match the search term in the route header
  const routeSearcher = useMemo(
    () =>
      new Searcher(alertRoutes, {
        keySelector: (route) => route.name,
        sortBy: sortKind.insertOrder,
        threshold: 0.8,
      }),
    [alertRoutes],
  );

  const filteredAlertRoutes = useMemo(
    () => (searchRoutes ? routeSearcher.search(searchRoutes) : alertRoutes),
    [searchRoutes, routeSearcher, alertRoutes],
  );

  // We pass this parameter separately as we don't want our empty state to show if someone's
  // filtered down their routes.
  const hasNoAlertRoutes = alertRoutes.length === 0;

  const hasComplexSetup =
    !forceAlertsConfigurationGrouping &&
    hasSourcesAndRoutesCrossover(filteredAlertSources);

  // Draw the nodes/edges and update them when the container width changes
  const [height, setHeight] = useState(0);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  useEffect(() => {
    const { nodes: newNodes, height: newHeight } = drawNodes({
      alertSources: filteredAlertSources,
      alertRoutes: filteredAlertRoutes,
      containerWidth,
      hasNoAlertRoutes,
      hasComplexSetup,
    });

    setNodes(newNodes);
    setHeight(newHeight);
  }, [
    containerWidth,
    filteredAlertRoutes,
    filteredAlertSources,
    hasComplexSetup,
    hasNoAlertRoutes,
    setNodes,
  ]);

  const haveNodes = nodes.length > 0;
  useEffect(() => {
    if (haveNodes) {
      const newEdges = drawEdges({
        filteredAlertSources,
        filteredAlertRoutes,
        hasNoAlertRoutes,
      });
      setEdges(newEdges);
    }
  }, [
    filteredAlertRoutes,
    filteredAlertSources,
    hasNoAlertRoutes,
    haveNodes,
    setEdges,
  ]);

  // Track which nodes/edges should be highlighted, based on what the user is hovering over
  const { hoveringNodesAndEdges, onHoveringEdge, onHoveringNode, onHoverEnd } =
    useHoveringState({
      edges,
    });

  return (
    <AlertConfigurationContext.Provider
      value={{
        searchSources,
        searchRoutes,
        setSearchSources,
        setSearchRoutes,
        hoveringNodesAndEdges,
      }}
    >
      <div
        className={tcx(
          "px-1 pb-6",
          "bg-surface-secondary bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]",
        )}
      >
        <div className="w-full flex flex-row justify-between p-6">
          <AlertSourcesHeader />
          <AlertRoutesHeader />
        </div>
        <div style={{ height: `${height}px` }}>
          <ReactFlowProvider>
            <ReactFlow
              nodes={nodes}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              edges={edges}
              edgeTypes={edgeTypes}
              onEdgesChange={onEdgesChange}
              onEdgeMouseEnter={onHoveringEdge}
              onEdgeMouseLeave={onHoverEnd}
              onEdgeMouseMove={onHoveringEdge}
              onNodeMouseEnter={onHoveringNode}
              onNodeMouseMove={onHoveringNode}
              onNodeMouseLeave={onHoverEnd}
              panOnDrag={false}
              zoomOnScroll={false}
              zoomOnPinch={false}
              preventScrolling={false}
              proOptions={PRO_OPTIONS}
              nodesDraggable={false}
              nodesConnectable={false}
              nodesFocusable={false}
              edgesFocusable={false}
              autoPanOnConnect={false}
              autoPanOnNodeDrag={false}
              zoomOnDoubleClick={false}
              selectNodesOnDrag={false}
              elevateNodesOnSelect={false}
              connectOnClick={false}
            />
          </ReactFlowProvider>
        </div>
      </div>
    </AlertConfigurationContext.Provider>
  );
};

const AlertConfigurationContext = createContext<{
  searchSources: string;
  searchRoutes: string;
  setSearchSources: React.Dispatch<React.SetStateAction<string>>;
  setSearchRoutes: React.Dispatch<React.SetStateAction<string>>;
  hoveringNodesAndEdges: { nodeIds: Set<string>; edgeIds: Set<string> } | null;
}>({
  searchSources: "",
  searchRoutes: "",
  setSearchSources: () => null,
  setSearchRoutes: () => null,
  hoveringNodesAndEdges: null,
});

export const useAlertConfigContext = () => {
  return useContext(AlertConfigurationContext);
};

export const useReduceOpacity = ({
  nodeId,
  edgeId,
}: { nodeId: string; edgeId?: never } | { nodeId?: never; edgeId: string }) => {
  const { hoveringNodesAndEdges } = useAlertConfigContext();
  // If we're not hovering, do nothing
  if (hoveringNodesAndEdges == null) {
    return false;
  }

  // We are hovering on something! If this isn't in the highlighted set, fade it out
  if (nodeId) {
    return !hoveringNodesAndEdges.nodeIds.has(nodeId);
  } else if (edgeId) {
    return !hoveringNodesAndEdges.edgeIds.has(edgeId);
  }

  // Unreachable: default to not fading for safety
  return false;
};

// These are a constant to avoid triggering a re-render
const PRO_OPTIONS = { hideAttribution: true };
