import {
  SecondaryNavPageWrapper,
  SecondaryNavSubPageWrapper,
  SecondaryNavSubPageWrapperProps,
} from "@incident-shared/layout/SecondaryNav";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { IconEnum } from "@incident-ui";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { useWorkbenchPages } from "src/components/workbench/WorkbenchIndexPage";
import { useIdentity } from "src/contexts/IdentityContext";

export const WorkbenchRoute = () => {
  const { identity, isImpersonating } = useIdentity();
  const perms = identity?.staff_permissions ?? [];
  const pageGroups = useWorkbenchPages();

  if (perms.length === 0 && !isImpersonating) {
    return <NotFoundPage />;
  }

  return (
    <SecondaryNavPageWrapper
      title="Workbench"
      navItemGroups={pageGroups}
      pathPrefix="workbench"
      icon={IconEnum.Briefcase}
    >
      <Routes>
        {pageGroups.map((grp) =>
          grp.items.map((page) => {
            return (
              <Route
                path={`${page.slug}/*`}
                element={
                  <>
                    <Helmet title={`Workbench: ${page.label} - incident.io`} />
                    <page.Component />
                  </>
                }
                key={page.slug}
              />
            );
          }),
        )}
        {/* Fallback with a redirect to a specific subsection */}
        <Route path="*" element={<OrgAwareNavigate to="overview" replace />} />
      </Routes>
    </SecondaryNavPageWrapper>
  );
};

export const WorkbenchSubPageWrapper = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & Omit<SecondaryNavSubPageWrapperProps, "icon" | "title"> & {
    title?: string;
  }) => {
  const options = useWorkbenchPages();

  const location = useLocation();
  // /org-slug/workbench/:slug
  const slug = location.pathname.split("/")[3];

  // to find the page, we'll take the path, split it by slashes
  // and go through the slashes until we find a match for our 'parent' page
  // there can't be collisions here because the slugs are unique!
  const page = options.flatMap((p) => p.items).find((i) => slug === i.slug);

  return (
    <SecondaryNavSubPageWrapper
      icon={page?.icon || IconEnum.Briefcase}
      title={page?.label || "Workbench"}
      {...props}
    >
      {children}
    </SecondaryNavSubPageWrapper>
  );
};
