/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Investigation,
    InvestigationFromJSON,
    InvestigationFromJSONTyped,
    InvestigationToJSON,
} from './Investigation';
import {
    InvestigationCheck,
    InvestigationCheckFromJSON,
    InvestigationCheckFromJSONTyped,
    InvestigationCheckToJSON,
} from './InvestigationCheck';

/**
 * 
 * @export
 * @interface AIShowInvestigationResponseBody
 */
export interface AIShowInvestigationResponseBody {
    /**
     * Checks that are associated with this investigation
     * @type {Array<InvestigationCheck>}
     * @memberof AIShowInvestigationResponseBody
     */
    checks: Array<InvestigationCheck>;
    /**
     * 
     * @type {Investigation}
     * @memberof AIShowInvestigationResponseBody
     */
    investigation: Investigation;
}

export function AIShowInvestigationResponseBodyFromJSON(json: any): AIShowInvestigationResponseBody {
    return AIShowInvestigationResponseBodyFromJSONTyped(json, false);
}

export function AIShowInvestigationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIShowInvestigationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checks': ((json['checks'] as Array<any>).map(InvestigationCheckFromJSON)),
        'investigation': InvestigationFromJSON(json['investigation']),
    };
}

export function AIShowInvestigationResponseBodyToJSON(value?: AIShowInvestigationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checks': ((value.checks as Array<any>).map(InvestigationCheckToJSON)),
        'investigation': InvestigationToJSON(value.investigation),
    };
}

