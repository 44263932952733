import {
  ColorPalette,
  ColorPaletteEnum,
  getColorPalette,
  OnCallExcludeColorPalettes,
} from "@incident-shared/utils/ColorPalettes";
import React, { MutableRefObject, useRef } from "react";
import { stringToHash } from "src/utils/utils";

type UniqueColorContext = {
  usedColors?: MutableRefObject<Record<string, ColorPaletteEnum>>;
};

const UniqueColorContext = React.createContext<UniqueColorContext>({});

export const useUniqueColorGenerator = (): ((
  id: string,
) => Readonly<ColorPalette>) => {
  const { usedColors } = React.useContext(UniqueColorContext);
  if (!usedColors) {
    throw new Error(
      "useUniqueColorGenerator must be used within a UniqueColorProvider",
    );
  }

  return (id: string) => {
    // If we've already assigned a colour to this ID, return that one
    if (usedColors.current[id]) {
      return getColorPalette(usedColors.current[id]);
    }

    const entries = Object.values(ColorPaletteEnum).filter(
      (v) => !OnCallExcludeColorPalettes.includes(v),
    );

    // First hash the id by itself and try to find the unique colour for this ID
    const hashed = stringToHash(id);
    const paletteIndex = hashed % entries.length;
    let candidateColor = entries[paletteIndex];

    // Build a set of available colours by removing the ones we've already used
    const availableColors = new Set(entries);
    Object.values(usedColors.current).forEach((color) => {
      availableColors.delete(color);
    });

    // If we've already used the colour we picked, and we have other colours
    // available, pick the first available colour
    if (!availableColors.has(candidateColor) && availableColors.size > 0) {
      // If we have available colours, but the one we picked is already used,
      // pick a random one from the available colours
      candidateColor = Array.from(availableColors)[0];
    }

    // Mark this colour as used
    usedColors.current[id] = candidateColor;

    return getColorPalette(candidateColor);
  };
};

export const UniqueColorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const usedColors = useRef<Record<string, ColorPaletteEnum>>({});

  return (
    <UniqueColorContext.Provider value={{ usedColors }}>
      {children}
    </UniqueColorContext.Provider>
  );
};
