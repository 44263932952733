import { StatusPageListSubscriptionsRequest } from "@incident-io/api";
import { ReactElement, useEffect } from "react";
import { downloadObjsAsCsv } from "src/utils/csv";
import { useAPIInfinite } from "src/utils/swr";
import { useDebounce } from "use-debounce";

export const DownloadSubscribers = ({
  statusPageId,
  search: rawSearch,
  componentIds,
  onDownload,
}: Pick<
  StatusPageListSubscriptionsRequest,
  "statusPageId" | "search" | "componentIds"
> & { onDownload: () => void }): ReactElement => {
  const [search] = useDebounce(rawSearch, 200);

  const {
    responses,
    isLoading,
    isFullyLoaded: allEntriesLoaded,
  } = useAPIInfinite(
    "statusPageListSubscriptions",
    {
      statusPageId,
      pageSize: 250,
      search,
      componentIds: (componentIds ?? []).length > 0 ? componentIds : undefined, // we need to explicitly pass undefined for this query param to be omitted
    },
    { eagerLoad: true },
  );

  useEffect(() => {
    if (!allEntriesLoaded) return;

    downloadObjsAsCsv(
      "subscribers.csv",
      responses
        .flatMap(({ subscriptions }) => subscriptions)
        .map(({ email, verified_at, components }) => ({
          email,
          verified_at,
          components: components.map(({ name }) => name).join(","),
        })),
    );

    onDownload();
  }, [isLoading, allEntriesLoaded, responses, onDownload]);

  return <></>;
};
