import {
  ManagedResourceResourceTypeEnum as ResourceTypeEnum,
  ManagementMeta,
  Schedule,
  ScheduleExternalProviderEnum,
  ScheduleRotationHandoverIntervalTypeEnum,
  SchedulesGenerateScheduleExportRequestBody,
  SchedulesGenerateScheduleExportRequestBodyModeEnum,
  WeekdayIntervalWeekdayEnum,
} from "@incident-io/api";
import { CopyToTerraformDrawer } from "@incident-shared/management-meta/CopyToTerraformDrawer";
import { defaultManagementMeta } from "@incident-shared/management-meta/utils";
import { Loader } from "@incident-ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { ScheduleFormData, WorkingInterval } from "../common/types";
import { flattenRotationsRecord } from "../common/util";

const numberToTextual = (num: number): string => {
  switch (num) {
    case 0:
      return "zero";
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
      return "three";
    case 4:
      return "four";
    case 5:
      return "five";
    case 6:
      return "six";
    case 7:
      return "seven";
    case 8:
      return "eight";
    case 9:
      return "nine";
    case 10:
      return "ten";
    default:
      return `${num}`;
  }
};

const layersArrayFromCount = (
  layerCount: number,
  rotationID,
): { id: string; name: string }[] => {
  return Array.from({ length: layerCount }, (_, i) => ({
    id: `${rotationID}-${numberToTextual(i + 1)}`,
    name: `Layer ${i + 1}`,
  }));
};

const workingIntervalArrayFromWorkingInterval = (
  workingIntervals: WorkingInterval[],
  hasWorkingIntervals: boolean,
): {
  start_time: string;
  end_time: string;
  weekday: WeekdayIntervalWeekdayEnum;
}[] => {
  if (!hasWorkingIntervals) {
    return [];
  }
  const workingIntervalArray: {
    start_time: string;
    end_time: string;
    weekday: WeekdayIntervalWeekdayEnum;
  }[] = [];
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  for (const day of days) {
    let lowestStart = "";
    let highestEnd = "";
    for (const interval of workingIntervals) {
      if (interval.days[day]) {
        if (lowestStart === "" || interval.start_time < lowestStart) {
          lowestStart = interval.start_time;
        }
        if (highestEnd === "" || interval.end_time > highestEnd) {
          highestEnd = interval.end_time;
        }
        workingIntervalArray.push({
          start_time: lowestStart,
          end_time: highestEnd,
          weekday: day as unknown as WeekdayIntervalWeekdayEnum,
        });
      }
    }
  }
  return workingIntervalArray;
};

const buildConfigPayload = ({
  resourceID,
  scheduleFormData,
}: {
  resourceID?: string;
  scheduleFormData: ScheduleFormData;
}): SchedulesGenerateScheduleExportRequestBody["schedule"] => {
  return {
    id: resourceID ?? "UNSET_SCHEDULE_ID",
    name: scheduleFormData.name,
    timezone: scheduleFormData.timezone,
    config: {
      version: 1,
      rotations: flattenRotationsRecord(scheduleFormData.rotations).map(
        (rotation, idx) => ({
          id: rotation.id || numberToTextual(idx),
          name: rotation.name,
          handover_start_at: rotation.handover_start_at,
          user_ids: rotation.users.map((user) => user.id),
          layers: layersArrayFromCount(rotation.layer_count, rotation.id),
          handovers: rotation.custom_handovers.map((customHandover) => ({
            interval: Number(customHandover.handover_interval),
            interval_type:
              customHandover.handover_interval_type as unknown as ScheduleRotationHandoverIntervalTypeEnum,
          })),
          working_intervals: workingIntervalArrayFromWorkingInterval(
            rotation.working_intervals,
            rotation.has_working_intervals === "specific_times",
          ),
        }),
      ),
    },
    created_at: new Date("2021-01-01T00:00:00Z"),
    updated_at: new Date("2021-01-01T00:00:00Z"),
    external_provider: ScheduleExternalProviderEnum.Native,
    external_provider_id: "",
  };
};

export const ScheduleCopyToTerraformDrawer = ({
  isOpen,
  onClose,
  resourceID,
  managementMeta,
}: {
  isOpen: boolean;
  onClose: () => void;
  resourceID?: string;
  managementMeta?: ManagementMeta;
}): React.ReactElement => {
  const formMethods = useFormContext<ScheduleFormData>();
  const { watch, getValues } = formMethods;

  const currentName = watch("name");

  const formState = getValues();

  const {
    data: terraformData,
    isLoading: terraformLoading,
    error: terraformError,
  } = useAPI(
    isOpen ? "schedulesGenerateScheduleExport" : null,
    {
      generateScheduleExportRequestBody: {
        schedule: buildConfigPayload({
          resourceID,
          scheduleFormData: formState,
        }),
        mode: SchedulesGenerateScheduleExportRequestBodyModeEnum.Terraform,
      },
    },
    {
      fallbackData: {
        _export: "",
      },
    },
  );

  const terraform = terraformData?._export;

  if (terraformLoading) {
    return <Loader />;
  }

  return (
    <CopyToTerraformDrawer
      isOpen={isOpen}
      onClose={onClose}
      terraform={terraform}
      terraformIsLoading={terraformLoading}
      terraformError={terraformError}
      resourceType={ResourceTypeEnum.Schedule}
      managementMeta={managementMeta || defaultManagementMeta()}
      resourceID={resourceID}
      resourceName={currentName}
    />
  );
};

const buildScheduleConfigPayloadFromStatic = (
  schedule: Schedule,
): SchedulesGenerateScheduleExportRequestBody["schedule"] => {
  return {
    id: schedule.id,
    name: schedule.name,
    timezone: schedule.timezone,
    config: {
      version: 1,
      rotations:
        schedule?.config?.rotations.map((rotation) => ({
          id: rotation.id,
          name: rotation.name,
          handover_start_at: rotation.handover_start_at,
          user_ids: rotation.user_ids,
          layers: layersArrayFromCount(rotation.layers.length, rotation.id),
          handovers: rotation.handovers.map((handover) => ({
            interval: handover.interval,
            interval_type:
              handover.interval_type as unknown as ScheduleRotationHandoverIntervalTypeEnum,
          })),
          working_intervals: rotation.working_intervals.map(
            (workingInterval) => ({
              start_time: workingInterval.start_time,
              end_time: workingInterval.end_time,
              weekday:
                workingInterval.weekday as unknown as WeekdayIntervalWeekdayEnum,
            }),
          ),
        })) ?? [],
    },
    created_at: new Date("2021-01-01T00:00:00Z"),
    updated_at: new Date("2021-01-01T00:00:00Z"),
    external_provider: ScheduleExternalProviderEnum.Native,
    external_provider_id: "",
  };
};

export const ScheduleConstantToTerraformDrawer = ({
  isOpen,
  onClose,
  resourceID,
  schedule,
  managementMeta,
}: {
  isOpen: boolean;
  onClose: () => void;
  resourceID?: string;
  schedule: Schedule;
  managementMeta: ManagementMeta;
}): React.ReactElement | null => {
  const {
    data: terraformData,
    isLoading: terraformLoading,
    error: terraformError,
  } = useAPI(
    isOpen ? "schedulesGenerateScheduleExport" : null,
    {
      generateScheduleExportRequestBody: {
        schedule: buildScheduleConfigPayloadFromStatic(schedule),
        mode: SchedulesGenerateScheduleExportRequestBodyModeEnum.Terraform,
      },
    },
    {
      fallbackData: {
        _export: "",
      },
    },
  );

  const terraform = terraformData?._export;

  if (terraformLoading) {
    return null;
  }

  return (
    <CopyToTerraformDrawer
      isOpen={isOpen}
      onClose={onClose}
      terraform={terraform}
      terraformIsLoading={terraformLoading}
      terraformError={terraformError}
      resourceType={ResourceTypeEnum.Schedule}
      resourceID={resourceID}
      resourceName={schedule.name}
      managementMeta={managementMeta}
    />
  );
};
