import { Route, Routes } from "react-router";

import { AIRequestsListPage } from "./AIRequestsListPage";

export const AIRequestsRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<AIRequestsListPage />} />
    </Routes>
  );
};
