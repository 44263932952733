/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationBacktestEntryPayload,
    EvaluationBacktestEntryPayloadFromJSON,
    EvaluationBacktestEntryPayloadFromJSONTyped,
    EvaluationBacktestEntryPayloadToJSON,
} from './EvaluationBacktestEntryPayload';

/**
 * 
 * @export
 * @interface AIStaffCreateEvaluationBacktestRequestBody
 */
export interface AIStaffCreateEvaluationBacktestRequestBody {
    /**
     * Number of investigations that can be run before requiring human intervention
     * @type {number}
     * @memberof AIStaffCreateEvaluationBacktestRequestBody
     */
    credit?: number;
    /**
     * Optional ID of the evaluation dataset this backtest was created from
     * @type {string}
     * @memberof AIStaffCreateEvaluationBacktestRequestBody
     */
    dataset_id?: string;
    /**
     * Entries to add to this backtest
     * @type {Array<EvaluationBacktestEntryPayload>}
     * @memberof AIStaffCreateEvaluationBacktestRequestBody
     */
    entries: Array<EvaluationBacktestEntryPayload>;
    /**
     * Type of investigation plan this backtest is running
     * @type {string}
     * @memberof AIStaffCreateEvaluationBacktestRequestBody
     */
    investigation_plan_type: string;
    /**
     * Optional notes about the rationale for this backtest
     * @type {string}
     * @memberof AIStaffCreateEvaluationBacktestRequestBody
     */
    notes?: string;
}

export function AIStaffCreateEvaluationBacktestRequestBodyFromJSON(json: any): AIStaffCreateEvaluationBacktestRequestBody {
    return AIStaffCreateEvaluationBacktestRequestBodyFromJSONTyped(json, false);
}

export function AIStaffCreateEvaluationBacktestRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffCreateEvaluationBacktestRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credit': !exists(json, 'credit') ? undefined : json['credit'],
        'dataset_id': !exists(json, 'dataset_id') ? undefined : json['dataset_id'],
        'entries': ((json['entries'] as Array<any>).map(EvaluationBacktestEntryPayloadFromJSON)),
        'investigation_plan_type': json['investigation_plan_type'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function AIStaffCreateEvaluationBacktestRequestBodyToJSON(value?: AIStaffCreateEvaluationBacktestRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credit': value.credit,
        'dataset_id': value.dataset_id,
        'entries': ((value.entries as Array<any>).map(EvaluationBacktestEntryPayloadToJSON)),
        'investigation_plan_type': value.investigation_plan_type,
        'notes': value.notes,
    };
}

