/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody,
    IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBodyFromJSON,
    IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBodyToJSON,
} from '../models';

export interface IntegrationsMicrosoftTeamsOnlineMeetingsHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsMicrosoftTeamsOnlineMeetingsHandleRedirectRequest {
    code?: string;
    state?: string;
    sessionState?: string;
    error?: string;
    errorDescription?: string;
    adminConsent?: boolean;
    tenant?: string;
}

/**
 * 
 */
export class IntegrationsMicrosoftTeamsOnlineMeetingsApi extends runtime.BaseAPI {

    /**
     * Get Microsoft Teams online meetings configuration for the organisation
     * GetConfig Integrations - Microsoft Teams online meetings
     */
    async integrationsMicrosoftTeamsOnlineMeetingsGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/microsoft_teams_online_meetings/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Microsoft Teams online meetings configuration for the organisation
     * GetConfig Integrations - Microsoft Teams online meetings
     */
    async integrationsMicrosoftTeamsOnlineMeetingsGetConfig(initOverrides?: RequestInit): Promise<IntegrationsMicrosoftTeamsOnlineMeetingsGetConfigResponseBody> {
        const response = await this.integrationsMicrosoftTeamsOnlineMeetingsGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Redirects user to Microsoft for installation of the Microsoft Teams online meetings integration
     * HandleInstall Integrations - Microsoft Teams online meetings
     */
    async integrationsMicrosoftTeamsOnlineMeetingsHandleInstallRaw(requestParameters: IntegrationsMicrosoftTeamsOnlineMeetingsHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsMicrosoftTeamsOnlineMeetingsHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/microsoft_teams_online_meetings_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirects user to Microsoft for installation of the Microsoft Teams online meetings integration
     * HandleInstall Integrations - Microsoft Teams online meetings
     */
    async integrationsMicrosoftTeamsOnlineMeetingsHandleInstall(requestParameters: IntegrationsMicrosoftTeamsOnlineMeetingsHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftTeamsOnlineMeetingsHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Microsoft redirect after successful authentication
     * HandleRedirect Integrations - Microsoft Teams online meetings
     */
    async integrationsMicrosoftTeamsOnlineMeetingsHandleRedirectRaw(requestParameters: IntegrationsMicrosoftTeamsOnlineMeetingsHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.sessionState !== undefined) {
            queryParameters['session_state'] = requestParameters.sessionState;
        }

        if (requestParameters.error !== undefined) {
            queryParameters['error'] = requestParameters.error;
        }

        if (requestParameters.errorDescription !== undefined) {
            queryParameters['error_description'] = requestParameters.errorDescription;
        }

        if (requestParameters.adminConsent !== undefined) {
            queryParameters['admin_consent'] = requestParameters.adminConsent;
        }

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft_teams_online_meetings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Microsoft redirect after successful authentication
     * HandleRedirect Integrations - Microsoft Teams online meetings
     */
    async integrationsMicrosoftTeamsOnlineMeetingsHandleRedirect(requestParameters: IntegrationsMicrosoftTeamsOnlineMeetingsHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftTeamsOnlineMeetingsHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove the Microsoft Teams online meetings integration
     * HandleUninstall Integrations - Microsoft Teams online meetings
     */
    async integrationsMicrosoftTeamsOnlineMeetingsHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/microsoft_teams_online_meetings_uninstall`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove the Microsoft Teams online meetings integration
     * HandleUninstall Integrations - Microsoft Teams online meetings
     */
    async integrationsMicrosoftTeamsOnlineMeetingsHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftTeamsOnlineMeetingsHandleUninstallRaw(initOverrides);
    }

}
