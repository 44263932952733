/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffRunPromptHealthReportRequestBody
 */
export interface AIStaffRunPromptHealthReportRequestBody {
    /**
     * Filter the report to only include prompts with these names
     * @type {Array<string>}
     * @memberof AIStaffRunPromptHealthReportRequestBody
     */
    filter_prompt_names?: Array<string>;
    /**
     * End of the period the report covers
     * @type {Date}
     * @memberof AIStaffRunPromptHealthReportRequestBody
     */
    period_end: Date;
    /**
     * Start of the period the report covers
     * @type {Date}
     * @memberof AIStaffRunPromptHealthReportRequestBody
     */
    period_start: Date;
}

export function AIStaffRunPromptHealthReportRequestBodyFromJSON(json: any): AIStaffRunPromptHealthReportRequestBody {
    return AIStaffRunPromptHealthReportRequestBodyFromJSONTyped(json, false);
}

export function AIStaffRunPromptHealthReportRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffRunPromptHealthReportRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filter_prompt_names': !exists(json, 'filter_prompt_names') ? undefined : json['filter_prompt_names'],
        'period_end': (new Date(json['period_end'])),
        'period_start': (new Date(json['period_start'])),
    };
}

export function AIStaffRunPromptHealthReportRequestBodyToJSON(value?: AIStaffRunPromptHealthReportRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter_prompt_names': value.filter_prompt_names,
        'period_end': (value.period_end.toISOString()),
        'period_start': (value.period_start.toISOString()),
    };
}

