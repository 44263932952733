/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OverviewWorkloadDatapoint,
    OverviewWorkloadDatapointFromJSON,
    OverviewWorkloadDatapointFromJSONTyped,
    OverviewWorkloadDatapointToJSON,
} from './OverviewWorkloadDatapoint';

/**
 * 
 * @export
 * @interface OverviewWorkloadTable
 */
export interface OverviewWorkloadTable {
    /**
     * 
     * @type {Array<OverviewWorkloadDatapoint>}
     * @memberof OverviewWorkloadTable
     */
    datapoints: Array<OverviewWorkloadDatapoint>;
}

export function OverviewWorkloadTableFromJSON(json: any): OverviewWorkloadTable {
    return OverviewWorkloadTableFromJSONTyped(json, false);
}

export function OverviewWorkloadTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewWorkloadTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoints': ((json['datapoints'] as Array<any>).map(OverviewWorkloadDatapointFromJSON)),
    };
}

export function OverviewWorkloadTableToJSON(value?: OverviewWorkloadTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoints': ((value.datapoints as Array<any>).map(OverviewWorkloadDatapointToJSON)),
    };
}

