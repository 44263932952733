/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathPayload,
    EscalationPathPayloadFromJSON,
    EscalationPathPayloadFromJSONTyped,
    EscalationPathPayloadToJSON,
} from './EscalationPathPayload';

/**
 * 
 * @export
 * @interface EscalationPathsGenerateExportRequestBody
 */
export interface EscalationPathsGenerateExportRequestBody {
    /**
     * 
     * @type {EscalationPathPayload}
     * @memberof EscalationPathsGenerateExportRequestBody
     */
    escalation_path: EscalationPathPayload;
    /**
     * The ID of the escalation path to generate Terraform for (if it already exists)
     * @type {string}
     * @memberof EscalationPathsGenerateExportRequestBody
     */
    id?: string;
    /**
     * The mode to generate Terraform in
     * @type {string}
     * @memberof EscalationPathsGenerateExportRequestBody
     */
    mode: EscalationPathsGenerateExportRequestBodyModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathsGenerateExportRequestBodyModeEnum {
    Terraform = 'terraform'
}

export function EscalationPathsGenerateExportRequestBodyFromJSON(json: any): EscalationPathsGenerateExportRequestBody {
    return EscalationPathsGenerateExportRequestBodyFromJSONTyped(json, false);
}

export function EscalationPathsGenerateExportRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathsGenerateExportRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_path': EscalationPathPayloadFromJSON(json['escalation_path']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mode': json['mode'],
    };
}

export function EscalationPathsGenerateExportRequestBodyToJSON(value?: EscalationPathsGenerateExportRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_path': EscalationPathPayloadToJSON(value.escalation_path),
        'id': value.id,
        'mode': value.mode,
    };
}

