import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { UpgradeRequiredProps } from "@incident-shared/gates/gates";
import { ButtonTheme, IconEnum, Loader } from "@incident-ui";
import React, { useMemo } from "react";
import graphic from "src/components/settings/banners/banner-enterprise-grid.svg";
import { SlackInfoUserSlackRoleEnum as SlackRoleEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { SlackEnterpriseGridViewSection } from "./SlackEnterpriseGridViewSection";
import { SlackEnterpriseGridWorkspaceViewSection } from "./SlackEnterpriseGridWorkspaceViewSection";
import { SlackWorkspaceViewSection } from "./SlackWorkspaceViewSection";

export const SlackEnterpriseGridViewPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const hasSlackEnterpriseGridInstallFeatureGate =
    identity.feature_gates?.slack_enterprise_grid_install === true;

  const { data: enterpriseSettingsData, isLoading } = useAPI(
    "settingsGetSlackEnterpriseSettings",
    undefined,
  );

  const slackEnterpriseSettings =
    enterpriseSettingsData?.slack_enterprise_settings;

  const userSlackRole = identity?.slack_info?.user_slack_role;
  const userIsSlackAdminOrOwner =
    userSlackRole &&
    [SlackRoleEnum.Admin, SlackRoleEnum.Owner].includes(userSlackRole);

  let unableToInstallEnterpriseExplanation: string | undefined = undefined;
  const hasSlackEnterpriseInstalled =
    slackEnterpriseSettings?.is_slack_enterprise_installed;
  const hasSlackEnterpriseID =
    slackEnterpriseSettings?.slack_enterprise_id !== "";
  const hasAddSlackEnterpriseURL =
    !!identity.slack_info?.enterprise_install_url;

  if (!hasSlackEnterpriseID) {
    unableToInstallEnterpriseExplanation =
      "Your Slack workspace does not belong to a Slack Enterprise Grid";
  } else if (!userIsSlackAdminOrOwner) {
    unableToInstallEnterpriseExplanation =
      "Only a Slack Admin or Owner can install incident.io into Slack Enterprise Grid";
  } else if (!hasAddSlackEnterpriseURL) {
    unableToInstallEnterpriseExplanation =
      "To install incident.io into your Slack Enterprise Grid, please arrange a call with your Customer Success Manager";
  }

  const isAddButtonDisabled = !!unableToInstallEnterpriseExplanation;

  const commonUpgradeRequiredProps: UpgradeRequiredProps = useMemo(() => {
    return {
      gate: { type: "boolean" },
      featureName: "Slack Enterprise Grid",
    };
  }, []);

  if (!slackEnterpriseSettings || isLoading) {
    return <Loader />;
  }

  return (
    <SettingsSubPageWrapper
      accessory={
        // If we have a Slack Enterprise Grid install, we don't need to show the button.
        hasSlackEnterpriseInstalled ? null : (
          <GatedButton
            onClick={() => {
              if (identity.slack_info?.enterprise_install_url) {
                window.location.href =
                  identity.slack_info.enterprise_install_url;
              }
            }}
            theme={ButtonTheme.Primary}
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            analyticsTrackingId="install-into-slack-enterprise-grid"
            icon={IconEnum.Slack}
            disabled={isAddButtonDisabled}
            disabledTooltipContent={unableToInstallEnterpriseExplanation}
            upgradeRequired={!hasSlackEnterpriseGridInstallFeatureGate}
            upgradeRequiredProps={commonUpgradeRequiredProps}
          >
            Install into Slack Enterprise Grid
          </GatedButton>
        )
      }
    >
      <SettingsHeading
        title="Reduce workspace complexity"
        graphic={<img src={graphic} className="h-40" />}
        subtitle={`Connect to Slack Enterprise Grid to leverage a single account shared across all your Slack workspaces.`}
      />
      {slackEnterpriseSettings.is_slack_enterprise_installed ? (
        // This is a Grid install, show settings
        <>
          <SlackEnterpriseGridViewSection settings={slackEnterpriseSettings} />
          <SlackEnterpriseGridWorkspaceViewSection
            settings={slackEnterpriseSettings}
          />
        </>
      ) : (
        // Slack workspace is part of a Grid, but they have not Grid installed with us
        <SlackWorkspaceViewSection />
      )}
    </SettingsSubPageWrapper>
  );
};
