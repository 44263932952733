import {
  CatalogRelation,
  Condition,
  ConditionPayload,
  EscalationPath,
  EscalationPathNode,
  EscalationPathNodeLevel,
  EscalationPathNodeLevelTimeToAckIntervalConditionEnum,
  EscalationPathNodeNotifyChannel,
  EscalationPathNodeNotifyChannelTimeToAckIntervalConditionEnum,
  EscalationPathNodePayload,
  EscalationPathNodePayloadTypeEnum,
  EscalationPathNodeTypeEnum as NodeTypes,
  EscalationPathsCreateRequest,
  EscalationPathsCreateRequestBody,
  EscalationPathsUpdateRequest,
  EscalationPathTargetScheduleModeEnum,
  EscalationPathTargetTypeEnum,
  EscalationPathTargetUrgencyEnum,
  Identity,
  UserOptionStateEnum,
  WeekdayInterval,
  WeekdayIntervalConfig,
  WeekdayIntervalWeekdayEnum,
} from "@incident-io/api";
import _, { compact } from "lodash";
import { assertUnreachable } from "src/utils/utils";

import { DONT_REPEAT_VALUE } from "../nodes/NodeRepeat";
import { timeToAckOptions } from "../nodes/TimeToAckForm";
import { getWorkingHoursDefaults } from "./default";
import {
  EscalationPathCatalogBindingData,
  EscalationPathConditionConfig,
  EscalationPathConditionType,
  EscalationPathFormData,
  EscalationPathTargetFormData,
  EscalationPathTargetSelectionMode,
  EscalationPathTimeToAckConfig,
  EscalationPathTimeToAckOption,
  NodeIfElse,
  NodeLevel,
  NodeNotifyChannel,
  PathNode,
} from "./types";

// escalationPathResponseToFormData transforms the escalation path API response to form data,
// with nodes compatible with ReactFlow
export const escalationPathResponseToFormData = (
  response?: EscalationPath,
  targets?: EscalationPathTargetFormData[],
): EscalationPathFormData | undefined => {
  if (!response || !targets) {
    return undefined;
  }

  if (!response.path || response.path?.length === 0) {
    throw new Error(
      "Unreachable: an escalation path should always have at least one node",
    );
  }

  const firstNodeId: string = response.path[0].id;

  return {
    id: response.id,
    name: response.name,
    working_hours: workingHoursResponseToFormData(response.working_hours),
    firstNodeId,
    nodes: responseNodesToPathNodes(response.path ?? [], firstNodeId, targets),
  };
};

// responseNodesToPathNodes transforms the escalation path nodes from the API response to ReactFlow nodes
export const responseNodesToPathNodes = (
  path: EscalationPathNode[],
  firstNodeId: string,
  targets: EscalationPathTargetFormData[],
): Record<string, PathNode> => {
  // We're going to recursively populate this object with the nodes.
  let nodes: Record<string, PathNode> = {};

  // Helper function to create a path node
  const makeNode = (
    node: EscalationPathNode,
    data: PathNode["data"],
  ): PathNode => {
    return {
      id: node.id,
      data: data,
    };
  };

  // Iterate through every node in the path and create a path node for each.
  path.forEach((node, index) => {
    switch (node.type) {
      case NodeTypes.Level:
        if (!node.level) {
          throw new Error(
            "Unreachable: a level node always needs a level property",
          );
        }
        //
        // // If a level node is at the end of the path, it's an invalid path, as it's
        // // not followed by a repeat node.
        // if (path.length <= index + 1) {
        //   throw new Error(
        //     "Unreachable: a level node cannot be the final node in the path",
        //   );
        // }

        const levelTargets =
          targets.length > 0
            ? node.level?.targets.map(
                (target): EscalationPathTargetFormData | null => {
                  const hydratedTarget = targets.find(
                    (t) => t.value === target.id,
                  );
                  if (!hydratedTarget) {
                    return null;
                  }

                  switch (hydratedTarget.type) {
                    case EscalationPathTargetTypeEnum.SlackChannel:
                      return null;
                    case EscalationPathTargetTypeEnum.User:
                      return {
                        ...hydratedTarget,
                        state:
                          hydratedTarget.state as unknown as UserOptionStateEnum,
                        type: EscalationPathTargetTypeEnum.User,
                      };
                    case EscalationPathTargetTypeEnum.Schedule:
                      return {
                        ...hydratedTarget,
                        type: EscalationPathTargetTypeEnum.Schedule,
                        schedule_mode: target.schedule_mode,
                        selected_rota_id: target.selected_rota_id,
                      };
                    default:
                      return null;
                  }
                },
              )
            : [];

        const urgency = node.level?.targets[0]?.urgency;
        nodes[node.id] = makeNode(node, {
          type: NodeTypes.Level,
          nextNodeId: path[index + 1]?.id,
          level: {
            targets: _.compact(levelTargets) || [],
            targetSelectionMode: node.level?.round_robin_config?.enabled
              ? EscalationPathTargetSelectionMode.RoundRobin
              : EscalationPathTargetSelectionMode.AllAtOnce,
            targetRotateAfterMinutes: node.level?.round_robin_config
              ?.rotate_after_seconds
              ? node.level.round_robin_config.rotate_after_seconds / 60
              : undefined,
            urgency: urgency ? urgency : EscalationPathTargetUrgencyEnum.High,
            ...escalationPathLevelToTimeToAckConfig(node.level),
          },
        });
        break;
      case NodeTypes.NotifyChannel:
        if (!node.notify_channel) {
          throw new Error(
            "Unreachable: a notify_channel node always needs a notify_channel property",
          );
        }

        const channelTargets =
          targets.length > 0
            ? node.notify_channel?.targets.map(
                (target): EscalationPathTargetFormData | null => {
                  const hydratedTarget = targets.find(
                    (t) => t.value === target.id,
                  );
                  if (
                    !hydratedTarget ||
                    hydratedTarget.type !==
                      EscalationPathTargetTypeEnum.SlackChannel
                  ) {
                    return null;
                  }

                  return {
                    ...hydratedTarget,
                    type: target.type as EscalationPathTargetTypeEnum.SlackChannel,
                  };
                },
              )
            : [];

        nodes[node.id] = makeNode(node, {
          type: NodeTypes.NotifyChannel,
          nextNodeId: path[index + 1]?.id,
          notify_channel: {
            targets: _.compact(channelTargets) || [],
            ...escalationPathNotifyChannelToTimeToAckConfig(
              node.notify_channel,
            ),
          },
        });
        break;
      case NodeTypes.Repeat:
        if (!node.repeat) {
          throw new Error(
            "Unreachable: a repeat node always needs a repeat property",
          );
        }

        const d = {
          type: NodeTypes.Repeat as const,
          repeat: {
            repeat_times: String(node.repeat.repeat_times || 3),
            to_node: node.repeat.to_node || firstNodeId,
          },
        };

        // If the repeat times is 0, we don't want to repeat at all.
        if (node.repeat.repeat_times === 0) {
          d.repeat.repeat_times = String(0);
          d.repeat.to_node = DONT_REPEAT_VALUE;
        }

        nodes[node.id] = makeNode(node, d);
        break;

      case NodeTypes.IfElse:
        if (!node.if_else) {
          throw new Error(
            "Unreachable: a condition node always needs an if_else property",
          );
        }

        // First add the condition node
        nodes[node.id] = makeNode(node, {
          type: NodeTypes.IfElse,
          if_else: {
            thenNodeId: node.if_else.then_path[0]?.id,
            elseNodeId: node.if_else.else_path[0]?.id,
            ...conditionsToFormData(node.if_else.conditions),
          },
        });

        // Then recursively add the if/else branches.
        nodes = {
          ...nodes,
          ...responseNodesToPathNodes(
            node.if_else.then_path,
            firstNodeId,
            targets,
          ),
          ...responseNodesToPathNodes(
            node.if_else.else_path,
            firstNodeId,
            targets,
          ),
        };
        break;
    }
  });

  return nodes;
};

const conditionsToFormData = (
  conditions: Condition[],
): EscalationPathConditionConfig => {
  // We only allow one condition so just grab the first one
  const condition = conditions[0];

  if (condition.subject.reference.includes("priority")) {
    return {
      conditionType: EscalationPathConditionType.Priority,
      priorityIds: compact(
        condition.param_bindings[0].array_value?.map((value) => value.literal),
      ),
    };
  } else if (condition.subject.reference.includes("working_hours")) {
    return {
      conditionType: EscalationPathConditionType.WorkingHoursActive,
    };
  }

  throw new Error("Unreachable: unknown condition type");
};

const conditionsToPayload = (
  workingHoursConfigId: string,
  conditionConfig: NodeIfElse,
): ConditionPayload[] => {
  if (conditionConfig.conditionType === EscalationPathConditionType.Priority) {
    if (!conditionConfig.priorityIds || !conditionConfig.priorityIds.length) {
      throw new Error(
        "Unreachable: expected at least one priority ID to be set",
      );
    }
    return [
      {
        subject: "escalation.priority",
        operation: "one_of",

        param_bindings: [
          {
            array_value: conditionConfig.priorityIds.map((id) => ({
              literal: id,
            })),
          },
        ],
      },
    ];
  } else if (
    conditionConfig.conditionType ===
    EscalationPathConditionType.WorkingHoursActive
  ) {
    return [
      {
        subject: `escalation.working_hours["${workingHoursConfigId}"]`,
        operation: "is_active",
        param_bindings: [],
      },
    ];
  }

  throw new Error("Unreachable: unknown condition type");
};

const workingHoursResponseToFormData = (
  response?: Array<WeekdayIntervalConfig>,
): EscalationPathFormData["working_hours"] | undefined => {
  // if they've never set working hours, we want to set them to our default working hours
  // values so that they're in scope for any new conditions
  if (
    !response ||
    response.length <= 0 ||
    response[0].weekday_intervals.length <= 0
  ) {
    return getWorkingHoursDefaults();
  }

  const config = response[0];
  const weekdays: { [key: string]: boolean } = {};

  config.weekday_intervals.forEach((interval) => {
    weekdays[interval.weekday] = true;
  });

  return {
    config_id: config.id,
    weekdays,
    start_time: config.weekday_intervals[0].start_time,
    end_time: config.weekday_intervals[0].end_time,
    timezone: config.timezone,
  };
};

// escalationPathFormDataToCreatePayload marshals the form data to the API request payload for
// creating an escalation path.
export const escalationPathFormDataToCreatePayload = (
  formData: EscalationPathFormData,
  identity: Identity,
  usersToPromote: string[],
): EscalationPathsCreateRequest => {
  return {
    createRequestBody: {
      name: formData.name,
      path: escalationPathFormDataNodesToPath(formData, formData.firstNodeId),
      working_hours: escalationPathWorkingHoursToPayload(
        formData.working_hours,
      ),
      user_ids_to_promote: usersToPromote,

      // TODO: Below are hardcoded values for the v1 data structure, they should be removed when
      // these fields are no longer required.
      ...v1EscalationPathProperties({ identity }),
    },
  };
};

// escalationPathFormDataToUpdatePayload marshals the form data to the API request payload for
// updating an escalation path.
export const escalationPathFormDataToUpdatePayload = (
  id: string,
  formData: EscalationPathFormData,
  identity: Identity,
  usersToPromote: string[],
): EscalationPathsUpdateRequest => {
  return {
    updateRequestBody: {
      id: id,
      name: formData.name,
      path: escalationPathFormDataNodesToPath(formData, formData.firstNodeId),
      working_hours: escalationPathWorkingHoursToPayload(
        formData.working_hours,
      ),
      user_ids_to_promote: usersToPromote,

      // TODO: Below are hardcoded values for the v1 data structure, they should be removed when
      // these fields are no longer required.
      ...v1EscalationPathProperties({ identity }),
    },
  };
};

// escalationPathFormDataNodesToPath marshals the form data nodes to the API request payload for
// creating or updating an escalation path.
export const escalationPathFormDataNodesToPath = (
  formData: Pick<
    EscalationPathFormData,
    "nodes" | "firstNodeId" | "working_hours"
  >,
  startingNode: string,
): EscalationPathNodePayload[] => {
  // What we will be returning, and using in the API payload.
  const path = [] as EscalationPathNodePayload[];
  // We apply the default here: if no working hours are set, the backend will return an error for this!
  const workingHoursConfigId = formData.working_hours?.config_id ?? "default";

  // Recursive function to handle each node in the form data.
  const handleNode = (nodeId: string) => {
    const node = formData.nodes[nodeId];

    switch (node.data.type) {
      case NodeTypes.Level:
        if (!node.data.level) {
          throw new Error(
            "Unreachable: a level node always needs a level property",
          );
        }

        const urgency = node.data.level.urgency;

        path.push({
          id: node.id,
          type: EscalationPathNodePayloadTypeEnum.Level,
          level: {
            ...timeToAckConfigToLevelPayload(
              formData.working_hours?.config_id,
              node.data.level,
            ),
            round_robin_config:
              node.data.level.targetSelectionMode ===
              EscalationPathTargetSelectionMode.RoundRobin
                ? {
                    enabled: true,
                    rotate_after_seconds: node.data.level
                      .targetRotateAfterMinutes
                      ? node.data.level.targetRotateAfterMinutes * 60
                      : undefined,
                  }
                : undefined,
            targets: node.data.level.targets.map((target) =>
              escalationPathFormDataTargetsToPayload(urgency, target),
            ),
          },
        });
        if (node.data.nextNodeId) {
          handleNode(node.data.nextNodeId);
        }

        break;
      case NodeTypes.NotifyChannel:
        if (!node.data.notify_channel) {
          throw new Error(
            "Unreachable: a notify_channel node always needs a notify_channel property",
          );
        }

        path.push({
          id: node.id,
          type: EscalationPathNodePayloadTypeEnum.NotifyChannel,
          notify_channel: {
            ...timeToAckConfigToNotifyChannelPayload(
              formData.working_hours?.config_id,
              node.data.notify_channel,
            ),
            targets: node.data.notify_channel.targets.map((target) => ({
              id: target.value,
              type: EscalationPathTargetTypeEnum.SlackChannel,
              urgency: EscalationPathTargetUrgencyEnum.High, // We hardcode this because it has no impact.
            })),
          },
        });

        if (node.data.nextNodeId) {
          handleNode(node.data.nextNodeId);
        }

        break;
      case NodeTypes.Repeat:
        if (!node.data.repeat) {
          throw new Error(
            "Unreachable: a repeat node always needs a repeat property",
          );
        }

        const doesRepeat = node.data.repeat.to_node !== DONT_REPEAT_VALUE;

        path.push({
          id: node.id,
          type: EscalationPathNodePayloadTypeEnum.Repeat,
          repeat: {
            repeat_times: doesRepeat
              ? Number(node.data.repeat.repeat_times)
              : 0,
            to_node: doesRepeat
              ? node.data.repeat.to_node
              : formData.nodes[formData.firstNodeId].id,
          },
        });

        break;
      case NodeTypes.IfElse:
        if (!node.data.if_else) {
          throw new Error(
            "Unreachable: an ifElse node always needs a ifElse property",
          );
        }

        // If either of these is empty, translate to an empty sub-path
        const thenPath = node.data.if_else.thenNodeId
          ? escalationPathFormDataNodesToPath(
              formData,
              node.data.if_else.thenNodeId,
            )
          : [];
        const elsePath = node.data.if_else.elseNodeId
          ? escalationPathFormDataNodesToPath(
              formData,
              node.data.if_else.elseNodeId,
            )
          : [];

        path.push({
          id: node.id,
          type: EscalationPathNodePayloadTypeEnum.IfElse,
          if_else: {
            conditions: conditionsToPayload(
              workingHoursConfigId,
              node.data.if_else,
            ),
            then_path: thenPath,
            else_path: elsePath,
          },
        });
        break;

      default:
        return null;
    }

    return null;
  };

  handleNode(startingNode);

  return compact(path);
};

export const escalationPathFormDataTargetsToPayload = (
  urgency: EscalationPathTargetUrgencyEnum,
  target: EscalationPathTargetFormData,
) => ({
  id: target.value,
  type: target.type as unknown as EscalationPathTargetTypeEnum,
  urgency,
  schedule_mode:
    target.schedule_mode as unknown as EscalationPathTargetScheduleModeEnum,
  selected_rota_id: target.selected_rota_id,
});

export const escalationPathWorkingHoursToPayload = (
  workingHours: EscalationPathFormData["working_hours"],
): EscalationPathsCreateRequestBody["working_hours"] => {
  if (!workingHours) return [];

  // If no weekdays are selected we don't have working hours.
  if (
    !workingHours.weekdays ||
    Object.values(workingHours.weekdays).every((enabled) => !enabled)
  ) {
    return [];
  }

  const weekdayIntervals: WeekdayInterval[] = [];

  Object.entries(workingHours.weekdays).forEach(([weekday, enabled]) => {
    if (!enabled) return;

    weekdayIntervals.push({
      weekday: weekday as WeekdayIntervalWeekdayEnum,
      start_time: workingHours.start_time,
      end_time: workingHours.end_time,
    });
  });

  return [
    {
      id: workingHours.config_id,
      name: "Working Hours",
      timezone: workingHours.timezone,
      weekday_intervals: weekdayIntervals,
    },
  ];
};

export const timeToAckConfigToLevelPayload = (
  workingHoursConfigId: string | undefined,
  level: NodeLevel,
): {
  time_to_ack_seconds?: number;
  time_to_ack_weekday_interval_config_id?: string;
  time_to_ack_interval_condition?: EscalationPathNodeLevelTimeToAckIntervalConditionEnum;
} => {
  if (
    level.time_to_ack_option ===
    EscalationPathTimeToAckOption.WorkingHoursActive
  ) {
    return {
      time_to_ack_weekday_interval_config_id: workingHoursConfigId,
      time_to_ack_interval_condition:
        EscalationPathNodeLevelTimeToAckIntervalConditionEnum.Active,
    };
  }

  if (
    level.time_to_ack_option ===
    EscalationPathTimeToAckOption.WorkingHoursInactive
  ) {
    return {
      time_to_ack_weekday_interval_config_id: workingHoursConfigId,
      time_to_ack_interval_condition:
        EscalationPathNodeLevelTimeToAckIntervalConditionEnum.Inactive,
    };
  }

  if (
    level.time_to_ack_option === EscalationPathTimeToAckOption.MinutesCustom
  ) {
    if (level.time_to_ack_custom_minutes === undefined) {
      throw new Error(
        "Unreachable: expected custom time to ack minutes to be set",
      );
    }
    return {
      time_to_ack_seconds: level.time_to_ack_custom_minutes * 60,
    };
  }

  const timetoAckMinutes =
    timeToAckOptions[level.time_to_ack_option].numMinutes;
  return {
    time_to_ack_seconds: timetoAckMinutes ? timetoAckMinutes * 60 : undefined,
  };
};

const timeToAckConfigToNotifyChannelPayload = (
  workingHoursConfigId: string | undefined,
  notifyChannel: NodeNotifyChannel,
): {
  time_to_ack_seconds?: number;
  time_to_ack_weekday_interval_config_id?: string;
  time_to_ack_interval_condition?: EscalationPathNodeNotifyChannelTimeToAckIntervalConditionEnum;
} => {
  switch (notifyChannel.time_to_ack_option) {
    case EscalationPathTimeToAckOption.WorkingHoursActive:
      return {
        time_to_ack_weekday_interval_config_id: workingHoursConfigId,
        time_to_ack_interval_condition:
          EscalationPathNodeNotifyChannelTimeToAckIntervalConditionEnum.Active,
      };
    case EscalationPathTimeToAckOption.WorkingHoursInactive:
      return {
        time_to_ack_weekday_interval_config_id: workingHoursConfigId,
        time_to_ack_interval_condition:
          EscalationPathNodeNotifyChannelTimeToAckIntervalConditionEnum.Inactive,
      };
    case EscalationPathTimeToAckOption.MinutesCustom:
      if (!notifyChannel.time_to_ack_custom_minutes) {
        throw new Error(
          "Unreachable: expected custom time to ack minutes to be set",
        );
      }
      return {
        time_to_ack_seconds: notifyChannel.time_to_ack_custom_minutes * 60,
      };
    case EscalationPathTimeToAckOption.MinutesFifteen:
      return {
        time_to_ack_seconds: 15 * 60,
      };
    case EscalationPathTimeToAckOption.MinutesTen:
      return {
        time_to_ack_seconds: 10 * 60,
      };
    case EscalationPathTimeToAckOption.MinutesFive:
      return {
        time_to_ack_seconds: 5 * 60,
      };
    case EscalationPathTimeToAckOption.MinutesZero:
      return {
        time_to_ack_seconds: 0,
      };
    default:
      assertUnreachable(notifyChannel.time_to_ack_option);
      return {
        time_to_ack_seconds: undefined,
      };
  }
};

export const escalationPathLevelToTimeToAckConfig = (
  level?: EscalationPathNodeLevel,
): EscalationPathTimeToAckConfig => {
  if (!level) {
    return {
      time_to_ack_option: EscalationPathTimeToAckOption.MinutesFive,
    };
  }

  if (
    level.time_to_ack_seconds === undefined &&
    level.time_to_ack_weekday_interval_config_id === undefined
  ) {
    return {
      time_to_ack_option: EscalationPathTimeToAckOption.MinutesFive,
    };
  }

  if (level.time_to_ack_seconds !== undefined) {
    if (level.time_to_ack_seconds === 5 * 60) {
      return {
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesFive,
      };
    }

    if (level.time_to_ack_seconds === 10 * 60) {
      return {
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesTen,
      };
    }

    if (level.time_to_ack_seconds === 15 * 60) {
      return {
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesFifteen,
      };
    }

    return {
      time_to_ack_option: EscalationPathTimeToAckOption.MinutesCustom,
      time_to_ack_custom_minutes: level.time_to_ack_seconds / 60,
    };
  }

  if (!level.time_to_ack_interval_condition) {
    throw new Error("Unreachable: expected interval condition to be set");
  }

  return {
    time_to_ack_option:
      level.time_to_ack_interval_condition ===
      EscalationPathNodeLevelTimeToAckIntervalConditionEnum.Active
        ? EscalationPathTimeToAckOption.WorkingHoursActive
        : EscalationPathTimeToAckOption.WorkingHoursInactive,
  };
};

export const escalationPathNotifyChannelToTimeToAckConfig = (
  notifyChannel?: EscalationPathNodeNotifyChannel,
): EscalationPathTimeToAckConfig => {
  if (!notifyChannel) {
    return {
      time_to_ack_option: EscalationPathTimeToAckOption.MinutesFive,
    };
  }

  // We check for undefined here rather than truthy because TypeScript reads 0 as falsely,
  // but we want to allow 0-time to ack for Slack channel targets.
  if (
    notifyChannel.time_to_ack_seconds === undefined &&
    !notifyChannel.time_to_ack_weekday_interval_config_id
  ) {
    return {
      time_to_ack_option: EscalationPathTimeToAckOption.MinutesFive,
    };
  }

  if (notifyChannel.time_to_ack_seconds !== undefined) {
    if (notifyChannel.time_to_ack_seconds === 0) {
      return {
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesZero,
      };
    }

    if (notifyChannel.time_to_ack_seconds === 5 * 60) {
      return {
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesFive,
      };
    }

    if (notifyChannel.time_to_ack_seconds === 10 * 60) {
      return {
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesTen,
      };
    }

    if (notifyChannel.time_to_ack_seconds === 15 * 60) {
      return {
        time_to_ack_option: EscalationPathTimeToAckOption.MinutesFifteen,
      };
    }

    return {
      time_to_ack_option: EscalationPathTimeToAckOption.MinutesCustom,
      time_to_ack_custom_minutes: notifyChannel.time_to_ack_seconds / 60,
    };
  }

  if (!notifyChannel.time_to_ack_interval_condition) {
    throw new Error("Unreachable: expected interval condition to be set");
  }

  return {
    time_to_ack_option:
      notifyChannel.time_to_ack_interval_condition ===
      EscalationPathNodeNotifyChannelTimeToAckIntervalConditionEnum.Active
        ? EscalationPathTimeToAckOption.WorkingHoursActive
        : EscalationPathTimeToAckOption.WorkingHoursInactive,
  };
};

export const escalationPathCatalogEntriesToFormData = (
  catalogRelations?: CatalogRelation[],
): EscalationPathCatalogBindingData => {
  const m: EscalationPathCatalogBindingData = {};
  if (!catalogRelations) {
    return m;
  }
  catalogRelations.forEach((relation) => {
    const catalogTypeId = relation.catalog_type.id;
    const escalationPathAttribute =
      relation.catalog_type.schema.attributes.find(
        (attribute) => attribute.type === "EscalationPath",
      );
    if (!escalationPathAttribute) return;
    const relevantAttribute = relation.attributes.find(
      (attr) => attr.attribute === escalationPathAttribute.id,
    );
    if (!relevantAttribute) return;
    m[catalogTypeId] = relevantAttribute.entries.map(
      (attr) => attr.catalog_entry_id,
    );
  });
  return m;
};

// TODO: Remove when v1 data structure is no longer required.
const v1EscalationPathProperties = ({ identity }: { identity: Identity }) => {
  return {
    levels: [
      {
        delay_seconds: 60,
        targets: [
          {
            id: identity.user_id,
            type: EscalationPathTargetTypeEnum.User,
            urgency: EscalationPathTargetUrgencyEnum.High,
          },
        ],
      },
    ],
    repeat_times: Number(3),
  };
};
