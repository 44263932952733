import "lodash";

import {
  Editor,
  EditorProvider,
  useEditorView,
} from "@aeaton/react-prosemirror";
import { ErrorBoundary } from "@sentry/react";
import { EditorState } from "prosemirror-state";
import { useMemo } from "react";
import React from "react";
import { TextNode } from "src/contexts/ClientContext";
import { EnrichedScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import { parseLinks } from "./parseLinks";
import { plugins, schema } from "./schema";
import styles from "./TemplatedTextDisplay.module.scss";
import { InterpolatedRef, plaintextDoc } from "./TemplatedTextEditor";
import { replaceLabels } from "./variable";

export enum TemplatedTextDisplayStyle {
  Full = "full",
  Compact = "compact",
  Naked = "naked",
}

type Props = {
  variableScope?: EnrichedScope<InterpolatedRef>;
  style?: TemplatedTextDisplayStyle;
  className?: string;
  truncatedText?: boolean;
};

export const TemplatedTextDisplay = React.forwardRef(
  (
    props: Props & { value: TextNode | string },
    ref?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <ErrorBoundary>
        <TemplatedTextDisplayL1 {...props} ref={ref} />
      </ErrorBoundary>
    );
  },
);

const TemplatedTextDisplayL1 = React.forwardRef(
  (
    {
      value,
      variableScope = undefined,
      style = TemplatedTextDisplayStyle.Full,
      className,
      truncatedText = false,
    }: Props & { value: TextNode | string },
    ref?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const valueNode = useMemo(() => {
      if (typeof value === "string") {
        if (value !== "") {
          return JSON.parse(value) as TextNode;
        } else {
          return plaintextDoc("");
        }
      } else {
        const hasEmptyNode = value.content?.some(
          (node) => node.content?.some((textNode) => textNode.text === ""),
        );
        if (hasEmptyNode) {
          return null;
        }
        return value;
      }
    }, [value]);

    return (
      valueNode && (
        <TemplatedTextDisplayL2
          {...{
            value: valueNode,
            variableScope,
            style,
            className,
            truncatedText,
          }}
          ref={ref}
        />
      )
    );
  },
);

const TemplatedTextDisplayL2 = React.forwardRef(
  (
    {
      value,
      variableScope = undefined,
      style = TemplatedTextDisplayStyle.Full,
      className,
      truncatedText = false,
    }: Props & { value: TextNode },
    ref?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const doc = useMemo(() => {
      let newDoc = schema.nodeFromJSON(value);
      if (variableScope) {
        // make sure any serialised var labels are correct;
        newDoc = replaceLabels(newDoc, variableScope);
      }
      return parseLinks(newDoc);
    }, [value, variableScope]);

    // Prosemirror doesn't rerender on prop changes by default as the element is
    // outside the react tree, so we manually update the state via replacing the
    // editorstate object. this has to be a component as we need to access the
    // view reference, which can only be accessed by child components of the
    // EditorProvider component, as the EditorProvider is creating a context that
    // holds a reference to the active EditorView
    const UpdateState = (): React.ReactElement => {
      const view = useEditorView();
      const state = EditorState.create({ schema, doc });
      view.updateState(state);
      return <></>;
    };

    return (
      <div
        className={tcx(
          styles.templatedTextContent,
          style === TemplatedTextDisplayStyle.Compact
            ? "[&_p:not(:last-of-type)]:!mb-[8px]"
            : "[&_p:not(:last-of-type)]:!mb-[14px]",
          truncatedText && styles.truncateContent,
          "break-words",
          "leading-6",
          className,
          {
            "rounded-2 border border-stroke px-4 py-2 w-full":
              style === TemplatedTextDisplayStyle.Full,
          },
        )}
        ref={ref}
      >
        {/* @ts-expect-error: EditorProvider is from a 3rd party library which has not been updated to React 18 types, so typescript thinks that it cannot receive children as a prop*/}
        <EditorProvider
          doc={doc}
          plugins={plugins}
          editorProps={{
            editable: (_state) => false, // non-editable view
          }}
        >
          <UpdateState />
          <Editor />
        </EditorProvider>
      </div>
    );
  },
);

TemplatedTextDisplay.displayName = "TemplatedTextDisplay";
TemplatedTextDisplayL1.displayName = "TemplatedTextDisplayL1";
TemplatedTextDisplayL2.displayName = "TemplatedTextDisplayL2";
