import { useAiStaffServiceAiStaffListEvaluationBacktests } from "@incident-io/query-api";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { BacktestCreateModal } from "./BacktestCreateModal";

export const BacktestsListPage = () => {
  const { data, isLoading } = useAiStaffServiceAiStaffListEvaluationBacktests();

  const backtests = data?.backtests ?? [];

  const [showCreateBacktestModal, setShowCreateBacktestModal] = useState(false);

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          analyticsTrackingId={null}
          onClick={() => setShowCreateBacktestModal(true)}
          theme={ButtonTheme.Primary}
        >
          Create backtest
        </Button>
      }
    >
      <LoadingWrapper loading={isLoading}>
        {isEmpty(backtests) ? (
          <EmptyState content="No backtests created yet" />
        ) : (
          <StackedList>
            {backtests.map((backtest) => (
              <StackedListItem
                rowHref={`/workbench/backtests/${backtest.id}`}
                key={backtest.id}
                title={
                  <LocalDateTime
                    timestamp={backtest.created_at}
                    className="font-semibold"
                  />
                }
                badgeNode={
                  <div className="flex items-center gap-2">
                    <Badge theme={BadgeTheme.Secondary}>
                      {backtest.investigation_plan_type}
                    </Badge>
                  </div>
                }
              />
            ))}
          </StackedList>
        )}
      </LoadingWrapper>
      {showCreateBacktestModal && (
        <BacktestCreateModal
          onClose={() => setShowCreateBacktestModal(false)}
        />
      )}
    </WorkbenchSubPageWrapper>
  );
};
