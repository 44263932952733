import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  ButtonTheme,
  Card,
  FullPageEmptyState,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { CatalogWizardCard } from "src/components/legacy/incidents-list/IncidentsListEmptyState";

import { ReactComponent as Graphic } from "./empty-state-orange.svg";

export const AlertsListEmptyState = () => {
  return (
    <FullPageEmptyState
      title="Create incidents and escalations from alerts"
      graphic={
        // Hack to add a little negative margin, otherwise
        // the default height of this can be a little too big for a laptop screen
        // and scrolls awkwardly
        <div className={"-mb-4"}>
          <Graphic />
        </div>
      }
      subtitle="Connect your alerting tools to incident.io and use alert routes to create incidents and escalations from your alerts."
      cta={
        <GatedButton
          analyticsTrackingId="alerts-empty-state-get-started"
          href={"/alerts/routes/create"}
          theme={ButtonTheme.Primary}
        >
          Get started
        </GatedButton>
      }
      helpcenterArticleId={"4007103429"}
      gradientClasses="from-alarmalade-50 to-white from-20% via-80%"
      className="w-full"
      cards={
        <>
          <CatalogWizardCard domain="alerts" />
          <Card
            className="h-full"
            icon={IconEnum.Bolt}
            iconSize={IconSize.Medium}
            title="Connect alert sources"
            description="Connect your external monitoring tools to bring your alerts into incident.io"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.AlertRoute}
            iconSize={IconSize.Medium}
            title="Set up an alert route"
            description="Create incidents and escalations from your alerts inside incident.io"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
        </>
      }
    />
  );
};
