import { Alert, Escalation } from "@incident-io/api";
import {
  EscalationTimelineItem,
  useEscalationsServiceEscalationsTimeline,
} from "@incident-io/query-api";
import { Timeline, TimelineElement } from "@incident-shared/timeline/Timeline";
import { TimelineItem } from "@incident-shared/timeline/TimelineItem";
import { differenceInSeconds } from "date-fns";

import { EscalationTimelineItemDisplayInfo } from "./EscalationTimelineItemDisplayInfo";

export const EscalationTimeline = ({
  escalation,
  alert,
}: {
  escalation: Escalation;
  alert?: Alert;
}) => {
  // Get the timeline
  const { data } = useEscalationsServiceEscalationsTimeline({
    escalationId: escalation.id,
  });

  // We want to show the relative time between every item, since things here
  // often happen within the same minute, so the timestamp itself isn't super
  // useful.
  const itemsWithGaps = (data?.timeline_elements ?? []).map((group) => ({
    ...group,
    items: group.items.flatMap((item, idx) => {
      if (idx === 0) {
        return [item];
      }

      const prev = group.items[idx - 1];
      const timeGap = differenceInSeconds(item.timestamp, prev.timestamp);
      if (timeGap <= 1) {
        return [item];
      }

      const gap = {
        type: "time_gap",
        timeline_gap: {
          duration: timeGap,
        },
      } as TimelineElement<EscalationTimelineItem, "timeline_item">;

      return [gap, item];
    }),
  }));

  return (
    <Timeline<EscalationTimelineItem, "timeline_item">
      items={itemsWithGaps}
      accessorKey={"timeline_item"}
      renderItem={({ item, hideSpacer }) =>
        RenderItem({ item, hideSpacer, alert, escalation })
      }
      minimizedItems={[]}
      supportsMinimizing={false}
      setMinimizedItems={() => []}
    />
  );
};

const RenderItem = ({
  item,
  hideSpacer,
  alert,
  escalation,
}: {
  item: EscalationTimelineItem;
  hideSpacer: boolean;
  escalation: Escalation;
  alert?: Alert;
}) => {
  const displayInfo = EscalationTimelineItemDisplayInfo[item.type];
  const ContentComponent = displayInfo.Component ?? (() => null);
  const title = displayInfo.renderTitle
    ? displayInfo.renderTitle(item)
    : item.title;

  return (
    <TimelineItem
      key={item.id}
      id={item.id}
      title={title}
      timestamp={item.timestamp}
      allowCommenting={false}
      icon={displayInfo.icon}
      color={displayInfo.color}
      hideSpacer={hideSpacer}
      minimized={false}
      setMinimized={(minimized) => minimized}
    >
      <ContentComponent item={item} alert={alert} escalation={escalation} />
    </TimelineItem>
  );
};
