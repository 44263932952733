import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import React from "react";

import emptyStateGraphic from "./empty-2024.svg";

export const InsightsYearInReviewRoute = (): React.ReactElement => {
  return (
    <PageWrapper
      icon={IconEnum.Calendar}
      width={PageWidth.Full}
      title="Year in review"
      overflowY={false}
      overflowX={false}
    >
      <div className="h-[830px] bg-gradient-to-b from-[#f7f7f8] to-white rounded-lg flex-col justify-start items-center gap-60 inline-flex">
        <div className="w-full grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
          <img src={emptyStateGraphic} />
          <div className="flex-col justify-center items-center gap-2 flex">
            <div className="text-center text-[#161618] text-2xl font-semibold font-['Inter'] leading-loose">
              We&rsquo;ll be back next year
            </div>
            <div className="w-[428px] text-center text-[#5f5f68] text-base font-semibold font-['Inter'] leading-normal">
              In the meantime, check out insights to satisfy all your data needs
            </div>
          </div>
          <div className="justify-center items-center gap-2 inline-flex">
            <Button
              theme={ButtonTheme.Primary}
              href="/insights"
              analyticsTrackingId={"wrapped-go-to-dashboard"}
            >
              Insights
            </Button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
