import {
  CallProvidersProvidersEnum,
  IntegrationSettingsProviderEnum,
} from "@incident-io/api";
import { IntegrationListProvider } from "@incident-shared/integrations/IntegrationConfig";
import { useAPI } from "src/utils/swr";

export const integrationProvidersWithScribeSupport: Array<IntegrationListProvider> =
  [
    IntegrationSettingsProviderEnum.GoogleMeet,
    IntegrationSettingsProviderEnum.Zoom,
  ];

export const callProvidersWithScribeSupport: Array<CallProvidersProvidersEnum> =
  [CallProvidersProvidersEnum.GoogleMeet, CallProvidersProvidersEnum.Zoom];

export const useHasCallProviderWithScribeSupport = (): {
  isSupportedCallProviderInstalled: boolean;
  isLoading: boolean;
} => {
  const { data, isLoading } = useAPI(
    "incidentCallSettingsGetCallProviders",
    {},
  );

  return {
    isSupportedCallProviderInstalled:
      data?.available_providers?.providers?.some((provider) =>
        callProvidersWithScribeSupport.includes(provider),
      ) ?? false,
    isLoading,
  };
};
