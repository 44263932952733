/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentAttachmentsCreateRequestBodyResource
 */
export interface IncidentAttachmentsCreateRequestBodyResource {
    /**
     * ID of the resource in the external system
     * @type {string}
     * @memberof IncidentAttachmentsCreateRequestBodyResource
     */
    external_id: string;
    /**
     * E.g. PagerDuty: the external system that holds the resource
     * @type {string}
     * @memberof IncidentAttachmentsCreateRequestBodyResource
     */
    resource_type: IncidentAttachmentsCreateRequestBodyResourceResourceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentAttachmentsCreateRequestBodyResourceResourceTypeEnum {
    PagerDutyIncident = 'pager_duty_incident',
    OpsgenieAlert = 'opsgenie_alert',
    DatadogMonitorAlert = 'datadog_monitor_alert',
    GithubPullRequest = 'github_pull_request',
    GitlabMergeRequest = 'gitlab_merge_request',
    SentryIssue = 'sentry_issue',
    JiraIssue = 'jira_issue',
    AtlassianStatuspageIncident = 'atlassian_statuspage_incident',
    ZendeskTicket = 'zendesk_ticket',
    GoogleCalendarEvent = 'google_calendar_event',
    OutlookCalendarEvent = 'outlook_calendar_event',
    Scrubbed = 'scrubbed',
    StatuspageIncident = 'statuspage_incident'
}

export function IncidentAttachmentsCreateRequestBodyResourceFromJSON(json: any): IncidentAttachmentsCreateRequestBodyResource {
    return IncidentAttachmentsCreateRequestBodyResourceFromJSONTyped(json, false);
}

export function IncidentAttachmentsCreateRequestBodyResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentAttachmentsCreateRequestBodyResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': json['external_id'],
        'resource_type': json['resource_type'],
    };
}

export function IncidentAttachmentsCreateRequestBodyResourceToJSON(value?: IncidentAttachmentsCreateRequestBodyResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'resource_type': value.resource_type,
    };
}

