import { useInsightsServiceInsightsGetLastSync } from "@incident-io/query-api";

export const useInsightsLastSync: () => Date | undefined = () => {
  const { data, isLoading, error } = useInsightsServiceInsightsGetLastSync([], {
    refetchInterval: 30000,
  });

  if (isLoading || error) {
    return undefined;
  }

  return data?.last_sync_at;
};
