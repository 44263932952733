/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverviewWorkloadDatapoint
 */
export interface OverviewWorkloadDatapoint {
    /**
     * 
     * @type {number}
     * @memberof OverviewWorkloadDatapoint
     */
    change: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewWorkloadDatapoint
     */
    contribution_pct: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewWorkloadDatapoint
     */
    hours: number;
    /**
     * 
     * @type {string}
     * @memberof OverviewWorkloadDatapoint
     */
    theme?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewWorkloadDatapoint
     */
    title?: string;
}

export function OverviewWorkloadDatapointFromJSON(json: any): OverviewWorkloadDatapoint {
    return OverviewWorkloadDatapointFromJSONTyped(json, false);
}

export function OverviewWorkloadDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewWorkloadDatapoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'change': json['change'],
        'contribution_pct': json['contribution_pct'],
        'hours': json['hours'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function OverviewWorkloadDatapointToJSON(value?: OverviewWorkloadDatapoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'change': value.change,
        'contribution_pct': value.contribution_pct,
        'hours': value.hours,
        'theme': value.theme,
        'title': value.title,
    };
}

