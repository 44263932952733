/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertAggregatedDatapoint,
    AlertAggregatedDatapointFromJSON,
    AlertAggregatedDatapointFromJSONTyped,
    AlertAggregatedDatapointToJSON,
} from './AlertAggregatedDatapoint';
import {
    Trend,
    TrendFromJSON,
    TrendFromJSONTyped,
    TrendToJSON,
} from './Trend';

/**
 * 
 * @export
 * @interface AlertDeclineRateOverview
 */
export interface AlertDeclineRateOverview {
    /**
     * 
     * @type {Trend}
     * @memberof AlertDeclineRateOverview
     */
    decline_rate: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertDeclineRateOverview
     */
    number_incidents: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertDeclineRateOverview
     */
    number_incidents_declined: Trend;
    /**
     * Top themes by decline rate
     * @type {Array<AlertAggregatedDatapoint>}
     * @memberof AlertDeclineRateOverview
     */
    top_themes: Array<AlertAggregatedDatapoint>;
}

export function AlertDeclineRateOverviewFromJSON(json: any): AlertDeclineRateOverview {
    return AlertDeclineRateOverviewFromJSONTyped(json, false);
}

export function AlertDeclineRateOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertDeclineRateOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decline_rate': TrendFromJSON(json['decline_rate']),
        'number_incidents': TrendFromJSON(json['number_incidents']),
        'number_incidents_declined': TrendFromJSON(json['number_incidents_declined']),
        'top_themes': ((json['top_themes'] as Array<any>).map(AlertAggregatedDatapointFromJSON)),
    };
}

export function AlertDeclineRateOverviewToJSON(value?: AlertDeclineRateOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decline_rate': TrendToJSON(value.decline_rate),
        'number_incidents': TrendToJSON(value.number_incidents),
        'number_incidents_declined': TrendToJSON(value.number_incidents_declined),
        'top_themes': ((value.top_themes as Array<any>).map(AlertAggregatedDatapointToJSON)),
    };
}

