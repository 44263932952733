import scribeStyles from "@incident-shared/aiscribe/ScribeGradient.module.scss";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import React from "react";
import { formatValueWithUnit } from "src/components/alerts/common/formatValueWithUnit";
import { tcx } from "src/utils/tailwind-classes";

export interface TableEntry {
  id: string;
  [key: string]: string | number | undefined;
  percentageChange?: number;
  href?: string;
}

interface Column {
  key: keyof TableEntry;
  label: string;
  align?: "left" | "right";
  render?: (value: string | number, entry: TableEntry) => React.ReactNode;
  show?: (entries: TableEntry[]) => boolean;
}

export interface InsightsTableProps {
  title: string;
  entries: TableEntry[];
  columns: Column[];
  className?: string;
  isAIThemed?: boolean;
  maxValues?: number;
}

const asNumber = (val: string | number) => {
  if (typeof val === "string") {
    return parseFloat(val);
  }
  return val;
};

const DEFAULT_COLUMNS: Column[] = [
  {
    key: "percentageChange",
    label: "Change",
    align: "left",
    render: (value) => <ChangeIndicator value={asNumber(value)} />,
    show: (entries) => entries.some((e) => e.percentageChange !== undefined),
  },
];

export const InsightsTable = ({
  title,
  columns,
  entries,
  className,
  isAIThemed,
  maxValues = 10,
}: InsightsTableProps) => {
  const navigate = useOrgAwareNavigate();

  const visibleColumns = [
    ...columns,
    ...DEFAULT_COLUMNS.filter((col) => !col.show || col.show(entries)),
  ];

  return (
    <div
      className={tcx(
        "w-full rounded-lg border border-stroke-primary bg-white",
        className,
      )}
    >
      {/* Header */}
      <div className="flex items-center justify-between px-4 py-3">
        <div>
          {isAIThemed ? (
            <div
              className={tcx(
                "text-sm font-semibold flex items-center gap-1",
                scribeStyles.staticTextGradient,
              )}
            >
              {title}
              <Icon
                id={IconEnum.SparklesAiGradient}
                size={IconSize.Small}
                className={scribeStyles.staticTextGradient}
              />
            </div>
          ) : (
            <div className="text-sm font-semibold">{title}</div>
          )}
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="table-fixed w-full border-t border-stroke-secondary">
          <thead>
            <tr>
              {visibleColumns.map(({ key, label, align }, i) => (
                <th
                  key={key}
                  className={tcx(
                    "py-2 text-xs-med text-content-tertiary text-left",
                    align === "right" ? "text-right" : "text-left",
                    i === 0 ? "pl-4" : "pl-2",
                    i === visibleColumns.length - 1 ? "pr-4" : "pr-2",
                    i === 0 ? "w-[60%]" : "w-auto",
                  )}
                >
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {entries.slice(0, maxValues).map((entry) => (
              <tr
                key={entry.id}
                className={tcx(
                  "group hover:bg-slate-50 transition-colors border-t border-stroke-secondary",
                  entry.href && "cursor-pointer",
                )}
                onClick={() => entry.href && navigate(entry.href)}
              >
                {visibleColumns.map(({ key, align, render }, i) => (
                  <td
                    key={key}
                    className={tcx(
                      "py-2 text-sm-med",
                      align === "right" ? "text-right" : "text-left",
                      i === 0 ? "pl-4" : "pl-2",
                      i === visibleColumns.length - 1 ? "pr-4" : "pr-2",
                      i === 0 ? "w-[60%]" : "w-auto",
                    )}
                  >
                    <div className={`truncate`}>
                      {render && entry[key] !== undefined
                        ? render(entry[key], entry)
                        : entry[key]}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ChangeIndicator = ({ value }: { value?: number }) => {
  if (value === undefined) return null;

  return (
    <span
      className={tcx(
        "text-sm-med",
        value < 0 && "text-green-500",
        value > 0 && "text-red-500",
        value === 0 && "text-content-secondary",
      )}
    >
      {value >= 0 ? "+" : ""}
      {formatValueWithUnit(value, "%")}
    </span>
  );
};
