/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    EngineScope,
    EngineScopeFromJSON,
    EngineScopeFromJSONTyped,
    EngineScopeToJSON,
} from './EngineScope';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface Trigger
 */
export interface Trigger {
    /**
     * the default condition groups associated with this trigger
     * @type {Array<ConditionGroup>}
     * @memberof Trigger
     */
    default_condition_groups: Array<ConditionGroup>;
    /**
     * the default once for associated with this trigger
     * @type {Array<Reference>}
     * @memberof Trigger
     */
    default_once_for: Array<Reference>;
    /**
     * Human readable identifier for the group this trigger belongs to
     * @type {string}
     * @memberof Trigger
     */
    group_label: TriggerGroupLabelEnum;
    /**
     * String telling the UI what icon to use for this field
     * @type {string}
     * @memberof Trigger
     */
    icon: TriggerIconEnum;
    /**
     * Human readable identifier for this trigger
     * @type {string}
     * @memberof Trigger
     */
    label: string;
    /**
     * Unique name of the trigger
     * @type {string}
     * @memberof Trigger
     */
    name: string;
    /**
     * 
     * @type {EngineScope}
     * @memberof Trigger
     */
    scope: EngineScope;
    /**
     * Human readable description of when the trigger fires
     * @type {string}
     * @memberof Trigger
     */
    when_description: string;
}

/**
* @export
* @enum {string}
*/
export enum TriggerGroupLabelEnum {
    General = 'general',
    UserActions = 'user actions',
    SpecificChanges = 'specific changes',
    Other = 'other'
}/**
* @export
* @enum {string}
*/
export enum TriggerIconEnum {
    Action = 'action',
    Alert = 'alert',
    Announcement = 'announcement',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Chart = 'chart',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentStatusDeclined = 'incident-status-declined',
    IncidentStatusMerged = 'incident-status-merged',
    IncidentType = 'incident_type',
    Key = 'key',
    Lock = 'lock',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    OnCall = 'on-call',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackGreyscale = 'slack-greyscale',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Sparkles = 'sparkles',
    SparklesAiGradient = 'sparkles-ai-gradient',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}

export function TriggerFromJSON(json: any): Trigger {
    return TriggerFromJSONTyped(json, false);
}

export function TriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trigger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_condition_groups': ((json['default_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'default_once_for': ((json['default_once_for'] as Array<any>).map(ReferenceFromJSON)),
        'group_label': json['group_label'],
        'icon': json['icon'],
        'label': json['label'],
        'name': json['name'],
        'scope': EngineScopeFromJSON(json['scope']),
        'when_description': json['when_description'],
    };
}

export function TriggerToJSON(value?: Trigger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_condition_groups': ((value.default_condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'default_once_for': ((value.default_once_for as Array<any>).map(ReferenceToJSON)),
        'group_label': value.group_label,
        'icon': value.icon,
        'label': value.label,
        'name': value.name,
        'scope': EngineScopeToJSON(value.scope),
        'when_description': value.when_description,
    };
}

