/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertAggregatedDatapoint
 */
export interface AlertAggregatedDatapoint {
    /**
     * The ID of this breakdown value
     * @type {string}
     * @memberof AlertAggregatedDatapoint
     */
    id: string;
    /**
     * The title of this breakdown value
     * @type {string}
     * @memberof AlertAggregatedDatapoint
     */
    title: string;
    /**
     * The unit of this value
     * @type {string}
     * @memberof AlertAggregatedDatapoint
     */
    unit: AlertAggregatedDatapointUnitEnum;
    /**
     * The value of this breakdown value
     * @type {number}
     * @memberof AlertAggregatedDatapoint
     */
    value: number;
}

/**
* @export
* @enum {string}
*/
export enum AlertAggregatedDatapointUnitEnum {
    Alert = 'alert',
    Incident = 'incident',
    Hour = 'hour',
    Percent = '%'
}

export function AlertAggregatedDatapointFromJSON(json: any): AlertAggregatedDatapoint {
    return AlertAggregatedDatapointFromJSONTyped(json, false);
}

export function AlertAggregatedDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertAggregatedDatapoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'unit': json['unit'],
        'value': json['value'],
    };
}

export function AlertAggregatedDatapointToJSON(value?: AlertAggregatedDatapoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'unit': value.unit,
        'value': value.value,
    };
}

