import { Route, Routes } from "react-router";

import { WorkbenchSuggestionShowPage } from "./WorkbenchSuggestionShowPage";
import { WorkbenchSuggestionsListPage } from "./WorkbenchSuggestionsListPage";

export const WorkbenchSuggestionsRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<WorkbenchSuggestionsListPage />} />
      <Route path=":suggestionId" element={<WorkbenchSuggestionShowPage />} />
    </Routes>
  );
};
