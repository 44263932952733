/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsightsReportSchedule,
    InsightsReportScheduleFromJSON,
    InsightsReportScheduleFromJSONTyped,
    InsightsReportScheduleToJSON,
} from './InsightsReportSchedule';

/**
 * 
 * @export
 * @interface InsightsListReportSchedulesResponseBody
 */
export interface InsightsListReportSchedulesResponseBody {
    /**
     * 
     * @type {Array<InsightsReportSchedule>}
     * @memberof InsightsListReportSchedulesResponseBody
     */
    report_schedules: Array<InsightsReportSchedule>;
}

export function InsightsListReportSchedulesResponseBodyFromJSON(json: any): InsightsListReportSchedulesResponseBody {
    return InsightsListReportSchedulesResponseBodyFromJSONTyped(json, false);
}

export function InsightsListReportSchedulesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsListReportSchedulesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'report_schedules': ((json['report_schedules'] as Array<any>).map(InsightsReportScheduleFromJSON)),
    };
}

export function InsightsListReportSchedulesResponseBodyToJSON(value?: InsightsListReportSchedulesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report_schedules': ((value.report_schedules as Array<any>).map(InsightsReportScheduleToJSON)),
    };
}

