import { Markdown } from "@incident-ui/Markdown/Markdown";
import React, { useCallback, useEffect, useState } from "react";
import { Components } from "react-markdown/lib";
import { mrkdwnToMarkdown } from "src/utils/rich-text";

export type MrkdwnProps = {
  className?: string;
  text?: string;
};

/**
Mrkdwn renders a Slack mrkdwn string using the react-markdown library, by first converting it to
Markdown. In general, we prefer using TemplatedText but this is useful if we haven't migrated over
yet.
*/
export const Mrkdwn = ({
  className,
  text,
}: MrkdwnProps): React.ReactElement | null => {
  // This is a bit of a mess because the mrkdwn-to-markdown pipeline is async.
  const [markdown, setMarkdown] = useState(text);
  const processMarkdown = useCallback(async () => {
    if (text) {
      // If the `text` is mrkdwn (Slack-flavoured markdown), things will be
      // subtly wrong if we just send it right into react-markdown. Ideally we'd
      // just have a plugin for remark which would parse mrkdwn directly, but
      // that's a lot of work, so instead we convert mrkdwn to HTML (using our
      // nasty regexp-and-hacks function), then use rehype to convert that
      // _back_ to markdown.
      setMarkdown(await mrkdwnToMarkdown(text));
    } else {
      setMarkdown(undefined);
    }
  }, [text]);

  useEffect(() => {
    processMarkdown();
  }, [processMarkdown]);

  if (!markdown) {
    return null;
  }

  return (
    <Markdown
      components={{
        p: PLato, // To apply the Lato font
        em: EmLato,
        strong: StrongLato, // To apply the Lato font
      }}
      className={className}
    >
      {markdown}
    </Markdown>
  );
};

const EmLato: Components["em"] = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <em className="!font-['lato'] pr-1" {...restProps}>
    {children}
  </em>
);

const PLato: Components["p"] = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>): React.ReactElement => (
  <p className="!font-['lato']" {...restProps}>
    {children}
  </p>
);

const StrongLato: Components["strong"] = ({
  children,
  ...restProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>): React.ReactElement => (
  <strong className="!font-['lato']" {...restProps}>
    {children}
  </strong>
);
