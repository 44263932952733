import { usePylon } from "@bolasim/react-use-pylon";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  StackedListButton,
  Txt,
} from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI } from "src/utils/swr";

import { InstallIntegrationButton } from "../common/InstallIntegrationButton";

interface Props {
  onClose: () => void;
  isReconnect?: boolean;
  product: "jira" | "confluence";
}

export const ConnectIntegrationModal = ({
  onClose,
  isReconnect,
  product,
}: Props) => {
  const { showKnowledgeBaseArticle } = usePylon();
  const {
    data: config,
    error,
    isLoading,
  } = useAPI(
    product === "jira"
      ? "integrationsJiraCloudGetConfig"
      : "integrationsConfluenceGetConfig",
    undefined,
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const productName = product === "jira" ? "Jira Cloud" : "Confluence";

  if (
    (config?.config.connect_app_installations.length ?? 0) > 0 &&
    isReconnect
  ) {
    return (
      <Modal
        isOpen={true}
        onClose={onClose}
        analyticsTrackingId={"atlassian-connect-app-reconnect"}
        title="Reconnect Atlassian app"
      >
        <ModalContent>
          <Txt>
            {productName} has been connected via our Atlassian app. To
            reconnect, visit the app settings within {productName} by navigating
            to &quot;Manage apps&quot;.
          </Txt>
          <br />
          <Txt>
            Follow our&nbsp;
            <Button
              theme={ButtonTheme.Link}
              analyticsTrackingId={`connect-${product}-help`}
              onClick={() => showKnowledgeBaseArticle("9818616764")}
            >
              instructions
            </Button>
            &nbsp;on how to connect incident.io to {productName}
          </Txt>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <ConnectIntegrationModalChoice
      onClose={onClose}
      product={product}
      isReconnect={isReconnect}
    />
  );
};

const ConnectIntegrationModalChoice = ({
  onClose,
  isReconnect,
  product,
}: Props) => {
  const [choice, setChoice] = useState<"oauth" | "connect-app" | null>(null);
  // We want to force users to use the new connect app unless they had an active old integration
  // This flag will be turned on for anyone who does not have an active OLD integration.
  const { featureForceAtlassianConnectApp } = useFlags();
  if (featureForceAtlassianConnectApp) {
    return <ConnectViaConnectAppModal onClose={onClose} product={product} />;
  }

  switch (choice) {
    case "oauth":
      return (
        <ConnectViaOAuthModal
          onClose={onClose}
          isReconnect={isReconnect}
          product={product}
        />
      );
    case "connect-app":
      return <ConnectViaConnectAppModal onClose={onClose} product={product} />;
    case null:
      return (
        <Modal
          isOpen
          title={`Connect to ${
            product === "jira" ? "Jira Cloud" : "Confluence"
          }`}
          analyticsTrackingId={`connect-atlassian-integration-choice-${product}`}
          onClose={onClose}
        >
          <ModalContent>
            <StackedList>
              <StackedListButton
                analyticsTrackingId={`${product}-connection-select-atlassian-app`}
                title="Atlassian Marketplace app"
                description={`Install the incident.io app from the Atlassian Marketplace to connect your ${
                  product === "jira" ? "Jira Cloud" : "Confluence"
                }.`}
                badgeProps={{ theme: BadgeTheme.Success, label: "Recommended" }}
                onSelect={() => setChoice("connect-app")}
              />
              <StackedListButton
                analyticsTrackingId={`${product}-connection-select-oauth`}
                title="OAuth 2.0"
                description={`Create a service account in ${
                  product === "jira" ? "Jira Cloud" : "Confluence"
                } and login via OAuth 2.0.`}
                onSelect={() => setChoice("oauth")}
                badgeProps={{ theme: BadgeTheme.Warning, label: "Deprecated" }}
              />
            </StackedList>
          </ModalContent>
        </Modal>
      );
    default:
      return null;
  }
};

const ConnectViaOAuthModal = ({ isReconnect, onClose, product }: Props) => {
  const { integrations } = useIntegrations();
  const integration = integrations?.find((i) => i.provider === product);
  if (!integrations || !integration) {
    return <Loader />;
  }

  const config = IntegrationConfigFor(integration.provider);

  return (
    <Modal
      title={`Connect to ${config.label}`}
      isOpen
      analyticsTrackingId="connect-integration-deeplink"
      analyticsTrackingMetadata={{ integration: integration.provider }}
      onClose={onClose}
    >
      <ModalContent>
        <p className="text-sm text-slate-700">
          We&apos;re about to send you to {config.label}, where (if you give
          permission) you can set up the incident.io integration.
        </p>
        <br></br>
        <p className="text-sm text-slate-700">
          Once installed, you&apos;ll be redirected back to the integrations
          page.
        </p>
        <div className="text-right mt-4">
          <InstallIntegrationButton
            integration={integration}
            onOAuthModal={true}
            isReconnect={isReconnect}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

const ConnectViaConnectAppModal = ({
  onClose,
  product,
}: {
  onClose: () => void;
  product: "jira" | "confluence";
}) => {
  const { showKnowledgeBaseArticle } = usePylon();
  const productName = product === "jira" ? "Jira Cloud" : "Confluence";
  return (
    <Modal
      isOpen
      title={`Connect to ${productName}`}
      analyticsTrackingId={"connect-jira-server-integration"}
      onClose={onClose}
    >
      <ModalContent>
        <p className="text-sm text-slate-700">
          To connect your {productName} account, you need to first install the
          incident.io app from the Atlassian Marketplace.
        </p>
        <br></br>
        <p className="text-sm text-slate-700">
          Follow our&nbsp;
          <Button
            theme={ButtonTheme.Link}
            analyticsTrackingId={`connect-${product}-help`}
            onClick={() => showKnowledgeBaseArticle("9818616764")}
          >
            instructions
          </Button>
          &nbsp;on how to connect incident.io to {productName}
        </p>
      </ModalContent>
      <ModalFooter
        confirmButtonType="button"
        confirmButtonText="Visit our app on the Atlassian Marketplace"
        onConfirm={() =>
          window.open("https://marketplace.atlassian.com/apps/1233901")
        }
      />
    </Modal>
  );
};
