/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestigationScorecardMetric,
    InvestigationScorecardMetricFromJSON,
    InvestigationScorecardMetricFromJSONTyped,
    InvestigationScorecardMetricToJSON,
} from './InvestigationScorecardMetric';

/**
 * Grade for a specific aspect of an investigation
 * @export
 * @interface InvestigationScorecardGrade
 */
export interface InvestigationScorecardGrade {
    /**
     * What grader powered this result
     * @type {string}
     * @memberof InvestigationScorecardGrade
     */
    grade_type: string;
    /**
     * Individual metric scores for this grade
     * @type {Array<InvestigationScorecardMetric>}
     * @memberof InvestigationScorecardGrade
     */
    metrics: Array<InvestigationScorecardMetric>;
    /**
     * Name of the grade
     * @type {string}
     * @memberof InvestigationScorecardGrade
     */
    name: string;
}

export function InvestigationScorecardGradeFromJSON(json: any): InvestigationScorecardGrade {
    return InvestigationScorecardGradeFromJSONTyped(json, false);
}

export function InvestigationScorecardGradeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationScorecardGrade {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grade_type': json['grade_type'],
        'metrics': ((json['metrics'] as Array<any>).map(InvestigationScorecardMetricFromJSON)),
        'name': json['name'],
    };
}

export function InvestigationScorecardGradeToJSON(value?: InvestigationScorecardGrade | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grade_type': value.grade_type,
        'metrics': ((value.metrics as Array<any>).map(InvestigationScorecardMetricToJSON)),
        'name': value.name,
    };
}

