import {
  AlertsGetInsightsResponseBody,
  IncidentSlim,
  OverviewActivityDatapoint,
  OverviewActivityDatapointPeriodEnum,
  TrendSentimentEnum,
} from "@incident-io/api";
import { ExtendedFormFieldValue } from "@incident-shared/filters";
import { ContentBox, GenericErrorMessage } from "@incident-ui";
import { BarChart } from "@incident-ui/Chart/BarChart";
import _ from "lodash";
import React, { useState } from "react";
import { formFieldValueToInsightsFilter } from "src/components/insights/v3/dashboards/common/marshall";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { getColor } from "src/utils/twConfig";

import { ALERT_INSIGHTS_TIME_PERIOD_LABEL } from "../alert-details/useAlertInsightsDates";
import {
  HeadlineMetricCard,
  HeadlineMetricType,
} from "../common/AlertHeadlineMetrics";
import { formatValueWithUnit } from "../common/formatValueWithUnit";

export const AlertActivityChart = ({
  timezone,
  startDate,
  endDate,
  filters,
}: {
  timezone: string;
  startDate: Date;
  endDate: Date;
  filters: ExtendedFormFieldValue[];
}): React.ReactNode => {
  const {
    data: activityData,
    isLoading: isLoadingActivity,
    error: activityError,
  } = useAPI("alertsGetInsightsOverviewActivity", {
    getInsightsOverviewActivityRequestBody: {
      timezone,
      start_date: startDate,
      end_date: endDate,
      filters: filters.map(formFieldValueToInsightsFilter),
    },
  });

  const {
    data: insightsData,
    isLoading: isLoadingInsights,
    error: insightsError,
  } = useAPI("alertsGetInsights", {
    getInsightsRequestBody: {
      filters: filters.map(formFieldValueToInsightsFilter),
      start_date: startDate,
      end_date: endDate,
      timezone,
    },
  });

  if (activityError || insightsError) {
    return <GenericErrorMessage />;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex">
          <div className="text-base-bold">
            Activity
            <span className="ml-2 text-xs-med text-content-secondary">
              {ALERT_INSIGHTS_TIME_PERIOD_LABEL}
            </span>
          </div>
        </div>
        <div className="text-content-secondary max-w-[560px]">
          A high level view of alert volume, time spend and decline rate for all
          your alerts. Use the filters at the top of the page to tailor this
          page to your needs.
        </div>
      </div>
      {!isLoadingActivity &&
      !isLoadingInsights &&
      activityData &&
      insightsData ? (
        <ActivityChart
          datapoints={activityData.datapoints}
          insightsData={insightsData}
        />
      ) : (
        <div
          className={tcx(
            "w-full rounded-lg bg-gradient-to-r",
            "bg-surface-secondary from-slate-50 w-full h-[320px]",
          )}
        />
      )}
    </div>
  );
};

const availableMetrics = [
  {
    key: "alert_count",
    title: "Volume",
    unit: "alert",
    background: "bg-red-300",
    type: HeadlineMetricType.NumberAlerts,
  },
  {
    key: "total_workload_minutes",
    title: "Time spent",
    unit: "hour",
    background: "bg-red-300",
    type: HeadlineMetricType.WorkloadTotalIncidents,
  },

  {
    key: "decline_rate",
    title: "Decline rate",
    unit: "%",
    background: "bg-red-300",
    type: HeadlineMetricType.DeclineRate,
  },
] as const;

type ActivityDatapoint = {
  id: string;
  label: string;
  current: number;
  previous: number;
  currentAverage?: number;
  previousAverage?: number;
  tooltipText: string;
  relevantIncidents: IncidentSlim[];
};
const ActivityChart = ({
  datapoints,
  insightsData,
}: {
  datapoints: OverviewActivityDatapoint[];
  insightsData: AlertsGetInsightsResponseBody;
}) => {
  const [selectedMetricType, setSelectedMetricType] =
    useState<HeadlineMetricType>(HeadlineMetricType.NumberAlerts);

  const selectedMetric = availableMetrics.find(
    (m) => m.type === selectedMetricType,
  );

  if (!selectedMetric) {
    throw new Error("Unreachable: expected selected metric to be defined");
  }

  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const previousAverage = _.meanBy(
    datapoints.filter(
      (data) => data.period === OverviewActivityDatapointPeriodEnum.Previous,
    ),
    (data) =>
      selectedMetric.key === "total_workload_minutes"
        ? data[selectedMetric.key] / 60
        : data[selectedMetric.key],
  );
  const currentAverage = _.meanBy(
    datapoints.filter(
      (data) => data.period === OverviewActivityDatapointPeriodEnum.Current,
    ),
    (data) =>
      selectedMetric.key === "total_workload_minutes"
        ? data[selectedMetric.key] / 60
        : data[selectedMetric.key],
  );

  const chartData: ActivityDatapoint[] = datapoints.map(
    (datapoint): ActivityDatapoint => {
      const value =
        selectedMetric.key === "total_workload_minutes"
          ? datapoint[selectedMetric.key] / 60
          : datapoint[selectedMetric.key];
      const isPrevious =
        datapoint.period === OverviewActivityDatapointPeriodEnum.Previous;
      return {
        id: datapoint.date.toISOString(),
        label: datapoint.date.toLocaleDateString(locale, {
          timeZone: timezone,
          weekday: "short",
          day: "numeric",
          month: "short",
        }),
        previous: isPrevious ? value : 0,
        current: isPrevious ? 0 : value,
        previousAverage: isPrevious ? previousAverage : undefined,
        currentAverage: isPrevious ? undefined : currentAverage,
        tooltipText: formatValueWithUnit(value, selectedMetric.unit),
        relevantIncidents: datapoint.relevant_incidents,
      };
    },
  );

  const getColorForAverageLine = (key: string) => {
    const metric = insightsData[selectedMetric.type];
    if (key === "previousAverage") {
      return getColor("slate", "400");
    }

    return metric.sentiment === TrendSentimentEnum.Good
      ? getColor("green", "500")
      : getColor("red", "600");
  };

  return (
    <ContentBox className="p-4">
      <div className="flex flex-col gap-4">
        <div className="flex w-full gap-4">
          {availableMetrics.map((m) => {
            return (
              <HeadlineMetricCard
                key={m.key}
                type={m.type}
                data={insightsData}
                onClick={() => setSelectedMetricType(m.type)}
                includePercentageChange
                className={tcx(selectedMetricType !== m.type ? "bg-white" : "")}
                containerClassName="w-1/3"
              />
            );
          })}
        </div>

        <BarChart<ActivityDatapoint>
          dataKeys={["previous", "current"]}
          data={chartData}
          chartConfig={{
            previous: {
              label: "Previous period",
              color: getColor("slate", "100"),
            },
            current: {
              label: "Last 4 weeks " + selectedMetric.title,
              color: getColor("slate", "200"),
            },
          }}
          referenceLineDataKeys={["previousAverage", "currentAverage"]}
          referenceLineConfig={{
            getLabelConfig: (key) => {
              const value =
                key === "previousAverage" ? previousAverage : currentAverage;

              return {
                content: `Avg. ${formatValueWithUnit(
                  value,
                  selectedMetric.unit,
                  1,
                )}`,
                textColor: getColor("white"),
                backgroundColor: getColorForAverageLine(key),
              };
            },
            getLineColor: getColorForAverageLine,
          }}
          className="w-full h-[200px]"
          cartesianGridProps={{
            vertical: false,
            horizontal: true,
            stroke: getColor("slate", "50"),
          }}
          barProps={{
            minPointSize: 1,
            defaultFill: getColor("slate", "200"),
          }}
          renderTooltipContent={(data) => (
            <div className="w-[360px] flex flex-col p-3">
              <div className="flex flex-col gap-2">
                <div className="text-xs-bold">{data.label}</div>
                <div className="flex items-center gap-2 text-xs-med text-content-secondary">
                  <div
                    className={tcx(
                      "w-2 h-2 shrink-0 rounded-[2px]",
                      selectedMetric.background,
                    )}
                  />
                  <div>{data.tooltipText}</div>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </ContentBox>
  );
};
