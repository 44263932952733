import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import {
  IncidentForm,
  IncidentFormFormTypeEnum as FormTypeEnum,
} from "src/contexts/ClientContext";

export type FormTypeConfig = {
  icon: IconEnum;
  label: string;
  description: string;
  rank: number;
  tooManyFieldsThreshold: number;
  verb: string;
  secret?: boolean;
  requiredProduct?: Product;
};

export const FORM_TYPE_CONFIG: {
  [key in FormTypeEnum]: FormTypeConfig;
} = {
  [FormTypeEnum.Declare]: {
    icon: IconEnum.Incident,
    label: "Declare",
    description: "Shown when a user first declares an incident",
    rank: 1,
    tooManyFieldsThreshold: 8,
    verb: "declared",
    requiredProduct: Product.Response,
  },
  [FormTypeEnum.Accept]: {
    icon: IconEnum.Tick,
    label: "Accept",
    description:
      "Shown when a user accepts an incident that was previously in triage",
    rank: 2,
    tooManyFieldsThreshold: 6,
    verb: "accepted",
    requiredProduct: Product.Response,
  },
  [FormTypeEnum.Update]: {
    icon: IconEnum.Announcement,
    label: "Update",
    description: "Shown when a user shares an update from an incident",
    rank: 3,
    tooManyFieldsThreshold: 6,
    verb: "updated",
    requiredProduct: Product.Response,
  },
  [FormTypeEnum.Resolve]: {
    icon: IconEnum.Flag,
    label: "Resolve",
    description: "Shown when a user resolves an incident",
    rank: 4,
    tooManyFieldsThreshold: 10,
    verb: "resolved",
    requiredProduct: Product.Response,
  },
  [FormTypeEnum.Escalate]: {
    icon: IconEnum.Escalate,
    label: "Escalate",
    description: "Shown when a user manually escalates an incident",
    rank: 5,
    tooManyFieldsThreshold: 5,
    verb: "escalated",
    requiredProduct: Product.OnCall,
  },
  [FormTypeEnum.CustomFields]: {
    icon: IconEnum.CustomField,
    label: "Custom fields",
    description: "Shown when a user edits the custom fields on an incident",
    rank: 6,
    tooManyFieldsThreshold: 5,
    verb: "updated",
    secret: true,
    requiredProduct: Product.Response,
  },
};

export const formEditPath = (form: IncidentForm) => {
  return `/settings/forms/${
    form.form_type === FormTypeEnum.Escalate ? "escalate" : "lifecycle"
  }/${form.id}`;
};
