import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Interpose } from "@incident-ui/Interpose/Interpose";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import React, { ForwardedRef, LegacyRef } from "react";
import { tcx } from "src/utils/tailwind-classes";

export type ConditionBadgeTheme = "naked" | "slate" | "white" | "dark";

const themeStyles: {
  [key in ConditionBadgeTheme]: {
    hover: string;
    background?: string;
    text?: string;
  };
} = {
  naked: {
    hover: "hover:border-stroke",
    background: undefined,
    text: undefined,
  },
  slate: {
    hover: "hover:bg-surface-tertiary",
    background: "bg-surface-secondary",
    text: "text-content-primary",
  },
  white: {
    hover: "hover:bg-surface-tertiary",
    background: "bg-surface-primary",
    text: "text-content-primary",
  },
  dark: {
    hover: "hover:bg-slate-700",
    background: "bg-surface-invert",
    text: "text-white",
  },
};

export type ConditionBadgeProps = {
  icon?: IconEnum;
  color?: ColorPaletteEnum;
  subject: string;
  operation: string;
  criteria: string | null | React.ReactNode;
  onDelete?: () => void;
  className?: string;
  inline?: boolean;
  // Mini controls the size of the badge: a mini badge has text-xs and a smaller icon
  mini?: boolean;
  theme: ConditionBadgeTheme;
  onClick?: () => void;
  // isTrigger is used for a badge where we are wrapping the component, but still want
  // to apply 'trigger' styles to the badge (e.g. hover states)
  isTrigger?: boolean;
  // noTooltip is used to disable the tooltip on the badge
  noTooltip?: boolean;
  hideOperationAndCriteria?: boolean;
};

/** ConditionBadge is used when displaying filters or conditions, such as:
 * severity is one of major, minor.
 * If it's passed an `onClick` it'll render a button, otherwise it'll render a span.
 * If it's passed an `onDelete` there'll be a cross allowing you to remove the condition
 */

export const ConditionBadge = React.forwardRef(
  (
    {
      icon, // e.g. "severity"
      subject, // e.g. "Severity"
      operation, // e.g. "is one of"
      criteria, // e.g. major, minor
      color = ColorPaletteEnum.Blue,
      onDelete,
      className,
      inline,
      mini,
      theme: themeKey,
      onClick,
      isTrigger,
      noTooltip,
      hideOperationAndCriteria = false,
    }: ConditionBadgeProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): React.ReactElement | null => {
    // if there's no onClick, it shouldn't be a button and
    // the hover shouldn't change the text colour
    const renderAsTrigger = isTrigger || !!onClick;

    const ComponentType = renderAsTrigger ? "button" : "span";
    const componentProps = renderAsTrigger ? { type: "button", onClick } : {};
    const labelParts = subject.split(" → ");

    const theme = themeStyles[themeKey];
    const palette = getColorPalette(color);

    const showOperation = !hideOperationAndCriteria && operation;
    const showCriteria = !hideOperationAndCriteria && criteria;

    return (
      <Tooltip
        content={
          <div className="text-xs break-words">
            <span className="font-medium">{subject}</span> {operation}{" "}
            <span className="font-medium">{criteria}</span>
          </div>
        }
        side="top"
        disabled={noTooltip}
      >
        <ComponentType
          ref={ref as LegacyRef<HTMLButtonElement>}
          {...(componentProps as { type?: "button"; onClick?: () => void })}
          className={tcx(
            "max-w-full w-fit h-auto overflow-hidden",
            "font-medium",
            theme.background,
            theme.text,
            {
              [theme.hover]: !!renderAsTrigger,
              "cursor-pointer": !!renderAsTrigger,
            },
            inline ? "inline-flex items-center" : "flex items-center text-left",
            mini
              ? "text-xs rounded-1 px-1 py-0.5 gap-1"
              : "text-sm rounded px-2 py-1 min-h-7 gap-2",
            className,
          )}
        >
          {/* Part 1: subject */}
          <div className="flex items-center gap-1 truncate shrink min-w-[24px]">
            {icon && (
              <Icon id={icon} size={IconSize.Small} className={palette.icon} />
            )}
            <div className="flex items-center flex-nowrap truncate">
              <Interpose
                separator={
                  <Icon
                    className="text-content-tertiary"
                    id={IconEnum.ChevronRight}
                  />
                }
              >
                {labelParts.map((part, i) => (
                  <span className="truncate" key={i}>
                    {part}
                  </span>
                ))}
              </Interpose>
            </div>
          </div>
          {/* Part 2: operation */}
          {showOperation && (
            <span
              className={tcx(
                "font-medium whitespace-nowrap text-content-tertiary",
              )}
            >{` ${operation}`}</span>
          )}
          {/* Part 3: criteria */}
          {showCriteria ? (
            <span className="truncate shrink min-w-[24px]">{criteria}</span>
          ) : undefined}
          {onDelete ? (
            <Button
              analyticsTrackingId="remove-condition"
              theme={ButtonTheme.Unstyled}
              className="text-content-tertiary hover:text-content-primary"
              icon={IconEnum.Close}
              onClick={onDelete}
              title="Remove"
            />
          ) : undefined}
        </ComponentType>
      </Tooltip>
    );
  },
);

ConditionBadge.displayName = "ConditionBadge";
