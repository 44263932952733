import { Callout, CalloutTheme } from "@incident-ui";
import React from "react";

import { SettingsSubHeading } from "../../../components/settings/SettingsSubHeading";

export const SettingsBillingSuccessRoute = (): React.ReactElement => {
  return (
    <>
      <SettingsSubHeading title="Billing" />
      <Callout theme={CalloutTheme.Success}>
        <div>
          <h3 className="text-base font-medium">All sorted!</h3>
          <div className="mt-2 text-base">
            <p>
              Your subscription has been set up successfully. If you have any
              questions, drop us an email at{" "}
              <a
                href="mailto:support@incident.io"
                className="underline hover:text-alarmalade-600"
              >
                support@incident.io
              </a>
              .
            </p>
          </div>
        </div>
      </Callout>
    </>
  );
};
