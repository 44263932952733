/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';

/**
 * 
 * @export
 * @interface IncidentCall
 */
export interface IncidentCall {
    /**
     * URL for the call
     * @type {string}
     * @memberof IncidentCall
     */
    call_url: string;
    /**
     * When the action was created
     * @type {Date}
     * @memberof IncidentCall
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof IncidentCall
     */
    created_by: Actor;
    /**
     * ID of the call in the external provider
     * @type {string}
     * @memberof IncidentCall
     */
    external_id?: string;
    /**
     * External provider for the call
     * @type {string}
     * @memberof IncidentCall
     */
    external_provider: IncidentCallExternalProviderEnum;
    /**
     * ID of the call
     * @type {string}
     * @memberof IncidentCall
     */
    id: string;
    /**
     * ID of the incident that this call belongs to
     * @type {string}
     * @memberof IncidentCall
     */
    incident_id: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentCallExternalProviderEnum {
    Zoom = 'zoom',
    GoogleMeet = 'google_meet',
    MicrosoftTeamsOnlineMeetings = 'microsoft_teams_online_meetings',
    Other = 'other'
}

export function IncidentCallFromJSON(json: any): IncidentCall {
    return IncidentCallFromJSONTyped(json, false);
}

export function IncidentCallFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCall {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'call_url': json['call_url'],
        'created_at': (new Date(json['created_at'])),
        'created_by': ActorFromJSON(json['created_by']),
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'external_provider': json['external_provider'],
        'id': json['id'],
        'incident_id': json['incident_id'],
    };
}

export function IncidentCallToJSON(value?: IncidentCall | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_url': value.call_url,
        'created_at': (value.created_at.toISOString()),
        'created_by': ActorToJSON(value.created_by),
        'external_id': value.external_id,
        'external_provider': value.external_provider,
        'id': value.id,
        'incident_id': value.incident_id,
    };
}

