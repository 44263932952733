/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalEscalationPath,
    ExternalEscalationPathFromJSON,
    ExternalEscalationPathFromJSONTyped,
    ExternalEscalationPathToJSON,
} from './ExternalEscalationPath';

/**
 * 
 * @export
 * @interface EscalationPathsCreateExternalRequestBody
 */
export interface EscalationPathsCreateExternalRequestBody {
    /**
     * Paths to import
     * @type {Array<ExternalEscalationPath>}
     * @memberof EscalationPathsCreateExternalRequestBody
     */
    paths_to_import: Array<ExternalEscalationPath>;
    /**
     * IDs of users that should be promoted to on-call responders before creating these paths
     * @type {Array<string>}
     * @memberof EscalationPathsCreateExternalRequestBody
     */
    user_ids_to_promote?: Array<string>;
}

export function EscalationPathsCreateExternalRequestBodyFromJSON(json: any): EscalationPathsCreateExternalRequestBody {
    return EscalationPathsCreateExternalRequestBodyFromJSONTyped(json, false);
}

export function EscalationPathsCreateExternalRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathsCreateExternalRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paths_to_import': ((json['paths_to_import'] as Array<any>).map(ExternalEscalationPathFromJSON)),
        'user_ids_to_promote': !exists(json, 'user_ids_to_promote') ? undefined : json['user_ids_to_promote'],
    };
}

export function EscalationPathsCreateExternalRequestBodyToJSON(value?: EscalationPathsCreateExternalRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paths_to_import': ((value.paths_to_import as Array<any>).map(ExternalEscalationPathToJSON)),
        'user_ids_to_promote': value.user_ids_to_promote,
    };
}

