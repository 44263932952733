import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Heading, HeadingLevel } from "@incident-ui/Heading/Heading";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Modal.module.scss";

type CloseProps = { onClose: () => void };

type Props = {
  /* The title to display at the top of the modal. */
  title?: string | React.ReactNode;
  /* Optional heading level for the modal */
  headingLevel?: HeadingLevel;
  /* Whether or not to include the border-bottom */
  hideBorder?: boolean;
} & CloseProps;

// A drop-in header component for the modal.
export function ModalHeader({
  title,
  headingLevel = 3,
  hideBorder = false,
  onClose,
}: Props): React.ReactElement {
  return (
    <div
      className={tcx(styles.modalHeader, "p-4", {
        "border-b border-stroke": !hideBorder,
      })}
    >
      <Heading
        id="modal-title"
        size="medium"
        level={headingLevel}
        data-testid="modal-heading"
      >
        {title}
      </Heading>

      <Button
        // TODO: add a analyticsTrackingId to modals and use this here!
        analyticsTrackingId={null}
        icon={IconEnum.Close}
        iconProps={{
          size: IconSize.Medium,
        }}
        className={tcx(styles.closeButton, "h-6 w-6 hover:bg-surface-tertiary")}
        theme={ButtonTheme.Unstyled}
        // 0 means "I go last", after any form and cancel/confirm buttons.
        tabIndex={0}
        onClick={onClose}
      >
        <span className={styles.closeText}>Close</span>
      </Button>
    </div>
  );
}
