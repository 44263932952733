import { Alert, AlertSource } from "@incident-io/api";
import {
  ReferenceExample,
  ReferenceExampleOption,
  ReferenceWithExample,
} from "@incident-shared/engine/expressions/ExpressionsEditor";
import { cloneDeep } from "lodash";
import { EnrichedScope, findIndexInScope } from "src/utils/scope";
import { useDeepCompareMemo } from "use-deep-compare";

// useScopeWithAlertPreviews gives you the scope for an alert source type, then adds in the JSON payload
// as examples for those alerts. That powers expression previews for JSON content.
export const useScopeWithAlertPreviews = ({
  alertSource,
  alerts,
}: {
  alertSource: AlertSource;
  alerts: Alert[];
}): EnrichedScope<ReferenceWithExample> => {
  const newScope = cloneDeep(alertSource.scope);

  const payloadReferenceIndex = findIndexInScope(newScope, "payload");

  if (alertSource && payloadReferenceIndex) {
    const example = createExamplePayloadOrUseRecentAlerts(alertSource, alerts);

    const payloadReference = newScope.references[payloadReferenceIndex];
    const payloadReferenceWithExample: ReferenceWithExample = {
      ...payloadReference,
      example,
    };

    const referencesWithPreviews = [
      ...newScope.references.slice(0, payloadReferenceIndex),
      payloadReferenceWithExample,
      ...newScope.references.slice(payloadReferenceIndex + 1),
    ];

    newScope.references = referencesWithPreviews;
  }

  // Avoid re-rendering if the scope hasn't meaningfully changed.
  const memoised = useDeepCompareMemo(() => newScope, [newScope]);

  return memoised as EnrichedScope<ReferenceWithExample>;
};

const createExamplePayloadOrUseRecentAlerts = (
  alertSource: AlertSource,
  alerts: Alert[] | undefined,
): ReferenceExample => {
  const examplesFromPreviewAlerts = alerts?.map(
    (alert: Alert): ReferenceExampleOption => {
      return {
        label: alert.title,
        value: alert.id,
        binding: {
          value: {
            literal: alert.payload,
          },
        },
      };
    },
  );

  const hasExamples =
    examplesFromPreviewAlerts && examplesFromPreviewAlerts.length > 0;
  return {
    initial: hasExamples ? examplesFromPreviewAlerts[0].value : "EXAMPLE",
    message:
      "Pick an example payload or choose a recent alert to test this query against.",
    groups: [
      ...(hasExamples
        ? [
            {
              label: "Recent alerts",
              options: examplesFromPreviewAlerts,
            },
          ]
        : []),
      {
        label: "Examples",
        options: [
          {
            label: `Reference payload`,
            value: "EXAMPLE",
            binding: {
              value: {
                literal: alertSource.example_payload,
              },
            },
          },
        ],
      },
    ],
  };
};
