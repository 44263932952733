import { usePylon } from "@bolasim/react-use-pylon";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { SetupPageWrapper } from "@incident-shared/layout/SetupPageWrapper/SetupPageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Loader,
  Modal,
  ModalContent,
  SlackTeamAvatar,
} from "@incident-ui";
import { motion } from "framer-motion";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { ConnectModal } from "src/components/settings/security/user-token/ConnectModal";
import { AddToSlackButton } from "src/components/slack/AddToSlackButton";
import {
  SlackInfoReconnectionReasonEnum as ReconnectionReason,
  SlackInfoUserTokenBehavioursEnum as BehaviourEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useMutation } from "src/utils/fetchData";
import { useQueryParams } from "src/utils/query-params";

import { IncidentFlameAvatar } from "../common/IncidentFlameAvatar";
import { ReactComponent as InstallArrowSvg } from "../common/install-arrow-2.svg";
import { InstallationStepID } from "../useSetupSteps";

const InstallBlurb = ({
  askForUserToken,
  wrongUserRole,
  reasonCode,
}: {
  askForUserToken: boolean;
  wrongUserRole: boolean;
  reasonCode: ReconnectionReason;
}): React.ReactElement | null => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  useEffect(() => {
    if (reasonCode === ReconnectionReason.MissingChannelCreatePermissions) {
      showArticle("4258864431");
    }
  }, [askForUserToken, reasonCode, showArticle]);

  switch (reasonCode) {
    case ReconnectionReason.MissingChannelCreatePermissions:
      return (
        <div className="space-y-2">
          <Callout
            theme={CalloutTheme.Danger}
            className="mb-4 text-left"
            title="Installation failed"
            subtitle="Your Slack workspace doesn’t allow bots to create channels, so we
            can’t complete our installation."
          />
          {wrongUserRole && (
            <Callout theme={CalloutTheme.Danger} className="mb-4">
              You must be logged in as a Slack admin or owner.
            </Callout>
          )}
          <Callout theme={CalloutTheme.Plain} showIcon={false} className="mb-4">
            We need to be able to create incident channels to give you a place
            to coordinate and communicate during the incident.
          </Callout>
        </div>
      );
    case ReconnectionReason.AnnouncementChannelsAlreadyExist:
      return (
        <div>
          <Callout
            theme={CalloutTheme.Danger}
            className="mb-4"
            title="Installation failed"
            subtitle={
              <>
                It looks like we were unable to complete our installation
                because channels{" "}
                <span className="font-semibold">#incidents</span> and{" "}
                <span className="font-semibold">#incident-io-incidents </span>
                already exist.
                <br />
                <br /> Please rename one of them to something else. You can
                rename the channel that we create after you complete the
                installation.
              </>
            }
          />
          <Callout
            theme={CalloutTheme.Plain}
            showIcon={false}
            className="flex flex-wrap gap-1 mb-4"
          >
            {"If you have any questions please email "}
            <a href="mailto:support@incident.io" className="underline">
              support@incident.io
            </a>
            .
          </Callout>
        </div>
      );
    default:
      return null;
  }
};

enum ResolveMissingPermission {
  ChangedPermissions = "changed_permissions",
  UseServiceAccount = "use_service_account",
}
type MissingPermissionFormData = {
  strategy: ResolveMissingPermission;
};

const UserTokenRequest = (): React.ReactElement => {
  const formMethods = useForm<MissingPermissionFormData>();
  const { setError, watch } = formMethods;
  const { identity, fetchIdentity } = useIdentity();
  const apiClient = useClient();

  const strategy = watch("strategy");
  const isConnecting = strategy === ResolveMissingPermission.UseServiceAccount;
  const [isOpen, setIsOpen] = useState(false);
  const [retryInstall, { saving: retryingInstall }] = useMutation(
    async () => {
      await apiClient.authRetryAnnouncementChannelsCreate();
      await fetchIdentity();
      setIsOpen(false);
    },
    {
      setError: (_, error) => {
        // There's only one place to put the error
        setError("strategy", error);
        setIsOpen(false); // ensure the modal is close so that user can see the error in the form
      },
    },
  );

  if (!identity) {
    return <Loader />;
  }

  return (
    <Form.Root formMethods={formMethods} onSubmit={() => setIsOpen(true)}>
      <ConnectModal
        isOpen={isOpen && isConnecting}
        onClose={() => setIsOpen(false)}
        identity={identity}
      />

      <Modal
        isOpen={isOpen && !isConnecting}
        onClose={() => setIsOpen(false)}
        title="Complete Slack installation"
        analyticsTrackingId={"resolve-permissions-retry"}
      >
        <ModalContent className="text-sm space-y-4">
          <p>
            We&apos;ll try to create the <code>#incidents</code> channel again.
          </p>
          <p className="text-center">
            <Button
              onClick={() => retryInstall({})}
              loading={retryingInstall}
              analyticsTrackingId="resolve-permissions-retry"
              theme={ButtonTheme.Primary}
            >
              Let&apos;s go
            </Button>
          </p>
        </ModalContent>
      </Modal>

      <div className="text-left">
        <RadioButtonGroupV2
          formMethods={formMethods}
          name="strategy"
          srLabel="How would you like to proceed?"
          required
          options={[
            {
              label: "I've changed channel creation permissions",
              value: ResolveMissingPermission.ChangedPermissions,
            },
            {
              label: "I'd like to use a Slack admin user",
              value: ResolveMissingPermission.UseServiceAccount,
            },
          ]}
        />
      </div>
      <Button
        disabled={!strategy}
        theme={ButtonTheme.Secondary}
        analyticsTrackingId="resolve-install"
        type="submit"
      >
        Continue
      </Button>
    </Form.Root>
  );
};

// SetupInstallSlack is the explanation and CTA for someone hitting the setup flow to
// install our app into Slack. If that's failed and there's an
// slack_reconnection_reason set, we will see text telling them what to do next
// (as defined in InstallBlurb).
export const SetupInstallSlack = (): React.ReactElement => {
  const { identity } = useIdentity();

  const queryParams = useQueryParams();
  const wrongUserRole = queryParams.get("message") === "user-token-bad-role";

  const askForUserToken = (
    identity.slack_info?.user_token_behaviours || []
  ).includes(BehaviourEnum.CreatePublicChannel);

  // For a non-Slack org, send them to the MS Teams setup flow
  const slackInfo = identity.slack_info;
  if (!slackInfo) {
    return <OrgAwareNavigate to="/setup/msteams" />;
  }

  const firstName = identity.user_name
    ? capitalize(identity.user_name.split(" ")[0])
    : "there";

  const shouldAnimate = !slackInfo.reconnection_reason;
  const transition = { duration: 2, delay: 0.2, times: [0, 0.4, 0.6, 0.9, 1] };

  return (
    <SetupPageWrapper currentStepID={InstallationStepID.Install}>
      <div className="flex flex-col gap-5">
        <motion.div
          className="font-semibold"
          animate={
            shouldAnimate
              ? {
                  y: ["100vh", "10vh", "10vh", "0", "0"],
                  fontSize: ["48px", "48px", "48px", "24px", "24px"],
                }
              : undefined
          }
          transition={transition}
        >
          Hey {firstName}
        </motion.div>
        <motion.div
          className="flex flex-col items-center justify-center gap-10"
          animate={
            shouldAnimate
              ? {
                  y: ["10vh", "10vh", "10vh", "0", "0"],
                  opacity: [0, 0, 0, 0.3, 1],
                }
              : undefined
          }
          transition={transition}
        >
          <div className="text-5xl-bold">Let’s add incident.io to Slack</div>
          <div className="h-[200px] w-[436px] bg-slate-800 rounded-3 flex items-center justify-center">
            <IncidentFlameAvatar />
            <div className="w-[78px] flex justify-center">
              <InstallArrowSvg />
            </div>
            <SlackTeamAvatar
              url={identity.organisation_icon_url}
              className="w-[60px] h-[60px] rounded-4 ml-2"
            />
          </div>

          <div className="w-[436px] mx-auto text-left flex flex-col gap-2">
            <InstallBlurb
              reasonCode={slackInfo.reconnection_reason}
              askForUserToken={askForUserToken}
              wrongUserRole={wrongUserRole}
            />
            {askForUserToken ? (
              <UserTokenRequest />
            ) : (
              <AddToSlackButton
                url={slackInfo.install_url}
                returnPath={`/${identity.organisation_slug}/setup`}
              />
            )}
          </div>
          <div className="text-slate-400 text-xs -mt-5">
            We only ask for Slack permissions we need to do our job.
            <br />
            See our{" "}
            <Button
              href="https://help.incident.io/collections/1827313954-security-faqs"
              theme={ButtonTheme.Unstyled}
              className="text-white"
              openInNewTab
              analyticsTrackingId={"install-security-faqs"}
            >
              Security FAQs
            </Button>{" "}
            for more detail.
          </div>
        </motion.div>
      </div>
    </SetupPageWrapper>
  );
};
