import { Product } from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { UpsellBadge } from "@incident-shared/settings";
import { ButtonTheme, ContentBox, Heading, Tooltip, Txt } from "@incident-ui";
import { HeadingLevel } from "@incident-ui/Heading/Heading";
import { kebabCase } from "lodash";
import React from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { usePrimaryCommsPlatform } from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";
import { UseAPIMutationReturn } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { slugify } from "src/utils/utils";

type SettingsSectionProps<TFormType extends FieldValues, TFetchResponse> = {
  formMethods: UseFormReturn<TFormType>;
  mutation: UseAPIMutationReturn<TFormType, TFetchResponse>;

  // The title of the section
  title: string;
  // The heading level for the section title (defaults to 2)
  titleHeadingLevel?: HeadingLevel;
  // An accessory to the title
  titleAccessory?: React.ReactNode;
  // An explanation of the section, which is always rendered (even if disabled)
  explanation: React.ReactNode;
  // Controls the text on the submit button: defaults to 'Save'
  submitText?: string;

  // You can provide an override value to isDirty: by default we'll use the value from formMethods.
  isDirty?: boolean;

  // These control whether a 'New' badge, or a 'Pro/Enterprise' badge is shown next to the title.
  newUntil?: Date;
  isPro?: boolean;
  isEnterprise?: boolean;

  // This controls whether the enabled toggle and submit button are gated. It defaults to
  // OrganisationSettingsUpdate.
  requiredScope?: ScopeNameEnum;
  // This controls whether the enabled toggle and submit button are gated. It defaults to
  // not requiring any products.
  requiredProduct?: Product;
  // This controls whether the toggled is disabled entirely (for some other reason
  // than not having the required scopes).
  disabled?: boolean;
  // If present, the section will be rendered with an 'enabled' toggle, and
  // this will be the path in the form that controls it.
  enabledPath?: Path<TFormType>;

  // If present, these will be rendered (if the section is enabled).
  children?: React.ReactNode;
};

export const SettingsSection = <
  TFormType extends FieldValues,
  TMutationResponse,
>(
  props: SettingsSectionProps<TFormType, TMutationResponse>,
): React.ReactElement => {
  const {
    title: heading,
    titleAccessory,
    children,
    requiredScope = ScopeNameEnum.OrganisationSettingsUpdate,
    requiredProduct,
    enabledPath,
    formMethods,
    mutation,
  } = props;
  const commsPlatform = usePrimaryCommsPlatform();
  const { hasScope } = useIdentity();
  const hasRequiredScope = hasScope(requiredScope);
  const { hasProduct } = useProductAccess();
  const hasRequiredProduct = requiredProduct
    ? hasProduct(requiredProduct)
    : true;

  const enabled = enabledPath ? formMethods.watch(enabledPath) : true;

  // This tries quite hard to avoid conflicting on the id: if you (e.g.) have
  // multiple of these on the same page that use config.enabled as their path,
  // you share an ID and it gets quite sad.
  const id = `settings-toggle-${slugify(heading)}`;

  const isDirty =
    props.isDirty === undefined ? formMethods.formState.isDirty : props.isDirty;

  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={mutation.trigger}
      genericError={mutation.genericError}
      saving={mutation.isMutating}
    >
      <ContentBox className="p-6">
        <div className="flex-center-y">
          {/* 1. Toggle */}
          {!!enabledPath && (
            <div className="mt-1 shrink-0 mr-2 lg:text-left md:mr-6 mb-auto">
              <ToggleV2
                formMethods={formMethods}
                name={enabledPath}
                idOverride={id}
                labelledById={kebabCase(heading)}
                disabled={
                  !hasRequiredProduct || !hasRequiredScope || props.disabled
                }
                disabledTooltipContent={
                  !hasRequiredProduct && requiredProduct ? (
                    <ProductRequiredMessage
                      requiredProduct={requiredProduct}
                      commsPlatform={commsPlatform}
                    />
                  ) : null
                }
              />
            </div>
          )}
          <div className="grow min-w-0">
            <SettingsSectionHeader
              {...props}
              requiredScope={requiredScope}
              requiredProduct={requiredProduct}
              isDirty={isDirty}
              titleAccessory={titleAccessory}
            />
            {children && enabled ? (
              <div className="mt-2">{children}</div>
            ) : null}
          </div>
        </div>
      </ContentBox>
    </Form.Root>
  );
};

const SettingsSectionHeader = ({
  title,
  titleAccessory,
  submitText = "Save",
  explanation,
  toggletipText,
  isDirty,
  isPro,
  isEnterprise,
  newUntil,
  requiredScope,
  requiredProduct,
  titleHeadingLevel = 2,
}: {
  submitText?: string;
  title: string;
  titleAccessory?: React.ReactNode;
  isDirty: boolean;
  explanation: string | React.ReactNode;
  toggletipText?: string;
  requiredScope?: ScopeNameEnum;
  requiredProduct?: Product;
  isPro?: boolean;
  isEnterprise?: boolean;
  newUntil?: Date;
  titleHeadingLevel?: HeadingLevel;
}): React.ReactElement => {
  return (
    <div className="flex justify-between items-start space-x-4">
      {/* 1. Title and explanation */}
      <div className="grow max-w-2xl min-w-0">
        <Heading
          level={titleHeadingLevel}
          id={kebabCase(title)}
          className={tcx({
            "!text-sm": titleHeadingLevel === 3,
          })}
        >
          {/* align vertically */}
          {title}
          {titleAccessory}{" "}
          <UpsellBadge
            isPro={isPro}
            isEnterprise={isEnterprise}
            newUntil={newUntil}
          />
          {toggletipText && (
            <div className="ml-2 inline">
              <Tooltip
                content={toggletipText}
                analyticsTrackingId={null}
                bubbleProps={{ className: "!w-96" }}
              />
            </div>
          )}
        </Heading>
        <Txt grey className="mt-1 mb-2 max-w-[75ch]">
          {explanation}
        </Txt>
      </div>
      {/* 2. Save button */}
      <GatedButton
        type="submit"
        analyticsTrackingId={null}
        className="mb-6 md:mb-0 mt-4 md:mt-0"
        disabled={!isDirty}
        theme={ButtonTheme.Primary}
        requiredScope={requiredScope}
        requiredProduct={requiredProduct}
      >
        {submitText}
      </GatedButton>
    </div>
  );
};
