/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertActivityOverview,
    AlertActivityOverviewFromJSON,
    AlertActivityOverviewFromJSONTyped,
    AlertActivityOverviewToJSON,
} from './AlertActivityOverview';
import {
    AlertDeclineRateOverview,
    AlertDeclineRateOverviewFromJSON,
    AlertDeclineRateOverviewFromJSONTyped,
    AlertDeclineRateOverviewToJSON,
} from './AlertDeclineRateOverview';
import {
    AlertWorkloadOverview,
    AlertWorkloadOverviewFromJSON,
    AlertWorkloadOverviewFromJSONTyped,
    AlertWorkloadOverviewToJSON,
} from './AlertWorkloadOverview';

/**
 * 
 * @export
 * @interface AlertsGetInsightsOverviewResponseBody
 */
export interface AlertsGetInsightsOverviewResponseBody {
    /**
     * 
     * @type {AlertActivityOverview}
     * @memberof AlertsGetInsightsOverviewResponseBody
     */
    activity: AlertActivityOverview;
    /**
     * 
     * @type {AlertDeclineRateOverview}
     * @memberof AlertsGetInsightsOverviewResponseBody
     */
    decline_rate: AlertDeclineRateOverview;
    /**
     * The timestamp that this data is valid from - note that we cache insights queries so this may be earlier than GetLastSync reports
     * @type {Date}
     * @memberof AlertsGetInsightsOverviewResponseBody
     */
    last_sync_at: Date;
    /**
     * 
     * @type {AlertWorkloadOverview}
     * @memberof AlertsGetInsightsOverviewResponseBody
     */
    workload: AlertWorkloadOverview;
}

export function AlertsGetInsightsOverviewResponseBodyFromJSON(json: any): AlertsGetInsightsOverviewResponseBody {
    return AlertsGetInsightsOverviewResponseBodyFromJSONTyped(json, false);
}

export function AlertsGetInsightsOverviewResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsGetInsightsOverviewResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activity': AlertActivityOverviewFromJSON(json['activity']),
        'decline_rate': AlertDeclineRateOverviewFromJSON(json['decline_rate']),
        'last_sync_at': (new Date(json['last_sync_at'])),
        'workload': AlertWorkloadOverviewFromJSON(json['workload']),
    };
}

export function AlertsGetInsightsOverviewResponseBodyToJSON(value?: AlertsGetInsightsOverviewResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity': AlertActivityOverviewToJSON(value.activity),
        'decline_rate': AlertDeclineRateOverviewToJSON(value.decline_rate),
        'last_sync_at': (value.last_sync_at.toISOString()),
        'workload': AlertWorkloadOverviewToJSON(value.workload),
    };
}

