import { IncidentCallExternalProviderEnum } from "@incident-io/api";
import { IconEnum } from "@incident-ui";

import GoogleMeetLogo from "./google_meet_logo.svg";
import ZoomLogo from "./zoom_logo.svg";

export const getProviderNameAndLogo = (
  provider: string,
): {
  name: string;
  logo: string;
  icon: IconEnum;
} => {
  switch (provider as IncidentCallExternalProviderEnum) {
    case IncidentCallExternalProviderEnum.Zoom:
      return { name: "Zoom", logo: ZoomLogo, icon: IconEnum.Zoom };
    case IncidentCallExternalProviderEnum.GoogleMeet:
      return {
        name: "Google Meet",
        logo: GoogleMeetLogo,
        icon: IconEnum.GoogleMeet,
      };
    case IncidentCallExternalProviderEnum.MicrosoftTeamsOnlineMeetings:
      return {
        name: "Microsoft Teams",
        logo: "",
        icon: IconEnum.MicrosoftTeams,
      };
    case IncidentCallExternalProviderEnum.Other:
      return { name: "Incident", logo: "", icon: IconEnum.Call };
    default:
      return { name: "Incident", logo: "", icon: IconEnum.Call };
  }
};
