import { Handle, Position } from "reactflow";
import { tcx } from "src/utils/tailwind-classes";

import { useReduceOpacity } from "./AlertsConfigurationPage";

export enum AlertsConfigurationNodeType {
  NoAlertRoutes = "no_alert_routes",
  AlertSource = "alert_source",
  AlertRoute = "alert_route",
}

export const AlertsConfigurationNodeWrapper = ({
  id,
  children,
}: {
  id?: string;
  children: React.ReactNode;
}) => {
  const reduceOpacity = useReduceOpacity({ nodeId: id ?? "" });

  return (
    <div
      className={tcx(
        "cursor-default",
        "duration-200 ease-in-out transition-opacity",
        reduceOpacity && "opacity-20",
      )}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{
          opacity: 0,
          left: 10,
          cursor: "default",
          pointerEvents: "none",
        }}
      />
      {children}
      <Handle
        type="source"
        position={Position.Right}
        style={{
          opacity: 0,
          right: 10,
          cursor: "default",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};
