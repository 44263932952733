/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuggestionConfig
 */
export interface SuggestionConfig {
    /**
     * Flag to enable/disable auto-renaming incidents
     * @type {boolean}
     * @memberof SuggestionConfig
     */
    auto_rename_incidents: boolean;
    /**
     * Global kill switch for all suggestions
     * @type {boolean}
     * @memberof SuggestionConfig
     */
    enabled: boolean;
    /**
     * Flag to enable/disable name suggestions
     * @type {boolean}
     * @memberof SuggestionConfig
     */
    suggest_names_enabled: boolean;
    /**
     * Flag to enable/disable role suggestions
     * @type {boolean}
     * @memberof SuggestionConfig
     */
    suggest_roles_enabled: boolean;
    /**
     * Flag to enable/disable update suggestions
     * @type {boolean}
     * @memberof SuggestionConfig
     */
    suggest_updates_enabled: boolean;
}

export function SuggestionConfigFromJSON(json: any): SuggestionConfig {
    return SuggestionConfigFromJSONTyped(json, false);
}

export function SuggestionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuggestionConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_rename_incidents': json['auto_rename_incidents'],
        'enabled': json['enabled'],
        'suggest_names_enabled': json['suggest_names_enabled'],
        'suggest_roles_enabled': json['suggest_roles_enabled'],
        'suggest_updates_enabled': json['suggest_updates_enabled'],
    };
}

export function SuggestionConfigToJSON(value?: SuggestionConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_rename_incidents': value.auto_rename_incidents,
        'enabled': value.enabled,
        'suggest_names_enabled': value.suggest_names_enabled,
        'suggest_roles_enabled': value.suggest_roles_enabled,
        'suggest_updates_enabled': value.suggest_updates_enabled,
    };
}

