/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PromptHealthReport,
    PromptHealthReportFromJSON,
    PromptHealthReportFromJSONTyped,
    PromptHealthReportToJSON,
} from './PromptHealthReport';

/**
 * 
 * @export
 * @interface AIStaffListPromptHealthReportsResponseBody
 */
export interface AIStaffListPromptHealthReportsResponseBody {
    /**
     * 
     * @type {Array<PromptHealthReport>}
     * @memberof AIStaffListPromptHealthReportsResponseBody
     */
    health_reports: Array<PromptHealthReport>;
}

export function AIStaffListPromptHealthReportsResponseBodyFromJSON(json: any): AIStaffListPromptHealthReportsResponseBody {
    return AIStaffListPromptHealthReportsResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListPromptHealthReportsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListPromptHealthReportsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'health_reports': ((json['health_reports'] as Array<any>).map(PromptHealthReportFromJSON)),
    };
}

export function AIStaffListPromptHealthReportsResponseBodyToJSON(value?: AIStaffListPromptHealthReportsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'health_reports': ((value.health_reports as Array<any>).map(PromptHealthReportToJSON)),
    };
}

