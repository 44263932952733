/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentSuggestionsGetSuggestionConfigResponseBody,
    IncidentSuggestionsGetSuggestionConfigResponseBodyFromJSON,
    IncidentSuggestionsGetSuggestionConfigResponseBodyToJSON,
    IncidentSuggestionsListSuggestionsResponseBody,
    IncidentSuggestionsListSuggestionsResponseBodyFromJSON,
    IncidentSuggestionsListSuggestionsResponseBodyToJSON,
    IncidentSuggestionsShowSuggestionResponseBody,
    IncidentSuggestionsShowSuggestionResponseBodyFromJSON,
    IncidentSuggestionsShowSuggestionResponseBodyToJSON,
    IncidentSuggestionsUpdateSuggestionConfigRequestBody,
    IncidentSuggestionsUpdateSuggestionConfigRequestBodyFromJSON,
    IncidentSuggestionsUpdateSuggestionConfigRequestBodyToJSON,
    IncidentSuggestionsUpdateSuggestionConfigResponseBody,
    IncidentSuggestionsUpdateSuggestionConfigResponseBodyFromJSON,
    IncidentSuggestionsUpdateSuggestionConfigResponseBodyToJSON,
} from '../models';

export interface IncidentSuggestionsListSuggestionsRequest {
    incidentId?: string;
    suggestionType?: Array<string>;
    includeArchived?: boolean;
    convictions?: Array<string>;
    nudged?: boolean;
    resourceId?: string;
}

export interface IncidentSuggestionsShowSuggestionRequest {
    id: string;
}

export interface IncidentSuggestionsUpdateSuggestionConfigRequest {
    updateSuggestionConfigRequestBody: IncidentSuggestionsUpdateSuggestionConfigRequestBody;
}

/**
 * 
 */
export class IncidentSuggestionsApi extends runtime.BaseAPI {

    /**
     * Get the suggestion configuration for this org
     * GetSuggestionConfig IncidentSuggestions
     */
    async incidentSuggestionsGetSuggestionConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSuggestionsGetSuggestionConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_suggestions/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSuggestionsGetSuggestionConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the suggestion configuration for this org
     * GetSuggestionConfig IncidentSuggestions
     */
    async incidentSuggestionsGetSuggestionConfig(initOverrides?: RequestInit): Promise<IncidentSuggestionsGetSuggestionConfigResponseBody> {
        const response = await this.incidentSuggestionsGetSuggestionConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all available suggestions for this incident
     * ListSuggestions IncidentSuggestions
     */
    async incidentSuggestionsListSuggestionsRaw(requestParameters: IncidentSuggestionsListSuggestionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSuggestionsListSuggestionsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        if (requestParameters.suggestionType) {
            queryParameters['suggestion_type'] = requestParameters.suggestionType;
        }

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['include_archived'] = requestParameters.includeArchived;
        }

        if (requestParameters.convictions) {
            queryParameters['convictions'] = requestParameters.convictions;
        }

        if (requestParameters.nudged !== undefined) {
            queryParameters['nudged'] = requestParameters.nudged;
        }

        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource_id'] = requestParameters.resourceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSuggestionsListSuggestionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all available suggestions for this incident
     * ListSuggestions IncidentSuggestions
     */
    async incidentSuggestionsListSuggestions(requestParameters: IncidentSuggestionsListSuggestionsRequest, initOverrides?: RequestInit): Promise<IncidentSuggestionsListSuggestionsResponseBody> {
        const response = await this.incidentSuggestionsListSuggestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single suggestion by ID
     * ShowSuggestion IncidentSuggestions
     */
    async incidentSuggestionsShowSuggestionRaw(requestParameters: IncidentSuggestionsShowSuggestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSuggestionsShowSuggestionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentSuggestionsShowSuggestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_suggestions/suggestions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSuggestionsShowSuggestionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a single suggestion by ID
     * ShowSuggestion IncidentSuggestions
     */
    async incidentSuggestionsShowSuggestion(requestParameters: IncidentSuggestionsShowSuggestionRequest, initOverrides?: RequestInit): Promise<IncidentSuggestionsShowSuggestionResponseBody> {
        const response = await this.incidentSuggestionsShowSuggestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the suggestion configuration for this org
     * UpdateSuggestionConfig IncidentSuggestions
     */
    async incidentSuggestionsUpdateSuggestionConfigRaw(requestParameters: IncidentSuggestionsUpdateSuggestionConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentSuggestionsUpdateSuggestionConfigResponseBody>> {
        if (requestParameters.updateSuggestionConfigRequestBody === null || requestParameters.updateSuggestionConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateSuggestionConfigRequestBody','Required parameter requestParameters.updateSuggestionConfigRequestBody was null or undefined when calling incidentSuggestionsUpdateSuggestionConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident_suggestions/config`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentSuggestionsUpdateSuggestionConfigRequestBodyToJSON(requestParameters.updateSuggestionConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentSuggestionsUpdateSuggestionConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the suggestion configuration for this org
     * UpdateSuggestionConfig IncidentSuggestions
     */
    async incidentSuggestionsUpdateSuggestionConfig(requestParameters: IncidentSuggestionsUpdateSuggestionConfigRequest, initOverrides?: RequestInit): Promise<IncidentSuggestionsUpdateSuggestionConfigResponseBody> {
        const response = await this.incidentSuggestionsUpdateSuggestionConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
