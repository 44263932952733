/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    EngineParam,
    EngineParamFromJSON,
    EngineParamFromJSONTyped,
    EngineParamToJSON,
} from './EngineParam';
import {
    EngineParamBinding,
    EngineParamBindingFromJSON,
    EngineParamBindingFromJSONTyped,
    EngineParamBindingToJSON,
} from './EngineParamBinding';
import {
    NudgePreviewDefinition,
    NudgePreviewDefinitionFromJSON,
    NudgePreviewDefinitionFromJSONTyped,
    NudgePreviewDefinitionToJSON,
} from './NudgePreviewDefinition';
import {
    NudgeWorkflowButton,
    NudgeWorkflowButtonFromJSON,
    NudgeWorkflowButtonFromJSONTyped,
    NudgeWorkflowButtonToJSON,
} from './NudgeWorkflowButton';

/**
 * 
 * @export
 * @interface Nudge
 */
export interface Nudge {
    /**
     * What condition groups must be met for this nudge to run?
     * @type {Array<ConditionGroup>}
     * @memberof Nudge
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * How long after the conditions apply should this nudge run for the first time?
     * @type {number}
     * @memberof Nudge
     */
    delay_for_seconds: number;
    /**
     * If present, when the nudge was last disabled
     * @type {Date}
     * @memberof Nudge
     */
    disabled_at?: Date;
    /**
     * Is this nudge currently enabled?
     * @type {boolean}
     * @memberof Nudge
     */
    enabled: boolean;
    /**
     * What fixed condition groups must be met for this nudge to run?
     * @type {Array<ConditionGroup>}
     * @memberof Nudge
     */
    fixed_conditions: Array<ConditionGroup>;
    /**
     * String telling the UI what icon to use for this field
     * @type {string}
     * @memberof Nudge
     */
    icon: NudgeIconEnum;
    /**
     * Unique identifier of this nudge
     * @type {string}
     * @memberof Nudge
     */
    id: string;
    /**
     * Whether the nudge should run in intelligent mode or not
     * @type {boolean}
     * @memberof Nudge
     */
    intelligent_mode: boolean;
    /**
     * What is the maximum number of times this nudge should run?
     * @type {number}
     * @memberof Nudge
     */
    maximum_nudge_run_count?: number;
    /**
     * 
     * @type {EngineParamBinding}
     * @memberof Nudge
     */
    message_content: EngineParamBinding;
    /**
     * Human readable the nudge
     * @type {string}
     * @memberof Nudge
     */
    name: string;
    /**
     * What type of nudge is this
     * @type {string}
     * @memberof Nudge
     */
    nudge_type: NudgeNudgeTypeEnum;
    /**
     * Any param bindings used to define the nudge in more detail
     * @type {Array<EngineParamBinding>}
     * @memberof Nudge
     */
    param_bindings: Array<EngineParamBinding>;
    /**
     * Type information for the step parameters
     * @type {Array<EngineParam>}
     * @memberof Nudge
     */
    params: Array<EngineParam>;
    /**
     * 
     * @type {NudgePreviewDefinition}
     * @memberof Nudge
     */
    preview: NudgePreviewDefinition;
    /**
     * How long after the nudge runs should it run again?
     * @type {number}
     * @memberof Nudge
     */
    repeat_after_seconds: number;
    /**
     * The buttons on the message, if it's a workflow nudge
     * @type {Array<NudgeWorkflowButton>}
     * @memberof Nudge
     */
    workflow_buttons?: Array<NudgeWorkflowButton>;
}

/**
* @export
* @enum {string}
*/
export enum NudgeIconEnum {
    Action = 'action',
    Alert = 'alert',
    Announcement = 'announcement',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Chart = 'chart',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentStatusDeclined = 'incident-status-declined',
    IncidentStatusMerged = 'incident-status-merged',
    IncidentType = 'incident_type',
    Key = 'key',
    Lock = 'lock',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    OnCall = 'on-call',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackGreyscale = 'slack-greyscale',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Sparkles = 'sparkles',
    SparklesAiGradient = 'sparkles-ai-gradient',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}/**
* @export
* @enum {string}
*/
export enum NudgeNudgeTypeEnum {
    Inactivity = 'inactivity',
    SendUpdate = 'send-update',
    Summary = 'summary',
    Triage = 'triage',
    CreateStatuspage = 'create-statuspage',
    UpdateStatuspage = 'update-statuspage',
    CustomField = 'custom-field',
    AssignRole = 'assign-role',
    IncidentTimestamp = 'incident-timestamp',
    CreateStream = 'create-stream',
    RunWorkflows = 'run-workflows'
}

export function NudgeFromJSON(json: any): Nudge {
    return NudgeFromJSONTyped(json, false);
}

export function NudgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Nudge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'delay_for_seconds': json['delay_for_seconds'],
        'disabled_at': !exists(json, 'disabled_at') ? undefined : (new Date(json['disabled_at'])),
        'enabled': json['enabled'],
        'fixed_conditions': ((json['fixed_conditions'] as Array<any>).map(ConditionGroupFromJSON)),
        'icon': json['icon'],
        'id': json['id'],
        'intelligent_mode': json['intelligent_mode'],
        'maximum_nudge_run_count': !exists(json, 'maximum_nudge_run_count') ? undefined : json['maximum_nudge_run_count'],
        'message_content': EngineParamBindingFromJSON(json['message_content']),
        'name': json['name'],
        'nudge_type': json['nudge_type'],
        'param_bindings': ((json['param_bindings'] as Array<any>).map(EngineParamBindingFromJSON)),
        'params': ((json['params'] as Array<any>).map(EngineParamFromJSON)),
        'preview': NudgePreviewDefinitionFromJSON(json['preview']),
        'repeat_after_seconds': json['repeat_after_seconds'],
        'workflow_buttons': !exists(json, 'workflow_buttons') ? undefined : ((json['workflow_buttons'] as Array<any>).map(NudgeWorkflowButtonFromJSON)),
    };
}

export function NudgeToJSON(value?: Nudge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'delay_for_seconds': value.delay_for_seconds,
        'disabled_at': value.disabled_at === undefined ? undefined : (value.disabled_at.toISOString()),
        'enabled': value.enabled,
        'fixed_conditions': ((value.fixed_conditions as Array<any>).map(ConditionGroupToJSON)),
        'icon': value.icon,
        'id': value.id,
        'intelligent_mode': value.intelligent_mode,
        'maximum_nudge_run_count': value.maximum_nudge_run_count,
        'message_content': EngineParamBindingToJSON(value.message_content),
        'name': value.name,
        'nudge_type': value.nudge_type,
        'param_bindings': ((value.param_bindings as Array<any>).map(EngineParamBindingToJSON)),
        'params': ((value.params as Array<any>).map(EngineParamToJSON)),
        'preview': NudgePreviewDefinitionToJSON(value.preview),
        'repeat_after_seconds': value.repeat_after_seconds,
        'workflow_buttons': value.workflow_buttons === undefined ? undefined : ((value.workflow_buttons as Array<any>).map(NudgeWorkflowButtonToJSON)),
    };
}

