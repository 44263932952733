import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  ButtonTheme,
  Card,
  FullPageEmptyState,
  IconEnum,
  IconSize,
} from "@incident-ui";

import { ReactComponent as Graphic } from "./empty-state.svg";

export const StatusPagesListEmptyState = () => {
  return (
    <FullPageEmptyState
      title="Keep your users in the loop"
      graphic={<Graphic />}
      subtitle="With our status pages, it's never been easier to make sure your users know what's going on"
      cta={
        <GatedButton
          analyticsTrackingId="create-status-page"
          theme={ButtonTheme.Primary}
          href="/status-pages/create"
          requiredScope={ScopeNameEnum.StatusPagesConfigure}
          disabledTooltipContent={
            "You don't have permission to configure status pages"
          }
        >
          Get started
        </GatedButton>
      }
      helpcenterArticleId={"8552190577"}
      gradientClasses="from-alarmalade-50 to-white from-20% via-80%"
      className="w-full"
      cards={
        <>
          <Card
            className="h-full"
            icon={IconEnum.Globe}
            iconSize={IconSize.Medium}
            title="Public status pages"
            description="Effortlessly communicate real-time status updates publicly to all your users"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.Users}
            iconSize={IconSize.Medium}
            title="Customer status pages"
            description="Securely provide private status updates to each of your customers"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.LockClosed}
            iconSize={IconSize.Medium}
            title="Internal status pages"
            description="Share active status updates with your internal employees"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
        </>
      }
    />
  );
};
