import {
  CustomField,
  CustomFieldRequiredV2Enum,
  PostIncidentTask,
  PostIncidentTaskConfigSlimTaskTypeEnum as ConfigTaskTypeEnum,
  PostIncidentTaskOptionTaskTypeEnum,
  PostIncidentTaskSlim,
  PostmortemsUpdateStatusRequestBodyStatusEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { CreatePostMortemModal } from "@incident-shared/postmortems/CreatePostMortemModal";
import { ExportPostmortemDrawer } from "@incident-shared/postmortems/ExportPostmortemDrawer";
import { SharePostMortemModal } from "@incident-shared/postmortems/SharePostMortemModal";
import {
  BadgeSize,
  ButtonTheme,
  GatedDropdownMenuItem,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash";
import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";
import {
  Incident,
  PostmortemsSetDocumentStatusRequestBodyStatusEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useDebriefName, usePostmortemName } from "src/utils/postmortem-name";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { BodyTabs } from "../body/IncidentBody";
import { useSummaryContext } from "../body/SummaryContext";
import { ScheduleDebriefModal } from "../debriefs/ScheduleDebriefModal";
import { EditRoleAssignmentsModal } from "../header/EditRoleAssignmentsModal";
import { EditTimestampsModal } from "../header/EditTimestampsModal";
import { GiveShoutoutModal } from "../header/GiveShoutoutModal";
import { useIncident } from "../hooks";
import { EditSpecificCustomFieldEntriesModal } from "../sidebar/EditCustomFieldEntriesModal";
import { usePostmortem } from "./usePostmortem";

type ActionMode = "dropdown" | "button";

export enum PostIncidentActionModal {
  CreatePostMortem = "create-post-mortem",
  ExportPostMortem = "export-post-mortem",
  SharePostMortem = "share-post-mortem",
  ScheduleDebrief = "schedule-debrief",
  EditRoleAssignments = "edit-role-assignments",
  EditCustomFieldEntries = "edit-custom-field-entries",
  EditTimestamps = "edit-timestamps",
  GiveShoutout = "give-shoutout",
}

type PostIncidentModalProps = {
  incident: Incident;
  shownRoleIds?: string[];
  filterToCustomFieldIDs?: string[];
  filterToTimestampIds?: string[];
  allCustomFields: CustomField[];
  onTaskComplete: () => void;
};

export const PostIncidentModalConfigs: {
  [key in PostIncidentActionModal]: {
    render: FunctionComponent<
      PostIncidentModalProps & {
        onClose: () => void;
      }
    >;
    isDrawer?: boolean;
  };
} = {
  [PostIncidentActionModal.CreatePostMortem]: { render: CreatePostMortemModal },
  [PostIncidentActionModal.ExportPostMortem]: {
    render: ExportPostmortemDrawer,
    isDrawer: true,
  },
  [PostIncidentActionModal.SharePostMortem]: { render: SharePostMortemModal },
  [PostIncidentActionModal.ScheduleDebrief]: { render: ScheduleDebriefModal },
  [PostIncidentActionModal.EditRoleAssignments]: {
    render: EditRoleAssignmentsModal,
  },
  [PostIncidentActionModal.EditCustomFieldEntries]: {
    render: EditSpecificCustomFieldEntriesModal,
  },
  [PostIncidentActionModal.EditTimestamps]: { render: EditTimestampsModal },
  [PostIncidentActionModal.GiveShoutout]: { render: GiveShoutoutModal },
};

type PostIncidentModalContextType = {
  showingModal: ActionModalState;
  setShowingModal: (a: ActionModalState) => void;
};

// PostIncidentModalContext allows other children of the page to open
// modals and drawers from the PINC flow.
export const PostIncidentModalContext =
  createContext<PostIncidentModalContextType>({
    showingModal: null,
    setShowingModal: () => null,
  });

export const PostIncidentModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [showingModal, setShowingModal] = useState<ActionModalState>(null);

  return (
    <PostIncidentModalContext.Provider
      value={{
        showingModal,
        setShowingModal,
      }}
    >
      {children}
    </PostIncidentModalContext.Provider>
  );
};

type ActionModalState = {
  modal: PostIncidentActionModal;
  props: PostIncidentModalProps;
} | null;

export const usePostIncidentModalContext = (): {
  showingModal: ActionModalState;
  setShowingModal: (a: ActionModalState) => void;
} => {
  const context = useContext(PostIncidentModalContext);
  if (!context) {
    throw new Error(
      `usePostIncidentModalContext must be used as a child of a PostIncidentModalContext provider`,
    );
  }

  return context;
};

export const PostIncidentActionModals = () => {
  const { showingModal, setShowingModal } = usePostIncidentModalContext();

  if (!showingModal) {
    return null;
  }

  const Modal = PostIncidentModalConfigs[showingModal.modal].render;

  return (
    <Modal onClose={() => setShowingModal(null)} {...showingModal.props} />
  );
};

type ItemProps = {
  incident: Incident;
  allCustomFields: CustomField[];
  incidentTask: PostIncidentTaskSlim | PostIncidentTask;
  mode: ActionMode;
  onError: (error: string) => void;
  onTaskComplete: () => void;
};

const ButtonOrDropdownItem = ({
  mode,
  label,
  icon,
  analyticsTrackingId,
  incidentTask,
  disabled,
  disabledExplanation,
  requiredScope,
  onClick,
}: {
  mode: ActionMode;
  label: string;
  icon: IconEnum;
  analyticsTrackingId: string;
  incidentTask: PostIncidentTaskSlim | PostIncidentTask;
  disabled?: boolean;
  onClick: () => void;
  disabledExplanation?: string;
  requiredScope?: ScopeNameEnum;
}) => {
  if (mode === "dropdown") {
    return (
      <GatedDropdownMenuItem
        onSelect={onClick}
        analyticsTrackingId={analyticsTrackingId}
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        icon={icon}
        label={label}
        disabled={disabled}
        requiredScope={requiredScope}
        explanationText={disabledExplanation}
      />
    );
  }

  return (
    <GatedButton
      onClick={onClick}
      theme={ButtonTheme.Primary}
      requiredScope={requiredScope}
      size={BadgeSize.Small}
      icon={icon}
      analyticsTrackingId={analyticsTrackingId}
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      disabled={disabled}
      disabledTooltipContent={disabledExplanation}
    >
      {label}
    </GatedButton>
  );
};

const CreatePostMortemItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(props.incident);

  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();
  const { setShowingModal } = usePostIncidentModalContext();

  return (
    <>
      <ButtonOrDropdownItem
        mode={mode}
        incidentTask={incidentTask}
        onClick={() =>
          setShowingModal({
            modal: featurePostmortemsInHouse
              ? PostIncidentActionModal.ExportPostMortem
              : PostIncidentActionModal.CreatePostMortem,
            props,
          })
        }
        analyticsTrackingId="post-incident-task-create-postmortem"
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.CreatePostmortem,
        )}
        label={
          featurePostmortemsInHouse
            ? `Export ${postmortemNameFormatted}`
            : `Create ${postmortemNameFormatted}`
        }
      />
    </>
  );
};

const SharePostMortemItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const { postmortemName, postmortemNameFormatted } = usePostmortemName(
    props.incident,
  );
  const { setShowingModal } = usePostIncidentModalContext();

  const { availableDocument: document } = usePostmortem(props.incident);
  const disabled = !document;

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.SharePostMortem,
          props,
        })
      }
      analyticsTrackingId="post-incident-task-share-postmortem"
      disabled={disabled}
      disabledExplanation={`${postmortemName} has not been created yet`}
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.SharePostmortem)}
      label={`Share ${postmortemNameFormatted}`}
    />
  );
};

const ScheduleDebriefItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const { debriefNameLower } = useDebriefName();
  const { setShowingModal } = usePostIncidentModalContext();

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      analyticsTrackingId="post-incident-task-schedule-debrief"
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.ScheduleDebrief,
          props,
        })
      }
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.ScheduleDebrief)}
      label={`Schedule ${debriefNameLower}`}
    />
  );
};

const AssignRoleItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const { setShowingModal } = usePostIncidentModalContext();
  const roleId = incidentTask.config.incident_role_id;

  if (!roleId) {
    throw new Error("found assign role task with no roleId set");
  }

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.EditRoleAssignments,
          props: { ...props, shownRoleIds: [roleId] },
        })
      }
      analyticsTrackingId="post-incident-task-assign-role"
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.AssignRole)}
      label="Assign role"
    />
  );
};

const SetCustomFieldsItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const applicableIDs = incidentTask.config.custom_field_ids ?? [];
  const { setShowingModal } = usePostIncidentModalContext();

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.EditCustomFieldEntries,
          props: { ...props, filterToCustomFieldIDs: applicableIDs },
        })
      }
      analyticsTrackingId="post-incident-task-assign-role"
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.SetCustomFields)}
      label="Update custom fields"
    />
  );
};

const CompletePostMortemItem = ({
  incident,
  incidentTask,
  onTaskComplete,
  onError,
  mode,
}: ItemProps): React.ReactElement => {
  const showToast = useToast();

  const { postmortemName, postmortemNameFormatted } =
    usePostmortemName(incident);

  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();

  const { availableDocument: document } = usePostmortem(incident);
  const disabled = !document && !featurePostmortemsInHouse;

  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );
  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });

  // This should be ripped out when we delete the feature flagged code.
  const {
    trigger: completePostmortemLegacy,
    isMutating: isMutatingLegacy,
    genericError: errorLegacy,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident?.id ?? "" },
    async (
      apiClient,
      {
        id,
      }: {
        id: string;
      },
    ) => {
      await apiClient.postmortemsSetDocumentStatus({
        id,
        setDocumentStatusRequestBody: {
          status: PostmortemsSetDocumentStatusRequestBodyStatusEnum.Complete,
        },
      });

      await refetchPostIncidentFlowTasks();

      // Don't bother waiting for this
      refetchIncident();
    },
  );

  const {
    trigger: completePostmortem,
    isMutating: isMutating,
    genericError: error,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident?.id ?? "" },
    async (apiClient) => {
      await apiClient.postmortemsUpdateStatus({
        updateStatusRequestBody: {
          incident_id: incident.id,
          status: PostmortemsUpdateStatusRequestBodyStatusEnum.Complete,
        },
      });

      await refetchPostIncidentFlowTasks();

      // Don't bother waiting for this
      refetchIncident();
    },
    {
      onError: () => {
        // We're hitting races when people (1) export the PM and (2) update this PINC task.
        showToast({
          theme: ToastTheme.Error,
          title: "Please try again in a few seconds.",
          toastSide: ToastSideEnum.TopRight,
        });
      },
    },
  );

  useEffect(() => {
    if (!isMutatingLegacy && errorLegacy) {
      onError(errorLegacy);
    }
    if (!isMutating && error) {
      onError(error);
    }
    // We want to check for an error after every fetch completes, rather than
    // after the error changes so we can report whenever a mutation fails.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMutatingLegacy, isMutating]);

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      disabledExplanation={`${postmortemName} has not been created yet`}
      onClick={async () => {
        if (featurePostmortemsInHouse) {
          await completePostmortem({});
          onTaskComplete();
          return;
        } else {
          if (document) {
            await completePostmortemLegacy({
              id: document.id,
            });
            onTaskComplete();
            return;
          }
        }
      }}
      analyticsTrackingId="post-incident-task-complete-postmortem"
      requiredScope={ScopeNameEnum.PostMortemsMarkAsComplete}
      disabled={disabled || isMutatingLegacy || isMutating}
      icon={TaskTypeToIcon(
        PostIncidentTaskOptionTaskTypeEnum.CompletePostmortem,
      )}
      label={`Complete ${postmortemNameFormatted}`}
    />
  );
};

const DraftPostMortemItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(props.incident);

  const { availableDocument: document } = usePostmortem(props.incident);

  const navigate = useOrgAwareNavigate();

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={async () => {
        // Redirect to viewing the postmortem.
        if (document) {
          window.open(document.permalink, "_blank");
        } else {
          navigate(`/incidents/${incidentTask.incident_id}?tab=post-incident`);
        }
      }}
      analyticsTrackingId="post-incident-task-in-review-postmortem"
      icon={TaskTypeToIcon(
        PostIncidentTaskOptionTaskTypeEnum.InReviewPostmortem,
      )}
      label={`View ${postmortemNameFormatted}`}
    />
  );
};

const ReviewTimelineItem = ({
  incidentTask,
  mode,
}: ItemProps): React.ReactElement | null => {
  const { pathname } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <ButtonOrDropdownItem
        mode={mode}
        incidentTask={incidentTask}
        onClick={() => {
          // If we're on the incident details page, just scroll
          if (pathname.includes("/incidents/")) {
            const timeline = document.getElementById("timeline");
            timeline && timeline.scrollIntoView({ behavior: "smooth" });
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(
              `/incidents/${incidentTask.incident_id}?tab=post-incident#timeline`,
            );
          }
        }}
        analyticsTrackingId="post-incident-task-review-timeline"
        icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.ReviewTimeline)}
        label="View timeline"
      />
    </>
  );
};

const UpdateIncidentSummaryItem = ({
  incidentTask,
  mode,
}: ItemProps): React.ReactElement | null => {
  const { setIsEditingSummary } = useSummaryContext();
  const { pathname, search } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <ButtonOrDropdownItem
        mode={mode}
        incidentTask={incidentTask}
        onClick={() => {
          // If we're on the incident details page, we can just set the state.
          if (pathname.includes("/incidents/")) {
            setIsEditingSummary(true);
            if (search.includes("tab=post-incident")) {
              // Switch to the overview tab, which is where the summary is.
              navigate(`/incidents/${incidentTask.incident_id}?tab=overview`);
            }
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(`/incidents/${incidentTask.incident_id}?`);
          }
        }}
        analyticsTrackingId="post-incident-task-update-incident-summary"
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.UpdateIncidentSummary,
        )}
        label="Update the summary"
      />
    </>
  );
};

const ReviewFollowUpsItem = ({
  incidentTask,
  mode,
}: ItemProps): React.ReactElement | null => {
  const { search, pathname } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <ButtonOrDropdownItem
        mode={mode}
        incidentTask={incidentTask}
        onClick={() => {
          // If we're on the incident details page, just switch tabs.
          if (pathname.includes("/incidents/")) {
            const newSearch = new URLSearchParams(search);
            newSearch.set("tab", BodyTabs.FollowUps);
            navigate(
              {
                pathname,
                search: newSearch.toString(),
              },
              { replace: true },
            );
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(`/incidents/${incidentTask.incident_id}?tab=follow-ups`);
          }
        }}
        analyticsTrackingId="post-incident-task-review-follow-ups"
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.ReviewFollowups,
        )}
        label="View follow-ups"
      />
    </>
  );
};

const UpdateTimestampsItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const { setShowingModal } = usePostIncidentModalContext();

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.EditTimestamps,
          props,
        })
      }
      analyticsTrackingId="post-incident-task-update-timestamps"
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.UpdateTimestamps)}
      label="View timestamps"
    />
  );
};

const GiveShoutoutItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const { setShowingModal } = usePostIncidentModalContext();

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.GiveShoutout,
          props,
        })
      }
      analyticsTrackingId="post-incident-task-give-shoutout"
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.GiveShoutout)}
      label="Give shoutout"
    />
  );
};

// PostIncidentTaskActionItem returns a Item for doing the given task (e.g. Create postmortem).
// If the task can only be completed by marking it as complete, it will return null.
export const PostIncidentTaskActionItem = ({
  incidentTask,
  onTaskComplete,
  mode,
}: {
  incidentTask: PostIncidentTaskSlim | PostIncidentTask;
  onTaskComplete: () => void;
  mode: ActionMode;
}): React.ReactElement | null => {
  const { identity } = useIdentity();
  const { incident } = useIncident(incidentTask.incident_id);
  const showToast = useToast();

  const {
    data: { custom_fields },
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  const customFields = sortBy(custom_fields, (field) => field.rank);

  const applicableIDs = incidentTask.config.custom_field_ids ?? [];

  // We only want to show the fields that they've chosen for this post-incident step.
  // We also want to fake that they're required, regardless of their regular
  // custom field setting.
  const requiredCustomFields = (
    customFields?.filter((x) => applicableIDs.includes(x.id)) || []
  ).map((c) => ({
    ...c,
    required_v2: CustomFieldRequiredV2Enum.Always,
  }));

  if (!incident || !incidentTask) {
    return null;
  }

  const onError = (error: string) =>
    showToast({
      theme: ToastTheme.Error,
      title: error,
    });

  const props: ItemProps = {
    incident,
    incidentTask,
    onTaskComplete,
    onError,
    mode,
    allCustomFields: requiredCustomFields,
  };

  switch (incidentTask.config?.task_type) {
    case ConfigTaskTypeEnum.CreatePostmortem:
      return <CreatePostMortemItem {...props} />;
    case ConfigTaskTypeEnum.SharePostmortem:
      if (!identity.can_share_postmortems) {
        return null;
      }
      return <SharePostMortemItem {...props} />;
    case ConfigTaskTypeEnum.CompletePostmortem:
      return <CompletePostMortemItem {...props} />;
    case ConfigTaskTypeEnum.InReviewPostmortem:
      return <DraftPostMortemItem {...props} />;
    case ConfigTaskTypeEnum.ReviewFollowups:
      return <ReviewFollowUpsItem {...props} />;
    case ConfigTaskTypeEnum.UpdateTimestamps:
      return <UpdateTimestampsItem {...props} />;
    case ConfigTaskTypeEnum.GiveShoutout:
      return <GiveShoutoutItem {...props} />;
    case ConfigTaskTypeEnum.ReviewTimeline:
      return <ReviewTimelineItem {...props} />;
    case ConfigTaskTypeEnum.UpdateIncidentSummary:
      return <UpdateIncidentSummaryItem {...props} />;
    case ConfigTaskTypeEnum.AssignRole:
      return <AssignRoleItem {...props} />;
    case ConfigTaskTypeEnum.SetCustomFields:
      return <SetCustomFieldsItem {...props} />;
    case ConfigTaskTypeEnum.SetTimestamps:
      return <UpdateTimestampsItem {...props} />;
    case ConfigTaskTypeEnum.ScheduleDebrief:
      return <ScheduleDebriefItem {...props} />;
    default:
      return null;
  }
};
