import React from "react";

const MicrosoftTeamsTabContext = React.createContext<{
  tabFriendlyView?: boolean;
}>({});

export const MicrosoftTeamsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <MicrosoftTeamsTabContext.Provider value={{ tabFriendlyView: true }}>
      {children}
    </MicrosoftTeamsTabContext.Provider>
  );
};

export const DisableMicrosoftTeamsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <MicrosoftTeamsTabContext.Provider value={{ tabFriendlyView: false }}>
      {children}
    </MicrosoftTeamsTabContext.Provider>
  );
};

export const useIsWithinMicrosoftTeamsTab = (): boolean => {
  const { tabFriendlyView } = React.useContext(MicrosoftTeamsTabContext);
  return tabFriendlyView ?? false;
};
