import { IntegrationSettingsProviderEnum } from "@incident-io/api";
import { Callout, CalloutTheme, Loader } from "@incident-ui";
import React from "react";
import { useIntegrations } from "src/hooks/useIntegrations";

const workspaceAppSpecificWarnings = {
  [IntegrationSettingsProviderEnum.GoogleCalendar]: (
    <span>
      We&apos;ll no longer be able to link Google Calendar events to incidents.
    </span>
  ),
  [IntegrationSettingsProviderEnum.GoogleDocs]: (
    <span>
      We&apos;ll no longer be able to export postmortems to Google Docs.
    </span>
  ),
  [IntegrationSettingsProviderEnum.GoogleMeet]: (
    <span>
      We&apos;ll no longer be able to start Google Meet call links for your
      incident channels.
    </span>
  ),
};

export const DisconnectGoogleWorkspaceWarning = (): React.ReactElement => {
  const { integrations } = useIntegrations();
  if (!integrations) {
    return <Loader />;
  }
  const installedGoogleIntegrations = integrations
    .filter((i) => i.provider.startsWith("google_"))
    .filter((i) => i.installed)
    .sort((a, b) => (a.provider < b.provider ? -1 : 1));

  if (installedGoogleIntegrations.length === 1) {
    return (
      <p className="text-sm">
        {workspaceAppSpecificWarnings[installedGoogleIntegrations[0].provider]}
      </p>
    );
  }

  return (
    <div className="space-y-4 text-sm">
      <Callout theme={CalloutTheme.Warning}>
        Disconnecting will disconnect{" "}
        <span className="font-semibold">
          all your Google Workspace integrations
        </span>
        .
      </Callout>
      <ul className="list-disc ml-4 space-y-2">
        {installedGoogleIntegrations.map(
          (x) =>
            workspaceAppSpecificWarnings[x.provider] && (
              <li key={x.provider}>
                <p>
                  <span className="font-semibold">{x.provider_name}</span> will
                  be <span className="font-semibold">uninstalled</span>
                </p>
                <p>{workspaceAppSpecificWarnings[x.provider]}</p>
              </li>
            ),
        )}
      </ul>
    </div>
  );
};
