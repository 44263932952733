import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  ButtonTheme,
  Card,
  FullPageEmptyState,
  IconEnum,
  IconSize,
} from "@incident-ui";

import { ReactComponent as Graphic } from "../dashboards/common/first-access-state.svg";

export const InsightsListEmptyState = () => {
  return (
    <FullPageEmptyState
      title="Dig deeper into your incidents"
      graphic={<Graphic />}
      subtitle="To get started with Insights, you need to set up a custom field."
      cta={
        <GatedButton
          analyticsTrackingId="insights-empty-state-cta"
          theme={ButtonTheme.Primary}
          href="/settings/custom-fields"
        >
          Set up custom field
        </GatedButton>
      }
      gradientClasses="from-alarmalade-50 to-white from-20% via-80%"
      className="w-full"
      cards={
        <>
          <Card
            className="h-full"
            icon={IconEnum.Box}
            iconSize={IconSize.Medium}
            title="Insights out of the box"
            description="Get comprehensive insights from the first alert to the final follow-up, all accessible with a single click"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.InsightsDashboard}
            iconSize={IconSize.Medium}
            title="Create custom dashboards"
            description="Select charts, add filters and grouping options, and arrange everything exactly how you want it"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.Email}
            iconSize={IconSize.Medium}
            title="Schedule reports"
            description="Regularly send tailored dashboards via email to keep everyone informed"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
        </>
      }
    />
  );
};
