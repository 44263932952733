import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { ErrorMessage, GenericErrorMessage } from "@incident-ui";
import { Loader, ModalFooter } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { compact } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import {
  IncidentRole,
  IncidentRoleRoleTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { BulkNoTriggersNotice } from "./BulkNoTriggersNotice";

export type BulkAssignRolesFormData = {
  role_assignments: {
    [key: string]: { assignee_id?: string };
  };
};

export function BulkAssignRolesForm({
  incidentIDs,
  onClose,
  onSubmit,
}: {
  incidentIDs: string[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement {
  const {
    data: rolesData,
    isLoading,
    error: incidentRolesError,
  } = useAPI("incidentRolesList", undefined);

  if (isLoading || !rolesData) {
    return <Loader />;
  }

  if (incidentRolesError) {
    return <GenericErrorMessage error={incidentRolesError} />;
  }

  const incidentRoles = rolesData.incident_roles.filter(
    (role) => role.role_type !== IncidentRoleRoleTypeEnum.Reporter,
  );

  return (
    <BulkAssignRolesFormInner
      incidentIDs={incidentIDs}
      onClose={onClose}
      onSubmit={onSubmit}
      incidentRoles={incidentRoles}
    />
  );
}

function BulkAssignRolesFormInner({
  incidentIDs,
  onClose,
  onSubmit,
  incidentRoles,
}: {
  incidentIDs: string[];
  incidentRoles: IncidentRole[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement {
  const defaultValues = incidentRoles.reduce((acc, role) => {
    acc[`role_assignments.${role.id}.assignee_id`] = "leave";
    return acc;
  }, {});
  const formMethods = useForm<BulkAssignRolesFormData>({ defaultValues });

  const [bulkErrors, setBulkErrors] = useState<string[] | null>(null);
  const analytics = useAnalytics();

  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, data: BulkAssignRolesFormData) => {
      analytics?.track("bulkActionApplied", {
        action: "assign roles",
        numIncidents: incidentIDs.length,
      });

      const { results } = await apiClient.incidentsBulkUpdateRoleAssignments({
        bulkUpdateRoleAssignmentsRequestBody: {
          incident_ids: incidentIDs,
          role_assignments: Object.entries(data.role_assignments)
            .filter(([_, assignee]) => {
              return assignee.assignee_id !== "leave";
            })
            .map(([roleID, assignee]) => {
              return {
                incident_role_id: roleID,
                assignee:
                  (assignee.assignee_id !== "unassign" && {
                    id: assignee.assignee_id,
                  }) ||
                  undefined,
              };
            })
            .filter((n) => n),
        },
      });
      const errors = compact(
        results.filter((res) => res.error).map((result) => result.error),
      );
      if (errors && errors.length !== 0) {
        setBulkErrors(errors);
      } else {
        onSubmit();
      }
    },
    {
      onSuccess: refreshIncidentList,
    },
  );

  const apiClient = useClient();
  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={submit}
      genericError={genericError}
      saving={saving}
      title={"Assign roles"}
      analyticsTrackingId="bulk-assign-roles"
      onClose={() => {
        onClose();
      }}
      footer={
        <ModalFooter
          hideBorder
          confirmButtonText={"Apply"}
          saving={saving}
          onClose={() => {
            onClose();
          }}
          confirmButtonType="submit"
        />
      }
    >
      {incidentRoles ? (
        <div className="space-y-2">
          {incidentRoles.map((role) => {
            let extraOptions: SelectOption[] = [
              { label: "Unassign role", value: "unassign" },
              { label: "Leave unchanged", value: "leave" },
            ];
            if (role.name === "Reporter") {
              extraOptions = [extraOptions[1]];
            }
            return (
              <div key={role.id}>
                <DynamicSingleSelectV2
                  formMethods={formMethods}
                  name={`role_assignments.${role.id}.assignee_id`}
                  key={role.id}
                  label={role.name}
                  placeholder="Leave unchanged"
                  required={`Please choose ${role.name}`}
                  loadOptions={async (input: string) => {
                    const options = await getTypeaheadOptions(
                      apiClient,
                      TypeaheadTypeEnum.User,
                    )(input);

                    return [...extraOptions, ...options];
                  }}
                  hydrateOptions={async (input: string) => {
                    const options = await hydrateInitialSelectOptions(
                      apiClient,
                      TypeaheadTypeEnum.User,
                    )(input);

                    return [...extraOptions, ...options];
                  }}
                  isClearable={false}
                />
              </div>
            );
          })}
          <BulkNoTriggersNotice className="mt-4" />
        </div>
      ) : (
        <Loader />
      )}
      {bulkErrors && (
        <ErrorMessage
          message={`We encountered a problem updating ${bulkErrors.length} of your incidents. If you keep encountering errors, please contact support.`}
        />
      )}
    </Form.Modal>
  );
}
