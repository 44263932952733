/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentSuggestionsUpdateSuggestionConfigRequestBody
 */
export interface IncidentSuggestionsUpdateSuggestionConfigRequestBody {
    /**
     * Flag to enable/disable auto-renaming incidents
     * @type {boolean}
     * @memberof IncidentSuggestionsUpdateSuggestionConfigRequestBody
     */
    auto_rename_incidents?: boolean;
    /**
     * Global kill switch for all suggestions
     * @type {boolean}
     * @memberof IncidentSuggestionsUpdateSuggestionConfigRequestBody
     */
    enabled?: boolean;
    /**
     * Flag to enable/disable name suggestions
     * @type {boolean}
     * @memberof IncidentSuggestionsUpdateSuggestionConfigRequestBody
     */
    suggest_names_enabled?: boolean;
    /**
     * Flag to enable/disable role suggestions
     * @type {boolean}
     * @memberof IncidentSuggestionsUpdateSuggestionConfigRequestBody
     */
    suggest_roles_enabled?: boolean;
    /**
     * Flag to enable/disable update suggestions
     * @type {boolean}
     * @memberof IncidentSuggestionsUpdateSuggestionConfigRequestBody
     */
    suggest_updates_enabled?: boolean;
}

export function IncidentSuggestionsUpdateSuggestionConfigRequestBodyFromJSON(json: any): IncidentSuggestionsUpdateSuggestionConfigRequestBody {
    return IncidentSuggestionsUpdateSuggestionConfigRequestBodyFromJSONTyped(json, false);
}

export function IncidentSuggestionsUpdateSuggestionConfigRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSuggestionsUpdateSuggestionConfigRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_rename_incidents': !exists(json, 'auto_rename_incidents') ? undefined : json['auto_rename_incidents'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'suggest_names_enabled': !exists(json, 'suggest_names_enabled') ? undefined : json['suggest_names_enabled'],
        'suggest_roles_enabled': !exists(json, 'suggest_roles_enabled') ? undefined : json['suggest_roles_enabled'],
        'suggest_updates_enabled': !exists(json, 'suggest_updates_enabled') ? undefined : json['suggest_updates_enabled'],
    };
}

export function IncidentSuggestionsUpdateSuggestionConfigRequestBodyToJSON(value?: IncidentSuggestionsUpdateSuggestionConfigRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_rename_incidents': value.auto_rename_incidents,
        'enabled': value.enabled,
        'suggest_names_enabled': value.suggest_names_enabled,
        'suggest_roles_enabled': value.suggest_roles_enabled,
        'suggest_updates_enabled': value.suggest_updates_enabled,
    };
}

