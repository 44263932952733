import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  isScheduleEntry,
  isScheduleOverride,
  TimelineEntry,
} from "@incident-shared/schedules/ScheduleOverview/common/types";
import { closeTooltips } from "@incident-ui/Tooltip/Tooltip";

export const navigateToOverrideForEntry = (
  navigate: ReturnType<typeof useOrgAwareNavigate>,
  entry: TimelineEntry,
) => {
  if (isScheduleOverride(entry)) {
    navigate(
      `/on-call/schedules/${entry.schedule_id}/overrides/${entry.id}/edit`,
    );
  } else if (isScheduleEntry(entry)) {
    if (entry.override_id) {
      navigate(
        `/on-call/schedules/${entry.schedule_id}/overrides/${entry.override_id}/edit`,
      );
    } else {
      navigate(
        `/on-call/schedules/${
          entry.schedule_id
        }/overrides/create?initial_rotation_id=${
          entry.rotation_id
        }&initial_layer_id=${
          entry.layer_id
        }&initial_start_at=${entry.start_at.toISOString()}&initial_end_at=${entry.end_at.toISOString()}`,
      );
    }
  } else {
    navigate(
      `/on-call/schedules/${
        entry.schedule_id
      }/overrides/create?&initial_start_at=${entry.start_at.toISOString()}&initial_end_at=${entry.end_at.toISOString()}`,
    );
  }

  closeTooltips();
};
