import { Route, Routes } from "react-router";

import { WorkbenchInvestigationPage } from "./WorkbenchInvestigationPage";
import { WorkbenchInvestigationsListPage } from "./WorkbenchInvestigationsListPage";

export const WorkbenchInvestigationsRoute = () => {
  return (
    <Routes>
      <Route
        path="/:investigationId"
        element={<WorkbenchInvestigationPage />}
      />
      <Route path="*" element={<WorkbenchInvestigationsListPage />} />
    </Routes>
  );
};
