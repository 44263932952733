import { NoPermissionMessage } from "@incident-shared/gates/gates";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  LoadingWrapper,
  StackedList,
  StackedListItem,
  Tooltip,
} from "@incident-ui";
import { useSearchContext } from "@incident-ui/SearchBar/SearchBar";
import { OverflowAction } from "@incident-ui/StackedList/AccordionStackedList";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { Searcher, sortKind } from "fast-fuzzy";
import { orderBy } from "lodash";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { ClientType, Nudge, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { NudgePreviewInner } from "../common/NudgePreview";
import { NudgeSelectTypeModal } from "./NudgeTypeSelectModal";

export const NudgesList = ({
  showChooseTypeModal,
  setShowChooseTypeModal,
  hasFeatureGate,
  renameToChannelSuggestion,
}: {
  showChooseTypeModal: boolean;
  setShowChooseTypeModal: (val: boolean) => void;
  hasFeatureGate: boolean;
  renameToChannelSuggestion: boolean;
}) => {
  const {
    data: nudgesList,
    error: nudgesErr,
    isLoading: nudgesLoading,
  } = useAPI("nudgesListNudges", undefined);

  if (nudgesErr) {
    return <GenericErrorMessage error={nudgesErr} />;
  }

  return (
    <>
      {showChooseTypeModal ? (
        <NudgeSelectTypeModal
          onClose={() => setShowChooseTypeModal(false)}
          renameToChannelSuggestion={renameToChannelSuggestion}
        />
      ) : null}
      <LoadingWrapper loading={nudgesLoading}>
        <NudgesStackedList
          nudges={(nudgesList && nudgesList.nudges) || []}
          hasFeatureGate={hasFeatureGate}
          renameToChannelSuggestion={renameToChannelSuggestion}
        />
      </LoadingWrapper>
    </>
  );
};

const NudgesStackedList = ({
  nudges,
  hasFeatureGate,
  renameToChannelSuggestion,
}: {
  nudges: Nudge[];
  hasFeatureGate: boolean | undefined;
  renameToChannelSuggestion: boolean;
}) => {
  const sortedNudges = orderBy(nudges, "updated_at", "asc");
  const { value: search } = useSearchContext();
  const noun = renameToChannelSuggestion ? "suggestion" : "nudge";

  const searcher = new Searcher(sortedNudges, {
    keySelector: (nudge) => nudge.name,
    sortBy: sortKind.insertOrder,
    threshold: 0.8,
  });

  const items = search ? searcher.search(search) : sortedNudges;

  const navigate = useOrgAwareNavigate();
  const showToast = useToast();
  const { hasScope } = useIdentity();
  const canEditNudges = hasScope(ScopeNameEnum.NudgesUpdate);
  const [showDeleteModal, setShowDeleteModal] = useState<string | false>(false);
  const deletingNudge = nudges.find((nudge) => nudge.id === showDeleteModal);

  const { trigger: toggleNudgeStateAPI } = useAPIMutation(
    "nudgesListNudges",
    undefined,
    async (apiClient: ClientType, data: FieldValues) => {
      if (data.currentlyEnabled) {
        await apiClient.nudgesDisable({
          id: data.nudgeId,
        });
      } else {
        await apiClient.nudgesEnable({
          id: data.nudgeId,
        });
      }

      return;
    },
    {
      setError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to update nudge",
          toastSide: ToastSideEnum.Bottom,
        });
      },
    },
  );

  const { trigger: deleteNudgeAPI, isMutating: isDeleting } = useAPIMutation(
    "nudgesListNudges",
    undefined,
    async (apiClient: ClientType, data: FieldValues) => {
      await apiClient.nudgesDestroy({
        id: data.nudgeId,
      });

      return;
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Nudge deleted",
          toastSide: ToastSideEnum.Bottom,
        });
      },
      setError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to update nudge",
          toastSide: ToastSideEnum.Bottom,
        });
      },
    },
  );

  const toggleNudgeState = async ({ id, currentlyEnabled }) => {
    await toggleNudgeStateAPI({ nudgeId: id, currentlyEnabled } as FieldValues);
  };
  const deleteNudge = async ({ id }) => {
    await deleteNudgeAPI({ nudgeId: id } as FieldValues);
  };

  const overflowActions: OverflowAction<Nudge>[] = [
    {
      id: "edit",
      label: "Edit " + noun,
      icon: IconEnum.Edit,
      onSelect: (id) => {
        navigate(`${id}/edit`);
      },
      tooltipContent: canEditNudges ? null : <>{NoPermissionMessage}</>,
      shouldHide: (_) => false,
    },
    {
      id: "disable",
      label: "Disable " + noun,
      icon: IconEnum.ToggleLeft,
      onSelect: (id) => {
        toggleNudgeState({ id, currentlyEnabled: true });
      },
      tooltipContent: canEditNudges ? null : <>{NoPermissionMessage}</>,
      shouldHide: (item) => !item.enabled,
    },
    {
      id: "enable",
      label: "Enable " + noun,
      icon: IconEnum.ToggleRight,
      onSelect: (id) => {
        toggleNudgeState({ id, currentlyEnabled: false });
      },
      tooltipContent: canEditNudges ? null : <>{NoPermissionMessage}</>,
      shouldHide: (item) => item.enabled,
    },
    {
      id: "delete",
      label: "Delete " + noun,
      icon: IconEnum.Delete,
      onSelect: (id) => {
        setShowDeleteModal(id);
      },
      tooltipContent: canEditNudges ? null : <>{NoPermissionMessage}</>,
      shouldHide: (item) => !item.enabled,
    },
  ];

  if (items.length === 0) {
    if (search) {
      return (
        <EmptyState
          icon={IconEnum.Search}
          title={`No matching ${noun}s`}
          content={`We couldn't find any ${noun}s that match your search.`}
        />
      );
    }

    return (
      <EmptyState
        icon={IconEnum.Nudge}
        content={`You don't have any ${noun}s yet`}
      />
    );
  }

  return (
    <>
      <StackedList>
        {items.map((nudge) => (
          <StackedListItem
            key={nudge.id}
            icon={nudge.icon as unknown as IconEnum}
            title={nudge.name}
            rowHref={`${nudge.id}/edit`}
            badgeProps={
              nudge.enabled
                ? undefined
                : { label: "Disabled", theme: BadgeTheme.Tertiary }
            }
            disabled={!nudge.enabled}
            accessory={
              hasFeatureGate && (
                <div className="flex items-center gap-2">
                  <Tooltip
                    light
                    noMaxWidth
                    align="end"
                    bubbleProps={{
                      className: "!max-w-[350px] p-0",
                    }}
                    content={
                      <NudgePreviewInner
                        messageContent={nudge.preview.message_content}
                        buttons={nudge.preview.buttons}
                        borderless
                      />
                    }
                  >
                    <Button
                      analyticsTrackingId="nudge-preview"
                      theme={ButtonTheme.Ghost}
                      size={BadgeSize.Medium}
                      icon={IconEnum.Eye}
                      title=""
                    />
                  </Tooltip>
                  <DropdownMenu
                    triggerButtonTheme={ButtonTheme.Tertiary}
                    triggerBadgeSize={BadgeSize.Medium}
                    analyticsTrackingId={"accordion-table-item-more-options"}
                    screenReaderText="More options"
                    triggerIcon={IconEnum.DotsVertical}
                    align="end"
                    menuClassName="w-[250px]"
                  >
                    {overflowActions
                      // Filter out enable/disable when not applicable
                      .filter((action) => !action.shouldHide(nudge))
                      .map((action) => (
                        <DropdownMenuItem
                          key={action.id}
                          disabled={action.disabled}
                          onSelect={() => action.onSelect(nudge.id)}
                          className={tcx(
                            "text-sm font-normal text-content-primary",
                            action.className,
                          )}
                          tooltipContent={action.tooltipContent}
                          label={action.label}
                          analyticsTrackingId={
                            "accordion-table-item-overflow-item"
                          }
                          analyticsTrackingMetadata={{
                            overflowActionId: action.id,
                          }}
                          icon={action.icon}
                          iconProps={{ className: "!w-6 !h-6" }}
                        />
                      ))}
                  </DropdownMenu>
                </div>
              )
            }
          />
        ))}
      </StackedList>
      {!!deletingNudge && (
        <ConfirmationDialog
          isOpen
          title={`Delete ${noun} "${deletingNudge.name}"?`}
          onConfirm={() => {
            deleteNudge({ id: deletingNudge.id });
            setShowDeleteModal(false);
          }}
          saving={isDeleting}
          confirmButtonText="Delete"
          confirmButtonTheme={ButtonTheme.Destroy}
          onCancel={() => setShowDeleteModal(false)}
        >
          <Callout theme={CalloutTheme.Warning}>
            Are you sure you want to permanently delete{" "}
            <span className="font-semibold">{deletingNudge.name}</span>?
            {deletingNudge.enabled && (
              <> Instead you could disable it to prevent it from running.</>
            )}
          </Callout>
        </ConfirmationDialog>
      )}
    </>
  );
};
