/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentGroundTruth,
    IncidentGroundTruthFromJSON,
    IncidentGroundTruthFromJSONTyped,
    IncidentGroundTruthToJSON,
} from './IncidentGroundTruth';

/**
 * 
 * @export
 * @interface AIListIncidentGroundTruthsResponseBody
 */
export interface AIListIncidentGroundTruthsResponseBody {
    /**
     * Collection of ground truths for the incident
     * @type {Array<IncidentGroundTruth>}
     * @memberof AIListIncidentGroundTruthsResponseBody
     */
    ground_truths: Array<IncidentGroundTruth>;
}

export function AIListIncidentGroundTruthsResponseBodyFromJSON(json: any): AIListIncidentGroundTruthsResponseBody {
    return AIListIncidentGroundTruthsResponseBodyFromJSONTyped(json, false);
}

export function AIListIncidentGroundTruthsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIListIncidentGroundTruthsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ground_truths': ((json['ground_truths'] as Array<any>).map(IncidentGroundTruthFromJSON)),
    };
}

export function AIListIncidentGroundTruthsResponseBodyToJSON(value?: AIListIncidentGroundTruthsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ground_truths': ((value.ground_truths as Array<any>).map(IncidentGroundTruthToJSON)),
    };
}

