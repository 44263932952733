import {
  useAiStaffServiceAiStaffListAiRequests,
  useAiStaffServiceAiStaffListAiSpans,
} from "@incident-io/query-api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  EmptyState,
  IconEnum,
  LoadingWrapper,
  LocalDateTime,
} from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { AIRequestTrace } from "../ai-requests/AIRequestTrace";

export const ProcessorShowPage = () => {
  const { traceId, spanId } = useParams<{ traceId: string; spanId: string }>();
  const navigate = useOrgAwareNavigate();
  if (!spanId || !traceId) {
    navigate("/workbench");
    return null;
  }

  return (
    <WorkbenchSubPageWrapper backHref="/workbench/processors">
      <InteractionsList traceId={traceId} spanId={spanId} />
    </WorkbenchSubPageWrapper>
  );
};

const InteractionsList = ({
  spanId,
  traceId,
}: {
  spanId: string;
  traceId: string;
}) => {
  const { data: spanData, isLoading: spanDataLoading } =
    useAiStaffServiceAiStaffListAiSpans({
      traceId,
    });

  const { data: requestData, isLoading: reqDataLoading } =
    useAiStaffServiceAiStaffListAiRequests({
      traceId,
    });

  const spans = spanData?.spans ?? [];

  const topSpan = spans.find((span) => span.id === spanId);
  if (!topSpan) {
    return (
      <EmptyState
        icon={IconEnum.Filter}
        content="spanID and traceID in url didn't match"
      />
    );
  }

  return (
    <LoadingWrapper
      loading={spanDataLoading || reqDataLoading}
      className="pb-32"
    >
      {spans.length === 0 ? (
        <EmptyState icon={IconEnum.Filter} content="No spans found" />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="text-2xl-bold">{topSpan.name}</div>
          {topSpan.resource_id && (
            <SingleLineCodeBlock
              title="Resource ID"
              code={topSpan.resource_id}
              className="w-fit"
            />
          )}
          <LocalDateTime timestamp={topSpan.created_at} />
          <AIRequestTrace requests={requestData?.requests || []} />
        </div>
      )}
    </LoadingWrapper>
  );
};
