import {
  Button,
  ButtonTheme,
  Heading,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { motion } from "framer-motion";
import { useClient } from "src/contexts/ClientContext";
import { useLocalStorage } from "usehooks-ts";

import DarkFlame from "./OnCallFlame.png";
import Glow from "./OnCallGlow.png";
import { useShowOnCallBanner } from "./useShowOnCallBanner";

export const OnCallBanner = () => {
  const { dismissOnCallBanner } = useShowOnCallBanner();
  const [isInWaitList, setIsInWaitlist] = useLocalStorage(
    "isInOnCallWaitlist",
    false,
  );

  return (
    <div className="bg-slate-950 rounded-2 flex flex-col lg:flex-row gap-4 items-stretch overflow-hidden relative">
      <div className="max-w-[460px] flex items-center justify-center -mr-[120px] -ml-[20px] relative flex-none">
        <img src={DarkFlame} width={1657} height={1062} className="z-[5]" />
        <Button
          theme={ButtonTheme.Unstyled}
          href="https://incident.io/on-call?ref=video"
          analyticsTrackingId={"on-call-homepage-video"}
          className="!absolute w-full h-full flex items-center justify-center"
          openInNewTab
        >
          <Icon
            id={IconEnum.PlayOverlay}
            className="text-white opacity-80 group-hover:opacity-100 transition z-[15] !w-20 !h-20 -ml-[49px]"
          />
        </Button>
        <div className="absolute w-full top-0 left-0">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3, ease: "easeInOut" }}
          >
            <img src={Glow} className="w-full" />
          </motion.div>
        </div>
      </div>
      <div className="p-8 flex flex-col justify-center">
        {isInWaitList ? (
          <InWaitListContent />
        ) : (
          <InitialContent onRequest={() => setIsInWaitlist(true)} />
        )}
      </div>

      <div className="absolute top-0 right-0">
        <Button
          theme={ButtonTheme.Unstyled}
          className="m-5 text-content-tertiary hover:text-slate-300 transition"
          onClick={() => dismissOnCallBanner()}
          analyticsTrackingId="on-call-banner-home-dismiss"
        >
          <Icon id={IconEnum.Close} size={IconSize.Large} />
        </Button>
      </div>
    </div>
  );
};

const LearnMoreButton = () => (
  <Button
    href="https://help.incident.io/collections/1193602123-get-started-with-on-call"
    analyticsTrackingId="on-call-learn-more"
    theme={ButtonTheme.Secondary}
    className="!border-0 !px-4 z-[20]"
    openInNewTab
  >
    Learn more
  </Button>
);

const InitialContent = ({ onRequest }: { onRequest: () => void }) => {
  const apiClient = useClient();
  const requestOnCall = () => {
    onRequest();
    // This is best-effort: it's about pinging us in #on-call-pulse!
    apiClient.onboardingRequestOnCall().catch(console.error);
  };

  return (
    <>
      <Heading
        level={3}
        className="text-white font-bold text-4xl leading-none mb-4"
      >
        Get started with <span className="text-alarmalade-600">On-call</span>
      </Heading>
      <p className="text-slate-200 text-lg leading-7 max-w-[610px] mb-6">
        We&rsquo;re delighted to launch On-call and are currently rolling it out
        to customers. Click &ldquo;Get On-call&rdquo; below and we&rsquo;ll get
        it enabled for your account. We can&rsquo;t wait to hear what you think!
      </p>
      <div className="flex gap-2 items-center">
        <Button
          theme={ButtonTheme.UnstyledPill}
          analyticsTrackingId="on-call-homepage-banner"
          className="bg-alarmalade-600 text-white hover:brightness-90 px-4 z-[20]"
          onClick={requestOnCall}
        >
          Get On-call
        </Button>
        <LearnMoreButton />
      </div>
    </>
  );
};

const InWaitListContent = () => (
  <>
    <Heading
      level={3}
      className="text-white font-bold text-4xl leading-none mb-4 max-w-prose"
    >
      <span className="text-alarmalade-600">On-call</span> is on its way!
    </Heading>
    <p className="text-slate-200 text-base leading-7 max-w-[610px] mb-6">
      One of our team will be in touch once it&rsquo;s ready for you to take for
      a spin. We can&rsquo;t wait to hear what you think!
    </p>
    <div className="flex gap-2 items-center">
      <LearnMoreButton />
    </div>
  </>
);
