import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Card, OrgAwareLink } from "@incident-ui";
import React from "react";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { WorkbenchPage, WorkbenchSection } from "./WorkbenchIndexPage";

export const WorkbenchOverviewPage = ({
  sections,
}: {
  sections: WorkbenchSection[];
}): React.ReactElement => {
  return (
    <WorkbenchSubPageWrapper>
      <div className="grid gap-8 grid-cols-1 ">
        {sections
          .filter((s) => s.label !== "")
          .map((section) => (
            <div key={section.label}>
              <h2 className="text-base-bold mb-2">{section.label}</h2>
              <WorkbenchOverviewSection items={section.items} />
            </div>
          ))}
      </div>
    </WorkbenchSubPageWrapper>
  );
};

const WorkbenchOverviewSection = ({
  items,
}: {
  items: WorkbenchPage[];
}): React.ReactElement => {
  return (
    <div className="grid gap-4 xl:grid-cols-3 md:grid-cols-2 grid-cols-1">
      {items
        .filter((i) => i.slug !== "overview")
        .map((item) => (
          <OrgAwareLink to={`/workbench/${item.slug}`} key={item.slug}>
            <Card
              key={item.slug}
              icon={item.icon}
              title={item.label}
              color={ColorPaletteEnum.Purple}
            />
          </OrgAwareLink>
        ))}
    </div>
  );
};
