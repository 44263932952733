/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIRequest,
    AIRequestFromJSON,
    AIRequestFromJSONTyped,
    AIRequestToJSON,
} from './AIRequest';

/**
 * 
 * @export
 * @interface AIStaffListAIRequestsResponseBody
 */
export interface AIStaffListAIRequestsResponseBody {
    /**
     * 
     * @type {Array<AIRequest>}
     * @memberof AIStaffListAIRequestsResponseBody
     */
    requests: Array<AIRequest>;
}

export function AIStaffListAIRequestsResponseBodyFromJSON(json: any): AIStaffListAIRequestsResponseBody {
    return AIStaffListAIRequestsResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListAIRequestsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListAIRequestsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requests': ((json['requests'] as Array<any>).map(AIRequestFromJSON)),
    };
}

export function AIStaffListAIRequestsResponseBodyToJSON(value?: AIStaffListAIRequestsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requests': ((value.requests as Array<any>).map(AIRequestToJSON)),
    };
}

