import { Mode } from "@incident-shared/forms/v2/formsv2";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { DeleteOverrideModal } from "@incident-shared/schedules/ScheduleOverview/DeleteOverrideModal";
import { GenericErrorMessage } from "@incident-ui";
import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { Route, Routes, useMatch, useNavigate, useParams } from "react-router";
import { CallRoutingCreateRoute } from "src/components/call-routing/CallRoutingCreateRoute";
import { EscalationPathCreateEditDrawer } from "src/components/escalation-paths/create-edit/EscalationPathCreateEditDrawer";
import { OverrideCreateEditDrawer } from "src/components/legacy/on-call/schedules/overrides/OverrideCreateEditDrawer";
import { OnCallReportsViewRoute } from "src/routes/legacy/on-call-pay/OnCallReportsViewRoute";
import { OnCallRoute as LegacyOnCallRoute } from "src/routes/legacy/on-call-pay/OnCallRoute";
import { useQueryParams } from "src/utils/query-params";

import { CallRoutingEditRoute } from "../../call-routing/CallRoutingEditRoute";
import { CallRoutingPage } from "../../call-routing/CallRoutingPage";
import { EscalationPathsPage } from "../../escalation-paths/EscalationPathsPage";
import { EscalationPathsImportDrawer } from "../../escalation-paths/import/EscalationPathsImportDrawer";
import { EscalationsCreateIncidentDrawer } from "./escalations/EscalationsCreateIncidentDrawer";
import { EscalationsPage } from "./escalations/EscalationsList";
import { EscalationsViewPage } from "./escalations/EscalationsViewPage";
import { GetStartedPage } from "./get-started/OnCallGetStartedPage";
import { OnCallPageWrapper } from "./OnCallPageWrapper";
import { OnCallSidebarRouter } from "./OnCallSidebarRouter";
import { ScheduleCreateEditDrawer } from "./schedules/schedule-create-edit/ScheduleCreateEditDrawer";
import { ScheduleImportDrawer } from "./schedules/ScheduleImportDrawer";
import { SchedulesListPage } from "./schedules/SchedulesListPage";
import { SchedulesViewPage } from "./schedules/SchedulesViewPage";

export const OnCallRoute = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  return (
    <ErrorBoundary fallback={<GenericErrorMessage />}>
      <Routes>
        {/* "Get started" state: Two tabs, just get-started and pay-calculator if it applies */}
        <Route element={<OnCallPageWrapper showGetStarted />}>
          <Route
            path="get-started"
            element={
              <OrgAwareNavigate to="/on-call/get-started/main" replace />
            }
          />
          <Route path="get-started/main" element={<GetStartedPage />} />
        </Route>
        {/* "main" state: tabs for escalations, paths, schedules, and pay-calculator */}
        <Route element={<OnCallPageWrapper showGetStarted={false} />}>
          <Route path="" element={<OnCallSidebarRouter />} />
          <Route path="pay-calculator/*" element={<LegacyOnCallRoute />} />
          <Route path="escalations" element={<EscalationsPage />} />
          <Route path="escalation-paths" element={<EscalationPathsPage />}>
            <Route path="import" element={<EscalationPathsImportRoute />} />
            <Route path="create" element={<EscalationPathCreateEditRoute />} />
            <Route path=":id" element={<EscalationPathCreateEditRoute />} />
            <Route
              path=":id/edit"
              element={<EscalationPathCreateEditRoute />}
            />
          </Route>
          <Route path="call-routes" element={<CallRoutingPage />}>
            <Route path="create" element={<CallRoutingCreateRoute />} />
            <Route path=":id" element={<CallRoutingEditRoute />} />
          </Route>

          <Route path="schedules" element={<SchedulesListPage />}>
            <Route
              path="import"
              element={
                <ScheduleImportDrawer
                  onClose={() => navigate("/on-call/schedules")}
                />
              }
            />
            <Route
              path="create"
              element={<ScheduleCreateEditDuplicateRoute />}
            />
          </Route>
        </Route>
        <Route path="escalations/:id" element={<EscalationsViewPage />}>
          <Route
            path="create-incident"
            element={<EscalationsCreateIncidentDrawer />}
          />
        </Route>
        <Route path="schedules/:id" element={<SchedulesViewPage />}>
          <Route path="edit" element={<ScheduleCreateEditDuplicateRoute />} />
          <Route
            path="duplicate"
            element={<ScheduleCreateEditDuplicateRoute />}
          />
          <Route
            path="overrides/create"
            element={<ScheduleOverrideEditRoute />}
          />
          <Route
            path="overrides/:override_id/edit"
            element={<ScheduleOverrideEditRoute />}
          />
          <Route
            path="overrides/:override_id/delete"
            element={<ScheduleOverrideDeleteRoute />}
          />
        </Route>
        <Route
          path="pay-calculator/reports/:id"
          element={<OnCallReportsViewRoute />}
        />

        <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
      </Routes>
    </ErrorBoundary>
  );
};

const EscalationPathCreateEditRoute = () => {
  const navigate = useOrgAwareNavigate();

  const onClose = () => navigate("/on-call/escalation-paths");

  const { id: escalationPathId } = useParams<{ id?: string }>();

  return (
    <EscalationPathCreateEditDrawer
      onClose={onClose}
      escalationPathId={escalationPathId}
    />
  );
};

const EscalationPathsImportRoute = () => {
  const navigate = useOrgAwareNavigate();

  const onClose = () => navigate("/on-call/escalation-paths");

  return <EscalationPathsImportDrawer onClose={onClose} />;
};

const ScheduleCreateEditDuplicateRoute = () => {
  const navigate = useOrgAwareNavigate();
  const duplicateId = useMatch("/:slug/on-call/schedules/:id/duplicate")?.params
    ?.id;
  const editId = useMatch("/:slug/on-call/schedules/:id/edit")?.params?.id;

  const onClose = () => navigate("/on-call/schedules");

  return (
    <ScheduleCreateEditDrawer
      editId={editId}
      duplicateId={duplicateId}
      onClose={onClose}
    />
  );
};

const ScheduleOverrideEditRoute = () => {
  const navigate = useNavigate();
  const { id: scheduleId, override_id: overrideId } = useParams<{
    id: string;
    override_id?: string;
  }>();

  const params = useQueryParams();

  const onClose = () => navigate(-1);

  if (!scheduleId) {
    return <OrgAwareNavigate to="/on-call/schedules" replace />;
  }

  let startAt: Date | undefined;
  let endAt: Date | undefined;
  const startAtString = params.get("initial_start_at");
  if (startAtString) {
    startAt = new Date(startAtString);
  }
  const endAtString = params.get("initial_end_at");
  if (endAtString) {
    endAt = new Date(endAtString);
  }

  return (
    <OverrideCreateEditDrawer
      scheduleId={scheduleId}
      startAt={startAt}
      endAt={endAt}
      layerId={params.get("initial_layer_id") ?? undefined}
      userId={params.get("initial_user_id") ?? undefined}
      rotationId={params.get("initial_rotation_id") ?? undefined}
      onClose={onClose}
      {...(overrideId
        ? {
            mode: Mode.Edit,
            initialData: overrideId,
          }
        : {
            mode: Mode.Create,
          })}
    />
  );
};

const ScheduleOverrideDeleteRoute = () => {
  const navigate = useNavigate();
  const { id: scheduleId, override_id: overrideId } = useParams<{
    id?: string;
    override_id?: string;
  }>();

  const onClose = () => navigate(-1);

  if (!overrideId) {
    return <OrgAwareNavigate to={"/on-call/schedules/" + scheduleId} replace />;
  }

  return <DeleteOverrideModal overrideID={overrideId} onClose={onClose} />;
};
