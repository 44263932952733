import { FormFieldValue } from "@incident-shared/filters";
import { Loader, StackedList } from "@incident-ui";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { IncidentListItem } from "src/components/@shared/incidents/IncidentListItem";
import { Incident } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./IncidentsList.module.scss";

type ExtendedIncidentFormFieldValue = FormFieldValue & {
  key: string;
};

type Props = {
  incidents: Incident[];
  incidentsLoadMore: () => Promise<void>;
  incidentsIsLoading: boolean;
  anyIncidentsLoaded: boolean;
  allIncidentsLoaded: boolean;
  filters: ExtendedIncidentFormFieldValue[];
  incidentTypesEnabled: boolean;
  selectedIncidentIDs: string[];
  setSelectedIncidentIDs: (ids: string[]) => void;
  enableSelection: boolean;
  className?: string;
};

export function IncidentsList({
  incidents,
  incidentsIsLoading,
  incidentsLoadMore,
  allIncidentsLoaded,
  anyIncidentsLoaded,
  filters,
  incidentTypesEnabled,
  selectedIncidentIDs,
  setSelectedIncidentIDs,
  enableSelection,
  className,
}: Props): React.ReactElement {
  const {
    data: { slack_team_configs: slackTeamConfigs },
  } = useAPI("slackTeamConfigsList", undefined, {
    fallbackData: { slack_team_configs: [] },
  });

  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(
    null,
  );
  const [shiftKeyHeld, setShiftKeyHeld] = useState(false);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.shiftKey) {
        setShiftKeyHeld(true);
      } else {
        setShiftKeyHeld(false);
      }
    },
    [setShiftKeyHeld],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyDown);
    };
  }, [handleKeyDown]);

  const [loadRef] = useInfiniteScroll({
    loading: incidentsIsLoading,
    hasNextPage: !allIncidentsLoaded,
    onLoadMore: incidentsLoadMore,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the ref comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  if (!anyIncidentsLoaded) {
    return <Loader />;
  }

  const incTypeFilters = filters.filter(
    ({ field_key }) => field_key === "incident_type",
  );
  const filteringBySingleIncidentType =
    incTypeFilters.length > 0 &&
    incTypeFilters.every(
      (filter) =>
        filter.single_option_value !== undefined ||
        filter.multiple_options_value?.length === 1,
    );

  const onChangeSelection = (index: number) => {
    const inc = incidents[index];
    if (!selectedIncidentIDs.includes(inc.id)) {
      setLastSelectedIndex(index);
      if (shiftKeyHeld && lastSelectedIndex != null) {
        const minIndex = Math.min(index, lastSelectedIndex);
        const maxIndex = Math.max(index, lastSelectedIndex);
        const newIncidentIDs = incidents
          .slice(minIndex, maxIndex + 1)
          .map((i) => i.id);
        setSelectedIncidentIDs([...selectedIncidentIDs, ...newIncidentIDs]);
      } else {
        setSelectedIncidentIDs([...selectedIncidentIDs, inc.id]);
      }
    } else {
      setLastSelectedIndex(null);
      setSelectedIncidentIDs(_.without(selectedIncidentIDs, inc.id));
    }
  };

  return (
    <div
      className={tcx(
        "overflow-y-auto px-8 pb-16",
        styles.equalScrollbarGutters,
        className,
      )}
    >
      <StackedList className="w-full max-w-[1936px] mx-auto">
        {incidents.map((inc, index) => (
          <IncidentListItem
            key={inc.id}
            slackTeamConfigs={slackTeamConfigs}
            showIncidentTypes={
              // If the org has incident types enabled, show incident type
              // unless you're explicitly filtering by a single incident type
              incidentTypesEnabled ? !filteringBySingleIncidentType : false
            }
            incident={inc}
            selectedIncidentIDs={selectedIncidentIDs}
            onClick={(e) => {
              if (shiftKeyHeld) {
                e.preventDefault();
                onChangeSelection(index);
              }
            }}
            onChangeSelection={() => onChangeSelection(index)}
            enableSelection={enableSelection}
          />
        ))}
      </StackedList>
      {/* If we scroll to the bottom and we still have incidents to load, trigger the load ref. */}
      {!allIncidentsLoaded && (
        <div ref={loadRef}>
          <Loader />
        </div>
      )}
    </div>
  );
}
