import {
  AIConfigEnabledFeaturesEnum,
  CallProvidersProvidersEnum,
} from "@incident-io/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIdentity } from "src/contexts/IdentityContext";
import { useLocalStorage } from "use-hooks";

import { useAPI } from "../../utils/swr";
import { useHasCallProviderWithScribeSupport } from "./useIsScribeAvailable";

const scribeBannerLocalStorageKey = "scribeBannerDismissed";

export const useShowScribeBanner = () => {
  const [isDismissed, setDismissed] = useLocalStorage(
    scribeBannerLocalStorageKey,
    false,
  );

  const { featureLiveCallTranscription } = useFlags();
  const { identity } = useIdentity();
  const { data: aiConfigData } = useAPI(
    identity ? "aIShowConfig" : null,
    undefined,
  );

  const { isSupportedCallProviderInstalled, isLoading } =
    useHasCallProviderWithScribeSupport();

  // We have "hours per month" for transcription
  // it can be undefined or 0 (meaning no access) or exactly null (meaning unlimited access) or > 0 (meaning limited access)
  const canUse = canUseCallTranscription(
    identity.feature_gates.call_transcription_hours_per_month,
  );

  // Also check that they haven't enabled it already - we cannot us useAI hook - as that would tell
  // us it's disabled if the org has disabled ALL ai features which would be mildly annoying for those orgs.
  const notYetEnabled =
    aiConfigData?.config.openai_subprocessor_enabled &&
    !aiConfigData?.config.enabled_features.includes(
      AIConfigEnabledFeaturesEnum.CallTranscription,
    );

  // Only show if: flag enabled, org has transcription available, have a provider already installed, and AI settings not yet enabled
  const showBanner =
    featureLiveCallTranscription &&
    canUse &&
    isSupportedCallProviderInstalled &&
    notYetEnabled;

  return {
    showScribeBanner: !isDismissed && showBanner && !isLoading,
    dismissScribeBanner: () => setDismissed(true),
  };
};

const canUseCallTranscription = (limit: number | undefined): boolean => {
  if (limit === undefined || limit == null) {
    return true;
  }

  if (limit > 0) {
    return true;
  }

  return false;
};

export const GoogleMeetMissingPermissionsForScribe = (): boolean => {
  // Check whether the org uses Google Meet and doesn't have sufficient permissions for scribe to work
  const { data } = useAPI("incidentCallSettingsGetCallProviders", {});
  const isGoogleMeetInstalled = data?.available_providers?.providers?.some(
    (provider) => provider === CallProvidersProvidersEnum.GoogleMeet,
  );
  // If Zoom is installed let them use that instead
  const isZoomInstalled = data?.available_providers?.providers?.some(
    (provider) => provider === CallProvidersProvidersEnum.Zoom,
  );
  const isGoogleMeetWithEventsEnabled =
    data?.available_providers_with_events_enabled?.providers?.some(
      (provider) => provider === CallProvidersProvidersEnum.GoogleMeet,
    );
  return (
    !!isGoogleMeetInstalled &&
    !isGoogleMeetWithEventsEnabled &&
    !isZoomInstalled
  );
};
