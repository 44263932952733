import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconBadge, IconEnum, IconSize, OrgAwareLink } from "@incident-ui";
import { Card, CardProps } from "@incident-ui/Card/Card";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export const AIFeatureCard = ({
  disabled,
  icon,
  to,
  ...cardProps
}: {
  disabled?: boolean;
  icon: IconEnum;
  to: string;
} & CardProps): React.ReactElement => {
  return (
    <OrgAwareLink to={to}>
      <Card
        clickable
        {...cardProps}
        iconOverride={
          <IconBadge
            size={IconSize.Medium}
            icon={icon}
            color={ColorPaletteEnum.SlateOnWhite}
            className={tcx(
              disabled ? "" : "text-alarmalade-500",
              "border-none",
            )}
          />
        }
        title={
          <span className={disabled ? "text-content-tertiary" : ""}>
            {cardProps.title}
          </span>
        }
        description={
          <span
            className={
              disabled ? "text-content-tertiary" : "text-content-primary"
            }
          >
            {cardProps.description}
          </span>
        }
        className={tcx(
          cardProps.className,
          "border-none",
          disabled
            ? "bg-surface-secondary hover:bg-surface-tertiary"
            : "bg-alarmalade-50 hover:bg-alarmalade-100 ",
        )}
      />
    </OrgAwareLink>
  );
};

export const AIFeatureCardGrid = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">{children}</div>
  );
};
