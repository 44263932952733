import { usePylon } from "@bolasim/react-use-pylon";
import {
  AIToggleFeatureRequestBodyFeatureEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  OrgAwareLink,
  SharedToasts,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { ProductMarketingBanner } from "@incident-ui/ProductMarketingBanner/ProductMarketingBanner";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import scribeStyles from "../@shared/aiscribe/ScribeGradient.module.scss";
import bannerGraphic from "./banner-scribe.svg";
import {
  GoogleMeetMissingPermissionsForScribe,
  useShowScribeBanner,
} from "./useShowScribeBanner";

export const EnableScribeBanner = () => {
  const { dismissScribeBanner, showScribeBanner } = useShowScribeBanner();
  const { showKnowledgeBaseArticle } = usePylon();
  const { hasScope } = useIdentity();
  const showToast = useToast();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const missingGooglePermissionsForScribe =
    GoogleMeetMissingPermissionsForScribe();

  const { trigger: enableScribe } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: {
          feature: AIToggleFeatureRequestBodyFeatureEnum.CallTranscription,
          enabled: true,
        },
      }),
    {
      onSuccess: () => {
        showToast({
          title: "Scribe successfully activated",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast(SharedToasts.SETTINGS_SAVE_ERROR);
        showToast({
          title: "Could not activate Scribe",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  if (!showScribeBanner) {
    return null;
  }

  return canEditSettings ? (
    <ProductMarketingBanner
      title={`Never miss a call detail`}
      subtitle={
        <p>
          Scribe auto-joins video calls to take notes and deliver concise
          summaries, allowing everyone to participate freely.
        </p>
      }
      className={tcx(scribeStyles.background, "text-white")}
      cta={
        <Tooltip
          side={"bottom"}
          content={
            missingGooglePermissionsForScribe ? (
              <span>
                Before you can activate Scribe, you need to{" "}
                <OrgAwareLink
                  className="underline"
                  to="/settings/integrations/google_meet"
                >
                  reconnect your video call provider
                </OrgAwareLink>{" "}
                to ensure we have the latest permissions.
              </span>
            ) : undefined
          }
        >
          <Button
            analyticsTrackingId="enable-scribe"
            theme={ButtonTheme.Secondary}
            onClick={() => enableScribe({})}
            disabled={missingGooglePermissionsForScribe}
          >
            Activate
          </Button>
        </Tooltip>
      }
      secondaryCta={
        <Button
          analyticsTrackingId="dismiss-scribe-banner"
          theme={ButtonTheme.Naked}
          onClick={() => showKnowledgeBaseArticle("3340727910")}
          className="text-white"
        >
          Learn more
        </Button>
      }
      closeAction={dismissScribeBanner}
      graphic={<img src={bannerGraphic} />}
    />
  ) : (
    <ProductMarketingBanner
      title={`Never miss a call detail`}
      subtitle={
        <p>
          Scribe auto-joins video calls to take notes and deliver concise
          summaries, allowing everyone to participate freely.
        </p>
      }
      className={tcx(scribeStyles.background, "text-white")}
      cta={
        <Button
          analyticsTrackingId="enable-scribe"
          theme={ButtonTheme.Secondary}
          onClick={() => showKnowledgeBaseArticle("3340727910")}
        >
          Learn more
        </Button>
      }
      secondaryCta={
        <Button
          analyticsTrackingId="dismiss-richer-calls-banner"
          theme={ButtonTheme.Naked}
          className="text-white"
          onClick={dismissScribeBanner}
        >
          Dismiss
        </Button>
      }
      graphic={<img src={bannerGraphic} />}
    />
  );
};
