import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { ErrorMessage, IconEnum } from "@incident-ui";
import { useFormContext } from "react-hook-form";
import { useNodeFormErrors } from "src/components/escalation-paths/nodes/useNodeFormErrors";

import { EscalationPathFormData } from "../common/types";
import { IfElseConditionPopover } from "./IfElseConditionPopover";
import { NodeCard, NodeCardDropdown } from "./NodeCard";

// NodeIfElse is used for all IfElse nodes in an escalation path except for the start node.
// Counter is the number next to the IfElse node header: 'Condition: X'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NodeIfElse = ({ id }: { id: string }) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const relatedErrors = useNodeFormErrors({
    formMethods,
    id,
    nodeType: NodeTypes.IfElse,
  });

  return (
    <NodeCard
      title="Condition"
      subtitle="If escalation matches these conditions"
      icon={IconEnum.GitBranch}
      iconColor={{ background: "bg-blue-50", icon: "text-blue-500" }}
      actionButton={<NodeCardDropdown nodeId={id} conversions={[]} canDelete />}
    >
      <div className="pt-4">
        <IfElseConditionPopover nodeId={id} />
      </div>

      {relatedErrors.length > 0 ? (
        <ErrorMessage message={relatedErrors[0]} className={"text-xs"} />
      ) : null}
    </NodeCard>
  );
};
