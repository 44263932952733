import { AIConfigEnabledFeaturesEnum, ErrorResponse } from "@incident-io/api";
import {
  GatedButton,
  GatedButtonProps,
} from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  ContentBox,
  GenericErrorMessage,
  IconEnum,
  LoadingBar,
} from "@incident-ui";
import React, { useState } from "react";
import { useAPI } from "src/utils/swr";

import { AIFeatureCard, AIFeatureCardGrid } from "../../ai/AIFeatureCard";
import { SimilarIncidentsDrawer } from "./SimilarIncidentsDrawer";
import { SuggestedFollowUpsDrawer } from "./SuggestedFollowUpsDrawer";
import { SuggestedNamesDrawer } from "./SuggestedNamesDrawer";
import { SuggestedRolesDrawer } from "./SuggestedRolesDrawer";
import { SuggestedSummariesDrawer } from "./SuggestedSummariesDrawer";
import { SuggestedUpdatesDrawer } from "./SuggestedUpdatesDrawer";

export const SuggestionAICards = (): React.ReactElement => {
  const { features, loading, error } = useSuggestionFeatures();

  if (loading) {
    return <LoadingBar />;
  }

  if (error) {
    return (
      <ContentBox className="p-4">
        <GenericErrorMessage error={error} />
      </ContentBox>
    );
  }

  return (
    <AIFeatureCardGrid>
      {features.map((feature) => (
        <AIFeatureCard
          key={feature.slug}
          to={feature.slug}
          disabled={!feature.enabled}
          title={feature.title}
          description={feature.subtitle}
          icon={feature.icon}
        />
      ))}
    </AIFeatureCardGrid>
  );
};

export interface AISuggestionFeatureConfig {
  slug: string;
  enabled: boolean;
  title: string;
  subtitle: string;
  icon: IconEnum;
  Drawer: React.ComponentType<AISuggestionFeatureDrawerProps>;
}

export interface AISuggestionFeatureDrawerProps {
  onClose: () => void;
  feature: Omit<AISuggestionFeatureConfig, "Drawer">;
}

export const useSuggestionFeatures = (): {
  features: AISuggestionFeatureConfig[];
  loading: boolean;
  error?: ErrorResponse;
} => {
  const { data: aiConfigResp, error: aiConfigError } = useAPI(
    "aIShowConfig",
    undefined,
  );

  const { data: suggestionConfigResp, error: suggestionConfigError } = useAPI(
    "incidentSuggestionsGetSuggestionConfig",
    undefined,
  );

  if (!aiConfigResp || !suggestionConfigResp) {
    return {
      loading: true,
      features: [],
    };
  }

  const error = aiConfigError || suggestionConfigError;
  if (error) {
    return {
      loading: false,
      error: error as ErrorResponse,
      features: [],
    };
  }

  const aiConfig = aiConfigResp.config;

  return {
    loading: false,
    features: [
      {
        slug: "summaries",
        enabled: aiConfig.enabled_features.includes(
          AIConfigEnabledFeaturesEnum.SummariseIncident,
        ),
        title: "Summaries",
        subtitle: `Summaries help responders get up to speed quickly. We'll draft them for you, and share them after each incident update.`,
        icon: IconEnum.QuoteAIGradient,
        Drawer: SuggestedSummariesDrawer,
      },
      {
        slug: "related-incidents",
        enabled: aiConfig.enabled_features.includes(
          AIConfigEnabledFeaturesEnum.IncidentSimilarity,
        ),
        title: "Related incidents",
        subtitle: `Related incidents help you learn from previous incidents to resolve the incident you’re working on faster. We’ll suggest inviting the related incidents lead so they can share context.`,
        icon: IconEnum.LinkAIGradient,
        Drawer: SimilarIncidentsDrawer,
      },
      {
        slug: "follow-ups",
        enabled: aiConfig.enabled_features.includes(
          AIConfigEnabledFeaturesEnum.SuggestFollowups,
        ),
        title: "Follow-ups",
        subtitle: `Follow-ups are work that helps you become more resilient in the future. We'll suggest follow-ups once the incident is resolved so they're never forgotten.`,
        icon: IconEnum.FollowUpsAIGradient,
        Drawer: SuggestedFollowUpsDrawer,
      },
      {
        slug: "updates",
        enabled: suggestionConfigResp.config.suggest_updates_enabled,
        title: "Updates",
        subtitle: `Updates keep everyone up to speed, and bring structure when it's needed most. When the incident has changed we'll suggest and update for you, so you never miss a beat.`,
        icon: IconEnum.IncidentTypeAIGradient,
        Drawer: SuggestedUpdatesDrawer,
      },
      {
        slug: "roles",
        enabled: suggestionConfigResp.config.suggest_roles_enabled,
        title: "Incident Roles",
        subtitle: `Roles make responsibilities clear. When someone has picked up the work, but hasn't been formally assigned the role, we'll suggest it so everyones knows what they're responsible for.`,
        icon: IconEnum.UserAIGradient,
        Drawer: SuggestedRolesDrawer,
      },
      {
        slug: "names",
        enabled: suggestionConfigResp.config.suggest_names_enabled,
        title: "Names",
        subtitle: `Names are the first step in understanding what's happening, and if you can help. We'll suggest them so they accurately reflect what's happening as the incident evolves.`,
        icon: IconEnum.TranscriptAIGradient,
        Drawer: SuggestedNamesDrawer,
      },
    ],
  };
};

export const ToggleSuggestionButton = ({
  enabled,
  onEnable,
  onDisable,
  disableModalTitle,
  disableModalContent,
  featureSlug,
  ...gatedButtonProps
}: {
  enabled: boolean;
  onEnable: () => void;
  onDisable: () => void;
  disableModalTitle: string;
  disableModalContent: React.ReactNode;
  featureSlug: string;
} & Partial<GatedButtonProps>) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <>
      {showDeleteDialog && (
        <ConfirmationDialog
          isOpen
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={() => {
            setShowDeleteDialog(false);
            onDisable();
          }}
          confirmButtonText="Disable"
          confirmButtonTheme={ButtonTheme.Destroy}
          title={disableModalTitle}
        >
          {disableModalContent}
        </ConfirmationDialog>
      )}
      {enabled ? (
        <GatedButton
          analyticsTrackingId="disable-suggestion"
          analyticsTrackingMetadata={{ feature: featureSlug }}
          theme={ButtonTheme.DestroySecondary}
          onClick={() => setShowDeleteDialog(true)}
          {...gatedButtonProps}
        >
          Disable
        </GatedButton>
      ) : (
        <GatedButton
          theme={ButtonTheme.Primary}
          analyticsTrackingId="enable-suggestion"
          analyticsTrackingMetadata={{ feature: featureSlug }}
          onClick={onEnable}
          {...gatedButtonProps}
        >
          Enable
        </GatedButton>
      )}
    </>
  );
};

export const AIPrivacyButton = () => {
  return (
    <Button
      theme={ButtonTheme.Tertiary}
      analyticsTrackingId="ai-privacy-guidance"
      openInNewTab
      href="https://trust.incident.io/resources?s=yyxepsc6m1tkxjigedpl&name=ai-privacy-guidance"
    >
      AI Privacy Guidance
    </Button>
  );
};
