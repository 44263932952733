import {
  AIRequest,
  AISpan,
  useAiStaffServiceAiStaffListAiSpans,
} from "@incident-io/query-api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Loader,
  TabPane,
  TabSection,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { JsonViewer } from "../common/JsonViewer";
import { TextViewer } from "../common/TextViewer";
import { YAMLViewer } from "../common/YamlViewer";

export const AIRequestTrace = ({ requests }: { requests: AIRequest[] }) => {
  // Sort requests by start time
  requests = requests.sort(
    (a, b) =>
      a.created_at.getTime() -
      a.duration_seconds * 1000 -
      (b.created_at.getTime() - b.duration_seconds * 1000),
  );

  const traceId = requests[0]?.trace;
  const { data: spanData, isLoading } = useAiStaffServiceAiStaffListAiSpans({
    traceId,
  });

  const [selectedSpan, setSelectedSpan] = useState<AISpan | null>(null);
  const selectedAIRequest = requests.find(
    (r) => r.id === selectedSpan?.ai_request_id,
  );

  if (isLoading) {
    return <Loader />;
  }

  // All the requests should be from the same trace. If not, then
  // we got issues.
  for (const request of requests) {
    if (request.trace !== traceId) {
      return (
        <Callout theme={CalloutTheme.Danger}>
          Multiple trace IDs found: should be impossible
        </Callout>
      );
    }
  }

  let spans = spanData?.spans || [];
  if (spans.length === 0) {
    spans = fakeSpansFromAIRequests(requests);
  }

  return (
    <>
      {selectedSpan && (
        <Drawer onClose={() => setSelectedSpan(null)} width="large">
          <DrawerContents>
            <DrawerTitle
              title={selectedSpan.name}
              onClose={() => setSelectedSpan(null)}
            />
            <DrawerBody className="overflow-y-auto">
              <TabSection
                withIndicator
                defaultTab="rendered"
                tabBarClassName="w-full border-b"
                tabs={[
                  {
                    id: "eval",
                    label: "Eval",
                  },
                  {
                    id: "messages",
                    label: "Completion messages",
                    hidden: !selectedAIRequest,
                  },
                ]}
              >
                <TabPane tabId="eval">
                  <div className="py-6 flex flex-col gap-6">
                    <TextViewer
                      title={
                        <div className="flex items-center gap-0.5">
                          <Icon
                            id={IconEnum.GoogleCloud}
                            size={IconSize.Small}
                          />
                          <Link
                            analyticsTrackingId={null}
                            openInNewTab
                            href={`https://console.cloud.google.com/traces/list?project=incident-io-production&tid=${selectedSpan.trace_id}`}
                          >
                            trace
                          </Link>
                        </div>
                      }
                      content={selectedSpan.trace_id || ""}
                    />

                    {selectedAIRequest ? (
                      <YAMLViewer
                        title="Response"
                        yaml={selectedAIRequest.response}
                      />
                    ) : (
                      <TextViewer
                        title="Response"
                        content={formatSpanResult(selectedSpan.result)}
                      />
                    )}
                    {selectedAIRequest && (
                      <YAMLViewer
                        title="Eval case"
                        yaml={selectedAIRequest.eval}
                      />
                    )}
                  </div>
                </TabPane>
                {selectedAIRequest && (
                  <TabPane tabId="messages">
                    <div className="py-6 flex flex-col gap-6">
                      {(selectedAIRequest.raw_messages || []).map(
                        (msg, idx) => {
                          if (msg.role === "tool" && msg.tool_call) {
                            return (
                              <JsonViewer
                                key={idx}
                                title={msg.role}
                                jsonStr={msg.tool_call}
                              />
                            );
                          } else {
                            return (
                              <TextViewer
                                key={idx}
                                title={msg.role}
                                content={msg.content}
                              />
                            );
                          }
                        },
                      )}
                    </div>
                  </TabPane>
                )}
              </TabSection>
            </DrawerBody>
          </DrawerContents>
        </Drawer>
      )}
      <div className="w-full pt-2">
        <div className="flex w-full gap-2">
          {/* Timeline */}
          <CopilotSpanTimeline
            spans={spans}
            requests={requests}
            setSelectedSpan={setSelectedSpan}
          />
        </div>
      </div>
    </>
  );
};

type SpanWithDepth = AISpan & {
  depth: number;
  hasIgnoredParent?: boolean;
};

const getSpanIcon = (type: string): IconEnum => {
  switch (type) {
    case "tool_call":
      return IconEnum.Call;
    case "completion_request":
      return IconEnum.Message;
    case "prompt":
      return IconEnum.Box;
    case "processor":
      return IconEnum.Cog;

    default:
      return IconEnum.QuestionMark;
  }
};

const getSpanColor = (span: SpanWithDepth) => {
  if (span.type === "prompt") {
    return "";
  }

  // Base color classes - will be combined with opacity if needed
  let baseColor = "";
  if (span.result_type === "error") {
    if (
      typeof span.result === "string" &&
      span.result.includes("context canceled")
    ) {
      baseColor = "bg-slate-200 hover:bg-slate-300";
    } else {
      baseColor = "bg-red-200 hover:bg-red-300";
    }
  } else if (span.result_type === "ignored") {
    baseColor = "bg-slate-200 hover:bg-slate-300";
  } else if (span.type === "tool_call") {
    baseColor = "bg-blue-200 hover:bg-blue-300";
  } else if (span.type === "processor") {
    baseColor = "bg-orange-200 hover:bg-orange-300";
  } else {
    baseColor = "bg-green-200 hover:bg-green-300";
  }

  // Add opacity if parent is ignored
  const isIgnored = span.hasIgnoredParent || span.result_type === "ignored";
  return tcx(baseColor, isIgnored && "opacity-60");
};

export const CopilotSpanTimeline = ({
  spans,
  requests,
  setSelectedSpan,
}: {
  spans: AISpan[];
  requests: AIRequest[];
  setSelectedSpan: (span: AISpan) => void;
}) => {
  // Build a map of parent spans for quick lookup
  const spansById = new Map(spans.map((span) => [span.id, span]));

  // Check if a span has an ignored parent
  const hasIgnoredParent = (span: AISpan): boolean => {
    let currentSpan = span;
    while (currentSpan.parent_id) {
      const parent = spansById.get(currentSpan.parent_id);
      if (!parent) break;
      if (parent.result_type === "ignored") return true;
      currentSpan = parent;
    }
    return false;
  };

  const hierarchicalSpans = getSpanHierarchy(spans).map((span) => ({
    ...span,
    hasIgnoredParent: hasIgnoredParent(span),
  }));

  // Calculate timeline parameters
  const startTime = Math.min(...spans.map((s) => s.start.getTime()));
  const endTime = Math.max(
    ...spans.map((s) => s.start.getTime() + s.duration_seconds * 1000),
  );
  const totalDuration = endTime - startTime;

  const getBarStyles = (span: AISpan) => {
    const spanStart = span.start.getTime();
    const left = ((spanStart - startTime) / totalDuration) * 100;
    const width = ((span.duration_seconds * 1000) / totalDuration) * 100;

    return {
      left: `${left}%`,
      width: `${width}%`,
    };
  };

  const BAR_HEIGHT = 28;
  const ROW_HEIGHT = 40;
  const INDENT_WIDTH = 20;

  return (
    <div className="w-full border-t border-slate-100">
      <div className="flex w-full gap-2">
        {/* Sidebar */}
        <div className="w-64 flex-shrink-0 pr-4 border-r border-slate-100">
          {hierarchicalSpans.map((span) => {
            const request = requests.find((r) => r.id === span.ai_request_id);

            if (span.type === "prompt") {
              return (
                <div
                  key={span.id}
                  className="text-sm"
                  style={{
                    height: `${ROW_HEIGHT}px`,
                    paddingLeft: `${span.depth * INDENT_WIDTH}px`,
                  }}
                />
              );
            }

            return (
              <div
                key={span.id}
                className={tcx(
                  "text-sm flex flex-col justify-center",
                  span.hasIgnoredParent && "opacity-60",
                )}
                style={{
                  height: `${ROW_HEIGHT}px`,
                  paddingLeft: `${span.depth * INDENT_WIDTH}px`,
                }}
              >
                <div className="font-medium flex items-center gap-2">
                  <Icon
                    id={getSpanIcon(span.type)}
                    size={IconSize.Small}
                    className="flex-shrink-0"
                  />
                  <span className="truncate">{span.name}</span>
                </div>
                <div className="text-xs text-content-tertiary">
                  {span.duration_seconds.toFixed(2)}s
                  {request && ` | $${(request.cost_cents / 100).toFixed(4)}`}
                </div>
              </div>
            );
          })}
        </div>

        {/* Timeline */}
        <div className="flex-grow">
          <div
            className="relative"
            style={{ height: `${hierarchicalSpans.length * ROW_HEIGHT}px` }}
          >
            {hierarchicalSpans.map((span, index) => {
              const styles = getBarStyles(span);
              const isPrompt = span.type === "prompt";

              return (
                <div
                  key={span.id}
                  className="absolute"
                  style={{
                    ...styles,
                    height: `${BAR_HEIGHT}px`,
                    top: `${
                      index * ROW_HEIGHT + (ROW_HEIGHT - BAR_HEIGHT) / 2
                    }px`,
                  }}
                >
                  <div
                    className={tcx(
                      "h-full transition relative flex items-center px-2 text-content-primary gap-2 text-xs",
                      getSpanColor(span),
                      isPrompt
                        ? "border-t border-x border-slate-400 text-content-tertiary"
                        : "rounded cursor-pointer group",
                    )}
                    onClick={() => !isPrompt && setSelectedSpan(span)}
                  >
                    <span className={isPrompt ? "" : "font-medium"}>
                      {span.name}
                    </span>
                    {!isPrompt && (
                      <>
                        {span.execution_type !== "normal" && (
                          <Badge
                            theme={BadgeTheme.Secondary}
                            size={BadgeSize.ExtraSmall}
                          >
                            Speculative
                          </Badge>
                        )}
                        <Icon
                          id={IconEnum.View}
                          size={IconSize.Small}
                          className="opacity-0 group-hover:opacity-100 transition ml-1"
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const getSpanHierarchy = (spans: AISpan[]): SpanWithDepth[] => {
  // Create map of parent IDs to child spans
  const childMap = new Map<string, AISpan[]>();
  spans.forEach((span) => {
    if (span.parent_id) {
      const children = childMap.get(span.parent_id) || [];
      children.push(span);
      childMap.set(span.parent_id, children);
    }
  });

  const shouldIncludeSpan = (span: AISpan): boolean => {
    const children = childMap.get(span.id) || [];
    // If this is a prompt with exactly one completion_request child, skip it
    if (span.type === "prompt" && children.length === 1) {
      return !children[0].type.includes("completion_request");
    }
    return true;
  };

  // Helper function to sort spans with same timestamp
  const sortSpans = (a: AISpan, b: AISpan): number => {
    // First sort by timestamp
    const timeA = a.start.getTime();
    const timeB = b.start.getTime();
    if (timeA !== timeB) return timeA - timeB;

    // If timestamps are equal, sort by type
    if (a.type !== b.type) {
      // Put completion_request above tool_call
      if (a.type === "completion_request") return -1;
      if (b.type === "completion_request") return 1;
      return a.type.localeCompare(b.type);
    }

    // If types are equal, sort by name
    return a.name.localeCompare(b.name);
  };

  const assignDepth = (span: AISpan, depth: number): SpanWithDepth[] => {
    const children = childMap.get(span.id) || [];
    const currentSpan = shouldIncludeSpan(span) ? [{ ...span, depth }] : [];

    // Sort children before processing
    const sortedChildren = [...children].sort(sortSpans);

    return [
      ...currentSpan,
      ...sortedChildren.flatMap((child) =>
        assignDepth(child, depth + (currentSpan.length ? 1 : 0)),
      ),
    ];
  };

  // Sort root spans as well
  const rootSpans = spans.filter((span) => !span.parent_id).sort(sortSpans);

  return rootSpans.flatMap((span) => assignDepth(span, 0));
};

/**
 * fakeSpansFromCopilotRequests converts CopilotRequests into artificial CopilotSpans.
 * This is useful when we only have request data but want to display it in a span timeline.
 *
 * @param requests Array of CopilotRequests to convert
 * @returns Array of generated CopilotSpans
 */
const fakeSpansFromAIRequests = (requests: AIRequest[]): AISpan[] => {
  if (!requests.length) return [];

  // Sort requests by their start time (created_at - duration)
  const sortedRequests = [...requests].sort((a, b) => {
    const aStart = a.created_at.getTime() - a.duration_seconds * 1000;
    const bStart = b.created_at.getTime() - b.duration_seconds * 1000;
    return aStart - bStart;
  });

  const spans: AISpan[] = [];

  // Create a parent span for the entire trace
  const firstRequest = sortedRequests[0];
  const lastRequest = sortedRequests[sortedRequests.length - 1];

  const traceStart = new Date(
    firstRequest.created_at.getTime() - firstRequest.duration_seconds * 1000,
  );
  const traceEnd = lastRequest.created_at;
  const traceDuration = (traceEnd.getTime() - traceStart.getTime()) / 1000;

  const traceSpan: AISpan = {
    id: `trace-${firstRequest.trace}`,
    organisation_id: "org", // This is a required field but we don't have it in requests
    name: "Interaction (No spans available, reconstructed from ai_requests)",
    type: "prompt",
    execution_type: "normal",
    result_type: "success",
    start: traceStart,
    duration_seconds: traceDuration,
    created_at: traceStart,
    updated_at: traceEnd,
    trace_id: firstRequest.trace,
    result: null,
  };

  spans.push(traceSpan);

  // Create individual spans for each request
  sortedRequests.forEach((request) => {
    const startTime = new Date(
      request.created_at.getTime() - request.duration_seconds * 1000,
    );

    const span: AISpan = {
      id: request.id,
      organisation_id: "org",
      name: request.prompt,
      type: "completion_request",
      execution_type: "normal",
      result_type: "success",
      start: startTime,
      duration_seconds: request.duration_seconds,
      created_at: startTime,
      updated_at: request.created_at,
      trace_id: request.trace,
      parent_id: traceSpan.id,
      ai_request_id: request.id,
      result: request.response,
    };

    spans.push(span);
  });

  return spans;
};

const formatSpanResult = (result: unknown): string => {
  if (typeof result === "string") {
    return result;
  }

  if (result == null) {
    return "";
  }

  // Handle common result structures we know about
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resultObj = result as any;
  if (resultObj.content && typeof resultObj.content === "string") {
    return resultObj.content;
  }

  // For other objects, return a formatted JSON string
  return JSON.stringify(result, null, 2);
};
