import { useOnCallNotificationsServiceOnCallNotificationsListRestrictedCountries } from "@incident-io/query-api";

export const useRestrictedCountries = () => {
  const { data } =
    useOnCallNotificationsServiceOnCallNotificationsListRestrictedCountries(
      [],
      {
        refetchInterval: 30000,
      },
    );

  if (!data) {
    return undefined;
  }

  return data.countries;
};
