import {
  EmbeddedCatalogEntry,
  ExternalSchedule,
  ExternalScheduleExternalProviderEnum,
  ExternalScheduleNativeConfigUnavailableReasonEnum,
  IntegrationSettingsProviderEnum as IntegrationProvider,
} from "@incident-io/api";
import styles from "@incident-shared/filters/Filters.module.scss";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Input,
  Link,
  Loader,
  OrgAwareLink,
  StackedList,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { GenericErrorMessage } from "@incident-ui/GenericErrorMessage/GenericErrorMessage";
import { InputType } from "@incident-ui/Input/Input";
import _ from "lodash";
import { ChangeEvent, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPIInfinite } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useDebounce } from "use-debounce";

export const ScheduleImportDrawer = ({ onClose }: { onClose: () => void }) => {
  const navigate = useOrgAwareNavigate();
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);

  const {
    responses,
    isLoading,
    isFullyLoaded: allEntriesLoaded,
    loadMore: loadMoreEntries,
    error,
  } = useAPIInfinite(
    "schedulesListExternal",
    {
      includeNativeConfigPayload: true,
      pageSize: 15,
      search: debouncedSearch,
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    },
  );
  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !allEntriesLoaded,
    onLoadMore: loadMoreEntries,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const { integrations, integrationsError, integrationsLoading } =
    useIntegrations();

  const installedEscalator = integrations?.find(
    (x) =>
      (x.provider === IntegrationProvider.Opsgenie ||
        x.provider === IntegrationProvider.Pagerduty) &&
      x.installed,
  );

  if (error || integrationsError) {
    return <GenericErrorMessage error={error || integrationsError} />;
  }

  const schedules = responses.flatMap((r) => r.external_schedules);

  return (
    <Drawer onClose={onClose} width={"medium"}>
      <DrawerContents>
        <DrawerTitle
          title={"Import schedule"}
          onClose={onClose}
          icon={IconEnum.Download}
          iconClassName={"bg-content-secondary"}
        />
        {integrationsLoading ? (
          <Loader />
        ) : !installedEscalator ? (
          <EmptyState />
        ) : (
          <DrawerBody className={"flex flex-col p-6 h-full"}>
            <Input
              id={"search"}
              placeholder={"Search for a schedule"}
              type={InputType.Search}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value ?? "")
              }
              value={search}
              iconName={IconEnum.Search}
              iconProps={{
                id: IconEnum.Search,
                className: "text-content-tertiary",
              }}
              className={tcx(
                "rounded-lg justify-start items-center gap-2 inline-flex bg-surface-secondary",
                styles.searchBarInput,
              )}
            />
            <div>
              <StackedList>
                {schedules.map((schedule) => {
                  const isDisabled =
                    !!schedule.native_config_unavailable_reason ||
                    schedule.created_native_schedule_id;

                  const unavailableInfo =
                    schedule.native_config_unavailable_reason
                      ? UnavailableInfo[
                          schedule.native_config_unavailable_reason
                        ]
                      : null;

                  const hasMissingUsers =
                    schedule.native_config_unavailable_reason ===
                    ExternalScheduleNativeConfigUnavailableReasonEnum.MissingUserIds;

                  const missingUserCount =
                    schedule.unresolvable_external_users?.length || 0;
                  const hasMissingUsersLabel =
                    missingUserCount > 1
                      ? `${missingUserCount} unknown users`
                      : `${missingUserCount} unknown user`;

                  return (
                    <li
                      key={schedule.id}
                      className={tcx("p-4 w-full text-sm", {
                        "hover:bg-surface-secondary cursor-pointer":
                          !isDisabled,
                      })}
                      onClick={() => {
                        if (schedule.native_config_unavailable_reason) {
                          return;
                        }
                        navigate(
                          `/on-call/schedules/create?from_external_schedule=${schedule.id}`,
                        );
                      }}
                    >
                      <div className={"flex flex-row justify-between w-full"}>
                        <div
                          className={tcx({
                            "text-slate-300": isDisabled,
                          })}
                        >
                          <div className={"flex flex-row items-center"}>
                            <Icon
                              id={
                                schedule.external_provider ===
                                ExternalScheduleExternalProviderEnum.Pagerduty
                                  ? IconEnum.Pagerduty
                                  : IconEnum.Opsgenie
                              }
                              size={IconSize.Medium}
                              className={tcx("mr-2 shrink-0")}
                            />
                            {schedule.name}
                          </div>
                        </div>
                        {unavailableInfo ? (
                          <UnavailableScheduleInformation
                            hasMissingUsers={hasMissingUsers}
                            schedule={schedule}
                            unavailableInfo={unavailableInfo}
                            hasMissingUsersLabel={hasMissingUsersLabel}
                          />
                        ) : schedule.created_native_schedule_id ? (
                          <OrgAwareLink
                            to={`/on-call/schedules/${schedule.created_native_schedule_id}`}
                          >
                            <Badge theme={BadgeTheme.Info}>
                              Already imported{" "}
                              <Icon id={IconEnum.ExternalLink} />
                            </Badge>
                          </OrgAwareLink>
                        ) : (
                          <Icon
                            id={IconEnum.ChevronRight}
                            size={IconSize.Medium}
                          />
                        )}
                      </div>
                    </li>
                  );
                })}
                {allEntriesLoaded && schedules.length === 0 && (
                  <span className={"text-content-secondary p-4 text-center"}>
                    No schedules found
                  </span>
                )}
                {!allEntriesLoaded && (
                  <div ref={infiniteScrollRef}>
                    <Loader />
                  </div>
                )}
              </StackedList>
            </div>
          </DrawerBody>
        )}
      </DrawerContents>
    </Drawer>
  );
};

const UnavailableInfo: Record<
  ExternalScheduleNativeConfigUnavailableReasonEnum,
  {
    tooltip: string;
    badge: string;
  }
> = {
  [ExternalScheduleNativeConfigUnavailableReasonEnum.MissingUserIds]: {
    // We special case this tooltip for users to list out who is missing
    tooltip: ``,
    badge: `Unknown users`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval]: {
    tooltip: `This schedule has a recurrence interval that isn't yet supported by incident.io.`,
    badge: `Unsupported interval`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedRestrictions]: {
    tooltip: `This schedule has restrictions that aren't yet supported by incident.io.`,
    badge: `Unsupported restrictions`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.EndsInFuture]: {
    tooltip: `This schedule has rotations due to end in the future, which isn't supported.`,
    badge: `Ends in future`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.NoUsableLayers]: {
    tooltip: `This schedule has no usable layers.`,
    badge: `No usable layers`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.NoConfigSynced]: {
    tooltip: `We've not yet been able to pull the rotation configuration for this schedule - please check back later.`,
    badge: `No configuration synced`,
  },
  [ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedParticipants]: {
    tooltip: `This schedule has participants that aren't yet supported by incident.io, such as a team or escalation.`,
    badge: `Unsupported participants`,
  },
};

const EmptyState = () => {
  return (
    <div className={"p-6 space-y-4"}>
      <p>To import schedules you need to connect PagerDuty or Opsgenie.</p>
      <div className="flex flex-wrap gap-4">
        <Button
          analyticsTrackingId={`configure-pagerduty-for-import`}
          href="/settings/integrations/pagerduty"
          icon={IconEnum.Pagerduty}
        >
          Connect PagerDuty
        </Button>
        <Button
          analyticsTrackingId={`configure-opsgenie-for-import`}
          href="/settings/integrations/opsgenie"
          icon={IconEnum.Opsgenie}
        >
          Connect Opsgenie
        </Button>
      </div>
    </div>
  );
};

const UnavailableScheduleInformation = ({
  hasMissingUsers,
  schedule,
  unavailableInfo,
  hasMissingUsersLabel,
}: {
  hasMissingUsers: boolean;
  schedule: ExternalSchedule;
  unavailableInfo: {
    tooltip: string;
    badge: string;
  };
  hasMissingUsersLabel: string;
}) => {
  return (
    <Tooltip
      content={
        hasMissingUsers && schedule.unresolvable_external_users ? (
          <UnresolvedUsersTooltipContent
            users={schedule.unresolvable_external_users}
            provider={schedule.external_provider}
            entity={"this schedule"}
          />
        ) : (
          unavailableInfo.tooltip
        )
      }
    >
      {/* We need the div for the tooltip hover to work */}
      <div>
        <Badge theme={BadgeTheme.Warning} className="flex">
          {hasMissingUsers ? hasMissingUsersLabel : unavailableInfo.badge}
          <Icon
            size={IconSize.Small}
            className={tcx("ml-1")}
            id={IconEnum.Info}
          />
        </Badge>
      </div>
    </Tooltip>
  );
};

export const UnresolvedUsersTooltipContent = ({
  users,
  provider,
  entity,
}: {
  users: EmbeddedCatalogEntry[];
  provider: ExternalScheduleExternalProviderEnum;
  entity: string;
}) => {
  const providerLabel =
    provider === ExternalScheduleExternalProviderEnum.Pagerduty
      ? "PagerDuty"
      : "Opsgenie";

  const catalogTypeName =
    provider === ExternalScheduleExternalProviderEnum.Pagerduty
      ? "PagerDutyUser"
      : "OpsgenieUser";

  return (
    <div className="flex flex-col p-1 text-md">
      <span className="text-sm">
        There are {providerLabel} users referenced by {entity} that don&apos;t
        have connected incident.io accounts. Learn how to manually connect them{" "}
        <Link
          openInNewTab
          href={
            "https://help.incident.io/articles/6563490557-adding-connected-users-to-catalog"
          }
          analyticsTrackingId={"connected-users-helpcenter"}
        >
          here
        </Link>
        .
      </span>
      <ul className="my-1">
        {_.uniqBy(users ?? [], (u) => u.id).map((u, idx) => {
          return (
            <li key={idx} className="flex gap-1">
              &bull;
              <Button
                theme={ButtonTheme.Link}
                href={`/catalog/${catalogTypeName}/${u.id}`}
                openInNewTab
                analyticsTrackingId={"unresolved-external-users"}
              >
                {u.name}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
