import { IconEnum } from "@incident-ui";
import { cloneDeep } from "lodash";
import { useIdentity } from "src/contexts/IdentityContext";

import { AIHealthReportRoute } from "./ai-health-reports/AIHealthReportRoute";
import { AIRequestsRoute } from "./ai-requests/AIRequestsRoute";
import { CopilotThreadsRoute } from "./copilot/CopilotThreadsRoute";
import { DatasetsRoute } from "./datasets/DatasetsRoute";
import { WorkbenchInvestigationsRoute } from "./investigations/WorkbenchInvestigationsRoute";
import { ProcessorsRoute } from "./processors/ProcessorsRoute";
import { WorkbenchSuggestionsRoute } from "./suggestions/WorkbenchSuggestionsRoute";
import { VectorsRoute } from "./vectors/VectorsRoute";
import { WorkbenchOverviewPage } from "./WorkbenchOverviewPage";

export type WorkbenchPage = {
  label: string;
  slug: string;
  Component: () => React.ReactElement;
  icon: IconEnum;
};

export type WorkbenchSection = {
  label: string;
  items: WorkbenchPage[];
};

export const useWorkbenchPages = (): WorkbenchSection[] => {
  const { identity, isImpersonating } = useIdentity();

  if (!identity.organisation_is_staff && !isImpersonating) {
    return [];
  }

  const pages = [
    {
      label: "AI",
      items: [
        {
          label: "Copilot threads",
          slug: "threads", // Legacy, not worth fixing right now
          Component: CopilotThreadsRoute,
          icon: IconEnum.Robot,
        },
        {
          label: "Datasets",
          slug: "datasets",
          Component: DatasetsRoute,
          icon: IconEnum.Database,
        },
        {
          label: "Health reports",
          slug: "health-reports",
          Component: AIHealthReportRoute,
          icon: IconEnum.Doc,
        },
        {
          label: "Investigations",
          slug: "investigations",
          Component: WorkbenchInvestigationsRoute,
          icon: IconEnum.Bulb,
        },
        {
          label: "Processors",
          slug: "processors",
          Component: ProcessorsRoute,
          icon: IconEnum.Components,
        },
        {
          label: "Requests",
          slug: "requests",
          Component: AIRequestsRoute,
          icon: IconEnum.History,
        },
        {
          label: "Suggestions",
          slug: "suggestions",
          Component: WorkbenchSuggestionsRoute,
          icon: IconEnum.Nudge,
        },
        {
          label: "Vectors",
          slug: "vectors",
          Component: VectorsRoute,
          icon: IconEnum.ArrowRight,
        },
      ],
    },
  ];

  // The overview page needs to know about all the other pages, so we have to add it after
  // declaring the array to avoid recursion
  const WorkbenchOverview = () => (
    <WorkbenchOverviewPage sections={cloneDeep(pages)} />
  );
  pages.unshift({
    label: "",
    items: [
      {
        label: "Workbench",
        icon: IconEnum.Briefcase,
        slug: "overview",
        Component: WorkbenchOverview,
      },
    ],
  });

  return pages;
};
