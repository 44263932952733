import {
  CallParticipant,
  Incident,
  IncidentCallExternalProviderEnum,
  IncidentCallSessionWithSummary,
  IncidentCallsGetForLatestForIncidentResponseBody,
  IncidentCallsListCallSessionsWithSummariesForIncidentResponseBody,
  IncidentVisibilityEnum,
  Stream,
} from "@incident-io/api";
import scribeStyles from "@incident-shared/aiscribe/ScribeGradient.module.scss";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { isSameDay } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { getProviderNameAndLogo } from "src/components/incident-calls/helpers";
import { useHowLongAgoFromDate } from "src/components/incident-calls/hooks";
import { IncidentCallsDeleteModal } from "src/components/incident-calls/IncidentCallsDeleteModal";
import { stringToHash } from "src/utils/utils";

import { useIdentity } from "../../../../contexts/IdentityContext";
import {
  IncidentDrawer,
  IncidentHeaderModal,
} from "../../../../routes/legacy/IncidentRoute";
import { formatTimestampLocale } from "../../../../utils/datetime";
import { useNavigateToModal } from "../../../../utils/query-params";
import { useClipboard } from "../../../../utils/useClipboard";
import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../sidebar/AvatarList";
import { IncidentStackedList } from "./Components";

export const CallsStackedList = ({
  callData,
  pastCallNotes,
  isLoading,
  incident,
}: {
  callData?: IncidentCallsGetForLatestForIncidentResponseBody;
  pastCallNotes?: IncidentCallsListCallSessionsWithSummariesForIncidentResponseBody;
  isLoading: boolean;
  incident: Incident | Stream;
}) => {
  const { copyTextToClipboard } = useClipboard();
  const { featureLiveCallTranscription } = useFlags();
  const { identity } = useIdentity();

  const navigateToModal = useNavigateToModal();
  const { featurePastCallNotes } = useFlags();
  const navigateToCall = (callSessionID?: string) => {
    if (featurePastCallNotes && callSessionID) {
      // New world: navigate to the notes for a specific call session
      navigateToModal(`call-notes/${callSessionID}`);
    } else {
      // Old world: navigate to the most recent call notes for this incident
      navigateToModal(IncidentDrawer.CallNotes);
    }
  };
  const pastCallSessions = pastCallNotes?.call_sessions_with_summaries.filter(
    (callSession) =>
      callSession.call_session.id !== callData?.active_call_session?.id && // Exclude the active call session
      callSession.summary, // Exclude call sessions without a summary
  );
  const showPastCallNotes =
    featurePastCallNotes && pastCallSessions && pastCallSessions.length > 0;

  const incidentCall = callData?.incident_call;
  const activeCallSession = callData?.active_call_session;

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const updatedAgo = useHowLongAgoFromDate(
    activeCallSession?.current_topic_last_updated_at ?? new Date(),
  );

  const isPrivateInc = incident?.visibility === IncidentVisibilityEnum.Private;

  let listItemText;
  switch (incidentCall?.external_provider) {
    case IncidentCallExternalProviderEnum.MicrosoftTeamsOnlineMeetings:
      const { name } = getProviderNameAndLogo(incidentCall.external_provider);
      listItemText = name;
      break;
    case IncidentCallExternalProviderEnum.Zoom:
    case IncidentCallExternalProviderEnum.GoogleMeet:
      if (!activeCallSession) {
        const { name } = getProviderNameAndLogo(incidentCall.external_provider);
        listItemText = name;
      } else if (!activeCallSession.participants?.length) {
        listItemText = "Call in progress";
      } else {
        listItemText = `${activeCallSession.participants.length} participant${
          activeCallSession.participants.length > 1 ? "s" : ""
        }`;
      }
      break;
    case IncidentCallExternalProviderEnum.Other:
      listItemText = incidentCall.call_url;
      break;
    default:
      listItemText = "";
      break;
  }

  if (!incidentCall && !showPastCallNotes) {
    return null;
  }

  return (
    <>
      {incidentCall && (
        <>
          <IncidentStackedList.Section title="Call" loading={isLoading}>
            <IncidentStackedList.Item
              iconNode={
                <Icon
                  id={IconForCallProvider(incidentCall.external_provider)}
                />
              }
              title={
                <div className="flex gap-2 items-center">
                  <div className="truncate">{listItemText}</div>
                  {!!activeCallSession?.participants &&
                    activeCallSession.participants.length > 0 && (
                      <dl className="flex-center-y justify-between">
                        <AvatarList
                          users={activeCallSession.participants.map((p) =>
                            convertToMaybeUser(p),
                          )}
                          modalTitle={"Call participants"}
                          maxToShow={5}
                          clickableType={AvatarListClickableType.OnlyOnSeeMore}
                        />
                      </dl>
                    )}
                </div>
              }
              accessoryOne={
                <div className="flex gap-2">
                  <Button
                    href={incidentCall.call_url}
                    target="_blank"
                    theme={ButtonTheme.Tertiary}
                    size={BadgeSize.ExtraSmall}
                    icon={IconEnum.Call}
                    title="Join call"
                    analyticsTrackingId="join-incident-call"
                  >
                    Join
                  </Button>
                  {!isPrivateInc &&
                    featureLiveCallTranscription &&
                    activeCallSession && (
                      <Button
                        theme={ButtonTheme.UnstyledPill}
                        size={BadgeSize.ExtraSmall}
                        icon={IconEnum.Scribe}
                        title="View notes"
                        onClick={() => navigateToCall(activeCallSession?.id)}
                        analyticsTrackingId="view-incident-notes"
                        className="bg-alarmalade-50 hover:bg-alarmalade-100"
                        // TODO: RESP-7354 - Add scribe gradient version of icon instead of this.
                        iconProps={{ className: "text-alarmalade-500" }}
                      >
                        View notes
                      </Button>
                    )}
                </div>
              }
              accessoryTwo={
                <DropdownMenu
                  align="end"
                  screenReaderText="More options"
                  analyticsTrackingId="call-more-options"
                  triggerButtonTheme={ButtonTheme.Naked}
                  triggerIconSize={IconSize.Small}
                  triggerIcon={IconEnum.DotsVerticalNopad}
                >
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onSelect={() =>
                        navigateToModal(IncidentHeaderModal.UpdateCall)
                      }
                      analyticsTrackingId={"edit-incident-call"}
                      label="Edit"
                      icon={IconEnum.Edit}
                    />
                    <DropdownMenuItem
                      onSelect={() =>
                        copyTextToClipboard(incidentCall.call_url)
                      }
                      analyticsTrackingId={"copy-incident-call"}
                      label="Copy link"
                      icon={IconEnum.Copy}
                    />
                    {identity.organisation_is_demo && (
                      <DropdownMenuItem
                        icon={IconEnum.Sparkles}
                        analyticsTrackingId={null}
                        onSelect={() =>
                          navigateToModal(IncidentHeaderModal.DemoMagicCall)
                        }
                        label="Scribe demo"
                      />
                    )}
                  </DropdownMenuGroup>
                  <DropdownMenuItem
                    onSelect={() => setConfirmDeleteModalOpen(true)}
                    analyticsTrackingId={"delete-incident-call"}
                    label="Remove"
                    icon={IconEnum.Archive}
                    destructive
                  />
                </DropdownMenu>
              }
              footerAccessory={
                activeCallSession?.current_topic &&
                activeCallSession?.current_topic_last_updated_at &&
                featureLiveCallTranscription && (
                  <div className="flex flex-col gap-2">
                    <div
                      className={scribeStyles.textGradientAngled}
                      key={stringToHash(activeCallSession.current_topic)}
                    >
                      {activeCallSession.current_topic}
                    </div>
                    <div className="flex align-center gap-1 text-content-tertiary text-xs-med hover:!no-underline">
                      <span>{updatedAgo}</span>
                      <Tooltip
                        content={
                          "Calculated from recent conversations and updated every 2 minutes"
                        }
                      />
                    </div>
                  </div>
                )
              }
            />
          </IncidentStackedList.Section>
          <IncidentCallsDeleteModal
            incidentCall={incidentCall}
            isOpen={confirmDeleteModalOpen}
            onClose={() => setConfirmDeleteModalOpen(false)}
          />
        </>
      )}
      {showPastCallNotes && (
        <IncidentStackedList.Section
          loading={isLoading}
          title="Past call notes"
        >
          {pastCallSessions.map((callSession) => (
            <PastCallSessionStackedListItem
              key={callSession.call_session.id}
              navigateToCall={navigateToCall}
              callSession={callSession}
            />
          ))}
        </IncidentStackedList.Section>
      )}
    </>
  );
};

const PastCallSessionStackedListItem = ({
  callSession,
  navigateToCall,
}: {
  callSession: IncidentCallSessionWithSummary;
  navigateToCall: (callSessionID: string) => void;
}) => {
  const localCallStartDate = formatTimestampLocale({
    timestamp: callSession.call_session.started_at,
    dateStyle: "short",
  });

  const localCallStartTime = formatTimestampLocale({
    timestamp: callSession.call_session.started_at,
    timeStyle: "short",
  });

  const callStartDay = isSameDay(
    Date.now(),
    callSession.call_session.started_at,
  )
    ? "Today"
    : localCallStartDate;

  return (
    <IncidentStackedList.Item
      key={callSession.call_session.id}
      iconNode={<Icon id={IconEnum.Scribe} size={IconSize.Small} />}
      title={
        <div className="flex gap-1">
          <div className="text-xs-bold">
            {callStartDay + " " + localCallStartTime}
          </div>
          <div className="truncate">{callSession.concise_overview}</div>
        </div>
      }
      onClick={() => navigateToCall(callSession.call_session.id)}
      accessoryOne={null}
      accessoryTwo={
        <>
          {!!callSession.call_session.participants &&
            callSession.call_session.participants.length > 0 && (
              <dl className="flex-center-y justify-between">
                <AvatarList
                  users={callSession.call_session.participants.map((p) =>
                    convertToMaybeUser(p),
                  )}
                  modalTitle={"Call participants"}
                  maxToShow={5}
                  clickableType={AvatarListClickableType.OnlyOnSeeMore}
                />
              </dl>
            )}
        </>
      }
    />
  );
};

const convertToMaybeUser = (participant: CallParticipant): MaybeUser => {
  const maybeUser = {} as MaybeUser;

  if (participant.user) {
    maybeUser.user = participant.user;
  } else {
    maybeUser.nonUserLabel = participant.email;
  }

  return maybeUser;
};

export const IconForCallProvider = (
  provider: IncidentCallExternalProviderEnum,
): IconEnum => {
  switch (provider) {
    case IncidentCallExternalProviderEnum.Zoom:
      return IconEnum.Zoom;
    case IncidentCallExternalProviderEnum.GoogleMeet:
      return IconEnum.GoogleMeet;
    case IncidentCallExternalProviderEnum.MicrosoftTeamsOnlineMeetings:
      return IconEnum.MicrosoftTeams;
    case IncidentCallExternalProviderEnum.Other:
      return IconEnum.Call;
    default:
      return IconEnum.Call;
  }
};
