import { Incident } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { CommandPaletteItem } from "@incident-ui/CommandPalette/CommandPalette";
import { useContextualItems } from "@incident-ui/CommandPalette/CommandPaletteProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact } from "lodash";
import { IncidentModeEnum, useClient } from "src/contexts/ClientContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

import { useNavigateToModal } from "../../../../utils/query-params";
import { incidentInEditableStatus } from "../helpers";
import { useIncident } from "../hooks";
import { Category, isOneOfCategories } from "../statuses/status-utils";
import { CustomFieldEntries } from "./CustomFieldEntries";
import { useIncidentKeyDetailsRefresh } from "./hooks";
import { IncidentKeyDetails } from "./IncidentKeyDetails";
import { Links, useIncidentSidebarLinkProps } from "./Links";
import { PostMortemPrompt } from "./PostMortemPrompt";
import { IncidentRoleAssignments } from "./RoleAssignments";
import { TimestampsAndMetrics } from "./TimestampsAndMetrics";

export const SidebarDivider = () => {
  return <div className="border-t w-full border-stroke-secondary" />;
};

export const ProductAccessConditionalComponent = ({
  children,
  requiredProduct,
}: {
  children: React.ReactNode;
  requiredProduct?: Product;
}): React.ReactElement => {
  const { hasProduct } = useProductAccess();

  if (!requiredProduct) {
    return <>{children}</>;
  }

  if (hasProduct(requiredProduct)) {
    return <>{children}</>;
  }
  return <></>;
};

export function IncidentSidebar({
  incidentId,
  className,
  setModalOpen,
}: {
  incidentId: string | null;
  className?: string;
  setModalOpen?: (modal: IncidentHeaderModal) => void;
}): React.ReactElement {
  const { incident, applicableFields } = useIncident(incidentId);

  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();

  const navigateToModal = useNavigateToModal();

  const sidebarProps = useIncidentSidebarLinkProps({
    incidentId: incidentId || "",
  });

  useIncidentContextualItems({
    incident,
  });
  useIncidentKeyDetailsRefresh({ incidentId });

  const showPostmortemPrompt =
    incident &&
    incidentInEditableStatus(incident) &&
    !featurePostmortemsInHouse;

  const setModalOpenWrapper = (open: IncidentHeaderModal) => {
    if (!setModalOpen) {
      return;
    }

    setModalOpen(open);
  };

  const sections: {
    component: React.ReactElement;
    requiredProduct?: Product;
  }[] = compact([
    {
      component: (
        <IncidentKeyDetails
          incident={incident}
          setModalOpen={setModalOpenWrapper}
        />
      ),
    },
    {
      component: (
        <IncidentRoleAssignments
          key="role-assignments"
          incidentId={incidentId}
          onEdit={() =>
            navigateToModal(IncidentHeaderModal.EditRoleAssignments)
          }
        />
      ),
    },
    incident && {
      component: <Links incident={incident} {...sidebarProps} />,
    },
    incident &&
      incident.mode !== IncidentModeEnum.Tutorial &&
      incident.custom_field_entries.length > 0 && {
        requiredProduct: Product.Response,
        component: (
          <CustomFieldEntries
            incident={incident}
            onEdit={() => navigateToModal(IncidentHeaderModal.EditCustomFields)}
            entries={incident.custom_field_entries}
            applicableFields={applicableFields}
          />
        ),
      },
    incident?.incident_timestamps &&
      incident.incident_timestamps.length > 0 && {
        component: (
          <TimestampsAndMetrics
            incident={incident}
            timestampValues={incident.incident_timestamps}
            onEdit={() => navigateToModal(IncidentHeaderModal.EditTimestamps)}
            metrics={incident.duration_metrics || []}
          />
        ),
      },
    showPostmortemPrompt && {
      requiredProduct: Product.Response,
      component: <PostMortemPrompt incident={incident} />,
    },
  ]);

  return (
    <>
      <aside className={tcx("text-content-primary text-sm", className)}>
        <dl className={"pb-16 flex flex-col gap-5"}>
          {sections.map((section, i) => {
            return (
              <ProductAccessConditionalComponent
                key={`section-${i}`}
                requiredProduct={section.requiredProduct}
              >
                <div className="flex flex-col gap-5">
                  {i !== 0 && <SidebarDivider />}
                  {section.component}
                </div>
              </ProductAccessConditionalComponent>
            );
          })}
        </dl>
      </aside>
    </>
  );
}

const useIncidentContextualItems = ({
  incident,
}: {
  incident: Incident | null;
}) => {
  const { copyTextToClipboard } = useClipboard();
  const navigateToModal = useNavigateToModal();

  const apiClient = useClient();

  let items: CommandPaletteItem[] = [];
  if (incident) {
    items = [
      {
        label: "Request update",
        analyticsId: "action_incident_request_update",
        key: "action_incident_request_update",
        icon: IconEnum.Hand,
        onSelect: () => navigateToModal(IncidentHeaderModal.RequestUpdate),
      },
      {
        label: "Set severity",
        analyticsId: "action_incident_set_severity",
        key: "action_incident_set_severity",
        icon: IconEnum.Severity,
        onSelect: () => navigateToModal(IncidentHeaderModal.UpdateSeverity),
      },
      {
        label: "Rename incident",
        analyticsId: "action_incident_rename",
        key: "action_incident_rename",
        icon: IconEnum.Status,
        onSelect: () => navigateToModal(IncidentHeaderModal.RenameIncident),
      },
      {
        label: "Edit custom fields",
        analyticsId: "action_incident_edit_custom_fields",
        key: "action_incident_edit_custom_fields",
        icon: IconEnum.CustomField,
        onSelect: () => navigateToModal(IncidentHeaderModal.EditCustomFields),
      },
      {
        label: "Edit timestamps",
        analyticsId: "action_incident_edit_timestamps",
        key: "action_incident_edit_timestamps",
        icon: IconEnum.Clock,
        onSelect: () => navigateToModal(IncidentHeaderModal.EditTimestamps),
      },
      {
        label: "Edit role assignments",
        analyticsId: "action_incident_edit_role_assignments",
        key: "action_incident_edit_role_assignments",
        icon: IconEnum.Users,
        onSelect: () =>
          navigateToModal(IncidentHeaderModal.EditRoleAssignments),
      },
      {
        label: `Copy incident ID (${incident.id})`,
        analyticsId: "action_incident_copy_id",
        key: "action_incident_copy_id",
        icon: IconEnum.Copy,
        onSelect: () => copyTextToClipboard(incident.id),
      },
      {
        label: `Copy incident reference (INC-${incident.external_id})`,
        analyticsId: "action_incident_copy_reference",
        key: "action_incident_copy_reference",
        icon: IconEnum.Copy,
        onSelect: () => copyTextToClipboard(`INC-${incident.external_id}`),
      },
      {
        label: `Copy incident as a markdown link`,
        analyticsId: "action_incident_copy_as_markdown_link",
        key: "action_incident_copy_as_markdown_link",
        icon: IconEnum.Copy,
        onSelect: () =>
          copyTextToClipboard(
            `[INC-${incident.external_id} ${incident.name}](${window.location.origin}${window.location.pathname})`,
          ),
      },
      {
        label: "Add demo Scribe transcript",
        analyticsId: "action_incident_add_scribe_demo_magic",
        key: "action_incident_add_scribe_demo_magic",
        icon: IconEnum.Scribe,
        hideByDefault: true,
        onSelect: () => {
          apiClient.demoRunCommand({
            id: "create-scribe-call-transcript",
            runCommandRequestBody: { payload: { incident_id: incident.id } },
          });
        },
        shouldInclude: (identity) => {
          return identity.organisation_is_demo ?? false;
        },
      },
    ];

    if (
      isOneOfCategories([
        Category.Active,
        Category.PostIncident,
        Category.Closed,
      ])(incident.incident_status)
    ) {
      items.push({
        label: "Set status",
        analyticsId: "action_incident_set_status",
        key: "action_incident_set_status",
        icon: IconEnum.Status,
        onSelect: () => navigateToModal(IncidentHeaderModal.UpdateStatus),
      });
      items.push({
        label: "Resolve incident",
        analyticsId: "action_incident_resolve",
        key: "action_incident_resolve",
        icon: IconEnum.Tick,
        onSelect: () => navigateToModal(IncidentHeaderModal.Resolve),
      });
    }
  }

  const title = incident ? `INC-${incident.external_id} ${incident.name}` : "";

  useContextualItems(title, items);
};
