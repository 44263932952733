/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface PromptHealthReport
 */
export interface PromptHealthReport {
    /**
     * When the report was created.
     * @type {Date}
     * @memberof PromptHealthReport
     */
    created_at: Date;
    /**
     * Unique ID of the report
     * @type {string}
     * @memberof PromptHealthReport
     */
    id: string;
    /**
     * Number of prompts in this report
     * @type {number}
     * @memberof PromptHealthReport
     */
    num_prompts: number;
    /**
     * Number of AI requests in this report
     * @type {number}
     * @memberof PromptHealthReport
     */
    num_requests: number;
    /**
     * Total cost of all AI interactions in this report
     * @type {number}
     * @memberof PromptHealthReport
     */
    overall_cost_cents: number;
    /**
     * End of the period the report covers
     * @type {Date}
     * @memberof PromptHealthReport
     */
    period_end: Date;
    /**
     * Start of the period the report covers
     * @type {Date}
     * @memberof PromptHealthReport
     */
    period_start: Date;
    /**
     * Prompts with errors in this report
     * @type {{ [key: string]: number; }}
     * @memberof PromptHealthReport
     */
    prompts_with_errors: { [key: string]: number; };
    /**
     * URL to the report in GCS
     * @type {string}
     * @memberof PromptHealthReport
     */
    url: string;
    /**
     * 
     * @type {User}
     * @memberof PromptHealthReport
     */
    user?: User;
}

export function PromptHealthReportFromJSON(json: any): PromptHealthReport {
    return PromptHealthReportFromJSONTyped(json, false);
}

export function PromptHealthReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptHealthReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'num_prompts': json['num_prompts'],
        'num_requests': json['num_requests'],
        'overall_cost_cents': json['overall_cost_cents'],
        'period_end': (new Date(json['period_end'])),
        'period_start': (new Date(json['period_start'])),
        'prompts_with_errors': json['prompts_with_errors'],
        'url': json['url'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function PromptHealthReportToJSON(value?: PromptHealthReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'num_prompts': value.num_prompts,
        'num_requests': value.num_requests,
        'overall_cost_cents': value.overall_cost_cents,
        'period_end': (value.period_end.toISOString()),
        'period_start': (value.period_start.toISOString()),
        'prompts_with_errors': value.prompts_with_errors,
        'url': value.url,
        'user': UserToJSON(value.user),
    };
}

