import {
  AlertsDeletePriorityRequest,
  Priority,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import React from "react";

import { useIdentity } from "../../../contexts/IdentityContext";
import { useAPIMutation } from "../../../utils/swr";
import { DeletionConfirmationModal } from "../../settings/DeletionConfirmationModal";

export const PriorityListItem = ({
  priority,
  priorities,
  onEdit,
  canEdit = true,
}: {
  priority: Priority;
  priorities: Priority[];
  onEdit: () => void;
  canEdit?: boolean;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const showToast = useToast();

  const canEditSettings = canEdit && hasScope(ScopeNameEnum.AlertSchemaUpdate);

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const { trigger: setDefaultPriority } = useAPIMutation(
    "alertsListPriorities",
    {},
    async (apiClient, { id }: DefaultFormData) => {
      await apiClient.alertsSetDefaultPriority({ id });
    },
    {
      onError: (error) => {
        showToast({
          title: "Unable to set default priority",
          description: error.message,
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const { trigger: deletePriority, isMutating: isDeleting } = useAPIMutation(
    "alertsListPriorities",
    {},
    async (apiClient, data: AlertsDeletePriorityRequest) => {
      await apiClient.alertsDeletePriority(data);
    },
    {
      onError: (error) => {
        showToast({
          title: "Unable to delete priority",
          description: error.message,
          theme: ToastTheme.Error,
        });
      },
    },
  );

  priorities = _.orderBy(priorities, ["rank"], ["asc"]);
  const highestPriority = _.first(priorities);
  const lowestPriority = _.findLast(priorities);

  const hasMultiplePriorities = priorities.length > 1;

  function getPriorityEnum(
    thisPriority: Priority,
    priorities: Priority[],
  ): IconEnum {
    const index = priorities.indexOf(thisPriority);
    if (index === 0) {
      return IconEnum.HighAlertPriority;
    } else if (priorities.length > 2 && index < priorities.length - 1) {
      return IconEnum.MediumAlertPriority;
    } else {
      return IconEnum.LowAlertPriority;
    }
  }

  return (
    <>
      <SettingsListItem
        iconNode={
          <Badge
            size={BadgeSize.Medium}
            theme={BadgeTheme.Tertiary}
            icon={getPriorityEnum(priority, priorities)}
            className="font-mono"
          />
        }
        title={priority.name}
        badgeNode={
          <>
            {priority.slugs.map((slug) => (
              <Badge
                size={BadgeSize.ExtraSmall}
                theme={BadgeTheme.Tertiary}
                label={slug}
                key={slug}
              />
            ))}
            {hasMultiplePriorities && highestPriority?.id === priority.id && (
              <span className={"text-xs font-medium text-slate-600"}>
                HIGHEST
              </span>
            )}
            {hasMultiplePriorities && lowestPriority?.id === priority.id && (
              <span className={"text-xs font-medium text-slate-600"}>
                LOWEST
              </span>
            )}
          </>
        }
        description={
          priority.description ? (
            <TemplatedTextDisplay
              value={priority.description || ""}
              style={TemplatedTextDisplayStyle.Compact}
              className="text-slate-600 text-sm !leading-5"
            />
          ) : (
            <span className={"text-slate-600 text-sm"}>
              No description provided
            </span>
          )
        }
        accessory={
          <>
            {priority.is_default ? (
              <DefaultBadge priorities={priorities} />
            ) : null}
            <div className="flex items-center">
              <DropdownMenu
                triggerButtonTheme={ButtonTheme.Unstyled}
                analyticsTrackingId={"priority-list-item-dropdown-menu"}
                screenReaderText="More options"
                triggerIcon={IconEnum.DotsVertical}
                align="end"
              >
                <DropdownMenuItem
                  analyticsTrackingId={"priority-edit"}
                  onSelect={onEdit}
                  className="text-sm font-normal text-content-primary"
                  label={"Edit"}
                  disabled={!canEditSettings}
                  icon={IconEnum.Edit}
                />
                {priority.is_default ? null : (
                  <DropdownMenuItem
                    onSelect={() => {
                      setDefaultPriority({ id: priority.id });
                    }}
                    analyticsTrackingId={"priority-set-default"}
                    className="text-sm font-normal text-content-primary"
                    label={"Set as default"}
                    disabled={!canEditSettings}
                    icon={IconEnum.Success}
                  />
                )}
                <DropdownMenuItem
                  onSelect={() => setShowDeleteModal(true)}
                  analyticsTrackingId={"priority-delete"}
                  className="text-sm font-normal text-content-primary"
                  label={"Delete"}
                  disabled={!canEditSettings || priority.is_default}
                  icon={IconEnum.Delete2}
                />
              </DropdownMenu>
            </div>
          </>
        }
      />
      {showDeleteModal ? (
        <DeletionConfirmationModal
          resourceTitle={priority.name}
          onDelete={() => {
            deletePriority({ id: priority.id });
          }}
          isDeleting={isDeleting}
          isOpen={true}
          onClose={() => setShowDeleteModal(false)}
          title="Delete priority"
          deleteConfirmationContent={
            "Are you sure you want to delete this priority?"
          }
          analyticsTrackingId="delete-priority"
          fetchDependentResources={[
            {
              resource_type: `CatalogEntry["AlertPriority"]`,
              id: priority.id,
            },
          ]}
        />
      ) : null}
    </>
  );
};

const DefaultBadge = ({
  priorities,
}: {
  priorities: Priority[];
}): React.ReactElement | null => {
  // If there's only one priority, you can't change the default, and there's not a
  // lot of point in showing that it's the default.
  if (priorities.length < 2) {
    return null;
  }

  return (
    <Badge
      className="ml-2 rounded-xl text-blue-700 font-medium"
      theme={BadgeTheme.Info}
    >
      Default
    </Badge>
  );
};

type DefaultFormData = {
  id: string;
};
