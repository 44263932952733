import {
  Identity,
  Schedule,
  ScheduleConfig,
  ScheduleOverride,
  SchedulesCreateOverrideRequestBody,
} from "@incident-io/api";
import { DateTime } from "luxon";
import {
  OverrideData,
  OverrideFormData,
} from "src/components/legacy/on-call/schedules/overrides/OverrideCreateEditDrawer";
import { WithRequired } from "src/types/generic";

import { isOnCallUser } from "../../../../settings/users/users/utils";
import {
  dateAndTimestringToUtcInstant,
  getWeekdayFromDayOfWeek,
} from "../common/util";

export const getDefaultOverrideValues = ({
  overrideData,
  identity,
  schedule,
  now,
}: {
  overrideData: OverrideData;
  identity: Identity | null;
  schedule: Schedule;
  now: DateTime;
}): Partial<OverrideFormData> => {
  const userId = isOnCallUser(identity?.user_state)
    ? overrideData.userId !== identity?.user_id
      ? identity?.user_id
      : undefined
    : undefined;

  const fallbacks = getDefaultRotationAndLayerValues(
    now,
    schedule.timezone,
    schedule.config,
  );
  const layer_id = overrideData.layerId ?? fallbacks.layer_id;
  const rotation_id = overrideData.rotationId ?? fallbacks.rotation_id;

  return {
    start_at: overrideData.startAt
      ? overrideData.startAt < fallbacks.start_at
        ? fallbacks.start_at
        : overrideData.startAt
      : fallbacks.start_at,
    end_at: overrideData.endAt || now.plus({ day: 1 }).toJSDate(),
    user_id: userId,
    layer_id: layer_id as string,
    rotation_id: rotation_id as string,
  };
};
// getDefaultRotationAndLayerValues will return a rotation and layer to default to
// when creating an override. It will try to find the first rotation that we are
// _able to_ create an override for, based on working intervals and current time.
// It will also pick the start_at date based on the greater of the current time
// and the start of the next working interval, so that we default the user to
// a sensible start time.
const getDefaultRotationAndLayerValues = (
  now: DateTime,
  scheduleTimezone: string,
  scheduleConfig?: ScheduleConfig,
): WithRequired<Partial<OverrideFormData>, "start_at"> => {
  let rotation_id = "";
  let layer_id = "";
  let start_at = now;
  if (scheduleConfig?.rotations && scheduleConfig?.rotations.length > 0) {
    const nextAvailableStartByRotation: Record<string, DateTime> = {};

    // To find the next available start time, we need to find the next working interval
    // for each rotation, create a date that is "next {day} at {start_time}" starting
    // from `now`, and then pick the earliest one.
    for (const rotation of scheduleConfig.rotations) {
      if (rotation.working_intervals.length === 0) {
        nextAvailableStartByRotation[rotation.id] = now;
        continue;
      }

      for (const workingInterval of rotation.working_intervals) {
        // Here, we'll create a new date that is "next {day} at {start_time}" and then
        // compare it to the current time. We need to compare it to the current time
        // for the case where the working interval is _today_, but the start time is
        // _before_ the current time, in which case we want the start time to be
        // _now_ rather than the interval start (which is in the past).
        //
        // We also use our `dateAndLocalTimeToUtcInstant` function to convert the
        // local time to a UTC instant, so that we can compare it to the current time
        // in a timezone-agnostic way.
        let nextStart = now.set({
          weekday: getWeekdayFromDayOfWeek(workingInterval.weekday),
        });
        // If the next start weekday is less than the current weekday, we need to
        // add a week, since it'll be in the past.
        if (nextStart.weekday < now.weekday) {
          nextStart = nextStart.plus({ week: 1 });
        }

        let nextStartDate = dateAndTimestringToUtcInstant(
          nextStart,
          workingInterval.start_time,
          scheduleTimezone,
        );

        if (nextStartDate < now) {
          nextStartDate = now;
        }

        if (
          !nextAvailableStartByRotation[rotation.id] ||
          nextStartDate < nextAvailableStartByRotation[rotation.id]
        ) {
          nextAvailableStartByRotation[rotation.id] = nextStartDate;
        }
      }
    }

    // We've now got a record of the next available start time for each rotation.
    // We'll now find the earliest one and use that as the default start time,
    // and use the rotation and layer that it corresponds to. We want to
    // find the earliest start time, so we'll sort the keys by the value of
    // the start time, and then pick the first one.
    rotation_id = Object.keys(nextAvailableStartByRotation).sort((a, b) =>
      nextAvailableStartByRotation[a]
        .diff(nextAvailableStartByRotation[b])
        .as("millisecond"),
    )[0];

    const rotation = scheduleConfig.rotations.find((r) => r.id === rotation_id);
    if (rotation?.layers && rotation.layers.length > 0) {
      layer_id = rotation.layers[0].id;
    }
    start_at = nextAvailableStartByRotation[rotation_id];

    if (rotation_id === "") {
      rotation_id = scheduleConfig.rotations[0].id;
      if (
        scheduleConfig?.rotations &&
        scheduleConfig.rotations[0].layers?.length > 0
      ) {
        layer_id = scheduleConfig.rotations[0].layers[0].id;
      }
    }
  }
  return {
    rotation_id,
    layer_id,
    start_at: start_at.toJSDate(),
  };
};
export const parseFormData = (
  scheduleId: string,
  formData: OverrideFormData,
): SchedulesCreateOverrideRequestBody => {
  return {
    end_at: formData.end_at,
    start_at: formData.start_at,
    user_id: formData.user_id,
    layer_id: formData.layer_id,
    rotation_id: formData.rotation_id,
    schedule_id: scheduleId,
  };
};
export const parseOverride = (
  override: ScheduleOverride,
  now: DateTime,
): OverrideFormData => {
  const nowDate = now.toJSDate();
  return {
    end_at: override.end_at,
    start_at: override.start_at < nowDate ? nowDate : override.start_at,
    user_id: override.user_id,
    rotation_id: override.rotation_id,
    layer_id: override.layer_id,
    natural_language: "",
  };
};
