import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Loader } from "@incident-ui";
import React from "react";
import { useParams } from "react-router";
import { IncidentOnCallOnlyDetailsPage } from "src/components/legacy/incident/IncidentOnCallOnlyDetailsPage";
import { useProductAccess } from "src/hooks/useProductAccess";

import { useIncident } from "../../components/legacy/incident/hooks";
import { IncidentDetailsPage } from "../../components/legacy/incident/IncidentDetailsPage";
import { PrivateIncidentNoAccess } from "../../components/legacy/incident/PrivateIncidentNoAccess/PrivateIncidentNoAccess";
import { useAPI } from "../../utils/swr";

export enum IncidentHeaderModal {
  AddRelatedIncident = "add-related-incident",
  Cancel = "cancel",
  ChangePrivacy = "change-privacy",
  CreatePostmortem = "create-postmortem",
  DemoMagicCall = "demo-magic-call",
  EditCustomFields = "edit-custom-fields",
  EditRoleAssignments = "edit-role-assignments",
  EditTimestamps = "edit-timestamps",
  GiveShoutout = "give-shoutout",
  ManageAccess = "manage-access",
  OptOutOfPostIncident = "opt-out-of-post-incident",
  RunWorkflow = "run-workflow",
  RunInvestigation = "run-investigation",
  RenameIncident = "rename-incident",
  RequestUpdate = "request-update",
  Resolve = "resolve",
  ScheduleDebrief = "schedule-debrief",
  UpdateCall = "call-url",
  SharePostmortem = "share-postmortem",
  UpdateIncident = "update-incident",
  UpdateIncidentType = "update-incident-type",
  UpdateIsTest = "update-is-test",
  UpdateSeverity = "update-severity",
  UpdateStatus = "update-status",
}

export enum IncidentDrawer {
  Alerts = "alerts",
  Attachments = "attachments",
  CallNotes = "call-notes",
  Debriefs = "debriefs",
  Escalate = "escalate",
  Escalations = "escalations",
  RelatedIncidents = "related-incidents",
  Streams = "streams",
  CatalogEntry = "catalog-entry",
}

export enum StreamModal {
  EditRoleAssignments = "edit-role-assignments",
  ManageAccess = "manage-access",
  RequestUpdate = "request-update",
  UpdateCall = "call-url",
}

export function IncidentRoute(): React.ReactElement {
  const { id: incidentExternalId } = useParams();

  const { data, error: idError } = useAPI(
    incidentExternalId ? "incidentsShowInternalID" : null,
    {
      id: incidentExternalId as string,
    },
    {
      // Disable revalidations here: this is an immutable mapping
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { hasResponse } = useProductAccess();

  const { error: incidentError } = useIncident(data ? data.incident.id : null);

  if (!incidentExternalId) {
    return <Loader />;
  }

  // Handle errors from either show API here, to detect changed permissions
  const error = idError || incidentError;

  if (error?.status === 404) {
    return <OrgAwareNavigate to="/404" replace />;
  }
  if (error?.status === 403) {
    return <PrivateIncidentNoAccess externalId={incidentExternalId} />;
  }

  if (!hasResponse) {
    return (
      <IncidentOnCallOnlyDetailsPage
        incidentId={data ? data.incident.id : null}
        externalId={
          data ? data.incident.external_id : parseInt(incidentExternalId)
        }
      />
    );
  }

  return (
    <IncidentDetailsPage
      incidentId={data ? data.incident.id : null}
      externalId={
        data ? data.incident.external_id : parseInt(incidentExternalId)
      }
    />
  );
}
