import { usePylon } from "@bolasim/react-use-pylon";
import {
  IntegrationSettingsProviderEnum as IntegrationProvider,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  IntegrationConfigFor,
  IntegrationsListObject,
} from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

import { useIdentity } from "../../../../../contexts/IdentityContext";

export const ZoomConnectModal = ({
  integration,
  onClose,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
}) => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();
  const { identity } = useIdentity();
  const config = IntegrationConfigFor(IntegrationProvider.Zoom);

  return (
    <Modal
      isOpen
      title={`Connect to ${config.label}`}
      analyticsTrackingId="connect-integration-deeplink"
      analyticsTrackingMetadata={{ integration: IntegrationProvider.Zoom }}
      onClose={onClose}
    >
      <ModalContent>
        <div className="flex flex-col space-y-3">
          <p>
            We&apos;re about to send you to {config.label}, where (if you have
            permission) you can set up the incident.io integration.
          </p>
          <Callout theme={CalloutTheme.Info} className="mb-2">
            <p>
              To access our full range of incident calls features, you&apos;ll
              need to:
            </p>
            <p>
              &bull; Install with an account that has role permissions to view
              users
            </p>
            <p>
              &bull; Check the box &quot;Allow this app to use my shared access
              permissions&quot;.
            </p>
          </Callout>
          <p>
            We&apos;ll create every call on behalf of the connected user, so we
            strongly advise{" "}
            <Button
              theme={ButtonTheme.Link}
              onClick={() => showArticle("6286858410")}
              analyticsTrackingId="zoom-connection-help-page"
            >
              using a service account
            </Button>{" "}
            to connect to Zoom.
          </p>
        </div>
      </ModalContent>
      <ModalFooter>
        <GatedButton
          href={integration.add_integration_url}
          analyticsTrackingId="settings-integrations-install"
          upgradeRequiredProps={{
            gate: {
              type: "numeric",
              value: identity.feature_gates.integrations_count,
              featureNameSingular: "integration",
            },
            featureName: "integrations",
          }}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          theme={ButtonTheme.Primary}
        >
          Connect
        </GatedButton>
      </ModalFooter>
    </Modal>
  );
};
