/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalationPathNodeRepeat
 */
export interface EscalationPathNodeRepeat {
    /**
     * How many times to repeat these nodes
     * @type {number}
     * @memberof EscalationPathNodeRepeat
     */
    repeat_times: number;
    /**
     * Which node ID we begin repeating from.
     * @type {string}
     * @memberof EscalationPathNodeRepeat
     */
    to_node: string;
}

export function EscalationPathNodeRepeatFromJSON(json: any): EscalationPathNodeRepeat {
    return EscalationPathNodeRepeatFromJSONTyped(json, false);
}

export function EscalationPathNodeRepeatFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathNodeRepeat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repeat_times': json['repeat_times'],
        'to_node': json['to_node'],
    };
}

export function EscalationPathNodeRepeatToJSON(value?: EscalationPathNodeRepeat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repeat_times': value.repeat_times,
        'to_node': value.to_node,
    };
}

