import type { SVGProps } from "react";
import * as React from "react";
const SvgStop = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#39393F"
      d="M15.1 3.721 3.72 15.104l1.178 1.178L16.28 4.9l-1.178-1.179Z"
    />
    <path
      fill="#39393F"
      d="M10.002 18.887c-4.9 0-8.889-3.988-8.889-8.889 0-4.9 3.988-8.889 8.89-8.889 4.9 0 8.888 3.988 8.888 8.89 0 4.9-3.988 8.888-8.889 8.888Zm0-16.111c-3.982 0-7.222 3.24-7.222 7.222s3.24 7.223 7.222 7.223 7.222-3.24 7.222-7.223c0-3.982-3.24-7.222-7.222-7.222Z"
    />
  </svg>
);
export default SvgStop;
