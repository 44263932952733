/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcknowledgedTimelineData,
    AcknowledgedTimelineDataFromJSON,
    AcknowledgedTimelineDataFromJSONTyped,
    AcknowledgedTimelineDataToJSON,
} from './AcknowledgedTimelineData';
import {
    CancelledTimelineData,
    CancelledTimelineDataFromJSON,
    CancelledTimelineDataFromJSONTyped,
    CancelledTimelineDataToJSON,
} from './CancelledTimelineData';
import {
    ConditionsEvaluatedTimelineData,
    ConditionsEvaluatedTimelineDataFromJSON,
    ConditionsEvaluatedTimelineDataFromJSONTyped,
    ConditionsEvaluatedTimelineDataToJSON,
} from './ConditionsEvaluatedTimelineData';
import {
    EscalatedTimelineChannelData,
    EscalatedTimelineChannelDataFromJSON,
    EscalatedTimelineChannelDataFromJSONTyped,
    EscalatedTimelineChannelDataToJSON,
} from './EscalatedTimelineChannelData';
import {
    EscalatedTimelineLevelData,
    EscalatedTimelineLevelDataFromJSON,
    EscalatedTimelineLevelDataFromJSONTyped,
    EscalatedTimelineLevelDataToJSON,
} from './EscalatedTimelineLevelData';
import {
    EscalatedTimelineUserNotification,
    EscalatedTimelineUserNotificationFromJSON,
    EscalatedTimelineUserNotificationFromJSONTyped,
    EscalatedTimelineUserNotificationToJSON,
} from './EscalatedTimelineUserNotification';
import {
    EscalationCreatedTimelineData,
    EscalationCreatedTimelineDataFromJSON,
    EscalationCreatedTimelineDataFromJSONTyped,
    EscalationCreatedTimelineDataToJSON,
} from './EscalationCreatedTimelineData';
import {
    NackedTimelineData,
    NackedTimelineDataFromJSON,
    NackedTimelineDataFromJSONTyped,
    NackedTimelineDataToJSON,
} from './NackedTimelineData';
import {
    TriggeredTimelineData,
    TriggeredTimelineDataFromJSON,
    TriggeredTimelineDataFromJSONTyped,
    TriggeredTimelineDataToJSON,
} from './TriggeredTimelineData';

/**
 * 
 * @export
 * @interface EscalationTimelineItemContent
 */
export interface EscalationTimelineItemContent {
    /**
     * 
     * @type {AcknowledgedTimelineData}
     * @memberof EscalationTimelineItemContent
     */
    acknowledged?: AcknowledgedTimelineData;
    /**
     * 
     * @type {EscalatedTimelineUserNotification}
     * @memberof EscalationTimelineItemContent
     */
    called_user?: EscalatedTimelineUserNotification;
    /**
     * 
     * @type {CancelledTimelineData}
     * @memberof EscalationTimelineItemContent
     */
    cancelled?: CancelledTimelineData;
    /**
     * 
     * @type {ConditionsEvaluatedTimelineData}
     * @memberof EscalationTimelineItemContent
     */
    conditions_evaluated?: ConditionsEvaluatedTimelineData;
    /**
     * 
     * @type {EscalationCreatedTimelineData}
     * @memberof EscalationTimelineItemContent
     */
    created?: EscalationCreatedTimelineData;
    /**
     * 
     * @type {EscalatedTimelineChannelData}
     * @memberof EscalationTimelineItemContent
     */
    escalated_channel?: EscalatedTimelineChannelData;
    /**
     * 
     * @type {EscalatedTimelineLevelData}
     * @memberof EscalationTimelineItemContent
     */
    escalated_level?: EscalatedTimelineLevelData;
    /**
     * 
     * @type {NackedTimelineData}
     * @memberof EscalationTimelineItemContent
     */
    nacked?: NackedTimelineData;
    /**
     * 
     * @type {TriggeredTimelineData}
     * @memberof EscalationTimelineItemContent
     */
    triggered?: TriggeredTimelineData;
}

export function EscalationTimelineItemContentFromJSON(json: any): EscalationTimelineItemContent {
    return EscalationTimelineItemContentFromJSONTyped(json, false);
}

export function EscalationTimelineItemContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationTimelineItemContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acknowledged': !exists(json, 'acknowledged') ? undefined : AcknowledgedTimelineDataFromJSON(json['acknowledged']),
        'called_user': !exists(json, 'called_user') ? undefined : EscalatedTimelineUserNotificationFromJSON(json['called_user']),
        'cancelled': !exists(json, 'cancelled') ? undefined : CancelledTimelineDataFromJSON(json['cancelled']),
        'conditions_evaluated': !exists(json, 'conditions_evaluated') ? undefined : ConditionsEvaluatedTimelineDataFromJSON(json['conditions_evaluated']),
        'created': !exists(json, 'created') ? undefined : EscalationCreatedTimelineDataFromJSON(json['created']),
        'escalated_channel': !exists(json, 'escalated_channel') ? undefined : EscalatedTimelineChannelDataFromJSON(json['escalated_channel']),
        'escalated_level': !exists(json, 'escalated_level') ? undefined : EscalatedTimelineLevelDataFromJSON(json['escalated_level']),
        'nacked': !exists(json, 'nacked') ? undefined : NackedTimelineDataFromJSON(json['nacked']),
        'triggered': !exists(json, 'triggered') ? undefined : TriggeredTimelineDataFromJSON(json['triggered']),
    };
}

export function EscalationTimelineItemContentToJSON(value?: EscalationTimelineItemContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acknowledged': AcknowledgedTimelineDataToJSON(value.acknowledged),
        'called_user': EscalatedTimelineUserNotificationToJSON(value.called_user),
        'cancelled': CancelledTimelineDataToJSON(value.cancelled),
        'conditions_evaluated': ConditionsEvaluatedTimelineDataToJSON(value.conditions_evaluated),
        'created': EscalationCreatedTimelineDataToJSON(value.created),
        'escalated_channel': EscalatedTimelineChannelDataToJSON(value.escalated_channel),
        'escalated_level': EscalatedTimelineLevelDataToJSON(value.escalated_level),
        'nacked': NackedTimelineDataToJSON(value.nacked),
        'triggered': TriggeredTimelineDataToJSON(value.triggered),
    };
}

