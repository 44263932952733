import { Mode } from "@incident-shared/forms/v2/formsv2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { OrgAwareNavLink } from "@incident-shared/org-aware";
import {
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import { sortBy } from "lodash";
import { useState } from "react";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { UpsellNotice } from "../../UpsellNotice";
import { RoleCreateEditModalInner } from "./RoleCreateEditModal";
import { RoleList } from "./RoleList";

export const RoleListPage = () => {
  const { identity } = useIdentity();
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);

  const { data: privilegesResp, error: privilegesError } = useAPI(
    "rBACRolesListPrivileges",
    undefined,
  );

  const {
    data: rolesResp,
    mutate: refetchRoles,
    error: rolesError,
  } = useAPI("rBACRolesList", undefined);
  const refetchUsers = useRevalidate(["usersList"]);

  if (privilegesError || rolesError) {
    return <GenericErrorMessage error={privilegesError || rolesError} />;
  }
  if (!rolesResp || !privilegesResp) {
    return <Loader />;
  }

  // Most important rules go first
  const baseRoles = sortBy(
    rolesResp.rbac_roles.filter(({ is_base_role }) => is_base_role),
    "rank",
  ).reverse();

  // Sort custom roles by name, they all rank as zero
  const customRoles = sortBy(
    rolesResp.rbac_roles.filter(({ is_base_role }) => !is_base_role),
    "name",
  );

  const accessory = (
    <GatedButton
      onClick={() => setShowCreateRoleModal(true)}
      analyticsTrackingId="add-role"
      icon={IconEnum.Add}
      theme={ButtonTheme.Secondary}
      requiredScope={ScopeNameEnum.CustomRbacRolesCreate}
    >
      Add role
    </GatedButton>
  );

  return (
    <>
      <div className="mt-5 space-y-5">
        <RoleList
          title="Base roles"
          explanation={`Each user will have a base role, which gives them certain permissions. These roles cannot be renamed, but you can edit the permissions associated with them.`}
          roles={baseRoles}
          allPrivileges={privilegesResp.rbac_privileges}
          roleCounts={rolesResp.rbac_base_role_counts}
          refetchRoles={refetchRoles}
          emptyStateText="There are no base roles in this account. Please contact support@incident.io."
        />
        <Callout theme={CalloutTheme.Info} iconOverride={IconEnum.New}>
          Looking for seat usage? This has moved to{" "}
          <OrgAwareNavLink className="underline" to="/settings/billing">
            Settings → Billing
          </OrgAwareNavLink>
          .
        </Callout>
        {!identity?.feature_gates.rbac_custom_roles ? (
          <UpsellNotice
            title={"Custom roles"}
            planName={"Enterprise"}
            description={
              <>
                {
                  "Use custom roles to provide additional permissions to users. For example:"
                }
                <ul className="p-2 pl-4 list-disc">
                  <li>
                    An <b>Engineer</b> custom role could grant permission to
                    manage API keys and webhooks
                  </li>
                  <li>
                    A <b>Finance</b> custom role could grant permission to
                    manage billing settings and on-call pay reports
                  </li>
                  <li>
                    A <b>Security</b> custom role could grant permission to see
                    all private incidents
                  </li>
                </ul>
              </>
            }
            articleId={"5656642159"}
            analyticsId={"custom-roles"}
          />
        ) : (
          <>
            <RoleList
              title="Custom roles"
              roles={customRoles}
              explanation={
                "You can create custom roles to control permissions at a more granular level. The permissions granted by a custom role will be in addition to the permissions granted by the user's base role."
              }
              allPrivileges={privilegesResp.rbac_privileges}
              roleCounts={rolesResp.rbac_base_role_counts}
              refetchRoles={refetchRoles}
              emptyStateText="You haven't created any custom roles yet"
              accessory={accessory}
            />
          </>
        )}
      </div>
      {showCreateRoleModal && (
        <RoleCreateEditModalInner
          privileges={privilegesResp.rbac_privileges}
          onClose={() => {
            setShowCreateRoleModal(false);
            refetchUsers();
            refetchRoles();
          }}
          mode={Mode.Create}
        />
      )}
    </>
  );
};
