import { Toggle, Tooltip } from "@incident-ui";
import { ToggleProps } from "@incident-ui/Toggle/Toggle";
import styles from "@incident-ui/Toggle/Toggle.module.scss";
import {
  FieldValues,
  Path,
  useController,
  UseFormReturn,
} from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const ToggleV2 = <TFormType extends FieldValues>(
  props: InputElementProps<
    TFormType,
    Omit<ToggleProps, "onToggle" | "on"> & { idOverride?: string }
  >,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    Omit<ToggleProps, "onToggle" | "on"> & { idOverride?: string }
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  // Toggle is a bit odd as you don't really want the label to be in the input wrapper,
  // so we can fudge it:
  wrapperProps.label = undefined;
  inputProps.label = props.label;

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      {/* @ts-expect-error it's upset about the labelledById, and I don't think that matters much right now */}
      <Toggle
        {...field}
        // We need the ID override as our Toggle component is sad if the IDs aren't unique. This is
        // a little fudge so that when we have a load of 'enabled' toggles on a settings page, it all
        // works as expected.
        {...inputProps}
        id={inputProps.idOverride || name}
        on={field.value}
        onToggle={() => {
          const newValue = !field.value;
          field.onChange(newValue);

          onValueChange && onValueChange(newValue);
        }}
      />
    </FormInputWrapper>
  );
};

// This component is a bit odd as it renders its own label and helptext
// (so we don't want a wrapper), and the _entire container_ is clickable and
// controls the input, so the actual toggle is just a dummy visual indicator.
// I have foregone implementing support for all of the props we pass to our
// usual inputs, as some of them don't make sense, but they should be straightforward
// to add as needed.
export const ToggleRowV2 = <TFormType extends FieldValues>(props: {
  name: Path<TFormType>;
  formMethods: UseFormReturn<TFormType>;
  label: string;
  description?: React.ReactNode;
  displayFullDescription?: boolean;
  disabled?: boolean;
  className?: string;
}): React.ReactElement => {
  const { field } = useController({
    name: props.name,
  });

  const on = field.value as unknown as boolean;
  return (
    <div
      className={tcx(
        styles.checkbox,
        "flex flex-col gap-1 justify-between border group/check border-stroke rounded-2 shadow-sm p-4 pl-5",
        {
          "cursor-not-allowed": props.disabled,
          "cursor-pointer hover:bg-slate-50": !props.disabled,
        },
        props.className,
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (!props.disabled) {
          field.onChange(!field.value);
        }
      }}
    >
      <span className="w-full font-medium mr-auto flex gap-2 items-center justify-between">
        <span className="space-x-2">
          {props.label}
          {props.description && !props.displayFullDescription && (
            <Tooltip content={props.description} />
          )}
        </span>

        <span
          className={tcx(styles.toggle, "self-end border shrink-0", {
            "bg-surface-invert border-slate-900": on,
            "bg-surface-tertiary border-stroke": !on,
            [styles.disabled]: props.disabled,
          })}
        >
          <input
            type="checkbox"
            id={props.name}
            checked={on}
            disabled={props.disabled}
          />
          <span
            className={tcx(styles.slider, "bg-white border", {
              "border-slate-900": on && !props.disabled,
              "border-slate-500": !on || props.disabled,
            })}
            aria-hidden="true"
          />
        </span>
      </span>
      {props.description && props.displayFullDescription && (
        <div className="text-xs-med text-content-secondary mr-12 text-pretty">
          {props.description}
        </div>
      )}
    </div>
  );
};

export const ToggleEnumV2 = <
  TFormType extends FieldValues,
  TEnumType extends string,
>(
  props: InputElementProps<
    TFormType,
    Omit<ToggleProps, "onToggle" | "on" | "onValueChange"> & {
      idOverride?: string;
      trueValue: TEnumType;
      falseValue: TEnumType;
      onValueChange?: (value: TEnumType) => void;
    }
  >,
): React.ReactElement => {
  const { onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps<
    TFormType,
    Omit<ToggleProps, "onToggle" | "on"> & { idOverride?: string }
  >(rest);
  const { field } = useController({
    name,
    rules,
  });

  // Toggle is a bit odd as you don't really want the label to be in the input wrapper,
  // so we can fudge it:
  wrapperProps.label = undefined;
  inputProps.label = props.label;

  const boolToEnumValue = (bool: boolean) =>
    bool ? props.trueValue : props.falseValue;
  const enumToBoolValue = (enumValue: TEnumType) =>
    enumValue === props.trueValue;

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      {/* @ts-expect-error it's upset about the labelledById, and I don't think that matters much right now */}
      <Toggle
        {...field}
        // We need the ID override as our Toggle component is sad if the IDs aren't unique. This is
        // a little fudge so that when we have a load of 'enabled' toggles on a settings page, it all
        // works as expected.
        {...inputProps}
        id={inputProps.idOverride || name}
        on={enumToBoolValue(field.value)}
        onToggle={() => {
          const currentBoolValue = enumToBoolValue(field.value);
          const newEnumValue = boolToEnumValue(!currentBoolValue);

          field.onChange(newEnumValue);

          onValueChange && onValueChange(newEnumValue);
        }}
      />
    </FormInputWrapper>
  );
};
