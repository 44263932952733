/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * URL of organisation icon
     * @type {string}
     * @memberof Session
     */
    organisation_icon_url?: string;
    /**
     * Unique identifier for organisation
     * @type {string}
     * @memberof Session
     */
    organisation_id: string;
    /**
     * Whether the organisation is the staff organisation
     * @type {boolean}
     * @memberof Session
     */
    organisation_is_staff?: boolean;
    /**
     * Human name of the organisation
     * @type {string}
     * @memberof Session
     */
    organisation_name: string;
    /**
     * Human readable unique identifier for organisation
     * @type {string}
     * @memberof Session
     */
    organisation_slug: string;
    /**
     * The staff permissions this user has.
     * @type {Array<string>}
     * @memberof Session
     */
    staff_permissions?: Array<SessionStaffPermissionsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum SessionStaffPermissionsEnum {
    AdjustPlan = 'adjust_plan',
    AttachSubscription = 'attach_subscription',
    BootstrapCustomerChannel = 'bootstrap_customer_channel',
    GrantStaffPermission = 'grant_staff_permission',
    Impersonate = 'impersonate',
    ImpersonateTrialAndDemo = 'impersonate_trial_and_demo',
    ManageFeatureAccess = 'manage_feature_access',
    ManageTrial = 'manage_trial',
    RenameOrganisation = 'rename_organisation',
    TeardownOrganisation = 'teardown_organisation',
    TeardownOnCallForOrganisation = 'teardown_on_call_for_organisation',
    EnableOnCallForOrganisation = 'enable_on_call_for_organisation',
    TeardownResponseForOrganisation = 'teardown_response_for_organisation',
    EnableResponseForOrganisation = 'enable_response_for_organisation',
    MarkSandboxAndDemo = 'mark_sandbox_and_demo',
    GrantOwnerRole = 'grant_owner_role',
    ViewChatContext = 'view_chat_context',
    ImportStatusPageSubscribers = 'import_status_page_subscribers'
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisation_icon_url': !exists(json, 'organisation_icon_url') ? undefined : json['organisation_icon_url'],
        'organisation_id': json['organisation_id'],
        'organisation_is_staff': !exists(json, 'organisation_is_staff') ? undefined : json['organisation_is_staff'],
        'organisation_name': json['organisation_name'],
        'organisation_slug': json['organisation_slug'],
        'staff_permissions': !exists(json, 'staff_permissions') ? undefined : json['staff_permissions'],
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisation_icon_url': value.organisation_icon_url,
        'organisation_id': value.organisation_id,
        'organisation_is_staff': value.organisation_is_staff,
        'organisation_name': value.organisation_name,
        'organisation_slug': value.organisation_slug,
        'staff_permissions': value.staff_permissions,
    };
}

