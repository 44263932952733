import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import * as ReactTooltip from "@radix-ui/react-tooltip";
import React, {
  forwardRef,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useMouse } from "src/hooks/useMouse";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Tooltip.module.scss";

type Props = {
  /** Text to display in the bubble */
  content: React.ReactNode;
  /** The trigger element (should be a button). Leave blank to use default i icon */
  children?: React.ReactElement;
  analyticsTrackingId?: string | null;
  /** How long in ms before we should show the bubble */
  delayDuration?: number;
  /** Which side to show the bubble */
  side?: TooltipSide;
  sideOffset?: number;
  align?: TooltipAlign;
  alignOffset?: number;
  bubbleProps?: Omit<BubbleProps, "children">;
  buttonClassName?: string;
  iconSize?: IconSize;
  light?: boolean;
  /** Manually set the tooltip to open/closed */
  openStateOverride?: boolean;
  // When you want the tooltip to follow your mouse, set this prop. In order to
  // center the tooltip bubble, you must also set a width which is the width of
  // your tooltip content.
  followMousePosition?: boolean;
  disabled?: boolean;
  noMaxWidth?: boolean;
};

export type TooltipSide = "left" | "right" | "top" | "bottom";

export type TooltipAlign = "start" | "center" | "end";

// Quite a hack, but radix don't expose a way to close the tooltip.
// But they do close tooltips when another tooltip is opened.
// https://github.com/radix-ui/primitives/blob/main/packages/react/tooltip/src/Tooltip.tsx#L506-L510
export const closeTooltips = () => {
  document.dispatchEvent(new CustomEvent("tooltip.open"));
};

export function Tooltip({
  content,
  children,
  analyticsTrackingId,
  delayDuration = 150,
  bubbleProps,
  buttonClassName,
  iconSize,
  light,
  openStateOverride,
  followMousePosition,
  noMaxWidth,
  disabled,
  ...restProps
}: Props): React.ReactElement | null {
  const { ref: childContainerRef, x } = useMouse({
    enabled: followMousePosition,
  });
  const tooltipBubbleRef = useRef<HTMLDivElement>(null);

  // 230 is a good initial value
  const [tooltipBubbleWidth, setTooltipBubbleWidth] = useState(230);
  useLayoutEffect(() => {
    if (!tooltipBubbleRef.current) {
      return;
    }
    if (followMousePosition) {
      setTooltipBubbleWidth(
        tooltipBubbleRef.current?.getBoundingClientRect().width,
      );
    }
  }, [followMousePosition, x, content]);

  if (content == null || content === "" || content === false || disabled) {
    return children || null;
  }

  const { className: bubbleClassName, ...restBubbleProps } = bubbleProps ?? {};

  return (
    <TooltipContext.Provider value={{ close: closeTooltips }}>
      <ReactTooltip.Provider delayDuration={delayDuration}>
        {/* If you're debugging, you can pass `open` into this component to force
      it to stay open. */}
        <ReactTooltip.Root
          open={openStateOverride !== undefined ? openStateOverride : undefined}
        >
          {children ? (
            <ReactTooltip.Trigger asChild ref={childContainerRef}>
              {children}
            </ReactTooltip.Trigger>
          ) : (
            <TooltipButton
              className={buttonClassName}
              analyticsTrackingId={analyticsTrackingId || null}
              iconSize={iconSize}
            />
          )}
          <TooltipBubble
            ref={tooltipBubbleRef}
            className={tcx(
              "[text-wrap:wrap]",
              // Note: the homepage activity graph uses D3 tooltips. If you change this
              // styling, please also change it there (Calendar.tsx:275)
              {
                "text-slate-100 font-normal !bg-surface-invert !border !border-slate-700":
                  !light,
                "!bg-white !text-content-primary !font-normal !border !border-stroke":
                  light,
                "!max-w-[280px]": !noMaxWidth,
              },
              bubbleClassName,
            )}
            arrowClassName={tcx("invisible", bubbleProps?.arrowClassName)}
            {...(followMousePosition
              ? {
                  align: "start",
                  alignOffset: x - tooltipBubbleWidth / 2,
                }
              : {})}
            {...restProps}
            {...restBubbleProps}
          >
            {content}
          </TooltipBubble>
        </ReactTooltip.Root>
      </ReactTooltip.Provider>
    </TooltipContext.Provider>
  );
}

function TooltipButton({
  analyticsTrackingId,
  className,
  iconSize = IconSize.Small,
}: {
  analyticsTrackingId: string | null;
  className?: string;
  iconSize?: IconSize;
} & ReactTooltip.TooltipTriggerProps): React.ReactElement {
  return (
    <ReactTooltip.Trigger asChild>
      <Button
        analyticsTrackingId={`tooltip-${analyticsTrackingId}`}
        theme={ButtonTheme.Unstyled}
        title=""
        icon={IconEnum.Info}
        iconProps={{ size: iconSize }}
        className={tcx(
          styles.button,
          "hover:text-content-primary text-content-tertiary",
          "cursor-default",
          className,
        )}
      />
    </ReactTooltip.Trigger>
  );
}

type BubbleProps = {
  className?: string;
  arrowClassName?: string;
  children: React.ReactNode;
} & ReactTooltip.TooltipContentProps;

export const TooltipBubble = forwardRef<HTMLDivElement, BubbleProps>(
  (
    { className, arrowClassName, children, ...rest },
    ref,
  ): React.ReactElement => (
    <ReactTooltip.Portal>
      <ReactTooltip.Content
        ref={ref}
        collisionPadding={16}
        className={tcx(
          styles.bubble,
          "z-50",
          "shadow",
          "px-3 py-2",
          "text-xs-med",
          className,
        )}
        {...rest}
      >
        {children}
        <ReactTooltip.Arrow className={tcx("fill-white", arrowClassName)} />
      </ReactTooltip.Content>
    </ReactTooltip.Portal>
  ),
);
TooltipBubble.displayName = "TooltipBubble";

type TooltipContextType = {
  close: () => void;
};
const TooltipContext = React.createContext<TooltipContextType | null>(null);

export const useTooltipContext = (): TooltipContextType | null => {
  return useContext(TooltipContext);
};
