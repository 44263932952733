import {
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { DropdownMenuSubItem } from "@incident-ui/DropdownMenu/DropdownMenu";
import { captureException } from "@sentry/react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../hooks/usePrimaryCommsPlatform";
import { EscalationPathFormData } from "../common/types";
import { deleteNode } from "../node-editor/helpers/deleteNode";
import { insertIfElseAboveNode } from "../node-editor/helpers/insertNode";
import {
  makeLevelNode,
  makeNotifyChannelNode,
  makeRepeatNode,
} from "../node-editor/helpers/makeNodes";
import { replaceNode } from "../node-editor/helpers/replaceNode";

type NodeCardProps = {
  title: React.ReactNode;
  subtitle: string;
  icon: IconEnum;
  iconColor: {
    background: string;
    icon: string;
  };
  iconSize?: IconSize;
  iconClassName?: string;
  actionButton?: React.ReactNode;
  children?: React.ReactNode;
};

export enum NodeConversionTargetsEnum {
  Level = "level",
  NotifyChannel = "notify_channel",
  Repeat = "repeat",
  Branch = "branch",
}

// NodeCard is a generic wrapper used to draw the different types of nodes in an escalation path.
export const NodeCard = ({
  title,
  subtitle,
  icon,
  iconColor,
  iconSize = IconSize.Medium,
  iconClassName,
  actionButton,
  children,
}: NodeCardProps) => {
  return (
    <div
      className={tcx(
        "w-[400px] rounded-xl bg-white border border-border shadow-sm p-4",
        // if this isn't being rendered in a group already, we definitely want hovering on the card to show the delete button!
        "group",
      )}
    >
      <div className="flex justify-between items-start">
        <div className="flex gap-3 items-center">
          <div
            className={tcx(
              iconColor.background,
              "rounded-2 w-10 h-10 flex items-center justify-center",
            )}
          >
            <Icon
              id={icon}
              size={iconSize}
              className={tcx(iconColor.icon, iconClassName)}
            />
          </div>
          <div className="h-10 flex-col justify-center items-start gap-1 inline-flex">
            <div className="text-content-secondary text-xs font-medium leading-none">
              {title}
            </div>
            <div className="text-content-primary text-sm font-medium leading-tight">
              {subtitle}
            </div>
          </div>
        </div>
        {actionButton}
      </div>

      {children}
    </div>
  );
};

// NodeCardTitleDropdown is the mini-dropdown menu that appears at the top of some cards. It has
// options to convert a card in a level or a condition.
export const NodeCardDropdown = ({
  nodeId,
  conversions,
  canDelete,
}: {
  nodeId: string;
  conversions: NodeConversionTargetsEnum[];
  canDelete: boolean;
}) => {
  const formMethods = useFormContext<EscalationPathFormData>();
  const primaryCommsPlatform = usePrimaryCommsPlatform();
  if (!primaryCommsPlatform) {
    captureException(
      new Error("No primary comms platform found from identity"),
    );
    return <></>;
  }

  const eligibleConversions = conversions.filter((conversion) => {
    const { requiresCommsPlatform } = CONVERSION_PROPS[conversion];
    return (
      !requiresCommsPlatform || requiresCommsPlatform === primaryCommsPlatform
    );
  });

  const nodeIsTerminal =
    formMethods.getValues("nodes")[nodeId].data.nextNodeId === undefined;

  return (
    <DropdownMenu
      sideOffset={-5}
      align="end"
      triggerIcon={IconEnum.DotsVertical}
      triggerButtonTheme={ButtonTheme.Unstyled}
      analyticsTrackingId={"escalation-path-node-title-dropdown"}
      screenReaderText="Edit node"
    >
      {eligibleConversions.length > 0 && (
        <DropdownMenuSubItem trigger="Convert to">
          {eligibleConversions.map((conversion) => {
            const { label, icon, onSelectBuilder, terminalOnly } =
              CONVERSION_PROPS[conversion];

            const disabledProps =
              terminalOnly && !nodeIsTerminal
                ? {
                    disabled: true,
                    tooltipContent:
                      "You can only repeat at the end of a branch",
                  }
                : {};

            return (
              <DropdownMenuItem
                key={conversion}
                analyticsTrackingId={"escalation-path-node-action"}
                analyticsTrackingMetadata={{
                  type: conversion,
                }}
                onSelect={onSelectBuilder({ nodeId, formMethods })}
                label={label}
                {...disabledProps}
              >
                <Icon
                  id={icon}
                  size={IconSize.Small}
                  className={"fill-slate-900"}
                />
                <div className="text-content-primary">{label}</div>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuSubItem>
      )}

      <DropdownMenuItem
        key={"delete"}
        analyticsTrackingId={"escalation-path-node-action"}
        analyticsTrackingMetadata={{
          type: "delete",
        }}
        onSelect={() => deleteNode(formMethods, nodeId)}
        label={"Delete"}
        disabled={!canDelete}
        tooltipContent={
          canDelete
            ? undefined
            : "Your escalation path must have at least one notify node."
        }
      >
        <Icon
          id={IconEnum.Delete}
          size={IconSize.Small}
          className={"fill-slate-900"}
        />
        <div className="text-content-primary">Delete</div>
      </DropdownMenuItem>
    </DropdownMenu>
  );
};

export const LoadingNodeCard = (
  props: Pick<NodeCardProps, "title" | "subtitle" | "icon" | "iconColor">,
) => {
  return <NodeCard {...props} />;
};

type DropdownActionProps = {
  nodeId: string;
  formMethods: UseFormReturn<EscalationPathFormData>;
};

const CONVERSION_PROPS: Record<
  NodeConversionTargetsEnum,
  {
    icon: IconEnum;
    label: string;
    requiresCommsPlatform?: CommsPlatform;
    terminalOnly?: boolean;
    onSelectBuilder: (props: DropdownActionProps) => () => void;
  }
> = {
  [NodeConversionTargetsEnum.Level]: {
    icon: IconEnum.Siren,
    label: "Notify",
    onSelectBuilder:
      ({ nodeId, formMethods }) =>
      () => {
        replaceNode(formMethods, nodeId, (oldNodeId) =>
          makeLevelNode({ nextNodeId: oldNodeId }),
        );
      },
  },

  [NodeConversionTargetsEnum.NotifyChannel]: {
    icon: IconEnum.SlackGreyscale,
    label: "Notify channel",
    requiresCommsPlatform: CommsPlatform.Slack,
    onSelectBuilder:
      ({ nodeId, formMethods }) =>
      () => {
        replaceNode(formMethods, nodeId, (oldNodeId) =>
          makeNotifyChannelNode({ nextNodeId: oldNodeId ?? "" }),
        );
      },
  },

  [NodeConversionTargetsEnum.Repeat]: {
    icon: IconEnum.Loop,
    label: "Repeat",
    terminalOnly: true,
    onSelectBuilder:
      ({ nodeId, formMethods }) =>
      () => {
        replaceNode(formMethods, nodeId, () =>
          makeRepeatNode({ toNodeId: formMethods.getValues("firstNodeId") }),
        );
      },
  },

  [NodeConversionTargetsEnum.Branch]: {
    icon: IconEnum.GitBranch,
    label: "Condition",
    onSelectBuilder:
      ({ nodeId, formMethods }) =>
      () =>
        insertIfElseAboveNode(formMethods, nodeId),
  },
};
