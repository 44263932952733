/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentSuggestion,
    IncidentSuggestionFromJSON,
    IncidentSuggestionFromJSONTyped,
    IncidentSuggestionToJSON,
} from './IncidentSuggestion';

/**
 * 
 * @export
 * @interface IncidentSuggestionsShowSuggestionResponseBody
 */
export interface IncidentSuggestionsShowSuggestionResponseBody {
    /**
     * 
     * @type {IncidentSuggestion}
     * @memberof IncidentSuggestionsShowSuggestionResponseBody
     */
    incident_suggestion: IncidentSuggestion;
    /**
     * The span ID which generated the suggestion
     * @type {string}
     * @memberof IncidentSuggestionsShowSuggestionResponseBody
     */
    span_id?: string;
    /**
     * The trace ID which generated the suggestion
     * @type {string}
     * @memberof IncidentSuggestionsShowSuggestionResponseBody
     */
    trace_id?: string;
}

export function IncidentSuggestionsShowSuggestionResponseBodyFromJSON(json: any): IncidentSuggestionsShowSuggestionResponseBody {
    return IncidentSuggestionsShowSuggestionResponseBodyFromJSONTyped(json, false);
}

export function IncidentSuggestionsShowSuggestionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSuggestionsShowSuggestionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_suggestion': IncidentSuggestionFromJSON(json['incident_suggestion']),
        'span_id': !exists(json, 'span_id') ? undefined : json['span_id'],
        'trace_id': !exists(json, 'trace_id') ? undefined : json['trace_id'],
    };
}

export function IncidentSuggestionsShowSuggestionResponseBodyToJSON(value?: IncidentSuggestionsShowSuggestionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_suggestion': IncidentSuggestionToJSON(value.incident_suggestion),
        'span_id': value.span_id,
        'trace_id': value.trace_id,
    };
}

