import {
  ScopeNameEnum,
  Settings,
  SettingsUpdateFollowUpOwnerRequiredRequestBody,
} from "@incident-io/api";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import { NoPermissionMessage } from "@incident-shared/gates/gates";
import { ContentBox, Loader, Txt } from "@incident-ui";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useSettings } from "src/hooks/useSettings";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSubHeading } from "../SettingsSubHeading";

export const FollowUpRequiredOwnerSection = () => {
  const { hasScope, identity } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { settings } = useSettings();

  if (!settings || !identity) {
    return <Loader />;
  }

  return (
    <FollowUpRequiredOwnerToggle
      settings={settings}
      canEditSettings={canEditSettings}
    />
  );
};

export const FollowUpRequiredOwnerToggle = ({
  settings,
  canEditSettings,
}: {
  settings: Settings;
  canEditSettings: boolean;
}) => {
  const { trigger: saveState } = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data: SettingsUpdateFollowUpOwnerRequiredRequestBody) => {
      await apiClient.settingsUpdateFollowUpOwnerRequired({
        updateFollowUpOwnerRequiredRequestBody: data,
      });
    },
  );

  const { setState, state, hasSaved, saving } =
    useOptimisticAutoSave<SettingsUpdateFollowUpOwnerRequiredRequestBody>({
      initialState: settings.misc,
      saveState: async (
        data: SettingsUpdateFollowUpOwnerRequiredRequestBody,
      ) => {
        await saveState(data);
      },
    });

  return (
    <>
      <SettingsSubHeading
        title={"Owner Required"}
        explanation={
          <div className="flex flex-col gap-1">
            <Txt>
              Ensure that follow-ups are managed by requiring an owner at
              creation.
            </Txt>
            <Txt>
              Follow-ups from incident channel links are assigned to the link
              poster. If synced from post-mortems without an owner, the incident
              lead is assigned.
            </Txt>
          </div>
        }
      />
      <ContentBox className={"p-6"}>
        <div className={"flex flex-row justify-between"}>
          <GatedToggle
            id="follow_up_owner_required"
            disabled={!canEditSettings}
            disabledTooltipContent={
              !canEditSettings ? <>{NoPermissionMessage}</> : undefined
            }
            align="left"
            label="Require follow-ups to have an owner"
            on={state.follow_up_owner_required}
            onToggle={() =>
              setState({
                ...state,
                follow_up_owner_required: !state.follow_up_owner_required,
              })
            }
          />
          <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
        </div>
      </ContentBox>
    </>
  );
};
