import { Route, Routes } from "react-router";

import { AIHealthReportPage } from "./AIHealthReportPage";
import { AIHealthReportsListPage } from "./AIHealthReportsListPage";

export const AIHealthReportRoute = () => {
  return (
    <Routes>
      <Route path="/:reportId" element={<AIHealthReportPage />} />
      <Route path="*" element={<AIHealthReportsListPage />} />
    </Routes>
  );
};
