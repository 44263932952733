import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type PageLayoutWithSidebarProps = {
  children: React.ReactNode;
  sidebarContent: React.ReactNode;
  drawerTitle?: string;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  sidebarWidth?: number;
  className?: string;
};

export const PageLayoutWithSidebar: React.FC<PageLayoutWithSidebarProps> = ({
  children,
  sidebarContent,
  drawerTitle,
  isSidebarOpen,
  setIsSidebarOpen,
  sidebarWidth = 380,
  className,
}) => {
  return (
    <div
      className={tcx(
        "items-stretch gap-6 justify-between h-full pl-4 md:pl-8 overflow-y-auto overflow-x-hidden",
        "xl:grid xl:divide-x",
        className,
      )}
      style={{
        gridTemplateColumns: `minmax(0, 1fr) ${sidebarWidth}px`,
      }}
    >
      <div className={tcx("flex justify-center w-full min-w-0 pr-6 xl:pr-0")}>
        {/* The weird 'max width' here avoids an annoying UI bug where if the 
        sidebar is collapsed, the page alignment looks funky at about 1250px wide. 
        Then when the sidebar is expanded from xl, the page alignment looks fine
        so we can use the width we actually want, which is 5xl = 1024px.
        */}
        <div className="grow xl:max-w-5xl w-full overflow-visible">
          {children}
        </div>
      </div>

      {/* Desktop Sidebar */}
      <div className={tcx("hidden xl:block h-full shrink-0")}>
        {sidebarContent}
      </div>

      {/* Mobile Drawer */}
      <AnimatePresence>
        {isSidebarOpen && (
          <Drawer onClose={() => setIsSidebarOpen(false)} width="extra-small">
            <DrawerContents>
              <DrawerTitle
                title={drawerTitle}
                onClose={() => setIsSidebarOpen(false)}
                compact
                sticky
              />
              {/* Force 0 padding so that callers can provide the padding */}
              <DrawerBody className={"!p-0 "}>{sidebarContent}</DrawerBody>
            </DrawerContents>
          </Drawer>
        )}
      </AnimatePresence>
    </div>
  );
};
