/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnCallNotificationsListRestrictedCountriesResponseBody
 */
export interface OnCallNotificationsListRestrictedCountriesResponseBody {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof OnCallNotificationsListRestrictedCountriesResponseBody
     */
    countries: { [key: string]: Array<string>; };
}

/**
* @export
* @enum {string}
*/
export enum OnCallNotificationsListRestrictedCountriesResponseBodyCountriesEnum {
    Call = 'call',
    Sms = 'sms'
}

export function OnCallNotificationsListRestrictedCountriesResponseBodyFromJSON(json: any): OnCallNotificationsListRestrictedCountriesResponseBody {
    return OnCallNotificationsListRestrictedCountriesResponseBodyFromJSONTyped(json, false);
}

export function OnCallNotificationsListRestrictedCountriesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationsListRestrictedCountriesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countries': json['countries'],
    };
}

export function OnCallNotificationsListRestrictedCountriesResponseBodyToJSON(value?: OnCallNotificationsListRestrictedCountriesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countries': value.countries,
    };
}

