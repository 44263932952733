import {
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  CatalogTypeColorEnum,
  CatalogTypeIconEnum,
  EngineParamBindingValue,
} from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Tooltip,
} from "@incident-ui";
import { useTooltipContext } from "@incident-ui/Tooltip/Tooltip";

import { CatalogEntryBadge } from "../catalog/CatalogEntryBadge";

export const NonPrimitiveEntry = ({
  value,
  enableTooltip = true,
  icon,
  color,
  className,
  suffix,
  preview = false,
  onClick,
  clickable = true,
  basePath,
  catalogEntryBadgeSize,
  asButton = false,
}: {
  value: EngineParamBindingValue;
  enableTooltip?: boolean;
  // Suffix allows us to truncate an entry label without truncating the (+X)
  // when displaying a collapsed list of entries
  suffix?: string;
  className?: string;
  icon?: CatalogResourceIconEnum | CatalogTypeIconEnum | IconEnum;
  color?: CatalogResourceColorEnum | CatalogTypeColorEnum | ColorPaletteEnum;
  // When preview is true, if we can't find an associated catalog entry we'll
  // just render a greyed out catalog entry badge. For previews it is unlikely
  // than anything is actually broken, even if we can't find a catalog entry
  preview?: boolean;
  clickable?: boolean;
  onClick?: () => void;
  basePath?: string;
  catalogEntryBadgeSize?: BadgeSize.Small | BadgeSize.Medium;
  // When asButton is true, we render the badge wrapped in a button, with
  // onClick passed in to this. We use this to power things like opening the
  // sidebar for an entry from the incident sidebar.
  asButton?: boolean;
}) => {
  const catalogEntry = value.catalog_entry;
  const label =
    value.label || catalogEntry?.catalog_entry_name || value.literal;
  const tooltipContext = useTooltipContext();
  if (preview) {
    return (
      <button onClick={onClick} className="w-fit">
        <CatalogEntryBadge
          color={ColorPaletteEnum.Slate}
          icon={icon}
          label={label}
          className={className}
          suffix={suffix}
          clickable
          size={catalogEntryBadgeSize}
        />
      </button>
    );
  }

  if (asButton) {
    return (
      <button
        onClick={() => {
          if (tooltipContext) {
            tooltipContext.close();
          }
          onClick && onClick();
        }}
        className="w-fit"
      >
        <CatalogEntryBadge
          color={color}
          icon={icon}
          label={label}
          className={className}
          suffix={suffix}
          clickable
          size={catalogEntryBadgeSize}
        />
      </button>
    );
  }

  // It's possible that we have a broken link, and should style this
  // consistently with the other links but with a different colour to indicate
  // it's broken.
  if (!catalogEntry) {
    return (
      <CatalogEntryBadge
        color={ColorPaletteEnum.Red}
        icon={IconEnum.Warning}
        label={label}
        className={className}
        suffix={
          <>
            {suffix && <span className="whitespace-pre">{suffix}</span>}
            {enableTooltip && (
              <Tooltip
                side={"bottom"}
                content={<>No entry found for this value</>}
              >
                <div>
                  <Icon
                    id={IconEnum.Info}
                    className={"text-alarmalade-600 cursor-pointer"}
                    size={IconSize.Medium}
                  />
                </div>
              </Tooltip>
            )}
          </>
        }
        size={catalogEntryBadgeSize}
      />
    );
  }

  if (!clickable) {
    return (
      <CatalogEntryBadge
        color={color}
        icon={icon}
        label={label}
        className={className}
        suffix={suffix}
        size={catalogEntryBadgeSize}
      />
    );
  }

  const entryURL = basePath
    ? basePath + "/" + catalogEntry.catalog_entry_id
    : `/catalog/${catalogEntry.catalog_type_id}/${catalogEntry.catalog_entry_id}`;

  return (
    <Link
      to={entryURL}
      className="!no-underline whitespace-nowrap block max-w-min"
      analyticsTrackingId="catalog-entry-internal-link"
    >
      <CatalogEntryBadge
        color={color}
        icon={icon}
        label={label}
        className={className}
        suffix={suffix}
        clickable
        size={catalogEntryBadgeSize}
      />
    </Link>
  );
};
