import { NudgeNudgeTypeEnum } from "@incident-io/api";
import { Mode } from "@incident-shared/forms/v2/formsv2";

import { useQueryParams } from "../../../../utils/query-params";
import { NudgesCreateEditForm } from "../common/NudgesCreateEditForm";

export const NudgesCreateDrawer = ({
  renameToChannelSuggestion,
  onClose,
}: {
  renameToChannelSuggestion: boolean;
  onClose: () => void;
}): React.ReactElement | null => {
  const queryParams = useQueryParams();
  const nudgeType = queryParams.get("nudge_type") as NudgeNudgeTypeEnum;

  if (!nudgeType) {
    return null;
  }

  return (
    <NudgesCreateEditForm
      mode={Mode.Create}
      nudgeType={nudgeType}
      renameToChannelSuggestion={renameToChannelSuggestion}
      onClose={onClose}
    />
  );
};
