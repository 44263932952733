import {
  CustomFieldFormElement,
  FormCustomFieldEntries,
  marshallCustomFieldEntriesToRequestPayload,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ErrorMessage } from "@incident-ui";
import { ModalFooter } from "@incident-ui";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { compact } from "lodash";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import {
  CustomField,
  CustomFieldEntryPayloadUpdateModeEnum,
  CustomFieldFieldTypeEnum,
  CustomFieldRequiredV2Enum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { BulkNoTriggersNotice } from "./BulkNoTriggersNotice";

type UpdateMode =
  | CustomFieldEntryPayloadUpdateModeEnum.Remove
  | CustomFieldEntryPayloadUpdateModeEnum.Set
  | CustomFieldEntryPayloadUpdateModeEnum.Merge
  | "clear";

export type BulkUpdateCustomFieldsFormData = {
  updates: FormCustomFieldEntries;
  custom_field_id: string;
  updateMode: UpdateMode;
};

export function BulkUpdateCustomFieldsForm({
  incidentIDs,
  onClose,
  onSubmit,
}: {
  incidentIDs: string[];
  onClose: () => void;
  onSubmit: () => void;
}): React.ReactElement {
  const analytics = useAnalytics();
  const formMethods = useForm<BulkUpdateCustomFieldsFormData>({
    defaultValues: {
      updateMode: CustomFieldEntryPayloadUpdateModeEnum.Set,
      updates: {},
    },
  });
  const { watch } = formMethods;

  const [bulkErrors, setBulkErrors] = useState<string[] | null>(null);

  const {
    data: { custom_fields: customFields },
    error: customFieldsError,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  if (customFieldsError) {
    throw customFieldsError;
  }
  const selectedCustomFieldId = watch("custom_field_id");
  const selectedCustomField = customFields.find(
    (field) => field.id === selectedCustomFieldId,
  );

  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const {
    trigger: submit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (
      apiClient,
      { custom_field_id, updateMode, updates }: BulkUpdateCustomFieldsFormData,
    ) => {
      const customField = customFields.find((f) => f.id === custom_field_id);
      if (!customField) {
        return;
      }
      // We only apply the update for the selected field
      const update = updates[custom_field_id];

      analytics?.track("bulkActionApplied", {
        action: "update custom field",
        numIncidents: incidentIDs.length,
      });

      // The API exposes 3 options:
      // - "set" (i.e. override existing and replace)
      // - "merge" (i.e. add to existing, removing duplicates)
      // - "remove" (i.e. remove the supplied option(s), ignoring others)
      //
      // We add a separate "clear" option in the UI, to disambiguate between
      // "set to empty" (i.e. "clear") and "set to a value I've not inputted yet
      // WOOPS".
      //
      // We therefore map the UI-only `clear` option to `set to []`, and only
      // allow using `set` to set to a non-empty list of values.
      if (updateMode === "clear") {
        updateMode = CustomFieldEntryPayloadUpdateModeEnum.Set;
        update.values = [];
      }

      const payload = marshallCustomFieldEntriesToRequestPayload(
        [customField],
        formMethods.formState.touchedFields,
        { [custom_field_id]: update },
      )
        // Whack the update mode on all of these. This is the only place in the
        // dashboard we use a non-default update mode, so we don't want to
        // generalise!
        .map((entry) => ({ ...entry, update_mode: updateMode }));

      const { results } = await apiClient.incidentsBulkUpdateCustomFieldEntries(
        {
          bulkUpdateCustomFieldEntriesRequestBody: {
            incident_ids: incidentIDs,
            custom_field_entries: payload,
          },
        },
      );
      const errors = compact(results.map((result) => result.error));

      if (errors && errors.length !== 0) {
        setBulkErrors(errors);
      } else {
        onSubmit();
      }
    },
    {
      onSuccess: refreshIncidentList,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={submit}
      title={"Update custom field"}
      analyticsTrackingId="bulk-assign-custom-field"
      onClose={onClose}
      genericError={genericError}
      loading={!customFields}
      footer={
        <ModalFooter
          confirmButtonText={"Apply"}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      {customFields && (
        <>
          <StaticSingleSelectV2
            placeholder="Select a custom field"
            label="Which custom field would you like to update?"
            formMethods={formMethods}
            name="custom_field_id"
            options={customFields.map((field) => ({
              label: field.name,
              value: field.id,
            }))}
          />
          {selectedCustomField && (
            <UpdateFieldEntry
              selectedCustomField={selectedCustomField}
              formMethods={formMethods}
            />
          )}
        </>
      )}
      {bulkErrors && (
        <ErrorMessage
          message={`We encountered a problem updating ${bulkErrors.length} of your incidents. If you keep encountering errors, please contact support.`}
        />
      )}
    </Form.Modal>
  );
}

const UpdateFieldEntry = ({
  selectedCustomField,
  formMethods,
}: {
  selectedCustomField: CustomField;
  formMethods: UseFormReturn<BulkUpdateCustomFieldsFormData>;
}): React.ReactElement => {
  const isMulti =
    selectedCustomField?.field_type === CustomFieldFieldTypeEnum.MultiSelect;

  const updateMode = formMethods.watch("updateMode");

  let updateVerb = "update";
  switch (updateMode) {
    case "clear":
      updateVerb = "update";
      break;
    case CustomFieldEntryPayloadUpdateModeEnum.Remove:
      updateVerb = "remove";
      break;
    case CustomFieldEntryPayloadUpdateModeEnum.Merge:
      updateVerb = "add";
      break;
    case CustomFieldEntryPayloadUpdateModeEnum.Set:
      updateVerb = "set";
      break;
  }
  const isRequired =
    selectedCustomField.required_v2 !== CustomFieldRequiredV2Enum.Always;

  const options: RadioButtonGroupOption[] = compact([
    {
      value: CustomFieldEntryPayloadUpdateModeEnum.Set,
      label: isMulti ? "Replace all values" : "Replace value",
    },
    isMulti && {
      value: CustomFieldEntryPayloadUpdateModeEnum.Merge,
      label: "Append values to existing ones",
    },
    isRequired && {
      value: CustomFieldEntryPayloadUpdateModeEnum.Remove,
      label: isMulti ? "Remove selected values" : "Delete value",
    },
    isMulti &&
      selectedCustomField.required_v2 !== CustomFieldRequiredV2Enum.Always && {
        value: "clear",
        label: "Clear all values",
      },
  ]);

  return (
    <div className="mt-4 text-sm flex gap-2 flex-col">
      <RadioButtonGroupV2
        formMethods={formMethods}
        name="updateMode"
        label="What would you like to change?"
        srLabel="What would you like to change?"
        options={options}
      />
      <div className="mt-4">
        <CustomFieldFormElement<BulkUpdateCustomFieldsFormData>
          fieldKeyPrefix={`updates.${selectedCustomField.id}.values`}
          key={selectedCustomField.id}
          customField={selectedCustomField}
          label={`Which value${
            isMulti ? "s" : ""
          } would you like to ${updateVerb}?`}
          formMethods={formMethods}
          autoFocus={true}
          required={isRequired && updateMode !== "clear"}
          disabled={updateMode === "clear"}
          includeNoValue={false}
          // We can't sensibly filter custom field options here as we have multiple incidents, so pass
          // in an empty array as if the incident has no custom field values at all (which will mean
          // no filtering happens)
          entryPayloads={[]}
        />
      </div>
      <BulkNoTriggersNotice className="mt-4" />
    </div>
  );
};
