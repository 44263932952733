export const LabeledValue = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <div className="w-full flex items-start gap-4">
      <div className="text-content-tertiary w-20 shrink-0 font-medium">
        {label}
      </div>
      <div className="text-content-primary">{value}</div>
    </div>
  );
};
