/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationDatasetDimension,
    EvaluationDatasetDimensionFromJSON,
    EvaluationDatasetDimensionFromJSONTyped,
    EvaluationDatasetDimensionToJSON,
} from './EvaluationDatasetDimension';

/**
 * 
 * @export
 * @interface AICalculateDatasetDimensionsResponseBody
 */
export interface AICalculateDatasetDimensionsResponseBody {
    /**
     * 
     * @type {Array<EvaluationDatasetDimension>}
     * @memberof AICalculateDatasetDimensionsResponseBody
     */
    dimensions: Array<EvaluationDatasetDimension>;
}

export function AICalculateDatasetDimensionsResponseBodyFromJSON(json: any): AICalculateDatasetDimensionsResponseBody {
    return AICalculateDatasetDimensionsResponseBodyFromJSONTyped(json, false);
}

export function AICalculateDatasetDimensionsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AICalculateDatasetDimensionsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dimensions': ((json['dimensions'] as Array<any>).map(EvaluationDatasetDimensionFromJSON)),
    };
}

export function AICalculateDatasetDimensionsResponseBodyToJSON(value?: AICalculateDatasetDimensionsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dimensions': ((value.dimensions as Array<any>).map(EvaluationDatasetDimensionToJSON)),
    };
}

