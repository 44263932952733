/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DebriefEventAttendee,
    DebriefEventAttendeeFromJSON,
    DebriefEventAttendeeFromJSONTyped,
    DebriefEventAttendeeToJSON,
} from './DebriefEventAttendee';

/**
 * 
 * @export
 * @interface DebriefEvent
 */
export interface DebriefEvent {
    /**
     * Attendees of the event
     * @type {Array<DebriefEventAttendee>}
     * @memberof DebriefEvent
     */
    attendees: Array<DebriefEventAttendee>;
    /**
     * End of the event
     * @type {Date}
     * @memberof DebriefEvent
     */
    end: Date;
    /**
     * URL of the event
     * @type {string}
     * @memberof DebriefEvent
     */
    permalink: string;
    /**
     * The provider of the event
     * @type {string}
     * @memberof DebriefEvent
     */
    provider: DebriefEventProviderEnum;
    /**
     * Start of the event
     * @type {Date}
     * @memberof DebriefEvent
     */
    start: Date;
    /**
     * Summary of the event
     * @type {string}
     * @memberof DebriefEvent
     */
    summary: string;
}

/**
* @export
* @enum {string}
*/
export enum DebriefEventProviderEnum {
    GoogleCalendar = 'google_calendar',
    OutlookCalendar = 'outlook_calendar'
}

export function DebriefEventFromJSON(json: any): DebriefEvent {
    return DebriefEventFromJSONTyped(json, false);
}

export function DebriefEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attendees': ((json['attendees'] as Array<any>).map(DebriefEventAttendeeFromJSON)),
        'end': (new Date(json['end'])),
        'permalink': json['permalink'],
        'provider': json['provider'],
        'start': (new Date(json['start'])),
        'summary': json['summary'],
    };
}

export function DebriefEventToJSON(value?: DebriefEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attendees': ((value.attendees as Array<any>).map(DebriefEventAttendeeToJSON)),
        'end': (value.end.toISOString()),
        'permalink': value.permalink,
        'provider': value.provider,
        'start': (value.start.toISOString()),
        'summary': value.summary,
    };
}

