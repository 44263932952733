import {
  IncidentStatusCategoryEnum,
  Stream,
  StreamVisibilityEnum,
} from "@incident-io/api";
import { ErrorResponse } from "@incident-io/api";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  type Tab,
  TabPane,
  TabSection,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useLocation, useOutlet, useParams } from "react-router";

import { StreamModal } from "../../../../routes/legacy/IncidentRoute";
import {
  useNavigateToModal,
  useQueryParams,
} from "../../../../utils/query-params";
import { useAPI } from "../../../../utils/swr";
import { EscalationDrawer } from "../../../escalations/EscalateDrawer";
import { IncidentCallsCreateButton } from "../../../incident-calls/IncidentCallsCreateButton";
import { IncidentActivity } from "../body/IncidentActivity";
import { useInternalId } from "../hooks";
import { PrivateStreamNoAccess } from "../PrivateIncidentNoAccess/PrivateIncidentNoAccess";
import { SidebarDivider } from "../sidebar/IncidentSidebar";
import { IncidentAttachmentsInner } from "../sidebar/links/IncidentAttachments";
import { StreamRoleAssignments } from "../sidebar/RoleAssignments";
import { CallsStackedList } from "../stacked-lists/CallsStackedList";
import { StreamExternalLinksSection } from "./StreamExternalLinksSection";
import { StreamsUpdateDrawer } from "./StreamsUpdateDrawer";

export const StreamsViewDrawer = ({ onClose }: { onClose: () => void }) => {
  const { streamId } = useParams() as {
    streamId: string;
  };

  const {
    internalId: streamInternalID,
    isLoading: isLoadingStreamId,
    error: streamIdError,
  } = useInternalId(streamId);

  if (streamIdError?.status === 403) {
    return <PrivateStreamNoAccess externalId={streamId} onClose={onClose} />;
  }
  if (streamIdError?.status === 404) {
    return <OrgAwareNavigate to="/404" replace />;
  }

  if (!streamInternalID || isLoadingStreamId) {
    return <Loader />;
  }

  return (
    <StreamsViewDrawerInner
      streamInternalID={streamInternalID}
      onClose={onClose}
    />
  );
};

const StreamsViewDrawerInner = ({
  streamInternalID,
  onClose,
}: {
  streamInternalID: string;
  onClose: () => void;
}) => {
  const {
    data: { stream },
    isLoading,
    error,
  } = useAPI(
    "streamsShow",
    {
      id: streamInternalID,
    },
    { fallbackData: { stream: undefined } },
  );
  const [updateStreamOpen, setUpdateStreamOpen] = useState(false);
  const [closeStreamOpen, setCloseStreamOpen] = useState(false);
  const closeUpdateDrawer = () => {
    setUpdateStreamOpen(false);
    setCloseStreamOpen(false);
  };

  const streamIsClosed =
    stream?.status.category === IncidentStatusCategoryEnum.Closed;
  const isPrivate = stream?.visibility === StreamVisibilityEnum.Private;

  // This is whatever modal is open within the stream drawer. We render it via
  // an outlet, so it can be wrapped with AnimatePresence for exit animations.
  const streamModal = useOutlet();

  const navigateToModal = useNavigateToModal();

  return (
    <>
      <Drawer onClose={onClose} width="medium">
        <AnimatePresence>{streamModal}</AnimatePresence>
        {updateStreamOpen || closeStreamOpen ? (
          <StreamsUpdateDrawer
            incident={stream as Stream}
            closeDrawer={() => closeUpdateDrawer()}
            isClosingStream={closeStreamOpen}
          />
        ) : (
          <DrawerContents>
            <DrawerTitle
              icon={IconEnum.GitBranch}
              color={ColorPaletteEnum.Violet}
              secondaryAccessory={
                !!stream &&
                !streamIsClosed && (
                  <>
                    <Button
                      icon={IconEnum.Announcement}
                      theme={ButtonTheme.Secondary}
                      size={BadgeSize.Medium}
                      analyticsTrackingId="share-update"
                      onClick={() => setUpdateStreamOpen(true)}
                    >
                      Share update
                    </Button>
                    <Button
                      icon={IconEnum.Archive}
                      theme={ButtonTheme.Secondary}
                      size={BadgeSize.Medium}
                      analyticsTrackingId="close-stream"
                      onClick={() => setCloseStreamOpen(true)}
                    >
                      Close stream
                    </Button>
                    {isPrivate && (
                      <Button
                        icon={IconEnum.LockClosed}
                        theme={ButtonTheme.Secondary}
                        size={BadgeSize.Medium}
                        analyticsTrackingId="manage-stream-access"
                        onClick={() =>
                          navigateToModal(StreamModal.ManageAccess)
                        }
                      >
                        Manage access
                      </Button>
                    )}
                  </>
                )
              }
              onClose={onClose}
              title={stream ? stream.name : "Stream details"}
              titleAccessory={
                isPrivate && (
                  <Badge
                    theme={BadgeTheme.Unstyled}
                    size={BadgeSize.Small}
                    icon={IconEnum.LockClosed}
                  >
                    Private
                  </Badge>
                )
              }
            />
            <DrawerBody className="overflow-y-auto p-0">
              <ViewStreamDrawerBody
                stream={stream}
                isLoading={isLoading}
                error={error}
              />
            </DrawerBody>
          </DrawerContents>
        )}
      </Drawer>
    </>
  );
};

const ViewStreamDrawerBody = ({
  stream,
  isLoading,
  error,
}: {
  stream: Stream | undefined;
  isLoading: boolean;
  error: ErrorResponse | undefined;
}) => {
  if (isLoading) {
    return <Loader />;
  }

  if (error || !stream) {
    return <GenericErrorMessage error={error} />;
  }
  const sections = [
    <StreamRoleAssignments key="role-assignments" stream={stream} />,
    <LinksSection key="external-links" stream={stream} />,
  ];

  return (
    <div className="flex items-stretch h-full">
      <div className="grow border-r p-6">
        <StreamBody stream={stream} />
      </div>
      <div className="min-w-[300px] p-6 gap-4">
        {sections.map((section, i) => {
          return (
            <div className="space-y-4" key={`section-${i}`}>
              {i !== 0 && <SidebarDivider />}
              {section}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const StreamBody = ({ stream }: { stream: Stream }) => {
  const queryParams = useQueryParams();
  const initialTab =
    (queryParams.get("stream-tab") as StreamTabs) || StreamTabs.Updates;
  const [currentTab, setCurrentTab] = useState<StreamTabs>(initialTab);
  const { search, pathname } = useLocation();
  const navigate = useOrgAwareNavigate();
  const onTabChange = (newTab: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("stream-tab", newTab);
    navigate(
      {
        pathname,
        search: newSearch.toString(),
      },
      { replace: true },
    );
    setCurrentTab(newTab as StreamTabs);
  };

  const tabs: Tab[] = [
    { id: StreamTabs.Updates, label: "Overview" },
    {
      id: StreamTabs.Attachments,
      label: "Attachments",
    },
  ];

  return (
    <TabSection
      withIndicator
      value={currentTab}
      onTabChange={onTabChange}
      tabClassName="!text-sm text-content-tertiary"
      tabs={tabs}
      tabBarClassName="border-b border-stroke"
    >
      <TabPane tabId={StreamTabs.Updates}>
        <ActivityTabInner stream={stream} />
      </TabPane>
      <TabPane tabId={StreamTabs.Attachments}>
        <div className="mt-2">
          {/* We don't have any non-editable statuses for streams, so the stream is always editable */}
          <IncidentAttachmentsInner incident={stream} inEditableStatus={true} />
        </div>
      </TabPane>
    </TabSection>
  );
};

const ActivityTabInner = ({ stream }: { stream: Stream }) => {
  const { data: callData, isLoading: callLoading } = useAPI(
    "incidentCallsGetForLatestForIncident",
    {
      incidentId: stream.id,
    },
    {
      refreshInterval: 15000,
      revalidateOnFocus: true,
    },
  );
  const { data: pastCallNotes, isLoading: pastCallNotesLoading } = useAPI(
    "incidentCallsListCallSessionsWithSummariesForIncident",
    {
      incidentId: stream.id,
    },
    {
      refreshInterval: 15000,
      revalidateOnFocus: true,
    },
  );

  const isClosed = stream.status.category === IncidentStatusCategoryEnum.Closed;

  return (
    <div className="flex flex-col gap-4 py-6">
      {callData?.incident_call ? (
        <CallsStackedList
          isLoading={callLoading || pastCallNotesLoading}
          callData={callData}
          pastCallNotes={pastCallNotes}
          incident={stream}
        />
      ) : (
        <div className="flex shrink">
          <IncidentCallsCreateButton
            incidentId={stream.id}
            theme={ButtonTheme.Secondary}
            size={BadgeSize.Medium}
            icon={IconEnum.Call}
          >
            Start a call
          </IncidentCallsCreateButton>
        </div>
      )}
      <div className="my-5  bg-surface-secondary h-[1px]"></div>
      <div className="text-base-bold text-content-primary">Activity</div>
      <IncidentActivity
        incidentId={stream.id}
        isClosed={isClosed}
        mode="highlights"
      />
    </div>
  );
};

const LinksSection = ({ stream }: { stream: Stream }) => {
  const [showEscalationModal, setShowEscalationModal] = useState(false);

  const { data: providerData } = useAPI("escalationsListProviders", undefined, {
    fallbackData: {
      providers: [],
    },
  });

  return (
    <>
      <StreamExternalLinksSection stream={stream} />
      {providerData.providers.length > 0 && (
        <Button
          analyticsTrackingId={null}
          onClick={() => setShowEscalationModal(true)}
          theme={ButtonTheme.Naked}
          className="transition -ml-0.5 !mt-0 text-xs"
          icon={IconEnum.Escalate}
          size={BadgeSize.Small}
          iconProps={{
            size: IconSize.Medium,
          }}
        >
          <div className="truncate">Escalate to someone</div>
        </Button>
      )}
      {showEscalationModal && (
        <EscalationDrawer
          onClose={() => setShowEscalationModal(false)}
          incidentId={stream.id}
          showDeclareIncident={false}
          shouldWarnWhenDirty={true}
        />
      )}
      <SidebarDivider />
    </>
  );
};

export enum StreamTabs {
  Updates = "updates",
  Attachments = "attachments",
}
