import {
  IncidentSuggestion,
  useIncidentSuggestionsServiceIncidentSuggestionsListSuggestions,
} from "@incident-io/query-api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  Icon,
  IconEnum,
  IconSize,
  LoadingBar,
  LocalDateTime,
  OrgAwareLink,
  StackedList,
} from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { JsonViewer } from "src/components/workbench/common/JsonViewer";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

export const DebugViewSuggestions = ({
  incidentId,
}: {
  incidentId: string;
}) => {
  const { identity } = useIdentity();
  const { debugShowAllIncidentSuggestions } = useFlags();

  if (!identity.organisation_is_staff || !debugShowAllIncidentSuggestions) {
    return null;
  }

  return <ViewSuggestions incidentId={incidentId} />;
};

const ViewSuggestions = ({ incidentId }: { incidentId: string }) => {
  const { data, isLoading, refetch, isRefetching } =
    useIncidentSuggestionsServiceIncidentSuggestionsListSuggestions({
      incidentId,
    });

  if (isLoading) {
    return <LoadingBar />;
  }

  const filteredSuggestions = data?.incident_suggestions ?? [];

  if (filteredSuggestions.length === 0) {
    return <ContentBox className="p-4">No suggestions found</ContentBox>;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className=" flex gap-2 items-center">
        <div className="text-base-bold">Debug: Suggestions</div>
        <Button
          icon={IconEnum.Refresh}
          analyticsTrackingId={null}
          size={BadgeSize.ExtraSmall}
          onClick={() => refetch()}
          loading={isRefetching}
          title="Refetch"
        />
        <OrgAwareLink
          className="ml-auto"
          to={{
            pathname: "/workbench/suggestions",
            search: `incident_id=${incidentId}`,
          }}
        >
          View all
        </OrgAwareLink>
      </div>
      <DebugSuggestionsList suggestions={filteredSuggestions} />
    </div>
  );
};

type BadgeInfo = { icon: IconEnum; theme: BadgeTheme };

type ConvictionLevel = IncidentSuggestion["conviction"];

const convictionStyles: Record<ConvictionLevel, BadgeTheme> = {
  "00_no_change": BadgeTheme.Info,
  "10_low": BadgeTheme.Tertiary,
  "20_medium": BadgeTheme.Warning,
  "30_high": BadgeTheme.Error,
};

const ConvictionBadge = ({ conviction }: { conviction: ConvictionLevel }) => (
  <Badge theme={convictionStyles[conviction]} size={BadgeSize.ExtraSmall}>
    {conviction.charAt(0).toUpperCase() + conviction.slice(1)}
  </Badge>
);

type Action = Exclude<IncidentSuggestion["action"], undefined>;

const actionStyles: Record<Action, BadgeInfo> = {
  "": { icon: IconEnum.Info, theme: BadgeTheme.Primary },
  accept: { icon: IconEnum.Tick, theme: BadgeTheme.Success },
  accept_with_edits: { icon: IconEnum.Edit, theme: BadgeTheme.Warning },
  decline: { icon: IconEnum.Close, theme: BadgeTheme.Tertiary },
};

const ActionBadge = ({ action }: { action: Action | undefined }) => {
  if (!action) {
    return null;
  }

  const { icon, theme } = actionStyles[action];

  return (
    <Badge size={BadgeSize.ExtraSmall} theme={theme} icon={icon}>
      {action}
    </Badge>
  );
};

export const DebugSuggestionsList = ({
  suggestions,
  onViewForIncident,
  linkIncident = false,
}: {
  suggestions: IncidentSuggestion[];
  linkIncident?: boolean;
  onViewForIncident?: (incidentId: string) => void;
}) => {
  if (suggestions.length === 0) {
    return (
      <div className={tcx("p-4 text-center text-gray-500 border rounded-md")}>
        <div className="flex flex-col items-center gap-2">
          <Icon id={IconEnum.Info} size={IconSize.Large} />
          <span>No suggestions found</span>
        </div>
      </div>
    );
  }

  return (
    <StackedList>
      {suggestions.map((suggestion) => (
        <Suggestion
          key={suggestion.id}
          suggestion={suggestion}
          onViewForIncident={onViewForIncident}
          linkIncident={linkIncident}
          collapsable
        />
      ))}
    </StackedList>
  );
};

type SuggestionProps = {
  suggestion: IncidentSuggestion;
  onViewForIncident?: (incidentId: string) => void;
  linkIncident?: boolean;
  collapsable?: boolean;
};

export const Suggestion = ({
  suggestion,
  onViewForIncident,
  linkIncident,
  collapsable = false,
}: SuggestionProps) => {
  return (
    <JsonViewer
      key={suggestion.id}
      collapsable={collapsable}
      className="rounded-none"
      jsonStr={JSON.stringify(suggestion.content)}
      title={
        <div className="flex items-center gap-4 w-full group">
          <span> {suggestion.suggestion_type}</span>
          {suggestion.resource_id && (
            <span className="text-content-tertiary">
              ({suggestion.resource_id})
            </span>
          )}
          <span>|</span>
          <span className="flex items-center gap-2 text-content-tertiary">
            <span>Generated: </span>
            <LocalDateTime timestamp={suggestion.generated_at} />
          </span>
          |
          <ConvictionBadge
            conviction={suggestion.conviction as unknown as ConvictionLevel}
          />
          {suggestion.triggered_nudge && (
            <Badge size={BadgeSize.ExtraSmall} theme={BadgeTheme.Warning}>
              Nudged
            </Badge>
          )}
          <ActionBadge action={suggestion.action} />
          <div className="items-center ml-auto gap-2 hidden group-hover:flex">
            <OrgAwareLink
              to={{
                pathname: `/workbench/suggestions/${suggestion.id}`,
              }}
              target="_blank"
            >
              View details
            </OrgAwareLink>
            {linkIncident && (
              <OrgAwareLink
                to={{
                  pathname: `/incidents/${suggestion.incident_id}`,
                }}
                target="_blank"
              >
                View incident
              </OrgAwareLink>
            )}
            {onViewForIncident && (
              <Button
                analyticsTrackingId={null}
                theme={ButtonTheme.Naked}
                onClick={() => onViewForIncident(suggestion.incident_id)}
                icon={IconEnum.Filter}
                size={BadgeSize.ExtraSmall}
              >
                Filter to incident
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
};
