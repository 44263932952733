import { DependentResourceList } from "@incident-shared/engine/DependentResourceList";
import { CheckboxGroupV2 } from "@incident-shared/forms/v2/inputs/CheckboxGroupV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import {
  Avatar,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  Tooltip,
  Txt,
} from "@incident-ui";
import { uniq } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  ConfluenceSite,
  JiraCloudSite,
  JiraUser,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { groupDependentResources } from "../../DeletionConfirmationModal";

type Provider = "jira" | "confluence";

export const ConnectingUserAndSiteJira = () => {
  const { data, isLoading: configLoading } = useAPI(
    "integrationsJiraCloudGetConfig",
    undefined,
  );
  const user = data?.config?.connecting_user;

  const {
    data: { sites: jiraCloudSites },
    isLoading: sitesLoading,
  } = useAPI("integrationsJiraCloudGetSites", undefined, {
    fallbackData: { sites: [] },
  });

  const enabledJiraCloudSites = jiraCloudSites.filter((site) => site.enabled);
  if (enabledJiraCloudSites.length === 0) {
    // TODO: this shouldnt happen, we should kick them to the configure modal
    return null;
  }
  const isLoading = configLoading || sitesLoading;

  return (
    <Callout theme={CalloutTheme.Plain}>
      <div className="space-y-2">
        <p>
          incident.io is connected to{" "}
          {isLoading ? (
            <Spinner className={"inline"} containerClassName={"inline ml-2"} />
          ) : (
            <AtlassianSitesLabel
              site={enabledJiraCloudSites[0]}
              sites={jiraCloudSites}
              provider={"jira"}
            />
          )}
          {user && (
            <>
              <span className="inline">
                {jiraCloudSites && jiraCloudSites.length === 1 && <>&nbsp;</>}as
                user
              </span>
              <ConnectingUser user={user} />
            </>
          )}
        </p>
        {user && (
          <p>
            That means some automatic actions we take might show up as being
            done by that account. We recommend creating a special account for
            this, called something like &apos;incident.io&apos;, to avoid
            potential confusion, or reconnecting using our new{" "}
            <Link
              analyticsTrackingId={null}
              openInNewTab
              href="https://help.incident.io/articles/9818616764-jira#setting-up-28"
            >
              Atlassian Connect app
            </Link>
            , which does not require a user account.
          </p>
        )}
      </div>
    </Callout>
  );
};

const ConnectingUser = ({ user }: { user: JiraUser | undefined }) => {
  if (!user) {
    return <Spinner />;
  }

  return (
    <span className="font-semibold inline">
      <Avatar
        size={IconSize.Small}
        name={user.name}
        url={user.avatar_url}
        className="mx-1 inline"
      />
      {user.name}
    </span>
  );
};

type EditAtlassianSitesFormData = { site_ids: string[] };
const EditAtlassianSiteButtonModal = <
  TProvider extends Provider,
  Site extends TProvider extends "jira"
    ? JiraCloudSite
    : TProvider extends "confluence"
    ? ConfluenceSite
    : never,
>({
  sites,
  provider,
  children,
}: {
  sites: Site[];
  provider: TProvider;
  children?: React.ReactNode;
}) => {
  const { identity, hasScope } = useIdentity();

  const enabledSitesGateCount = identity
    ? identity.feature_gates?.enabled_atlassian_sites_count
    : 1; // cannot use coalesce operator because count can be undefined which means unlimited count

  const formMethods = useForm<EditAtlassianSitesFormData>({
    defaultValues: {
      site_ids: sites.filter((site) => site.enabled).map(({ id }) => id),
    },
  });

  const revalidateEngineListResources = useRevalidate([
    "engineListResources",
    "engineTypeahead",
  ]);
  const { trigger: setEnabledSites, isMutating } = useAPIMutation(
    provider === "jira"
      ? "integrationsJiraCloudGetSites"
      : "integrationsConfluenceGetSites",
    undefined,
    async (apiClient, { site_ids }: EditAtlassianSitesFormData) => {
      if (provider === "jira") {
        await apiClient.integrationsJiraCloudSetEnabledSites({
          setEnabledSitesRequestBody: { site_ids },
        });
        revalidateEngineListResources();
      } else if (provider === "confluence") {
        await apiClient.integrationsConfluenceSetEnabledSites({
          setEnabledSitesRequestBody: { site_ids },
        });
      }
    },
    {
      onSuccess: () => setModalOpen(false),
      setError: formMethods.setError,
    },
  );

  const [modalOpen, setModalOpen] = useState(false);

  const options = sites
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({
      label: (
        <>
          {name}
          <span className="text-content-tertiary">.atlassian.net</span>
        </>
      ),
      value: id,
    }));

  const originalSites = sites.filter((site) => site.enabled);
  const siteIds = formMethods.watch("site_ids");
  const sitesBeingRemoved = originalSites.filter(
    (site) => !siteIds.includes(site.id),
  );
  // sitesWithBlockers lists sites which the user is trying to remove, but
  // which have resources that depend on them.
  const sitesWithBlockers = sitesBeingRemoved
    .map((site) => ({
      site,
      blockingDependents: groupDependentResources(
        site.dependent_resources ?? [],
      ).requiresDeletionResources,
    }))
    .filter(({ blockingDependents }) => blockingDependents.length > 0);

  // blockingDependents de-duplicates and re-groups the dependent resources
  // across all sites that are being disabled
  const blockingDependents = groupDependentResources(
    uniq(
      sitesWithBlockers.flatMap(({ blockingDependents }) =>
        blockingDependents.flat(),
      ),
    ),
  ).requiresDeletionResources;
  const [installModalOpen, setInstallModalOpen] = useState(false);

  const providerLabel = providerToLabel[provider];

  const shouldUseRadios =
    provider === "confluence"
      ? // Confluence always uses radio buttons, since we only support one site.
        true
      : // For Jira, we use radio buttons if the org is only allowed one site, and
        // they are within that limit
        enabledSitesGateCount === 1 && siteIds.length <= 1;
  return (
    <>
      {installModalOpen && (
        <AddSiteModal onClose={() => setInstallModalOpen(false)} />
      )}
      <Button
        theme={ButtonTheme.Naked}
        title={`Edit ${providerLabel} site`}
        analyticsTrackingId={`edit-${provider}-site`}
        className="align-middle"
        onClick={() => setModalOpen(true)}
        disabled={!hasScope(ScopeNameEnum.OrganisationSettingsUpdate)}
      >
        {children}
        <Icon id={IconEnum.Edit} className="!ml-1" size={IconSize.Small} />
      </Button>
      {modalOpen && (
        <Form.Modal
          formMethods={formMethods}
          onSubmit={setEnabledSites}
          title={
            provider === "jira" ? "Manage Jira sites" : "Manage Confluence site"
          }
          onClose={() => setModalOpen(false)}
          analyticsTrackingId={`edit-${provider}-site-modal`}
          footer={
            <ModalFooter
              onClose={() => setModalOpen(false)}
              confirmButtonType="submit"
              saving={isMutating}
              disabled={
                siteIds.length === 0 ||
                !formMethods.formState.isValid ||
                blockingDependents.length > 0
              }
            />
          }
        >
          <Form.Helptext>
            A {providerLabel} site is an isolated instance of {providerLabel},
            hosted on its own domain, for example{" "}
            <span className="font-medium">
              {identity?.organisation_slug ?? "example"}.atlassian.net
            </span>
            .{" "}
            {shouldUseRadios ? (
              provider === "jira" ? (
                <>
                  All incidents and follow-ups will be exported to this Jira
                  site.
                </>
              ) : (
                <>
                  All post-mortem documents will be created in this Confluence
                  site.
                </>
              )
            ) : (
              <>
                Enable the sites you want to export incidents and follow-ups to
                from your incident.io account.
              </>
            )}
          </Form.Helptext>
          {shouldUseRadios ? (
            <RadioButtonGroupV2
              srLabel={`Enabled ${providerLabel} site`}
              formMethods={formMethods}
              name="site_ids.0"
              options={options}
              required="You must select the site you want to use for your Jira integration."
            />
          ) : (
            <CheckboxGroupV2
              formMethods={formMethods}
              name="site_ids"
              options={options}
              rules={{
                // maxLength rule for some reason doesn't work
                validate: (value) => {
                  if (value.length === 0) {
                    return "Please select at least one site.";
                  }
                  if (
                    numericGateLimitReached(enabledSitesGateCount, value.length)
                  ) {
                    return `Your organisation can only have ${enabledSitesGateCount} site${
                      !!enabledSitesGateCount && enabledSitesGateCount > 1
                        ? "s"
                        : ""
                    } enabled.`;
                  }
                  return true;
                },
              }}
            />
          )}
          {provider === "jira" && (
            <Button
              icon={IconEnum.Add}
              theme={ButtonTheme.Secondary}
              title={"Connect another site"}
              analyticsTrackingId="jiracloud-install-extra-site"
              onClick={() => setInstallModalOpen(true)}
            >
              Connect another site
            </Button>
          )}
          {blockingDependents.length > 0 && (
            <Callout theme={CalloutTheme.Warning} showIcon={false}>
              <DependentResourceList
                verb={"disable"}
                headingSmall
                title={sitesWithBlockers
                  .map(({ site }) => site.name)
                  .join(", ")}
                requiresDeletionResources={blockingDependents}
              />
            </Callout>
          )}
          {provider === "jira" && !!enabledSitesGateCount && (
            <Callout theme={CalloutTheme.Plain}>
              You can only have {enabledSitesGateCount} site
              {enabledSitesGateCount > 1 ? "s" : ""} enabled. If you want to
              export incidents and follow-ups to more sites, please{" "}
              <Button
                analyticsTrackingId={"jiracloud-multi-site-upsell"}
                theme={ButtonTheme.Link}
                href="mailto:support@incident.io"
              >
                talk to us
              </Button>
              .
            </Callout>
          )}
        </Form.Modal>
      )}
    </>
  );
};

const AddSiteModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal
      analyticsTrackingId={"jiracloud-add-site"}
      title="Connect another site"
      onClose={onClose}
      isOpen
    >
      <ModalContent className="text-sm space-y-4">
        <p>
          To connect another Jira site, follow the{" "}
          <Link
            analyticsTrackingId={null}
            openInNewTab
            href="https://help.incident.io/articles/9818616764-jira#setting-up-28"
          >
            Atlassian Connect app install guide
          </Link>{" "}
          for the extra site.
        </p>

        <p>
          Once access is granted, you&rsquo;ll be redirected back to the
          integrations page.
        </p>
      </ModalContent>
      <ModalFooter onClose={onClose} hideConfirmButton />
    </Modal>
  );
};

export const ConnectingUserAndSiteConfluence = () => {
  const { data: sitesData } = useAPI(
    "integrationsConfluenceGetSites",
    undefined,
  );
  const { data } = useAPI("integrationsConfluenceGetConfig", undefined);

  const connectingUser = data?.config?.connecting_user;

  // Currently we only support one site being enabled for Confluence
  // In the future we can extend this to support multiple sites.
  const enabledSite = sitesData?.sites.find((site) => site.enabled);
  if (!enabledSite) {
    // TODO: this shouldnt happen, we should kick them to the configure modal
    return null;
  }

  return (
    <Callout theme={CalloutTheme.Info}>
      <Txt className="space-y-2">
        <Txt>
          incident.io is connected to{" "}
          <AtlassianSitesLabel
            site={enabledSite}
            sites={sitesData?.sites ?? []}
            provider={"confluence"}
          />
          {connectingUser && (
            <>
              as user <ConnectingUser user={connectingUser} />.
            </>
          )}
        </Txt>
        {connectingUser && (
          <p>
            That means some automatic actions we take might show up as being
            done by that account. We recommend creating a special account for
            this, called something like &apos;incident.io&apos;, to avoid
            potential confusion, or reconnecting using our new{" "}
            <Link
              analyticsTrackingId={null}
              openInNewTab
              href="https://help.incident.io/articles/9818616764-jira#setting-up-28"
            >
              Atlassian Connect app
            </Link>
            , which does not require a user account.
          </p>
        )}
      </Txt>
    </Callout>
  );
};

const providerToLabel = {
  jira: "Jira",
  confluence: "Confluence",
} as const;

const AtlassianSitesLabel = <
  TProvider extends Provider,
  Site extends TProvider extends "jira"
    ? JiraCloudSite
    : TProvider extends "confluence"
    ? ConfluenceSite
    : never,
>({
  site,
  sites,
  provider,
}: {
  site: Site;
  sites: Array<Site>;
  provider: TProvider;
}) => {
  const providerLabel = providerToLabel[provider];
  const enabledSites = sites.filter((site) => site.enabled);
  return (
    <Txt inline className="whitespace-nowrap">
      {enabledSites.length > 1 ? (
        <Tooltip
          content={
            <>
              {enabledSites.map((site) => (
                <div key={site.id}>
                  <span className="font-medium">{site.name}</span>.atlassian.net
                </div>
              ))}
            </>
          }
        >
          {/* preventDefault is required otherwise this becomes a form submit, nasty */}
          <button onClick={(e) => e.preventDefault()}>
            <EditAtlassianSiteButtonModal provider={provider} sites={sites}>
              <Txt inline className="font-semibold">
                {enabledSites.length} {providerLabel} sites
              </Txt>
            </EditAtlassianSiteButtonModal>
          </button>
        </Tooltip>
      ) : (
        <>
          {site.avatar_url && (
            <Avatar
              size={IconSize.Small}
              url={site.avatar_url}
              className="mx-1 inline"
            />
          )}
          <span className="font-semibold inline">{site.name}</span>
          <EditAtlassianSiteButtonModal provider={provider} sites={sites} />
        </>
      )}
    </Txt>
  );
};
