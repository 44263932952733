import { CatalogEntryBadge } from "@incident-shared/attribute";
import { BadgeSize } from "@incident-ui/Badge/Badge";
import { IncidentStatusBadge } from "@incident-ui/Badge/IncidentStatusBadge";
import { SeverityBadge } from "@incident-ui/Badge/SeverityBadge";
import { EmptyState } from "@incident-ui/EmptyState/EmptyState";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { Command } from "cmdk";
import { motion } from "framer-motion";
import React from "react";
import { CatalogType, Incident } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./CommandPalette.module.scss";
import { CommandPaletteContextT } from "./CommandPaletteProvider";

type RenderProps =
  | {
      label: string;
      icon: IconEnum;
      renderItem?: never;
    }
  | {
      // Used for items that have a custom render function (e.g. incidents)
      renderItem?: (props: { onSelect: () => void }) => React.ReactElement;
      label?: never;
      icon?: never;
    };

type OnSelectProps =
  | { onSelect?: never; path: string; href?: never }
  | { onSelect?: never; path?: never; href: string }
  | {
      onSelect: (context?: CommandPaletteContextT) => void;
      path?: never;
      href?: never;
    };

export type CommandPaletteUIItem = {
  key: string;

  // Used for tracking
  analyticsId: string;
  analyticsProps?: Record<string, string>;
} & RenderProps &
  OnSelectProps;

export type CommandPaletteItemGroup = {
  groupTitle: string;
  loading?: boolean;
  items: CommandPaletteUIItem[];
};

export function CommandPaletteUI({
  open,
  setOpen,
  itemGroups,
  onSelectItem,
  search,
  setSearch,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  itemGroups: CommandPaletteItemGroup[];
  search: string;
  setSearch: (search: string) => void;
  onSelectItem: (item: CommandPaletteUIItem) => void;
}): React.ReactElement {
  const showEmptyState =
    search !== "" &&
    itemGroups.flatMap((gr) => gr.items).length === 0 &&
    !itemGroups.some((gr) => gr.loading);
  return (
    <Command.Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open);
      }}
      shouldFilter={false}
      className={styles.root}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="absolute top-0 left-0 h-screen w-screen bg-surface-invert bg-opacity-25 z-[99]"
        onClick={() => setOpen(false)}
      />
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.1,
        }}
        className={tcx(
          "absolute w-[650px] max-w-[95vw] mx-auto max-h-[min(90vh,600px)] h-fit overflow-y-hidden",
          "top-[51px] left-0 right-0 z-[100]",
          "bg-surface-secondary shadow-2xl rounded-3 p-1",
          "flex flex-col",
        )}
      >
        <div className="text-sm-normal text-content-tertiary flex items-center gap-2 w-full px-4 py-3 shrink-0">
          <Icon id={IconEnum.Search} />
          <Command.Input
            value={search}
            onValueChange={setSearch}
            placeholder="Type a command or search"
            className="text-sm-normal w-full p-0 !border-none !outline-none bg-surface-secondary !placeholder-content-tertiary text-content-primary"
          />
        </div>
        <Command.List className="bg-white rounded-t-2 grow">
          {showEmptyState ? (
            <EmptyState
              icon={IconEnum.Search}
              title="No results found"
              content="Try different keywords or use more general keywords."
              className="h-full border-none my-10"
            />
          ) : (
            <>
              {itemGroups.map(
                ({ groupTitle, items, loading }) =>
                  (items.length > 0 || loading) && (
                    <Command.Group
                      key={groupTitle}
                      heading={
                        <div className="py-2 px-3 text-xs-med text-content-tertiary">
                          {groupTitle}
                        </div>
                      }
                      className={tcx(
                        "w-full py-2 px-1 border-b border-stroke-secondary last:border-b-0",
                      )}
                    >
                      {loading ? (
                        <Command.Loading>
                          <div className="flex flex-col gap-2">
                            <div className="px-2 flex items-center gap-2">
                              <LoadingBar className="h-5 w-5" />
                              <LoadingBar className="h-5" />
                            </div>
                            <div className="px-2 flex items-center gap-2">
                              <LoadingBar className="h-5 w-5" />
                              <LoadingBar className="h-5" />
                            </div>
                          </div>
                        </Command.Loading>
                      ) : (
                        <>
                          {items.map((item) =>
                            item.renderItem ? (
                              item.renderItem({
                                onSelect: () => onSelectItem(item),
                              })
                            ) : (
                              <CommandItem
                                key={item.key}
                                onSelect={() => onSelectItem(item)}
                              >
                                {item.icon && (
                                  <Icon id={item.icon} size={IconSize.Small} />
                                )}
                                {item.label}
                              </CommandItem>
                            ),
                          )}
                        </>
                      )}
                    </Command.Group>
                  ),
              )}
            </>
          )}
        </Command.List>
        {!showEmptyState && <CommandPaletteFooter />}
      </motion.div>
    </Command.Dialog>
  );
}

export const IncidentCommandItem = ({
  incident,
  onSelect,
}: {
  incident: Incident;
  onSelect: () => void;
}) => {
  return (
    <CommandItem key={incident.id} onSelect={onSelect} className="gap-2">
      <IncidentStatusBadge
        status={incident.incident_status}
        className="font-normal shrink-0"
        size={BadgeSize.Small}
        iconOnly
      />
      <div className="text-sm-normal text-content-tertiary shrink-0">
        INC-{incident.external_id}
      </div>
      <div className="truncate text-sm-normal text-content-primary grow">
        {incident.name}
      </div>
      <SeverityBadge
        className="font-normal shrink-0"
        severity={incident.severity}
        size={BadgeSize.Small}
      />
    </CommandItem>
  );
};

export const CatalogTypeCommandItem = ({
  catalogType,
  onSelect,
}: {
  catalogType: CatalogType;
  onSelect: () => void;
}) => (
  <CommandItem onSelect={onSelect}>
    <CatalogEntryBadge
      color={catalogType.color}
      icon={catalogType.icon}
      iconOnly
      size={BadgeSize.Small}
    />
    <div className="truncate text-sm-normal text-content-primary grow">
      {catalogType.name}
    </div>
  </CommandItem>
);

export const CommandItem = ({
  onSelect,
  children,
  className,
}: {
  onSelect: () => void;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Command.Item
      onSelect={onSelect}
      className={tcx(
        "flex w-full items-center gap-2 px-3 py-2 cursor-pointer rounded",
        className,
      )}
    >
      {children}
    </Command.Item>
  );
};

const CommandPaletteFooter = () => (
  <div className="w-full px-4 py-3 bg-white rounded-b-2 border-t border-stroke-secondary flex justify-end gap-4 text-content-tertiary text-xs-med shrink-0">
    {/* To navigate */}
    <div className="flex items-center gap-1">
      <KeyCap>
        <Icon
          size={IconSize.XS}
          id={IconEnum.ArrowLeft}
          className="rotate-90"
        />
      </KeyCap>
      <KeyCap>
        <Icon
          size={IconSize.XS}
          id={IconEnum.ArrowRight}
          className="rotate-90"
        />
      </KeyCap>
      To navigate
    </div>
    {/* To select */}
    <div className="flex items-center gap-1">
      <KeyCap>
        <div className="pt-[3px]">↵</div>
      </KeyCap>
      To select
    </div>
  </div>
);

const KeyCap = ({ children }: { children: React.ReactNode }) => (
  <div className="rounded border border-stroke text-content-tertiary w-5 h-5 flex items-center justify-center">
    {children}
  </div>
);
