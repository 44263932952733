import { round } from "lodash";
import pluralize from "pluralize";
import { formatDurationFromHours } from "src/utils/datetime";

export const formatValueWithUnit = (
  value: number,
  unit: string,
  roundToPrecision?: number,
) => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const numberFormatter = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    useGrouping: true,
  });

  if (unit === "%") {
    const percentageFormatter = new Intl.NumberFormat(locale, {
      style: "percent",
      maximumFractionDigits: 0,
    });
    // 50%
    return percentageFormatter.format(value);
  }
  if (unit === "hour") {
    // 2h30m or 2 hours
    if (Number.isInteger(value)) {
      return `${numberFormatter.format(value)} hours`;
    }

    return value === 0 ? "0 hours" : formatDurationFromHours(value);
  }

  // Force incidents or alerts to be whole numbers
  if (unit === "incident" || unit === "alert") {
    value = round(value, roundToPrecision || 0);
  }
  const formattedUnit = `${pluralize(unit, value)}`;
  return `${numberFormatter.format(value)} ${formattedUnit}`;
};
