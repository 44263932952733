import { assertUnreachable } from "@incident-io/status-page-ui";
import { Callout, CalloutTheme, Loader } from "@incident-ui";
import { Path, UseFormReturn } from "react-hook-form";
import { Incident } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { useStatusesForIncident } from "../useIncidentCrudResources";
import {
  ActiveIncidentDecision,
  ActiveIncidentDecisionRadio,
} from "./ActiveIncidentDecisionRadio";
import {
  ClosedDecisionRadio,
  UpdateIncidentInClosedDecision,
} from "./ClosedDecisionRadio";
import {
  PostIncidentDecisionRadio,
  PostIncidentFlowDecision,
} from "./PostIncidentDecisionRadio";
import { Category } from "./status-utils";
import { StatusSelect } from "./StatusSelect";
import { TriageDecision, TriageDecisionRadio } from "./TriageDecisionRadio";

export type IncidentStatusFormData = {
  incident_status_id: string;
  merged_into_incident_id?: string;
  incident_status_decision: StatusFormElementDecision;
};

export type StatusFormElementDecision =
  | PostIncidentFlowDecision
  | TriageDecision
  | UpdateIncidentInClosedDecision
  | ActiveIncidentDecision
  | "none";

export const StatusFormElement = <TFormType extends IncidentStatusFormData>({
  formMethods: formMethodsRaw,
  incident,
}: {
  formMethods: UseFormReturn<TFormType>;
  incident: Incident | null;
}): React.ReactElement | null => {
  // TS and react-hook-form don't play nicely, and this seems to fix it for some reason
  const formMethods = formMethodsRaw as unknown as UseFormReturn<
    IncidentStatusFormData & { incident_type_id: string } // of the incident type somehow, and it is there I promise. // This is a weird little hack: when we're previewing, we need to get hold
  >;

  const previewIncidentTypeId = formMethods.watch("incident_type_id");

  const initialStatus = incident?.incident_status;

  // If incident is null, we're in a preview, so we should use the default
  // lifecycle.
  const fetchStatusParams: Parameters<typeof useStatusesForIncident>[0] =
    incident == null
      ? { forIncidentTypeId: previewIncidentTypeId }
      : {
          incident,
        };
  const { statuses, statusesLoading } =
    useStatusesForIncident(fetchStatusParams);

  const {
    data: { incident_tasks: incidentTasks },
    isLoading: tasksLoading,
  } = useAPI(
    incident ? "postIncidentFlowListTasks" : null,
    { incidentId: incident?.id ?? "" },
    { fallbackData: { incident_tasks: [] } },
  );

  const { watch } = formMethods;
  const selectedDecision = watch("incident_status_decision");

  if (statusesLoading || tasksLoading) {
    return <Loader />;
  }

  // If we're in a preview, we want to render as if we're in an active incident.
  const initialStatusCategory = initialStatus?.category || Category.Active;
  switch (initialStatusCategory) {
    case Category.Merged:
    case Category.Declined:
      // These are all 'terminal states' and so you can't move status from here. We shouldn't be rendering this
      // really, but in case we are, let's render some text
      return (
        <Callout theme={CalloutTheme.Danger}>
          This incident has been{" "}
          {initialStatusCategory === Category.Merged ? "merged" : "declined"}{" "}
          and cannot be updated.
        </Callout>
      );
    case Category.Canceled:
      return (
        <StatusSelect<TFormType>
          formMethods={formMethodsRaw}
          name={"incident_status_id" as Path<TFormType>}
          filterByCategories={
            incident?.incident_type ? [Category.Active] : [Category.Triage]
          }
          {...fetchStatusParams}
        />
      );
    case Category.Active:
    case Category.Paused:
      return (
        <>
          <ActiveIncidentDecisionRadio incidentId={incident?.id} />
          {(selectedDecision === ActiveIncidentDecision.Stay ||
            !selectedDecision) && (
            <StatusSelect<TFormType>
              formMethods={formMethodsRaw}
              name={"incident_status_id" as Path<TFormType>}
              filterByCategories={[Category.Active]}
              {...fetchStatusParams}
            />
          )}
        </>
      );
    case Category.Triage:
      return (
        <TriageDecisionRadio
          statuses={statuses}
          incidentId={incident?.id || ""}
        />
      );
    case Category.PostIncident:
      if (!initialStatus) {
        throw new Error(
          "unreachable: initialStatus should be defined if category is post-incident",
        );
      }
      return (
        <PostIncidentDecisionRadio
          initialStatus={initialStatus}
          statuses={statuses}
          incidentTasks={incidentTasks}
          incident={incident}
        />
      );
    case Category.Closed:
      return (
        <ClosedDecisionRadio
          statuses={statuses}
          incidentTasks={incidentTasks}
          incident={incident}
        />
      );
    default:
      return assertUnreachable(initialStatusCategory);
  }
};
