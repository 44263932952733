import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  BadgeSize,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  SearchBar,
} from "@incident-ui";
import { chain, max } from "lodash";
import { useRef, useState } from "react";

import { AlertRouteCreateConnectConfirmationModal } from "./AlertRouteCreateConnectConfirmationModal";
import {
  useAlertConfigContext,
  useAlertRoutes,
  useAlertSourceConfigs,
} from "./AlertsConfigurationPage";

export const AlertRoutesHeader = () => {
  const { searchRoutes, setSearchRoutes } = useAlertConfigContext();
  // Hack to minimise re-renders
  const iconProps = useRef({ size: IconSize.Small }).current;

  return (
    <div className="w-1/3 flex flex-row items-center justify-between pb-4 border-b border-stroke-primary ">
      <div className="flex flex-row items-center">
        <Icon id={IconEnum.SplitArrow} size={IconSize.Small} />
        <div className="pl-2 text-base-bold">Routes</div>
      </div>
      <div className="flex flex-row space-x-2">
        <SearchBar
          id="search-alert-routes"
          value={searchRoutes}
          onChange={setSearchRoutes}
          className="w-full"
          inputClassName={"h-7 rounded-md bg-surface-primary shadow-sm"}
          placeholder="Search"
          iconProps={iconProps}
        />
        <NewAlertRouteButton />
      </div>
    </div>
  );
};

const NewAlertRouteButton = () => {
  const { alertRoutes } = useAlertRoutes();
  const { alertSourceConfigs } = useAlertSourceConfigs();

  const [showConfirmation, setShowConfirmation] = useState(false);

  // Show more-recently-active routes first
  const routesToGroupWith = chain(alertRoutes)
    .filter((route) => route.alert_sources.length > 0)
    .sortBy((route) => {
      return max([
        route.approx_last_escalation_created_at,
        route.approx_last_incident_created_at,
      ]);
    })
    .value();

  const hasGroupableAlertRoutes =
    routesToGroupWith && routesToGroupWith.length > 0;

  const opts = hasGroupableAlertRoutes
    ? { onClick: () => setShowConfirmation(true) }
    : { href: "/alerts/routes/create" };

  return (
    <>
      <GatedButton
        theme={ButtonTheme.Secondary}
        analyticsTrackingId={"add-alert-route"}
        requiredScope={ScopeNameEnum.AlertRouteCreate}
        size={BadgeSize.Medium}
        icon={IconEnum.Add}
        iconProps={{ size: IconSize.Small }}
        title=""
        {...opts}
      />
      {showConfirmation ? (
        <AlertRouteCreateConnectConfirmationModal
          onClose={() => setShowConfirmation(false)}
          alertRoutes={routesToGroupWith || []}
          alertSources={alertSourceConfigs}
        />
      ) : null}
    </>
  );
};
