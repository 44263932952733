/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIGroupDatasetEntriesBySegmentResponseBody
 */
export interface AIGroupDatasetEntriesBySegmentResponseBody {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AIGroupDatasetEntriesBySegmentResponseBody
     */
    entries_by_segment: { [key: string]: Array<string>; };
}

export function AIGroupDatasetEntriesBySegmentResponseBodyFromJSON(json: any): AIGroupDatasetEntriesBySegmentResponseBody {
    return AIGroupDatasetEntriesBySegmentResponseBodyFromJSONTyped(json, false);
}

export function AIGroupDatasetEntriesBySegmentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIGroupDatasetEntriesBySegmentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries_by_segment': json['entries_by_segment'],
    };
}

export function AIGroupDatasetEntriesBySegmentResponseBodyToJSON(value?: AIGroupDatasetEntriesBySegmentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries_by_segment': value.entries_by_segment,
    };
}

