import {
  EvaluationDataset,
  useAiServiceAiCreateEvaluationDataset,
  useAiServiceAiListEvaluationDatasets,
} from "@incident-io/query-api";
import { FormInputWrapper } from "@incident-shared/forms/v2/helpers";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  IconEnum,
  Input,
  LoadingWrapper,
  LocalDateTime,
  ModalFooter,
  StackedList,
  ToastTheme,
} from "@incident-ui";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

export const DatasetsListPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isLoading } = useAiServiceAiListEvaluationDatasets();
  const datasetCount = data?.datasets.length || 0;

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          onClick={() => setIsModalOpen(true)}
          analyticsTrackingId={null}
          icon={IconEnum.Database}
          theme={ButtonTheme.Primary}
        >
          Create a dataset
        </Button>
      }
    >
      <LoadingWrapper loading={isLoading}>
        {datasetCount === 0 ? (
          <ContentBox>
            <EmptyState
              title="No datasets"
              content="Create a dataset to that can be used to run backtests"
              icon={IconEnum.Database}
              cta={
                <Button
                  onClick={() => setIsModalOpen(true)}
                  analyticsTrackingId={null}
                  icon={IconEnum.Database}
                >
                  Create a dataset
                </Button>
              }
            />
          </ContentBox>
        ) : (
          <StackedList>
            {data?.datasets?.map((dataset) => (
              <DatasetRow key={dataset.id} dataset={dataset} />
            ))}
          </StackedList>
        )}
      </LoadingWrapper>
      {isModalOpen ? (
        <DatasetCreateModal onClose={() => setIsModalOpen(false)} />
      ) : null}
    </WorkbenchSubPageWrapper>
  );
};

const DatasetRow = ({ dataset }: { dataset: EvaluationDataset }) => {
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex flex-col gap-4 px-4 group"
      href={`/workbench/datasets/${dataset.id}`}
    >
      <div className="flex flex-between gap-2 w-full">
        <div className="flex gap-6 py-4">
          <LocalDateTime
            timestamp={dataset.created_at}
            className="font-medium"
          />
          <div className="font-medium">{dataset.name}</div>
        </div>
        <div className="grow" />
        <div className="flex items-center justify-end gap-4">
          <div className="flex items-center gap-0.5">{dataset.description}</div>
        </div>
        <Button
          theme={ButtonTheme.Naked}
          icon={IconEnum.ArrowRight}
          href={`/workbench/datasets/${dataset.id}`}
          analyticsTrackingId={null}
          title="View dataset"
          className="justify-end py-4 group-hover:text-slate-900"
        />
      </div>
    </Button>
  );
};

type FormData = {
  name: string;
};

export const DatasetCreateModal = ({ onClose }: { onClose: () => void }) => {
  const formMethods = useForm<FormData>();
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();

  const { mutate: createDataset, isPending } =
    useAiServiceAiCreateEvaluationDataset({
      onSuccess: (data) => {
        showToast({
          theme: ToastTheme.Success,
          title: "Dataset created successfully",
          toastSide: ToastSideEnum.TopRight,
        });
        navigate(`/workbench/datasets/${data.dataset.id}`);
      },
      onError: (error) => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to create dataset",
          description: JSON.stringify(error),
          toastSide: ToastSideEnum.TopRight,
        });
      },
    });

  const handleSubmit = (data: FormData) => {
    createDataset({
      requestBody: {
        name: data.name,
      },
    });
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Create dataset"
      analyticsTrackingId="create-dataset"
      disableQuickClose
      onClose={onClose}
      onSubmit={handleSubmit}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Create dataset"
          analyticsTrackingId="create-dataset-submit"
          saving={isPending}
        />
      }
    >
      <div className="space-y-2">
        <FormInputWrapper
          label="Dataset name"
          name="name"
          helptext="Describe what this dataset is for"
        >
          <Input
            id="name"
            {...formMethods.register("name", {
              required: "Dataset name is required",
            })}
            placeholder="Verified code changes"
          />
        </FormInputWrapper>
      </div>
    </Form.Modal>
  );
};
