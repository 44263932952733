import { PostmortemSettings, ScopeNameEnum } from "@incident-io/api";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import { NoPermissionMessage } from "@incident-shared/gates/gates";
import { ContentBox, SharedToasts } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSubHeading } from "../../SettingsSubHeading";

type postmortemAutoSyncFollowUpsForm = {
  postmortem_followup_sync?: boolean;
};

export const PostmortemAutoSyncFollowup = ({
  postmortemSettings,
}: {
  postmortemSettings: PostmortemSettings;
}): React.ReactElement => {
  const showToast = useToast();
  const { hasScope } = useIdentity();
  const canEdit = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { trigger, genericError } = useAPIMutation(
    "postmortemsSettingsShow",
    undefined,
    async (apiClient, data: postmortemAutoSyncFollowUpsForm) =>
      await apiClient.postmortemsSettingsTogglePostmortemFollowupSync({
        togglePostmortemFollowupSyncRequestBody: data,
      }),
    {
      onError: (error) => {
        if (!error) {
          return;
        }

        showToast(SharedToasts.SETTINGS_SAVE_ERROR);
      },
    },
  );

  const { setState, hasSaved, saving, state } =
    useOptimisticAutoSave<postmortemAutoSyncFollowUpsForm>({
      initialState: {
        postmortem_followup_sync:
          postmortemSettings.postmortem_followup_sync ?? false,
      },
      saveState: async (data) => {
        await trigger(data);
      },
    });

  return (
    <div>
      <SettingsSubHeading
        title="Automatically sync follow-ups from post-mortems"
        titleHeadingLevel={2}
        explanation={
          <>
            When you link a follow-up, ticket, or to-do from your post-mortem,
            we can sync it back into incident.io.
          </>
        }
      />

      <ContentBox className={"p-6"}>
        <div className={"flex flex-row justify-between"}>
          <GatedToggle
            id="postmortem_autosync_follow_ups"
            disabled={!canEdit}
            disabledTooltipContent={
              !canEdit ? <>{NoPermissionMessage}</> : undefined
            }
            align="left"
            label="Sync follow-ups from post-mortems back into incident.io"
            on={state.postmortem_followup_sync ?? false}
            onToggle={() =>
              setState({
                postmortem_followup_sync: !state.postmortem_followup_sync,
              })
            }
          />
          <AutoSavingIndicator
            saving={saving}
            hasSaved={hasSaved && !genericError}
          />
        </div>
      </ContentBox>
    </div>
  );
};
