import { Trend, TrendSentimentEnum } from "@incident-io/api";
import { Badge, BadgeSize, BadgeTheme, IconEnum } from "@incident-ui";

import { formatValueWithUnit } from "./formatValueWithUnit";

export const TrendChangeBadge = ({ trend }: { trend: Trend }) => {
  // Showing a percentage change on a percentage metric is confusing, so just show the previous value
  const isTrendOnPctMetric = trend.unit === "%";
  return (
    <Badge
      size={BadgeSize.ExtraSmall}
      icon={
        isTrendOnPctMetric
          ? undefined
          : trend.sentiment === TrendSentimentEnum.Neutral
          ? IconEnum.TrendNeutral
          : trend.absolute_change > 0
          ? IconEnum.TrendUp
          : IconEnum.TrendDown
      }
      theme={
        trend.sentiment === TrendSentimentEnum.Good
          ? BadgeTheme.Success
          : trend.sentiment === TrendSentimentEnum.Neutral
          ? BadgeTheme.Info
          : BadgeTheme.Error
      }
    >
      {isTrendOnPctMetric
        ? `Prev ${formatValueWithUnit(trend.previous_value, trend.unit)}`
        : formatValueWithUnit(trend.percentage_change, "%")}
    </Badge>
  );
};
