import { usePylon } from "@bolasim/react-use-pylon";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { IssueTrackerProviderEnum } from "@incident-shared/issue-trackers";
import { Button, ButtonTheme, ModalFooter } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { IntegrationsClickUpInstallRequestBody as FormData } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

export const ConnectToClickUpModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement | null => {
  const formMethods = useForm<FormData>();

  const {
    trigger: setClickUpApiToken,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "integrationsList",
    undefined,
    async (apiClient, data: FormData) => {
      await apiClient.integrationsClickUpInstall({
        installRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  return (
    <Form.Modal
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={setClickUpApiToken}
      analyticsTrackingId="connect-clickup-integration"
      title="Connect to ClickUp"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Connect"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm text-slate-700">
        Add an ClickUp personal access token here to allow us to export your
        follow-ups to ClickUp. If you need help finding your API token, you can
        follow our{" "}
        <Button
          analyticsTrackingId={"connect-clickup-integration-help"}
          theme={ButtonTheme.Link}
          onClick={() =>
            showArticle(
              IntegrationConfigFor(IssueTrackerProviderEnum.ClickUp)
                .helpcenterArticleId,
            )
          }
        >
          instructions
        </Button>
        .
      </p>
      <InputV2
        formMethods={formMethods}
        label="API Token"
        type={InputType.Password}
        required="Personal access token is required"
        name="token"
        autoComplete="none"
        placeholder={"pk_xxxxxxxx_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"}
      />
    </Form.Modal>
  );
};
