import { usePylon } from "@bolasim/react-use-pylon";
import { Button, ButtonTheme, ContentBox } from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { PlanBadge } from "./PlanBadge";

type Props = {
  title: string;
  requiredProduct?: string;
  planName?: string;
  description: React.ReactNode;
  analyticsId: string;
  className?: string;
  bare?: boolean; // render the notice without a content box 🙊
} & (
  | {
      learnMoreURL: string;
      articleId?: never;
    }
  | {
      learnMoreURL?: never;
      articleId: string;
    }
);

export const UpsellNotice = ({
  title,
  planName,
  description,
  articleId,
  learnMoreURL,
  analyticsId,
  bare,
  className,
}: Props): React.ReactElement => {
  const { showKnowledgeBaseArticle } = usePylon();

  const Container = bare ? "div" : ContentBox;

  return (
    <Container className={tcx("space-y-3", className, { "p-5": !bare })}>
      <div className="space-x-2 flex items-center">
        <span className="font-semibold text-base">{title}</span>
        {planName && <PlanBadge planName={planName} />}
      </div>
      <div className="grow max-w-2xl text-sm !text-slate-700">
        {description}
      </div>
      <div className="flex gap-2 text-sm">
        <Button
          theme={ButtonTheme.Secondary}
          href={"/settings/billing"}
          analyticsTrackingId={`upsell-notice-${analyticsId}`}
        >
          Upgrade
        </Button>
        {learnMoreURL || articleId ? (
          <Button
            href={learnMoreURL}
            onClick={
              articleId ? () => showKnowledgeBaseArticle(articleId) : undefined
            }
            theme={ButtonTheme.Link}
            analyticsTrackingId={`upsell-notice-learn-more-${analyticsId}`}
          >
            Learn more
          </Button>
        ) : null}
      </div>
    </Container>
  );
};
