import { IconEnum } from "@incident-ui";

import { NodeCard } from "./NodeCard";

// NodeStart is used only for the first node in an escalation path, regardless of the node type (IfElse or Level).
export const NodeStart = (_: { id: string }) => {
  return (
    <NodeCard
      title="Start"
      subtitle="When an escalation is routed to this path"
      icon={IconEnum.Escalate}
      iconColor={{
        background: "bg-yellow-100",
        icon: "text-yellow-700",
      }}
    />
  );
};
