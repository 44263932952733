/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AISpan,
    AISpanFromJSON,
    AISpanFromJSONTyped,
    AISpanToJSON,
} from './AISpan';

/**
 * 
 * @export
 * @interface AIStaffListAISpansResponseBody
 */
export interface AIStaffListAISpansResponseBody {
    /**
     * 
     * @type {Array<AISpan>}
     * @memberof AIStaffListAISpansResponseBody
     */
    spans: Array<AISpan>;
}

export function AIStaffListAISpansResponseBodyFromJSON(json: any): AIStaffListAISpansResponseBody {
    return AIStaffListAISpansResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListAISpansResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListAISpansResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spans': ((json['spans'] as Array<any>).map(AISpanFromJSON)),
    };
}

export function AIStaffListAISpansResponseBodyToJSON(value?: AIStaffListAISpansResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spans': ((value.spans as Array<any>).map(AISpanToJSON)),
    };
}

