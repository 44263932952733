/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntryPayload,
    CustomFieldEntryPayloadFromJSON,
    CustomFieldEntryPayloadFromJSONTyped,
    CustomFieldEntryPayloadToJSON,
} from './CustomFieldEntryPayload';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';
import {
    IncidentTimestampValuePayload,
    IncidentTimestampValuePayloadFromJSON,
    IncidentTimestampValuePayloadFromJSONTyped,
    IncidentTimestampValuePayloadToJSON,
} from './IncidentTimestampValuePayload';
import {
    RetrospectiveIncidentOptions,
    RetrospectiveIncidentOptionsFromJSON,
    RetrospectiveIncidentOptionsFromJSONTyped,
    RetrospectiveIncidentOptionsToJSON,
} from './RetrospectiveIncidentOptions';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface IncidentsCreateRequestBody
 */
export interface IncidentsCreateRequestBody {
    /**
     * Set the incident's custom fields to these values
     * @type {Array<CustomFieldEntryPayload>}
     * @memberof IncidentsCreateRequestBody
     */
    custom_field_entries?: Array<CustomFieldEntryPayload>;
    /**
     * The ID an escalation that you want to create an incident for
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    escalation_id?: string;
    /**
     * Unique string used to de-duplicate incident create requests
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    idempotency_key: string;
    /**
     * Assign incident roles to these people
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof IncidentsCreateRequestBody
     */
    incident_role_assignments?: Array<IncidentRoleAssignmentPayload>;
    /**
     * The ID of the current status of this incident
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    incident_status_id?: string;
    /**
     * Assign the incident's timestamps to these values
     * @type {Array<IncidentTimestampValuePayload>}
     * @memberof IncidentsCreateRequestBody
     */
    incident_timestamp_values?: Array<IncidentTimestampValuePayload>;
    /**
     * A list of values for the incident's timestamps
     * @type {Array<IncidentTimestampValuePayload>}
     * @memberof IncidentsCreateRequestBody
     */
    incident_timestamps?: Array<IncidentTimestampValuePayload>;
    /**
     * The ID of the incident type this incident is configured to use
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    incident_type_id?: string;
    /**
     * Whether the incident is standard, retrospective or test
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    mode?: IncidentsCreateRequestBodyModeEnum;
    /**
     * Explanation of the incident
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    name?: string;
    /**
     * The ID of the parent incident, if this incident is a stream
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    parent_incident_id?: string;
    /**
     * 
     * @type {RetrospectiveIncidentOptions}
     * @memberof IncidentsCreateRequestBody
     */
    retrospective_incident_options?: RetrospectiveIncidentOptions;
    /**
     * The ID of the current severity of this incident
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    severity_id?: string;
    /**
     * Name of the Slack channel to create for this incident
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    slack_channel_name_override?: string;
    /**
     * ID of the Slack team / workspace. This is only required if you are using a Slack Enterprise Grid with multiple teams.
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    slack_team_id?: string;
    /**
     * Channel ID of the source message, if this incident was created from one
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    source_message_channel_id?: string;
    /**
     * Slack Team ID of the source message, if this incident was created from one
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    source_message_team_id?: string;
    /**
     * Timestamp of the source message, if this incident was created from one
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    source_message_timestamp?: string;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentsCreateRequestBody
     */
    summary?: TextDocumentPayload;
    /**
     * A list of user IDs to invite to the incident
     * @type {Array<string>}
     * @memberof IncidentsCreateRequestBody
     */
    users_to_invite?: Array<string>;
    /**
     * Whether the incident should be open to anyone in your Slack workspace (public), or invite-only (private). For more information on Private Incidents see our [help centre](https://help.incident.io/articles/5905558102-can-we-mark-incidents-as-sensitive-and-restrict-access).
     * @type {string}
     * @memberof IncidentsCreateRequestBody
     */
    visibility: IncidentsCreateRequestBodyVisibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentsCreateRequestBodyModeEnum {
    Standard = 'standard',
    Test = 'test',
    Retrospective = 'retrospective'
}/**
* @export
* @enum {string}
*/
export enum IncidentsCreateRequestBodyVisibilityEnum {
    Public = 'public',
    Private = 'private'
}

export function IncidentsCreateRequestBodyFromJSON(json: any): IncidentsCreateRequestBody {
    return IncidentsCreateRequestBodyFromJSONTyped(json, false);
}

export function IncidentsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_entries': !exists(json, 'custom_field_entries') ? undefined : ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryPayloadFromJSON)),
        'escalation_id': !exists(json, 'escalation_id') ? undefined : json['escalation_id'],
        'idempotency_key': json['idempotency_key'],
        'incident_role_assignments': !exists(json, 'incident_role_assignments') ? undefined : ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
        'incident_status_id': !exists(json, 'incident_status_id') ? undefined : json['incident_status_id'],
        'incident_timestamp_values': !exists(json, 'incident_timestamp_values') ? undefined : ((json['incident_timestamp_values'] as Array<any>).map(IncidentTimestampValuePayloadFromJSON)),
        'incident_timestamps': !exists(json, 'incident_timestamps') ? undefined : ((json['incident_timestamps'] as Array<any>).map(IncidentTimestampValuePayloadFromJSON)),
        'incident_type_id': !exists(json, 'incident_type_id') ? undefined : json['incident_type_id'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parent_incident_id': !exists(json, 'parent_incident_id') ? undefined : json['parent_incident_id'],
        'retrospective_incident_options': !exists(json, 'retrospective_incident_options') ? undefined : RetrospectiveIncidentOptionsFromJSON(json['retrospective_incident_options']),
        'severity_id': !exists(json, 'severity_id') ? undefined : json['severity_id'],
        'slack_channel_name_override': !exists(json, 'slack_channel_name_override') ? undefined : json['slack_channel_name_override'],
        'slack_team_id': !exists(json, 'slack_team_id') ? undefined : json['slack_team_id'],
        'source_message_channel_id': !exists(json, 'source_message_channel_id') ? undefined : json['source_message_channel_id'],
        'source_message_team_id': !exists(json, 'source_message_team_id') ? undefined : json['source_message_team_id'],
        'source_message_timestamp': !exists(json, 'source_message_timestamp') ? undefined : json['source_message_timestamp'],
        'summary': !exists(json, 'summary') ? undefined : TextDocumentPayloadFromJSON(json['summary']),
        'users_to_invite': !exists(json, 'users_to_invite') ? undefined : json['users_to_invite'],
        'visibility': json['visibility'],
    };
}

export function IncidentsCreateRequestBodyToJSON(value?: IncidentsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_entries': value.custom_field_entries === undefined ? undefined : ((value.custom_field_entries as Array<any>).map(CustomFieldEntryPayloadToJSON)),
        'escalation_id': value.escalation_id,
        'idempotency_key': value.idempotency_key,
        'incident_role_assignments': value.incident_role_assignments === undefined ? undefined : ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
        'incident_status_id': value.incident_status_id,
        'incident_timestamp_values': value.incident_timestamp_values === undefined ? undefined : ((value.incident_timestamp_values as Array<any>).map(IncidentTimestampValuePayloadToJSON)),
        'incident_timestamps': value.incident_timestamps === undefined ? undefined : ((value.incident_timestamps as Array<any>).map(IncidentTimestampValuePayloadToJSON)),
        'incident_type_id': value.incident_type_id,
        'mode': value.mode,
        'name': value.name,
        'parent_incident_id': value.parent_incident_id,
        'retrospective_incident_options': RetrospectiveIncidentOptionsToJSON(value.retrospective_incident_options),
        'severity_id': value.severity_id,
        'slack_channel_name_override': value.slack_channel_name_override,
        'slack_team_id': value.slack_team_id,
        'source_message_channel_id': value.source_message_channel_id,
        'source_message_team_id': value.source_message_team_id,
        'source_message_timestamp': value.source_message_timestamp,
        'summary': TextDocumentPayloadToJSON(value.summary),
        'users_to_invite': value.users_to_invite,
        'visibility': value.visibility,
    };
}

