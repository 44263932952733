/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertActivityDatapoint,
    AlertActivityDatapointFromJSON,
    AlertActivityDatapointFromJSONTyped,
    AlertActivityDatapointToJSON,
} from './AlertActivityDatapoint';

/**
 * 
 * @export
 * @interface AlertActivityOverview
 */
export interface AlertActivityOverview {
    /**
     * The count of alerts for each hour in the last day
     * @type {Array<AlertActivityDatapoint>}
     * @memberof AlertActivityOverview
     */
    last_day: Array<AlertActivityDatapoint>;
    /**
     * The total count of alerts in the last day
     * @type {number}
     * @memberof AlertActivityOverview
     */
    last_day_total: number;
    /**
     * The count of alerts for each day in the last month
     * @type {Array<AlertActivityDatapoint>}
     * @memberof AlertActivityOverview
     */
    last_month: Array<AlertActivityDatapoint>;
    /**
     * The total count of alerts in the last month
     * @type {number}
     * @memberof AlertActivityOverview
     */
    last_month_total: number;
}

export function AlertActivityOverviewFromJSON(json: any): AlertActivityOverview {
    return AlertActivityOverviewFromJSONTyped(json, false);
}

export function AlertActivityOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertActivityOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'last_day': ((json['last_day'] as Array<any>).map(AlertActivityDatapointFromJSON)),
        'last_day_total': json['last_day_total'],
        'last_month': ((json['last_month'] as Array<any>).map(AlertActivityDatapointFromJSON)),
        'last_month_total': json['last_month_total'],
    };
}

export function AlertActivityOverviewToJSON(value?: AlertActivityOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_day': ((value.last_day as Array<any>).map(AlertActivityDatapointToJSON)),
        'last_day_total': value.last_day_total,
        'last_month': ((value.last_month as Array<any>).map(AlertActivityDatapointToJSON)),
        'last_month_total': value.last_month_total,
    };
}

