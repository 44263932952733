/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestigationScorecardGrade,
    InvestigationScorecardGradeFromJSON,
    InvestigationScorecardGradeFromJSONTyped,
    InvestigationScorecardGradeToJSON,
} from './InvestigationScorecardGrade';

/**
 * Scorecard measuring an investigation's performance across multiple dimensions
 * @export
 * @interface InvestigationScorecard
 */
export interface InvestigationScorecard {
    /**
     * When this scorecard was produced
     * @type {Date}
     * @memberof InvestigationScorecard
     */
    graded_at: Date;
    /**
     * Collection of grades for different aspects of the investigation
     * @type {Array<InvestigationScorecardGrade>}
     * @memberof InvestigationScorecard
     */
    grades: Array<InvestigationScorecardGrade>;
    /**
     * Unique identifier
     * @type {string}
     * @memberof InvestigationScorecard
     */
    id: string;
    /**
     * ID of the investigation this scorecard is for
     * @type {string}
     * @memberof InvestigationScorecard
     */
    investigation_id: string;
    /**
     * Warnings generated during the grading process
     * @type {Array<string>}
     * @memberof InvestigationScorecard
     */
    warnings: Array<string>;
}

export function InvestigationScorecardFromJSON(json: any): InvestigationScorecard {
    return InvestigationScorecardFromJSONTyped(json, false);
}

export function InvestigationScorecardFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationScorecard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'graded_at': (new Date(json['graded_at'])),
        'grades': ((json['grades'] as Array<any>).map(InvestigationScorecardGradeFromJSON)),
        'id': json['id'],
        'investigation_id': json['investigation_id'],
        'warnings': json['warnings'],
    };
}

export function InvestigationScorecardToJSON(value?: InvestigationScorecard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'graded_at': (value.graded_at.toISOString()),
        'grades': ((value.grades as Array<any>).map(InvestigationScorecardGradeToJSON)),
        'id': value.id,
        'investigation_id': value.investigation_id,
        'warnings': value.warnings,
    };
}

