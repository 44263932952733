/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AICalculateDatasetDimensionsResponseBody,
    AICalculateDatasetDimensionsResponseBodyFromJSON,
    AICalculateDatasetDimensionsResponseBodyToJSON,
    AICreateAssistantResponseFeedbackRequestBody,
    AICreateAssistantResponseFeedbackRequestBodyFromJSON,
    AICreateAssistantResponseFeedbackRequestBodyToJSON,
    AICreateAssistantThreadQuestionRequestBody,
    AICreateAssistantThreadQuestionRequestBodyFromJSON,
    AICreateAssistantThreadQuestionRequestBodyToJSON,
    AICreateAssistantThreadQuestionResponseBody,
    AICreateAssistantThreadQuestionResponseBodyFromJSON,
    AICreateAssistantThreadQuestionResponseBodyToJSON,
    AICreateAssistantThreadResponseBody,
    AICreateAssistantThreadResponseBodyFromJSON,
    AICreateAssistantThreadResponseBodyToJSON,
    AICreateEvaluationDatasetRequestBody,
    AICreateEvaluationDatasetRequestBodyFromJSON,
    AICreateEvaluationDatasetRequestBodyToJSON,
    AICreateEvaluationDatasetResponseBody,
    AICreateEvaluationDatasetResponseBodyFromJSON,
    AICreateEvaluationDatasetResponseBodyToJSON,
    AICreateIncidentGroundTruthRequestBody,
    AICreateIncidentGroundTruthRequestBodyFromJSON,
    AICreateIncidentGroundTruthRequestBodyToJSON,
    AICreateIncidentGroundTruthResponseBody,
    AICreateIncidentGroundTruthResponseBodyFromJSON,
    AICreateIncidentGroundTruthResponseBodyToJSON,
    AICreateInvestigationResponseBody,
    AICreateInvestigationResponseBodyFromJSON,
    AICreateInvestigationResponseBodyToJSON,
    AICreateInvestigationScorecardRequestBody,
    AICreateInvestigationScorecardRequestBodyFromJSON,
    AICreateInvestigationScorecardRequestBodyToJSON,
    AICreateInvestigationScorecardResponseBody,
    AICreateInvestigationScorecardResponseBodyFromJSON,
    AICreateInvestigationScorecardResponseBodyToJSON,
    AICurateEvaluationDatasetRequestBody,
    AICurateEvaluationDatasetRequestBodyFromJSON,
    AICurateEvaluationDatasetRequestBodyToJSON,
    AICurateEvaluationDatasetResponseBody,
    AICurateEvaluationDatasetResponseBodyFromJSON,
    AICurateEvaluationDatasetResponseBodyToJSON,
    AIGroupDatasetEntriesBySegmentResponseBody,
    AIGroupDatasetEntriesBySegmentResponseBodyFromJSON,
    AIGroupDatasetEntriesBySegmentResponseBodyToJSON,
    AIListAssistantThreadMessagesResponseBody,
    AIListAssistantThreadMessagesResponseBodyFromJSON,
    AIListAssistantThreadMessagesResponseBodyToJSON,
    AIListEvaluationDatasetsResponseBody,
    AIListEvaluationDatasetsResponseBodyFromJSON,
    AIListEvaluationDatasetsResponseBodyToJSON,
    AIListIncidentGroundTruthsResponseBody,
    AIListIncidentGroundTruthsResponseBodyFromJSON,
    AIListIncidentGroundTruthsResponseBodyToJSON,
    AIListInvestigationScorecardsResponseBody,
    AIListInvestigationScorecardsResponseBodyFromJSON,
    AIListInvestigationScorecardsResponseBodyToJSON,
    AIListInvestigationsResponseBody,
    AIListInvestigationsResponseBodyFromJSON,
    AIListInvestigationsResponseBodyToJSON,
    AIShowAIAssistantResponseBody,
    AIShowAIAssistantResponseBodyFromJSON,
    AIShowAIAssistantResponseBodyToJSON,
    AIShowAssistantThreadRunResponseBody,
    AIShowAssistantThreadRunResponseBodyFromJSON,
    AIShowAssistantThreadRunResponseBodyToJSON,
    AIShowConfigResponseBody,
    AIShowConfigResponseBodyFromJSON,
    AIShowConfigResponseBodyToJSON,
    AIShowEvaluationDatasetResponseBody,
    AIShowEvaluationDatasetResponseBodyFromJSON,
    AIShowEvaluationDatasetResponseBodyToJSON,
    AIShowInvestigationResponseBody,
    AIShowInvestigationResponseBodyFromJSON,
    AIShowInvestigationResponseBodyToJSON,
    AIToggleFeatureRequestBody,
    AIToggleFeatureRequestBodyFromJSON,
    AIToggleFeatureRequestBodyToJSON,
    AIToggleFeatureResponseBody,
    AIToggleFeatureResponseBodyFromJSON,
    AIToggleFeatureResponseBodyToJSON,
    AIUpdateEvaluationDatasetRequestBody,
    AIUpdateEvaluationDatasetRequestBodyFromJSON,
    AIUpdateEvaluationDatasetRequestBodyToJSON,
    AIUpdateEvaluationDatasetResponseBody,
    AIUpdateEvaluationDatasetResponseBodyFromJSON,
    AIUpdateEvaluationDatasetResponseBodyToJSON,
    AIUpdateIncidentGroundTruthRequestBody,
    AIUpdateIncidentGroundTruthRequestBodyFromJSON,
    AIUpdateIncidentGroundTruthRequestBodyToJSON,
    AIUpdateIncidentGroundTruthResponseBody,
    AIUpdateIncidentGroundTruthResponseBodyFromJSON,
    AIUpdateIncidentGroundTruthResponseBodyToJSON,
    AIUpdateIncidentSummariesConfigRequestBody,
    AIUpdateIncidentSummariesConfigRequestBodyFromJSON,
    AIUpdateIncidentSummariesConfigRequestBodyToJSON,
    AIUpdateIncidentSummariesConfigResponseBody,
    AIUpdateIncidentSummariesConfigResponseBodyFromJSON,
    AIUpdateIncidentSummariesConfigResponseBodyToJSON,
    InvestigationPayload,
    InvestigationPayloadFromJSON,
    InvestigationPayloadToJSON,
} from '../models';

export interface AICalculateDatasetDimensionsRequest {
    entryIds: Array<string>;
    id: string;
}

export interface AICreateAssistantResponseFeedbackRequest {
    createAssistantResponseFeedbackRequestBody: AICreateAssistantResponseFeedbackRequestBody;
}

export interface AICreateAssistantThreadQuestionRequest {
    assistantId: string;
    threadId: string;
    createAssistantThreadQuestionRequestBody: AICreateAssistantThreadQuestionRequestBody;
}

export interface AICreateEvaluationDatasetRequest {
    createEvaluationDatasetRequestBody: AICreateEvaluationDatasetRequestBody;
}

export interface AICreateIncidentGroundTruthRequest {
    createIncidentGroundTruthRequestBody: AICreateIncidentGroundTruthRequestBody;
}

export interface AICreateInvestigationRequest {
    createInvestigationRequestBody: InvestigationPayload;
}

export interface AICreateInvestigationScorecardRequest {
    createInvestigationScorecardRequestBody: AICreateInvestigationScorecardRequestBody;
}

export interface AICurateEvaluationDatasetRequest {
    id: string;
    curateEvaluationDatasetRequestBody: AICurateEvaluationDatasetRequestBody;
}

export interface AIDestroyEvaluationDatasetRequest {
    id: string;
}

export interface AIGroupDatasetEntriesBySegmentRequest {
    id: string;
}

export interface AIListAssistantThreadMessagesRequest {
    assistantId: string;
    threadId: string;
}

export interface AIListIncidentGroundTruthsRequest {
    incidentId: string;
}

export interface AIListInvestigationScorecardsRequest {
    investigationId: string;
}

export interface AIListInvestigationsRequest {
    pageSize?: number;
    after?: string;
    incidentId?: string;
}

export interface AIShowAssistantThreadRunRequest {
    assistantId: string;
    threadId: string;
    runId: string;
}

export interface AIShowEvaluationDatasetRequest {
    id: string;
}

export interface AIShowInvestigationRequest {
    id: string;
}

export interface AIToggleFeatureRequest {
    toggleFeatureRequestBody: AIToggleFeatureRequestBody;
}

export interface AIUpdateEvaluationDatasetRequest {
    id: string;
    updateEvaluationDatasetRequestBody: AIUpdateEvaluationDatasetRequestBody;
}

export interface AIUpdateIncidentGroundTruthRequest {
    id: string;
    updateIncidentGroundTruthRequestBody: AIUpdateIncidentGroundTruthRequestBody;
}

export interface AIUpdateIncidentSummariesConfigRequest {
    updateIncidentSummariesConfigRequestBody: AIUpdateIncidentSummariesConfigRequestBody;
}

/**
 * 
 */
export class AIApi extends runtime.BaseAPI {

    /**
     * Calculate dimensions for a given list of incident IDs, for a given dataset.
     * CalculateDatasetDimensions AI
     */
    async aICalculateDatasetDimensionsRaw(requestParameters: AICalculateDatasetDimensionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICalculateDatasetDimensionsResponseBody>> {
        if (requestParameters.entryIds === null || requestParameters.entryIds === undefined) {
            throw new runtime.RequiredError('entryIds','Required parameter requestParameters.entryIds was null or undefined when calling aICalculateDatasetDimensions.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aICalculateDatasetDimensions.');
        }

        const queryParameters: any = {};

        if (requestParameters.entryIds) {
            queryParameters['entry_ids'] = requestParameters.entryIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/evaluation_datasets/{id}/dimensions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICalculateDatasetDimensionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Calculate dimensions for a given list of incident IDs, for a given dataset.
     * CalculateDatasetDimensions AI
     */
    async aICalculateDatasetDimensions(requestParameters: AICalculateDatasetDimensionsRequest, initOverrides?: RequestInit): Promise<AICalculateDatasetDimensionsResponseBody> {
        const response = await this.aICalculateDatasetDimensionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Give feedback on a response Assistant has given.
     * CreateAssistantResponseFeedback AI
     */
    async aICreateAssistantResponseFeedbackRaw(requestParameters: AICreateAssistantResponseFeedbackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createAssistantResponseFeedbackRequestBody === null || requestParameters.createAssistantResponseFeedbackRequestBody === undefined) {
            throw new runtime.RequiredError('createAssistantResponseFeedbackRequestBody','Required parameter requestParameters.createAssistantResponseFeedbackRequestBody was null or undefined when calling aICreateAssistantResponseFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/assistant/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AICreateAssistantResponseFeedbackRequestBodyToJSON(requestParameters.createAssistantResponseFeedbackRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Give feedback on a response Assistant has given.
     * CreateAssistantResponseFeedback AI
     */
    async aICreateAssistantResponseFeedback(requestParameters: AICreateAssistantResponseFeedbackRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aICreateAssistantResponseFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new Thread with the AI Assistant.
     * CreateAssistantThread AI
     */
    async aICreateAssistantThreadRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICreateAssistantThreadResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/assistant/thread`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICreateAssistantThreadResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new Thread with the AI Assistant.
     * CreateAssistantThread AI
     */
    async aICreateAssistantThread(initOverrides?: RequestInit): Promise<AICreateAssistantThreadResponseBody> {
        const response = await this.aICreateAssistantThreadRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a new question in a Thread with the AI Assistant.
     * CreateAssistantThreadQuestion AI
     */
    async aICreateAssistantThreadQuestionRaw(requestParameters: AICreateAssistantThreadQuestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICreateAssistantThreadQuestionResponseBody>> {
        if (requestParameters.assistantId === null || requestParameters.assistantId === undefined) {
            throw new runtime.RequiredError('assistantId','Required parameter requestParameters.assistantId was null or undefined when calling aICreateAssistantThreadQuestion.');
        }

        if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
            throw new runtime.RequiredError('threadId','Required parameter requestParameters.threadId was null or undefined when calling aICreateAssistantThreadQuestion.');
        }

        if (requestParameters.createAssistantThreadQuestionRequestBody === null || requestParameters.createAssistantThreadQuestionRequestBody === undefined) {
            throw new runtime.RequiredError('createAssistantThreadQuestionRequestBody','Required parameter requestParameters.createAssistantThreadQuestionRequestBody was null or undefined when calling aICreateAssistantThreadQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/assistant/{assistant_id}/thread/{thread_id}/question`.replace(`{${"assistant_id"}}`, encodeURIComponent(String(requestParameters.assistantId))).replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AICreateAssistantThreadQuestionRequestBodyToJSON(requestParameters.createAssistantThreadQuestionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICreateAssistantThreadQuestionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new question in a Thread with the AI Assistant.
     * CreateAssistantThreadQuestion AI
     */
    async aICreateAssistantThreadQuestion(requestParameters: AICreateAssistantThreadQuestionRequest, initOverrides?: RequestInit): Promise<AICreateAssistantThreadQuestionResponseBody> {
        const response = await this.aICreateAssistantThreadQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new dataset for evaluating investigation performance.
     * CreateEvaluationDataset AI
     */
    async aICreateEvaluationDatasetRaw(requestParameters: AICreateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICreateEvaluationDatasetResponseBody>> {
        if (requestParameters.createEvaluationDatasetRequestBody === null || requestParameters.createEvaluationDatasetRequestBody === undefined) {
            throw new runtime.RequiredError('createEvaluationDatasetRequestBody','Required parameter requestParameters.createEvaluationDatasetRequestBody was null or undefined when calling aICreateEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/evaluation_datasets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AICreateEvaluationDatasetRequestBodyToJSON(requestParameters.createEvaluationDatasetRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICreateEvaluationDatasetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new dataset for evaluating investigation performance.
     * CreateEvaluationDataset AI
     */
    async aICreateEvaluationDataset(requestParameters: AICreateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<AICreateEvaluationDatasetResponseBody> {
        const response = await this.aICreateEvaluationDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new ground truth for an incident
     * CreateIncidentGroundTruth AI
     */
    async aICreateIncidentGroundTruthRaw(requestParameters: AICreateIncidentGroundTruthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICreateIncidentGroundTruthResponseBody>> {
        if (requestParameters.createIncidentGroundTruthRequestBody === null || requestParameters.createIncidentGroundTruthRequestBody === undefined) {
            throw new runtime.RequiredError('createIncidentGroundTruthRequestBody','Required parameter requestParameters.createIncidentGroundTruthRequestBody was null or undefined when calling aICreateIncidentGroundTruth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/incident_ground_truths`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AICreateIncidentGroundTruthRequestBodyToJSON(requestParameters.createIncidentGroundTruthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICreateIncidentGroundTruthResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new ground truth for an incident
     * CreateIncidentGroundTruth AI
     */
    async aICreateIncidentGroundTruth(requestParameters: AICreateIncidentGroundTruthRequest, initOverrides?: RequestInit): Promise<AICreateIncidentGroundTruthResponseBody> {
        const response = await this.aICreateIncidentGroundTruthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new investigation
     * CreateInvestigation AI
     */
    async aICreateInvestigationRaw(requestParameters: AICreateInvestigationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICreateInvestigationResponseBody>> {
        if (requestParameters.createInvestigationRequestBody === null || requestParameters.createInvestigationRequestBody === undefined) {
            throw new runtime.RequiredError('createInvestigationRequestBody','Required parameter requestParameters.createInvestigationRequestBody was null or undefined when calling aICreateInvestigation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/investigations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestigationPayloadToJSON(requestParameters.createInvestigationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICreateInvestigationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new investigation
     * CreateInvestigation AI
     */
    async aICreateInvestigation(requestParameters: AICreateInvestigationRequest, initOverrides?: RequestInit): Promise<AICreateInvestigationResponseBody> {
        const response = await this.aICreateInvestigationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new scorecard using most recent investigation data
     * CreateInvestigationScorecard AI
     */
    async aICreateInvestigationScorecardRaw(requestParameters: AICreateInvestigationScorecardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICreateInvestigationScorecardResponseBody>> {
        if (requestParameters.createInvestigationScorecardRequestBody === null || requestParameters.createInvestigationScorecardRequestBody === undefined) {
            throw new runtime.RequiredError('createInvestigationScorecardRequestBody','Required parameter requestParameters.createInvestigationScorecardRequestBody was null or undefined when calling aICreateInvestigationScorecard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/investigation_scorecards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AICreateInvestigationScorecardRequestBodyToJSON(requestParameters.createInvestigationScorecardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICreateInvestigationScorecardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new scorecard using most recent investigation data
     * CreateInvestigationScorecard AI
     */
    async aICreateInvestigationScorecard(requestParameters: AICreateInvestigationScorecardRequest, initOverrides?: RequestInit): Promise<AICreateInvestigationScorecardResponseBody> {
        const response = await this.aICreateInvestigationScorecardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add or remove entries from a dataset.
     * CurateEvaluationDataset AI
     */
    async aICurateEvaluationDatasetRaw(requestParameters: AICurateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AICurateEvaluationDatasetResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aICurateEvaluationDataset.');
        }

        if (requestParameters.curateEvaluationDatasetRequestBody === null || requestParameters.curateEvaluationDatasetRequestBody === undefined) {
            throw new runtime.RequiredError('curateEvaluationDatasetRequestBody','Required parameter requestParameters.curateEvaluationDatasetRequestBody was null or undefined when calling aICurateEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/evaluation_datasets/{id}/actions/curate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AICurateEvaluationDatasetRequestBodyToJSON(requestParameters.curateEvaluationDatasetRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AICurateEvaluationDatasetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Add or remove entries from a dataset.
     * CurateEvaluationDataset AI
     */
    async aICurateEvaluationDataset(requestParameters: AICurateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<AICurateEvaluationDatasetResponseBody> {
        const response = await this.aICurateEvaluationDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive an evaluation dataset.
     * DestroyEvaluationDataset AI
     */
    async aIDestroyEvaluationDatasetRaw(requestParameters: AIDestroyEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIDestroyEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/evaluation_datasets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an evaluation dataset.
     * DestroyEvaluationDataset AI
     */
    async aIDestroyEvaluationDataset(requestParameters: AIDestroyEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aIDestroyEvaluationDatasetRaw(requestParameters, initOverrides);
    }

    /**
     * Get a list of which entries are in which segment (note this is quite an expensive operation).
     * GroupDatasetEntriesBySegment AI
     */
    async aIGroupDatasetEntriesBySegmentRaw(requestParameters: AIGroupDatasetEntriesBySegmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIGroupDatasetEntriesBySegmentResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIGroupDatasetEntriesBySegment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/evaluation_datasets/{id}/entries_by_segment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIGroupDatasetEntriesBySegmentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a list of which entries are in which segment (note this is quite an expensive operation).
     * GroupDatasetEntriesBySegment AI
     */
    async aIGroupDatasetEntriesBySegment(requestParameters: AIGroupDatasetEntriesBySegmentRequest, initOverrides?: RequestInit): Promise<AIGroupDatasetEntriesBySegmentResponseBody> {
        const response = await this.aIGroupDatasetEntriesBySegmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the status of a Run for a specific Thread on the AI Assistant.
     * ListAssistantThreadMessages AI
     */
    async aIListAssistantThreadMessagesRaw(requestParameters: AIListAssistantThreadMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIListAssistantThreadMessagesResponseBody>> {
        if (requestParameters.assistantId === null || requestParameters.assistantId === undefined) {
            throw new runtime.RequiredError('assistantId','Required parameter requestParameters.assistantId was null or undefined when calling aIListAssistantThreadMessages.');
        }

        if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
            throw new runtime.RequiredError('threadId','Required parameter requestParameters.threadId was null or undefined when calling aIListAssistantThreadMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/assistant/{assistant_id}/thread/{thread_id}/messages`.replace(`{${"assistant_id"}}`, encodeURIComponent(String(requestParameters.assistantId))).replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIListAssistantThreadMessagesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Gets the status of a Run for a specific Thread on the AI Assistant.
     * ListAssistantThreadMessages AI
     */
    async aIListAssistantThreadMessages(requestParameters: AIListAssistantThreadMessagesRequest, initOverrides?: RequestInit): Promise<AIListAssistantThreadMessagesResponseBody> {
        const response = await this.aIListAssistantThreadMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all incident datasets.
     * ListEvaluationDatasets AI
     */
    async aIListEvaluationDatasetsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIListEvaluationDatasetsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/evaluation_datasets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIListEvaluationDatasetsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all incident datasets.
     * ListEvaluationDatasets AI
     */
    async aIListEvaluationDatasets(initOverrides?: RequestInit): Promise<AIListEvaluationDatasetsResponseBody> {
        const response = await this.aIListEvaluationDatasetsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List ground truths for an incident
     * ListIncidentGroundTruths AI
     */
    async aIListIncidentGroundTruthsRaw(requestParameters: AIListIncidentGroundTruthsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIListIncidentGroundTruthsResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling aIListIncidentGroundTruths.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/incident_ground_truths`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIListIncidentGroundTruthsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List ground truths for an incident
     * ListIncidentGroundTruths AI
     */
    async aIListIncidentGroundTruths(requestParameters: AIListIncidentGroundTruthsRequest, initOverrides?: RequestInit): Promise<AIListIncidentGroundTruthsResponseBody> {
        const response = await this.aIListIncidentGroundTruthsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the most recent scorecards for an investigation (up to 5)
     * ListInvestigationScorecards AI
     */
    async aIListInvestigationScorecardsRaw(requestParameters: AIListInvestigationScorecardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIListInvestigationScorecardsResponseBody>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling aIListInvestigationScorecards.');
        }

        const queryParameters: any = {};

        if (requestParameters.investigationId !== undefined) {
            queryParameters['investigation_id'] = requestParameters.investigationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/investigation_scorecards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIListInvestigationScorecardsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the most recent scorecards for an investigation (up to 5)
     * ListInvestigationScorecards AI
     */
    async aIListInvestigationScorecards(requestParameters: AIListInvestigationScorecardsRequest, initOverrides?: RequestInit): Promise<AIListInvestigationScorecardsResponseBody> {
        const response = await this.aIListInvestigationScorecardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the most recent 50 investigations, optionally filtered by parameters.
     * ListInvestigations AI
     */
    async aIListInvestigationsRaw(requestParameters: AIListInvestigationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIListInvestigationsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/investigations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIListInvestigationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the most recent 50 investigations, optionally filtered by parameters.
     * ListInvestigations AI
     */
    async aIListInvestigations(requestParameters: AIListInvestigationsRequest, initOverrides?: RequestInit): Promise<AIListInvestigationsResponseBody> {
        const response = await this.aIListInvestigationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Shows if the organisation has an OpenAI Assistant.
     * ShowAIAssistant AI
     */
    async aIShowAIAssistantRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIShowAIAssistantResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/assistant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIShowAIAssistantResponseBodyFromJSON(jsonValue));
    }

    /**
     * Shows if the organisation has an OpenAI Assistant.
     * ShowAIAssistant AI
     */
    async aIShowAIAssistant(initOverrides?: RequestInit): Promise<AIShowAIAssistantResponseBody> {
        const response = await this.aIShowAIAssistantRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the status of a Run for a specific Thread on the AI Assistant.
     * ShowAssistantThreadRun AI
     */
    async aIShowAssistantThreadRunRaw(requestParameters: AIShowAssistantThreadRunRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIShowAssistantThreadRunResponseBody>> {
        if (requestParameters.assistantId === null || requestParameters.assistantId === undefined) {
            throw new runtime.RequiredError('assistantId','Required parameter requestParameters.assistantId was null or undefined when calling aIShowAssistantThreadRun.');
        }

        if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
            throw new runtime.RequiredError('threadId','Required parameter requestParameters.threadId was null or undefined when calling aIShowAssistantThreadRun.');
        }

        if (requestParameters.runId === null || requestParameters.runId === undefined) {
            throw new runtime.RequiredError('runId','Required parameter requestParameters.runId was null or undefined when calling aIShowAssistantThreadRun.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/assistant/{assistant_id}/thread/{thread_id}/run/{run_id}`.replace(`{${"assistant_id"}}`, encodeURIComponent(String(requestParameters.assistantId))).replace(`{${"thread_id"}}`, encodeURIComponent(String(requestParameters.threadId))).replace(`{${"run_id"}}`, encodeURIComponent(String(requestParameters.runId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIShowAssistantThreadRunResponseBodyFromJSON(jsonValue));
    }

    /**
     * Gets the status of a Run for a specific Thread on the AI Assistant.
     * ShowAssistantThreadRun AI
     */
    async aIShowAssistantThreadRun(requestParameters: AIShowAssistantThreadRunRequest, initOverrides?: RequestInit): Promise<AIShowAssistantThreadRunResponseBody> {
        const response = await this.aIShowAssistantThreadRunRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the AI config
     * ShowConfig AI
     */
    async aIShowConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIShowConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIShowConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the AI config
     * ShowConfig AI
     */
    async aIShowConfig(initOverrides?: RequestInit): Promise<AIShowConfigResponseBody> {
        const response = await this.aIShowConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show a single dataset including its dimensions.
     * ShowEvaluationDataset AI
     */
    async aIShowEvaluationDatasetRaw(requestParameters: AIShowEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIShowEvaluationDatasetResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIShowEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/evaluation_datasets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIShowEvaluationDatasetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single dataset including its dimensions.
     * ShowEvaluationDataset AI
     */
    async aIShowEvaluationDataset(requestParameters: AIShowEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<AIShowEvaluationDatasetResponseBody> {
        const response = await this.aIShowEvaluationDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all the details for an investigation
     * ShowInvestigation AI
     */
    async aIShowInvestigationRaw(requestParameters: AIShowInvestigationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIShowInvestigationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIShowInvestigation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai/investigations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIShowInvestigationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get all the details for an investigation
     * ShowInvestigation AI
     */
    async aIShowInvestigation(requestParameters: AIShowInvestigationRequest, initOverrides?: RequestInit): Promise<AIShowInvestigationResponseBody> {
        const response = await this.aIShowInvestigationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * When true, we\'ll suggest an AI-generated summary after an incident update.
     * ToggleFeature AI
     */
    async aIToggleFeatureRaw(requestParameters: AIToggleFeatureRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIToggleFeatureResponseBody>> {
        if (requestParameters.toggleFeatureRequestBody === null || requestParameters.toggleFeatureRequestBody === undefined) {
            throw new runtime.RequiredError('toggleFeatureRequestBody','Required parameter requestParameters.toggleFeatureRequestBody was null or undefined when calling aIToggleFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/config/actions/toggle_feature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIToggleFeatureRequestBodyToJSON(requestParameters.toggleFeatureRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIToggleFeatureResponseBodyFromJSON(jsonValue));
    }

    /**
     * When true, we\'ll suggest an AI-generated summary after an incident update.
     * ToggleFeature AI
     */
    async aIToggleFeature(requestParameters: AIToggleFeatureRequest, initOverrides?: RequestInit): Promise<AIToggleFeatureResponseBody> {
        const response = await this.aIToggleFeatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing incident dataset.
     * UpdateEvaluationDataset AI
     */
    async aIUpdateEvaluationDatasetRaw(requestParameters: AIUpdateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIUpdateEvaluationDatasetResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIUpdateEvaluationDataset.');
        }

        if (requestParameters.updateEvaluationDatasetRequestBody === null || requestParameters.updateEvaluationDatasetRequestBody === undefined) {
            throw new runtime.RequiredError('updateEvaluationDatasetRequestBody','Required parameter requestParameters.updateEvaluationDatasetRequestBody was null or undefined when calling aIUpdateEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/evaluation_datasets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AIUpdateEvaluationDatasetRequestBodyToJSON(requestParameters.updateEvaluationDatasetRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIUpdateEvaluationDatasetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing incident dataset.
     * UpdateEvaluationDataset AI
     */
    async aIUpdateEvaluationDataset(requestParameters: AIUpdateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<AIUpdateEvaluationDatasetResponseBody> {
        const response = await this.aIUpdateEvaluationDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing ground truth
     * UpdateIncidentGroundTruth AI
     */
    async aIUpdateIncidentGroundTruthRaw(requestParameters: AIUpdateIncidentGroundTruthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIUpdateIncidentGroundTruthResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIUpdateIncidentGroundTruth.');
        }

        if (requestParameters.updateIncidentGroundTruthRequestBody === null || requestParameters.updateIncidentGroundTruthRequestBody === undefined) {
            throw new runtime.RequiredError('updateIncidentGroundTruthRequestBody','Required parameter requestParameters.updateIncidentGroundTruthRequestBody was null or undefined when calling aIUpdateIncidentGroundTruth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/incident_ground_truths/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AIUpdateIncidentGroundTruthRequestBodyToJSON(requestParameters.updateIncidentGroundTruthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIUpdateIncidentGroundTruthResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing ground truth
     * UpdateIncidentGroundTruth AI
     */
    async aIUpdateIncidentGroundTruth(requestParameters: AIUpdateIncidentGroundTruthRequest, initOverrides?: RequestInit): Promise<AIUpdateIncidentGroundTruthResponseBody> {
        const response = await this.aIUpdateIncidentGroundTruthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the settings for the AI summaries feature.
     * UpdateIncidentSummariesConfig AI
     */
    async aIUpdateIncidentSummariesConfigRaw(requestParameters: AIUpdateIncidentSummariesConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIUpdateIncidentSummariesConfigResponseBody>> {
        if (requestParameters.updateIncidentSummariesConfigRequestBody === null || requestParameters.updateIncidentSummariesConfigRequestBody === undefined) {
            throw new runtime.RequiredError('updateIncidentSummariesConfigRequestBody','Required parameter requestParameters.updateIncidentSummariesConfigRequestBody was null or undefined when calling aIUpdateIncidentSummariesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai/incident_summaries_section_labels`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AIUpdateIncidentSummariesConfigRequestBodyToJSON(requestParameters.updateIncidentSummariesConfigRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIUpdateIncidentSummariesConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates the settings for the AI summaries feature.
     * UpdateIncidentSummariesConfig AI
     */
    async aIUpdateIncidentSummariesConfig(requestParameters: AIUpdateIncidentSummariesConfigRequest, initOverrides?: RequestInit): Promise<AIUpdateIncidentSummariesConfigResponseBody> {
        const response = await this.aIUpdateIncidentSummariesConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
