import { CallParticipant } from "@incident-io/query-api";
import { IconSize } from "@incident-ui";

import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../legacy/incident/sidebar/AvatarList";

export const CallSessionParticipants = ({
  participants,
}: {
  participants: CallParticipant[] | undefined;
}) => {
  return (
    <div className="flex flex-col gap-1">
      {!!participants && participants.length > 0 && (
        <>
          <div className="text-content-secondary text-xs-med">Participants</div>
          <dl className="flex-center-y justify-between">
            <AvatarList
              users={participants.map((p) => convertToMaybeUser(p))}
              modalTitle={"Call participants"}
              maxToShow={5}
              clickableType={AvatarListClickableType.OnlyOnSeeMore}
              avatarSize={IconSize.Large}
            />
          </dl>
        </>
      )}
    </div>
  );
};

const convertToMaybeUser = (participant: CallParticipant): MaybeUser => {
  const maybeUser = {} as MaybeUser;

  if (participant.user) {
    maybeUser.user = participant.user;
  } else {
    maybeUser.nonUserLabel = participant.email;
  }

  maybeUser.greyedOut = participant.last_left_at ? true : false;

  return maybeUser;
};
