/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleWorkspaceCalendar,
    GoogleWorkspaceCalendarFromJSON,
    GoogleWorkspaceCalendarFromJSONTyped,
    GoogleWorkspaceCalendarToJSON,
} from './GoogleWorkspaceCalendar';

/**
 * 
 * @export
 * @interface DebriefPlaceholder
 */
export interface DebriefPlaceholder {
    /**
     * 
     * @type {GoogleWorkspaceCalendar}
     * @memberof DebriefPlaceholder
     */
    calendar: GoogleWorkspaceCalendar;
    /**
     * How long is the meeting in minutes.
     * @type {number}
     * @memberof DebriefPlaceholder
     */
    duration: number;
    /**
     * Calendar provider ID for the placeholder event.
     * @type {string}
     * @memberof DebriefPlaceholder
     */
    external_id: string;
    /**
     * Internal ID for the placeholder event.
     * @type {string}
     * @memberof DebriefPlaceholder
     */
    id: string;
    /**
     * Link to the event in Google Calendar.
     * @type {string}
     * @memberof DebriefPlaceholder
     */
    permalink: string;
    /**
     * Description of how the event repeats.
     * @type {string}
     * @memberof DebriefPlaceholder
     */
    recurrence_description: string;
    /**
     * Whether or not we should reuse the conference details from the placeholder when creating a debrief event.
     * @type {boolean}
     * @memberof DebriefPlaceholder
     */
    reuse_conference: boolean;
    /**
     * When does the event start (with timezone).
     * @type {string}
     * @memberof DebriefPlaceholder
     */
    start_time: string;
    /**
     * Title of the calendar event.
     * @type {string}
     * @memberof DebriefPlaceholder
     */
    title: string;
}

export function DebriefPlaceholderFromJSON(json: any): DebriefPlaceholder {
    return DebriefPlaceholderFromJSONTyped(json, false);
}

export function DebriefPlaceholderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebriefPlaceholder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendar': GoogleWorkspaceCalendarFromJSON(json['calendar']),
        'duration': json['duration'],
        'external_id': json['external_id'],
        'id': json['id'],
        'permalink': json['permalink'],
        'recurrence_description': json['recurrence_description'],
        'reuse_conference': json['reuse_conference'],
        'start_time': json['start_time'],
        'title': json['title'],
    };
}

export function DebriefPlaceholderToJSON(value?: DebriefPlaceholder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendar': GoogleWorkspaceCalendarToJSON(value.calendar),
        'duration': value.duration,
        'external_id': value.external_id,
        'id': value.id,
        'permalink': value.permalink,
        'recurrence_description': value.recurrence_description,
        'reuse_conference': value.reuse_conference,
        'start_time': value.start_time,
        'title': value.title,
    };
}

