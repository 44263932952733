/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompletionMessage,
    CompletionMessageFromJSON,
    CompletionMessageFromJSONTyped,
    CompletionMessageToJSON,
} from './CompletionMessage';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface AIRequest
 */
export interface AIRequest {
    /**
     * ID of the copilot interaction, if any
     * @type {string}
     * @memberof AIRequest
     */
    copilot_interaction_id?: string;
    /**
     * The cost of running this prompt
     * @type {number}
     * @memberof AIRequest
     */
    cost_cents: number;
    /**
     * When the request was made.
     * @type {Date}
     * @memberof AIRequest
     */
    created_at: Date;
    /**
     * Number of seconds the request took
     * @type {number}
     * @memberof AIRequest
     */
    duration_seconds: number;
    /**
     * A copiable eval case
     * @type {string}
     * @memberof AIRequest
     */
    eval: string;
    /**
     * ID of the request
     * @type {string}
     * @memberof AIRequest
     */
    id: string;
    /**
     * The external ID of the incident this request is related to (if any)
     * @type {string}
     * @memberof AIRequest
     */
    incident?: string;
    /**
     * Which prompt is this request for
     * @type {string}
     * @memberof AIRequest
     */
    prompt: string;
    /**
     * Messages we sent to the LLM
     * @type {Array<CompletionMessage>}
     * @memberof AIRequest
     */
    raw_messages: Array<CompletionMessage>;
    /**
     * YAML of the response from the LLM
     * @type {string}
     * @memberof AIRequest
     */
    response: string;
    /**
     * Which trace ID is this request for
     * @type {string}
     * @memberof AIRequest
     */
    trace: string;
    /**
     * 
     * @type {User}
     * @memberof AIRequest
     */
    user?: User;
}

export function AIRequestFromJSON(json: any): AIRequest {
    return AIRequestFromJSONTyped(json, false);
}

export function AIRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'copilot_interaction_id': !exists(json, 'copilot_interaction_id') ? undefined : json['copilot_interaction_id'],
        'cost_cents': json['cost_cents'],
        'created_at': (new Date(json['created_at'])),
        'duration_seconds': json['duration_seconds'],
        'eval': json['eval'],
        'id': json['id'],
        'incident': !exists(json, 'incident') ? undefined : json['incident'],
        'prompt': json['prompt'],
        'raw_messages': ((json['raw_messages'] as Array<any>).map(CompletionMessageFromJSON)),
        'response': json['response'],
        'trace': json['trace'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function AIRequestToJSON(value?: AIRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'copilot_interaction_id': value.copilot_interaction_id,
        'cost_cents': value.cost_cents,
        'created_at': (value.created_at.toISOString()),
        'duration_seconds': value.duration_seconds,
        'eval': value.eval,
        'id': value.id,
        'incident': value.incident,
        'prompt': value.prompt,
        'raw_messages': ((value.raw_messages as Array<any>).map(CompletionMessageToJSON)),
        'response': value.response,
        'trace': value.trace,
        'user': UserToJSON(value.user),
    };
}

