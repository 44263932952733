import { InternalStatusPage } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  ContentBox,
  Icon,
  IconEnum,
  StackedList,
} from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { SettingsSubHeading } from "src/components/settings/SettingsSubHeading";
import { useAPI, useAPIMutation } from "src/utils/swr";

export const InternalStatusPageSubscriptions = () => {
  const {
    data: { internal_status_pages: pages },
    isLoading: pagesLoading,
  } = useAPI("internalStatusPageListPages", undefined, {
    fallbackData: { internal_status_pages: [] },
  });
  const {
    data: { incident_auto_subscribe_rules: rules },
    isLoading: rulesLoading,
  } = useAPI("incidentSubscriptionsListAutoSubscribeRules", undefined, {
    fallbackData: { incident_auto_subscribe_rules: [] },
  });

  // We don't show this if you don't have internal status pages
  if (pagesLoading || pages?.length === 0) {
    return null;
  }

  return (
    <LoadingWrapper loading={rulesLoading}>
      <ContentBox className="p-6">
        <SettingsSubHeading
          title="Internal status page subscriptions"
          explanation={`
              Subscribe to all incidents that are posted to an internal status
              page automatically. If an incident also matches an auto-subscribe
              rule, we will only notify you once for each update.
            `}
        />
        <StackedList className={"mb-4 max-w-4xl"}>
          {pages.map((page) => (
            <Item
              key={page.id}
              page={page}
              ruleId={
                rules.find((rule) => rule.internal_status_page?.id === page.id)
                  ?.id ?? null
              }
            />
          ))}
        </StackedList>
      </ContentBox>
    </LoadingWrapper>
  );
};

const Item = ({
  page,
  ruleId,
}: {
  page: InternalStatusPage;
  ruleId: string | null;
}) => {
  const { trigger: onDelete, isMutating: isDeleting } = useAPIMutation(
    "incidentSubscriptionsListAutoSubscribeRules",
    undefined,
    async (apiClient, { id }: { id: string }) =>
      await apiClient.incidentSubscriptionsDestroyAutoSubscribeRule({ id }),
  );

  const { trigger: onCreate, isMutating: isCreating } = useAPIMutation(
    "incidentSubscriptionsListAutoSubscribeRules",
    undefined,
    async (apiClient) => {
      await apiClient.incidentSubscriptionsCreateAutoSubscribeRule({
        createAutoSubscribeRuleRequestBody: {
          conditions: [],
          internal_status_page_id: page.id,
        },
      });
    },
  );

  const isMutating = isDeleting || isCreating;

  return (
    <li className="px-4 pt-3 pb-2 flex flex-row justify-between items-center bg-surface-secondary">
      <Button
        className="group"
        theme={ButtonTheme.Naked}
        analyticsTrackingId={"prefs-view-internal-sp"}
        href={page.page_url}
        openInNewTab
      >
        {page.name}
        <Icon id={IconEnum.ExternalLink} />
      </Button>
      <Button
        analyticsTrackingId={
          ruleId
            ? "prefs-internal-sp-unsubscribe"
            : "prefs-internal-sp-subscribe"
        }
        loading={isMutating}
        disabled={isMutating}
        className="min-w-[120px] justify-center"
        onClick={ruleId ? () => onDelete({ id: ruleId }) : () => onCreate({})}
      >
        {ruleId ? "Unsubscribe" : "Subscribe"}
      </Button>
    </li>
  );
};
