import { usePylon } from "@bolasim/react-use-pylon";
import { DisableConfirmationModal } from "@incident-shared/forms/DisableConfirmationModal/DisableConfirmationModal";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, Callout, CalloutTheme, Txt } from "@incident-ui";
import { default as React, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  RBACRole,
  SCIMShowSettingsResponseBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSubHeading } from "../../SettingsSubHeading";
import { UpsellNotice } from "../../UpsellNotice";
import { ScimGroupRoleMappingSection } from "./ScimGroupRoleMappingSection";
import { ScimSetupModal } from "./ScimSetupModal";
import { ScimSetupStep } from "./ScimShowPage";

// https://workos.com/docs/reference/directory-sync/directory
enum ScimDirectoryType {
  AZURE_SCIM_V2_0 = "azure scim v2.0",
  BAMBOOHR = "bamboohr",
  BREATHE_HR = "breathe hr",
  CEZANNE_HR = "cezanne hr",
  GENERIC_SCIM_V1_1 = "generic scim v1.1",
  GENERIC_SCIM_V2_0 = "generic scim v2.0",
  GSUITE_DIRECTORY = "gsuite directory",
  JUMP_CLOUD_SCIM_V2_0 = "jump cloud scim v2.0",
  OKTA_SCIM_V1_1 = "okta scim v1.1",
  OKTA_SCIM_V2_0 = "okta scim v2.0",
  ONELOGIN_SCIM_V2_0 = "onelogin scim v2.0",
  PEOPLE_HR = "people hr",
  PINGFEDERATE_SCIM_V2_0 = "pingfederate scim v2.0",
  WORKDAY = "workday",
}

const DIRECTORY_TYPE_TO_NAME_MAPPING: Record<ScimDirectoryType, string> = {
  [ScimDirectoryType.AZURE_SCIM_V2_0]: "Azure SCIM v2.0",
  [ScimDirectoryType.BAMBOOHR]: "BambooHR",
  [ScimDirectoryType.BREATHE_HR]: "Breathe",
  [ScimDirectoryType.CEZANNE_HR]: "Cezanne",
  [ScimDirectoryType.GENERIC_SCIM_V1_1]: "A Generic SCIM v1.0 Provider",
  [ScimDirectoryType.GENERIC_SCIM_V2_0]: "A Generic SCIM v2.0 Provider",
  [ScimDirectoryType.GSUITE_DIRECTORY]: "GSuite",
  [ScimDirectoryType.JUMP_CLOUD_SCIM_V2_0]: "Jump Could SCIM v2.0",
  [ScimDirectoryType.OKTA_SCIM_V1_1]: "Okta v1.1",
  [ScimDirectoryType.OKTA_SCIM_V2_0]: "Okta v2.0",
  [ScimDirectoryType.ONELOGIN_SCIM_V2_0]: "OneLogin v2.0",
  [ScimDirectoryType.PEOPLE_HR]: "PeopleHR",
  [ScimDirectoryType.PINGFEDERATE_SCIM_V2_0]: "PingFederate v2.0",
  [ScimDirectoryType.WORKDAY]: "Workday",
};

export const scimIntegrationURL = (orgID: string, forTeam: boolean) => {
  const searchParams = new URLSearchParams();
  searchParams.set("intent", "dsync");
  searchParams.set("organisation_id", orgID);
  if (forTeam) {
    searchParams.set(
      "return_path",
      "catalog/team-wizard/choose-source-of-truth",
    );
    searchParams.set(
      "success_path",
      "catalog/team-wizard/choose-teams?resource_type=SCIMGroup",
    );
  }
  return `/auth/workos_portal?${searchParams.toString()}`;
};

export const useSCIMPortalURL = (forTeam = false) => {
  const { identity, hasScope } = useIdentity();
  const hasPermission = hasScope(ScopeNameEnum.ScimUpdate);
  return {
    hasPermission: hasPermission,
    scimPortalURL:
      identity?.organisation_id && hasPermission
        ? scimIntegrationURL(identity.organisation_id, forTeam)
        : null,
  };
};

export const cleanDirectoryName = (name: string | undefined): string => {
  if (!name) {
    return "A Generic SCIM Provider";
  }
  return DIRECTORY_TYPE_TO_NAME_MAPPING[name] ?? name;
};

export const heading = "Automatic user provisioning (SCIM)";

export const ScimShowPageInner = ({
  scimConfigState,
  roles,
  step,
}: {
  scimConfigState: SCIMShowSettingsResponseBody;
  roles: RBACRole[];
  step: ScimSetupStep;
}): React.ReactElement | null => {
  const { identity } = useIdentity();
  const { search } = useLocation();
  const [showCompleteSetupModal, setShowCompleteSetupModal] = useState(
    search.includes("install") && step === ScimSetupStep.NeedsOwnerAssignment,
  );

  // If the org doesn't have the billing gate, show the upsell banner.
  // If they have SCIM enabled however, show them SCIM. It's possible they downgraded from enterprise, we'll
  // let them disable SCIM, but they won't be able to re-enable it.
  if (!identity?.feature_gates.scim && !scimConfigState.enabled) {
    return <ScimUpsellBanner bare />;
  }

  const manageScimButton = (
    <>
      <ManageScimButton
        step={step}
        setShowCompleteSetupModal={setShowCompleteSetupModal}
      />
    </>
  );

  return (
    <div className="mt-4">
      <div className="grow">
        <SettingsSubHeading
          title={heading}
          explanation={<ScimHelpText includeHelpLink />}
          accessory={step === ScimSetupStep.Installed ? manageScimButton : null}
        />
        <div className="mt-2 space-y-4">
          <ScimInfoBanner step={step} scimConfigState={scimConfigState} />
          {step !== ScimSetupStep.Installed && manageScimButton}
        </div>
      </div>
      {showCompleteSetupModal && (
        <ScimSetupModal
          setShowCompleteSetupModal={setShowCompleteSetupModal}
          scimConfigState={scimConfigState}
          roles={roles}
          onComplete={() => {
            setShowCompleteSetupModal(false);
          }}
        />
      )}
      {step === ScimSetupStep.Installed && (
        <ScimGroupRoleMappingSection
          showHeading={true}
          roles={roles}
          scimConfigState={scimConfigState}
          setHasSetOwnerMapping={() => {
            // noop - we don't need to do anything here, this is only used for validation on first install
          }}
        />
      )}
    </div>
  );
};

export const ScimUpsellBanner = ({ bare }: { bare: boolean }) => {
  return (
    <UpsellNotice
      bare={bare}
      className={"mt-4"}
      analyticsId={"scim-upsell-banner"}
      title={heading}
      planName={"Enterprise"}
      description={<ScimHelpText includeHelpLink={false} />}
      articleId={"3136902249"}
    />
  );
};

const ManageScimButton = ({
  step,
  setShowCompleteSetupModal,
}: {
  step: ScimSetupStep;
  setShowCompleteSetupModal: (v: boolean) => void;
}) => {
  const { scimPortalURL, hasPermission: canEditScimSettings } =
    useSCIMPortalURL();

  const [action, setAction] = useState<"configuring" | "deleting" | null>(null);
  const [showDisableModal, setShowDisableModal] = useState(false);

  const onClickConfigure = () => {
    setAction("configuring");
    if (scimPortalURL) {
      window.location.href = scimPortalURL;
    }
  };

  const { trigger: uninstallScimConfig } = useAPIMutation(
    "sCIMShowSettings",
    undefined,
    async (apiClient, _) => {
      await apiClient.sCIMUninstall();
      return { enabled: false };
    },
  );

  const onClickDisable = async () => {
    setAction("deleting");
    setShowDisableModal(false);
    await uninstallScimConfig({}).finally(() => setAction(null));
  };

  // Only users with the required scope should be able to edit these settings.
  // But we still want to show the button to other users.
  const tooltipContent = canEditScimSettings
    ? undefined
    : "You don't have permission to manage security settings.";

  const onDisableClick = () => setShowDisableModal(true);
  let button: JSX.Element | null;
  if (step === ScimSetupStep.Uninstalled) {
    button = (
      <GatedButton
        analyticsTrackingId={"connect-scim"}
        disabledTooltipContent={tooltipContent}
        onClick={onClickConfigure}
        loading={action === "configuring"}
        disabled={!canEditScimSettings || action === "deleting"}
      >
        Connect
      </GatedButton>
    );
  } else if (step === ScimSetupStep.PartiallyInstalled) {
    button = (
      <GatedButton
        analyticsTrackingId={"connect-scim"}
        disabledTooltipContent={tooltipContent}
        onClick={onClickConfigure}
        loading={action === "configuring"}
        disabled={!canEditScimSettings || action === "deleting"}
      >
        Finish setup
      </GatedButton>
    );
  } else {
    if (step === ScimSetupStep.NeedsOwnerAssignment) {
      button = (
        <div className={"flex gap-2"}>
          <GatedButton
            onClick={() => setShowCompleteSetupModal(true)}
            disabledTooltipContent={tooltipContent}
            analyticsTrackingId={"complete-scim"}
            disabled={!canEditScimSettings || action === "configuring"}
          >
            Complete setup
          </GatedButton>
          <GatedButton
            onClick={onDisableClick}
            disabledTooltipContent={tooltipContent}
            analyticsTrackingId={"disable-scim"}
            theme={ButtonTheme.DestroySecondary}
            loading={action === "deleting"}
            disabled={!canEditScimSettings || action === "configuring"}
          >
            Cancel setup
          </GatedButton>
        </div>
      );
    } else {
      // Otherwise, we must be all connected 😯
      button = (
        <GatedButton
          onClick={onDisableClick}
          disabledTooltipContent={tooltipContent}
          analyticsTrackingId={"disable-scim"}
          theme={ButtonTheme.DestroySecondary}
          loading={action === "deleting"}
          disabled={!canEditScimSettings || action === "configuring"}
        >
          Disable SCIM
        </GatedButton>
      );
    }
  }

  return (
    <>
      {button}
      {showDisableModal && (
        <DisableConfirmationModal
          title={"Disable SCIM"}
          submitText={"Disable SCIM"}
          typeToConfirmPhrase={"disable SCIM"}
          helpText={
            <>
              <p className="mb-2">This action cannot be undone.</p>
              <p className="mb-2">
                Once SCIM is disabled, you will no longer be able to edit user
                roles in your identity provider. Users will retain their current
                roles, which you can manually edit.
              </p>
              <p className="mb-2">
                To re-enable SCIM, you will have to go through the set-up steps
                again.
              </p>
              <p className="mb-2">
                Please type &quot;disable SCIM&quot; below to confirm.
              </p>
            </>
          }
          onSubmit={onClickDisable}
          onClose={() => setShowDisableModal(false)}
        />
      )}
    </>
  );
};

// ScimInfoBanner is displayed when an organisation are eligible to use SCIM. It displays info about
// the state of the organisation's SCIM installation.
const ScimInfoBanner = ({
  scimConfigState,
  step,
}: {
  scimConfigState: SCIMShowSettingsResponseBody;
  step: ScimSetupStep;
}) => {
  const directoryType = scimConfigState.scim_config?.directory_type;
  const directoryName = cleanDirectoryName(directoryType);

  if (step === ScimSetupStep.Uninstalled) {
    return null;
  }

  if (step === ScimSetupStep.PartiallyInstalled) {
    return (
      <Callout theme={CalloutTheme.Warning}>
        <h3 className="font-medium">
          You&apos;ve not finished connecting SCIM yet
        </h3>
        <div className="mt-2 space-y-2">
          You have an incomplete SCIM set up in progress. You can press the
          button on the right to continue, or reach out to our support team if
          you&apos;d like some additional help.
        </div>
      </Callout>
    );
  } else if (step === ScimSetupStep.NeedsOwnerAssignment) {
    return (
      <>
        <Callout theme={CalloutTheme.Info}>
          <div className="space-y-2">
            <h3 className="font-medium">
              {`You've not finished setting up SCIM yet`}
            </h3>
            <p className="mt-2 space-y-2">
              You&apos;re connected to SCIM via <strong>{directoryName}</strong>
              , but you&apos;ll need to configure your organisation&apos;s role
              settings before you can use it.
            </p>
          </div>
        </Callout>
      </>
    );
  }

  // Otherwise, we must be all connected 😯
  return (
    <Callout theme={CalloutTheme.Success}>
      <div className="space-y-2">
        <h3 className="font-medium">
          You&apos;re connected to SCIM via {directoryName}.
        </h3>
        <div className="mt-2 space-y-2">
          <p>
            Users will be automatically created and their roles set
            automatically based on their group member in {directoryName}.
          </p>
        </div>
      </div>
    </Callout>
  );
};

export const ScimHelpText = ({
  includeHelpLink,
}: {
  includeHelpLink: boolean;
}) => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  return (
    <Txt>
      Automatically provision users and configure their roles using your
      organisation&apos;s identity provider, such as Okta.
      {includeHelpLink && (
        <>
          {" "}
          You can learn more in our{" "}
          <Button
            theme={ButtonTheme.Link}
            analyticsTrackingId={"upsell-notice-learn-more"}
            onClick={() => showArticle("3136902249")}
          >
            help center
          </Button>
          .
        </>
      )}
    </Txt>
  );
};
