import {
  ExternalSchedule,
  ManagementMeta,
  Schedule,
  SchedulesShowResponseBody,
} from "@incident-io/api";
import { defaultManagementMeta } from "@incident-shared/management-meta/utils";
import { GenericErrorMessage, IconEnum, Loader } from "@incident-ui";
import {
  Drawer,
  DrawerProps,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryParams } from "src/utils/query-params";
import { ulid } from "ulid";

import { useAPI } from "../../../../../utils/swr";
import { useNow } from "../../../../../utils/use-now";
import { useRevalidate } from "../../../../../utils/use-revalidate";
import { useIntegrationsList } from "../../../../settings/integrations/IntegrationsRoute";
import { IntegrationDrawer } from "../../../../settings/integrations/list/IntegrationDrawer";
import {
  externalScheduleToFormData,
  getVersionId,
  scheduleToFormData,
} from "../common/marshall";
import { timezoneToCountries } from "../common/timezoneToCountries";
import {
  CustomHandoverRuleType,
  IntervalData,
  RotaFormData,
  RotaHandoverType,
  RotaId,
  RotationVersions,
  ScheduleFormData,
} from "../common/types";
import { getDefaultWorkingInterval } from "../common/util";
import { ScheduleCopyToTerraformDrawer } from "./ScheduleCopyToTerraform";
import { ScheduleCreateEditForm } from "./ScheduleCreateEditForm";
import { ScheduleUser } from "./ScheduleCreateFormUsersList";

export const ScheduleCreateEditDrawer = ({
  onClose,
  editId,
  duplicateId,
  initialRotaId,
  onScheduleSaved,
}: {
  onClose: () => void;
  editId?: string;
  duplicateId?: string;
  initialRotaId?: string;
  onScheduleSaved?: (res: Schedule) => void;
}) => {
  // useRef means this won't change over the lifetime of the component, even if the query param is removed
  const externalScheduleImportId = React.useRef(
    useQueryParams().get("from_external_schedule"),
  ).current;

  const {
    data: scheduleData,
    isLoading: scheduleLoading,
    error: scheduleError,
  } = useAPI(editId || duplicateId ? "schedulesShow" : null, {
    id: editId ?? duplicateId ?? "",
  });

  const {
    data: externalScheduleData,
    isLoading: externalScheduleLoading,
    error: externalScheduleError,
  } = useAPI(externalScheduleImportId ? "schedulesShowExternal" : null, {
    id: externalScheduleImportId ?? "",
    includeNativeConfigPayload: true,
  });

  const sharedDrawerProps: Omit<DrawerProps, "children"> = {
    onClose,
    width: "large",
  };

  const initialData = editId ? scheduleData?.schedule : undefined;
  const importExternalScheduleData = externalScheduleData?.external_schedule;
  const initialDuplicateData = duplicateId ? scheduleData?.schedule : undefined;

  if (scheduleError || externalScheduleError) {
    return (
      <Drawer {...sharedDrawerProps}>
        <GenericErrorMessage />
      </Drawer>
    );
  }

  if (
    (duplicateId && !initialDuplicateData) ||
    (editId && !scheduleData) ||
    (externalScheduleImportId && !externalScheduleData) ||
    scheduleLoading ||
    externalScheduleLoading
  ) {
    return (
      <Drawer {...sharedDrawerProps}>
        <Loader />
      </Drawer>
    );
  }

  return (
    <ScheduleCreateEditFormWrapper
      onScheduleSaved={onScheduleSaved}
      onClose={onClose}
      initialData={initialData}
      managementMeta={scheduleData?.management_meta ?? defaultManagementMeta()}
      initialDuplicateData={initialDuplicateData}
      importExternalScheduleData={importExternalScheduleData}
      initialRotaId={initialRotaId}
      editId={editId}
      scheduleData={scheduleData}
      duplicateId={duplicateId}
      sharedDrawerProps={sharedDrawerProps}
      externalScheduleImportId={externalScheduleImportId}
    />
  );
};

// This component is a middle component to ensure that the ScheduleCreateEditForm is prepopulated correctly
const ScheduleCreateEditFormWrapper = ({
  initialData,
  initialDuplicateData,
  managementMeta,
  importExternalScheduleData,
  onScheduleSaved,
  initialRotaId,
  onClose,
  externalScheduleImportId,
  editId,
  scheduleData,
  duplicateId,
  sharedDrawerProps,
}: {
  initialData: Schedule | undefined;
  initialDuplicateData: Schedule | undefined;
  managementMeta: ManagementMeta;
  importExternalScheduleData: ExternalSchedule | undefined;
  onScheduleSaved: ((result: Schedule) => void) | undefined;
  initialRotaId: string | undefined;
  onClose: () => void;
  externalScheduleImportId: string | null;
  editId: string | undefined;
  scheduleData: SchedulesShowResponseBody | undefined;
  duplicateId: string | undefined;
  sharedDrawerProps: Omit<DrawerProps, "children">;
}) => {
  // These should be loaded by the time this component is called, otherwise the defaultValues
  // for the form will be initialised wrong, and even if they get fixed on latter renders, the form
  // will never update with the correct data
  if (duplicateId && !initialDuplicateData) {
    throw new Error("Duplicate schedule not found");
  } else if (editId && !scheduleData) {
    throw new Error("Schedule not found");
  } else if (externalScheduleImportId && !importExternalScheduleData) {
    throw new Error("External schedule not found");
  }

  const showToast = useToast();

  const [showCalendarFeedDrawer, setShowCalendarFeedDrawer] =
    useState<boolean>(false);
  const revalidateCalendarFeeds = useRevalidate(["holidaysListFeeds"]);

  const [showTerraformDrawer, setShowTerraformDrawer] =
    useState<boolean>(false);

  const openTerraformDrawer = () => {
    setShowTerraformDrawer(true);
  };
  const closeTerraformDrawer = () => {
    setShowTerraformDrawer(false);
    onClose();
    showToast({
      title: "",
      description: "Copy your schedule configuration to Terraform",
      theme: ToastTheme.Info,
      toastSide: ToastSideEnum.Bottom,
    });
  };

  const initialDataNow = useNow(initialData?.timezone);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const defaultHandoverTime = new Date();
  defaultHandoverTime.setHours(9, 0, 0, 0);

  const getNewRota = (index: number): RotaFormData => {
    return {
      id: ulid(),
      version_id: getVersionId(null, index === -1),
      name: index === -1 ? `Rotation` : `Rotation ${index}`,
      users: Array<ScheduleUser>(),
      handover_start_at: defaultHandoverTime,
      layer_count: 1,
      has_working_intervals: "all_day" as const,
      working_intervals: [getDefaultWorkingInterval()] as IntervalData,
      custom_handovers: [
        {
          handover_interval: "1",
          handover_interval_type: CustomHandoverRuleType.Weekly,
        },
      ],
      rota_handover_type: RotaHandoverType.Weekly,
      is_deferred: "false", // Default to immediate changes
    };
  };

  const defaultValues = getDefaultValues({
    initialData: initialData || initialDuplicateData,
    isDuplicating: !!initialDuplicateData,
    importExternalScheduleData,
    now: initialDataNow,
    userTimezone,
    getNewRota,
  });

  const formMethods = useForm<ScheduleFormData>({
    defaultValues,
  });

  return (
    <FormProvider<ScheduleFormData> {...formMethods}>
      <Drawer
        {...sharedDrawerProps}
        isInBackground={showTerraformDrawer || showCalendarFeedDrawer}
        warnWhenDirty
        width="full"
        className="flex flex-col"
      >
        <DrawerTitle
          title={
            externalScheduleImportId
              ? "Import schedule"
              : editId
              ? `Edit ${scheduleData?.schedule.name}`
              : duplicateId
              ? `Duplicate ${scheduleData?.schedule.name}`
              : "Create a new schedule"
          }
          icon={IconEnum.Calendar}
          iconClassName={"text-content-primary bg-surface-secondary"}
          onClose={onClose}
          compact
          sticky
          theme={DrawerTitleTheme.Bordered}
        />
        <ScheduleCreateEditForm
          onScheduleSaved={onScheduleSaved}
          onClose={onClose}
          initialData={initialData}
          initialDuplicateData={initialDuplicateData}
          managementMeta={managementMeta}
          importExternalScheduleData={importExternalScheduleData}
          initialTab={initialRotaId}
          openTerraformDrawer={openTerraformDrawer}
          openCalendarFeedDrawer={() => setShowCalendarFeedDrawer(true)}
          formMethods={formMethods}
          defaultValues={defaultValues}
          getNewRota={getNewRota}
        />
      </Drawer>
      <ScheduleCopyToTerraformDrawer
        managementMeta={managementMeta}
        onClose={closeTerraformDrawer}
        isOpen={showTerraformDrawer}
        resourceID={editId}
      />
      {showCalendarFeedDrawer && (
        <ScheduleCalendarFeedDrawer
          onClose={() => {
            setShowCalendarFeedDrawer(false);
            revalidateCalendarFeeds();
          }}
        />
      )}
    </FormProvider>
  );
};

const ScheduleCalendarFeedDrawer = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const { integrations, error } = useIntegrationsList();

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <IntegrationDrawer
      integrations={integrations}
      activeIntegration={"calendar_feeds"}
      backHref={"-1"} // We won't use this
      onClose={onClose}
    />
  );
};

const getDefaultValues = ({
  initialData,
  importExternalScheduleData,
  userTimezone,
  now,
  getNewRota,
  isDuplicating,
}: {
  initialData?: Schedule;
  importExternalScheduleData?: ExternalSchedule;
  userTimezone: string;
  now: DateTime;
  getNewRota: (index: number) => RotaFormData;
  isDuplicating: boolean;
}): ScheduleFormData => {
  if (initialData) {
    return scheduleToFormData({
      schedule: initialData,
      now,
      isDuplicating,
    });
  }

  if (importExternalScheduleData) {
    return externalScheduleToFormData(importExternalScheduleData);
  }

  const newRota = getNewRota(-1);
  const rotaId = newRota.id as string;
  const rotaVersionId = newRota.version_id;
  const rotations: Record<RotaId, RotationVersions> = {
    [rotaId]: {
      [rotaVersionId]: newRota,
    },
  };

  return {
    name: "",
    timezone: userTimezone,
    rotations: rotations,
    selected_rotation_versions: {
      [rotaId]: rotaVersionId,
    },
    holidays_public_config: {
      country_codes: timezoneToCountries[userTimezone] || [],
    },
  };
};
