import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { assertUnreachable } from "src/utils/utils";

import { PathNode } from "../../common/types";
import { getNextNodeId } from "./getNextNodeId";

export const shouldDisplayWorkingHoursTimeToAck = (
  nodes: Record<string, PathNode>,
  node: PathNode,
  firstNodeId: string,
) => {
  const nextNodeId = getNextNodeId({ node });

  // If there's no next node, then we are the last level node
  if (!nextNodeId) {
    return true;
  }
  const nextNode: PathNode = nodes[nextNodeId];

  // We only show the working hours time to ack option if this is the last level node in the
  // tree (on the branch). Meaning there's either no next node, or the next node is a repeat node.
  const lastLevelNode = !nextNode || nextNode.data.type === NodeTypes.Repeat;
  if (!lastLevelNode) {
    return false;
  }

  // Helper function to determine if a node is in a branched path or not
  const isBranchedNode = (
    currentNodeId: string | undefined,
    targetNode: PathNode,
    isBranched: boolean,
  ) => {
    if (!currentNodeId) {
      // We've found an empty branch: that cannot be branched!
      return false;
    }
    const currentNode = nodes[currentNodeId];

    // Base case: we've found the node we're looking for, and have travered all nodes
    // in the path before it and know if it's branched or not
    if (currentNode.id === targetNode.id) {
      return isBranched;
    }

    switch (currentNode.data.type) {
      case NodeTypes.Repeat:
        return false;
      case NodeTypes.Level:
        // If we've found a level node that's at the end of a branch, and which doesn't
        // have a next node, then we know it's not branched, since we've looked
        // through the entire tree and not found the node we were looking for.
        if (!currentNode.data.nextNodeId) {
          return false;
        }

        return isBranchedNode(
          currentNode.data.nextNodeId,
          targetNode,
          isBranched,
        );
      case NodeTypes.NotifyChannel:
        if (!currentNode.data.nextNodeId) {
          return false;
        }

        return isBranchedNode(
          currentNode.data.nextNodeId,
          targetNode,
          isBranched,
        );
      case NodeTypes.IfElse:
        const thenPath = isBranchedNode(
          currentNode.data.if_else.thenNodeId,
          targetNode,
          true,
        );

        const elsePath = isBranchedNode(
          currentNode.data.if_else.elseNodeId,
          targetNode,
          true,
        );

        return thenPath || elsePath;

      default:
        assertUnreachable(currentNode.data);
        return false;
    }
  };

  // We show the working hours time to ack options if the next node is a repeat node
  // and if the node is in a branched path.
  return isBranchedNode(firstNodeId, node, false) && lastLevelNode;
};
