import { UpgradeRequiredProps } from "@incident-shared/gates/gates";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

export const useCanAddCustomCatalogType = () => {
  const {
    data: { catalog_types },
    isLoading: typesLoading,
    error,
  } = useAPI(
    "catalogListTypes",
    { includeCount: true },
    { fallbackData: { catalog_types: [] } },
  );

  const { identity } = useIdentity();
  const loading = typesLoading || !catalog_types || !identity;
  let upgradeRequired: boolean | undefined;

  if (!loading) {
    const customCatalogTypeCount = catalog_types.filter(
      (t) => t.mode === "manual",
    ).length;

    upgradeRequired = numericGateLimitReached(
      identity.feature_gates.custom_catalog_types_count,
      customCatalogTypeCount,
    );
  }

  const upgradeRequiredProps: UpgradeRequiredProps = {
    featureName: "custom catalog types",
    gate: {
      type: "numeric",
      value: identity?.feature_gates.custom_catalog_types_count,
      featureNameSingular: "custom catalog types",
    },
  };

  const isLimited = identity?.feature_gates.custom_catalog_types_count != null;

  return {
    upgradeRequired,
    upgradeRequiredProps,
    isLimited,
    loading,
    error,
  };
};
