import { usePylon } from "@bolasim/react-use-pylon";
import { ScopeNameEnum } from "@incident-io/api";
import {
  CallRoute,
  useCallRoutingServiceCallRoutingList,
} from "@incident-io/query-api";
import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  numericGateLimitReached,
  UpgradeRequiredProps,
} from "@incident-shared/gates/gates";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  IconEnum,
  IconSize,
  Loader,
  LocalRelativeDateTime,
  SlackTeamAvatar,
  Tooltip,
  Txt,
} from "@incident-ui";
import {
  Table,
  TableCell,
  TableCellStacked,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { AnimatePresence } from "framer-motion";
import { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { useOutlet } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { EmptyState } from "../legacy/on-call/EmptyState";
import illustration from "./empty-state.svg";

export const CallRoutingPage = () => {
  const { data, isLoading, error } = useCallRoutingServiceCallRoutingList();
  const routes = data?.call_routes || [];

  const drawer = useOutlet();

  if (error) {
    return <GenericErrorMessage error={error as Error | undefined} />;
  }
  return (
    <>
      <AnimatePresence>{drawer}</AnimatePresence>
      {isLoading ? <Loader /> : <CallRoutingList routes={routes} />}
    </>
  );
};

const PhoneNumber = ({ phoneNumber }: { phoneNumber?: string }) => {
  const parsedNumber = parsePhoneNumber(phoneNumber ?? "");
  if (!parsedNumber) {
    return <span>{phoneNumber}</span>;
  }

  return <>{parsedNumber.formatInternational()}</>;
};

const CallRoutingList = ({ routes }: { routes: CallRoute[] }) => {
  const navigate = useOrgAwareNavigate();
  const { showKnowledgeBaseArticle } = usePylon();
  const { identity } = useIdentity();
  const showHelp = () => showKnowledgeBaseArticle("6647601278");
  const upgradeRequired = numericGateLimitReached(
    identity.feature_gates.call_routes_count,
    routes.length,
  );
  const upgradeRequiredProps: UpgradeRequiredProps = {
    featureName: "Call routing",
    gate: {
      type: "numeric",
      value: identity.feature_gates.call_routes_count,
      featureNameSingular: "call route",
    },
  };

  if (routes.length === 0) {
    return (
      <CallRoutingEmptyState
        upgradeRequired={upgradeRequired}
        upgradeRequiredProps={upgradeRequiredProps}
      />
    );
  }

  return (
    <>
      <div className="flex justify-between w-full mb-6">
        <div className="max-w-[80%] h-full flex flex-col justify-between">
          <div className="block lg:flex lg:flex-between">
            <div className={"mt-2"}>
              <Heading level={1} size="medium" className="mb-2">
                <div className="flex items-center gap-2">
                  Connect phone calls to on-call responders
                </div>
              </Heading>
              <Txt className="max-w-3xl">
                With live call routing, you can expand your support offering by
                connecting incoming phone calls to the right person, schedule,
                or on-call team.
              </Txt>
            </div>
          </div>
        </div>
        <div className="shrink-0 flex items-center gap-2">
          <Button
            onClick={showHelp}
            theme={ButtonTheme.Tertiary}
            analyticsTrackingId={"call-route-help"}
          >
            Learn more
          </Button>

          <GatedButton
            upgradeRequired={upgradeRequired}
            upgradeRequiredProps={upgradeRequiredProps}
            href="/on-call/call-routes/create"
            analyticsTrackingId={"call-route-request"}
            icon={IconEnum.Add}
            theme={ButtonTheme.Secondary}
            className="!py-2.5 grow-0"
          >
            Request number
          </GatedButton>
        </div>
      </div>
      <Table<CallRoute>
        gridTemplateColumns={`320px 200px 200px 1fr`}
        header={
          <>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Number</TableHeaderCell>
            <TableHeaderCell>Last call</TableHeaderCell>
            <TableHeaderCell />
          </>
        }
        data={routes}
        renderRow={(route, index) => (
          <TableRow
            key={route.id}
            isLastRow={index === routes.length - 1}
            className="text-sm group"
            onClick={() => navigate(`/on-call/call-routes/${route.id}`)}
          >
            <TableCell
              key={`name-${route.id}`}
              className="text-content-primary font-semibold"
            >
              {route.name}
            </TableCell>
            <TableCell
              key={`number-${route.id}`}
              className={tcx({
                "text-content-secondary": route.current_state === "active",
                "text-content-tertiary": route.current_state === "pending",
              })}
            >
              {route.current_state === "active" ? (
                <PhoneNumber phoneNumber={route.phone_number} />
              ) : (
                <Badge theme={BadgeTheme.Tertiary} className="-my-0.5">
                  Pending
                  <Tooltip
                    content={
                      <>
                        We&rsquo;re working on activating a phone number for
                        you. We&rsquo;ll be in touch once it&rsquo;s ready!
                      </>
                    }
                  />
                </Badge>
              )}
            </TableCell>
            <TableCellStacked
              primary={
                route.most_recent_call ? (
                  <span className="line-clamp-1">
                    From{" "}
                    <span className="text-slate-800 font-semibold">
                      {route.most_recent_call?.caller_phone_number}
                    </span>
                  </span>
                ) : null
              }
              secondary={
                route.most_recent_call ? (
                  <LocalRelativeDateTime
                    className="text-xs-med"
                    date={route.most_recent_call.created_at}
                  />
                ) : null
              }
            />
            <TableCell className="justify-end">
              <Button
                icon={IconEnum.Edit}
                analyticsTrackingId={`call-route-edit`}
                analyticsTrackingMetadata={{ callRouteID: route.id }}
                href={`/on-call/call-routes/${route.id}`}
                title="Edit"
                iconProps={{ size: IconSize.Small }}
                theme={ButtonTheme.Naked}
                className="group-hover:text-content-primary transition"
              />
            </TableCell>
          </TableRow>
        )}
      />
    </>
  );
};

const CallRoutingEmptyState = ({
  upgradeRequired,
  upgradeRequiredProps,
}: {
  upgradeRequired: boolean;
  upgradeRequiredProps: UpgradeRequiredProps;
}) => {
  const { identity } = useIdentity();
  const { showKnowledgeBaseArticle } = usePylon();
  const showHelp = () => showKnowledgeBaseArticle("6647601278");

  return (
    <EmptyState
      title="Connect phone calls to on-call responders"
      copy={
        <>
          With live call routing, you can expand your support offering by
          connecting incoming phone calls to the right person, schedule, or
          on-call team.
        </>
      }
      imageSrc={illustration}
      imageClassName="absolute min-w-[600px] left-[calc(50%-300px)] top-0"
      imageOverlay={
        <>
          <div className="w-[236px] absolute top-[80px] left-[calc(50%-118px)] flex flex-col items-center">
            <SlackTeamAvatar
              title=""
              url={identity.organisation_icon_url}
              className="w-[60px] h-[60px] rounded-full mb-5 mt-7 shadow-sm"
              placeholderImg="/slack-team-avatar-placeholder-dark.svg"
            />
            <span className="text-sm font-semibold mb-1">
              {identity.organisation_name}
            </span>
            <span className="text-sm text-slate-200">
              <Ticker />
            </span>
          </div>
          <div className="h-[360px]" />
        </>
      }
      buttons={[
        {
          children: "Request a number",
          href: "/on-call/call-routes/create",
          analyticsTrackingId: "call-route-add",
          theme: ButtonTheme.Primary,
          isGated: true,
          gatingProps: {
            requiredScope: ScopeNameEnum.CallRoutesCreate,
            requiredProduct: Product.OnCall,
            upgradeRequired,
            upgradeRequiredProps,
          },
        },
        {
          onClick: showHelp,
          analyticsTrackingId: "call-route-help",
          theme: ButtonTheme.Secondary,
          children: "Learn more",
        },
      ]}
    />
  );
};

const Ticker = () => {
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setDuration((duration) => (duration + 1) % 600); // roll back every 10 mins
    }, 1000);

    return () => clearInterval(interval);
  }, [setDuration]);

  const minutes = Math.floor(duration / 60)
    .toFixed(0)
    .padStart(2, "0");
  const seconds = Math.floor(duration % 60)
    .toFixed(0)
    .padStart(2, "0");

  return (
    <>
      {minutes}:{seconds}
    </>
  );
};
