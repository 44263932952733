import React, { createContext, useContext, useState } from "react";

// AssistantOverlayContext is a context that is used to toggle Assistant's chat overlay globally.
export const AssistantOverlayContext = createContext<{
  isOverlayOpen: boolean;
  toggleOverlay: (questionID?: string) => void;
  questionID: string | null;
  shouldShowIntro: boolean;
  setShouldShowIntro: React.Dispatch<React.SetStateAction<boolean>>;
  resetView: () => void;
}>({
  isOverlayOpen: false,
  toggleOverlay: () => {
    throw new Error("toggleOverlay function must be overridden");
  },
  shouldShowIntro: true,
  setShouldShowIntro: () => {
    throw new Error("setShouldShowIntro function must be overridden");
  },
  questionID: null,
  resetView: () => {
    throw new Error("resetView function must be overridden");
  },
});

export const AssistantOverlayProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // UI state.
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [shouldShowIntro, setShouldShowIntro] = useState(true);

  // API data state.
  const [questionID, setQuestionID] = useState<string | null>(null);

  const toggleOverlay = (questionID?: string) => {
    setIsOverlayOpen(!isOverlayOpen);
    if (questionID) {
      setQuestionID(questionID);
    } else {
      // Clear old question ID
      setQuestionID(null);
    }
  };

  const resetView = () => {
    // Reset the view to the intro screen.
    setShouldShowIntro(true);
    // Additionally, reset these to avoid confusion.
    setQuestionID(null);
  };

  return (
    <AssistantOverlayContext.Provider
      value={{
        isOverlayOpen,
        toggleOverlay,
        shouldShowIntro,
        setShouldShowIntro,
        questionID,
        resetView,
      }}
    >
      {children}
    </AssistantOverlayContext.Provider>
  );
};

export const useAssistantOverlay = () => useContext(AssistantOverlayContext);
