import { IntegrationSettingsProviderEnum } from "src/contexts/ClientContext";
import { useIntegrations } from "src/hooks/useIntegrations";

export const integrationsThatUseDelegatedPermissions = [
  IntegrationSettingsProviderEnum.OutlookCalendar,
  IntegrationSettingsProviderEnum.MicrosoftTeamsOnlineMeetings,
];

export const useInstalledIntegrationsThatUseDelegatedPermissions = () => {
  const { integrations } = useIntegrations();

  return integrations
    ?.filter(
      ({ provider, installed }) =>
        integrationsThatUseDelegatedPermissions.includes(provider) && installed,
    )
    .sort((a, b) => (a.provider_name < b.provider_name ? -1 : 1));
};
