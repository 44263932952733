import {
  IntegrationConfigFor,
  IntegrationListProvider,
} from "@incident-shared/integrations";
import { Avatar, Callout, CalloutTheme, IconSize } from "@incident-ui";

export const IntegrationConnectedUser = ({
  name,
  email,
  avatar_url,
  provider,
}: {
  name?: string;
  email?: string;
  avatar_url?: string;
  provider: IntegrationListProvider;
}) => {
  const config = IntegrationConfigFor(provider);
  return (
    <Callout theme={CalloutTheme.Info}>
      <div className="flex items-center gap-1 flex-wrap">
        <span className="inline">
          You&apos;re connected to {config.label} as user
        </span>
        <span className="flex items-center space-x-1">
          <Avatar
            size={IconSize.Small}
            url={avatar_url}
            name={name}
            className="shrink-0"
          />
          <span>
            {name && <span className="font-semibold">{name}</span>}
            {!!email && <>{` (${email})`}</>}
          </span>
        </span>
      </div>
    </Callout>
  );
};
