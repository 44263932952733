import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  ButtonTheme,
  Card,
  FullPageEmptyState,
  IconEnum,
  IconSize,
} from "@incident-ui";

import { ReactComponent as Graphic } from "./empty-state.svg";

export const WorkflowsListEmptyState = ({
  onButtonClick,
}: {
  onButtonClick: () => void;
}) => {
  return (
    <FullPageEmptyState
      title="Automate your incidents"
      graphic={<Graphic />}
      subtitle="Create workflows in seconds, and bring consistency to your incidents. Configure your processes once and we'll ensure they're followed every time."
      cta={
        <GatedButton
          analyticsTrackingId="workflows-empty-state-create"
          onClick={onButtonClick}
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.WorkflowsCreate}
        >
          Get started
        </GatedButton>
      }
      helpcenterArticleId={"7288327080"}
      gradientClasses="from-alarmalade-50 to-white from-20% via-80%"
      className="w-full"
      cards={
        <>
          <Card
            className="h-full"
            icon={IconEnum.Action}
            iconSize={IconSize.Medium}
            title="Bring consistency to incidents"
            description="Workflows guide responders through each incident step by step"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.Users}
            iconSize={IconSize.Medium}
            title="Empower your team"
            description="Create and run incidents autonomously, so you can scale your team with confidence"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.Maintenance}
            iconSize={IconSize.Medium}
            title="Eliminate busywork"
            description="We'll guide the process, so your time is freed up to focus on fixing"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
        </>
      }
    />
  );
};
