import { AlertRoute, ScopeNameEnum } from "@incident-io/api";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import {
  prependSlugToPathIfNeeded,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  LocalDateTime,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { differenceInSeconds } from "date-fns";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useInterval } from "usehooks-ts";

import { useIdentity } from "../../../contexts/IdentityContext";
import { useOptimisticAutoSave } from "../../../hooks/useOptimisticAutoSave";
import { usePrimaryCommsPlatform } from "../../../hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "../../../hooks/useProductAccess";
import { DurationEnum, formatDurationInSeconds } from "../../../utils/datetime";
import { useAPIMutation } from "../../../utils/swr";
import { tcx } from "../../../utils/tailwind-classes";
import { DeletionConfirmationModal } from "../../settings/DeletionConfirmationModal";
import {
  parseAlertRoute,
  parseFormData,
} from "../alert-route-create-edit/types";
import { AlertsConfigurationNodeWrapper } from "./AlertsConfigurationNode";
import { useAlertRoutes } from "./AlertsConfigurationPage";

export const AlertRouteItem = ({ id: alertRouteId }: { id: string }) => {
  const { alertRoutes } = useAlertRoutes();
  const alertRoute = alertRoutes.find((route) => route.id === alertRouteId);
  if (!alertRoute) {
    throw new Error("Alert route not found");
  }

  const showToast = useToast();
  const { slug } = useParams();
  const navigate = useOrgAwareNavigate();
  const { hasOnCall } = useProductAccess();
  const primaryCommsPlatform = usePrimaryCommsPlatform();

  const { trigger: onDelete } = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, { id }) => {
      await apiClient.alertRoutesDestroyAlertRoute({
        id,
      });
      return;
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: `${alertRoute.name} deleted.`,
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: `Could not delete ${alertRoute.name}`,
        });
      },
    },
  );

  return (
    <AlertsConfigurationNodeWrapper id={alertRouteId}>
      <div
        className={tcx(
          "p-4 rounded-xl shadow-sm border border-stroke-primary justify-start items-center gap-3 flex bg-white w-full hover:cursor-pointer",
        )}
        onClick={(e) => {
          let path = `/alerts/routes/${alertRoute.id}/edit`;
          if (slug) {
            path = prependSlugToPathIfNeeded(path, slug);
          }
          if (e.metaKey || e.ctrlKey) {
            const win = window.open(path, "_blank");
            win?.focus();
          } else {
            navigate(path);
          }
        }}
      >
        <div className="flex flex-col grow min-w-0 gap-1">
          <div className={"text-sm font-semibold truncate"}>
            {alertRoute.name}
          </div>
          <AlertRouteRecentlyCreatedText alertRoute={alertRoute} />
        </div>
        <div className={"flex flex-row gap-3"}>
          {alertRoute.enabled ? (
            <div className={"flex flex-row gap-2"}>
              {hasOnCall ? (
                <AlertRouteCreatingIcon
                  alertRoute={alertRoute}
                  resourceType={"escalations"}
                />
              ) : undefined}
              <AlertRouteCreatingIcon
                alertRoute={alertRoute}
                resourceType={"incidents"}
              />
              {primaryCommsPlatform === "slack" ? (
                <AlertRouteCreatingIcon
                  alertRoute={alertRoute}
                  resourceType={"pulse"}
                />
              ) : undefined}
            </div>
          ) : (
            <Badge theme={BadgeTheme.Tertiary}>
              <Tooltip
                side={"left"}
                content={
                  "This alert route has been manually deactivated and will not create incidents or escalations."
                }
              >
                <span>Inactive</span>
              </Tooltip>
            </Badge>
          )}
          <AlertRouteOverflowMenu
            alertRoute={alertRoute}
            onDelete={() => onDelete({ id: alertRoute.id })}
          />
        </div>
      </div>
    </AlertsConfigurationNodeWrapper>
  );
};

const AlertRouteRecentlyCreatedText = ({
  alertRoute,
}: {
  alertRoute: AlertRoute;
}) => {
  if (alertRoute.is_private) {
    return (
      <div className="flex flex-row gap-1 items-center text-content-tertiary text-xs-med truncate">
        <Icon id={IconEnum.LockClosed} size={IconSize.XS} />
        <div>Creates private incidents</div>
      </div>
    );
  }

  const incidentDate = alertRoute.approx_last_incident_created_at
    ? new Date(alertRoute.approx_last_incident_created_at)
    : undefined;

  const escalationDate = alertRoute.approx_last_escalation_created_at
    ? new Date(alertRoute.approx_last_escalation_created_at)
    : undefined;

  return (
    <AlertRouteTimeDisplay
      incidentDate={incidentDate}
      escalationDate={escalationDate}
    />
  );
};

type AlertRouteTimeDisplayProps = {
  incidentDate?: Date;
  escalationDate?: Date;
  className?: string;
};

export const AlertRouteTimeDisplay = ({
  incidentDate,
  escalationDate,
  className = "text-content-tertiary text-xs-med truncate",
}: AlertRouteTimeDisplayProps): React.ReactElement => {
  const [now, setNow] = useState(new Date());
  useInterval(() => setNow(new Date()), 15000);

  if (!incidentDate && !escalationDate) {
    return (
      <div className={className}>
        Never created an incident or an escalation
      </div>
    );
  }

  const formatRelativeTime = (date: Date) => {
    const diff = differenceInSeconds(now, date);
    const durationString = formatDurationInSeconds(
      diff,
      1,
      DurationEnum.minutes,
    );
    return diff === 0
      ? "now"
      : durationString
      ? `${durationString} ago`
      : "less than a minute ago";
  };

  // If we only have one date
  if (!incidentDate || !escalationDate) {
    const date = incidentDate || escalationDate;
    const type = incidentDate ? "incident" : "escalation";
    return (
      <div className={className}>
        Created an <span className="text-content-primary">{type}</span>{" "}
        <LocalDateTime className={className} timestamp={date as Date}>
          {formatRelativeTime(date as Date)}
        </LocalDateTime>
      </div>
    );
  }

  const incidentDuration = formatRelativeTime(incidentDate);
  const escalationDuration = formatRelativeTime(escalationDate);
  const timeDifferenceMinutes = Math.abs(
    (incidentDate.getTime() - escalationDate.getTime()) / (1000 * 60),
  );

  // If both happened at effectively the same time
  if (incidentDuration === escalationDuration || timeDifferenceMinutes < 5) {
    return (
      <div className={className}>
        Created an <span className="text-content-primary">incident</span>
        {" and an "}
        <span className="text-content-primary">escalation</span>{" "}
        <LocalDateTime className={className} timestamp={incidentDate}>
          {incidentDuration}
        </LocalDateTime>
      </div>
    );
  }

  // Show separate times, always with incident first
  return (
    <div className={className}>
      Created an <span className="text-content-primary">incident</span>{" "}
      <LocalDateTime className={className} timestamp={incidentDate}>
        {incidentDuration}
      </LocalDateTime>{" "}
      and an <span className="text-content-primary">escalation</span>{" "}
      <LocalDateTime className={className} timestamp={escalationDate}>
        {escalationDuration}
      </LocalDateTime>
    </div>
  );
};

const AlertRouteOverflowMenu = ({
  alertRoute,
  onDelete,
}: {
  alertRoute: AlertRoute;
  onDelete: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const navigate = useOrgAwareNavigate();

  const canEdit = hasScope(ScopeNameEnum.AlertRouteUpdate);
  const hasDeletionPermissions = hasScope(ScopeNameEnum.AlertRouteDestroy);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const tooltipContent = canEdit
    ? null
    : "You don't have permission to edit this alert route.";

  const { trigger: toggleActivatedState } = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, data: { enabled: boolean }) => {
      await apiClient.alertRoutesUpdateAlertRoute({
        id: alertRoute.id,
        updateAlertRouteRequestBody: {
          ...parseFormData(parseAlertRoute(alertRoute, null), []),
          enabled: data.enabled,
        },
      });
    },
  );

  const { setState: setActivatedState, saving } = useOptimisticAutoSave<{
    enabled: boolean;
  }>({
    initialState: alertRoute,
    saveState: async (data: { enabled: boolean }) => {
      await toggleActivatedState(data);
    },
  });

  const onToggleDeactivated = () => {
    if (saving) {
      return;
    }
    setActivatedState({
      enabled: !alertRoute.enabled,
    });
  };

  return (
    <>
      <DeletionConfirmationModal
        title="Delete alert route"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        fetchDependentResources={[
          {
            resource_type: "AlertRoute",
            id: alertRoute.id,
          },
        ]}
        onDelete={onDelete}
        analyticsTrackingId="alert-routes.delete"
        resourceTitle={alertRoute.name}
        deleteConfirmationContent={
          <>
            Are you sure you want to delete{" "}
            <span className="font-bold">{alertRoute.name}</span>?
          </>
        }
      />
      <DropdownMenu
        menuClassName={"w-[140px]"}
        side={"bottom"}
        align={"end"}
        triggerButton={
          <Button
            theme={ButtonTheme.Naked}
            type="button"
            className="-ml-2"
            analyticsTrackingId="alert-routes.options"
            icon={IconEnum.DotsVerticalNopad}
            title="Alert route options"
            iconProps={{ size: IconSize.Large, className: "-my-2" }}
            onClick={(e) => e.stopPropagation()}
          />
        }
      >
        <DropdownMenuItem
          disabled={!canEdit}
          tooltipContent={tooltipContent}
          onSelect={() => navigate(`/alerts/routes/${alertRoute.id}/edit`)}
          analyticsTrackingId={"alert-routes.edit"}
          icon={IconEnum.Edit}
          label={"Edit"}
        >
          Edit
        </DropdownMenuItem>
        <DropdownMenuItem
          disabled={!hasDeletionPermissions}
          tooltipContent={
            hasDeletionPermissions
              ? undefined
              : "You don't have permission to delete this alert route"
          }
          onSelect={() => setShowDeleteModal(true)}
          analyticsTrackingId={"alert-routes.delete"}
          icon={IconEnum.Delete}
          label="Delete"
          destructive
        >
          Delete
        </DropdownMenuItem>
        <FormDivider className={"my-1"} />
        <DropdownMenuItem
          disabled={!canEdit}
          tooltipContent={tooltipContent}
          onSelect={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onToggleDeactivated();
          }}
          analyticsTrackingId={"alert-routes.edit"}
          label={"Enabled"}
        >
          <div className="flex flex-row justify-between">
            <GatedToggle
              id="deactivate"
              disabled={!canEdit}
              disabledTooltipContent={<>{tooltipContent}</>}
              align="right"
              label="Deactivate"
              on={!alertRoute.enabled}
              onToggle={onToggleDeactivated}
              isLoading={saving}
            />
          </div>
        </DropdownMenuItem>
      </DropdownMenu>
    </>
  );
};

const AlertRouteCreatingIcon = ({
  alertRoute,
  resourceType,
}: {
  alertRoute: AlertRoute;
  resourceType: "escalations" | "incidents" | "pulse";
}) => {
  const icon = {
    incidents: IconEnum.Incident,
    escalations: IconEnum.ArrowThreeUp,
    pulse: IconEnum.SlackGreyscale,
  };
  const condition = {
    incidents: alertRoute.incident_enabled,
    escalations: alertRoute.escalation_bindings.length > 0,
    pulse: !!alertRoute.alert_pulse_config,
  };
  const enabledTooltipText = {
    incidents: "Creating incidents",
    escalations: "Creating escalations",
    pulse: "Sending alerts to Slack",
  };
  const disabledTooltipText = {
    incidents: "Not creating incidents",
    escalations: "Not creating escalations",
    pulse: "Not sending alerts to Slack",
  };
  return (
    <Tooltip
      content={
        <div className="text-center text-white text-xs font-medium">
          {condition[resourceType]
            ? enabledTooltipText[resourceType]
            : disabledTooltipText[resourceType]}
        </div>
      }
      side={"bottom"}
    >
      <div className={"hover:cursor-pointer"}>
        <Icon
          id={icon[resourceType]}
          className={tcx({
            "text-green-600 hover:text-green-700": condition[resourceType],
            "text-slate-200 hover:text-slate-300": !condition[resourceType],
          })}
        />
      </div>
    </Tooltip>
  );
};
