import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import _ from "lodash";
import { UseFormReturn } from "react-hook-form";

import { EscalationPathFormData, PathNode } from "../../common/types";
import { setChildOfParentNode } from "./insertNode";

// replaceNode replaces a node with the provided node.
export const replaceNode = (
  formMethods: UseFormReturn<EscalationPathFormData>,
  nodeId: string,
  makeNewNode: (oldId: string | undefined) => PathNode,
) => {
  const { firstNodeId, nodes } = formMethods.getValues();
  const oldNode = nodes[nodeId];

  const newNodes: Record<string, PathNode> = {};
  let newNode: PathNode | undefined;
  let modifiedParentNodeId: string | undefined;

  // Iterates through all the nodes and adds them to newNodes
  Object.entries(nodes).forEach(([key, value]) => {
    if (value.id === oldNode.id) {
      // Make the new node and look at the old node we want to delete
      // so we know our next node ID.
      switch (oldNode.data.type) {
        case NodeTypes.Level:
          newNode = makeNewNode(oldNode.data.nextNodeId);

          break;
        case NodeTypes.NotifyChannel:
          newNode = makeNewNode(oldNode.data.nextNodeId);

          break;

        case NodeTypes.Repeat:
          newNode = makeNewNode(oldNode.data.nextNodeId);

          break;

        case NodeTypes.IfElse:
          throw new Error(
            "Unreachable: you can't change non-level or channel nodes",
          );
      }

      if (!newNode) {
        return;
      }

      // Make the parent node point to the new node, or if the node being replaced
      // is the first node, then update the firstNodeId
      if (oldNode.id === firstNodeId) {
        // We need to iterate over all repeat nodes in both the old and new nodes and make them
        // repeat from the new first node. We need to do this in both the old and new nodes
        // in case the repeat node has already been added to newNodes.
        _.union(Object.values(nodes), Object.values(newNodes)).forEach(
          (node) => {
            if (node.data.type === NodeTypes.Repeat) {
              // Update the to_node to point to the new node
              if (node.data.repeat.to_node === firstNodeId) {
                node.data.repeat.to_node = newNode?.id || "";
              }
            }
          },
        );

        // Set the new firstNodeId
        formMethods.setValue<"firstNodeId">("firstNodeId", newNode.id);
      } else {
        const parentNode = setChildOfParentNode({
          nodes,
          oldChildId: oldNode.id,
          newChildId: newNode.id,
        });
        newNodes[parentNode.id] = parentNode;
        modifiedParentNodeId = parentNode.id;
      }

      // Add new node to newNodes
      newNodes[newNode.id] = newNode;
    } else {
      // We don't want to add the parent back in if it's already there, because
      // we would have modified it to point to our new node.
      if (key !== modifiedParentNodeId) {
        newNodes[key] = value;
      }
    }
  });

  formMethods.setValue<"nodes">("nodes", newNodes);

  return newNode;
};
