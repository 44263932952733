import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import React from "react";
import { Route, Routes } from "react-router";

import { NudgesCreateDrawer } from "./create/NudgesCreateDrawer";
import { NudgesEditDrawer } from "./edit/NudgesEditDrawer";
import { NudgesListPage } from "./list/NudgesListPage";

export const NudgesRoute = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/settings/nudges");

  return (
    <Routes>
      <Route path="" element={<NudgesListPage />}>
        <Route
          path="create"
          element={
            <NudgesCreateDrawer
              renameToChannelSuggestion={false}
              onClose={onClose}
            />
          }
        />
        <Route
          path=":id/edit"
          element={
            <NudgesEditDrawer
              renameToChannelSuggestion={false}
              onClose={onClose}
            />
          }
        />
      </Route>
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};
