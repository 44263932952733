import { AlertRoute } from "@incident-io/api";
import { Badge, BadgeSize, BadgeTheme } from "@incident-ui";

export const AlertRouteDisabledBadge = ({
  alertRoute,
}: {
  alertRoute: AlertRoute | undefined;
}) => {
  if (!alertRoute) return null;
  if (alertRoute.enabled) return null;

  return (
    <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.ExtraSmall}>
      Inactive
    </Badge>
  );
};
