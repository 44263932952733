import {
  StatusPageAffectedComponentStatusEnum as StatusEnum,
  StatusPageIncident,
  StatusPageIncidentStatusEnum,
} from "@incident-io/api";
import { COMPONENT_STATUS_CONFIG } from "@incident-shared/utils/StatusPages";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { LocalDateTime } from "@incident-ui/LocalDateTime/LocalDateTime";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { ScaleTime, scaleTime } from "d3";
import { differenceInMinutes, isSameDay, isToday } from "date-fns";
import _ from "lodash";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { formatTimestampLocale } from "src/utils/datetime";
import { tcx } from "src/utils/tailwind-classes";
import { useResize } from "src/utils/use-resize";
import { useInterval } from "src/utils/utils";

import { StatusPageComponentStatusIcon } from "../../incidents/view/StatusIcons";
import {
  AnnotationType,
  calculateBufferedDomain,
  calculateDomain,
  Component,
  fillIn,
  flatten,
  Group,
  Impacts,
  ImpactWindow,
} from "./helpers";

export type ComponentImpactTimelineProps = {
  impacts: Impacts<StatusEnum>[];
  incident: StatusPageIncident;
  annotations: AnnotationType[];
  rowHeight?: number;
  marginX?: number;
  className?: string;
  onClickComponent?: (componentId: string) => void;
};

const currentStatus = (component: Component<StatusEnum>) => {
  const openImpact = component.impacts.find(
    (impact) => impact.end_at === undefined,
  );
  return openImpact ? openImpact.status : StatusEnum.Operational;
};

export const ComponentImpactTimeline = ({
  impacts,
  incident,
  annotations = [],
  marginX = 12,
  rowHeight = 10,
  className,
  onClickComponent,
}: ComponentImpactTimelineProps): React.ReactElement => {
  // Keep the size of this component in the state
  const ref = useRef<HTMLDivElement>(null);

  const { width: containerWidth } = useResize(ref);

  // Redraw every 5 minutes, to avoid the scale getting stale
  const [now, setNow] = useState(new Date());
  useInterval(() => setNow(new Date()), 5 * 60 * 1000);
  // Also reset now whenever the inputs to this component change.
  useEffect(() => setNow(new Date()), [impacts, incident, annotations]);

  const isOngoing = incident.status !== StatusPageIncidentStatusEnum.Resolved;
  const [impactStartAt, impactEndAt] = calculateDomain(
    now,
    annotations,
    impacts,
    isOngoing,
  );

  const width = containerWidth - 2 * marginX;
  const height = rowHeight;

  const bufferedDomain = calculateBufferedDomain(impactStartAt, impactEndAt);
  const scaleX = scaleTime().domain(bufferedDomain).range([0, width]);

  const [cursor, setCursor] = useState<{ pos: number; time: Date } | null>(
    null,
  );
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!ref.current) {
        return;
      }

      const rect = ref.current.getBoundingClientRect();
      let cursorPos = e.clientX - rect.left - marginX;
      // if the mouse is inside the parent box
      if (
        0 <= cursorPos &&
        cursorPos <= width &&
        rect.top <= e.clientY &&
        e.clientY <= rect.bottom
      ) {
        let cursorTime = scaleX.invert(cursorPos);

        // If the cursor moves into the future, pin it to 'now'
        if (cursorTime > now) {
          cursorTime = now;
          cursorPos = scaleX(now);
        }
        setCursor({ pos: cursorPos, time: cursorTime });
      } else {
        setCursor(null);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  });

  return (
    <>
      <div
        className={tcx(
          "text-sm rounded-2 border border-stroke bg-white",
          className,
        )}
        ref={ref}
      >
        <Key
          scaleX={scaleX}
          now={now}
          marginX={marginX}
          impactEndAt={impactEndAt}
          impactStartAt={impactStartAt}
          cursor={cursor}
        />

        <div className={"relative grid"}>
          <div
            className="col-start-1 row-start-1 relative"
            style={{ width, marginLeft: marginX, marginRight: marginX }}
          >
            <div
              className="h-full"
              style={{
                top: 0,
              }}
            >
              {annotations.map(({ timestamp, ...rest }, index) => (
                <Annotation key={index} x={scaleX(timestamp)} {...rest} />
              ))}
            </div>
          </div>

          <div className="divide-y divide-slate-200 col-start-1 row-start-1 relative">
            {impacts.map(({ group, component }, index) =>
              component ? (
                <div
                  key={index}
                  className="py-3"
                  style={{ paddingLeft: marginX, paddingRight: marginX }}
                >
                  <ImpactedComponentRow
                    scaleX={scaleX}
                    component={component}
                    width={width}
                    height={height}
                    cursorPos={cursor?.pos}
                    isOngoing={isOngoing}
                    onClickComponent={onClickComponent}
                  />
                </div>
              ) : (
                <GroupRow
                  key={index}
                  scaleX={scaleX}
                  group={group}
                  width={width}
                  height={height}
                  cursorPos={cursor?.pos}
                  marginX={marginX}
                  isOngoing={isOngoing}
                  onClickComponent={onClickComponent}
                />
              ),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const GroupRow = ({
  scaleX,
  group,
  width,
  height,
  cursorPos,
  marginX,
  isOngoing,
  onClickComponent,
}: {
  scaleX: ScaleTime<number, number>;
  group: Group<StatusEnum>;
  width: number;
  height: number;
  cursorPos?: number;
  marginX: number;
  isOngoing: boolean;
  onClickComponent?: (componentId: string) => void;
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(true);
  const hasInvalidContents = group.components.some(
    ({ error }) => error !== undefined,
  );
  const openOrInvalid = isOpen || hasInvalidContents;

  // We need to do some de-duping here, I think
  const impactWindows = fillIn(
    scaleX,
    flatten(
      COMPONENT_STATUS_CONFIG,
      scaleX,
      group.components.flatMap(({ impacts }) => impacts),
    ),
    COMPONENT_STATUS_CONFIG,
  );

  const groupStatuses = group.components.map((component) =>
    currentStatus(component),
  );

  const maxGroupStatus = _.maxBy(
    groupStatuses,
    (status) => COMPONENT_STATUS_CONFIG[status].rank,
  );

  if (!maxGroupStatus) {
    throw new Error("Unreachable: No status found for group");
  }

  return (
    <div className={"pt-3 pb-2"}>
      <div
        className="text-content-primary mb-1 flex items-center"
        style={{ paddingLeft: marginX, paddingRight: marginX }}
      >
        {isOngoing ||
          (!openOrInvalid && (
            <StatusPageComponentStatusIcon
              status={maxGroupStatus}
              size={IconSize.Large}
              className="mr-0.5 -ml-1"
            />
          ))}
        <Button
          onClick={() => setIsOpen(!isOpen)}
          theme={ButtonTheme.Naked}
          analyticsTrackingId={"component-impact-timeline-toggle-group"}
          className="grow group"
          disabled={hasInvalidContents}
        >
          <div className="flex items-center">
            <div className="text-content-primary mr-0.5">{group.name}</div>
            <Icon
              id={openOrInvalid ? IconEnum.Collapse : IconEnum.Expand}
              size={IconSize.Medium}
              className="ml-1 text-content-tertiary group-hover:text-content-primary"
            />
          </div>
        </Button>
      </div>
      {openOrInvalid ? (
        group.components.map((component, index) => (
          <div
            key={index}
            className="py-1.5"
            style={{ paddingLeft: marginX, paddingRight: marginX }}
          >
            <ImpactedComponentRow
              scaleX={scaleX}
              component={component}
              width={width}
              height={height}
              cursorPos={cursorPos}
              isOngoing={isOngoing}
              onClickComponent={onClickComponent}
            />
          </div>
        ))
      ) : (
        <div
          className="pb-1"
          style={{ paddingLeft: marginX, paddingRight: marginX }}
        >
          <SvgRow
            width={width}
            height={height}
            impactWindows={impactWindows}
            cursorPos={cursorPos}
          />
        </div>
      )}
    </div>
  );
};

const SvgRow = ({
  width: containerWidth,
  height,
  cursorPos,
  impactWindows,
  onClick,
}: {
  width: number;
  height: number;
  cursorPos?: number;
  impactWindows: ImpactWindow<StatusEnum>[];
  onClick?: () => void;
}): React.ReactElement => {
  return (
    <>
      {cursorPos !== undefined && (
        <div
          className="absolute border-slate-600 border-l-[0.5px] border-dashed h-full opacity-50 hidden md:block pointer-events-none"
          // Why 11px? Not sure really, but it makes it look right.
          style={{ left: cursorPos + 11, top: 0 }}
        />
      )}
      <div
        style={{ width: containerWidth, height }}
        className="relative"
        onClick={onClick}
      >
        {cursorPos !== undefined && (
          <div
            className="absolute bg-slate-600 z-[75] w-[1px] h-full opacity-50 pointer-events-none"
            // Why -1? Also not sure! It makes it look about right though.
            style={{ left: cursorPos - 1 }}
          />
        )}

        {impactWindows.map(
          ({ status, colour, x, width, start_at, end_at }, idx) => {
            const blob = (
              <button
                style={{
                  left: x,
                  top: 0,
                  width,
                  height,
                  backgroundColor: colour,
                }}
                type="button"
                className={tcx(
                  "absolute rounded-full",
                  onClick ? "cursor-pointer" : "cursor-default",
                )}
              />
            );

            return (
              <Tooltip
                key={idx}
                // Let it grow
                bubbleProps={{ className: "!max-w-100vw" }}
                content={
                  <div className="whitespace-nowrap flex flex-col space-y-2">
                    <div className="font-medium">
                      {!end_at && "Since "}
                      {formatTimestampLocale({
                        timestamp: start_at,
                        dateStyle: "short",
                        timeStyle: "short",
                      })}
                      {end_at && (
                        <>
                          {" "}
                          &rarr;{" "}
                          {formatTimestampLocale({
                            timestamp: end_at,
                            dateStyle: isSameDay(start_at, end_at)
                              ? undefined
                              : "short",
                            timeStyle: "short",
                          })}
                        </>
                      )}
                    </div>
                    <div className="flex">
                      <StatusPageComponentStatusIcon
                        status={status}
                        className="mr-1 -ml-0.5"
                      />{" "}
                      {COMPONENT_STATUS_CONFIG[status].label}
                    </div>
                  </div>
                }
              >
                {blob}
              </Tooltip>
            );
          },
        )}
      </div>
    </>
  );
};

const ImpactedComponentRow = ({
  scaleX,
  component,
  width,
  height,
  cursorPos,
  isOngoing,
  onClickComponent,
}: {
  scaleX: ScaleTime<number, number>;
  component: Component<StatusEnum>;
  width: number;
  height: number;
  cursorPos?: number;
  isOngoing: boolean;
  onClickComponent?: (componentId: string) => void;
}): React.ReactElement => {
  const flattened = flatten(COMPONENT_STATUS_CONFIG, scaleX, component.impacts);

  const impacts = fillIn(scaleX, flattened, COMPONENT_STATUS_CONFIG);

  return (
    <>
      <div className="text-content-primary mb-1 flex items-center">
        {isOngoing && (
          <StatusPageComponentStatusIcon
            status={currentStatus(component)}
            size={IconSize.Large}
            className="mr-0.5 -ml-1"
          />
        )}
        <div className="mr-1">{component.label}</div>
      </div>
      {component.error ? (
        component.error
      ) : (
        <SvgRow
          width={width}
          height={height}
          impactWindows={impacts}
          cursorPos={cursorPos}
          onClick={
            onClickComponent ? () => onClickComponent(component.id) : undefined
          }
        />
      )}
    </>
  );
};

const Annotation = ({
  x,
  bubbleContent,
  onClick,
}: {
  x: number;
  bubbleContent?: React.ReactNode;
  onClick?: () => void;
}): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  // The width is normally 26px, with a standard font size
  const { width } = useResize(ref, { width: 26, height: 26, left: 0 });

  // `x` tells us where the annotation should sit.
  // The left-offset therefore is x, minus half this component's width (because
  // this should be centred on the time it happened), minus 1px because we put a
  // 2px spacing between component impacts, and this means the annotation splits
  // that perfectly if it was inferred from an update.
  const left = x - width / 2 - 1;

  return (
    <div ref={ref} className="absolute h-full" style={{ left }}>
      <div className="h-full border-dashed border-l-[1px] border-stroke mx-auto w-[1px]" />
      <Tooltip
        side={left < 100 ? "right" : left > width - 100 ? "left" : "top"}
        bubbleProps={{ className: "w-96 z-50" }}
        content={bubbleContent}
      >
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Naked}
          title={"Incident updated"}
          icon={IconEnum.SpeechImportant}
          iconProps={{ size: IconSize.Large }}
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
};

type Tick = {
  left: number;
  label: React.ReactNode;
  ts: Date;
};

const Key = ({
  scaleX,
  impactStartAt,
  impactEndAt,
  marginX,
  cursor,
  now,
}: {
  impactStartAt: Date;
  impactEndAt: Date;
  scaleX: ScaleTime<number, number>;
  marginX: number;
  cursor: { pos: number; time: Date } | null;
  now: Date;
}): React.ReactElement => {
  const label = (ts: Date, withDate = false) => {
    return Math.abs(now.getTime() - ts.getTime()) < 1
      ? "Now"
      : formatTimestampLocale({
          timestamp: ts,
          timeStyle: "short",
          dateStyle: withDate ? "short" : undefined,
        });
  };
  const width = scaleX.range()[1];
  const items: Tick[] =
    differenceInMinutes(impactStartAt, impactEndAt) <= 2 && now === impactEndAt
      ? [
          {
            left: scaleX(impactEndAt),
            label: "Now",
            ts: impactEndAt,
          },
        ]
      : [
          {
            left: scaleX(impactStartAt),
            label: label(impactStartAt, !isToday(impactStartAt)),
            ts: impactStartAt,
          },
          {
            left: scaleX(impactEndAt),
            label: label(impactEndAt, !isSameDay(impactStartAt, impactEndAt)),
            ts: impactEndAt,
          },
        ];

  return (
    <div
      className={tcx(`bg-surface-secondary text-content-tertiary rounded-t-lg`)}
      style={{ paddingLeft: marginX, paddingRight: marginX }}
    >
      <div className="h-9 relative" style={{ width }}>
        {items.map((tick, idx) => (
          <Tick
            key={idx}
            parentWidth={width}
            {...tick}
            isFirst={idx === 0}
            isLast={idx === items.length - 1}
            faded={cursor != null}
          />
        ))}
        {cursor != null && (
          <Tick
            left={cursor.pos}
            ts={cursor.time}
            parentWidth={width}
            label={label(cursor.time, !isToday(cursor.time))}
          />
        )}
      </div>
    </div>
  );
};

const Tick = ({
  left,
  ts,
  label,
  parentWidth,
  isFirst = false,
  isLast = false,
  faded = false,
}: Tick & {
  parentWidth: number;
  isFirst?: boolean;
  isLast?: boolean;
  faded?: boolean;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { width } = useResize(ref);
  const style: CSSProperties = {};

  // If we can measure the tick text, position it accurately
  if (ref.current) {
    // Adjust the left-offset by half the width.
    style.left = ref.current && left ? left - width / 2 : left;

    // Now make sure we don't overflow the container:
    style.left = _.clamp(style.left, 0, parentWidth - width);
  } else {
    style.left = isFirst ? 10 : isLast ? undefined : left;
    style.right = isLast ? 10 : undefined;
  }

  return (
    <>
      <div
        className={tcx(
          "absolute whitespace-nowrap pointer-events-none cursor-default pt-2",
          faded ? "text-slate-300" : "text-content-tertiary",
        )}
        style={style}
        ref={ref}
      >
        <LocalDateTime timestamp={ts} format="yyyy-MM-dd HH:mm">
          {label}
        </LocalDateTime>
      </div>
      <div
        className={tcx(
          "absolute w-px h-[7px] transition bottom-0",
          faded ? "bg-surface-tertiary/50" : "bg-surface-tertiary",
        )}
        style={{ left: left - 1 }}
      />
    </>
  );
};
