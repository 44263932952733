/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathNodePayload,
    EscalationPathNodePayloadFromJSON,
    EscalationPathNodePayloadFromJSONTyped,
    EscalationPathNodePayloadToJSON,
} from './EscalationPathNodePayload';
import {
    WeekdayIntervalConfig,
    WeekdayIntervalConfigFromJSON,
    WeekdayIntervalConfigFromJSONTyped,
    WeekdayIntervalConfigToJSON,
} from './WeekdayIntervalConfig';

/**
 * 
 * @export
 * @interface EscalationPathsCreateRequestBody
 */
export interface EscalationPathsCreateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof EscalationPathsCreateRequestBody
     */
    created_from_external_escalation_policy_id?: string;
    /**
     * The name of this escalation path, for the user's reference.
     * @type {string}
     * @memberof EscalationPathsCreateRequestBody
     */
    name: string;
    /**
     * The nodes that form the levels and branches of this escalation path.
     * @type {Array<EscalationPathNodePayload>}
     * @memberof EscalationPathsCreateRequestBody
     */
    path: Array<EscalationPathNodePayload>;
    /**
     * IDs of users that should be promoted to on-call responders before creating this path
     * @type {Array<string>}
     * @memberof EscalationPathsCreateRequestBody
     */
    user_ids_to_promote?: Array<string>;
    /**
     * The working hours for this escalation path.
     * @type {Array<WeekdayIntervalConfig>}
     * @memberof EscalationPathsCreateRequestBody
     */
    working_hours?: Array<WeekdayIntervalConfig>;
}

export function EscalationPathsCreateRequestBodyFromJSON(json: any): EscalationPathsCreateRequestBody {
    return EscalationPathsCreateRequestBodyFromJSONTyped(json, false);
}

export function EscalationPathsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_from_external_escalation_policy_id': !exists(json, 'created_from_external_escalation_policy_id') ? undefined : json['created_from_external_escalation_policy_id'],
        'name': json['name'],
        'path': ((json['path'] as Array<any>).map(EscalationPathNodePayloadFromJSON)),
        'user_ids_to_promote': !exists(json, 'user_ids_to_promote') ? undefined : json['user_ids_to_promote'],
        'working_hours': !exists(json, 'working_hours') ? undefined : ((json['working_hours'] as Array<any>).map(WeekdayIntervalConfigFromJSON)),
    };
}

export function EscalationPathsCreateRequestBodyToJSON(value?: EscalationPathsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_from_external_escalation_policy_id': value.created_from_external_escalation_policy_id,
        'name': value.name,
        'path': ((value.path as Array<any>).map(EscalationPathNodePayloadToJSON)),
        'user_ids_to_promote': value.user_ids_to_promote,
        'working_hours': value.working_hours === undefined ? undefined : ((value.working_hours as Array<any>).map(WeekdayIntervalConfigToJSON)),
    };
}

