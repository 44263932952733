/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotInteraction,
    CopilotInteractionFromJSON,
    CopilotInteractionFromJSONTyped,
    CopilotInteractionToJSON,
} from './CopilotInteraction';
import {
    CopilotThread,
    CopilotThreadFromJSON,
    CopilotThreadFromJSONTyped,
    CopilotThreadToJSON,
} from './CopilotThread';

/**
 * 
 * @export
 * @interface AIStaffShowCopilotThreadResponseBody
 */
export interface AIStaffShowCopilotThreadResponseBody {
    /**
     * 
     * @type {Array<CopilotInteraction>}
     * @memberof AIStaffShowCopilotThreadResponseBody
     */
    interactions: Array<CopilotInteraction>;
    /**
     * 
     * @type {CopilotThread}
     * @memberof AIStaffShowCopilotThreadResponseBody
     */
    thread: CopilotThread;
}

export function AIStaffShowCopilotThreadResponseBodyFromJSON(json: any): AIStaffShowCopilotThreadResponseBody {
    return AIStaffShowCopilotThreadResponseBodyFromJSONTyped(json, false);
}

export function AIStaffShowCopilotThreadResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffShowCopilotThreadResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interactions': ((json['interactions'] as Array<any>).map(CopilotInteractionFromJSON)),
        'thread': CopilotThreadFromJSON(json['thread']),
    };
}

export function AIStaffShowCopilotThreadResponseBodyToJSON(value?: AIStaffShowCopilotThreadResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interactions': ((value.interactions as Array<any>).map(CopilotInteractionToJSON)),
        'thread': CopilotThreadToJSON(value.thread),
    };
}

