import {
  EvaluationBacktest,
  useAiStaffServiceAiStaffShowEvaluationBacktest,
} from "@incident-io/query-api";
import { ContentBox } from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { LabeledValue } from "../common/LabeledValue";

export const BacktestsShowPage = () => {
  const { backtestId } = useParams<{ backtestId: string }>();

  const { data, isLoading } = useAiStaffServiceAiStaffShowEvaluationBacktest({
    id: backtestId || "",
  });

  const backtest = data?.backtest;

  return (
    <WorkbenchSubPageWrapper
      title="View backtest"
      loading={isLoading}
      backHref="/workbench/backtests"
    >
      {backtest && <BacktestsShowPageContents backtest={backtest} />}
    </WorkbenchSubPageWrapper>
  );
};

const BacktestsShowPageContents = ({
  backtest,
}: {
  backtest: EvaluationBacktest;
}) => {
  return (
    <div className="flex flex-col gap-6">
      <ContentBox className="p-4 flex flex-col gap-2">
        <LabeledValue
          label="ID"
          value={<SingleLineCodeBlock code={backtest.id} />}
        />
        <LabeledValue label="Notes" value={backtest.notes} />
      </ContentBox>
    </div>
  );
};
