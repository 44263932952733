import { AlertsGetInsightsResponseBody } from "@incident-io/api";
import { Tile, TrendTheme } from "@incident-shared/insights/TrendTile";
import { IconEnum } from "@incident-ui";
import { format, isToday } from "date-fns";

import { formatValueWithUnit } from "./formatValueWithUnit";
import { TrendChangeBadge } from "./TrendChangeBadge";

export enum HeadlineMetricType {
  NumberIncidents = "number_incidents",
  NumberAlerts = "number_alerts",
  WorkloadTotalIncidents = "workload_total_incidents",
  DeclineRate = "decline_rate",
}

export const HEADLINE_METRIC_CONFIG: {
  [key in HeadlineMetricType]: {
    type: HeadlineMetricType;
    title: string;
    icon: IconEnum;
    getTooltip: (filteredBy?: string, lastSyncAt?: Date) => React.ReactNode;
    getSubtitle: (data: AlertsGetInsightsResponseBody) => string;
  };
} = {
  [HeadlineMetricType.NumberIncidents]: {
    type: HeadlineMetricType.NumberIncidents,
    title: "Total",
    icon: IconEnum.Incident,
    getTooltip: (filteredBy?: string, lastSyncAt?: Date) => (
      <div className={"flex flex-col gap-1"}>
        <span>
          Number of incidents linked to alerts {getFilterString(filteredBy)}
        </span>
        <LastSyncAt lastSyncAt={lastSyncAt} />
      </div>
    ),
    getSubtitle: (data: AlertsGetInsightsResponseBody) => {
      const totalAlerts = data.number_alerts;
      return `${formatValueWithUnit(
        totalAlerts.current_value,
        totalAlerts.unit,
      )}`;
    },
  },
  [HeadlineMetricType.NumberAlerts]: {
    type: HeadlineMetricType.NumberAlerts,
    title: "Volume",
    icon: IconEnum.BarChart,
    getTooltip: (filteredBy?: string, lastSyncAt?) => (
      <div className={"flex flex-col gap-1"}>
        <span>Number of alerts {getFilterString(filteredBy)}</span>
        <LastSyncAt lastSyncAt={lastSyncAt} />
      </div>
    ),
    getSubtitle: (data: AlertsGetInsightsResponseBody) => {
      const totalIncidents = data.number_incidents;
      return `${formatValueWithUnit(
        totalIncidents.current_value,
        totalIncidents.unit,
      )}`;
    },
  },
  [HeadlineMetricType.WorkloadTotalIncidents]: {
    type: HeadlineMetricType.WorkloadTotalIncidents,
    title: "Time spent",
    icon: IconEnum.Clock,
    getTooltip: (filteredBy?: string, lastSyncAt?) => (
      <div className={"flex flex-col gap-1"}>
        <span>
          Amount of time spent on incidents linked to alerts{" "}
          {getFilterString(filteredBy)}
        </span>
        <LastSyncAt lastSyncAt={lastSyncAt} />
      </div>
    ),
    getSubtitle: (data: AlertsGetInsightsResponseBody) => {
      const acceptedWorkload = data.workload_real_incidents;
      const declinedWorkload = data.workload_declined_incidents;
      return `Accepted: ${formatValueWithUnit(
        acceptedWorkload.current_value,
        acceptedWorkload.unit,
      )} · Declined: ${formatValueWithUnit(
        declinedWorkload.current_value,
        declinedWorkload.unit,
      )}`;
    },
  },
  [HeadlineMetricType.DeclineRate]: {
    type: HeadlineMetricType.DeclineRate,
    title: "Decline rate",
    icon: IconEnum.CloseCircle,

    getTooltip: (filteredBy?: string, lastSyncAt?) => (
      <div className={"flex flex-col gap-1"}>
        <span>
          Percentage of incidents linked to alerts
          {getFilterString(filteredBy)}
          that were declined
        </span>
        <LastSyncAt lastSyncAt={lastSyncAt} />
      </div>
    ),
    getSubtitle: (data: AlertsGetInsightsResponseBody) => {
      const declinedIncidents = data.number_incidents_declined;
      const totalIncidents = data.number_incidents;
      if (totalIncidents.current_value === 0) {
        return "No incidents";
      }
      return `Declined ${Math.round(
        declinedIncidents.current_value,
      )} of ${Math.round(totalIncidents.current_value)} incidents`;
    },
  },
};

const getFilterString = (filteredBy?: string) => {
  if (filteredBy === "multiple-filters") {
    return " that match your filters ";
  }
  if (filteredBy) {
    return ` with this ${filteredBy} `;
  }
  return " in the selected time period ";
};

export const HeadlineMetricCard = ({
  type,
  data,
  filteredBy,
  includePercentageChange,
  onClick,
  className,
  containerClassName,
  getTheme,
  renderAccessory,
}: {
  type: HeadlineMetricType;
  data: AlertsGetInsightsResponseBody;
  filteredBy?: string;
  includePercentageChange?: boolean;
  onClick?: () => void;
  className?: string;
  containerClassName?: string;
  getTheme?: (
    type: HeadlineMetricType,
    value: number,
    data: AlertsGetInsightsResponseBody,
  ) => TrendTheme;
  renderAccessory?: (theme: TrendTheme) => React.ReactNode;
}) => {
  const cardConfig = HEADLINE_METRIC_CONFIG[type];
  const metric = data[type];

  const title = cardConfig.title;
  const content = formatValueWithUnit(metric.current_value, metric.unit);
  const subtitle = cardConfig.getSubtitle(data);
  const tooltipContent = cardConfig.getTooltip(filteredBy, data.last_sync_at);

  const theme = getTheme
    ? getTheme(type, metric.current_value, data)
    : metric.sentiment;

  return (
    <Tile
      smaller
      className={className}
      containerClassName={containerClassName}
      onClick={onClick}
      title={title}
      theme={theme as TrendTheme}
      tooltipContent={
        <div className="text-content-invert max-w-[200px]">
          {tooltipContent}
        </div>
      }
      content={
        <div className={"flex flex-col gap-1"}>
          <div className="flex gap-2 items-center">
            <div className="text-base-bold">{content}</div>
            {includePercentageChange ? (
              <TrendChangeBadge trend={metric} />
            ) : null}
            {renderAccessory ? renderAccessory(theme as TrendTheme) : null}
          </div>
          <span className={"text-xs-med text-content-secondary"}>
            {subtitle}
          </span>
        </div>
      }
    />
  );
};

const LastSyncAt = ({ lastSyncAt }: { lastSyncAt?: Date }) => {
  if (!lastSyncAt) {
    return null;
  }
  return (
    <span className={"text-content-tertiary"}>
      Synced{" "}
      {isToday(lastSyncAt)
        ? `at ${format(lastSyncAt, "HH:mm")}`
        : `on ${format(lastSyncAt, "EEE, d MMM HH:mm")}`}
    </span>
  );
};
