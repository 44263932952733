import { Condition, EngineScope, Reference, Resource } from "@incident-io/api";
import { IconEnum } from "@incident-ui";
import {
  PopoverItem,
  PopoverItemGroup,
} from "@incident-ui/Popover/PopoverItem";
import React from "react";

import { EngineRefIsSelectable, ReferenceSelectorPopover } from "..";
import { ConditionOperationSelect } from "./ConditionOperationSelect";
import { FIXED_OPERATIONS } from "./fixed_operations";

export type ConditionMenuEntry = Reference & {
  resource: Resource;
};

export const AddConditionOrGroupPopover = ({
  renderTriggerButton,
  onAddCondition,
  onAddGroup,
  thisGroupConditions,
  resources,
  allowExpressions,
  scope,
  isSelectable,
}: {
  renderTriggerButton: (props: { onClick: () => void }) => React.ReactElement;
  resources: Resource[];
  scope: EngineScope;
  onAddCondition: (condition: Condition) => void;
  onAddGroup: () => void;
  thisGroupConditions: Condition[];
  allowExpressions?: boolean;
  isSelectable?: EngineRefIsSelectable;
}) => {
  const allowAddORGroup = thisGroupConditions.length > 0;

  return (
    <ReferenceSelectorPopover
      scope={scope}
      renderTriggerButton={renderTriggerButton}
      allowExpressions={allowExpressions}
      // Let people close the popover by clicking outside of it. This is so we don't have to show a
      // close button in the popover itself. Note that if you're in the expression modal, we'll stop you
      // from closing the modal by accident anyway!
      stopPreventClose={true}
      renderSuffixNode={
        allowAddORGroup
          ? ({ onClose }) => (
              <PopoverItemGroup>
                <PopoverItem
                  icon={IconEnum.Filter}
                  onClick={() => {
                    onAddGroup();
                    onClose();
                  }}
                >
                  Add ‘OR’ condition group
                </PopoverItem>
              </PopoverItemGroup>
            )
          : undefined
      }
      isSelectable={isSelectable}
      onSelectReference={(entry): boolean => {
        const conditionBuilder = FIXED_OPERATIONS[entry.key];

        if (conditionBuilder) {
          const condition = conditionBuilder(entry);
          if (condition) {
            onAddCondition(condition);
            return true;
          }
        }

        return false;
      }}
      renderOnSelectedForm={({ selectedEntry, onClose }) => {
        const handleSubmit = (newCondition: Condition) => {
          onAddCondition(newCondition);
          onClose();
        };

        return (
          <ConditionOperationSelect
            handleSubmit={handleSubmit}
            resources={resources}
            scope={scope}
            selectedEntry={selectedEntry}
            allowExpressions={allowExpressions}
          />
        );
      }}
    />
  );
};
