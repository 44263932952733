import { usePylon } from "@bolasim/react-use-pylon";
import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  Spinner,
  ToastTheme,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import graphic from "src/components/settings/banners/banner-incident-type.svg";
import { IncidentType, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { Plurality, ProductUpsellNotice } from "../../ProductUpsellNotice";
import { SettingsHeading } from "../../SettingsHeading";
import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { IncidentTypesList } from "./IncidentTypesList";

export const IncidentTypesListPage = (): React.ReactElement => {
  const { hasProduct } = useProductAccess();
  const { identity } = useIdentity();
  const { settings } = useSettings();

  // the feature gate is separate to the org setting of whether the company has incident types enabled or not.
  // the feature gate takes precedence
  const hasIncidentTypesFeatureGate =
    identity?.feature_gates?.incident_types === true;
  const hasIncidentTypesEnabled = settings?.misc.incident_types_enabled;

  const {
    data: { incident_types: incidentTypes },
    error,
    isLoading: loading,
  } = useAPI(
    hasIncidentTypesFeatureGate ? "incidentTypesList" : null,
    undefined,
    { fallbackData: { incident_types: [] } },
  );

  const defaultIncidentType = incidentTypes.find(
    (type) => type.is_default === true,
  );

  if (error) {
    return (
      <GenericErrorMessage description="We couldn't load incident type settings." />
    );
  }

  if (!identity || !settings) {
    return <FullPageLoader />;
  }

  return (
    <SettingsSubPageWrapper
      accessory={
        <GatedButton
          href="/settings/incident-types/create"
          requiredScope={ScopeNameEnum.IncidentTypesEdit}
          requiredProduct={Product.Response}
          analyticsTrackingId="create-incident-type"
          upgradeRequired={!hasIncidentTypesFeatureGate}
          upgradeRequiredProps={{
            gate: { type: "boolean" },
            featureName: "incident types",
          }}
          icon={IconEnum.Add}
          theme={ButtonTheme.Primary}
        >
          Add type
        </GatedButton>
      }
    >
      <SettingsHeading
        title="Customize incident types"
        subtitle="Adapt your incident response with specific fields and roles for different types."
        articleId={"8660174559"}
        planNameMustUpgradeTo="Pro"
        requiresUpgrade={!hasIncidentTypesFeatureGate}
        graphic={<img src={graphic} className="h-40" />}
      />
      {hasProduct(Product.Response) ? (
        hasIncidentTypesEnabled ? (
          <IncidentTypesList loading={loading} types={incidentTypes ?? []} />
        ) : (
          <NotEnabledTeaser
            defaultIncidentType={defaultIncidentType}
            hasIncidentTypesFeatureGate={hasIncidentTypesFeatureGate}
          />
        )
      ) : (
        <ProductUpsellNotice
          featureName="Incident Types"
          plurality={Plurality.Plural}
          requiredProduct={Product.Response}
        />
      )}
    </SettingsSubPageWrapper>
  );
};

const NotEnabledTeaser = ({
  hasIncidentTypesFeatureGate,
  defaultIncidentType,
}: {
  hasIncidentTypesFeatureGate: boolean;
  defaultIncidentType: IncidentType | undefined;
}) => {
  const { showKnowledgeBaseArticle } = usePylon();
  const showToast = useToast();
  const navigate = useOrgAwareNavigate();

  const { trigger: enable, isMutating: enabling } = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient) => {
      await apiClient.settingsEnableIncidentTypes();
    },
    {
      onSuccess: () => {
        showToast({
          title: "Enabled Successfully",
          theme: ToastTheme.Success,
        });

        if (defaultIncidentType) {
          navigate(`/settings/incident-types/${defaultIncidentType.id}/edit`);
        }
      },
    },
  );

  return (
    <div className="p-6 bg-surface-secondary rounded-2">
      <Heading className="mb-4" level={3} size="medium">
        Using incident types you can:{" "}
      </Heading>
      <ul className="space-y-2 mb-4">
        <li className="flex-center-y">
          <Icon id={IconEnum.Cog} className="mr-2 text-brand" /> Define custom
          fields, roles and workflows for varying situations and teams
        </li>
        <li className="flex-center-y">
          <Icon id={IconEnum.Severity} className="mr-2 text-brand" /> Define
          severity levels uniquely for the context they are used in
        </li>
        <li className="flex-center-y">
          <Icon id={IconEnum.Chart} className="mr-2 text-brand" /> View
          analytics breakdowns filtered by type to gain further insights
        </li>
      </ul>
      <div className="mb-4 ">
        <p>
          See more information in our{" "}
          <Button
            theme={ButtonTheme.Link}
            onClick={() => showKnowledgeBaseArticle("8660174559")}
            analyticsTrackingId="incident-types-help"
          >
            Help Centre
          </Button>
        </p>
      </div>
      <GatedButton
        onClick={() => enable({})}
        disabled={enabling}
        analyticsTrackingId="enable-incident-types"
        requiredScope={ScopeNameEnum.IncidentTypesEdit}
        upgradeRequired={!hasIncidentTypesFeatureGate}
        upgradeRequiredProps={{
          gate: { type: "boolean" },
          featureName: "incident types",
        }}
      >
        {enabling ? <Spinner /> : "Enable incident types"}
      </GatedButton>
    </div>
  );
};
