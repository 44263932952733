import type { SVGProps } from "react";
import * as React from "react";
const SvgTranscriptAiGradient = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="url(#transcript-ai-gradient_svg__a)"
      fillRule="evenodd"
      d="M11.246 11.236A2.239 2.239 0 0 0 10 13.25c0 .263.059.512.143.75h-2.88l-3.544 2.835A.75.75 0 0 1 2.5 16.25V4.75A2.752 2.752 0 0 1 5.25 2h9.5a2.752 2.752 0 0 1 2.75 2.75v4.968l-.237-.475c-.373-.746-1.145-1.21-2.013-1.21-.868 0-1.64.463-2.013 1.21l-.664 1.329-1.327.664Zm5.563.455 1.776.888a.749.749 0 0 1 0 1.342l-1.776.888-.888 1.776a.75.75 0 0 1-1.342 0l-.888-1.776-1.776-.888a.749.749 0 0 1 0-1.342l1.776-.888.888-1.776c.254-.508 1.088-.508 1.342 0l.888 1.776Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="transcript-ai-gradient_svg__a"
        x1={2.5}
        x2={21.736}
        y1={17}
        y2={7.596}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#F25533" />
        <stop offset={0.9} stopColor="#F1CD98" />
        <stop offset={1} stopColor="#F8F5EF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgTranscriptAiGradient;
