/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusPageImportResult
 */
export interface StatusPageImportResult {
    /**
     * Description of the failure, if one occurred
     * @type {string}
     * @memberof StatusPageImportResult
     */
    description?: string;
    /**
     * Email address
     * @type {string}
     * @memberof StatusPageImportResult
     */
    email: string;
    /**
     * Whether the email was successfully subscribed
     * @type {boolean}
     * @memberof StatusPageImportResult
     */
    successful: boolean;
}

export function StatusPageImportResultFromJSON(json: any): StatusPageImportResult {
    return StatusPageImportResultFromJSONTyped(json, false);
}

export function StatusPageImportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageImportResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'email': json['email'],
        'successful': json['successful'],
    };
}

export function StatusPageImportResultToJSON(value?: StatusPageImportResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'email': value.email,
        'successful': value.successful,
    };
}

