import { AlertsGetInsightsResponseBody } from "@incident-io/api";
import { LoadingTiles } from "@incident-shared/insights/LoadingTrendsTiles";
import { TrendTheme } from "@incident-shared/insights/TrendTile";
import { Badge, BadgeSize, BadgeTheme } from "@incident-ui";

import {
  HeadlineMetricCard,
  HeadlineMetricType,
} from "../../common/AlertHeadlineMetrics";
import { AlertMatchByOption } from "../AlertDetailsInsights";

const NUM_TILES = 3;

export const AlertDetailsHeadlineMetrics = ({
  data,
  filteredBy,
}: {
  data?: AlertsGetInsightsResponseBody;
  filteredBy?: AlertMatchByOption["value"] | "multiple-filters";
}) => {
  if (!data) {
    return (
      <div className={`gap-4 grid grid-cols-${NUM_TILES}`}>
        <LoadingTiles numTiles={NUM_TILES} overrideTheme={TrendTheme.Grey} />
      </div>
    );
  }

  const getTheme = (
    type: HeadlineMetricType,
    value: number,
    data: AlertsGetInsightsResponseBody,
  ): TrendTheme => {
    if (type === HeadlineMetricType.WorkloadTotalIncidents) {
      const workloadAccepted = data.workload_real_incidents;
      const workloadDeclined = data.workload_declined_incidents;
      if (
        value > 0 &&
        workloadDeclined.current_value >= workloadAccepted.current_value
      ) {
        return TrendTheme.Bad;
      }
      return TrendTheme.Grey;
    }

    if (type === HeadlineMetricType.DeclineRate) {
      if (value > 50) {
        return TrendTheme.Bad;
      }
      return TrendTheme.Grey;
    }

    return TrendTheme.Grey;
  };

  const renderAccessory = (theme: TrendTheme) => {
    if (theme === TrendTheme.Bad) {
      return (
        <Badge theme={BadgeTheme.Error} size={BadgeSize.ExtraSmall}>
          High
        </Badge>
      );
    }

    return null;
  };

  return (
    <div className={`gap-4 grid grid-cols-${NUM_TILES}`}>
      {Object.values([
        HeadlineMetricType.NumberIncidents,
        HeadlineMetricType.WorkloadTotalIncidents,
        HeadlineMetricType.DeclineRate,
      ]).map((metricType) => {
        return (
          <HeadlineMetricCard
            key={metricType}
            type={metricType}
            data={data}
            filteredBy={filteredBy}
            getTheme={getTheme}
            renderAccessory={renderAccessory}
          />
        );
      })}
    </div>
  );
};
