/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsightsFilter,
    InsightsFilterFromJSON,
    InsightsFilterFromJSONTyped,
    InsightsFilterToJSON,
} from './InsightsFilter';

/**
 * 
 * @export
 * @interface AlertsGetInsightsRequestBody
 */
export interface AlertsGetInsightsRequestBody {
    /**
     * ID of a specific alert to show insights for
     * @type {string}
     * @memberof AlertsGetInsightsRequestBody
     */
    alert_id?: string;
    /**
     * The end date of the period we're interested in
     * @type {Date}
     * @memberof AlertsGetInsightsRequestBody
     */
    end_date: Date;
    /**
     * The filters to apply to the insights
     * @type {Array<InsightsFilter>}
     * @memberof AlertsGetInsightsRequestBody
     */
    filters: Array<InsightsFilter>;
    /**
     * The start date of the period we're interested in
     * @type {Date}
     * @memberof AlertsGetInsightsRequestBody
     */
    start_date: Date;
    /**
     * Timezone of the user to fetch data in the correct times
     * @type {string}
     * @memberof AlertsGetInsightsRequestBody
     */
    timezone: string;
}

export function AlertsGetInsightsRequestBodyFromJSON(json: any): AlertsGetInsightsRequestBody {
    return AlertsGetInsightsRequestBodyFromJSONTyped(json, false);
}

export function AlertsGetInsightsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsGetInsightsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_id': !exists(json, 'alert_id') ? undefined : json['alert_id'],
        'end_date': (new Date(json['end_date'])),
        'filters': ((json['filters'] as Array<any>).map(InsightsFilterFromJSON)),
        'start_date': (new Date(json['start_date'])),
        'timezone': json['timezone'],
    };
}

export function AlertsGetInsightsRequestBodyToJSON(value?: AlertsGetInsightsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_id': value.alert_id,
        'end_date': (value.end_date.toISOString()),
        'filters': ((value.filters as Array<any>).map(InsightsFilterToJSON)),
        'start_date': (value.start_date.toISOString()),
        'timezone': value.timezone,
    };
}

