/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestigationPayload
 */
export interface InvestigationPayload {
    /**
     * Cutoff point up until we consider data for this investigation
     * @type {Date}
     * @memberof InvestigationPayload
     */
    cutoff_at?: Date;
    /**
     * ID of the incident this investigation is associated with
     * @type {string}
     * @memberof InvestigationPayload
     */
    incident_id: string;
    /**
     * Whether the investigation is muted
     * @type {boolean}
     * @memberof InvestigationPayload
     */
    muted: boolean;
    /**
     * Which plan did this investigation follow
     * @type {string}
     * @memberof InvestigationPayload
     */
    plan_type: InvestigationPayloadPlanTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum InvestigationPayloadPlanTypeEnum {
    Default = 'default',
    CodeChanges = 'code-changes'
}

export function InvestigationPayloadFromJSON(json: any): InvestigationPayload {
    return InvestigationPayloadFromJSONTyped(json, false);
}

export function InvestigationPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cutoff_at': !exists(json, 'cutoff_at') ? undefined : (new Date(json['cutoff_at'])),
        'incident_id': json['incident_id'],
        'muted': json['muted'],
        'plan_type': json['plan_type'],
    };
}

export function InvestigationPayloadToJSON(value?: InvestigationPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cutoff_at': value.cutoff_at === undefined ? undefined : (value.cutoff_at.toISOString()),
        'incident_id': value.incident_id,
        'muted': value.muted,
        'plan_type': value.plan_type,
    };
}

