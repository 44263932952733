import { PylonProvider } from "@bolasim/react-use-pylon";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import * as Sentry from "@sentry/react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router";
import { Navigate, useNavigate } from "react-router";
import { CheckForImpersonateSession } from "src/components/impersonation/CheckForImpersonationSession";
import { useStoredSession } from "src/components/impersonation/useStoredSession";
import { WatchForImpersonationExpiring } from "src/components/impersonation/WatchForImpersonationExpiring";
import {
  ErrorResponse,
  FeatureGates,
  Identity,
  IdentityOrganisationAvailableProductsEnum,
  IdentitySelfResponseBody,
  ImpersonationSession,
  ScopeNameEnum,
  Session,
  TrialStatusPlanNameEnum,
  useClient,
} from "src/contexts/ClientContext";
import { isDevelopment } from "src/utils/environment";
import { emptyIdentity } from "src/utils/identity";
import { sessionCanImpersonate } from "src/utils/sessions";
import { useAPI } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";
import { KeyedMutator } from "swr";

const hasScope =
  (identity: Identity | null) =>
  (scope: ScopeNameEnum): boolean => {
    return identity?.scopes?.map((x) => x.name).includes(scope) ? true : false;
  };

const checkIsImpersonating = (identity: Identity | null): boolean => {
  const orgID = identity?.organisation_id;
  const actorOrgID = identity?.actor_organisation_id;
  let isImpersonating = false;
  // if orgID and actorOrgID are set, and are not the same, we must be impersonating.
  if (orgID && actorOrgID && orgID !== actorOrgID) {
    isImpersonating = true;
  }
  return isImpersonating;
};

// Helpful guides for getting types sorted for this:
// - https://www.carlrippon.com/react-context-with-typescript-p1/
// - https://www.carlrippon.com/react-context-with-typescript-p2/

export type IdentityOverrides = {
  gates?: Partial<FeatureGates>;
  products?: IdentityOrganisationAvailableProductsEnum[];
  removeScopes?: ScopeNameEnum[];
  planName?: TrialStatusPlanNameEnum;
};

export type IdentityContextType = {
  identity: Identity;
  identityOverrides: IdentityOverrides;
  setIdentityOverrides: (overrides: IdentityOverrides) => void;
  hasScope: (scopeName: ScopeNameEnum) => boolean;
  hasDismissedCTA: (cta: string) => boolean;
  fetchIdentity: KeyedMutator<IdentitySelfResponseBody>;
  isImpersonating: boolean;
  switchToSession: (session: Session) => void;
  outsideOfProvider?: "outside-of-IdentityProvider";
  sessions: Session[];
};

export const IdentityContext = createContext<IdentityContextType>({
  identity: emptyIdentity,
  hasScope: () => false,
  hasDismissedCTA: () => false,
  fetchIdentity: async () => {
    return undefined;
  },
  setIdentityOverrides: () => null,
  isImpersonating: false,
  switchToSession: () => null,
  outsideOfProvider: "outside-of-IdentityProvider",
  identityOverrides: {},
  sessions: [],
});

// Provider component that wraps the app and makes the identity object
// available to any child component that calls useIdentity().
export const IdentityProvider = ({
  slug: slugFromPath,
  children,
}: {
  slug: string;
  children: React.ReactNode;
}) => {
  const { identity, fetchIdentity, authError } = useSelf();
  const { sessions, sessionsLoading } = useSessions();
  const [identityOverrides, setIdentityOverrides] = useState<IdentityOverrides>(
    {},
  );

  const navigate = useNavigate();
  const { scenario, startImpersonating, switchToSession } = useSwitcherScenario(
    {
      identity,
      fetchIdentity,
      sessions,
      sessionsLoading,
      slugFromPath,
    },
  );

  useIdentityInLaunchDarkly(identity);
  useIdentityInSentry(identity);

  // If we've had an auth error loading the identity, boot you out to login
  if (authError) {
    return <RedirectToLogin />;
  }

  if (
    // This will always be Scenario.Loading, but TypeScript doesn't know that.
    !identity ||
    scenario === Scenario.Loading ||
    scenario === Scenario.SwitchingOrg ||
    scenario === Scenario.ExistingSession
  ) {
    return <FullPageLoader />;
  }

  const hasDismissedCTA = (cta: string) =>
    identity?.org_dismissed_ctas.includes(cta) ||
    identity?.user_dismissed_ctas.includes(cta);

  const contextValue = {
    identity: applyOverrides(identity, identityOverrides),
    identityOverrides,
    setIdentityOverrides,
    hasScope: hasScope(identity),
    hasDismissedCTA,
    fetchIdentity,
    isImpersonating: checkIsImpersonating(identity),
    switchToSession,
    sessions,
  };

  if (scenario === Scenario.CorrectSlug) {
    return (
      <PylonProvider
        autoBoot={true}
        chatSettings={{
          appId: PYLON_APP_ID,
          name: identity.user_name,
          email: identity.user_email,
          avatarUrl: identity.user_avatar_url,
          emailHash: identity.pylon_user_email_hash,
        }}
      >
        <IdentityContext.Provider value={contextValue}>
          {contextValue.isImpersonating ? (
            <WatchForImpersonationExpiring />
          ) : null}
          {children}
        </IdentityContext.Provider>
      </PylonProvider>
    );
  }

  if (scenario === Scenario.WantsToImpersonate) {
    return (
      <IdentityContext.Provider value={contextValue}>
        <CheckForImpersonateSession
          orgSlug={slugFromPath}
          startImpersonating={startImpersonating}
          onClose={() => navigate(`/${identity.organisation_slug}`)}
        />
      </IdentityContext.Provider>
    );
  }

  if (scenario === Scenario.NeedsToLogin) {
    return (
      <Navigate
        to={`/${slugFromPath}/login/additional-organisation`}
        state={{ returnPath: location.pathname + location.search }}
      />
    );
  }

  if (scenario === Scenario.InvalidSlug) {
    return (
      <Navigate
        to={
          location.pathname.replace(
            `/${slugFromPath}/`,
            `/${identity.organisation_slug}/`,
          ) + location.search
        }
      />
    );
  }

  assertUnreachable(scenario);
  return <></>;
};

const PYLON_APP_ID = "eb922e86-a5d3-448f-b07b-efc1afe5ac31";

const useSessions = () => {
  const {
    data: { sessions },
    isLoading: sessionsLoading,
  } = useAPI("identitySessionsList", undefined, {
    fallbackData: { sessions: [] },
  });

  return { sessions, sessionsLoading };
};

const useSelf = () => {
  const { setIsAuthenticated, orgHeaders } = useClient();
  const [authError, setAuthError] = useState<
    "logout" | "stop-impersonating" | null
  >(null);
  const navigate = useNavigate();

  // Try to grab the identity from the backend.
  const {
    data,
    mutate: fetchIdentity,
    error,
  } = useAPI("identitySelf", undefined, {
    onSuccess: (resp) => {
      setIsAuthenticated(true);
      setAuthError(null);
      orgHeaders.current.orgId = resp.identity.actor_organisation_id;
    },
    // Within this hook it's very useful to know the previous valid identity if
    // we get a 401. This lets us figure out where we can go back to safely if
    // we've been booted out of impersonation.
    keepPreviousData: true,
  });

  const { setSession } = useStoredSession({
    orgSlug: data?.identity.organisation_slug ?? "",
  });

  // When we get a 401, figure out where to go.
  useEffect(() => {
    if (!isAuthError(error)) {
      return;
    }

    if (authError != null) {
      // We've already decided what to do with this auth error.
      // Because orgHeaders is a ref, we need to be very careful not to run this
      // logic twice, since the second run will see a different value inside
      // orgHeaders.
      //
      // This does mean that if someone is _genuinely_ logged out while
      // impersonating, they'll get stuck on a loading screen forever. However,
      // this only affects employees of incident.io and can be fixed with a
      // refresh.
      return;
    }

    if (orgHeaders.current.impersonationSessionId && data) {
      // Right: we are currently trying to impersonate, and we have the
      // previously-valid identity. Let's use this to stop impersonating and go
      // back to the actor org.

      // First tag the error as something that we can deal with: this means the
      // hook returns `authError: false`, and we don't redirect to login.
      setAuthError("stop-impersonating");

      // Reset the client headers to the actor org.
      orgHeaders.current = {
        orgId: data.identity.actor_organisation_id,
        impersonationSessionId: null,
        impersonatingOrgSlug: null,
      };

      // Clear any invalid session from our localstorage
      setSession(undefined);

      // Navigate back to the actor org and refetch the identity.
      navigate(`/${data.identity.actor_organisation_slug}/`);
      fetchIdentity();
    } else {
      // This tells the parent to render RedirectToLogin.
      setAuthError("logout");
    }
  }, [authError, error, fetchIdentity, orgHeaders, navigate, data, setSession]);

  // If there's an error, we ask SWR to give us the previous response which is
  // super useful but shouldn't be used in the rest of the app.
  const identity = data && !error ? data.identity : null;

  return {
    identity,
    fetchIdentity,
    // If authError is `stop-impersonating`, we'll deal with that in the effect
    // within this hook.
    authError: authError === "logout",
  };
};

const applyOverrides = (
  identity: Identity,
  overrides: IdentityOverrides,
): Identity => {
  const { gates, products, removeScopes, planName } = overrides;

  // Do nothing if we're not in dev!
  if (!isDevelopment()) {
    return identity;
  }

  if (gates) {
    identity.feature_gates = { ...identity.feature_gates, ...gates };
  }

  if (products) {
    identity.organisation_available_products = products;
  }

  if (removeScopes) {
    identity.scopes = identity.scopes.filter(
      (scope) => !removeScopes.includes(scope.name),
    );
  }

  if (planName) {
    identity.trial_status.plan_name = planName;
  }

  return identity;
};

const useSwitcherScenario = ({
  identity,
  fetchIdentity,
  sessions,
  sessionsLoading,
  slugFromPath,
}: {
  identity: Identity | null;
  fetchIdentity: KeyedMutator<IdentitySelfResponseBody>;
  sessions: Session[];
  sessionsLoading: boolean;
  slugFromPath: string;
}) => {
  const navigate = useNavigate();
  const { orgHeaders } = useClient();
  const [switchingToOrganisationSlug, setSwitchingToOrganisationSlug] =
    useState<string | null>(null);

  // Detect when an org switch completes:
  // 1. The slug in the URL switches; and
  // 2. We have an identity loaded for the new org.
  if (switchingToOrganisationSlug != null) {
    if (
      switchingToOrganisationSlug === slugFromPath &&
      switchingToOrganisationSlug === identity?.organisation_slug
    ) {
      setSwitchingToOrganisationSlug(null);
    }
  }

  // To switch to an organisation, we tell the client to send that header, and
  // make a note of where we're trying to go.
  const switchToSession = useCallback(
    ({ organisation_id: id, organisation_slug: slug }: Session) => {
      setSwitchingToOrganisationSlug(slug);
      orgHeaders.current = {
        orgId: id,
        // no more impersonating
        impersonatingOrgSlug: null,
        impersonationSessionId: null,
      };
    },
    [setSwitchingToOrganisationSlug, orgHeaders],
  );

  // To start impersonating, it's the same but we tell the client about a
  // different header to set.
  const startImpersonating = useCallback(
    (session: ImpersonationSession) => {
      const targetSlug = session.target_organisation_slug;
      setSwitchingToOrganisationSlug(targetSlug);
      orgHeaders.current = {
        ...orgHeaders.current,
        impersonatingOrgSlug: targetSlug,
        impersonationSessionId: session.id,
      };

      if (slugFromPath !== targetSlug) {
        navigate(`/${targetSlug}/`);
      } else {
        fetchIdentity();
      }
    },
    [
      setSwitchingToOrganisationSlug,
      orgHeaders,
      navigate,
      fetchIdentity,
      slugFromPath,
    ],
  );

  const [scenario, existingSession] = scenarioFor({
    identity,
    sessionsLoading,
    slugFromPath,
    sessions,
    isSwitchingOrg: switchingToOrganisationSlug != null,
  });

  // If we can action the current scenario for the user, do that!
  useEffect(() => {
    switch (scenario) {
      case Scenario.ExistingSession:
        if (existingSession) {
          switchToSession(existingSession);
        }
        break;

      case Scenario.WantsToImpersonate:
        if (existingSession) {
          // Ensure we're asking for things as the impersonation-capable session
          orgHeaders.current = {
            ...orgHeaders.current,
            orgId: existingSession.organisation_id,
          };
        }
    }
  });

  return { scenario, startImpersonating, switchToSession };
};

enum Scenario {
  SwitchingOrg = "switching_org",
  Loading = "loading",
  CorrectSlug = "correct_slug",
  InvalidSlug = "invalid_slug",
  ExistingSession = "existing_session",
  WantsToImpersonate = "wants_to_impersonate",
  NeedsToLogin = "needs_to_login",
}
const scenarioFor = ({
  identity,
  sessionsLoading,
  sessions,
  slugFromPath,
  isSwitchingOrg,
}: {
  identity: Identity | null;
  sessionsLoading: boolean;
  sessions: Session[];
  slugFromPath: string;
  isSwitchingOrg: boolean;
}): [Scenario, Session | undefined] => {
  const existingSession = sessions.find(
    (session) => session.organisation_slug === slugFromPath,
  );
  const impersonatingSession = sessions.find(sessionCanImpersonate);

  let scenario: Scenario;

  if (isSwitchingOrg) {
    scenario = Scenario.SwitchingOrg;
  } else if (!identity || sessionsLoading) {
    scenario = Scenario.Loading;
  } else if (identity.organisation_slug === slugFromPath) {
    scenario = Scenario.CorrectSlug;
  } else if (slugFromPath === "~" || slugFromPath === "undefined") {
    scenario = Scenario.InvalidSlug;
  } else if (existingSession) {
    scenario = Scenario.ExistingSession;
  } else if (impersonatingSession) {
    return [Scenario.WantsToImpersonate, impersonatingSession];
  } else {
    scenario = Scenario.NeedsToLogin;
  }

  return [scenario, existingSession];
};

const isAuthError = (err: ErrorResponse | undefined): boolean =>
  err !== undefined && [401, 404].includes(err.status);

export const useIdentity = (): IdentityContextType => {
  return useContext(IdentityContext);
};

const useIdentityInLaunchDarkly = (identity: Identity | null) => {
  const flagClient = useLDClient();

  // Push info about the current user to LaunchDarkly
  useEffect(() => {
    if (identity == null) return;

    flagClient?.identify({
      kind: "user",
      key: identity.user_id,
      organisation: identity.organisation_id,
    });
  }, [identity, flagClient]);
};

const useIdentityInSentry = (identity: Identity | null) => {
  // Push info about the current user to LaunchDarkly and Sentry
  useEffect(() => {
    if (identity == null) return;

    Sentry.setUser({
      id: identity.user_id,
    });
    Sentry.setTags({
      organisation_id: identity.organisation_id,
      organisation_name: identity.organisation_name,
    });
  }, [identity]);
};

const RedirectToLogin = (): React.ReactElement => {
  const { pathname } = useLocation();

  let loginPath = "/login";
  if (
    pathname.startsWith("/setup-msteams") ||
    pathname.startsWith("/setup/msteams")
  ) {
    loginPath = "/setup-msteams/login";
  }
  if (pathname.startsWith("/setup")) {
    loginPath = "/setup/login";
  }
  return (
    <OrgAwareNavigate
      to={loginPath}
      state={{ returnPath: location.pathname + location.search }}
      replace
    />
  );
};
