import { RefObject } from "react";
import { getNodesBounds, Node, ReactFlowProps } from "reactflow";
import { useResize } from "src/utils/use-resize";

import { ReactFlowDataType, ReactFlowNodeCustomType } from "../../common/types";
import { NODE_WIDTH, RANK_SEPARATION } from "./getLayoutElements";

export const useViewportProps = (
  ref: RefObject<HTMLDivElement>,
  reactFlowNodes: Node<ReactFlowDataType>[],
): [Partial<ReactFlowProps>, boolean] => {
  const { width: containerWidth, height: containerHeight } = useResize(ref, {
    // Good guesses
    width: window.innerWidth * 0.66,
    height: window.innerHeight * 0.9,
    left: 0,
  });

  // Focus on the start node
  const firstNode = reactFlowNodes.find(
    (node) => node.data.type === ReactFlowNodeCustomType.Start,
  );
  const firstNodePosition = firstNode?.position ?? { x: 0, y: 0 };
  const positionOptions = {
    defaultViewport: {
      x: firstNodePosition.x + NODE_WIDTH / 2 + containerWidth / 2,
      y: firstNodePosition.y + 50,
      zoom: 1,
    },
  };

  // Get the bounds for all nodes
  const bounds = getNodesBounds(reactFlowNodes);

  // First calculate how much wider/taller than the nodes the container is
  const spareWidth = Math.max(containerWidth - bounds.width, 0);
  const spareHeight = Math.max(containerHeight - bounds.height, 0);
  // Assign half of the spare width/height to each side as padding (or a minimum of 150)
  const xPadding = Math.max(150, spareWidth / 2);
  const yPadding = Math.max(150, spareHeight / 2);

  return [
    {
      translateExtent: [
        // top left
        [-xPadding, -yPadding],
        // bottom right
        [
          bounds.width + xPadding,
          bounds.height +
            yPadding -
            // Remove a bit of padding which was added by the terminus nodes!
            RANK_SEPARATION,
        ],
      ],
      ...positionOptions,
    },
    // On our first render, we might not have built & laid out the graph yet, in
    // which case we can't be sure where the root node will be placed. Therefore
    // we tell the caller to not render the graph until we have this layout info
    // and can configure the viewport correctly.
    firstNode !== undefined,
  ];
};
