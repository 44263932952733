/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationDatasetDimension
 */
export interface EvaluationDatasetDimension {
    /**
     * Description of what this dimension measures
     * @type {string}
     * @memberof EvaluationDatasetDimension
     */
    description: string;
    /**
     * Number of entries that have a value for this dimension
     * @type {number}
     * @memberof EvaluationDatasetDimension
     */
    has_value_count: number;
    /**
     * Human readable name of the dimension
     * @type {string}
     * @memberof EvaluationDatasetDimension
     */
    name: string;
    /**
     * Total number of entries in the dataset
     * @type {number}
     * @memberof EvaluationDatasetDimension
     */
    total: number;
    /**
     * Number of entries where the dimension value has been verified
     * @type {number}
     * @memberof EvaluationDatasetDimension
     */
    verified_count: number;
}

export function EvaluationDatasetDimensionFromJSON(json: any): EvaluationDatasetDimension {
    return EvaluationDatasetDimensionFromJSONTyped(json, false);
}

export function EvaluationDatasetDimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationDatasetDimension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'has_value_count': json['has_value_count'],
        'name': json['name'],
        'total': json['total'],
        'verified_count': json['verified_count'],
    };
}

export function EvaluationDatasetDimensionToJSON(value?: EvaluationDatasetDimension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'has_value_count': value.has_value_count,
        'name': value.name,
        'total': value.total,
        'verified_count': value.verified_count,
    };
}

