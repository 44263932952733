import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Txt,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import React, { useState } from "react";
import {
  BillingCreateSequenceCheckoutSessionRequestBody,
  BillingSetting,
  BillingSettingPlanNameEnum,
  Plan,
  PlanNameEnum,
  ScopeNameEnum,
  TrialStatusPlanNameEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { isTrialPlan } from "src/utils/trial";

import {
  isOnCallSeatCount,
  isResponderSeatCount,
} from "../../../hooks/useCanPromoteToOnCall";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { CurrentPlanExplanation } from "./BillingCurrentPlanExplanation";
import { BillingOnCallUpsellCallout } from "./BillingOnCallUpsellCallout";
import { BillingPlanCards, GetMoreFromIncident } from "./BillingPlanCards";
import { BillingSeatsEditModal } from "./BillingSeatsEditModal";

export const BillingPageContent = (): React.ReactElement => {
  const { identity } = useIdentity();
  const apiClient = useClient();

  const [chosenPlan, setChosenPlan] = useState<PlanNameEnum | null>(
    location.search === "?buy_team" ? PlanNameEnum.TeamV2 : null,
  );

  const { data: billingData, isLoading: isLoadingSettings } = useAPI(
    "billingShowBillingSettings",
    undefined,
  );

  const {
    data: { plans },
    isLoading: isLoadingPlans,
  } = useAPI("billingListPlans", undefined, { fallbackData: { plans: [] } });

  const {
    data: { seat_counts: seatCounts },
    isLoading: isLoadingSeats,
  } = useAPI("billingListSeatCounts", undefined, {
    fallbackData: { seat_counts: [] },
  });

  const onBuyPlan = async (
    data: BillingCreateSequenceCheckoutSessionRequestBody,
  ) => {
    const resp = await apiClient.billingCreateSequenceCheckoutSession({
      createSequenceCheckoutSessionRequestBody:
        data as BillingCreateSequenceCheckoutSessionRequestBody,
    });

    if (!window.Stripe) {
      throw new Error("Stripe client not initialised");
    }
    const stripe = window.Stripe(resp.stripe_publishable_api_key);

    // Call Stripe.js method to redirect to the new Checkout page
    stripe.redirectToCheckout({
      sessionId: resp.stripe_session_id,
    });
  };

  if (
    isLoadingSettings ||
    isLoadingPlans ||
    !billingData ||
    !identity ||
    isLoadingSeats
  ) {
    return <FullPageLoader />;
  }

  const chosenPlanBody = plans.find((plan) => plan.name === chosenPlan) ?? null;

  const billingSetting = billingData.billing_setting;

  const onSelectPlan = (plan: Plan) => {
    // If there's no price IDs, we want them to talk to us
    if (
      !plan.annual_purchase.can_self_serve &&
      !plan.monthly_purchase.can_self_serve
    ) {
      window.open(
        plan.name === PlanNameEnum.ProV2
          ? "https://incident.chilipiper.com/book/demo-round-robin"
          : "https://incident.chilipiper.com/book/round-robin-over-1000",
        "_blank",
      );
      return;
    }

    setChosenPlan(plan.name);
  };

  const currentResponderCount = seatCounts.find(isResponderSeatCount)?.used;
  const currentOnCallCount = seatCounts.find(isOnCallSeatCount)?.used;

  // If they're on a legacy plan, show a special header
  if (billingSetting.plan_name === BillingSettingPlanNameEnum.Legacy) {
    return (
      <SettingsSubPageWrapper
        accessory={<ManageBillingAccessory billingSetting={billingSetting} />}
      >
        <GetMoreFromIncident billingSetting={billingSetting} />
        <BillingOnCallUpsellCallout canRequestWithoutUpgrade={false} />
        <LegacyPlanContent />
      </SettingsSubPageWrapper>
    );
  }

  const planRanking = {
    [PlanNameEnum.Legacy]: 4,
    [PlanNameEnum.EnterpriseV2]: 3,
    [PlanNameEnum.EnterpriseV1]: 3,
    [PlanNameEnum.EnterpriseV2Trial]: 3,
    [PlanNameEnum.ProV2]: 2,
    [PlanNameEnum.ProV2Trial]: 2,
    [PlanNameEnum.ProV1]: 2,
    [PlanNameEnum.TeamV2]: 1,
    [PlanNameEnum.Trial]: 1, // Trial folks shouldn't see Team (or below)
    [PlanNameEnum.StarterV1]: 0.5, // You can go Starter->Team!
    [PlanNameEnum.BasicV2]: 0,
  };
  const visiblePlans = plans.filter(
    (plan) =>
      planRanking[plan.name] > planRanking[billingSetting.plan_name] &&
      !plan.trial,
  );

  const canRequestWithoutUpgrade =
    billingSetting.plan_name === BillingSettingPlanNameEnum.BasicV2 ||
    billingSetting.plan_name === BillingSettingPlanNameEnum.TeamV2;

  return (
    <SettingsSubPageWrapper
      accessory={<ManageBillingAccessory billingSetting={billingSetting} />}
    >
      {canRequestWithoutUpgrade && (
        <BillingOnCallUpsellCallout canRequestWithoutUpgrade={true} />
      )}
      {chosenPlanBody != null ? (
        <BillingSeatsEditModal
          plan={chosenPlanBody}
          onClose={() => setChosenPlan(null)}
          onBuyPlan={onBuyPlan}
          currentResponderCount={currentResponderCount ?? 0}
          currentOnCallCount={currentOnCallCount ?? 0}
        />
      ) : null}
      <CurrentPlanExplanation
        billingSetting={billingSetting}
        seatCounts={seatCounts}
      />
      <GetMoreFromIncident billingSetting={billingSetting} />
      {!canRequestWithoutUpgrade && (
        <BillingOnCallUpsellCallout canRequestWithoutUpgrade={false} />
      )}
      <BillingPlanCards plans={visiblePlans} onSelectPlan={onSelectPlan} />
      <EnterprisePlanContent billingSetting={billingSetting} />
      <DowngradeCallout currentPlan={billingSetting} />
    </SettingsSubPageWrapper>
  );
};

const DowngradeCallout = ({
  currentPlan,
}: {
  currentPlan: BillingSetting;
}): React.ReactElement | null => {
  if (
    isTrialPlan(currentPlan.plan_name as unknown as TrialStatusPlanNameEnum) ||
    currentPlan.plan_name === BillingSettingPlanNameEnum.BasicV2 ||
    currentPlan.plan_name === BillingSettingPlanNameEnum.StarterV1
  ) {
    return null;
  }
  return (
    <>
      <div className="w-full flex text-center text-content-tertiary justify-center py-10 text-xs">
        <Txt>
          If you&apos;d like to downgrade your plan,{" "}
          <Button
            analyticsTrackingId="downgrade-request"
            href="mailto:support@incident.io"
            theme={ButtonTheme.Unstyled}
          >
            <span className="underline hover:text-content-primary">
              get in touch
            </span>
          </Button>
          .
        </Txt>
      </div>
    </>
  );
};

const EnterprisePlanContent = ({
  billingSetting,
}: {
  billingSetting: BillingSetting;
}): React.ReactElement | null => {
  if (
    billingSetting.plan_name !== BillingSettingPlanNameEnum.EnterpriseV1 &&
    billingSetting.plan_name !== BillingSettingPlanNameEnum.EnterpriseV2
  ) {
    // Only show this to enterprise customers
    return null;
  }

  return (
    <div className="w-full lg:mx-0 border border-stroke rounded-2 px-6 py-4">
      <Heading level={3} className="font-medium mb-2">
        Questions about your plan?
      </Heading>
      <Txt>
        If you have any questions about billing, please get in touch with your
        Customer Success Manager.
      </Txt>
    </div>
  );
};

const LegacyPlanContent = (): React.ReactElement => {
  return (
    <>
      <div className="w-full mt-5 mx-auto lg:mx-0 border border-1 border-stroke rounded-2 px-6 py-4  flex items-center gap-6">
        <div className="grow">
          <Heading level={3} className="font-medium mb-2">
            You&apos;re on early-adopter pricing
          </Heading>
          <Txt>
            If you&apos;d like to switch over to our new plans, please get in
            touch.
          </Txt>
        </div>
        <div className="shrink-0">
          <Button
            analyticsTrackingId="legacy-upgrade"
            href="mailto:support@incident.io"
          >
            Talk to us
          </Button>
        </div>
      </div>
    </>
  );
};

const ManageBillingAccessory = ({
  billingSetting,
}: {
  billingSetting: BillingSetting;
}) => {
  const { identity } = useIdentity();
  const isPaying = billingSetting.sequence_customer_id != null;
  if (isPaying) {
    return (
      <>
        {billingSetting.stripe_customer_id !== undefined && (
          <GatedButton
            analyticsTrackingId="billing-header-button"
            onClick={() =>
              (window.location.href = `/api/billing/stripe_customer_portal?organisation_id=${identity?.organisation_id}&for_sequence=false`)
            }
            requiredScope={ScopeNameEnum.BillingSettingsEdit}
          >
            View invoices (pre Nov 2024)
            <Icon size={IconSize.Small} id={IconEnum.ExternalLink} />
          </GatedButton>
        )}
        <GatedButton
          analyticsTrackingId="billing-header-button"
          onClick={() =>
            (window.location.href = `https://portal${
              process.env.NODE_ENV === "production" ? "" : ".sandbox"
            }.sequencehq.com/customers/${
              billingSetting.sequence_customer_id
            }/home`)
          }
          requiredScope={ScopeNameEnum.BillingSettingsEdit}
        >
          View invoices
          <Icon size={IconSize.Small} id={IconEnum.ExternalLink} />
        </GatedButton>
        <GatedButton
          analyticsTrackingId="billing-header-button"
          onClick={() =>
            (window.location.href = `/api/billing/stripe_customer_portal?organisation_id=${identity?.organisation_id}&for_sequence=true`)
          }
          requiredScope={ScopeNameEnum.BillingSettingsEdit}
        >
          Manage billing settings
          <Icon size={IconSize.Small} id={IconEnum.ExternalLink} />
        </GatedButton>
      </>
    );
  }

  return null;
};
