import { SearchBar as SearchBarUI } from "@incident-ui";
import { IconProps } from "@incident-ui/Icon/Icon";
import { InputType } from "@incident-ui/Input/Input";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { ExtendedFormFieldValue } from "src/components/@shared/filters";
import { FormField } from "src/contexts/ClientContext";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";

import { FULL_TEXT_SEARCH } from "./constants";

export const SearchBar = ({
  id,
  placeholder,
  availableFilterFields,
  appliedFilters,
  onEditFilter,
  onAddFilter,
  onDeleteFilter,
  className,
  iconProps,
  throttleMs = 300,
}: {
  id: string;
  placeholder: string;
  availableFilterFields: FormField[];
  onEditFilter: (f: ExtendedFormFieldValue) => void;
  onDeleteFilter: (id: string) => void;
  onAddFilter: (f: ExtendedFormFieldValue) => void;
  appliedFilters: ExtendedFormFieldValue[];
  className?: string | undefined;
  iconProps?: Partial<IconProps>;
  throttleMs?: number;
}): React.ReactElement | null => {
  // Get search config
  const fullTextSearchConfig = useMemo(
    () => availableFilterFields.find((x) => x.key === FULL_TEXT_SEARCH),
    [availableFilterFields],
  );

  const currentFilterObj = useMemo(
    () => appliedFilters.find((x) => x.field_key === fullTextSearchConfig?.key),
    [appliedFilters, fullTextSearchConfig?.key],
  );

  // State hooks
  const [localValue, setLocalValue] = useState(
    currentFilterObj?.string_value || "",
  );
  const [debouncedValue] = useDebounce(localValue, throttleMs, {
    equalityFn: _.isEqual,
  });

  // Actually apply the filter, using the debounced value
  React.useEffect(() => {
    if (!fullTextSearchConfig) return;

    if (debouncedValue === "") {
      if (currentFilterObj) {
        onDeleteFilter(currentFilterObj.field_id);
      }
      return;
    }

    if (currentFilterObj) {
      if (currentFilterObj.string_value === debouncedValue) {
        return;
      }
      onEditFilter({
        ...currentFilterObj,
        string_value: debouncedValue,
      });
    } else {
      onAddFilter({
        key: uuid(),
        field_key: fullTextSearchConfig.key,
        field_id: fullTextSearchConfig.key,
        operator: fullTextSearchConfig.operators[0].key,
        string_value: debouncedValue,
        filter_id: fullTextSearchConfig.key,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const onChange = (newVal: string) => {
    setLocalValue(newVal);
  };

  if (!fullTextSearchConfig) {
    return null;
  }

  return (
    <SearchBarUI
      id={id}
      placeholder={placeholder}
      type={InputType.Search}
      onChange={onChange}
      value={localValue}
      autoFocus
      className={className}
      iconProps={iconProps}
    />
  );
};
