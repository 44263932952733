import { Condition, EngineScope, Reference, Resource } from "@incident-io/api";
import { AddExpressionsModalWrapper } from "@incident-shared/engine/ReferenceSelectorPopover/AddExpressionsModalWrapper";
import { Loader } from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";
import React, { useCallback, useState } from "react";
import { enrichScope, lookupInScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import { isExpression } from "..";
import { ConditionMenuEntry } from "./AddConditionPopover";
import { ConditionOperationSelect } from "./ConditionOperationSelect";

export const EditConditionPopover = ({
  scopeLoading,
  condition,
  scope,
  resources,
  handleEdit,
  RenderTriggerButton,
  allowExpressions,
}: {
  scopeLoading: boolean;
  condition: Condition;
  scope: EngineScope;
  resources: Resource[];
  handleEdit: (newCondition: Condition) => void;
  RenderTriggerButton: React.ForwardRefExoticComponent<{
    onClick: () => void;
  }>;
  allowExpressions?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showExpressionModal, setShowExpressionModal] =
    useState<boolean>(false);

  const getResource = useCallback(
    (type: string): Resource => {
      const resource = resources.find((resource) => resource.type === type);
      if (!resource) {
        throw new Error(`could not find resource of type "${type}"`);
      }
      return resource;
    },
    [resources],
  );
  if (scopeLoading || !resources.length) {
    return (
      <Popover
        trigger={<RenderTriggerButton onClick={() => setIsOpen(true)} />}
        className={tcx("max-h-[400px] min-h-[200px] w-[350px] !p-0")}
        open={isOpen}
      >
        <Loader />
      </Popover>
    );
  }
  // Hydrate each reference with its relevant resource
  const scopeWithResources = enrichScope<ConditionMenuEntry>(
    scope,
    (x: Reference) => {
      return {
        ...x,
        resource: getResource(x.type),
      };
    },
  );

  const selectedEntry = lookupInScope(
    scopeWithResources,
    condition.subject.reference,
  );
  if (!selectedEntry) {
    throw new Error(
      `Could not find reference ${condition.subject.reference} in scope`,
    );
  }

  const handleSubmit = (newCondition: Condition) => {
    handleEdit(newCondition);
    setIsOpen(false);
  };

  const isRefAnExpression = isExpression(condition.subject.reference);
  const shouldShowExpressionsModal = isRefAnExpression && showExpressionModal;
  return (
    <>
      {shouldShowExpressionsModal && (
        <AddExpressionsModalWrapper
          isRefAnExpression={isRefAnExpression}
          reference={condition.subject.reference}
          scope={scope}
          resources={resources}
          onClose={() => setShowExpressionModal(false)}
          expressionFixedResultType={{
            label: "Conditions expression",
            type: selectedEntry.type,
            typeIsAutocompletable: selectedEntry.resource.autocompletable,
            typeLabel:
              resources.find((r) => r.type === selectedEntry.type)
                ?.type_label || selectedEntry.type,
            array: selectedEntry.array,
          }}
          allowDelete={false}
        />
      )}
      <Popover
        trigger={<RenderTriggerButton onClick={() => setIsOpen(true)} />}
        className={tcx("max-h-[400px] min-h-[100px] w-[350px] !p-0")}
        onInteractOutside={(e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
          e.stopPropagation();
          if (!shouldShowExpressionsModal) setIsOpen(false);
        }}
        open={isOpen}
      >
        <ConditionOperationSelect
          defaultValues={condition}
          handleSubmit={handleSubmit}
          resources={resources}
          scope={scope}
          selectedEntry={selectedEntry}
          allowExpressions={allowExpressions}
          showExpressionModal={() => setShowExpressionModal(true)}
        />
      </Popover>
    </>
  );
};
