import { Loader } from "@incident-ui";
import { useMemo } from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";
import { useAPI } from "src/utils/swr";
import { UMAP } from "umap-js";

export const VectorsListPage = () => {
  const {
    data: { vectors },
  } = useAPI(
    "aIStaffListVectors",
    {
      type: "incident_fact",
    },
    {
      fallbackData: {
        vectors: [],
      },
    },
  );

  const projection = useMemo(() => {
    if (vectors.length === 0) {
      return [];
    }

    const rawVectors = vectors.map((vector) => vector.vector);
    const umap = new UMAP({
      // nNeighbours controls the _size_ of our clusters, you can view
      // it as a proxy for "how many points should I consider related"
      //
      // Small values will create more, smaller clusters
      // Large values will create fewer, larger clusters
      nNeighbors: 15,
    });
    const data = umap.fit(rawVectors);

    const mappedData = vectors.map((vector, index) => ({
      ...vector,
      projection: data[index],
    }));

    return mappedData;
  }, [vectors]);

  if (projection.length === 0) {
    return <Loader />;
  }

  const chartData = projection.map(({ name, projection: [x, y] }, index) => ({
    x,
    y,
    index,
    name,
  }));

  return (
    <WorkbenchSubPageWrapper title="Vectors: UMAP Projection">
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
          <CartesianGrid />
          <XAxis type="number" dataKey="x" name="X" />
          <YAxis type="number" dataKey="y" name="Y" />
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            content={<CustomTooltip />}
          />
          <Scatter name="Data Points" data={chartData} fill="#8884d8" />
        </ScatterChart>
      </ResponsiveContainer>
    </WorkbenchSubPageWrapper>
  );
};

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const { x, y, name } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p>
          <strong>{name}</strong>
        </p>
        <p>X: {x}</p>
        <p>Y: {y}</p>
      </div>
    );
  }

  return null;
};
