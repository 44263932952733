import {
  Schedule,
  ScheduleEntry,
  ScheduleExternalProviderEnum,
  ScopeNameEnum,
  User,
  UsersShowResponseBody,
} from "@incident-io/api";
import { ManagementMetaBadge } from "@incident-shared/management-meta/ManagementMetaBadge";
import {
  OrgAwareLink,
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useSearchContext } from "@incident-ui/SearchBar/SearchBar";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { Searcher } from "fast-fuzzy";
import _ from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import { getEscalationPathLevelNodes } from "src/components/escalation-paths/common/helpers";
import { useAPI } from "src/utils/swr";
import useSWRImmutable from "swr/immutable";

import { useClient } from "../../../../contexts/ClientContext";
import { useIdentity } from "../../../../contexts/IdentityContext";
import { CopyDebugID } from "../../../../utils/ShowDebugIDProvider";
import { tcx } from "../../../../utils/tailwind-classes";
import { useOnCallEntityCount } from "../utils";
import {
  ConfirmDeleteScheduleModal,
  ScheduleEscalationPathBadge,
} from "./SchedulesViewPage";

type ScheduleContext = "ALL_SCHEDULES" | "MY_SCHEDULES";

export const SchedulesTable = () => {
  const { identity } = useIdentity();
  const {
    data: schedulesResp,
    isLoading: schedulesIsLoading,
    error: schedulesError,
  } = useAPI("schedulesList", undefined);

  const {
    data: entityCount,
    isLoading: onCallEntityLoading,
    error: onCallEntityError,
  } = useOnCallEntityCount();

  const nativeSchedules = (schedulesResp?.schedules ?? []).filter(
    (s) => s.external_provider === ScheduleExternalProviderEnum.Native,
  );

  const { value: searchTerm } = useSearchContext();

  // build a structure of {"scheduleId": ["userId1", "userId2"]}
  const currentShiftUserIDs = _.chain(nativeSchedules)
    .map((schedule) => ({
      scheduleId: schedule.id,
      userIds: schedule.current_shifts?.map((shift) => shift.external_user_id),
    }))
    .value();

  const searcher = new Searcher(nativeSchedules, {
    keySelector: (schedule) => [
      schedule.name,
      schedulesResp?.users
        .filter(
          (user) =>
            currentShiftUserIDs
              .find((x) => x.scheduleId === schedule.id)
              ?.userIds?.includes(user.id),
        )
        .map((user) => user.name)
        .join(", ") || "",
    ],
  });

  let filteredSchedules = nativeSchedules;
  if (searchTerm) {
    filteredSchedules = searcher.search(searchTerm);
  }

  if (schedulesIsLoading || onCallEntityLoading) {
    return <Loader />;
  }

  if (schedulesError || onCallEntityError) {
    return <GenericErrorMessage error={schedulesError} />;
  }

  if (entityCount === 0) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  const userIsOnSchedule = (schedule: Schedule) => {
    let userIsOnSchedule = false;
    schedule.config?.rotations.forEach((rotation) => {
      if (rotation.user_ids.includes(identity.user_id || "")) {
        userIsOnSchedule = true;
      }
    });
    return userIsOnSchedule;
  };

  const userOnSchedules = _.filter(filteredSchedules, userIsOnSchedule);

  return (
    <div className={"flex flex-col gap-10"}>
      {userOnSchedules.length > 0 && !searchTerm ? (
        <ScheduleTable
          schedules={userOnSchedules}
          users={schedulesResp?.users || []}
          context={"MY_SCHEDULES"}
        />
      ) : null}
      {filteredSchedules.length > 0 && (
        <ScheduleTable
          schedules={filteredSchedules}
          users={schedulesResp?.users || []}
          context={"ALL_SCHEDULES"}
        />
      )}
      {searchTerm && filteredSchedules.length === 0 && (
        <tr>
          <td colSpan={5}>
            <div className="flex items-center justify-center h-[32px]">
              <span>No results found</span>
            </div>
          </td>
        </tr>
      )}
    </div>
  );
};

const ScheduleTable = ({
  schedules,
  users,
  context,
}: {
  schedules: Schedule[];
  users: User[];
  context: ScheduleContext;
}) => {
  return (
    <div className={"flex flex-col gap-6"}>
      <div className={"text-content-primary font-semibold text-base"}>
        {context === "ALL_SCHEDULES" ? "All schedules" : "My schedules"}
      </div>
      <Table
        gridTemplateColumns={"repeat(4, 1fr) auto"}
        header={
          <>
            <TableHeaderCell className={"first:pl-0"}>Name</TableHeaderCell>
            <TableHeaderCell>On call now</TableHeaderCell>
            <TableHeaderCell>On call next</TableHeaderCell>
            <TableHeaderCell>Escalation paths</TableHeaderCell>
            <TableHeaderCell className={"justify-end"} />
          </>
        }
        data={schedules}
        renderRow={(schedule, idx) => (
          <ScheduleTableRow
            key={schedule.id}
            schedule={schedule}
            users={users || []}
            isLastRow={idx === schedules.length - 1}
          />
        )}
      />
    </div>
  );
};

const UserDisplayPictureGroup = ({ users }: { users: User[] }) => {
  // if there's no users, display nothing
  if (users.length === 0) {
    return null;
  }

  // if there's only one user, display their picture
  if (users.length === 1) {
    return (
      <Avatar
        url={users[0].avatar_url}
        name={users[0].name}
        size={IconSize.Large}
        className="w-8 h-8"
        title={users[0].name}
      />
    );
  }
  // if there are more than one user, display on the top left and bottom right
  return (
    <div className="relative w-8 h-8">
      <Avatar
        url={users[0].avatar_url}
        name={users[0].name}
        size={IconSize.Medium}
        title={users[0].name}
        className="absolute top-0 overflow-hidden z-10"
      />
      {/* make the second one have an inherited border */}
      {users.length === 2 ? (
        <Avatar
          url={users[1].avatar_url}
          name={users[1].name}
          size={IconSize.Medium}
          title={users[1].name}
          className="absolute bottom-0 right-0 overflow-hidden outline outline-2 outline-white group-hover:outline-slate-100 z-20"
        />
      ) : (
        <Tooltip side="top" content={users.map((u) => u.name).join(", ")}>
          <div className="absolute bottom-0 right-0 w-5 h-5 rounded-full overflow-hidden outline outline-2 outline-white group-hover:outline-slate-100 z-20 bg-surface-secondary flex items-center justify-center">
            <Txt>+{users.length - 1}</Txt>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

const RichOnCallPreview = ({
  users,
  shifts,
  timezone,
  context,
}: {
  users: User[];
  shifts: ScheduleEntry[] | undefined;
  timezone: string;
  context: "current" | "next";
}) => {
  const { identity } = useIdentity();

  const shiftsByUser = _.chain(shifts)
    .filter((shift) => shift.external_user_id !== "NOBODY")
    .groupBy("external_user_id")
    .map((shifts, userId) => ({
      user: users.find((u) => u.id === userId),
      shifts,
    }))
    .value();

  const shiftsForAllUsersWithoutNobody = (shifts || []).filter(
    (s) => s.external_user_id !== "NOBODY",
  );

  const nextShiftSwapDate =
    context === "next"
      ? shiftsForAllUsersWithoutNobody?.sort(
          (a, b) => a.start_at.getTime() - b.start_at.getTime(),
        )?.[0]?.start_at
      : shiftsForAllUsersWithoutNobody?.sort(
          (a, b) => a.end_at.getTime() - b.end_at.getTime(),
        )?.[0]?.end_at;

  const userTimezone = DateTime.local().zoneName;

  return (
    <div className="flex items-center gap-2">
      {/* Display pictures */}
      <UserDisplayPictureGroup
        users={
          shiftsByUser.map((user) => user.user).filter((user) => user) as User[]
        }
      />
      <div className="flex flex-wrap w-fit flex-col">
        <div className={"text-sm font-medium text-content-primary"}>
          {shiftsByUser
            .filter(({ user }) => user && user.id !== "NOBODY")
            .map(({ user }) => {
              if (!user) {
                return null;
              }
              if (user.id === identity?.user_id) {
                return `${user.name} (you)`;
              }
              return user.name;
            })
            .reduce((acc, curr) => {
              if (acc === "") {
                return curr;
              }
              return `${acc}, ${curr}`;
            }, "")}
        </div>
        {nextShiftSwapDate &&
        shiftsByUser.filter((user) => user.user).length > 0 ? (
          <div className={"text-xs font-medium text-content-tertiary"}>
            {context === "current" ? "Until" : "On"}{" "}
            {DateTime.fromJSDate(nextShiftSwapDate)
              .setZone(timezone)
              .toLocaleString({
                weekday: "short",
                day: "numeric",
                month: "short",
                hour: "numeric",
                hourCycle: "h12",
                minute: "numeric",
                timeZoneName: userTimezone !== timezone ? "short" : undefined,
              })}
          </div>
        ) : (
          <div className="text-slate-300">{"\u2014"}</div>
        )}
      </div>
    </div>
  );
};

const ScheduleTableRow = ({
  schedule,
  users,
  isLastRow,
}: {
  schedule: Schedule;
  users: User[];
  isLastRow: boolean;
}) => {
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const apiClient = useClient();

  const [canEditSchedules, canDestroySchedules] = [
    hasScope(ScopeNameEnum.SchedulesUpdate),
    hasScope(ScopeNameEnum.SchedulesDestroy),
  ];

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data: escalationPathData, isLoading: escalationPathLoading } = useAPI(
    "escalationPathsList",
    undefined,
  );

  const epTargets = escalationPathData?.escalation_paths?.flatMap((ep) =>
    getEscalationPathLevelNodes(ep.path).flatMap((level) =>
      level.targets
        .filter((t) => t.type === "schedule")
        .map((t) => ({ epId: ep.id, scheduleTargetId: t.id })),
    ),
  );

  const epTargetsForSchedule = epTargets?.filter(
    (t) => t.scheduleTargetId === schedule.id,
  );

  const nextUpUsers = schedule?.next_shifts
    ?.map((shift) => shift.external_user_id)
    .filter((u) => u !== "NOBODY");

  const { data: nextUserOnCallData, isLoading: nextUserOnCallLoading } =
    useSWRImmutable(
      `next-users-${schedule.id}`,
      async (): Promise<UsersShowResponseBody[]> => {
        // TODO swap these user look ups for a single call
        // https://linear.app/incident-io/issue/ONC-3143/add-a-users-fetch-by-id-endpoint
        const userResponses = await Promise.all(
          (nextUpUsers ?? []).map((u) => apiClient.usersShow({ id: u })),
        );

        return userResponses || [];
      },
    );

  const cellClassName = [
    "group-hover:bg-surface-secondary",
    { "border-stroke-primary": isLastRow },
  ];

  return (
    <TableRow isLastRow={false} className={"group"}>
      <TableCell className={tcx("first:pl-0", ...cellClassName)}>
        {/* Name */}
        <OrgAwareLink
          className="w-full h-full flex items-center gap-2"
          analyticsTrackingId={"schedules-list-view"}
          to={`/on-call/schedules/${schedule.id}`}
        >
          <span className="flex flex-col">
            <span className="text-sm font-semibold truncate max-w-[30ch]">
              {schedule.name}
            </span>
            <CopyDebugID id={schedule.id} />
          </span>
          <ManagementMetaBadge
            management={schedule.management_meta}
            resourceName="schedule"
          />
        </OrgAwareLink>
      </TableCell>

      {/* On call now */}
      <TableCell className={tcx(...cellClassName)}>
        <OrgAwareLink
          analyticsTrackingId={"schedules-list-view"}
          to={`/on-call/schedules/${schedule.id}`}
        >
          <div className="flex flex-wrap gap-2 w-fit">
            <RichOnCallPreview
              users={users}
              shifts={schedule.current_shifts}
              timezone={schedule.timezone}
              context={"current"}
            />
          </div>
        </OrgAwareLink>
      </TableCell>

      {/* On call next */}
      <TableCell className={tcx(...cellClassName)}>
        <OrgAwareLink
          analyticsTrackingId={"schedules-list-view"}
          to={`/on-call/schedules/${schedule.id}`}
        >
          {nextUserOnCallLoading ? (
            <span className="text-slate-300">{"\u2014"}</span>
          ) : nextUserOnCallData ? (
            <RichOnCallPreview
              users={nextUserOnCallData.map((u) => u.user as unknown as User)}
              shifts={schedule?.next_shifts}
              timezone={schedule.timezone}
              context={"next"}
            />
          ) : (
            <span className="text-slate-300">{"\u2014"}</span>
          )}
        </OrgAwareLink>
      </TableCell>

      {/* Escalation Paths */}
      <TableCell className={tcx(...cellClassName)}>
        {escalationPathLoading ? <Loader large={false} /> : null}
        {escalationPathData ? (
          <div className="flex flex-wrap gap-2 w-fit">
            <ScheduleEscalationPathBadge
              escalationPathsForSchedule={escalationPathData.escalation_paths.filter(
                (ep) =>
                  epTargetsForSchedule?.map((t) => t.epId).includes(ep.id),
              )}
              scheduleID={schedule.id}
            />
          </div>
        ) : (
          <span className="text-slate-300">{"\u2014"}</span>
        )}
      </TableCell>

      {/* Dropdown */}
      <TableCell className={tcx(...cellClassName)}>
        <DropdownMenu
          menuClassName={"w-[120px] mr-5"}
          triggerButton={
            <Button
              theme={ButtonTheme.Unstyled}
              size={BadgeSize.Medium}
              className={tcx(
                "!p-0 hover:bg-surface-secondary text-content-tertiary",
              )}
              icon={IconEnum.DotsVertical}
              iconProps={{
                size: IconSize.XL,
              }}
              title={"Open menu"}
              analyticsTrackingId={"schedules.open-menu"}
            />
          }
          side={"bottom"}
        >
          <DropdownMenuItem
            analyticsTrackingId={"schedules.edit"}
            onSelect={() => navigate(`/on-call/schedules/${schedule.id}/edit`)}
            label="Edit"
            disabled={!canEditSchedules}
            icon={IconEnum.Edit}
            tooltipContent={
              !canEditSchedules &&
              "You do not have permission to edit schedules."
            }
          />
          <DropdownMenuItem
            analyticsTrackingId={"schedules.duplicate"}
            onSelect={() =>
              navigate(`/on-call/schedules/${schedule.id}/duplicate`)
            }
            label="Duplicate"
            disabled={!canEditSchedules}
            icon={IconEnum.Copy}
            tooltipContent={
              !canEditSchedules &&
              "You do not have permission to edit schedules."
            }
          />
          <DropdownMenuItem
            analyticsTrackingId={"schedules.view"}
            onSelect={() => navigate(`/on-call/schedules/${schedule.id}`)}
            label="View"
            icon={IconEnum.View}
          />
          <DropdownMenuItem
            analyticsTrackingId={"schedules.delete"}
            onSelect={() => setDeleteModalOpen(true)}
            label="Delete"
            disabled={!canDestroySchedules}
            tooltipContent={
              !canDestroySchedules &&
              "You do not have permission to delete schedules."
            }
            icon={IconEnum.Delete}
            destructive
          />
        </DropdownMenu>
      </TableCell>

      {deleteModalOpen && (
        <ConfirmDeleteScheduleModal
          onConfirm={() => navigate("/on-call/schedules")}
          onCancel={() => setDeleteModalOpen(false)}
          schedule={schedule}
        />
      )}
    </TableRow>
  );
};
