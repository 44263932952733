import {
  OutlookCalendarConfig,
  useIntegrationsOutlookCalendarServiceIntegrationsOutlookCalendarGetConfig as useGetConfig,
} from "@incident-io/query-api";
import {
  ConfigureDrawerProps,
  IntegrationListProvider,
} from "@incident-shared/integrations";
import { GenericErrorMessage, OrgAwareLink, Spinner } from "@incident-ui";
import pluralize from "pluralize";
import { SettingsSubHeading } from "src/components/settings/SettingsSubHeading";
import { useDebriefName } from "src/utils/postmortem-name";

import { IntegrationConnectedUser } from "../../common/IntegrationConnectedUser";
import { GenericConfigureDrawerContents } from "../IntegrationDrawer";

export const OutlookCalendarConfigureDrawer = (props: ConfigureDrawerProps) => {
  const { data, isLoading, error } = useGetConfig();

  if (error) {
    return (
      <GenericConfigureDrawerContents {...props}>
        <GenericErrorMessage error={error as Error | undefined} />
      </GenericConfigureDrawerContents>
    );
  }
  return (
    <GenericConfigureDrawerContents {...props} aboutThis={null}>
      {isLoading || !data ? (
        <Spinner />
      ) : (
        <DrawerContents
          provider={props.integration.provider}
          config={data.config}
        />
      )}
    </GenericConfigureDrawerContents>
  );
};

const DrawerContents = ({
  provider,
  config,
}: {
  provider: IntegrationListProvider;
  config: OutlookCalendarConfig;
}) => {
  const { debriefName } = useDebriefName();
  return (
    <div className="space-y-4">
      <IntegrationConnectedUser
        {...config.connected_user}
        provider={provider}
      />
      <SettingsSubHeading
        title={"Automatic event attachment"}
        explanationClassName="mr-4 mt-1 max-w-4xl"
        explanation={
          <span>
            We&apos;ll automatically attach events which invite the connected
            user. As long as the incident identifier (e.g. INC-123) is in the
            title or description, we&apos;ll find the correct incident to link
            it to.
          </span>
        }
      />
      <SettingsSubHeading
        title={`Schedule ${pluralize(debriefName).toLowerCase()}`}
        explanationClassName="mr-4 mt-1 max-w-4xl"
        explanation={
          // get name of debrief for here
          <span>
            You can schedule {pluralize(debriefName).toLowerCase()} for
            incidents from the incident details page. Configure defaults such as
            who to invite, event titles, durations, and more from the{" "}
            {
              <OrgAwareLink
                to={"/settings/debriefs"}
                className="underline font-medium"
              >
                {pluralize(debriefName).toLowerCase()} settings
              </OrgAwareLink>
            }{" "}
            page.
          </span>
        }
      />
    </div>
  );
};
