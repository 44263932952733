/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    HydratedSlackChannel,
    HydratedSlackChannelFromJSON,
    HydratedSlackChannelFromJSONTyped,
    HydratedSlackChannelToJSON,
} from './HydratedSlackChannel';

/**
 * 
 * @export
 * @interface AnnouncementRule
 */
export interface AnnouncementRule {
    /**
     * 
     * @type {Array<HydratedSlackChannel>}
     * @memberof AnnouncementRule
     */
    channels: Array<HydratedSlackChannel>;
    /**
     * Condition groups that apply to this announcement rule
     * @type {Array<ConditionGroup>}
     * @memberof AnnouncementRule
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * When the action was created
     * @type {Date}
     * @memberof AnnouncementRule
     */
    created_at: Date;
    /**
     * Unique identifier for this rule
     * @type {string}
     * @memberof AnnouncementRule
     */
    id: string;
    /**
     * Mode specifies how triage incidents should be announced
     * @type {string}
     * @memberof AnnouncementRule
     */
    mode: AnnouncementRuleModeEnum;
    /**
     * Human readable name for the rule
     * @type {string}
     * @memberof AnnouncementRule
     */
    name: string;
    /**
     * Unique identifier for the org that owns this rule
     * @type {string}
     * @memberof AnnouncementRule
     */
    organisation_id: string;
    /**
     * Should incident updates be shared in the channel
     * @type {boolean}
     * @memberof AnnouncementRule
     */
    share_updates_to_channel: boolean;
    /**
     * When the action was last updated
     * @type {Date}
     * @memberof AnnouncementRule
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum AnnouncementRuleModeEnum {
    LiveAndClosed = 'live_and_closed',
    IncludeTriage = 'include_triage',
    IncludeDeclinedAndMerged = 'include_declined_and_merged',
    IncludeAll = 'include_all'
}

export function AnnouncementRuleFromJSON(json: any): AnnouncementRule {
    return AnnouncementRuleFromJSONTyped(json, false);
}

export function AnnouncementRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': ((json['channels'] as Array<any>).map(HydratedSlackChannelFromJSON)),
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'mode': json['mode'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'share_updates_to_channel': json['share_updates_to_channel'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AnnouncementRuleToJSON(value?: AnnouncementRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': ((value.channels as Array<any>).map(HydratedSlackChannelToJSON)),
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'mode': value.mode,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'share_updates_to_channel': value.share_updates_to_channel,
        'updated_at': (value.updated_at.toISOString()),
    };
}

