/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertAggregatedDatapoint,
    AlertAggregatedDatapointFromJSON,
    AlertAggregatedDatapointFromJSONTyped,
    AlertAggregatedDatapointToJSON,
} from './AlertAggregatedDatapoint';
import {
    Trend,
    TrendFromJSON,
    TrendFromJSONTyped,
    TrendToJSON,
} from './Trend';

/**
 * 
 * @export
 * @interface AlertWorkloadOverview
 */
export interface AlertWorkloadOverview {
    /**
     * Top themes that contribute to workload
     * @type {Array<AlertAggregatedDatapoint>}
     * @memberof AlertWorkloadOverview
     */
    top_themes: Array<AlertAggregatedDatapoint>;
    /**
     * 
     * @type {Trend}
     * @memberof AlertWorkloadOverview
     */
    workload_declined_incidents: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertWorkloadOverview
     */
    workload_real_incidents: Trend;
    /**
     * 
     * @type {Trend}
     * @memberof AlertWorkloadOverview
     */
    workload_total_incidents: Trend;
}

export function AlertWorkloadOverviewFromJSON(json: any): AlertWorkloadOverview {
    return AlertWorkloadOverviewFromJSONTyped(json, false);
}

export function AlertWorkloadOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertWorkloadOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'top_themes': ((json['top_themes'] as Array<any>).map(AlertAggregatedDatapointFromJSON)),
        'workload_declined_incidents': TrendFromJSON(json['workload_declined_incidents']),
        'workload_real_incidents': TrendFromJSON(json['workload_real_incidents']),
        'workload_total_incidents': TrendFromJSON(json['workload_total_incidents']),
    };
}

export function AlertWorkloadOverviewToJSON(value?: AlertWorkloadOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'top_themes': ((value.top_themes as Array<any>).map(AlertAggregatedDatapointToJSON)),
        'workload_declined_incidents': TrendToJSON(value.workload_declined_incidents),
        'workload_real_incidents': TrendToJSON(value.workload_real_incidents),
        'workload_total_incidents': TrendToJSON(value.workload_total_incidents),
    };
}

