import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { BadgeSize, Button, ButtonTheme, IconEnum, Txt } from "@incident-ui";
import { useEffect } from "react";
import { FieldPath, UseFormReturn } from "react-hook-form";

import { assertUnreachable } from "../../../../../utils/utils";
import {
  CustomHandoverRuleType,
  RotaFormData,
  RotaId,
  RotaVersionId,
  ScheduleFormData,
} from "../common/types";

export type CustomHandoverElementProps = {
  formMethods: UseFormReturn<ScheduleFormData>;
  getPath: (
    key: FieldPath<RotaFormData>,
  ) => `rotations.${RotaId}.${RotaVersionId}.${FieldPath<RotaFormData>}`;
  index: number;
  showDeleteButton: boolean;
  removeHandover: () => void;
};

export const CustomHandoverElement = ({
  index,
  getPath,
  formMethods,
  showDeleteButton,
  removeHandover,
}: CustomHandoverElementProps) => {
  // Dynamically set the maximum interval repeat based on the selected interval type. Server also enforces this.
  const customIntervalType = formMethods.watch(
    getPath(`custom_handovers.${index}.handover_interval_type`),
  ) as CustomHandoverRuleType;
  const customIntervalRepeat =
    (formMethods.watch(
      getPath(`custom_handovers.${index}.handover_interval`),
    ) as number) || 1;

  const maxCustomIntervalRepeat = (() => {
    switch (customIntervalType) {
      case CustomHandoverRuleType.Hourly:
        return 12;
      case CustomHandoverRuleType.Daily:
        return 7;
      case CustomHandoverRuleType.Weekly:
        return 4;
      default:
        assertUnreachable(customIntervalType);
        return 1;
    }
  })();

  // Set the interval repeat to the max for the selected interval type to avoid an invalid form state
  useEffect(() => {
    if (customIntervalRepeat > maxCustomIntervalRepeat) {
      formMethods.setValue(
        getPath(`custom_handovers.${index}.handover_interval`),
        `${maxCustomIntervalRepeat}`,
      );
    }
  }, [
    customIntervalRepeat,
    formMethods,
    getPath,
    index,
    maxCustomIntervalRepeat,
  ]);

  return (
    <div key={index} className="flex-center-y space-x-2 flex-row">
      <Txt bold className={"whitespace-nowrap"}>
        {index === 0 ? "Change shift every" : "then every"}
      </Txt>
      <PopoverSingleSelectV2
        formMethods={formMethods}
        required
        triggerStyle="inline-button"
        name={getPath(`custom_handovers.${index}.handover_interval`)}
        options={Array.from({ length: maxCustomIntervalRepeat }).map(
          (_, i) => ({
            label: String(i + 1),
            value: String(i + 1),
          }),
        )}
      />
      <PopoverSingleSelectV2
        formMethods={formMethods}
        required
        triggerStyle="inline-button"
        name={getPath(`custom_handovers.${index}.handover_interval_type`)}
        options={[
          {
            label: "hours",
            value: CustomHandoverRuleType.Hourly,
          },
          {
            label: "days",
            value: CustomHandoverRuleType.Daily,
          },
          {
            label: "weeks",
            value: CustomHandoverRuleType.Weekly,
          },
        ]}
      />
      {showDeleteButton && (
        <Button
          className={"px-2"}
          theme={ButtonTheme.DestroySecondary}
          icon={IconEnum.Delete2}
          size={BadgeSize.Medium}
          analyticsTrackingId={null}
          title="Remove handover"
          onClick={() => removeHandover()}
        />
      )}
    </div>
  );
};
