import { Product } from "@incident-shared/billing";
import { PolicyViolationNotification } from "@incident-shared/policy/PolicyViolationNotification";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  Loader,
  StackedList,
  StackedListItem,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { captureException } from "@sentry/react";
import { differenceInSeconds } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import pluralize from "pluralize";
import {
  DebriefEventAttendee,
  DebriefEventAttendeeResponseEnum,
  DebriefEventProviderEnum,
  DebriefsListIncidentDebriefsResponseBody,
  Incident,
  IncidentDebrief,
  IncidentStatusCategoryEnum,
  IntegrationSettingsProviderEnum,
  IntegrationSettingsReconnectionReasonEnum,
  Policy,
  PolicyViolation,
  PolicyViolationPolicyTypeEnum,
} from "src/contexts/ClientContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import {
  IncidentDrawer,
  IncidentHeaderModal,
} from "src/routes/legacy/IncidentRoute";
import { useDebriefName } from "src/utils/postmortem-name";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  DurationEnum,
  formatDurationInSeconds,
} from "../../../../../utils/datetime";
import { usePoliciesAndViolations } from "../../hooks";
import { AvatarList, AvatarListClickableType, MaybeUser } from "../AvatarList";
import { IconValueButtonItem, Sidebar } from "../Components";
import { LinkBuilder, LinkBuilderProps } from "./builders";

const Debriefs = (props: LinkBuilderProps) => {
  const { debriefName } = useDebriefName();

  return (
    <Sidebar.Entry
      label={debriefName}
      allowLabelShrink
      value={<DebriefsValue {...props} />}
    />
  );
};

const DebriefsValue = ({ debriefs, incident }: LinkBuilderProps) => {
  const navigateToDrawer = useNavigateToModal();
  const { policies, policyViolations } = usePoliciesAndViolations(
    incident.id ?? "",
  );
  const { debriefNameLower } = useDebriefName();
  const { disableDebriefAddToGoogleCalendar } = useFlags();
  const navigateToModal = useNavigateToModal();

  const policyViolation =
    policyViolations &&
    policyViolations.find(
      (violation) =>
        violation.policy_type === PolicyViolationPolicyTypeEnum.Debrief,
    );

  let violatedPolicy: Policy | undefined = undefined;
  if (policyViolation) {
    violatedPolicy = policies?.find(
      (policy) => policy.id === policyViolation.policy_id,
    );
  }

  if (debriefs.length === 0) {
    if (policyViolation && violatedPolicy) {
      return (
        <PolicyViolationNotification
          iconOnly
          policy={violatedPolicy}
          resourceName="debrief"
          level={policyViolation.level}
          daysUntil={policyViolation.days}
          violationID={policyViolation.id}
        />
      );
    }

    if (disableDebriefAddToGoogleCalendar) {
      return null;
    }

    return (
      <Sidebar.EmptyValueButton
        onClick={() => navigateToModal(IncidentHeaderModal.ScheduleDebrief)}
        analyticsTrackingId="incident-sidebar-schedule-debrief"
      >
        Schedule
      </Sidebar.EmptyValueButton>
    );
  }

  // Build the props for each item in the list
  const sidebarItems: IconValueButtonItem[] = [];

  debriefs.forEach((debrief) => {
    sidebarItems.push({
      icon: policyViolation ? IconEnum.Warning : debriefProviderIcon(debrief),
      label: debriefTimeInfo(debrief),
    });
  });

  return (
    <Tooltip content={`View ${pluralize(debriefNameLower)}`}>
      <Sidebar.IconValueButton
        items={sidebarItems}
        analyticsTrackingId="incident-sidebar-view-alerts"
        onClick={() => navigateToDrawer(IncidentDrawer.Debriefs)}
      />
    </Tooltip>
  );
};

const scheduleDebriefItem = ({
  debriefNameLower,
  disableDebriefAddToGoogleCalendar,
  navigateToModal,
}: LinkBuilderProps) => {
  if (disableDebriefAddToGoogleCalendar) {
    return [];
  }

  return [
    {
      icon: IconEnum.CalendarPlus,
      onSelect: () => navigateToModal(IncidentHeaderModal.ScheduleDebrief),
      label: `Schedule ${debriefNameLower}`,
      analyticsTrackingId: "incident-sidebar-schedule-debrief",
    },
  ];
};

export const DebriefsDrawer = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement | null => {
  const {
    data: { incident_debriefs: debriefs },
    isLoading,
  } = useAPI(
    incident ? "debriefsListIncidentDebriefs" : null,
    { incidentId: incident ? incident.id : "" },
    { fallbackData: { incident_debriefs: [] } },
  );
  const { debriefName } = useDebriefName();

  return (
    <Drawer onClose={onClose} width="medium">
      {isLoading ? (
        <DrawerContentsLoading />
      ) : (
        <DrawerContents>
          <DrawerTitle
            icon={IconEnum.Calendar}
            onClose={onClose}
            title={pluralize(debriefName)}
          />
          <DrawerBody className="overflow-y-auto">
            <DebriefsDrawerInner incident={incident} debriefs={debriefs} />
          </DrawerBody>
        </DrawerContents>
      )}
    </Drawer>
  );
};

const DebriefsDrawerInner = ({
  incident,
  debriefs,
}: {
  incident: Incident;
  debriefs: IncidentDebrief[];
}): React.ReactElement => {
  const { policies, policyViolations } = usePoliciesAndViolations(incident.id);

  const { integrations } = useIntegrations();
  const hasCalendarIntegration =
    integrations?.some(
      (integration) =>
        integration.installed &&
        integration.reconnection_reason ===
          IntegrationSettingsReconnectionReasonEnum.Empty &&
        integration.provider === IntegrationSettingsProviderEnum.GoogleCalendar,
    ) ?? false;

  const {
    trigger: destroyIncidentDebrief,
    isMutating: isDestroying,
    genericError: destroyError,
  } = useAPIMutation(
    "debriefsListIncidentDebriefs",
    { incidentId: incident ? incident.id : "" },
    async (
      apiClient,
      {
        id,
        destroyCalendarEvent,
      }: { id: string; destroyCalendarEvent: boolean },
    ) => {
      await apiClient.debriefsDestroyIncidentDebrief({
        id,
        destroyIncidentDebriefRequestBody: {
          destroy_calendar_event: destroyCalendarEvent,
        },
      });
    },
  );

  const sortedDebriefs = _.sortBy(debriefs, function (debrief) {
    return debrief.debrief_event?.start;
  });

  if (isDestroying) {
    return <Loader />;
  }

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  return (
    <StackedList>
      {sortedDebriefs.map((debrief) => (
        <DebriefDetails
          debrief={debrief}
          policyViolations={policyViolations}
          policies={policies}
          key={debrief.id}
          destroyIncidentDebrief={destroyIncidentDebrief}
          hasCalendarIntegration={hasCalendarIntegration}
        />
      ))}
    </StackedList>
  );
};

const debriefTimeInfo = (debrief: IncidentDebrief): string => {
  const now = new Date();

  if (!debrief.debrief_event) {
    return "";
  }

  let info = "Occurring now";
  if (debrief.debrief_event.start > now) {
    const diff = differenceInSeconds(debrief.debrief_event.start, now);
    const durationString = formatDurationInSeconds(
      diff,
      1,
      DurationEnum.minutes,
    );
    info = `In ${durationString}`;
  } else if (debrief.debrief_event.end < now) {
    info = debrief.debrief_event.end.toLocaleDateString();
  }
  return info;
};

const debriefProviderIcon = (debrief) => {
  switch (debrief.debrief_event.provider) {
    case DebriefEventProviderEnum.GoogleCalendar:
      return IconEnum.GoogleCalendar;
    case DebriefEventProviderEnum.OutlookCalendar:
      return IconEnum.Outlook;
    default:
      return IconEnum.Calendar;
  }
};

const DebriefDetails = ({
  debrief,
  destroyIncidentDebrief,
  policyViolations,
  policies,
  hasCalendarIntegration,
}: {
  debrief: IncidentDebrief;
  policyViolations: PolicyViolation[];
  policies: Policy[];
  hasCalendarIntegration: boolean;
  destroyIncidentDebrief: (params: {
    id: string;
    destroyCalendarEvent: boolean;
  }) => Promise<DebriefsListIncidentDebriefsResponseBody>;
}): React.ReactElement | null => {
  const { debriefNameLower } = useDebriefName();

  const policyViolation =
    policyViolations &&
    policyViolations.find((violation) => violation.resource_id === debrief.id);

  let violatedPolicy: Policy | undefined = undefined;
  if (policyViolation) {
    violatedPolicy = policies?.find(
      (policy) => policy.id === policyViolation.policy_id,
    );
  }

  if (!debrief.debrief_event) {
    captureException(new Error("No Google Calendar event found for debrief"));
    return null;
  }

  // We don't want to show the avatar of users that have declined in the sidebar.
  const attendeeUsers = debrief.debrief_event?.attendees
    ?.filter(
      (attendee) => attendee.response !== DebriefEventAttendeeResponseEnum.No,
    )
    .map(convertToMaybeUser);

  return (
    <StackedListItem
      icon={debriefProviderIcon(debrief)}
      iconColor={ColorPaletteEnum.SlateOnWhite}
      title={debrief.debrief_event.summary}
      description={debriefTimeInfo(debrief)}
      badgeNode={
        violatedPolicy != null && policyViolation ? (
          <PolicyViolationNotification
            iconOnly
            policy={violatedPolicy}
            resourceName="debrief"
            level={policyViolation.level}
            daysUntil={policyViolation.days}
            violationID={policyViolation.id}
          />
        ) : undefined
      }
      accessory={
        <div className="flex items-center gap-2">
          {attendeeUsers !== undefined && attendeeUsers.length > 0 && (
            <AvatarList
              users={attendeeUsers}
              modalTitle={"Debrief attendees"}
              maxToShow={5}
              clickableType={AvatarListClickableType.OnlyOnSeeMore}
            />
          )}
          <div className="grow"></div>
          <Button
            theme={ButtonTheme.Tertiary}
            icon={IconEnum.ExternalLink}
            href={debrief.debrief_event.permalink}
            openInNewTab
            title="View in calendar"
            analyticsTrackingId={null}
          />
          <DropdownMenu
            triggerButton={
              <Button
                theme={ButtonTheme.Tertiary}
                type="button"
                analyticsTrackingId="post-mortem-actions"
                icon={IconEnum.DotsVertical}
                title="post-mortem-options"
              />
            }
          >
            <DropdownMenuItem
              onSelect={() =>
                destroyIncidentDebrief({
                  id: debrief.id,
                  destroyCalendarEvent: false,
                })
              }
              analyticsTrackingId={"mark-event-not-as-debrief"}
              label={`Unlink ${debriefNameLower}`}
              icon={IconEnum.LinkBreak}
            >
              Unlink {debriefNameLower}
            </DropdownMenuItem>
            {hasCalendarIntegration &&
              debrief.debrief_event.provider !==
                DebriefEventProviderEnum.OutlookCalendar && ( // Outlook Calendar API does not support deleting events
                <DropdownMenuItem
                  onSelect={() =>
                    destroyIncidentDebrief({
                      id: debrief.id,
                      destroyCalendarEvent: true,
                    })
                  }
                  analyticsTrackingId={"delete-event-and-unlink-debrief"}
                  label={`Delete ${debriefNameLower} from calendar`}
                  icon={IconEnum.Delete2}
                >
                  Delete {debriefNameLower} from calendar
                </DropdownMenuItem>
              )}
          </DropdownMenu>
        </div>
      }
    />
  );
};

function convertToMaybeUser(attendee: DebriefEventAttendee): MaybeUser {
  const maybeUser = {} as MaybeUser;

  if (attendee.user) {
    maybeUser.user = attendee.user;
  } else {
    maybeUser.nonUserLabel = attendee.email;
  }

  maybeUser.attendee = attendee;

  return maybeUser;
}

export const DebriefsBuilder: LinkBuilder = {
  Render: Debriefs,
  // Only show the debriefs section if the incident is resolved, or there's already a debrief attached
  visible: ({ incident, debriefs }) =>
    incident &&
    (incident.incident_status.category === IncidentStatusCategoryEnum.Closed ||
      incident.incident_status.category ===
        IncidentStatusCategoryEnum.PostIncident ||
      debriefs.length > 0),
  requiredProduct: Product.Response,
  getDropdownItems: scheduleDebriefItem,
};
