/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertSourceConfigTransition,
    AlertSourceConfigTransitionFromJSON,
    AlertSourceConfigTransitionFromJSONTyped,
    AlertSourceConfigTransitionToJSON,
} from './AlertSourceConfigTransition';
import {
    AlertSourceRouting,
    AlertSourceRoutingFromJSON,
    AlertSourceRoutingFromJSONTyped,
    AlertSourceRoutingToJSON,
} from './AlertSourceRouting';
import {
    AlertSourceSlim,
    AlertSourceSlimFromJSON,
    AlertSourceSlimFromJSONTyped,
    AlertSourceSlimToJSON,
} from './AlertSourceSlim';
import {
    AlertTemplate,
    AlertTemplateFromJSON,
    AlertTemplateFromJSONTyped,
    AlertTemplateToJSON,
} from './AlertTemplate';

/**
 * 
 * @export
 * @interface AlertSourceConfig
 */
export interface AlertSourceConfig {
    /**
     * When this alert source last received an alert
     * @type {Date}
     * @memberof AlertSourceConfig
     */
    alert_last_fired_at?: Date;
    /**
     * Routes that this alert source is used in
     * @type {Array<string>}
     * @memberof AlertSourceConfig
     */
    alert_route_ids: Array<string>;
    /**
     * 
     * @type {AlertSourceSlim}
     * @memberof AlertSourceConfig
     */
    alert_source: AlertSourceSlim;
    /**
     * Whether to automatically acknowledge incidents in the source system when they are acknowledged in incident.io. This is not supported for all source types.
     * @type {boolean}
     * @memberof AlertSourceConfig
     */
    auto_ack_in_source: boolean;
    /**
     * When this type was created
     * @type {Date}
     * @memberof AlertSourceConfig
     */
    created_at: Date;
    /**
     * The ID of this alert source
     * @type {string}
     * @memberof AlertSourceConfig
     */
    id: string;
    /**
     * Unique name of the alert source
     * @type {string}
     * @memberof AlertSourceConfig
     */
    name: string;
    /**
     * Unique identifier of the organisation
     * @type {string}
     * @memberof AlertSourceConfig
     */
    organisation_id: string;
    /**
     * 
     * @type {AlertSourceRouting}
     * @memberof AlertSourceConfig
     */
    routing: AlertSourceRouting;
    /**
     * Secret token used to authenticate this source, if applicable
     * @type {string}
     * @memberof AlertSourceConfig
     */
    secret_token?: string;
    /**
     * Type of alert source
     * @type {string}
     * @memberof AlertSourceConfig
     */
    source_type: string;
    /**
     * State of the alert source
     * @type {string}
     * @memberof AlertSourceConfig
     */
    state: AlertSourceConfigStateEnum;
    /**
     * 
     * @type {AlertTemplate}
     * @memberof AlertSourceConfig
     */
    template: AlertTemplate;
    /**
     * Transitions for this source config
     * @type {Array<AlertSourceConfigTransition>}
     * @memberof AlertSourceConfig
     */
    transitions?: Array<AlertSourceConfigTransition>;
    /**
     * When this type was last updated
     * @type {Date}
     * @memberof AlertSourceConfig
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum AlertSourceConfigStateEnum {
    PendingSetup = 'pending_setup',
    Setup = 'setup'
}

export function AlertSourceConfigFromJSON(json: any): AlertSourceConfig {
    return AlertSourceConfigFromJSONTyped(json, false);
}

export function AlertSourceConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_last_fired_at': !exists(json, 'alert_last_fired_at') ? undefined : (new Date(json['alert_last_fired_at'])),
        'alert_route_ids': json['alert_route_ids'],
        'alert_source': AlertSourceSlimFromJSON(json['alert_source']),
        'auto_ack_in_source': json['auto_ack_in_source'],
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'name': json['name'],
        'organisation_id': json['organisation_id'],
        'routing': AlertSourceRoutingFromJSON(json['routing']),
        'secret_token': !exists(json, 'secret_token') ? undefined : json['secret_token'],
        'source_type': json['source_type'],
        'state': json['state'],
        'template': AlertTemplateFromJSON(json['template']),
        'transitions': !exists(json, 'transitions') ? undefined : ((json['transitions'] as Array<any>).map(AlertSourceConfigTransitionFromJSON)),
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AlertSourceConfigToJSON(value?: AlertSourceConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_last_fired_at': value.alert_last_fired_at === undefined ? undefined : (value.alert_last_fired_at.toISOString()),
        'alert_route_ids': value.alert_route_ids,
        'alert_source': AlertSourceSlimToJSON(value.alert_source),
        'auto_ack_in_source': value.auto_ack_in_source,
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'name': value.name,
        'organisation_id': value.organisation_id,
        'routing': AlertSourceRoutingToJSON(value.routing),
        'secret_token': value.secret_token,
        'source_type': value.source_type,
        'state': value.state,
        'template': AlertTemplateToJSON(value.template),
        'transitions': value.transitions === undefined ? undefined : ((value.transitions as Array<any>).map(AlertSourceConfigTransitionToJSON)),
        'updated_at': (value.updated_at.toISOString()),
    };
}

