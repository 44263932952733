import { ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
} from "../../ai/AIFeatureConfigurationDrawer";
import {
  AIPrivacyButton,
  AISuggestionFeatureDrawerProps,
  ToggleSuggestionButton,
} from "./SuggestionAICards";

export const SuggestedRolesDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: suggestConfigResp } = useAPI(
    "incidentSuggestionsGetSuggestionConfig",
    undefined,
  );

  const { trigger: toggleSuggestionFeature } = useAPIMutation(
    "incidentSuggestionsGetSuggestionConfig",
    undefined,
    async (apiClient, { enabled }) =>
      await apiClient.incidentSuggestionsUpdateSuggestionConfig({
        updateSuggestionConfigRequestBody: {
          suggest_roles_enabled: enabled,
        },
      }),
    {
      onSuccess: () => {
        onClose();
      },
      showErrorToast: "Toggling suggested roles",
    },
  );

  const enabled = suggestConfigResp?.config.suggest_roles_enabled;

  return (
    <AIFeatureConfigurationDrawer
      loading={!suggestConfigResp}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleSuggestionButton
                enabled={!!enabled}
                disableModalTitle="Disable suggestion"
                disableModalContent="This means we'll stop suggesting role assignments."
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                onEnable={() => toggleSuggestionFeature({ enabled: true })}
                onDisable={() => toggleSuggestionFeature({ enabled: false })}
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Identify role mismatches"
              description="We'll highlight when someone's activities suggest they should be formally assigned to a role in the incident."
              icon={IconEnum.UserAdd}
            />
            <AIProductMarketingCard
              title="Keep stakeholders informed"
              description="Let everyone know who's doing what so they can focus on their own responsibilities to progress th incident."
              icon={IconEnum.Announcement}
            />
          </AIProductMarketingCards>
        </>
      }
      width="medium"
    />
  );
};
