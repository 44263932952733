import {
  EscalationPath,
  EscalationPathTargetTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { OrgAwareLink, useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import _ from "lodash";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { CopyDebugID } from "../../../utils/ShowDebugIDProvider";
import { tcx } from "../../../utils/tailwind-classes";
import { useRevalidate } from "../../../utils/use-revalidate";
import { DeletionConfirmationModal } from "../../settings/DeletionConfirmationModal";

type EscalationPathsContext = "ALL_ESCALATION_PATHS" | "MY_ESCALATION_PATHS";

export const EscalationPathsTable = ({
  firstLevelUserIDs,
  escalationPaths,
  showEmptyState,
  context,
}: {
  showEmptyState?: boolean;
  escalationPaths: EscalationPath[];
  firstLevelUserIDs: Record<string, string[]>;
  context: EscalationPathsContext;
}) => {
  return (
    <div className={"flex flex-col gap-6"}>
      <div className={"text-content-primary font-semibold text-base"}>
        {context === "ALL_ESCALATION_PATHS"
          ? "All escalation paths"
          : "My escalation paths"}
      </div>
      <Table
        gridTemplateColumns={"repeat(3, auto)"}
        header={
          <>
            <TableHeaderCell className={"first:pl-0"}>Name</TableHeaderCell>
            <TableHeaderCell>Current responders</TableHeaderCell>
            <TableHeaderCell className={"justify-end"} />
          </>
        }
        data={escalationPaths}
        renderRow={(escalationPath, idx) => (
          <EscalationPathRow
            key={`escalation-path-${escalationPath.id}`}
            escalationPath={escalationPath}
            firstLevelUserIDs={firstLevelUserIDs[escalationPath.id] || []}
            isLastRow={idx === escalationPaths.length - 1}
          />
        )}
      />
      {showEmptyState && escalationPaths.length === 0 && (
        <tr>
          <td colSpan={3}>
            <div className="flex items-center justify-center h-[32px]">
              <span>No results found</span>
            </div>
          </td>
        </tr>
      )}
    </div>
  );
};

const EscalationPathRow = ({
  escalationPath,
  firstLevelUserIDs,
  isLastRow,
}: {
  escalationPath: EscalationPath;
  firstLevelUserIDs: Array<string>;
  isLastRow: boolean;
}) => {
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const cellClassName = [
    "group-hover:bg-surface-secondary",
    { "border-stroke-primary": isLastRow },
  ];

  const revalidate = useRevalidate([
    "escalationPathsList",
    "escalationPathsListExternal",
  ]);

  return (
    <>
      <TableRow isLastRow={isLastRow} className={"group"}>
        {/* Name */}
        <TableCell className={tcx("first:pl-0", ...cellClassName)}>
          <OrgAwareLink
            to={`/on-call/escalation-paths/${escalationPath.id}`}
            className="w-full h-full flex items-center"
          >
            <span className="text-sm font-semibold block truncate">
              {escalationPath.name}
            </span>
            <CopyDebugID id={escalationPath.id} />
          </OrgAwareLink>
        </TableCell>

        {/* Current responders */}
        <TableCell className={tcx(...cellClassName)}>
          <OrgAwareLink
            to={`/on-call/escalation-paths/${escalationPath.id}`}
            className="w-full h-full grow flex items-center flex-wrap gap-2"
          >
            {firstLevelUserIDs.length > 0 ? (
              firstLevelUserIDs.map((id) => (
                <TargetPreview
                  key={id}
                  targetType={EscalationPathTargetTypeEnum.User}
                  targetID={id}
                />
              ))
            ) : (
              <div className="text-slate-300">{"\u2014"}</div>
            )}
          </OrgAwareLink>
        </TableCell>

        {/* Dropdown */}
        <TableCell className={tcx("flex justify-end pr-4", ...cellClassName)}>
          <DropdownMenu
            menuClassName={"w-[120px] mr-5"}
            triggerButton={
              <Button
                theme={ButtonTheme.Unstyled}
                size={BadgeSize.Medium}
                className={tcx(
                  "!p-0 hover:bg-surface-secondary text-content-tertiary",
                )}
                icon={IconEnum.DotsVertical}
                iconProps={{
                  size: IconSize.XL,
                }}
                title={"Open menu"}
                analyticsTrackingId={"escalation-paths.open-menu"}
              />
            }
            side={"bottom"}
          >
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => navigate(`${escalationPath.id}/edit`)}
              label="Edit"
              icon={IconEnum.Edit}
            />
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() =>
                navigate(
                  `/on-call/escalation-paths/create?clone=${escalationPath.id}`,
                )
              }
              label="Duplicate"
              icon={IconEnum.Copy}
            />
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => setDeleteModalOpen(true)}
              label="Delete"
              disabled={!hasScope(ScopeNameEnum.EscalationPathsDestroy)}
              tooltipContent={
                hasScope(ScopeNameEnum.EscalationPathsDestroy)
                  ? undefined
                  : "You do not have permission to delete escalation paths."
              }
              destructive
              icon={IconEnum.Delete}
              iconProps={{ className: "text-red-content" }}
            />
          </DropdownMenu>
        </TableCell>
      </TableRow>
      {deleteModalOpen && escalationPath && (
        <ConfirmDeleteEscalationPolicyModal
          escalationPath={escalationPath}
          onConfirm={() => {
            revalidate();
            navigate("/on-call/escalation-paths");
          }}
          onCancel={() => setDeleteModalOpen(false)}
        />
      )}
    </>
  );
};

const TargetPreview = ({
  targetType,
  targetID,
}: {
  targetType: EscalationPathTargetTypeEnum;
  targetID: string;
}) => {
  if (targetType === EscalationPathTargetTypeEnum.User) {
    return <TargetPreviewUser targetID={targetID} />;
  }

  if (targetType === EscalationPathTargetTypeEnum.Schedule) {
    return <TargetPreviewSchedule targetID={targetID} />;
  }

  throw new Error(`Unexpected TargetPreview.type: ${targetType}`);
};

const TargetPreviewSchedule = ({ targetID }: { targetID: string }) => {
  const { data, isLoading, error } = useAPI("schedulesList", undefined); // schedulesList is used rather than schedulesShow as it's a cheaper call

  const schedulesByID = _.keyBy(data?.schedules || [], "id");
  const schedule = schedulesByID[targetID];

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.Medium}>
      <Icon id={IconEnum.Calendar} className="text-content-secondary" />

      {isLoading || !schedule ? (
        <span>Loading schedule</span>
      ) : (
        <span className="text-sm font-medium text-content-primary inline-block truncate">
          {schedule.name}
        </span>
      )}
    </Badge>
  );
};

const TargetPreviewUser = ({ targetID }: { targetID: string }) => {
  const { data: userResp, isLoading } = useAPI("usersShow", {
    id: targetID,
  });

  return (
    <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.Medium}>
      <Avatar
        size={IconSize.Medium}
        url={userResp?.user.avatar_url}
        name={userResp?.user.name}
      />
      {isLoading ? (
        <span>Loading user</span>
      ) : (
        <span className="text-sm font-medium text-content-primary inline-block truncate">
          {userResp?.user.name}
        </span>
      )}
    </Badge>
  );
};

const ConfirmDeleteEscalationPolicyModal = ({
  onConfirm,
  onCancel,
  escalationPath,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  escalationPath: EscalationPath;
}) => {
  const {
    trigger: onSubmit,
    isMutating: isDestroying,
    genericError: destroyError,
  } = useAPIMutation(
    "escalationPathsList",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.escalationPathsDestroy({
        id,
      });
    },
    {
      onSuccess: onConfirm,
    },
  );

  const onDelete = () => {
    onSubmit({ id: escalationPath.id });
  };

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  return (
    <DeletionConfirmationModal
      resourceTitle={escalationPath.name}
      title={"Delete escalation path"}
      onDelete={onDelete}
      isDeleting={isDestroying}
      isOpen={true}
      onClose={onCancel}
      deleteConfirmationContent={
        "Are you sure you want to delete this escalation path?"
      }
      analyticsTrackingId="delete-escalation-path"
      fetchDependentResources={[
        {
          resource_type: `CatalogEntry["EscalationPath"]`,
          id: escalationPath.id,
        },
      ]}
    />
  );
};
