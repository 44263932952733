/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserWithStaffPermissions
 */
export interface UserWithStaffPermissions {
    /**
     * URL of user avatar image
     * @type {string}
     * @memberof UserWithStaffPermissions
     */
    avatar_url?: string;
    /**
     * Email address of the user.
     * @type {string}
     * @memberof UserWithStaffPermissions
     */
    email: string;
    /**
     * Unique identifier of the user
     * @type {string}
     * @memberof UserWithStaffPermissions
     */
    id: string;
    /**
     * Name of the user
     * @type {string}
     * @memberof UserWithStaffPermissions
     */
    name: string;
    /**
     * The staff permissions this user has.
     * @type {Array<string>}
     * @memberof UserWithStaffPermissions
     */
    permissions: Array<UserWithStaffPermissionsPermissionsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum UserWithStaffPermissionsPermissionsEnum {
    AdjustPlan = 'adjust_plan',
    AttachSubscription = 'attach_subscription',
    BootstrapCustomerChannel = 'bootstrap_customer_channel',
    GrantStaffPermission = 'grant_staff_permission',
    Impersonate = 'impersonate',
    ImpersonateTrialAndDemo = 'impersonate_trial_and_demo',
    ManageFeatureAccess = 'manage_feature_access',
    ManageTrial = 'manage_trial',
    RenameOrganisation = 'rename_organisation',
    TeardownOrganisation = 'teardown_organisation',
    TeardownOnCallForOrganisation = 'teardown_on_call_for_organisation',
    EnableOnCallForOrganisation = 'enable_on_call_for_organisation',
    TeardownResponseForOrganisation = 'teardown_response_for_organisation',
    EnableResponseForOrganisation = 'enable_response_for_organisation',
    MarkSandboxAndDemo = 'mark_sandbox_and_demo',
    GrantOwnerRole = 'grant_owner_role',
    ViewChatContext = 'view_chat_context',
    ImportStatusPageSubscribers = 'import_status_page_subscribers'
}

export function UserWithStaffPermissionsFromJSON(json: any): UserWithStaffPermissions {
    return UserWithStaffPermissionsFromJSONTyped(json, false);
}

export function UserWithStaffPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWithStaffPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar_url': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'email': json['email'],
        'id': json['id'],
        'name': json['name'],
        'permissions': json['permissions'],
    };
}

export function UserWithStaffPermissionsToJSON(value?: UserWithStaffPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_url': value.avatar_url,
        'email': value.email,
        'id': value.id,
        'name': value.name,
        'permissions': value.permissions,
    };
}

