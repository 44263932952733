import {
  Alert,
  AlertSchema,
  AlertsListAlertsRequest,
  AlertSourceConfig,
  CatalogResource,
  ErrorResponse,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  AppliedFiltersBanner,
  ExtendedFormFieldValue,
  FilterPopover,
  FiltersContextProvider,
  filtersToListParams,
  SearchBar,
  useFiltersContext,
} from "@incident-shared/filters";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Banner,
  BannerTheme,
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Loader,
  ToastTheme,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/react";
import { AnimatePresence, motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  AlertsTable,
  AlertTableColumn,
} from "src/components/alerts/common/AlertsTable";
import { CustomiseShownAttributesDropdown } from "src/components/alerts/common/ShownAttributes";
import { useAlertResources } from "src/components/alerts/common/useAlertResources";
import { useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useAPIInfinite } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useIdentity } from "../../../contexts/IdentityContext";
import { isTrialPlan, useTrialData } from "../../../utils/trial";
import { useAlertFilters } from "../alert-details/useAlertFilters";
import { useAlertInsightsDates } from "../alert-details/useAlertInsightsDates";
import { AlertActivityChart } from "../overview/AlertActivityChart";

export const AlertsListPage = () => {
  const trialData = useTrialData();
  const [selectedAlerts, setSelectedAlerts] = useState<Set<string>>(new Set());
  const { identity } = useIdentity();

  const selectAlert = (alert: Alert) =>
    setSelectedAlerts((prev) => new Set(prev).add(alert.id));

  const deselectAlert = (alert: Alert) =>
    setSelectedAlerts((prev) => {
      const updated = new Set(prev);
      updated.delete(alert.id);
      return updated;
    });

  const [visibleColumns, setVisibleColumns] = useState<
    AlertTableColumn[] | null
  >(null);

  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    schemaResponse,
    configsResp,
    resourcesListResp,
  } = useAlertResources();

  const { filters, setFilters, filterFields, filtersLoading, filtersError } =
    useAlertFilters();

  const queryFilters: AlertsListAlertsRequest = filtersToListParams(filters);
  const isLoading = resourcesLoading || filtersLoading;
  const error = resourcesError || filtersError;

  const {
    responses,
    isLoading: listAlertsLoading,
    isFullyLoaded,
    error: listAlertsError,
    loadMore: onLoadMore,
  } = useAPIInfinite("alertsListAlerts", queryFilters, {
    revalidateFirstPage: true,
    refreshInterval: 10000, // Reload every 10s
  });

  const alerts = responses.flatMap(({ alerts }) => alerts);

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading || !schemaResponse || !resourcesListResp || !configsResp) {
    return <FullPageLoader />;
  }

  const trialEndedOverOneMonthAgo = (trialData?.daysLeft ?? 0) < -30;
  const isStaleTrialOrg =
    trialEndedOverOneMonthAgo &&
    isTrialPlan(identity?.trial_status.plan_name) &&
    !identity?.organisation_is_sandbox &&
    !identity?.organisation_is_demo;

  return (
    <FiltersContextProvider
      filters={filters}
      setFilters={setFilters}
      availableFilterFields={filterFields}
      filtersLoading={isLoading}
      kind={"alert"}
    >
      <Helmet title={"All alerts - incident.io"} />

      {isStaleTrialOrg && (
        <Banner theme={BannerTheme.Error}>
          Your trial has ended, you won&apos;t receive any alerts until you
          upgrade your plan.
        </Banner>
      )}

      <div className={"px-4 md:px-8 py-4 md:py-6 bg-white"}>
        {/* --- Title Bar --- */}
        <div className={"w-full flex justify-between mb-4"}>
          <AlertSearchBar />

          <div className={"flex flex-row space-x-4"}>
            <FilterPopover
              renderTriggerButton={({ onClick }) => (
                <Button
                  theme={ButtonTheme.Naked}
                  onClick={() => onClick()}
                  analyticsTrackingId={"alert-list-filter"}
                  icon={IconEnum.Filter}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                >
                  Add filter
                </Button>
              )}
            />
            <CustomiseShownAttributesDropdown
              setVisibleColumns={setVisibleColumns}
              visibleColumns={visibleColumns}
              schema={schemaResponse.alert_schema}
            />
            {selectedAlerts.size > 0 && (
              <ResolveAlertsButton
                selectedAlerts={selectedAlerts}
                setSelectedAlerts={setSelectedAlerts}
              />
            )}
          </div>
        </div>

        <AppliedFiltersBanner
          totalNumberOfItems={alerts.length}
          itemsLabel={"alert"}
          style={"partOfPage"}
          className={"mb-4"}
        />

        <AlertsListInsights filters={filters} className={"mb-4"} />

        <InfiniteScrollingAlerts
          alertSourceConfigs={configsResp.alert_source_configs}
          visibleColumns={visibleColumns}
          schema={schemaResponse.alert_schema}
          resources={resourcesListResp.resources}
          selectAlert={selectAlert}
          deselectAlert={deselectAlert}
          selectedAlerts={selectedAlerts}
          setSelectedAlerts={setSelectedAlerts}
          alerts={alerts}
          listAlertsError={listAlertsError}
          isLoading={listAlertsLoading}
          isFullyLoaded={isFullyLoaded}
          onLoadMore={onLoadMore}
        />
      </div>
    </FiltersContextProvider>
  );
};

const AlertsListInsights = ({
  filters,
  className,
}: {
  filters: ExtendedFormFieldValue[];
  className?: string;
}) => {
  const { featureAlertsOverview } = useFlags();

  const shouldShowInsights =
    featureAlertsOverview &&
    // These graphs always show last 30 days, so don't show them if you're
    // filtering for created_at.
    !filters.some((f) => f.field_key === "created_at");

  const dates = useAlertInsightsDates({
    fromStartOfToday: true,
  });

  return (
    <AnimatePresence>
      {shouldShowInsights && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
          className={tcx("flex flex-col gap-3", className)}
        >
          <AlertActivityChart {...dates} filters={filters} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const ResolveAlertsButton = ({
  selectedAlerts,
  setSelectedAlerts,
}: {
  selectedAlerts: Set<string>;
  setSelectedAlerts: React.Dispatch<React.SetStateAction<Set<string>>>;
}) => {
  const { filters } = useFiltersContext();
  const queryFilters: AlertsListAlertsRequest = filtersToListParams(filters);

  const { refetch } = useAPIInfinite("alertsListAlerts", queryFilters, {
    revalidateFirstPage: true,
  });

  const apiClient = useClient();
  const showToast = useToast();
  const [trigger, { saving: isMutating }] = useMutation(
    async () => {
      for (const alertID of selectedAlerts) {
        try {
          await apiClient.alertsResolve({ id: alertID });
        } catch (e) {
          captureException(e);
        }
      }
      await refetch();
    },
    {
      onSuccess: () => {
        setSelectedAlerts(new Set());
        showToast({
          theme: ToastTheme.Success,
          title: "Alerts resolved",
          description:
            selectedAlerts.size === 1
              ? "1 alert has been marked as resolved"
              : `${selectedAlerts.size} alerts have been marked as resolved`,
        });
      },
      onError: (_, fieldErrors) => {
        const hasExternallyResolvedAlert = fieldErrors?.some(
          (fieldError) => fieldError.error.message?.includes("third-party"),
        );
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to resolve alerts",
          description: hasExternallyResolvedAlert
            ? "Some alerts cannot be manually resolved"
            : "An unexpected error occurred while resolving alerts",
        });
      },
    },
  );

  return (
    // Min height keeps this the same size as the other components in the toolbar
    <Button
      theme={ButtonTheme.Primary}
      analyticsTrackingId={"resolve-alerts-button"}
      onClick={() => trigger({})}
      loading={isMutating}
    >
      Resolve {selectedAlerts.size} alert{selectedAlerts.size > 1 && "s"}
    </Button>
  );
};

const AlertSearchBar = () => {
  const {
    availableFilterFields,
    filters,
    addFilter,
    editFilter,
    deleteFilter,
  } = useFiltersContext();
  return (
    <SearchBar
      id="search_alerts"
      placeholder="Search alerts"
      availableFilterFields={availableFilterFields}
      appliedFilters={filters}
      onEditFilter={editFilter}
      onDeleteFilter={deleteFilter}
      onAddFilter={addFilter}
    />
  );
};

const InfiniteScrollingAlerts = ({
  selectedAlerts,
  selectAlert,
  setSelectedAlerts,
  deselectAlert,
  visibleColumns,
  schema,
  resources,
  alertSourceConfigs,
  isLoading,
  isFullyLoaded,
  onLoadMore,
  alerts,
  listAlertsError,
}: {
  isLoading: boolean;
  isFullyLoaded: boolean;
  onLoadMore: () => void;
  alerts: Alert[];
  listAlertsError?: ErrorResponse;
  selectedAlerts: Set<string>;
  selectAlert: (alert: Alert) => void;
  deselectAlert: (alert: Alert) => void;
  setSelectedAlerts: React.Dispatch<React.SetStateAction<Set<string>>>;
  visibleColumns: AlertTableColumn[] | null;
  schema: AlertSchema;
  resources: CatalogResource[];
  alertSourceConfigs: AlertSourceConfig[];
}) => {
  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry gets close to
    // the viewport, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const hasNoAlertSources = alertSourceConfigs.length === 0;
  return (
    <>
      {alerts.length === 0 && isLoading ? (
        <Loader />
      ) : hasNoAlertSources ? (
        <EmptyState
          icon={IconEnum.Alert}
          title={"You have no alerts yet"}
          content={
            "Configure your alert sources to connect your external monitoring tools to incident.io."
          }
          cta={
            <GatedButton
              theme={ButtonTheme.Secondary}
              requiredScope={ScopeNameEnum.AlertSourceCreate}
              href="/alerts/sources/create"
              analyticsTrackingId="create-alert-source"
            >
              Configure an alert source
            </GatedButton>
          }
        />
      ) : (
        <div className="overflow-auto h-full min-h-full">
          <AlertsTable
            selectedAlerts={selectedAlerts}
            setSelectedAlerts={setSelectedAlerts}
            selectAlert={selectAlert}
            deselectAlert={deselectAlert}
            enableSelection={true}
            visibleColumns={visibleColumns}
            schema={schema}
            resources={resources}
            alertSourceConfigs={alertSourceConfigs}
            alerts={alerts}
            error={listAlertsError}
            infiniteScrollRef={infiniteScrollRef}
            allEntriesLoaded={isFullyLoaded}
          />
        </div>
      )}
    </>
  );
};
