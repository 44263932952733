import {
  EscalationPathNodePayloadTypeEnum,
  EscalationPathNodeTypeEnum as NodeTypes,
} from "@incident-io/api";
import { SlackChannelsEditorV2 } from "@incident-shared/forms/v2/editors";
import { ErrorMessage } from "@incident-ui";
import { IconEnum } from "@incident-ui";
import { useFormContext } from "react-hook-form";
import { useNodeFormErrors } from "src/components/escalation-paths/nodes/useNodeFormErrors";

import { EscalationPathFormData } from "../common/types";
import { shouldDisplayWorkingHoursTimeToAck } from "../node-editor/helpers/displayWorkingHoursTimeToAck";
import {
  NodeCard,
  NodeCardDropdown,
  NodeConversionTargetsEnum,
} from "./NodeCard";
import { allowDeleteNode } from "./NodeLevel";
import { TimeToAckForm } from "./TimeToAckForm";

// NodeNotifyChannel is used for all NotifyChannel nodes in an escalation path, and is a wrapper around the box element.
export const NodeNotifyChannel = ({ id }: { id: string }) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const nodes = formMethods.watch("nodes");
  const firstNodeId = formMethods.watch("firstNodeId");

  const shouldAllowWorkingHoursTimeToAck = shouldDisplayWorkingHoursTimeToAck(
    nodes,
    nodes[id],
    firstNodeId,
  );

  const relatedErrors = useNodeFormErrors({
    formMethods,
    id,
    nodeType: NodeTypes.NotifyChannel,
  });

  return (
    <NodeCard
      title="Channel"
      subtitle="Notify"
      icon={IconEnum.Slack}
      iconColor={{
        background: "border border-stroke-secondary",
        icon: "",
      }}
      actionButton={
        <NodeCardDropdown
          nodeId={id}
          conversions={[
            NodeConversionTargetsEnum.Level,
            NodeConversionTargetsEnum.Branch,
            NodeConversionTargetsEnum.Repeat,
          ]}
          canDelete={allowDeleteNode(nodes)}
        />
      }
    >
      <div className="flex flex-col gap-4 mt-4">
        <SlackChannelsEditorV2
          formMethods={formMethods}
          name={`nodes.${id}.data.notify_channel.targets`}
          placeholder="Choose a channel..."
          disallowPrivateChannels={true}
          required
        />

        <TimeToAckForm
          id={id}
          formMethods={formMethods}
          nodeType={EscalationPathNodePayloadTypeEnum.NotifyChannel}
          shouldAllowWorkingHoursTimeToAck={shouldAllowWorkingHoursTimeToAck}
        />
        {relatedErrors.length > 0 ? (
          <ErrorMessage message={relatedErrors[0]} className={"text-xs"} />
        ) : null}
      </div>
    </NodeCard>
  );
};
