/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    CustomField,
    CustomFieldFromJSON,
    CustomFieldFromJSONTyped,
    CustomFieldToJSON,
} from './CustomField';
import {
    IncidentRole,
    IncidentRoleFromJSON,
    IncidentRoleFromJSONTyped,
    IncidentRoleToJSON,
} from './IncidentRole';
import {
    IncidentTimestamp,
    IncidentTimestampFromJSON,
    IncidentTimestampFromJSONTyped,
    IncidentTimestampToJSON,
} from './IncidentTimestamp';

/**
 * 
 * @export
 * @interface AvailableIncidentFormLifecycleElement
 */
export interface AvailableIncidentFormLifecycleElement {
    /**
     * Whether this element can be hidden
     * @type {boolean}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    can_be_hidden: boolean;
    /**
     * Whether this element can be reordered by the user
     * @type {boolean}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    can_be_reordered: boolean;
    /**
     * Whether the user can set the 'can_select_no_value' settting
     * @type {boolean}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    can_choose_select_no_value: boolean;
    /**
     * Whether the user can change the 'required if' settting
     * @type {boolean}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    can_configure_required_if: boolean;
    /**
     * Whether this element can have a default value
     * @type {boolean}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    can_have_default: boolean;
    /**
     * Whether this element can have description
     * @type {boolean}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    can_have_description: boolean;
    /**
     * Whether this element can have a placeholder
     * @type {boolean}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    can_have_placeholder: boolean;
    /**
     * 
     * @type {CustomField}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    custom_field?: CustomField;
    /**
     * The type of element
     * @type {string}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    element_type: AvailableIncidentFormLifecycleElementElementTypeEnum;
    /**
     * Is the engine resource an array?
     * @type {boolean}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    engine_resource_array: boolean;
    /**
     * The resource type that represents this element (so that we can configure default and fixed values)
     * @type {string}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    engine_resource_type: string;
    /**
     * The icon for this element
     * @type {string}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    icon: AvailableIncidentFormLifecycleElementIconEnum;
    /**
     * 
     * @type {IncidentRole}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    incident_role?: IncidentRole;
    /**
     * 
     * @type {IncidentTimestamp}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    incident_timestamp?: IncidentTimestamp;
    /**
     * The label of this element (e.g. the custom field name)
     * @type {string}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    label: string;
    /**
     * The rank of this element (for sorting)
     * @type {number}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    rank?: number;
    /**
     * 
     * @type {Array<ConditionGroup>}
     * @memberof AvailableIncidentFormLifecycleElement
     */
    show_if_fixed_condition_groups: Array<ConditionGroup>;
}

/**
* @export
* @enum {string}
*/
export enum AvailableIncidentFormLifecycleElementElementTypeEnum {
    Name = 'name',
    Status = 'status',
    Severity = 'severity',
    IncidentRole = 'incident_role',
    IncidentType = 'incident_type',
    Summary = 'summary',
    CustomField = 'custom_field',
    Timestamp = 'timestamp',
    UpdateMessage = 'update_message',
    NextUpdateIn = 'next_update_in',
    Triage = 'triage',
    Visibility = 'visibility',
    Divider = 'divider',
    Text = 'text'
}/**
* @export
* @enum {string}
*/
export enum AvailableIncidentFormLifecycleElementIconEnum {
    Action = 'action',
    Alert = 'alert',
    Announcement = 'announcement',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Chart = 'chart',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentStatusDeclined = 'incident-status-declined',
    IncidentStatusMerged = 'incident-status-merged',
    IncidentType = 'incident_type',
    Key = 'key',
    Lock = 'lock',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    OnCall = 'on-call',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackGreyscale = 'slack-greyscale',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Sparkles = 'sparkles',
    SparklesAiGradient = 'sparkles-ai-gradient',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}

export function AvailableIncidentFormLifecycleElementFromJSON(json: any): AvailableIncidentFormLifecycleElement {
    return AvailableIncidentFormLifecycleElementFromJSONTyped(json, false);
}

export function AvailableIncidentFormLifecycleElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableIncidentFormLifecycleElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_be_hidden': json['can_be_hidden'],
        'can_be_reordered': json['can_be_reordered'],
        'can_choose_select_no_value': json['can_choose_select_no_value'],
        'can_configure_required_if': json['can_configure_required_if'],
        'can_have_default': json['can_have_default'],
        'can_have_description': json['can_have_description'],
        'can_have_placeholder': json['can_have_placeholder'],
        'custom_field': !exists(json, 'custom_field') ? undefined : CustomFieldFromJSON(json['custom_field']),
        'element_type': json['element_type'],
        'engine_resource_array': json['engine_resource_array'],
        'engine_resource_type': json['engine_resource_type'],
        'icon': json['icon'],
        'incident_role': !exists(json, 'incident_role') ? undefined : IncidentRoleFromJSON(json['incident_role']),
        'incident_timestamp': !exists(json, 'incident_timestamp') ? undefined : IncidentTimestampFromJSON(json['incident_timestamp']),
        'label': json['label'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'show_if_fixed_condition_groups': ((json['show_if_fixed_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
    };
}

export function AvailableIncidentFormLifecycleElementToJSON(value?: AvailableIncidentFormLifecycleElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_be_hidden': value.can_be_hidden,
        'can_be_reordered': value.can_be_reordered,
        'can_choose_select_no_value': value.can_choose_select_no_value,
        'can_configure_required_if': value.can_configure_required_if,
        'can_have_default': value.can_have_default,
        'can_have_description': value.can_have_description,
        'can_have_placeholder': value.can_have_placeholder,
        'custom_field': CustomFieldToJSON(value.custom_field),
        'element_type': value.element_type,
        'engine_resource_array': value.engine_resource_array,
        'engine_resource_type': value.engine_resource_type,
        'icon': value.icon,
        'incident_role': IncidentRoleToJSON(value.incident_role),
        'incident_timestamp': IncidentTimestampToJSON(value.incident_timestamp),
        'label': value.label,
        'rank': value.rank,
        'show_if_fixed_condition_groups': ((value.show_if_fixed_condition_groups as Array<any>).map(ConditionGroupToJSON)),
    };
}

