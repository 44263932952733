import {
  CopilotThread,
  useAiStaffServiceAiStaffShowCopilotThread,
} from "@incident-io/query-api";
import { Button, ButtonTheme, Tooltip } from "@incident-ui";

export const CopilotThreadScoring = ({
  threadTS,
  slackChannelID,
}: {
  threadTS: string;
  slackChannelID: string;
}) => {
  const { data, isLoading, isError } =
    useAiStaffServiceAiStaffShowCopilotThread({
      slackChannelId: slackChannelID,
      threadTs: threadTS,
    });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="h-2 w-2 animate-pulse rounded-full bg-gray-500" />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="my-4 rounded-md bg-red-50 p-3 text-red-700">
        Failed to load scoring information
      </div>
    );
  }

  const thread = data?.thread;

  return (
    <div className="w-full space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-start align-left gap-2">
          <div className="flex items-center gap-2">
            <CopilotScoreBadge score={thread.scores?.score} />
            {thread.classification && <ClassificationBadge thread={thread} />}
          </div>
          {thread.scores?.tags && (
            <div className="flex flex-row gap-1 items-start align-center">
              <p className="text-xs text-slate-700 uppercase font-mono">
                Tags:
              </p>
              <div className="flex flex-wrap gap-2">
                {thread.scores?.tags?.map((tag) => (
                  <Button
                    theme={ButtonTheme.Unstyled}
                    analyticsTrackingId={null}
                    key={tag}
                    href={`/workbench/threads?tag=${tag}`}
                    className="rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-600 cursor-pointer"
                  >
                    {tag}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-4">
        <div className="rounded-md bg-gray-50 p-3">
          <h3 className="mb-1.5 text-sm font-medium">Analysis</h3>
          <p className="text-sm text-gray-700">{thread.scores?.reasoning}</p>
        </div>

        {thread.scores?.metrics && (
          <div>
            <h3 className="mb-1.5 text-sm font-medium">Metrics</h3>
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3">
              {Object.entries(thread.scores.metrics)
                .sort(([a], [b]) => a.localeCompare(b))
                .map(([key, value]) => (
                  <div
                    key={key}
                    className="flex items-start gap-2 rounded-md bg-gray-50 p-2"
                  >
                    <div>
                      <div className="text-xs font-medium capitalize">
                        {key.replace(/_/g, " ")}
                      </div>
                      <div className="text-xs text-gray-600">{value}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const scoreColors = {
  high: "bg-green-500",
  medium: "bg-yellow-500",
  low: "bg-red-500",
};

const scoreExpalantions = {
  high: "A high score indicates that a given copilot interaction satisfies the users needs excatly without fuss or confusion.",
  medium:
    "A medium score indicates that a given copilot interaction satisfies the users needs but with some confusion or fuss.",
  low: "A low score indicates that a given copilot interaction does not satisfy the users needs and is confusing or frustrating.",
};

export const CopilotScoreBadge = ({
  score,
}: {
  score?: "high" | "medium" | "low";
}) => {
  if (!score) {
    return null;
  }

  return (
    <Tooltip content={scoreExpalantions[score]}>
      <div
        className={`inline-flex items-center rounded-md ${scoreColors[score]} px-2.5 py-1 text-xs font-medium text-white`}
      >
        <span>{score.toUpperCase()}</span>
      </div>
    </Tooltip>
  );
};

const classificationStyles = {
  question: {
    background: "bg-blue-100",
    text: "text-blue-700",
    icon: "❓",
  },
  action: {
    background: "bg-purple-100",
    text: "text-purple-700",
    icon: "⚡",
  },
  other: {
    background: "bg-gray-100",
    text: "text-gray-700",
    icon: "•",
  },
};

const classificationExplanation = {
  question:
    "We think the purpose of the user interacting with us is to extract information.",
  action:
    "We think the purpose of the user interacting with us is to perform an action.",
  both: "We think the user would like to both extract information and perform an action.",
  unsure:
    "We are not sure what the purpose of the user interacting with us is.",
};

const ClassificationBadge = ({
  thread,
}: {
  thread: CopilotThread;
}): React.ReactElement | null => {
  const classification = thread.classification;
  const requestTypes = thread.request_types;

  if (!classification) {
    return null;
  }

  const style =
    classificationStyles[classification] || classificationStyles.other;
  let title: string =
    classification.charAt(0).toUpperCase() + classification.slice(1);
  if (requestTypes && requestTypes.length > 0) {
    title = `${title}: ${requestTypes.join(", ")}`;
  }

  return (
    <Tooltip content={classificationExplanation[classification]}>
      <div
        className={`inline-flex items-center gap-2 rounded-full ${style.background} px-3 py-1`}
      >
        <span className="text-base">{style.icon}</span>
        <span className={`text-sm font-medium ${style.text}`}>{title}</span>
      </div>
    </Tooltip>
  );
};
