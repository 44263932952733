import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Callout, CalloutTheme, IconEnum } from "@incident-ui";
import _ from "lodash";
import { DateTime } from "luxon";
import { UseFormReturn } from "react-hook-form";

import { ScheduleFormData } from "../common/types";
import { getCurrentAndUpcomingRotaVersions } from "../common/util";

interface RotaUpcomingChangePickerProps {
  rotaID: string;
  formMethods: UseFormReturn<ScheduleFormData>;
  now: DateTime;
}

export const RotaUpcomingChangePicker = ({
  rotaID,
  formMethods,
  now,
}: RotaUpcomingChangePickerProps) => {
  const rotaVersions = formMethods.watch(`rotations.${rotaID}`);

  const sortedRotaVersions = _.sortBy(
    getCurrentAndUpcomingRotaVersions(Object.values(rotaVersions), now),
    (rotation) => (rotation.effective_from ? 1 : 0),
    "effective_from",
  );
  const options = sortedRotaVersions.map((rota, index) => {
    const formattedDate = rota.effective_from
      ? DateTime.fromJSDate(rota.effective_from).toFormat("yyyy-MM-dd HH:mm")
      : "";

    return {
      label:
        index === 0
          ? "Current"
          : `Upcoming change (${formattedDate})` || "Upcoming change",
      value: rota.version_id,
    };
  });

  if (sortedRotaVersions.length <= 1) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <Callout
        iconOverride={IconEnum.CalendarClock}
        theme={CalloutTheme.Info}
        title="This rotation has upcoming changes"
        subtitle="Select which version you would like to make changes to"
      />

      <StaticSingleSelectV2
        label={"Version"}
        options={options}
        formMethods={formMethods}
        name={`selected_rotation_versions.${rotaID}`}
      />

      <div className="border-t border-dashed border-slate-200" />
    </div>
  );
};
