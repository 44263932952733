import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";

import { PathNode } from "../../common/types";

export const getNextNodeId = ({
  node,
}: {
  node: PathNode;
}): string | undefined => {
  if (node.data.type === NodeTypes.Level) {
    return node.data.nextNodeId;
  } else if (node.data.type === NodeTypes.NotifyChannel) {
    return node.data.nextNodeId;
  }
  return undefined;
};
