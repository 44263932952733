/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OverviewDeclineRateDatapoint,
    OverviewDeclineRateDatapointFromJSON,
    OverviewDeclineRateDatapointFromJSONTyped,
    OverviewDeclineRateDatapointToJSON,
} from './OverviewDeclineRateDatapoint';

/**
 * 
 * @export
 * @interface OverviewDeclineRateTable
 */
export interface OverviewDeclineRateTable {
    /**
     * 
     * @type {Array<OverviewDeclineRateDatapoint>}
     * @memberof OverviewDeclineRateTable
     */
    datapoints: Array<OverviewDeclineRateDatapoint>;
}

export function OverviewDeclineRateTableFromJSON(json: any): OverviewDeclineRateTable {
    return OverviewDeclineRateTableFromJSONTyped(json, false);
}

export function OverviewDeclineRateTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewDeclineRateTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoints': ((json['datapoints'] as Array<any>).map(OverviewDeclineRateDatapointFromJSON)),
    };
}

export function OverviewDeclineRateTableToJSON(value?: OverviewDeclineRateTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoints': ((value.datapoints as Array<any>).map(OverviewDeclineRateDatapointToJSON)),
    };
}

