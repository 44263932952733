/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagedResourcesUpsertRequestBody
 */
export interface ManagedResourcesUpsertRequestBody {
    /**
     * A map of annotations to apply to this resource, Provide an empty map to clear existing annotations
     * @type {{ [key: string]: string; }}
     * @memberof ManagedResourcesUpsertRequestBody
     */
    annotations?: { [key: string]: string; };
    /**
     * The way this resource is managed. Provide an empty string to clear an existing value
     * @type {string}
     * @memberof ManagedResourcesUpsertRequestBody
     */
    managed_by?: ManagedResourcesUpsertRequestBodyManagedByEnum;
    /**
     * The ID of the resource that this relates to
     * @type {string}
     * @memberof ManagedResourcesUpsertRequestBody
     */
    resource_id: string;
    /**
     * The type of the related resource
     * @type {string}
     * @memberof ManagedResourcesUpsertRequestBody
     */
    resource_type: ManagedResourcesUpsertRequestBodyResourceTypeEnum;
    /**
     * The URL where this resource is managed. Provide an empty string to clear an existing value
     * @type {string}
     * @memberof ManagedResourcesUpsertRequestBody
     */
    source_url?: string;
}

/**
* @export
* @enum {string}
*/
export enum ManagedResourcesUpsertRequestBodyManagedByEnum {
    Dashboard = 'dashboard',
    Terraform = 'terraform',
    External = 'external'
}/**
* @export
* @enum {string}
*/
export enum ManagedResourcesUpsertRequestBodyResourceTypeEnum {
    EscalationPath = 'escalation_path',
    Schedule = 'schedule',
    Workflow = 'workflow'
}

export function ManagedResourcesUpsertRequestBodyFromJSON(json: any): ManagedResourcesUpsertRequestBody {
    return ManagedResourcesUpsertRequestBodyFromJSONTyped(json, false);
}

export function ManagedResourcesUpsertRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagedResourcesUpsertRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'managed_by': !exists(json, 'managed_by') ? undefined : json['managed_by'],
        'resource_id': json['resource_id'],
        'resource_type': json['resource_type'],
        'source_url': !exists(json, 'source_url') ? undefined : json['source_url'],
    };
}

export function ManagedResourcesUpsertRequestBodyToJSON(value?: ManagedResourcesUpsertRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'managed_by': value.managed_by,
        'resource_id': value.resource_id,
        'resource_type': value.resource_type,
        'source_url': value.source_url,
    };
}

