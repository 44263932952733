import { MutableRefObject, useEffect, useState } from "react";

export type BoundingContainer = {
  width: number;
  height: number;
  left: number;
};

export const useResize = (
  targetRef: MutableRefObject<HTMLDivElement | null>,
  defaults = { width: 700, height: 500, left: 0 },
): BoundingContainer => {
  const [divPosition, setDivPosition] = useState<BoundingContainer>(defaults);

  useEffect(() => {
    const currentRef = targetRef.current;
    if (!currentRef) return undefined;

    setDivPosition(currentRef.getBoundingClientRect());

    const observer = new ResizeObserver((entries) => {
      const rects = entries[0].target.getBoundingClientRect();
      // When we call getBoundingClientRect, for some reason it is not
      // respecting the box-sizing: border styling as the width is inclusive of
      // the border width, when we don't want to include the border in the
      // output.
      //
      // To avoid infinitely resizing width-wise, we adjust the result of
      // getBoundingClientRect to match the observer's inlineSize which appears
      // to be more reliable when accounting for the border widths.
      if (rects.width !== entries[0].contentBoxSize[0].inlineSize) {
        rects.width = entries[0].contentBoxSize[0].inlineSize;
      }
      setDivPosition(rects);
    });
    observer.observe(currentRef);

    return () => observer.unobserve(currentRef);
  }, [targetRef]);

  return divPosition;
};
