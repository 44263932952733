import { TemplatedTextDisplayStyle } from "@incident-shared/forms/v1/TemplatedText";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { PrimaryGatedButtonWithDropdown } from "@incident-shared/gates/GatedButton/PrimaryGatedButtonWithDropdown";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  DropdownMenuItem,
  LoadingWrapper,
} from "@incident-ui";
import { isEmpty, isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IncidentSummarySuggestion,
  IncidentsUpdateSummaryRequestBody,
  TextDocument,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { useIdentity } from "../../../../contexts/IdentityContext";
import { useUpdateSummary } from "./hooks";

type SummaryEditorProps = {
  alignButtons?: "left" | "right";
  incidentId: string;
  summary?: TextDocument;
  suggestion?: IncidentSummarySuggestion;
  onCancel: () => void;
  onSave: () => void;
};

export const SummaryEditor = ({
  incidentId,
  summary,
  suggestion,
  alignButtons = "left",
  onCancel,
  onSave,
}: SummaryEditorProps) => {
  const formMethods = useForm<IncidentsUpdateSummaryRequestBody>({
    defaultValues: {
      summary: suggestion ? suggestion.suggestion : summary,
      notify_slack_channel: false,
    },
  });

  const { reset, handleSubmit } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useUpdateSummary(incidentId, suggestion, () => {
    reset();
    onSave();
  });

  const onSubmitAndNotify = async (data: IncidentsUpdateSummaryRequestBody) => {
    return onSubmit({ ...data, notify_slack_channel: true });
  };

  const { identity } = useIdentity();
  const channelType = identity.ms_teams_info === undefined ? "Slack" : "Teams";

  // Only enable the save button if an edit has been made
  const summaryChanged = !isEqual(summary, formMethods.getValues("summary"));
  const summaryEmpty = isEmpty(formMethods.getValues("summary.text_node"));

  return (
    <LoadingWrapper loading={saving} isTransparent>
      <Form.Root
        formMethods={formMethods}
        aria-live="polite"
        onSubmit={onSubmit}
        genericError={genericError}
        // deliberately don't pass 'saving' as we only want the loading wrapper around the templatedText input
      >
        <TemplatedTextInputV2
          formMethods={formMethods}
          name="summary.text_node"
          aria-describedby="summary-heading"
          autoFocus
          includeVariables={false}
          format="slack_rich_text_with_headings"
          style={TemplatedTextDisplayStyle.Naked}
          includeExpressions={false}
          placeholder="e.g. Problem: users have reported that they're unable to login..."
          disabled={saving}
        />
        <div className="ml-auto space-y-3">
          <div
            className={tcx(
              "flex gap-2",
              alignButtons === "right" && "flex-row-reverse",
            )}
          >
            <PrimaryGatedButtonWithDropdown
              analyticsTrackingId="save-summary"
              type="submit"
              size={BadgeSize.Medium}
              disabled={!summaryChanged || summaryEmpty || saving}
              dropdownItems={
                <DropdownMenuItem
                  analyticsTrackingId="save-and-notify-slack"
                  onSelect={() => handleSubmit(onSubmitAndNotify)()}
                  label={`Save and notify ${channelType}`}
                />
              }
              theme={ButtonTheme.Primary}
            >
              Save
            </PrimaryGatedButtonWithDropdown>
            <Button
              analyticsTrackingId="save-summary"
              type="reset"
              size={BadgeSize.Medium}
              disabled={saving}
              onClick={() => {
                reset();
                onCancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form.Root>
    </LoadingWrapper>
  );
};
