import { usePylon } from "@bolasim/react-use-pylon";
import {
  ErrorResponse,
  SelectOption as SelectOption,
  SlackInfoUserSlackRoleEnum as SlackRoleEnum,
} from "@incident-io/api";
import {
  Avatar,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconSize,
  Modal,
  ModalContent,
  Txt,
} from "@incident-ui";
import { captureException } from "@sentry/react";
import _ from "lodash";
import React from "react";

import { Identity } from "../../../../contexts/ClientContext";
import { useAPI } from "../../../../utils/swr";
import { RawAddToSlackButton } from "../../../slack/AddToSlackButton";

export const ConnectModal = ({
  isOpen,
  onClose,
  identity,
}: {
  isOpen: boolean;
  identity: Identity;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data: authURIData,
    isLoading: authURILoading,
    error: authURIError,
  } = useAPI("authShowSlackUserTokenConfig", undefined);
  const authURI = authURIData?.slack_auth_uri;

  const {
    data: { options: admins },
    error: adminsError,
  } = useAPI(
    "engineTypeahead",
    { resource: "SlackAdminUser" },
    { fallbackData: { options: [], option_groups: [] } },
  );
  const {
    data: { options: owners },
    error: ownersError,
  } = useAPI(
    "engineTypeahead",
    { resource: "SlackOwnerUser" },
    { fallbackData: { options: [], option_groups: [] } },
  );

  const currentUserRole = identity.slack_info?.user_slack_role;

  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  let isUserMissingPermissions = false;
  let error: ErrorResponse | null = null;
  if (authURIError?.status === 422) {
    // If we get a 422, that suggests the current user does not have the correct permissions
    isUserMissingPermissions = true;
  } else {
    error = authURIError || adminsError || ownersError || null;
  }

  if (error) {
    captureException(error);
  }

  return (
    <Modal
      title={"Connect Slack user"}
      isOpen={isOpen}
      onClose={onClose}
      loading={authURILoading}
      analyticsTrackingId={"connect-slack-user"}
    >
      <ModalContent className="divide-y divide-slate-300 text-sm">
        <div className="space-y-2 pb-4">
          {(admins || []).length + (owners || []).length > 0 && (
            <>
              {currentUserRole === SlackRoleEnum.Owner ? (
                <Txt>
                  Within your Slack workspace, your account has the
                  &quot;owner&quot; role. Therefore you can add your own
                  account, or one of the following other &quot;admin&quot; or
                  &quot;owner&quot; accounts in your Slack workspace:
                </Txt>
              ) : currentUserRole === SlackRoleEnum.Admin ? (
                <Txt>
                  Within your Slack workspace, your account has the
                  &quot;admin&quot; role. Therefore you can add your own
                  account, or one of the following other &quot;admin&quot; or
                  &quot;owner&quot; accounts in your Slack workspace:
                </Txt>
              ) : (
                <Txt>
                  Within your Slack workspace, your account does not have the
                  &quot;admin&quot; or &quot;owner&quot; role. You will need to
                  connect one of the following accounts:
                </Txt>
              )}
              <div className="flex flex-row">
                <UserList users={admins || []} title="Admins" />
                <UserList users={owners || []} title="Owners" />
              </div>
            </>
          )}
        </div>

        <Txt className="py-4">
          We recommend creating a Slack account just for incident.io. Read our
          <Button
            theme={ButtonTheme.Link}
            onClick={() => showArticle("5227680663")}
            analyticsTrackingId="connect-slack-user-help"
          >
            setup guide
          </Button>{" "}
          to learn how.
        </Txt>

        <div className="pt-4 space-y-2">
          <Callout theme={CalloutTheme.Warning}>
            If you use an Owner account, it will be invited to private incidents
            where necessary to manage the channel.
          </Callout>

          <p className="pt-2 text-center">
            {error ? (
              <GenericErrorMessage />
            ) : (
              <RawAddToSlackButton
                url={authURI || ""}
                isUserMissingPermissions={isUserMissingPermissions}
              />
            )}
          </p>
        </div>
      </ModalContent>
    </Modal>
  );
};

const UserList = ({
  users,
  title,
}: {
  users: SelectOption[];
  title: string;
}): React.ReactElement | null => {
  if (users.length === 0) {
    return null;
  }

  return (
    <div className="grow">
      <span className="font-semibold">{title}:</span>
      <ul className="pt-2 space-y-2 max-h-40 overflow-y-auto">
        {_.sortBy(users, "sort_key").map((opt) => (
          <li key={opt.value} className="flex items-center gap-1">
            <Avatar
              size={IconSize.Medium}
              name={opt.label}
              url={opt.image_url}
            />{" "}
            {opt.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
