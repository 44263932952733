/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentSuggestion
 */
export interface IncidentSuggestion {
    /**
     * The action that was taken on this suggestion
     * @type {string}
     * @memberof IncidentSuggestion
     */
    action?: IncidentSuggestionActionEnum;
    /**
     * The content that has been suggested
     * @type {object}
     * @memberof IncidentSuggestion
     */
    content: object;
    /**
     * How confident is the LLM in this suggestion
     * @type {string}
     * @memberof IncidentSuggestion
     */
    conviction: IncidentSuggestionConvictionEnum;
    /**
     * When the suggestion was generated
     * @type {Date}
     * @memberof IncidentSuggestion
     */
    generated_at: Date;
    /**
     * The ID of the suggestion
     * @type {string}
     * @memberof IncidentSuggestion
     */
    id: string;
    /**
     * What incident is this suggestion for
     * @type {string}
     * @memberof IncidentSuggestion
     */
    incident_id: string;
    /**
     * The ID of the associated resource (optional) e.g. an incident role ID
     * @type {string}
     * @memberof IncidentSuggestion
     */
    resource_id?: string;
    /**
     * How was this suggestion generated
     * @type {string}
     * @memberof IncidentSuggestion
     */
    source?: IncidentSuggestionSourceEnum;
    /**
     * The type of suggestion
     * @type {string}
     * @memberof IncidentSuggestion
     */
    suggestion_type: IncidentSuggestionSuggestionTypeEnum;
    /**
     * Whether this suggestion has triggered a nudge
     * @type {boolean}
     * @memberof IncidentSuggestion
     */
    triggered_nudge?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum IncidentSuggestionActionEnum {
    Accept = 'accept',
    AcceptWithEdits = 'accept_with_edits',
    Decline = 'decline',
    Empty = ''
}/**
* @export
* @enum {string}
*/
export enum IncidentSuggestionConvictionEnum {
    _30High = '30_high',
    _20Medium = '20_medium',
    _10Low = '10_low',
    _00NoChange = '00_no_change'
}/**
* @export
* @enum {string}
*/
export enum IncidentSuggestionSourceEnum {
    Empty = '',
    Copilot = 'copilot'
}/**
* @export
* @enum {string}
*/
export enum IncidentSuggestionSuggestionTypeEnum {
    IncidentRole = 'incident_role',
    Update = 'update',
    Name = 'name'
}

export function IncidentSuggestionFromJSON(json: any): IncidentSuggestion {
    return IncidentSuggestionFromJSONTyped(json, false);
}

export function IncidentSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSuggestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'content': json['content'],
        'conviction': json['conviction'],
        'generated_at': (new Date(json['generated_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'resource_id': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'suggestion_type': json['suggestion_type'],
        'triggered_nudge': !exists(json, 'triggered_nudge') ? undefined : json['triggered_nudge'],
    };
}

export function IncidentSuggestionToJSON(value?: IncidentSuggestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'content': value.content,
        'conviction': value.conviction,
        'generated_at': (value.generated_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'resource_id': value.resource_id,
        'source': value.source,
        'suggestion_type': value.suggestion_type,
        'triggered_nudge': value.triggered_nudge,
    };
}

