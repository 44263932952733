import { Incident, IncidentStatusCategoryEnum } from "@incident-io/api";
import { Badge, BadgeSize, BadgeTheme, IconEnum, Tooltip } from "@incident-ui";
import { compact } from "lodash";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { formatDurationInSecondsShort } from "src/utils/datetime";
import { getDurationInSeconds } from "src/utils/presenters";
import { useInterval } from "usehooks-ts";

import { incidentInEditableStatus } from "../helpers";
import { Sidebar } from "./Components";
import {
  EditableIncidentTypesBadge,
  EditableSeverityBadge,
  EditableStatusBadge,
} from "./EditableBadges";

type IncidentKeyDetailsProps = {
  incident: Incident | null;
  setModalOpen: (modal: IncidentHeaderModal) => void;
  horizontal?: boolean;
};

export const IncidentKeyDetails = ({
  incident,
  setModalOpen,
  horizontal = false,
}: IncidentKeyDetailsProps) => {
  const incidentIsEditable = incident && incidentInEditableStatus(incident);
  const calculateDuration = () => {
    if (!incident) return null;

    const duration = getDurationInSeconds(incident);
    if (!duration) return null;

    return formatDurationInSecondsShort(duration);
  };
  const [duration, setDuration] = useState(calculateDuration);
  useInterval(() => setDuration(calculateDuration), 1 * 1000);

  const showIncidentTypes = useIsIncidentTypesEnabled();

  if (!incident) {
    return null;
  }

  const components = compact([
    {
      label: "Status",
      component: (
        <EditableStatusBadge
          status={incident?.incident_status}
          onEdit={
            incidentIsEditable
              ? () => setModalOpen(IncidentHeaderModal.UpdateStatus)
              : undefined
          }
        />
      ),
    },
    {
      label: "Severity",
      component: (
        <EditableSeverityBadge
          severity={incident.severity}
          onEdit={
            incidentIsEditable
              ? () => setModalOpen(IncidentHeaderModal.UpdateSeverity)
              : undefined
          }
        />
      ),
    },
    showIncidentTypes
      ? {
          label: "Type",
          component: (
            <EditableIncidentTypesBadge
              incidentType={incident?.incident_type}
              onEdit={
                incidentIsEditable
                  ? () => setModalOpen(IncidentHeaderModal.UpdateIncidentType)
                  : undefined
              }
            />
          ),
        }
      : undefined,
    {
      label: "Duration",
      component:
        duration && incident ? (
          <Tooltip
            content={
              <IncidentDuration
                category={incident.incident_status.category}
                durationString={duration}
              />
            }
          >
            <Badge
              size={BadgeSize.Medium}
              theme={BadgeTheme.Naked}
              icon={IconEnum.Clock}
              className="text-content-primary"
            >
              {duration}
            </Badge>
          </Tooltip>
        ) : null,
    },
  ]);

  if (horizontal) {
    return (
      <div className="xl:hidden flex gap-2 items-center pt-3">
        {components.map(({ component }) => {
          if (!component) return null;

          return component;
        })}
      </div>
    );
  }

  return (
    <Sidebar.Section>
      {components.map(({ component, label }) => {
        if (!component) return null;

        return <Sidebar.Entry label={label} key={label} value={component} />;
      })}
    </Sidebar.Section>
  );
};

const IncidentDuration = ({
  category,
  durationString,
}: {
  category: IncidentStatusCategoryEnum;
  durationString: string;
}) => {
  let prefix = "";
  switch (category) {
    case IncidentStatusCategoryEnum.Declined:
    case IncidentStatusCategoryEnum.Canceled:
    case IncidentStatusCategoryEnum.Merged:
      // Don't show anything!
      return <></>;

    case IncidentStatusCategoryEnum.Closed:
    case IncidentStatusCategoryEnum.PostIncident:
      prefix = "Lasted";
      break;
    case IncidentStatusCategoryEnum.Triage:
      prefix = "In triage for";
      break;
    default:
      prefix = "Ongoing for";
  }

  return <>{`${prefix} ${durationString}`}</>;
};

export const useIsIncidentTypesEnabled = () => {
  const { identity } = useIdentity();
  const { settings } = useSettings();

  // the feature gate is separate to the org setting of whether the company has incident types enabled or not.
  // the feature gate takes precedence
  const hasIncidentTypesFeatureGate =
    identity?.feature_gates?.incident_types === true;
  const hasIncidentTypesEnabled = settings?.misc.incident_types_enabled;
  return hasIncidentTypesFeatureGate && hasIncidentTypesEnabled;
};
