/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentSlim,
    IncidentSlimFromJSON,
    IncidentSlimFromJSONTyped,
    IncidentSlimToJSON,
} from './IncidentSlim';

/**
 * 
 * @export
 * @interface OverviewActivityDatapoint
 */
export interface OverviewActivityDatapoint {
    /**
     * Number of alerts in this time period
     * @type {number}
     * @memberof OverviewActivityDatapoint
     */
    alert_count: number;
    /**
     * The date this datapoint represents
     * @type {Date}
     * @memberof OverviewActivityDatapoint
     */
    date: Date;
    /**
     * Percentage of incidents that were declined in this time period
     * @type {number}
     * @memberof OverviewActivityDatapoint
     */
    decline_rate: number;
    /**
     * Number of declined incidents in this time period
     * @type {number}
     * @memberof OverviewActivityDatapoint
     */
    declined_incident_count: number;
    /**
     * Workload from declined incidents in minutes
     * @type {number}
     * @memberof OverviewActivityDatapoint
     */
    declined_workload_minutes: number;
    /**
     * Number of incidents on this time period
     * @type {number}
     * @memberof OverviewActivityDatapoint
     */
    incident_count: number;
    /**
     * Whether this datapoint is for the current or previous period
     * @type {string}
     * @memberof OverviewActivityDatapoint
     */
    period: OverviewActivityDatapointPeriodEnum;
    /**
     * Workload from real (non-declined) incidents in minutes
     * @type {number}
     * @memberof OverviewActivityDatapoint
     */
    real_workload_minutes: number;
    /**
     * 
     * @type {Array<IncidentSlim>}
     * @memberof OverviewActivityDatapoint
     */
    relevant_incidents: Array<IncidentSlim>;
    /**
     * Total workload in minutes for this time period
     * @type {number}
     * @memberof OverviewActivityDatapoint
     */
    total_workload_minutes: number;
}

/**
* @export
* @enum {string}
*/
export enum OverviewActivityDatapointPeriodEnum {
    Current = 'current',
    Previous = 'previous'
}

export function OverviewActivityDatapointFromJSON(json: any): OverviewActivityDatapoint {
    return OverviewActivityDatapointFromJSONTyped(json, false);
}

export function OverviewActivityDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewActivityDatapoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_count': json['alert_count'],
        'date': (new Date(json['date'])),
        'decline_rate': json['decline_rate'],
        'declined_incident_count': json['declined_incident_count'],
        'declined_workload_minutes': json['declined_workload_minutes'],
        'incident_count': json['incident_count'],
        'period': json['period'],
        'real_workload_minutes': json['real_workload_minutes'],
        'relevant_incidents': ((json['relevant_incidents'] as Array<any>).map(IncidentSlimFromJSON)),
        'total_workload_minutes': json['total_workload_minutes'],
    };
}

export function OverviewActivityDatapointToJSON(value?: OverviewActivityDatapoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_count': value.alert_count,
        'date': (value.date.toISOString()),
        'decline_rate': value.decline_rate,
        'declined_incident_count': value.declined_incident_count,
        'declined_workload_minutes': value.declined_workload_minutes,
        'incident_count': value.incident_count,
        'period': value.period,
        'real_workload_minutes': value.real_workload_minutes,
        'relevant_incidents': ((value.relevant_incidents as Array<any>).map(IncidentSlimToJSON)),
        'total_workload_minutes': value.total_workload_minutes,
    };
}

