/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPathRoundRobinConfig,
    EscalationPathRoundRobinConfigFromJSON,
    EscalationPathRoundRobinConfigFromJSONTyped,
    EscalationPathRoundRobinConfigToJSON,
} from './EscalationPathRoundRobinConfig';
import {
    EscalationPathTarget,
    EscalationPathTargetFromJSON,
    EscalationPathTargetFromJSONTyped,
    EscalationPathTargetToJSON,
} from './EscalationPathTarget';

/**
 * 
 * @export
 * @interface EscalationPathNodeLevel
 */
export interface EscalationPathNodeLevel {
    /**
     * 
     * @type {EscalationPathRoundRobinConfig}
     * @memberof EscalationPathNodeLevel
     */
    round_robin_config?: EscalationPathRoundRobinConfig;
    /**
     * The targets (users or schedules) for this level
     * @type {Array<EscalationPathTarget>}
     * @memberof EscalationPathNodeLevel
     */
    targets: Array<EscalationPathTarget>;
    /**
     * If the time to ack is relative to a time window, this defines whether we move when the window is active or inactive
     * @type {string}
     * @memberof EscalationPathNodeLevel
     */
    time_to_ack_interval_condition?: EscalationPathNodeLevelTimeToAckIntervalConditionEnum;
    /**
     * How long should we wait for this level to acknowledge before proceeding to the next node in the path?
     * @type {number}
     * @memberof EscalationPathNodeLevel
     */
    time_to_ack_seconds?: number;
    /**
     * If the time to ack is relative to a time window, this identifies which window it is relative to
     * @type {string}
     * @memberof EscalationPathNodeLevel
     */
    time_to_ack_weekday_interval_config_id?: string;
}

/**
* @export
* @enum {string}
*/
export enum EscalationPathNodeLevelTimeToAckIntervalConditionEnum {
    Active = 'active',
    Inactive = 'inactive'
}

export function EscalationPathNodeLevelFromJSON(json: any): EscalationPathNodeLevel {
    return EscalationPathNodeLevelFromJSONTyped(json, false);
}

export function EscalationPathNodeLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationPathNodeLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'round_robin_config': !exists(json, 'round_robin_config') ? undefined : EscalationPathRoundRobinConfigFromJSON(json['round_robin_config']),
        'targets': ((json['targets'] as Array<any>).map(EscalationPathTargetFromJSON)),
        'time_to_ack_interval_condition': !exists(json, 'time_to_ack_interval_condition') ? undefined : json['time_to_ack_interval_condition'],
        'time_to_ack_seconds': !exists(json, 'time_to_ack_seconds') ? undefined : json['time_to_ack_seconds'],
        'time_to_ack_weekday_interval_config_id': !exists(json, 'time_to_ack_weekday_interval_config_id') ? undefined : json['time_to_ack_weekday_interval_config_id'],
    };
}

export function EscalationPathNodeLevelToJSON(value?: EscalationPathNodeLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'round_robin_config': EscalationPathRoundRobinConfigToJSON(value.round_robin_config),
        'targets': ((value.targets as Array<any>).map(EscalationPathTargetToJSON)),
        'time_to_ack_interval_condition': value.time_to_ack_interval_condition,
        'time_to_ack_seconds': value.time_to_ack_seconds,
        'time_to_ack_weekday_interval_config_id': value.time_to_ack_weekday_interval_config_id,
    };
}

