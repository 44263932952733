import { Route, Routes } from "react-router";

import { DatasetShowPage } from "./DatasetShowPage";
import { DatasetsListPage } from "./DatasetsListPage";

export const DatasetsRoute = () => {
  return (
    <Routes>
      <Route path="/:datasetId" element={<DatasetShowPage />} />
      <Route path="*" element={<DatasetsListPage />} />
    </Routes>
  );
};
