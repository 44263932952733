import { usePylon } from "@bolasim/react-use-pylon";
import {
  DependentResource,
  SeatDescription,
  SeatDescriptionTypeEnum,
  UsersCreateRequestBodyBaseRoleSlugEnum as BaseRoleSlugEnum,
  UsersCreateRequestBodySeatTypesEnum as SeatTypeEnum,
} from "@incident-io/api";
import { UpgradeRequiredMessage } from "@incident-shared/billing";
import { DependentResourceLink } from "@incident-shared/engine/DependentResourceList";
import { BoxedCheckboxGroup } from "@incident-shared/forms/v2/inputs/BoxedCheckboxGroup";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { Link, LoadingBar, Txt } from "@incident-ui";
import { Button, ButtonTheme, Callout, CalloutTheme } from "@incident-ui";
import { CheckboxSelectOption } from "@incident-ui/Checkbox/CheckboxGroup";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact } from "lodash";
import { singular } from "pluralize";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Identity } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { groupDependentResources } from "../../DeletionConfirmationModal";

export interface SeatSelectFormData {
  state: Exclude<SeatTypeEnum, SeatTypeEnum.OnCallResponder>;
  seat_types: SeatTypeEnum[];
  base_role_slug: BaseRoleSlugEnum;
  custom_role_ids: string[];
}

export const SeatSelect = ({
  formMethods,
  userId,
  dependentResources,
  showSeatSelector = true,
}: {
  formMethods: UseFormReturn<SeatSelectFormData>;
  userId: string | null;
  dependentResources: DependentResource[];
  showSeatSelector?: boolean;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const { data: seatData } = useAPI("usersListAvailableSeatsAndRoles", {
    userId: userId ?? undefined,
  });

  const { onCallOnlyBillingSeats } = useFlags();

  if (!identity || !seatData) {
    return <LoadingBar className="h-12" />;
  }

  const { requiresDeletionResources } =
    groupDependentResources(dependentResources);

  return (
    <>
      {onCallOnlyBillingSeats ? (
        showSeatSelector ? (
          <BoxedCheckboxGroup
            formMethods={formMethods}
            name="seat_types"
            helptext={
              <div className="text-sm-normal text-content-secondary">
                You are billed based on seats (see{" "}
                <Link
                  to="/settings/billing"
                  openInNewTab
                  analyticsTrackingId={null}
                >
                  billing
                </Link>{" "}
                more info). Leave seats unchecked to add a user as a viewer
                only.
              </div>
            }
            options={newSeatOptions({
              identity,
              seats: seatData.seats,
              canEdit: dependentResources.length === 0,
            })}
            onValueChange={(value) => {
              // Reset the custom roles if the user changes the base role to viewer
              if (value.length === 0) {
                formMethods.setValue<"base_role_slug">(
                  "base_role_slug",
                  BaseRoleSlugEnum.User,
                );
                formMethods.setValue<"custom_role_ids">("custom_role_ids", []);
              } else {
                const { defaultValues } = formMethods.formState;

                // Revert to the defaults - i.e. what was there before the downgrade
                // to viewer. `resetField` _should_ do this, but only works if the
                // fields have been registered in the form, which they aren't
                // necessarily!
                formMethods.setValue(
                  "base_role_slug",
                  defaultValues?.base_role_slug ?? BaseRoleSlugEnum.User,
                );
                formMethods.setValue(
                  "custom_role_ids",
                  compact(defaultValues?.custom_role_ids ?? []),
                );
              }
            }}
          />
        ) : null
      ) : (
        <RadioButtonGroupV2
          formMethods={formMethods}
          name="state"
          helptext={
            <>
              You are billed based on seats. See{" "}
              <Link
                to="/settings/billing"
                openInNewTab
                analyticsTrackingId={null}
              >
                Settings → Billing
              </Link>
              .
            </>
          }
          helptextClassName="!text-xs"
          srLabel="Seat"
          options={seatOptions({
            identity,
            seats: seatData.seats,
            canEdit: dependentResources.length === 0,
          })}
          boxed
          onValueChange={(value) => {
            // Reset the custom roles if the user changes the base role to viewer
            if (value === "viewer") {
              formMethods.setValue<"base_role_slug">(
                "base_role_slug",
                BaseRoleSlugEnum.User,
              );
              formMethods.setValue<"custom_role_ids">("custom_role_ids", []);
            } else {
              const { defaultValues } = formMethods.formState;

              // Revert to the defaults - i.e. what was there before the downgrade
              // to viewer. `resetField` _should_ do this, but only works if the
              // fields have been registered in the form, which they aren't
              // necessarily!
              formMethods.setValue(
                "base_role_slug",
                defaultValues?.base_role_slug ?? BaseRoleSlugEnum.User,
              );
              formMethods.setValue(
                "custom_role_ids",
                compact(defaultValues?.custom_role_ids ?? []),
              );
            }
          }}
        />
      )}
      {requiresDeletionResources.length > 0 ? (
        <Callout theme={CalloutTheme.Plain} className="text-sm mt-2">
          <Txt>You can&apos;t downgrade this user, since they are on-call:</Txt>
          <ul className="list-disc">
            {requiresDeletionResources.flatMap((resources) =>
              resources.map((resource) => (
                <li key={resource.id} className="ml-4">
                  <DependentResourceLink resource={resource}>
                    {singular(resource.resource_type_label)}: {resource.label}
                  </DependentResourceLink>
                </li>
              )),
            )}
          </ul>
        </Callout>
      ) : null}
    </>
  );
};

const ViewerCallout = () => {
  const { showKnowledgeBaseArticle: showArticle } = usePylon();

  return (
    <Callout theme={CalloutTheme.Info} className="!text-xs">
      Viewers will automatically become responders when they get involved in an
      incident.{" "}
      <Button
        theme={ButtonTheme.Link}
        analyticsTrackingId="viewer-to-responder-learn-more"
        onClick={() => showArticle("8989378399")}
      >
        Learn more
      </Button>
      .
    </Callout>
  );
};

const seatOptions = ({
  identity,
  seats,
  canEdit,
}: {
  identity: Identity;
  seats: SeatDescription[];
  canEdit: boolean;
}): RadioButtonGroupOption[] => {
  return seats.map((seat) => {
    const option: RadioButtonGroupOption = {
      label: seat.name,
      value: seat.type,
      description: seat.description,
      isDisabled: !canEdit || !seat.can_assign || seat.upgrade_required,
      isDisabledTooltipContent: seat.upgrade_required ? (
        <UpgradeRequiredMessage {...upgradePropsFor[seat.type](identity)} />
      ) : !seat.can_assign ? (
        seat.type === "viewer" ? (
          "This person has been assigned a role that requires them to be a responder."
        ) : (
          `You don't have permission to make this person a ${seat.name}. This requires a billing admin.`
        )
      ) : undefined,
    };

    if (seat.type === SeatDescriptionTypeEnum.Viewer) {
      option.renderWhenSelectedNode = () => <ViewerCallout />;
      option.suffixNode = (
        <div className="bg-blue-200 text-blue-content rounded text-xs font-medium leading-4 py-0.5 px-2">
          Free
        </div>
      );
    }

    return option;
  });
};

const newSeatOptions = ({
  identity,
  seats,
  canEdit,
}: {
  identity: Identity;
  seats: SeatDescription[];
  canEdit: boolean;
}): CheckboxSelectOption[] => {
  return seats.map((seat) => {
    return {
      label: seat.name,
      value: seat.type,
      description: seat.description,
      isDisabled: !canEdit || !seat.can_assign || seat.upgrade_required,
      isDisabledTooltipContent: seat.upgrade_required ? (
        <UpgradeRequiredMessage {...upgradePropsFor[seat.type](identity)} />
      ) : !seat.can_assign ? (
        `You don't have permission to make this person a ${seat.name}.`
      ) : undefined,
    };
  });
};

export const upgradePropsFor: {
  [key in SeatDescriptionTypeEnum]: (
    identity: Identity,
  ) => Parameters<typeof UpgradeRequiredMessage>[0];
} = {
  viewer: () => ({
    featureName: "users",
    gate: {
      type: "numeric",
      value: 0, // this is a bit fake: we don't currently gate viewers.
      featureNameSingular: "user",
    },
  }),

  responder: () => ({
    featureName: "responders",
    gate: {
      type: "numeric",
      value: undefined, // TODO: when we build the free plan, this should be the responder gate
      featureNameSingular: "user",
    },
  }),

  on_call: (identity) => ({
    featureName: "on-callers",
    gate: {
      type: "numeric",
      value: identity.feature_gates.on_call_responders_count,
      featureNameSingular: "on-call user",
    },
  }),

  on_call_responder: (identity) => ({
    featureName: "responders with on-call",
    gate: {
      type: "numeric",
      value: identity.feature_gates.on_call_responders_count,
      featureNameSingular: "on-call user",
    },
  }),
};
