import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PopoverMultiSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import {
  IntegrationConfigFor,
  IntegrationsListObject,
} from "@incident-shared/integrations";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  ContentBox,
  Heading,
  IconEnum,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { ProductMarketingBanner } from "@incident-ui/ProductMarketingBanner/ProductMarketingBanner";
import { Searcher } from "fast-fuzzy";
import { intersection, sortBy } from "lodash";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import bannerGraphic from "src/components/settings/banners/banner-integrations.svg";
import { useIntegrations } from "src/hooks/useIntegrations";

import { PlanBadge } from "../../PlanBadge";
import { SettingsHeading } from "../../SettingsHeading";
import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { CATEGORY_CONFIG } from "../common/categories";
import { IntegrationCard } from "../common/IntegrationCard";
import upsellGraphic from "./integrations-upsell-graphic.svg";

type FormData = {
  searchTerm: string;
  searchCategories: string[];
};

export const IntegrationsListPage = ({
  integrations,
}: {
  integrations: IntegrationsListObject[];
}) => {
  const { noMoreIntegrationsInPlan } = useIntegrations();

  const formMethods = useForm<FormData>({
    defaultValues: {
      searchTerm: "",
      searchCategories: [],
    },
  });

  if (!integrations) {
    return null;
  }

  const [searchTerm, searchCategories] = formMethods.watch([
    "searchTerm",
    "searchCategories",
  ]);

  const typeOptions = Object.values(CATEGORY_CONFIG).map((category) => ({
    sort_key: category.label,
    value: category.category as string,
    label: category.label,
  }));

  const { installedIntegrations, uninstalledIntegrations } =
    filterAndSortIntegrations({
      integrations,
      searchTerm,
      searchCategories,
    });

  const toIntegrationCard = (integration: IntegrationsListObject) => {
    return (
      <IntegrationCard key={integration.provider} integration={integration} />
    );
  };

  return (
    <SettingsSubPageWrapper wrapperClassName="gap-8">
      <SettingsHeading
        title="Enhance with integrations"
        subtitle="Connect your favorite tools to boost functionality and efficiency."
        graphic={<img src={bannerGraphic} />}
      />
      <div className="flex flex-col gap-4">
        <Form.Root
          formMethods={formMethods}
          onSubmit={() => null}
          innerClassName="flex items-center w-full gap-4"
        >
          <InputV2
            formMethods={formMethods}
            name={"searchTerm"}
            type={InputType.Search}
            iconName={IconEnum.Search}
            className="grow"
            placeholder="Search integrations"
          />
          <PopoverMultiSelectV2
            formMethods={formMethods}
            name={"searchCategories"}
            placeholder="Filter by type"
            options={typeOptions}
            className="min-w-[200px] !mt-0 max-w-[50%]"
            triggerClassName="min-w-[200px]"
            align="end"
            isClearable={false}
          />
        </Form.Root>
        {installedIntegrations.length > 0 && (
          <ul className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
            {installedIntegrations.map(toIntegrationCard)}
          </ul>
        )}
      </div>
      {uninstalledIntegrations && (
        <div className="flex flex-col gap-4">
          <Heading level={2} size="medium">
            Connect to your other tools
          </Heading>
          {noMoreIntegrationsInPlan && <NoMoreIntegrationsCallout />}
          <ul className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
            {uninstalledIntegrations.map(toIntegrationCard)}
          </ul>
        </div>
      )}
      <ContentBox className="p-4 text-sm">
        <div className="max-w-2xl">
          <p className="font-medium text-content-primary mb-2">
            {`Looking for an integration that isn't here?`}
          </p>
          {`We're constantly building new integrations to help connect you to the
            tools you love most. If there's something you'd like to see, please `}
          <Button
            analyticsTrackingId="other-provider"
            theme={ButtonTheme.Link}
            href={`mailto:help@incident.io?subject=${encodeURIComponent(
              "Integration request",
            )}`}
            openInNewTab
          >
            let us know
          </Button>
          .
        </div>
      </ContentBox>
    </SettingsSubPageWrapper>
  );
};

const filterAndSortIntegrations = ({
  integrations,
  searchTerm,
  searchCategories,
}: {
  integrations: IntegrationsListObject[];
  searchTerm: string;
  searchCategories: string[];
}) => {
  const sortedIntegrations = sortBy(
    integrations,
    ({ provider }) => IntegrationConfigFor(provider).label,
  );

  let visibleIntegrations: IntegrationsListObject[] = sortedIntegrations.filter(
    ({ provider }) => {
      const config = IntegrationConfigFor(provider);
      if (
        searchCategories.length > 0 &&
        intersection(searchCategories, config.categories).length === 0
      ) {
        return false;
      }
      return true;
    },
  );

  const searcher = new Searcher(visibleIntegrations, {
    keySelector: (integration) => {
      const config = IntegrationConfigFor(integration.provider);
      return [
        config.label,
        config.description,
        ...config.categories.map((x) => CATEGORY_CONFIG[x].label),
      ];
    },
  });

  if (searchTerm !== "") {
    visibleIntegrations = searcher.search(searchTerm);
  }

  const installedIntegrations = visibleIntegrations.filter(
    ({ installed }) => installed,
  );

  const uninstalledIntegrations = [...visibleIntegrations].filter(
    ({ installed }) => !installed,
  );

  return {
    installedIntegrations,
    uninstalledIntegrations,
  };
};

const NoMoreIntegrationsCallout = () => (
  <ProductMarketingBanner
    className="bg-surface-invert text-white"
    title="Unlock more integrations"
    badge={<PlanBadge planName="team" size={BadgeSize.ExtraSmall} />}
    subtitle="You've reached your integration limit, upgrade your plan to access additional integrations."
    cta={
      <Button
        size={BadgeSize.Medium}
        href="/settings/billing"
        analyticsTrackingId="integrations-view-plans"
        className="w-fit"
      >
        View plans
      </Button>
    }
    graphic={<img src={upsellGraphic} />}
  />
);
