import {
  drawerUrlFor,
  INTEGRATION_CONFIGS,
  IntegrationListProvider,
} from "@incident-shared/integrations";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { isBefore } from "date-fns";
import { Route, Routes, useLocation } from "react-router-dom";
import { useQueryParams } from "src/utils/query-params";

export const INTEGRATION_REDIRECT_LOCAL_STORAGE_KEY_PREFIX =
  "redirect-on-integration-install:";

export const IntegrationsRedirectRoutes = () => {
  return (
    <Routes>
      {Object.keys(INTEGRATION_CONFIGS).map((provider) => (
        <Route
          key={provider}
          path={drawerUrlFor(provider as IntegrationListProvider)}
          element={
            <IntegrationsRedirectRoute
              key={provider}
              provider={provider as IntegrationListProvider}
            />
          }
        />
      ))}
    </Routes>
  );
};

const IntegrationsRedirectRoute = ({
  provider,
}: {
  provider: IntegrationListProvider;
}) => {
  const param = useQueryParams();
  const location = useLocation();
  const localStorageKey =
    INTEGRATION_REDIRECT_LOCAL_STORAGE_KEY_PREFIX + provider;

  const existingValue = localStorage.getItem(localStorageKey);

  // If we have a value in localstorage, and it's not expired, we should redirect back to that.
  const { canParse, returnPathAfterInstall, expiry } =
    parseLocalStorageValue(existingValue);
  const now = new Date();

  if (canParse && isBefore(now, expiry)) {
    // We do a little dance to preserve both query params in the return path and
    // the query params in the current location.
    const parsedReturnPath = new URL(
      // Make the path into an actual URL so that we can parse it.
      "https://incident.io" + returnPathAfterInstall,
    );
    const locationParams = new URLSearchParams(location.search);

    const combinedParams = new URLSearchParams();
    locationParams.forEach((value, key) => {
      combinedParams.append(key, value);
    });
    parsedReturnPath.searchParams.forEach((value, key) => {
      combinedParams.append(key, value);
    });

    return (
      <OrgAwareNavigate
        replace
        to={{
          pathname: parsedReturnPath.pathname,
          search: combinedParams.toString(),
        }}
      />
    );
  }

  // If we have a connect param, we should redirect to the connect page.
  const isConnect = param.get("connect") != null;

  // Don't pass through the connect param, it'll be encoded in the redirect path.
  const newParams = new URLSearchParams(location.search);
  newParams.delete("connect");

  return (
    <OrgAwareNavigate
      replace
      to={{
        pathname: isConnect
          ? `/settings/integrations/${provider}/connect`
          : `/settings/integrations/${provider}`,
        search: newParams.toString(),
      }}
    />
  );
};

const parseLocalStorageValue = (
  value: string | null,
): { canParse: boolean; returnPathAfterInstall: string; expiry: Date } => {
  if (!value) {
    return { canParse: false, returnPathAfterInstall: "", expiry: new Date() };
  }

  const splitValue = value.split("|");
  if (splitValue.length !== 2) {
    return { canParse: false, returnPathAfterInstall: "", expiry: new Date() };
  }

  const [returnPathAfterInstall, expiry] = splitValue;
  if (returnPathAfterInstall) {
    return { canParse: true, returnPathAfterInstall, expiry: new Date(expiry) };
  }

  return { canParse: false, returnPathAfterInstall: "", expiry: new Date() };
};
