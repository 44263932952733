// For when you don't have the right auth scopes and need to reinstall.

import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  IconEnum,
  Txt,
} from "@incident-ui";
import React from "react";
import { useLocation } from "react-router";
import { tcx } from "src/utils/tailwind-classes";

import { AddToSlackButton } from "../../components/slack/AddToSlackButton";
import {
  SlackInfoReconnectionReasonEnum as ReconnectionReason,
  SlackTokenScope,
  SlackTokenScope as TokenScope,
} from "../../contexts/ClientContext";
import { useIdentity } from "../../contexts/IdentityContext";

export const ReinstallSlackRoute = (): React.ReactElement => {
  const { search } = useLocation();
  const { identity } = useIdentity();

  if (!identity.slack_info) {
    // If you don't have Slack installed, you can't re-install!
    return <OrgAwareNavigate to="/dashboard" />;
  }

  const {
    install_url: addToSlackUrl,
    missing_token_scopes: missingTokenScopes,
    reconnection_reason: globalReinstallReason,
  } = identity.slack_info;
  const returnPath = new URLSearchParams(search).get("return_to");

  return (
    <PageWrapper
      icon={IconEnum.SlackGreyscale}
      title="Reinstall incident.io"
      className="text-center"
      width={PageWidth.Narrow}
    >
      <h1 className="mt-8 text-2xl mb-4 font-medium text-content-primary">
        Reinstall incident.io to your Slack workspace
      </h1>
      <ContentBox className="space-y-4 p-6">
        <div className="block text-base font-base text-slate-700 prose mx-auto">
          {globalReinstallReason !== ReconnectionReason.Empty ? (
            <ReinstallReasonExplanation
              reinstallReason={globalReinstallReason}
            />
          ) : (
            <ScopesNeededReinstallMessage
              missingTokenScopes={missingTokenScopes}
            />
          )}
        </div>
        <AddToSlackButton
          url={addToSlackUrl}
          returnPath={returnPath || undefined}
        />
      </ContentBox>
    </PageWrapper>
  );
};

const ReinstallReasonExplanation = ({
  reinstallReason,
}: {
  reinstallReason: ReconnectionReason;
}): React.ReactElement => {
  switch (reinstallReason) {
    case ReconnectionReason.NoWorkspaces:
      return (
        <>
          <Txt className="mb-2">
            incident.io is installed into your Slack Enterprise Grid
            organization, however you don&apos;t have any Slack workspaces
            connected.
          </Txt>
          <Txt className="text-center mb-4">
            Click &quot;Add to Slack&quot; below to install the app into a
            workspace.
          </Txt>
          <Callout theme={CalloutTheme.Info}>
            Only Slack admins will be able to install incident.io into an
            Enterprise Grid workspace.
          </Callout>
        </>
      );
    case ReconnectionReason.MissingChannelCreatePermissions:
      return (
        <>
          <Txt className="mb-2">
            incident.io needs permission to create channels in your Slack
            workspace.
          </Txt>
          <Txt className="text-center mb-4">
            Follow the steps in{" "}
            <Button
              href="https://help.incident.io/articles/4258864431-troubleshooting%3A-unable-to-install-due-to-channel-restrictions"
              theme={ButtonTheme.Link}
              openInNewTab
              analyticsTrackingId={"install-channel-create-permissions"}
            >
              this article
            </Button>{" "}
            to enable this permission and reinstall the app.
          </Txt>
        </>
      );
    default:
      return (
        <>
          <Txt className="mb-2">
            Our connection with your Slack workspace has expired.
            <br />
            To fix this, you&apos;ll need to reinstall incident.io to Slack.
          </Txt>
          <Txt className="text-center mb-4">
            Click &quot;Add to Slack&quot; below to reinstall the app.
          </Txt>
        </>
      );
  }
};

export function ScopesNeededReinstallMessage({
  feature,
  missingTokenScopes,
  className,
}: {
  feature?: string;
  missingTokenScopes: SlackTokenScope[];
  className?: string;
}): React.ReactElement {
  const featureText = feature ? feature : "our newest features";

  return (
    <div className={tcx("text-slate-700 space-y-2 prose mx-auto", className)}>
      <Txt>
        From time to time we need to request new permissions from Slack to bring
        you new functionality. In this case, we need to{" "}
        {getNicelyFormattedListOfScopeReasons(missingTokenScopes)}.
      </Txt>
      <Txt>
        To get access to {featureText}, you&apos;ll need to reinstall
        incident.io to your Slack workspace by clicking the &quot;Add to
        Slack&quot; button below.
      </Txt>
      <Txt>
        Not to worry &ndash; you won&apos;t experience any interruption in
        service.
      </Txt>
    </div>
  );
}

function getNicelyFormattedListOfScopeReasons(missingScopes: TokenScope[]) {
  const numberOfMissingScopes = missingScopes.length;
  if (numberOfMissingScopes === 1)
    return <span className="font-medium">{missingScopes[0].reason}</span>;
  return missingScopes.map((scope, i: number) => (
    <span key={scope.scope}>
      <span className="font-medium">{scope.reason}</span>
      {i < numberOfMissingScopes - 2
        ? ", "
        : i === numberOfMissingScopes - 2
        ? " and "
        : ""}
    </span>
  ));
}
